import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'hf-warning-badge',
  templateUrl: './warning-badge.component.html',
  styleUrls: ['./warning-badge.component.scss']
})
export class WarningBadgeComponent {
  @Input() label = 'Vulnerable';
  @Input() iconName = 'error_outlined';
}
