import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { getExceptionMessage } from '../../../../../../projects/client/src/app/shared/functions/get-exception-message';
import { ToastService } from '../../../../../../projects/client/src/app/shared/services/toast.service';
import { UserApiService } from '../../../services/user-api.service';

@Component({
  selector: 'hf-link-twenty-seven-tec',
  templateUrl: './link-twenty-seven-tec.component.html',
  styleUrls: ['./link-twenty-seven-tec.component.scss']
})
export class LinkTwentySevenTecComponent implements OnDestroy {
  public isSubmitting = false;
  public isLoading = true;

  public error: string;
  public get mode() { return this.route.snapshot.data.mode }
  public get code() { return this.route.snapshot.queryParamMap.get("code") }

  private destroy$ = new Subject();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userApiService: UserApiService,
    private toastService: ToastService,
  ) {
    if (this.mode !== "link") {
      return
    }

    this.code
      ? this.processAuthCode()
      : this.initialize();
  }

  private processAuthCode() {
    this.userApiService.processTwentySevenTecAuthCode({ authCode: this.code }).pipe(takeUntil(this.destroy$)).subscribe(
      result => {
        if (result.successful) {
          this.router.navigate(["/login/link-twenty-seven-tec-success"]);
          return;
        }

        this.isLoading = false;
        this.error = result.error;
        this.router.navigate(["/login/link-twenty-seven-tec-failed"]);
      },
      e => {
        this.isLoading = false;
        this.error = e.error?.error?.message;
        this.toastService.add("Failed to process auth code", "error", e);
      }
    );
  }

  private initialize() {
    this.userApiService.linkTwentySevenTec().pipe(takeUntil(this.destroy$)).subscribe(
      url => {
        window.location = url.value as any;
      },
      e => {
        this.isLoading = false;
        this.error = getExceptionMessage(e);
        this.toastService.add("Failed to link Twenty7tec", "error", e);
      });
  }

  public async onTryClicked() {
    this.router.navigate(["/login/link-twenty-seven-tec"]);
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }
}
