<label [for]="controlId" hfDragover (drop)="onFileChange($event)" [class.saved-file]="isFileSaved()">

  <ng-container *ngIf="!isLoading">
    <hf-file-preview *ngIf="isFileUploaded()" [fileChanged]="fileChanged" (deleteFile)="onDeleteFile()" [invalidFile]="errorMessage != null" [group]="group" [controlName]="controlName" [fileControl]="fileControl"></hf-file-preview>
    <div class="label-text" *ngIf="!isFileSaved()">Drag & Drop or click to add attachment</div>
  </ng-container>

  <hf-custom-spinner [sidepaneLoading]="true" *ngIf="isLoading"></hf-custom-spinner>
</label>

<input type="file" [id]="controlId" (change)="onFileChange($event)" [accept]="allowedFileTypes" [class.saved-file]="isFileSaved()"/>

<div *ngIf="showMaxHint" class="max-size-hint">
  Maximum total size of attachments is 10MB
</div>

<div>{{errorMessage}}</div>

