import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PinnedNoteViewDTO } from '../../models/pinned-note-view.dto';

@Component({
  selector: 'hf-pinned-note-box',
  templateUrl: './pinned-note-box.component.html',
  styleUrls: ['./pinned-note-box.component.scss']
})
export class PinnedNoteBoxComponent {
  @Input()
  public pinnedNote: PinnedNoteViewDTO;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  public onEditClicked() {
    this.router.navigate(["pinned-note"], { relativeTo: this.route });
  }
}
