import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CustomSelectContainerComponent } from './custom-select-container.component';

@NgModule({
  declarations: [
    CustomSelectContainerComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CustomSelectContainerComponent
  ],
  providers: []
})
export class CustomSelectContainerModule {

}
