import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { User, UserSimple } from '../models';
import { Attachment } from '../models/attachment';
import { ListResult } from './api-models';
import { ApiService } from './api.service';
import { HeronUserAssignmentDTO } from '../models/heron-user-assignment.dto';
import { UserService } from './user.service';

export interface ValidateEmailBody {
  email?: string,
  introducerId?: string,
  userId?: string,
  userType?: string
}

export interface MicrosoftGraphUrlDTO {
  value: string;
}

export interface MicrosoftGraphAuthDTO {
  authCode: string;
}

export interface MicrosoftGraphAuthResponseDTO {
  successful: boolean;
  error: string;
}

@Injectable()
export class UserApiService {
  private readonly API_NAME: string = 'user';

  constructor(
    private readonly apiService: ApiService,
    private readonly userService: UserService,
  ) { }

  public getProfileInfo(): Promise<User> {
    return this.apiService.get(this.API_NAME, "getProfileInfo")
      .toPromise();
  }

  public validateEmailAddress(body: ValidateEmailBody): Observable<any> {
    return this.apiService.post(this.API_NAME, 'validateEmailAddress', body)
  }

  public validateEmailAddressForEditProfile(body: { email: string }): Observable<any> {
    return this.apiService.post(this.API_NAME, 'validateEmailAddressForEditProfile', body)
  }

  public editProfile(profile: User): Promise<any> {
    return this.apiService.post(this.API_NAME, 'editProfile', profile).toPromise();
  }

  public listHeronUsers(params: { [key: string]: any }): Observable<any> {
    return this.apiService.get(this.API_NAME, 'listHeronUsers', { params });
  }

  public async getLineManagers(): Promise<UserSimple[]> {
    const result = await this.apiService.get(this.API_NAME, 'getLineManagers').toPromise();
    return result.items;
  }

  public getProtectionAdvisors(userIdToExclude?: string): Promise<UserSimple[]> {
    return this.apiService.get<UserSimple[]>(this.API_NAME, 'getProtectionAdvisors').pipe(
      map(result => result.items.filter(user => user.id != userIdToExclude))
    ).toPromise();
  }

  public getDefaultProtectionAdvisor(mortgageAdvisorId: string): Promise<UserSimple> {
    return this.apiService.get<UserSimple[]>(this.API_NAME, 'getDefaultProtectionAdvisor', { params: { mortgageAdvisorId } }).toPromise();
  }

  public viewHeronUser(userId: string): Promise<User> {
    return this.apiService.get(this.API_NAME, 'viewHeronUser', { params: { userId } }).toPromise();
  }

  public async getHeronUsers(params?: { mortgageId?: string, protectionCaseId?: string }): Promise<UserSimple[]> {
    const result = await this.apiService.get(this.API_NAME, 'getHeronUsers', { params }).toPromise();

    return result.items;
  }

  public async getAllAdvisors(): Promise<UserSimple[]> {
    const result = await this.apiService.get(this.API_NAME, 'getAllAdvisors').toPromise();

    return result.items;
  }

  public addHeronUser(user: User): Promise<void> {
    return this.apiService.post(this.API_NAME, 'addHeronUser', user).toPromise();
  }

  public editHeronUser(userId: string, user: User): Promise<void> {
    return this.apiService.post(this.API_NAME, 'editHeronUser', user, { params: { userId } }).toPromise();
  }

  public getMortgageAdvisors(userIdToExclude?: string): Promise<UserSimple[]> {
    return this.apiService.get(this.API_NAME, 'getMortgageAdvisors').pipe(
      map(result => result.items.filter(user => user.id != userIdToExclude))
    ).toPromise();
  }

  public getCaseProgressionUsers(): Promise<ListResult<UserSimple>> {
    return this.apiService.get(this.API_NAME, 'getCaseProgressionUsers').toPromise();
  }

  public removeHeronUser(userId: string, assignMortgagesToId?: string, assignProtectionCasesToId?: string) {
    const assignMortgagesToIdParam = assignMortgagesToId ? ('&assignMortgagesToId=' + assignMortgagesToId) : "";
    const assignProtectionCasesToIdParam = assignProtectionCasesToId ? ('&assignProtectionCasesToId=' + assignProtectionCasesToId) : "";
    return this.apiService.delete(this.API_NAME, 'removeHeronUser?userId=' + userId + assignMortgagesToIdParam + assignProtectionCasesToIdParam).toPromise();
  }

  public triggerAttachmentDownload(attachment: Attachment): void {
    if (!attachment || !attachment.filename)
      return;

    const url = `${environment.apiUrl}servlet/gcs/${attachment.folder}/${attachment.filename}?download=true`;

    window.open(url);
  }

  public getIntroducerAdvisors(id: string): Promise<UserSimple[]> {
    return this.apiService.get(this.API_NAME, 'getIntroducerAdvisors', {
      params: {
        introducerId: id
      }
    }).pipe(
      map(result => result.items)
    ).toPromise();
  }

  public async getMortgageAdvisorsForAssignment(mortgageId: string): Promise<HeronUserAssignmentDTO[]> {
    const result = await this.apiService.get(this.API_NAME, 'getMortgageAdvisorsForAssignment', { params: { mortgageId } })
      .toPromise<ListResult<HeronUserAssignmentDTO>>();

    return result.items;
  }

  public async getProtectionAdvisorsForAssignment(protectionCaseId: string): Promise<HeronUserAssignmentDTO[]> {
    const result = await this.apiService.get(this.API_NAME, 'getProtectionAdvisorsForAssignment', { params: { protectionCaseId } })
      .toPromise<ListResult<HeronUserAssignmentDTO>>();

    return result.items;
  }

  public linkOffice365(): Observable<MicrosoftGraphUrlDTO> {
    return this.apiService.get(this.API_NAME, 'linkOffice365');
  }

  public unlinkOffice365(): Observable<string> {
    return this.apiService.post(this.API_NAME, 'unlinkOffice365');
  }

  public processOffice365AuthCode(body: MicrosoftGraphAuthDTO): Observable<MicrosoftGraphAuthResponseDTO> {
    return this.apiService.post(this.API_NAME, 'processOffice365AuthCode', body);
  }

  public linkTwentySevenTec(): Observable<any> {
    return this.apiService.get(this.API_NAME, 'linkTwentySevenTec');
  }

  public unlinkTwentySevenTec(): Observable<any> {
    return this.apiService.post(this.API_NAME, 'unlinkTwentySevenTec');
  }

  public processTwentySevenTecAuthCode(data: { authCode: string }): Observable<any> {
    return this.apiService.post(this.API_NAME, 'processTwentySevenTecAuthCode', data);
  }
}
