import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { AuthenticationService } from './website/services/authentication.service';
import { UserService } from './website/services/user.service';
import { UserPermission } from './website/models';
import { NavigationService } from './website/services/navigation.service';
import {LoginService} from './website/services/login.service';

@Injectable()
export class CanAccessGuard implements CanActivate, CanActivateChild {
  constructor(
    private loginService: LoginService,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private navigationService: NavigationService
  ) { }

  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const isAutheticated = await this.isAutheticated();

    if (!isAutheticated) {
      this.navigationService.goToLogin(state.url);

      return false;
    }

    if (!this.hasPermissionsToActivate(route)) {
      this.navigationService.goToApp();

      return false;
    }

    return true;
  }

  public canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.hasPermissionsToActivate(childRoute);
  }

  private async isAutheticated(): Promise<boolean> {
    if (!this.userService.getUser()) {
      await this.loginService.tryCheckSign(); 
    }

    return this.userService.isAutheticated();
  }

  private hasPermissionsToActivate(route: ActivatedRouteSnapshot): boolean {
    const routePerm: UserPermission | UserPermission[] = route.data.permission;

    return !routePerm || this.userService.hasPermission(routePerm);
  }
}
