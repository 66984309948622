import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '../../../../../../projects/client/src/app/shared/services';
import { Twenty7tecApiService, Twenty7tecRecommendedProduct } from '../../../services/twenty7tec-api.service';
import { fadeAnimation, sidepaneMove } from '../../../shared/animations/sidepane-animation';
import { SelectTwenty7tecMortgageService } from './select-twenty7tec-mortgage.service';

@Component({
  selector: 'hf-select-twenty7tec-mortgage',
  templateUrl: './select-twenty7tec-mortgage.component.html',
  styleUrls: [
    './select-twenty7tec-mortgage.component.scss',
    '../../../styles/sidepanes.partial.scss'
  ],
  animations: [sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove]': 'true' }
})
export class SelectTwenty7tecMortgageComponent {
  public mortgages: Twenty7tecRecommendedProduct[];

  public isLoading = true;
  public compareFn = (a1: Twenty7tecRecommendedProduct, a2: Twenty7tecRecommendedProduct) => a1.documentId === a2.documentId;

  public form: FormGroup = new FormGroup({
    mortgage: new FormControl(null, Validators.required)
  });

  private get handoverId() { return this.route.snapshot.paramMap.get("handoverId") }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private twenty7tecApi: Twenty7tecApiService,
    private toast: ToastService,
    private selectTwenty7tecMortgageService: SelectTwenty7tecMortgageService,
  ) {
    this.initialize();
    this.form.get("mortgage").valueChanges.subscribe(value => {
      this.selectTwenty7tecMortgageService.mortgage$.next(value);
      this.onBack();
    });
  }

  private async initialize() {
    this.isLoading = true;

    this.twenty7tecApi.listRecommendedProducts(this.handoverId).subscribe(
      r => {
        this.mortgages = r.items;
        this.isLoading = false;
      },
      e => {
        this.isLoading = false;
        this.toast.add("Failed to get list of mortgages", "error", e);
      }
    );
  }

  public onBack() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }
}
