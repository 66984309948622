import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { AuthenticationService } from './website/services/authentication.service';
import { UserService } from './website/services/user.service';
import { UserPermission } from './website/models';
import { NavigationService } from './website/services/navigation.service';

@Injectable()
export class CanAccessNeedsVerifyGuard implements CanActivate, CanActivateChild {
  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private navigationService: NavigationService
  ) { }

  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const isAutheticated = await this.isAutheticated();

    if (!isAutheticated) {
      this.navigationService.goToLogin(state.url);

      return false;
    }

    return isAutheticated;
  }

  public canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean>  {
    return this.isAutheticated();
  }

  private async isAutheticated(): Promise<boolean> {
    const user = this.userService.getUser();

    if (user && (user.id || user.twoFactorNeeded) && user.userType !== "CLIENT") {
      return true;
    }

    try {
      const session = await this.authenticationService.checkSigned();

      if (session.userType === "CLIENT") {
        return false;
      }

      this.userService.setUser(session);

      return true;
    } catch (e) {
      console.log("checkSigned error: ", e);
    }

    return false;
  }
}
