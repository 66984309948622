<div (click)="onBack()" class="overlay" [@fadeAnimation]></div>

<div class="sidepane-content">
  <div class="flex-space-between-layout template-header">
    <h1>Journal Entry</h1>
    <hf-custom-button type="button" class="button-plain-icon" icon="close" iconPos="center" (onClick)="onBack()">
    </hf-custom-button>
  </div>

  <div class="template-main">
    <hf-custom-spinner *ngIf="isLoading" sidepaneLoading="true"></hf-custom-spinner>
    <ng-container *ngIf="!isLoading">
      <form>
        <ng-container *ngIf="isMortgage && entry.application">
          <label>Application</label>
          <div class="hf-form-static-value">
            <hf-attachment-image [attachment]="entry.application.lenderLogo" [alt]="entry.application.lender" class="provider-logo"></hf-attachment-image>
          </div>

          <label>Application Reference</label>
          <div class="hf-form-static-value">
            {{ entry.application.lenderReference }}
          </div>
        </ng-container>

        <label>Date and Time</label>
        <div class="hf-form-static-value">
          {{entry?.createdDate | date:'d MMM y, HH:mm'}}
        </div>

        <label>Activity</label>
        <div class="hf-form-static-value">
          {{JOURNAL_ACTIVITY_NAME.get(entry?.activity)}}
        </div>

        <label>Summary</label>
        <div class="hf-form-static-value">
          {{entry?.summary}}
        </div>

        <label>Details</label>
        <div class="hf-form-static-value" [innerHTML]="entry?.details"></div>

        <div class="mark-area" [ngClass]="{'passed': entry?.clientNotified, 'canceled': !entry?.clientNotified}">
          <i class="material-icons">{{entry?.clientNotified ? 'check_circle' : 'cancel'}}</i>
          <span>Sent to Client</span>
        </div>

        <div *ngIf="showIntroducerNotified"
             class="mark-area"
             [ngClass]="{'passed': entry?.introducerNotified, 'canceled': !entry?.introducerNotified}">
          <i class="material-icons">{{entry?.introducerNotified ? 'check_circle' : 'cancel'}}</i>
          <span>Sent to Introducer</span>
        </div>

        <ng-container *ngIf="entry?.connectedDocument">
          <label>File Attachment</label>
          <div class="file-attachment" (click)="onDocumentDownload()">
            <div>{{entry?.connectedDocument?.attachment.originalFilename || entry?.connectedDocument?.attachment.filename}}</div>
            <i class="material-icons">get_app</i>
          </div>
        </ng-container>
      </form>
    </ng-container>
  </div>

  <div *ngIf="canBeRemoved" class="template-footer">
      <hf-custom-button type="button"
                        class="button-plain-icon delete-button keep-right"
                        icon="delete"
                        iconPos="center"
                        (onClick)="onRemove()">
      </hf-custom-button>
  </div>

  <div class="sidepane-child-anchor">
    <router-outlet></router-outlet>
  </div>
