import {Directive, ElementRef, EventEmitter, HostListener, OnInit, Output} from '@angular/core';

@Directive({selector: '[click-away]'})

export class ClickAwayDirective implements OnInit{

  private initiated:boolean;
  @Output() clickedAway = new EventEmitter();

  constructor(private elRef:ElementRef) {
  }

  ngOnInit() {
    setTimeout(() => {
      this.initiated = true;
    }, 100);
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event) {

    if(!this.initiated)
      return;

    let elPosition = this.elRef.nativeElement.getBoundingClientRect();

    if ((event.clientX > elPosition.left && event.clientX < elPosition.left + elPosition.width) &&
      (event.clientY > elPosition.top && event.clientY < elPosition.top + elPosition.height)) {
    } else {
      this.clickedAway.emit();
    }
  }
}
