import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CustomButtonModule } from '../../../shared/custom-button/custom-button.module';
import { CustomSelectorModule } from '../../../shared/custom-selector/custom-selector.module';
import { CustomSpinnerModule } from '../../../shared/custom-spinner/custom-spinner.module';
import { SharedModule } from '../../../shared/shared.module';
import { AssignCaseProgressionComponent } from './assign-case-progression.component';

@NgModule({
  declarations: [AssignCaseProgressionComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    CustomButtonModule,
    CustomSpinnerModule,
    CustomSelectorModule,
  ],
  exports: [AssignCaseProgressionComponent]
})
export class AssignCaseProgressionModule { }
