import { Component, Inject, InjectionToken } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { ReturnSection } from '../../../services/fact-find-api.service';
import { ToasterService } from '../../../services/toaster.service';
import { fadeAnimation, sidepaneMove } from '../../../shared/animations/sidepane-animation';

export interface ReturnFactFindService {
  markSectionReturned(routeSnapshot: ActivatedRouteSnapshot, formValue: ReturnSection): Promise<void>;
}

export const RETURN_FACT_FIND_SERVICE = new InjectionToken<ReturnFactFindService>("RETURN_FACT_FIND_SERVICE");

@Component({
  selector: 'hf-return-fact-find',
  templateUrl: './return-fact-find.component.html',
  styleUrls: ['./return-fact-find.component.scss',
    '../../../styles/sidepanes.partial.scss'
  ],
  animations: [sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove]': 'true' }
})
export class ReturnFactFindComponent {
  public get mortgageId() { return this.route.snapshot.paramMap.get("id"); }

  public form = new FormGroup({
    returnComment: new FormControl(undefined, Validators.required),
  });

  public isSubmitting = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store,
    @Inject(RETURN_FACT_FIND_SERVICE) private returnFactFindService: ReturnFactFindService,
    private toasterService: ToasterService
  ) { }

  public async onSubmit() {
    try {
      this.isSubmitting = true;

      await this.returnFactFindService.markSectionReturned(this.route.snapshot, this.form.value);
      this.toasterService.callToaster({ severity: 'info', summary: 'Info', detail: 'Section marked for return. When finished, press Return' });

      this.onBack();
    } catch (err) {
      this.toasterService.callToaster({ severity: 'error', summary: 'Error', detail: `Failed to return section. Please try again. ${err.error.error.message}` });
    } finally {
      this.isSubmitting = false;
    }
  }

  public onBack() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }
}
