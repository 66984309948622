import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

import { ApiService } from './api.service';
import { JournalEntry } from '../models/journal-details';
import { Observable } from 'rxjs';
import { JournalListDTO, NoteDTO } from '../models/journal';
import { MortgageTableParams } from '../models/mortgage-dashboard';
import { PinnedNoteViewDTO } from '../models/pinned-note-view.dto';
import { ListResult, TableResult } from './api-models';
import { UserWithContactDTO } from '../models/user-with-contact.dto';
import { EmailJournalType } from '../utils/journal.enum';

export interface EmailJournalEventDTO {
  recipient: UserWithContactDTO;
  createdDate: string;
  type: EmailJournalType;
  details: string
}

@Injectable()
export class JournalApiService {
  private readonly API_NAME: string = 'journal';

  constructor(private readonly apiService: ApiService) { }

  public viewEntry(journalEntryId: string): Promise<JournalEntry> {
    const method = 'getJournalDetails';
    const params = { journalEntryId };

    return this.apiService.get(this.API_NAME, method, { params }).toPromise();
  }

  public deleteEntry(journalEntryId: string): Promise<void> {
    const method = 'deleteJournal';
    const params = { journalEntryId };

    return this.apiService.delete(this.API_NAME, method, { params }).toPromise();
  }

  public async getMortgageJournalDetails(journalEntryId: string): Promise<any> {
    const params = { journalEntryId };

    return this.apiService
      .get(this.API_NAME, "getMortgageJournalDetails", { params })
      .toPromise();
  }

  public triggerAttachmentDownload(journalEntry: JournalEntry): void {
    if (!journalEntry.connectedDocument || !journalEntry.connectedDocument.attachment)
      return;

    const attachment = journalEntry.connectedDocument.attachment;
    const url = `${environment.apiUrl}servlet/gcs/${attachment.folder}/${attachment.filename}?download=true`;

    window.open(url);
  }

  public listMortgageJournal(params: MortgageTableParams): Observable<TableResult<JournalEntry>> {
    const method = 'listMortgageJournal';

    return this.apiService.get(this.API_NAME, method, { params });
  }

  public listProtectionCaseJournal(params: any): Observable<JournalEntry[]> {
    const method = 'listProtectionCaseJournal';

    return this.apiService.get(this.API_NAME, method, { params });
  }

  public addProtectionCaseNote(protectionCaseId: string, note: NoteDTO): Observable<JournalListDTO> {
    const method = 'addProtectionCaseNote?protectionCaseId=' + protectionCaseId;
    return this.apiService.post(this.API_NAME, method, note);
  }


  public addMortgagePinnedNote(mortgageId: string, note: PinnedNoteViewDTO): Observable<PinnedNoteViewDTO> {
    const params = { mortgageId };
    return this.apiService.post(this.API_NAME, "addMortgagePinnedNote", note, { params });
  }

  public editMortgagePinnedNote(mortgageId: string, note: PinnedNoteViewDTO, version: number): Observable<PinnedNoteViewDTO> {
    const params = { mortgageId, version };
    return this.apiService.post(this.API_NAME, "editMortgagePinnedNote", note, { params });
  }

  public deleteMortgagePinnedNote(mortgageId: string, version: number): Observable<void> {
    const params = { mortgageId, version };
    return this.apiService.delete(this.API_NAME, "deleteMortgagePinnedNote", { params });
  }

  public viewMortgagePinnedNote(mortgageId: string): Observable<PinnedNoteViewDTO> {
    const params = { mortgageId };
    return this.apiService.get(this.API_NAME, "viewMortgagePinnedNote", { params });
  }

  public addProtectionPinnedNote(protectionCaseId: string, note: PinnedNoteViewDTO): Observable<PinnedNoteViewDTO> {
    const params = { protectionCaseId };
    return this.apiService.post(this.API_NAME, "addProtectionPinnedNote", note, { params });
  }

  public editProtectionPinnedNote(protectionCaseId: string, note: PinnedNoteViewDTO, version: number): Observable<PinnedNoteViewDTO> {
    const params = { protectionCaseId, version };
    return this.apiService.post(this.API_NAME, "editProtectionPinnedNote", note, { params });
  }

  public deleteProtectionPinnedNote(protectionCaseId: string, version: number): Observable<void> {
    const params = { protectionCaseId, version };
    return this.apiService.delete(this.API_NAME, "deleteProtectionPinnedNote", { params });
  }

  public viewProtectionPinnedNote(protectionCaseId: string): Observable<PinnedNoteViewDTO> {
    const params = { protectionCaseId };
    return this.apiService.get(this.API_NAME, "viewProtectionPinnedNote", { params });
  }

  public loadEmailEvents(emailJournalId: string): Observable<ListResult<EmailJournalEventDTO>> {
    const params = { emailJournalId };
    return this.apiService.get(this.API_NAME, "loadEmailEvents", { params });
  }

  public loadEmailThread(emailJournalId: string): Observable<ListResult<any>> {
    const params = { emailJournalId };
    return this.apiService.get(this.API_NAME, "loadEmailThread", { params });
  }
}
