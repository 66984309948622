<div (click)="onBack()" class="overlay" [@fadeAnimation]></div>

<div class="sidepane-content">
  <div class="flex-space-between-layout template-header">
    <h1>Run Credit Report</h1>
    <hf-custom-button type="button"
                      class="button-plain-icon"
                      icon="close"
                      iconPos="center"
                      (onClick)="onBack()">
    </hf-custom-button>
  </div>

  <div class="template-main">
    <hf-custom-spinner *ngIf="isLoading" sidepaneLoading="true"></hf-custom-spinner>

    <form *ngIf="!isLoading" [formGroup]="form">
      <ng-container *ngIf="activeStep === 1">
        <hf-custom-selector formControlName="applicants" [items]="applicants" [multi]="true">
          <ng-template #itemTemplate let-item>
            <div>
              {{ item.fullName }}
            </div>
          </ng-template>
        </hf-custom-selector>
      </ng-container>

      <ng-container *ngIf="activeStep === 2">
        <div class="text-big-grey24">
          <ng-container *ngIf="value.applicants?.length > 1">
            An Experian credit report will be run for the following applicants:

            <ul class="text-big-medium-grey24">
              <li *ngFor="let applicant of value.applicants">{{ applicant.fullName }}</li>
            </ul>
          </ng-container>
          <ng-container *ngIf="value.applicants?.length === 1">
            An Experian credit report will be run for applicant {{ value.applicants[0].fullName }}.<br><br>
          </ng-container>

          Results will show within a few minutes.
        </div>
      </ng-container>
    </form>
  </div>

  <div class="template-footer">
    <ng-container *ngIf="activeStep === 1">
      <hf-custom-button type="submit"
                        class="button-primary"
                        label="Run Credit Report"
                        [disabled]="form.invalid"
                        [isLoading]="isSubmitting"
                        (onClick)="onSubmit()">
      </hf-custom-button>

      <hf-custom-button type="button"
                        class="button-secondary"
                        label="Cancel"
                        (onClick)="onBack()">
      </hf-custom-button>
    </ng-container>

    <ng-container *ngIf="activeStep === 2">
      <hf-custom-button type="button"
                        class="button-secondary"
                        label="Close"
                        (onClick)="onBack()">
      </hf-custom-button>
    </ng-container>
  </div>
</div>

<div class="sidepane-child-anchor">
  <router-outlet></router-outlet>
</div>
