import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { fadeAnimation, sidepaneMove } from '../../../shared/animations/sidepane-animation';
import { ToasterService } from '../../../services/toaster.service';
import { MORTGAGE_DOCUMENT_TYPE, PROTECTION_DOCUMENT_TYPE } from '../../../utils/variables.data';
import { FileControlsConfigBuilder } from '../../../shared/fileuploader/utils/fileuploader.utils';
import { HfValidators } from '../../../utils/form.validators';
import { AddDocumentService } from './add-document.service';
import { SelectDocumentTypeService } from '../select-document-type/select-document-type.service';
import { Subscription, Subject } from 'rxjs';
import { JournalTabService } from '../../tabs/journal/journal-tab.service';
import { DocumentsTabService } from '../../tabs/documents/documents-tab.service';
import {SelectApplicationLenderService, fixHandoverId} from '../../../modules/mortgages/select-application-lender/select-application-lender.service';
import {takeUntil} from 'rxjs/operators';
import {MortgagesService} from '../../../modules/mortgages/mortgages.service';
import { Store } from '@ngxs/store';

@Component({
  selector: 'hf-add-document',
  templateUrl: 'add-document.component.html',
  styleUrls: [
    'add-document.component.scss',
    '../../../styles/sidepanes.partial.scss'
  ],
  animations: [sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove]': 'true' },
  providers: [SelectApplicationLenderService],
})

export class AddDocumentComponent implements OnInit, OnDestroy {
  public addDocumentForm: FormGroup;
  public isSubmitting = false;
  public get value() { return this.addDocumentForm.value }
  public get handovers() { return this.mortgagesService.data?.handovers }

  private documentTypeSelectedSubscription: Subscription;
  private mortgageId;
  private destroy$ = new Subject();
  private control(name: string) { return this.addDocumentForm.get(name) }

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toasterService: ToasterService,
    private addDocumentService: AddDocumentService,
    public selectDocumentTypeService: SelectDocumentTypeService,
    private journalTabService: JournalTabService,
    private documentsTabService: DocumentsTabService,
    private selectApplicationLenderService: SelectApplicationLenderService,
    private mortgagesService: MortgagesService,
    private store: Store,
  ) {
    this.addDocumentForm = this.fb.group({
      connectedDocument: this.fb.group({
        attachment: this.fb.group(
          new FileControlsConfigBuilder()
            .maxFileSizeDisk()
            .required('Document is required')
            .build()
        ),
        type: [null, Validators.required],
        otherTypeLabel: [{ value: '', disabled: true }, Validators.required],
        sendToClient: [''],
      }),
      internalNotes: [null, Validators.required],
      messageToClient: [{ value: null, disabled: true }, Validators.required],
      _handover: [null],
    });

    this.addDocumentForm.get('connectedDocument.sendToClient').valueChanges.subscribe(value => {
      if (value) {
        this.addDocumentForm.get('messageToClient').enable();
        this.addDocumentForm.get('connectedDocument.attachment').get(FileControlsConfigBuilder.FILE_SIZE).setValidators([HfValidators.maxFileSizeEmail()]);
      } else {
        this.addDocumentForm.get('messageToClient').disable();
        if (!this.addDocumentForm.get('connectedDocument.attachment').value[FileControlsConfigBuilder.FILE_NAME]) {
          this.addDocumentForm.get('connectedDocument.attachment').reset();
        }
        this.addDocumentForm.get('connectedDocument.attachment').get(FileControlsConfigBuilder.FILE_SIZE).setValidators([HfValidators.maxFileSizeDisk()]);
      }
      this.addDocumentForm.get('connectedDocument.attachment').get(FileControlsConfigBuilder.FILE_SIZE).updateValueAndValidity();
    });

    this.addDocumentForm.get('connectedDocument.type').valueChanges.subscribe((value: MORTGAGE_DOCUMENT_TYPE) => {
      if (value == 'OTHER')
        this.addDocumentForm.get('connectedDocument.otherTypeLabel').enable();
      else
        this.addDocumentForm.get('connectedDocument.otherTypeLabel').disable();
    });

    this.selectApplicationLenderService.application$.pipe(takeUntil(this.destroy$))
     .subscribe(handover => this.control("_handover").setValue(handover?.id ? handover : null));

  }

  public onSelectLender() {
    this.router.navigate(["selectApplication"], { relativeTo: this.activatedRoute });
  }

  ngOnInit() {
    this.documentTypeSelectedSubscription = this.selectDocumentTypeService.documentTypeChanged.subscribe((type: MORTGAGE_DOCUMENT_TYPE | PROTECTION_DOCUMENT_TYPE) => {
      this.addDocumentForm.get('connectedDocument.type').setValue(type);
    });

    this.mortgageId = this.activatedRoute.snapshot.params['id'];
  }

  async onSubmit() {
    const value = fixHandoverId(this.value);

    this.isSubmitting = true;

    try {
      await this.addDocumentService.addDocument(value, this.activatedRoute.snapshot.paramMap);

      const saveAction = this.activatedRoute.snapshot.data.saveAction;
      if (saveAction) {
        this.store.dispatch(new saveAction());
      }

      this.journalTabService.reloadJournalTab.emit();
      this.documentsTabService.reloadDocumentsTab.emit();
      this.toasterService.callToaster({ severity: 'info', summary: 'Info', detail: `Document added` });
      this.onBack();
    } catch (err) {
      this.toasterService.callToaster({ severity: 'error', summary: 'Error', detail: `${err.error.error.message}` });
    };

    this.isSubmitting = false;
  }

  public onBack() {
    this.router.navigate(['./..'], { relativeTo: this.activatedRoute });
  }

  ngOnDestroy(): void {
    this.documentTypeSelectedSubscription.unsubscribe();
    this.addDocumentService.selectedDocumentType = null;
    this.destroy$.next();
  }

  selectDocumentType() {
    this.selectDocumentTypeService.selectedDocumentType = this.addDocumentForm.value.connectedDocument.type;
    this.router.navigate(["selectDocumentType"], { relativeTo: this.activatedRoute });
  }
}
