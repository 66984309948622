  <ng-container *ngIf="type === 'radio'" [formGroup]="group">
  <div class="hf-custom-radio-container">
    <ng-container *ngFor="let item of enumData; let i = index">
      <input [tabindex]="i"
             [formControlName]="elemId"
             type="radio"
             [id]="instanceCounter+'-'+elemId+'-'+i"
             [name]="elemId"
             [value]="item[0]"/>
      <label [for]="instanceCounter+'-'+elemId+'-'+i" (click)="onLabelClicked(item[0])">
        {{item[1]}}
        <span class="hf-checked-tick">
          <i class="material-icons">check</i>
        </span>
      </label>
    </ng-container>
  </div>
</ng-container>

<!-- CHECKBOXES -->
<ng-container *ngIf="type === 'checks'" [formGroup]="group.controls[elemId]">
  <div class="hf-custom-checks-container">
    <ng-container *ngFor="let item of enumData; let i = index">
      <input [tabindex]="i"
             [formControlName]="elemId+'-'+i"
             type="checkbox"
             [id]="instanceCounter+'-'+elemId+'-'+i"
             [name]="elemId"
             [value]="item[0]"/>
      <label [for]="instanceCounter+'-'+elemId+'-'+i">
        {{item[1]}}
        <span class="hf-checked-tick">
          <i class="material-icons">check</i>
        </span>
      </label>
    </ng-container>
  </div>
</ng-container>
