import { Component, ContentChild, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'hf-tab-panel',
  templateUrl: './tab-panel.component.html',
  styleUrls: ['./tab-panel.component.scss']
})
export class TabPanelComponent {
  @Input()
  public key: string;

  @Input()
  public label: string;

  @Input()
  public badge: string;

  @ContentChild("content")
  public content: TemplateRef<any>
}
