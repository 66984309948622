<div (click)="onBack()" class="overlay" [@fadeAnimation]></div>

<div class="sidepane-content">
  <div class="flex-space-between-layout template-header">
    <h1>Document Request</h1>
    <hf-custom-button type="button"
                      class="button-plain-icon"
                      icon="close"
                      iconPos="center"
                      (onClick)="onBack()">
    </hf-custom-button>
  </div>

  <div class="template-main">
    <ng-container *ngIf="isLoading">
      <hf-custom-spinner sidepaneLoading="true"></hf-custom-spinner>
    </ng-container>

    <form *ngIf="!isLoading" [formGroup]="form">
      <label>Requested</label>
      <div class="hf-form-static-value">
        {{data.createdDate | date:'d MMM y, HH:mm'}}
      </div>

      <label>Document Type</label>
      <div class="hf-form-static-value">
        {{selectDocumentTypeService.documentTypeName.get(data.type)}}
      </div>

      <label>Requested By</label>
      <div class="hf-form-static-value">
        {{data.documentRequest.requestedBy.fullName}}
      </div>

      <label>Requested From</label>
      <div class="hf-form-static-value">
        {{data.documentRequest.requestedFrom.fullName}}
      </div>

      <label>Message to Client</label>
      <div class="hf-form-static-value">
        {{data.documentRequest.messageToClient}}
      </div>

      <label>Upload on Behalf of Client</label>
      <div marginElement
           class="flex-space-between-layout"
           [style.width.%]="100">
        <hf-fileuploader controlName="attachment"
                         [group]="form"
                         [fileControl]="form.value['attachment']">
        </hf-fileuploader>
      </div>

    </form>
  </div>

  <div class="template-footer">
    <hf-custom-button type="submit"
                      class="button-primary"
                      label="Save Document"
                      [disabled]="form.invalid"
                      [isLoading]="isSubmitting"
                      (onClick)="onSubmit()">
    </hf-custom-button>

    <hf-custom-button type="button"
                      class="button-secondary"
                      label="Cancel"
                      (onClick)="onBack()">
    </hf-custom-button>

    <hf-custom-button type="button"
                      class="button-plain-icon delete-button keep-right"
                      icon="delete"
                      iconPos="center"
                      (onClick)="onRemove()">
    </hf-custom-button>
  </div>
</div>

<div class="sidepane-child-anchor">
  <router-outlet></router-outlet>
</div>
