import { AbstractControl, ValidatorFn } from "@angular/forms";

const regexp = /^[1237]\d{9}$/;

export function getUkPhoneValidator(prefixControlName?: string): ValidatorFn {
  const prefixControl = prefixControlName || "phonePrefix";

  return (control: AbstractControl) => {
    const prefix = control.parent?.get(prefixControl)?.value;
    const value: string = control.value

    if (!value || !prefix) {
      return null;
    }

    if (prefix !== "+44") {
      if ((value || "").length < 4) {
        return { ukPhoneValidator: true };
      } else {
        return null;
      }
    }

    return value.match(regexp)?.length
      ? null
      : { ukPhoneValidator: true }
  }
}
