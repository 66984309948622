import { SidepaneHelper, SidepaneHost } from "./sidepane-helper.class";

// TODO
// - Add warning if ngOnDestroy is declared in host sidepane OR save it and call it ?

export function Sidepane(): ClassDecorator {
  return function (target: Function) {
    target.prototype.ngOnDestroy = function() {
      const component: SidepaneHost = this;
      const sh: SidepaneHelper = component.sh;

      // console.log("target: ", this);

      if (sh && sh.destroy$) {
        sh.destroy$.next();
      }

      // console.log('ngOnDestroy:', target.name);
    };
  };
}
