<div class="header">
  <div class="heading">
    Upload Documents
    <div class="applicant">{{ getApplicantName(applicantData) }}</div>

    <div *ngIf="config?.updatedBy" class="saved-by">
      Saved by {{ config.updatedBy.fullName }} at {{config?.lastUpdated | date:'HH:mm on E d MMM y'}}
    </div>
  </div>

  <hfc-button *ngIf="config.buttonType" [type]="config.buttonType" [isLoading]="config.buttonIsLoading" size="small" (click)="buttonClick.next(config)">{{ config.buttonLabel }}</hfc-button>
</div>

<div *ngIf="config.returnComment" class="returnComment">
  {{config.returnComment}}
  <div class="lastUpdated">{{config.lastUpdated | date:'d MMM y, HH:mm'}}</div>
</div>

<div *ngIf="config.stepStatus == 'NOT_STARTED'" class="notReady">
  Waiting for applicant to complete
</div>

<div *ngIf="config.stepStatus !== 'NOT_STARTED'" class="content">
  <div class="thumbnails-container">
    <hfc-document-thumbnail *ngFor="let document of applicantData.personalDocuments" [document]="document"></hfc-document-thumbnail>
  </div>
</div>
