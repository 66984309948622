import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ReviewSectionState } from '../../../../../../shared/components/review-section/review-section.component';
import { DEPOSIT_SOURCE_TYPE_NAME, PRODUCT_TYPE_NAME, PROPERTY_BEDROOMS_NAME, PROPERTY_FLAT_TYPE_NAME, PROPERTY_HOUSE_TYPE_NAME, PROPERTY_PAYMENT_METHOD_NAME, PROPERTY_TENURE_NAME, PROPERTY_TYPE_NAME, REGION_TYPE_NAME, REPAYMENT_PLAN_MAP } from '../../../../../enums.module';
import { DepositSource, FactFindData, PropertyDetails, PropertyRemortgageIntentName } from '../../../../../services/fact-find-api.service';

@Component({
  selector: 'hfc-property-review-section',
  templateUrl: './property-review-section.component.html',
  styleUrls: ['./property-review-section.component.scss'],
  providers: [CurrencyPipe]
})
export class PropertyReviewSectionComponent implements OnChanges {
  @Input()
  public data: FactFindData;

  @Input()
  public config: ReviewSectionState;

  @Output()
  public buttonClick = new EventEmitter<ReviewSectionState>();

  public PRODUCT_TYPE_NAME = PRODUCT_TYPE_NAME;
  public PROPERTY_PAYMENT_METHOD_NAME = PROPERTY_PAYMENT_METHOD_NAME;
  public PROPERTY_TYPE_NAME = PROPERTY_TYPE_NAME;
  public PROPERTY_FLAT_TYPE_NAME = PROPERTY_FLAT_TYPE_NAME;
  public PROPERTY_TENURE_NAME = PROPERTY_TENURE_NAME;
  public PROPERTY_BEDROOMS_NAME = PROPERTY_BEDROOMS_NAME;
  public PROPERTY_HOUSE_TYPE_NAME = PROPERTY_HOUSE_TYPE_NAME;
  public DEPOSIT_SOURCE_TYPE_NAME = DEPOSIT_SOURCE_TYPE_NAME;
  public PropertyRemortgageIntentName = PropertyRemortgageIntentName;
  public REGION_TYPE_NAME = REGION_TYPE_NAME;

  public interestOnlyOptions: string;

  constructor(private currencyPipe: CurrencyPipe) { }

  public getDepositSourceValues(property: PropertyDetails) {
    const format = (source: DepositSource) => {
      return `${source.otherType || DEPOSIT_SOURCE_TYPE_NAME.get(source.type)}: ${this.currencyPipe.transform(source.amount, "GBP", undefined, "1.0-0")}`;
    }

    return property.depositSources.map(format).join("<br>");
  }

  public yesno(value: boolean) {
    return value ? "Yes" : "No";
  }

  public getToBorrowLabel() {
    switch (this.data.propertyDetails.propertyRemortgageIntent) {
      case "INCREASE_MORTGAGE":
        return "What would you like to increase your new mortgage to?";
      case "REDUCE_MORTGAGE":
        return "What would you like to reduce your motgage balance to?";
      case "LIKE_FOR_LIKE_REMORTGAGE":
        return "How much would you like to borrow?";
      default:
        return "How much would you like to borrow?";
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    if ("data" in changes && this.data.propertyDetails) {
      this.interestOnlyOptions = this.getInterestOnlyOptions();
    }
  }

  private getInterestOnlyOptions() {
    return Object.entries(this.data.propertyDetails.interestOnlyOptions)
      .filter(([k, v]) => v)
      .map(([k, v]) => REPAYMENT_PLAN_MAP.get(k))
      .join(", ");
  }

  public getInterestOnlyLabel() {
    if (this.data?.propertyDetails?.paymentMethod === "INTEREST_ONLY") return "Please indicate your mortgage repayment plan";
    if (this.data?.propertyDetails?.paymentMethod === "PART_AND_PART") return "Please indicate your mortgage repayment plan for the interest only part";
    return "";
  }
}
