<div *ngFor="let row of getBalanceRows(account.balances)" class="cd-balance-container">
  <div class="cd-balance-legend-desktop">
    <div>Month</div>
    <div>Balance (£)</div>
    <div>Status</div>
  </div>

  <ng-container *ngFor="let col of getBalanceCols(account.balances, row); index as i">
    <div *ngIf="(i % 3) === 0" class="cd-balance-legend">
      <div>Month</div>
      <div>Balance (£)</div>
      <div>Status</div>
    </div>

    <div class="cd-balance-month">
      <div>
        <span *ngIf="getBalanceTooltip(col)" [ngClass]="col.paymentChange" [hfTooltip]="getBalanceTooltip(col)">
          {{ col.monthNum }}
          <i class="material-icons"></i>
        </span>
        <span *ngIf="!getBalanceTooltip(col)" [ngClass]="col.paymentChange">
          {{ col.monthNum }}
        </span>
      </div>
      <div>{{ col.balance | currency:'GBP':'':'1.2-2' }}</div>
      <div *ngIf="col.status !== 'SATISFACTORY'">{{ CreditAccountBalanceStatusName.get(col.status) }}</div>
      <div *ngIf="col.status === 'SATISFACTORY'"><i class="material-icons">check</i></div>
    </div>

    <div *ngIf="(i % 3) === 2" class="cd-separator mobile"></div>
  </ng-container>

  <div class="cd-separator desktop"></div>
</div>
