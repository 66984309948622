<div class="overlay" [@fadeAnimation] [routerLink]="['./..']"></div>

<div class="sidepane-content">
  <div class="template-header flex-space-between-layout">
    <h1 class="title-area">Client Not Proceeding</h1>
    <hf-custom-button
      type="button"
      class="button-plain-icon"
      icon="close"
      iconPos="center"
      [routerLink]="['./..']"
    >
    </hf-custom-button>
  </div>

  <div class="template-main">
    <div [style.width.%]="100" class="padding-y">
      <form [formGroup]="mortgageNotProceedingForm" class="np-form">
        <!-- Not proceeding reasons list -->
        <div class="hf-selector">
          <label>Not Proceeding Reason</label>
          <div
            *ngIf="!notProceedingReason"
            class="hf-selector-container hf-selector-has-value"
            [routerLink]="['./selectNotProceedingReason']"
          >
            <div class="hf-selector-label-area">
              <div class="title">Select Not Proceeding Reason</div>
            </div>
            <i class="material-icons chevron-icon">chevron_right</i>
          </div>

          <ng-container *ngIf="notProceedingReason">
            <div
              class="hf-selector-container hf-selector-has-value hf-selector-black-color"
              [routerLink]="['./selectNotProceedingReason']"
            >
              <div class="hf-selector-content-area">
                <div class="title">
                  {{ notProceedingReason }}
                </div>
              </div>
              <i class="material-icons chevron-icon">chevron_right</i>
            </div>

            <div *ngIf="enabled('declinedReasonId')" class="ev-form-field">
              <div [hfFormField]="control('declinedReasonId')">
                <label>Declining Reason</label>
                <hf-selector-link
                  placeholder="Select Declining Reason"
                  [value]="value?._declinedReason?.other || value?._declinedReason?.followup?.displayName"
                  [isInvalid]="control('declinedReasonId').invalid && isSubmitted"
                  (click)="onSelectDecliningReasonClicked()"
                >
                </hf-selector-link>
              </div>
            </div>
            
          </ng-container>
        </div>

        <ng-container *ngIf="mortgageNotProceedingForm.get('reason').enabled">
          <label [style.width.%]="100">
            Reason (shared externally with Sales Advisor)
          </label>
          <hf-textarea
            [group]="mortgageNotProceedingForm"
            [inputFormControlName]="'reason'"
            [smaller]="true"
          ></hf-textarea>
        </ng-container>

        <label [style.width.%]="100" class="mt"> Private Notes </label>
        <hf-textarea
          [group]="mortgageNotProceedingForm.get('journal')"
          [inputFormControlName]="'internalNotes'"
          [smaller]="true"
        ></hf-textarea>

        <div
          marginElement
          class="flex-space-between-layout switch-toggle"
          style="width: 100%; margin-bottom: 8px"
        >
          <hf-custom-switch-toggle
            label="Notify Client"
            inputFormControlName="sendToClient"
            [checked]="false"
            [group]="mortgageNotProceedingForm.get('journal')"
          >
          </hf-custom-switch-toggle>
        </div>

        <hf-textarea
          *ngIf="
            mortgageNotProceedingForm.get('journal.messageToClient').enabled
          "
          [group]="mortgageNotProceedingForm.get('journal')"
          [inputFormControlName]="'messageToClient'"
          [smaller]="true"
          [placeholder]="'Message to Client'"
        ></hf-textarea>

        <div
          *ngIf="
            mortgageNotProceedingForm.get('journal.messageToClient').enabled
          "
          class="flex-space-between-layout"
          style="width: 100%; margin-bottom: 8px"
        >
          <hf-fileuploader
            controlName="attachment"
            [group]="mortgageNotProceedingForm.get('journal.connectedDocument')"
            [fileControl]="
              mortgageNotProceedingForm.get('journal.connectedDocument').value[
                'attachment'
              ]
            "
          >
          </hf-fileuploader>
        </div>
      </form>
    </div>
  </div>

  <div class="template-footer">
    <hf-custom-button
      type="submit"
      class="button-primary"
      label="Not Proceeding"
      [disabled]="mortgageNotProceedingForm.invalid"
      [callable]="true"
      [callableApi]="submitResponse"
      (onClick)="onSubmit()"
    >
    </hf-custom-button>

    <hf-custom-button
      type="button"
      class="button-secondary"
      label="Cancel"
      [routerLink]="['./..']"
    >
    </hf-custom-button>
  </div>
</div>

<div class="sidepane-child-anchor">
  <router-outlet></router-outlet>
</div>
