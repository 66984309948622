import { EventEmitter, Injectable } from '@angular/core';
import { Client } from '../../../models/client';

@Injectable()
export class SelectOrAddClientService {

  public add = new EventEmitter<Client>();
  public select = new EventEmitter<Client>();
  public client: Client;
}
