import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ReviewSectionState } from '../../../../../../shared/components/review-section/review-section.component';
import { yesno } from '../../../../../../shared/functions/yesno';
import { ASSET_TYPE_NAME, PROPERTY_ASSET_STATUS_NAME, PROPERTY_BEDROOMS_NAME, PROPERTY_FLAT_TYPE_NAME, PROPERTY_HOUSE_TYPE_NAME, PROPERTY_TENURE_NAME, PROPERTY_TYPE_NAME } from '../../../../../enums.module';
import { FactFindData } from '../../../../../services/fact-find-api.service';

@Component({
  selector: 'hfc-assets-review-section',
  templateUrl: './assets-review-section.component.html',
  styleUrls: ['./assets-review-section.component.scss']
})
export class AssetsReviewSectionComponent {
  @Input()
  public data: FactFindData;

  @Input()
  public config: ReviewSectionState;

  @Output()
  public buttonClick = new EventEmitter<ReviewSectionState>();

  public ASSET_TYPE_NAME = ASSET_TYPE_NAME;
  public PROPERTY_TYPE_NAME = PROPERTY_TYPE_NAME;
  public PROPERTY_FLAT_TYPE_NAME = PROPERTY_FLAT_TYPE_NAME;
  public PROPERTY_TENURE_NAME = PROPERTY_TENURE_NAME;
  public PROPERTY_BEDROOMS_NAME = PROPERTY_BEDROOMS_NAME;
  public PROPERTY_HOUSE_TYPE_NAME = PROPERTY_HOUSE_TYPE_NAME;
  public PROPERTY_ASSET_STATUS_NAME = PROPERTY_ASSET_STATUS_NAME;

  public yesno = yesno;
}
