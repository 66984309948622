<div class="heading">
  Applications
</div>

<table *ngIf="mortgage.applications?.length" class="table">
  <tr class="header">
    <td class="cell width-130"><span>Lender</span></td>
    <td class="cell"><span>Lender Ref</span></td>
    <td class="cell width-110 center"><span>Progression</span></td>
    <td class="cell width-130"><span>Loan Amount</span></td>
    <td class="cell width-150"><span>Submitted Date</span></td>
    <td class="cell width-130"><span>Offer Expiry</span></td>
    <td class="cell width-150"><span>Status</span></td>
  </tr>

  <tbody *ngFor="let application of mortgage.applications" class="tbody">
      <tr class="row">
        <td data-mobileTitle="Lender" class="cell width-130 no-padding">
          <span *ngIf="!application.lenderLogo?.filename" class="lender-name" [hfTooltip]="application.lender">{{ application.lender }}</span>
          <hf-attachment-image *ngIf="application.lenderLogo?.filename" [attachment]="application.lenderLogo" [hfTooltip]="application.lender" class="provider-logo"></hf-attachment-image>
        </td>
        <td data-mobileTitle="Lender Ref" class="cell"><span>{{ application.lenderReference }}</span></td>
        <td data-mobileTitle="Progression" class="cell avatar width-110">
          <hf-avatar [photo]="application.caseProgressionPhoto" [hfTooltip]="getCaseProgressionTooltip(application)"></hf-avatar>
        </td>
        <td data-mobileTitle="Loan Amount" class="cell width-130"><span>{{ application.loanAmount | currency:'GBP':undefined:'1.0-0' }}</span></td>
        <td data-mobileTitle="Submitted Date" class="cell width-150"><span>{{ application.submittedDate | date:dateFormat }}</span></td>
        <td data-mobileTitle="Offer Expiry" class="cell width-130"><span>{{ application.offerExpiryDate | date:dateFormat }}</span></td>
        <td data-mobileTitle="Status" class="cell width-150">
          <hf-application-status-badge [state]="application.state" [isClickable]="true" (click)="statusClick.emit(application)"></hf-application-status-badge>
        </td>
      </tr>
  </tbody>
</table>

<div *ngIf="!mortgage.applications?.length" class="placeholder" [class.canCreateApplication]="mortgage.canCreateApplication">
  <div *ngIf="mortgage.canCreateApplication">
    Your applications will appear here. You can create an application now that the client sheet is approved.
  </div>
  <div *ngIf="!mortgage.canCreateApplication">
    Your applications will appear here. You can create an application once the client sheet is approved.
  </div>
</div>

<div class="buttons">
  <hfc-button *ngIf="mortgage.canCreateApplication" (click)="createClick.emit()" type="tertiary" class="create-button">
    Create Application
  </hfc-button>
</div>
