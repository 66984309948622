import { Component, Input } from '@angular/core';

@Component({
  selector: 'hf-export-button',
  templateUrl: './export-button.component.html',
  styleUrls: ['./export-button.component.scss']
})
export class ExportButtonComponent {
  @Input()
  public label: string;
}
