import { Component, Input } from '@angular/core';
import { FactFindData } from '../../../../../services/fact-find-api.service';
import { Environment } from '../../../../../../../../../../src/app/website/services/environment.service';
import { Attachment } from '../../../../../../../../../../src/app/website/models/attachment';

@Component({
  selector: 'hfc-readonly-signature-review-section',
  templateUrl: './readonly-signature-review-section.component.html',
  styleUrls: ['./readonly-signature-review-section.component.scss']
})
export class ReadonlySignatureReviewSectionComponent {
  @Input()
  public data: FactFindData;

  public hasSignature() { return this.data && this.data.applicants.some((a: any) => a.signed || a.signedByHeron) }

  public getSignatureUrl(signature: Attachment) {
    const url = `${this.environment.apiUrl}servlet/gcs/${signature.folder}/${signature.filename}`;

    return url;
  }

  constructor(
    private environment: Environment
  ) { }
}
