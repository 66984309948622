<button [ngStyle]="style"
        [ngClass]="{
                   'sm-button-padding':(sm),
                   'icon-only':(icon && !label) || icon,
                   'sm-icon-only':(icon && !label && sm),
                   'isLoading': isLoading
        }"
        [type]="type"
        [class]="class"
        (click)="onButtonClick($event)"
        (submit)="onButtonClick($event)"
        [disabled]="disabled"
        class="btn"
>

  <div *ngIf="!isLoading"
       [ngClass]="{'align-btn':(icon && !sm),
                   'sm-align-btn':(icon && sm),
                   'align-btn-center':(!icon && !sm),
                   'sm-align-btn-center':(!icon && sm),
                   'mat-icon-right':(iconPos === 'right'),
                   'align-center':(textPos === 'center' && iconPos === 'left') ||
                    (textPos === 'right' && iconPos === 'left')}">

    <i *ngIf="icon && iconPos" class="material-icons">{{icon}}</i>
    <span [ngClass]="{
    'show-btn':(label && icon),
    'center-text-left':(textPos === 'left' && iconPos === 'right'),
    'center-left-icon-text':(textPos === 'center' && iconPos === 'left'),
    'sm-center-left-icon-text':(textPos === 'center' && iconPos === 'left' && sm),
    'center-right-icon-text':(textPos === 'center' && iconPos === 'right'),
    'padding-left-text':(textPos === 'left' && iconPos === 'left'),
    'padding-right-text':(textPos === 'right' && iconPos === 'right'),
    'sm-center-right-icon-text':(textPos === 'center' && iconPos === 'right' && sm),
    'sm-center-text':(textPos==='center' && iconPos === 'left' || iconPos === 'right') && sm
    }">{{label}}</span>
  </div>
  <hf-custom-spinner *ngIf="isLoading"
                     [darkTheme]="darkTheme"
                     [saveButton]="true">
  </hf-custom-spinner>
</button>
