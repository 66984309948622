<div class="header">
  <div class="heading">
    Dependants

    <div *ngIf="config?.updatedBy" class="saved-by">
      Saved by {{ config.updatedBy.fullName }} at {{config?.lastUpdated | date:'HH:mm on E d MMM y'}}
    </div>
  </div>

  <hfc-button *ngIf="config.buttonType" [type]="config.buttonType" [isLoading]="config.buttonIsLoading" size="small" (click)="buttonClick.next(config)">{{ config.buttonLabel }}</hfc-button>
</div>

<div *ngIf="config.returnComment" class="returnComment">
  {{config.returnComment}}
  <div class="lastUpdated">{{config.lastUpdated | date:'d MMM y, HH:mm'}}</div>
</div>

<div *ngIf="config.stepStatus == 'NOT_STARTED'" class="notReady">
  Waiting for applicant to complete
</div>

<div *ngIf="config.stepStatus !== 'NOT_STARTED'" class="content">
  <ng-container *ngFor="let dependant of data.dependants">
    <div class="header-2">{{ dependant.fullName + ', ' + (dependant.otherType || DEPENDANT_TYPE_NAME.get(dependant.type)) }}</div>

    <div class="column">
      <div class="record">
        <span class="label">Date of Birth</span>
        <span class="value">{{ dependant.birthDate | date:'d MMM y' }}</span>
      </div>
    </div>

    <div class="column">
      <div class="record">
        <span class="label">Gender</span>
        <span class="value">{{ dependant.otherGender || DEPENDANT_GENDER_NAME.get(dependant.gender) }}</span>
      </div>
    </div>
  </ng-container>

  <div *ngIf="!data.dependants?.length" class="empty">
    There are no dependants
  </div>
</div>
