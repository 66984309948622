import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { UserSimpleDTO } from '../../../models/user-simple.dto';
import { fadeAnimation, sidepaneMove } from '../../../shared/animations/sidepane-animation';

export abstract class SharedSelectApplicantService {
  abstract getApplicants(id?: string): Promise<UserSimpleDTO[]>;
  initial?: UserSimpleDTO[];
}

export class ApplicantSelected {
  static readonly type = "[SelectApplicantComponent] Applicant Selected";
  constructor(public applicants: UserSimpleDTO[]) { }
}

@Component({
  selector: 'hf-select-applicant',
  templateUrl: './select-applicant.component.html',
  styleUrls: [
    './select-applicant.component.scss',
    '../../../styles/sidepanes.partial.scss'
  ],
  animations: [sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove]': 'true' }
})
export class SelectApplicantComponent {
  public applicants: UserSimpleDTO[];
  public isLoading = true;
  public compareFn = (a1: UserSimpleDTO, a2: UserSimpleDTO) => a1.id === a2.id;

  public form: FormGroup = new FormGroup({
    applicant: new FormControl(null, Validators.required)
  });

  private get saveAction() { return this.route.snapshot.data.saveAction }
  private get id() { return this.route.snapshot.paramMap.get("id") }

  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private selectApplicantService: SharedSelectApplicantService
  ) {
    this.initialize();
  }

  private async initialize() {
    this.isLoading = true;

    this.applicants = await this.selectApplicantService.getApplicants(this.id);
    if (this.selectApplicantService.initial) {
      this.form.patchValue({ applicant: this.selectApplicantService.initial });
    }

    this.isLoading = false;
  }

  public onSubmit() {
    const applicants = this.form.get("applicant").value;

    this.store.dispatch(new ApplicantSelected(applicants));

    this.onBack();
  }

  public onBack() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }
}
