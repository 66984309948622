<div class="dropdown flex-space-between-layout"
     [class.active-dropdown]="menu.visible===true"
     (click)="onClickDropdown($event,menu)"
     #dropdown>
  <div class="dropdown-style">
    <hf-avatar [photo]="user.photo"></hf-avatar>

    <div class="flex-col p-text-dropdown">
      <span class="title">{{user.fullName}}</span>
      <span class="role">{{USER_TYPE_NAMES.get(user.userType)}}</span>
    </div>
  </div>
  <button class="align-items-center"
          [ngClass]="{'active-dropdown':menu.visible===true}">
    <i class="material-icons">arrow_drop_down</i>
  </button>
  <p-menu appendTo="body"
          [showTransitionOptions]="'100ms'"
          [hideTransitionOptions]="'0ms'"
          [model]="user.items"
          [popup]="true"
          styleClass="dropdown-override"
          #menu>
  </p-menu>
</div>


