import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { Lender } from '../../../models/lender';
import { MortgageApiService, ApplicantIncome, NetMonthlyIncome, ComplianceHandoverData } from '../../../services/mortgage-api.service';
import { fadeAnimation, sidepaneMove2Cols } from '../../../shared/animations/sidepane-animation';
import { MORTGAGE_OBJECTIVE_NAME, MORTGAGE_PRODUCT_DATA_TYPE_NAME, MORTGAGE_PRODUCT_END_TYPE_NAME, DEBT_CONSOLIDATION_EXPLANATION_NAME, MORTGAGE_PRODUCT_END_TYPE, MORTGAGE_OBJECTIVE, DEBT_CONSOLIDATION_EXPLANATION } from '../../../utils/variables.data';
import { MonthlyIncomeService } from '../monthly-income/monthly-income.service';
import { MortgagesService, ReloadMortgageDashboardEvent } from '../mortgages.service';
import { SelectedObjective, SelectObjectivesService } from '../select-objectives/select-objectives.service';
import { SelectDebtConsolidationService, SelectedDebtConsolidation } from '../select-debt-consolidation/select-debt-consolidation.service';
import { ToasterService } from '../../../services/toaster.service';
import { FileControlsConfigBuilder } from '../../../shared/fileuploader/utils/fileuploader.utils';
import { setControlEnabled } from '../../../utils/functions/set-control-enabled';

const productTransferObjectives: MORTGAGE_OBJECTIVE[] = ["RESI_PT", "BTL_PT", "FA", "PORT_FA"];

@Component({
  selector: 'hf-compliance-handover',
  templateUrl: './compliance-handover.component.html',
  styleUrls: [
    './compliance-handover.component.scss',
    '../../../styles/sidepanes.partial.scss'
  ],
  animations: [sidepaneMove2Cols, fadeAnimation],
  host: { '[@sidepaneMove2Cols]': 'true' }
})
export class ComplianceHandoverComponent implements OnDestroy {
  public form = this.fb.group({
    currentSituation: ["", Validators.required],
    objective: [null, Validators.required],
    otherObjective: ["", Validators.required],
    clientMortgageNeed: ["", Validators.required],
    choiceOfLenderExplanation: ["", Validators.required],
    incomeCalculationExplanation: ["", Validators.required],
    feesToLoan: [null, Validators.required],
    mortgageBeyondRetirement: [null, Validators.required],
    debtConsolidation: [null, Validators.required],
    debtConsolidationExplanation: [null, Validators.required],
    otherDebtConsolidationExplanation: ["", Validators.required],
    interestOnly: [null, Validators.required],
    interestOnlyExplanation: [{ value: "", disabled: true }, Validators.required],
    knownIncomeChanges: [null, Validators.required],
    knownIncomeChangesExplanation: ["", Validators.required],
    lender: [null, Validators.required],
    loanAmount: [null, Validators.required],
    type: [null, Validators.required],
    mortgageTerm: this.fb.group({
      years: [null, [Validators.required, Validators.min(0)]],
      months: [null, [Validators.required, Validators.min(0), Validators.max(11)]]
    }),
    rate: [null, Validators.required],
    productCode: [null, Validators.required],
    endDateType: [null, Validators.required],
    endDate: [{ value: null, disabled: true }, Validators.required],
    endYearsMonths: this.fb.group({
      years: [null, [Validators.required, Validators.min(0)]],
      months: [null, [Validators.required, Validators.min(0), Validators.max(11)]]
    }),
    netMonthlyIncomes: [null, Validators.required],
    kfi: this.fb.group(
      new FileControlsConfigBuilder()
        .maxFileSizeDisk()
        .required("")
        .build()),
    recommendationEvidence: this.fb.group(
      new FileControlsConfigBuilder()
        .maxFileSizeDisk()
        .required("")
        .build()),
    applicantsIncomes: [null],
    reasonForChange: [null, Validators.required],
    currentType: [null, Validators.required],
    currentMortgageTerm: this.fb.group({
      years: [null, [Validators.required, Validators.min(0)]],
      months: [null, [Validators.required, Validators.min(0), Validators.max(11)]]
    }),
    currentRate: [null, Validators.required],
  });

  public MORTGAGE_OBJECTIVE_NAME = MORTGAGE_OBJECTIVE_NAME;
  public MORTGAGE_PRODUCT_DATA_TYPE_NAME = MORTGAGE_PRODUCT_DATA_TYPE_NAME;
  public MORTGAGE_PRODUCT_END_TYPE_NAME = MORTGAGE_PRODUCT_END_TYPE_NAME;
  public DEBT_CONSOLIDATION_EXPLANATION_NAME = DEBT_CONSOLIDATION_EXPLANATION_NAME;

  public get mortgageId() { return this.activatedRoute.snapshot.paramMap.get("id") }
  public get handoverId() { return this.activatedRoute.snapshot.paramMap.get("handoverId") }
  public get isEdit() { return !!this.handoverId }
  public get isCurrentVisible() { return (!this.value.objective && this.mortgagesService.data?.clientCategory === "PRODUCT_TRANSFER") || productTransferObjectives.includes(this.value.objective) }
  public isLoading = true;
  public isSubmitting = false;

  private get value() { return this.form.value as ComplianceHandoverData }
  private destroy$ = new Subject();

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute,
    private mortgagesService: MortgagesService,
    private mortgageApiService: MortgageApiService,
    private toasterService: ToasterService,
    private selectObjectivesService: SelectObjectivesService,
    private monthlyIncomeService: MonthlyIncomeService,
    private selectDebtConsolidationService: SelectDebtConsolidationService
  ) {
    this.setupForm();
    this.initialize();
  }

  public getApplicantName(applicantId: string) {
    const applicantsIncomes = this.form.get("applicantsIncomes").value as ApplicantIncome[];
    const applicantIncome = applicantsIncomes.find(a => a.applicant.id == applicantId)

    return applicantIncome && applicantIncome.applicant.fullName;
  }

  public async initialize() {
    const data = await this.mortgageApiService.viewClientSheet(this.mortgageId, this.handoverId);

    this.form.reset(data);

    if (this.mortgagesService.data.id && !this.mortgagesService.data.clientSheetAvailable) {
      const complianceHandover = { ...this.mortgagesService.userStore.complianceHandover };

      if (complianceHandover && complianceHandover.mortgageId == this.mortgageId) {
        delete complianceHandover.applicantsIncomes;
        this.form.patchValue(complianceHandover);
      }
    }

    this.isLoading = false;

    const applicantsIncomes = this.form.get("applicantsIncomes").value;
    this.monthlyIncomeService.applicantsIncomes$.next(applicantsIncomes);
  }

  public async onSubmit() {
    const value = this.form.value;
    this.isSubmitting = true;

    try {
      const response = this.isEdit
        ? await this.mortgageApiService.editComplianceHandover(this.mortgageId, this.handoverId, value)
        : await this.mortgageApiService.createComplianceHandover(this.mortgageId, value);

      const event: ReloadMortgageDashboardEvent = { tab: "client-sheet", handover: response.handover };

      this.toasterService.callToaster({ severity: 'info', summary: 'Info', detail: 'Compliance Handover saved.' });
      this.mortgagesService.reloadMortgageDashboard.emit(event);
      delete this.mortgagesService.userStore.complianceHandover;

      this.onBack();
    } catch (err) {
      this.toasterService.callToaster({ severity: 'error', summary: 'Error', detail: `Failed. Please try again. ${err.error.error.message}` });
    } finally {
      this.isSubmitting = false;
    }
  }

  public onSelectObjectivesClicked() {
    const { objective, otherObjective } = this.value;
    const selectedObjective: SelectedObjective = { objective, otherObjective };

    this.selectObjectivesService.setObjective(selectedObjective);

    this.router.navigate(["selectObjectives"], { relativeTo: this.route });
  }

  public onSelectLenderClicked() {
    const { lender } = this.value;

    this.mortgagesService.lenderSelected$.next(lender);

    this.router.navigate(["selectLender"], { relativeTo: this.route });
  }

  public onMonthlyIncomeClicked() {
    const netMonthlyIncomes: NetMonthlyIncome[] = this.form.get("netMonthlyIncomes").value;

    this.monthlyIncomeService.netMonthlyIncomes$.next(netMonthlyIncomes);

    this.router.navigate(["monthlyIncome"], { relativeTo: this.route });
  }

  public onSelectDebtConsolidationClicked() {
    const { debtConsolidationExplanation, otherDebtConsolidationExplanation } = this.value;
    const selectedDebtConsolidation: SelectedDebtConsolidation = { debtConsolidationExplanation, otherDebtConsolidationExplanation };

    this.selectDebtConsolidationService.setDebtConsolidation(selectedDebtConsolidation);

    this.router.navigate(["selectDebtConsolidation"], { relativeTo: this.route });
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }

  public onBack() {
    this.mortgagesService.userStore.complianceHandover = { mortgageId: this.mortgageId, ...this.value };

    const path = this.isEdit
      ? ["../.."]
      : [".."];

    this.router.navigate(path, { relativeTo: this.route });
  }

  private setupProductTransferFields() {
    setControlEnabled(this.form.get("currentType"), this.isCurrentVisible);
    setControlEnabled(this.form.get("currentMortgageTerm"), this.isCurrentVisible);
    setControlEnabled(this.form.get("currentRate"), this.isCurrentVisible);
  }

  private setupForm() {
    this.form.get("endYearsMonths").disable();

    this.mortgagesService.mortgageDashboardDataLoaded.pipe(take(1)).subscribe(() => {
      this.setupProductTransferFields();
    })

    this.selectObjectivesService.objective$.pipe(takeUntil(this.destroy$)).subscribe((objective: SelectedObjective) => {
      objective && this.form.patchValue(objective);
    });

    this.mortgagesService.lenderSelected$.pipe(takeUntil(this.destroy$)).subscribe((lender: Lender) => {
      lender && this.form.get("lender").setValue(lender);
    });

    this.monthlyIncomeService.netMonthlyIncomes$.pipe(takeUntil(this.destroy$)).subscribe(netMonthlyIncomes => {
      if (netMonthlyIncomes !== this.form.get("netMonthlyIncomes").value) {
        this.form.get("netMonthlyIncomes").setValue(netMonthlyIncomes);
      }
    });

    this.selectDebtConsolidationService.debtConsolidation$.pipe(takeUntil(this.destroy$)).subscribe(debtConsolidation => {
      debtConsolidation && this.form.patchValue(debtConsolidation);
    })

    this.form.get("objective").valueChanges.subscribe((objective: MORTGAGE_OBJECTIVE) => {
      if (objective === "OTHER") {
        this.form.get("otherObjective").enable();
      } else {
        this.form.get("otherObjective").disable();
      }

      this.setupProductTransferFields();
    })

    this.form.get('debtConsolidation').valueChanges.subscribe(debtConsolidation => {
      if (debtConsolidation) {
        this.form.get("debtConsolidationExplanation").enable();
        this.form.get("otherDebtConsolidationExplanation").enable();
      } else {
        this.form.get("debtConsolidationExplanation").disable();
        this.form.get("otherDebtConsolidationExplanation").disable();
      }
    })

    this.form.get("debtConsolidationExplanation").valueChanges.subscribe((debtConsolidationExplanation: DEBT_CONSOLIDATION_EXPLANATION) => {
      if (debtConsolidationExplanation === "OTHER") {
        this.form.get("otherDebtConsolidationExplanation").enable();
      } else {
        this.form.get("otherDebtConsolidationExplanation").disable();
      }
    })

    this.form.get('interestOnly').valueChanges.subscribe(interestOnly => {
      if (interestOnly) {
        this.form.get("interestOnlyExplanation").enable();
      } else {
        this.form.get("interestOnlyExplanation").disable();
      }
    })

    this.form.get('knownIncomeChanges').valueChanges.subscribe(knownIncomeChanges => {
      if (knownIncomeChanges) {
        this.form.get("knownIncomeChangesExplanation").enable();
      } else {
        this.form.get("knownIncomeChangesExplanation").disable();
      }
    })

    this.form.get('endDateType').valueChanges.subscribe((endDateType: MORTGAGE_PRODUCT_END_TYPE) => {
      if (endDateType === "DATE") {
        this.form.get("endDate").enable();
        this.form.get("endYearsMonths").disable();
      } else {
        this.form.get("endDate").disable();
        this.form.get("endYearsMonths").enable();
      }
    })

    setControlEnabled(this.form.get("reasonForChange"), this.isEdit);
  }
}
