import { Component, Input, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { expandAnimation } from '../../../../../projects/client/src/app/shared/animations/expand.animation';
import { DocumentApiService } from '../../services/document-api.service';
import { EmailJournalEventDTO, JournalApiService } from '../../services/journal-api.service';
import { EmailEventType, EmailEventTypeIcon, EmailEventTypeName, EmailJournalState, EmailJournalStateIcon, EmailJournalStateName } from '../../utils/journal.enum';

@Component({
  selector: 'hf-email-events',
  templateUrl: './email-events.component.html',
  styleUrls: ['./email-events.component.scss'],
  animations: [expandAnimation],
})
export class EmailEventsComponent implements OnDestroy {
  @Input()
  public email: any;

  public events: EmailJournalEventDTO[];
  public EmailJournalStateName = EmailJournalStateName;
  public EmailEventTypeName = EmailEventTypeName;
  public isOpen = false;
  public initialized = false;
  public isLoading = false;

  private destroy$ = new Subject();

  constructor(
    private journalApiService: JournalApiService,
  ) { }

  public getIcon(type: EmailEventType) {
    return EmailEventTypeIcon.get(type) || EmailEventTypeIcon.get("");
  }

  public getOverallIcon(state: EmailJournalState) {
    return EmailJournalStateIcon.get(state) || EmailJournalStateIcon.get("");
  }

  public onHeaderClicked() {
    if (this.isOpen) {
      this.isOpen = false;
      return;
    }

    if (this.initialized) {
      this.isOpen = true;
    } else {
      this.initialize();
    }
  }

  private initialize() {
    this.initialized = true;
    this.isLoading = true;

    this.journalApiService.loadEmailEvents(this.email.id).pipe(takeUntil(this.destroy$)).subscribe(events => {
      this.events = events.items;
      this.isLoading = false;
      this.isOpen = true;
    });
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }
}
