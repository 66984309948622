import { Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToastService } from '../../../../../../projects/client/src/app/shared/services/toast.service';
import { PropCode } from '../../../models/new-compliance-handover-recommended.dto';
import { MortgageApiService } from '../../../services/mortgage-api.service';
import { fadeAnimation, sidepaneMove } from '../../../shared/animations/sidepane-animation';
import { NewComplianceHandoverRecommendedComponent } from '../new-compliance-handover/new-compliance-handover-recommended/new-compliance-handover-recommended.component';
import { NewComplianceHandoverComponent } from '../new-compliance-handover/new-compliance-handover.component';
import { SelectPropcoService } from './select-propco-code.service';

@Component({
  selector: 'hf-select-lender',
  templateUrl: './select-propco-code.component.html',
  styleUrls: [
    './select-propco-code.component.scss',
    '../../../styles/sidepanes.partial.scss'
  ],
  animations: [sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove]': 'true' }
})
export class SelectPropcoCodeComponent implements OnDestroy {
  public isLoading = true;
  public codes: PropCode[];

  public get lenderId() { return this.route.snapshot.queryParamMap.get("lenderId") }

  public form: FormGroup = new FormGroup({
    code: new FormControl()
  });

  public compareFn = (code1: PropCode, code2: PropCode): boolean =>
    !!(code1 && code2 && code1.id === code2.id);

  private destroy$ = new Subject();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private mortgageApiService: MortgageApiService,
    private toast: ToastService,
    private selectPropcoService: SelectPropcoService,
  ) {
    this.initialize();
  }

  public initialize() {
    this.isLoading = true;

    this.mortgageApiService.getPropCodes(this.lenderId).subscribe(
      result => {
        this.codes = result.items;
        this.isLoading = false;
      },
      e => {
        this.toast.add("Failed", "error", e);
        this.isLoading = false;
      }
    );

    this.form.get("code").valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(code => {
        this.selectPropcoService.code$.next(code);
        this.onBack();
      });
  }

  public async ngOnDestroy() {
    this.destroy$.next();
  }

  public onBack() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }
}
