import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {Route, RouterModule} from '@angular/router';
import {ClientSharedModule} from '../../../../../projects/client/src/app/shared/client-shared.module';
import {SharedLayoutModule} from '../../shared-layout/shared-layout.module';
import {AddTaskComponent} from '../../shared-layout/side-panes/add-task/add-task.component';
import {SelectUserComponent} from '../../shared-layout/side-panes/select-user/select-user.component';
import {SelectorSidepaneComponent, SelectorSidepaneConfig} from '../../shared-layout/side-panes/selector-sidepane/selector-sidepane.component';
import {CustomButtonModule} from '../../shared/custom-button/custom-button.module';
import {CustomSelectorModule} from '../../shared/custom-selector/custom-selector.module';
import {CustomSpinnerModule} from '../../shared/custom-spinner/custom-spinner.module';
import {CustomTextFieldModule} from '../../shared/custom-text-field/custom-text-field.module';
import {CustomTextAreaModule} from '../../shared/custom-textarea/custom-text-area.module';
import {DatePickerModule} from '../../shared/date-picker/date-picker.module';
import {EvergladesSharedMorule} from '../../shared/everglades-shared.module';
import {HfCheckboxModule} from '../../shared/hf-checkbox-component/hf-checkbox.module';
import {ItemsPerPageSelectorModule} from '../../shared/item-per-page-selector/items-per-page-selector.module';
import {PaginationSummaryBoxModule} from '../../shared/pagination-summary-box/pagination-summary-box.module';
import {PaginatorModule} from '../../shared/paginator/paginator.module';
import {SharedModule} from '../../shared/shared.module';
import {SelectApplicationLenderComponent, SelectApplicationLenderConfig} from '../mortgages/select-application-lender/select-application-lender.component';
import {SelectDateAndTimeComponent} from '../mortgages/select-date-and-time/select-date-and-time.component';
import {SelectDateAndTimeModule} from '../mortgages/select-date-and-time/select-date-and-time.module';
import {UserModule} from '../user/user.module';
import {DeleteTaskComponent} from './components/delete-task/delete-task.component';
import {ReassignTaskComponent} from './components/reassign-task/reassign-task.component';
import {TaskListComponent, teamsForFilter} from './components/task-list/task-list.component';
import {ViewTaskComponent, ViewTaskConfig} from './components/view-task/view-task.component';
import {TaskApiService} from './task-api.service';
import {TaskService} from './task.service';

export const routes: Route[] = [
  {
    path: "",
    component: TaskListComponent,
    children: [
      {
        path: "viewTask/:taskId",
        component: ViewTaskComponent,
        data: {
          hasViewDetailButton: true,
        } as ViewTaskConfig,
        children: [
          {
            path: "edit",
            component: AddTaskComponent,
            children: [
              {
                path: "selectUser",
                component: SelectUserComponent
              },
              {
                path: "selectDateAndTime",
                component: SelectDateAndTimeComponent
              },
              {
                path: "selectApplication",
                component: SelectApplicationLenderComponent,
                data: {
                  hasNoneOption: true,
                  source: "service"
                } as SelectApplicationLenderConfig
              },
            ]
          },
          {
            path: "delete",
            component: DeleteTaskComponent
          },
          {
            path: "reassign",
            component: ReassignTaskComponent
          }
        ]
      },
      {
        path: "createTask",
        component: AddTaskComponent,
        children: [
          {
            path: "selectUser",
            component: SelectUserComponent
          },
          {
            path: "selectDateAndTime",
            component: SelectDateAndTimeComponent
          }
        ]
      },
      {
        path: "selectTeams",
        component: SelectorSidepaneComponent,
        data: {
          config: {
            heading: "Select Teams",
            key: "selectTeams",
            map: teamsForFilter,
            multiple: true,
          } as SelectorSidepaneConfig
        }
      },
      {
        path: "selectAssignee",
        component: SelectUserComponent,
      },
    ]
  }
];

@NgModule({
  declarations: [
    TaskListComponent,
    ViewTaskComponent,
    DeleteTaskComponent,
    ReassignTaskComponent,
  ],
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    EvergladesSharedMorule,
    SharedLayoutModule,
    ClientSharedModule,
    CustomButtonModule,
    CustomTextFieldModule,
    CustomTextAreaModule,
    ItemsPerPageSelectorModule,
    PaginatorModule,
    PaginationSummaryBoxModule,
    HfCheckboxModule,
    DatePickerModule,
    SelectDateAndTimeModule,
    CustomSpinnerModule,
    CustomSelectorModule,

    UserModule,
  ],
  providers: [
    TaskApiService,
    TaskService
  ]
})
export class TaskModule { }
