import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavButtonComponent } from './nav-button.component';
import { CustomButtonModule } from '../custom-button/custom-button.module';

@NgModule({
  declarations: [NavButtonComponent],
  imports: [
    CommonModule,
    CustomButtonModule
  ],
  exports: [NavButtonComponent]
})
export class NavButtonModule { }
