import { Component } from '@angular/core';
import { PAGE_SIZES } from '../../utils/variables.data';
import { TableSettingsService } from '../table-utils/table-settings.service';

@Component({
  selector: 'hf-item-per-page-selector',
  templateUrl: 'item-per-page-selector.component.html',
  styleUrls: ['item-per-page-selector.component.scss']
})

export class ItemPerPageSelectorComponent {
  public PAGE_SIZES = PAGE_SIZES;

  public get pageSize() { return this.tableSettingsService.pageSize; }

  public pageSizeClicked(pageSize: number) {
    this.tableSettingsService.pageSizeRequest(pageSize);
  }

  constructor(
    public tableSettingsService: TableSettingsService
  ) { }
}
