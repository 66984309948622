import {Injectable} from "@angular/core";
import {Subject} from "rxjs";
import {PresetRangeFilter} from "../../../modules/dashboard-charts/services/dashboard-charts.service";

export interface RangeFilter {
  preset: PresetRangeFilter;
  from?: Date;
  to?: Date;
}

@Injectable()
export class SelectDateRangeService {
  public range$ = new Subject<RangeFilter>();

  public initial: RangeFilter;
}
