import { EMPLOYMENT_STATUS, PERSONAL_TITLE } from "../../../../projects/client/src/app/fact-find/enums.module"
import { UserSelectorDTO } from "./user"

export type GenderNoOther =
  | "MALE"
  | "FEMALE"

export const GenderNoOtherName = new Map<GenderNoOther, string>([
  ["MALE", "Male"],
  ["FEMALE", "Female"],
])

export type MetricType =
| "IMPERIAL"
| "METRIC"

export const MetricTypeName = new Map<MetricType, string>([
  ["IMPERIAL", "Feet & Stone"],
  ["METRIC", "Centimetres & Kilograms"],
])

export interface ApplicantPrerequisitesAddDTO {
  client: UserSelectorDTO;
  title: PERSONAL_TITLE;
  gender: GenderNoOther;
  smokerLastYear: boolean;
  dateOfBirth: Date;
  metricType: MetricType;
  heightFeet: number;
  heightInches: number;
  heightCm: number;
  weightStone: number;
  weightPounds: number;
  weightKg: number;
  employmentStatus: EMPLOYMENT_STATUS;
  origoCode: string;
  annualIncome: number;
  lead?: boolean;
}
