<div (click)="onBack()" class="overlay" [@fadeAnimation]></div>

<div class="sidepane-content">
  <div class="flex-space-between-layout template-header">
    <h1>Select Objectives</h1>
    <hf-custom-button type="button" class="button-plain-icon" icon="close" iconPos="center" (onClick)="onBack()">
    </hf-custom-button>
  </div>

  <div class="template-main">
    <form [formGroup]="form">
      <hf-custom-selector formControlName="objective"
                          [items]="objectives">
        <ng-template #itemTemplate let-item>
          <div>
            {{ MORTGAGE_OBJECTIVE_NAME.get(item) }}
            <div *ngIf="isCapRaising(item)" class="cap-raising">+ Cap Raising</div>
          </div>
        </ng-template>
      </hf-custom-selector>
      <hf-custom-text-field *ngIf="form.get('objective').value === 'OTHER'" [group]="form" inputFormControlName="otherObjective"></hf-custom-text-field>
    </form>
  </div>

  <div *ngIf="form.get('objective').value === 'OTHER'" class="template-footer">
    <hfc-button type="primary" (click)="onSubmit()">Save</hfc-button>
    <hfc-button type="secondary" (click)="onBack()">Cancel</hfc-button>
  </div>
</div>
