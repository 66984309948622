import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ReviewSectionState } from '../../../../../../shared/components/review-section/review-section.component';
import { APPLICATION_STEP } from '../../../../../enums.module';
import { ApplicantData, FactFindData } from '../../../../../services/fact-find-api.service';

@Component({
  selector: 'hfc-sign-off-content',
  templateUrl: './sign-off-content.component.html',
  styleUrls: ['./sign-off-content.component.scss', '../../../../../../protection-fact-find/steps/sign-off-component/sign-off.component.shared.scss']
})
export class SignOffContentComponent {
  @Input()
  public data: FactFindData;

  @Input()
  public commonSectionConfigs: Record<APPLICATION_STEP, ReviewSectionState>

  @Input()
  public applicantSectionConfigs: Record<APPLICATION_STEP, Record<string, ReviewSectionState>>;

  @Output()
  public buttonClick = new EventEmitter<ReviewSectionState>();

  public getConfig(step: APPLICATION_STEP, applicantData?: ApplicantData) {
    if (applicantData) {

      const applicantId = applicantData.applicant.client.id;

      return this.applicantSectionConfigs[step][applicantId];
    }

    return this.commonSectionConfigs[step];
  }

  public onButtonClicked(config: ReviewSectionState) {
    this.buttonClick.next(config);
  }
}
