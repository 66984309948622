import { Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { fadeAnimation, sidepaneMove } from '../../../shared/animations/sidepane-animation';
import { SelectorSidepaneItemSelected, SelectorSidepaneService } from './selector-sidepane.service';

export interface SelectorSidepaneConfig {
  heading: string;
  key: string;
  map: Map<string, string>;
  multiple?: boolean;
  other?: boolean;
  otherResolver?: (any) => boolean;
}

@Component({
  selector: 'hf-selector-sidepane',
  templateUrl: './selector-sidepane.component.html',
  styleUrls: [
    './selector-sidepane.component.scss',
    '../../../styles/sidepanes.partial.scss'
  ],
  animations: [sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove]': 'true' }
})
export class SelectorSidepaneComponent implements OnDestroy {
  public form: FormGroup = new FormGroup({
    item: new FormControl(),
    other: new FormControl(),
  });

  public items: any[];
  public itemSelected: SelectorSidepaneItemSelected;

  public get config() { return this.route.snapshot.data.config as SelectorSidepaneConfig }
  public get map() { return this.config.map || this.selectorSidepaneService.map }

  private destroy$ = new Subject();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private selectorSidepaneService: SelectorSidepaneService
  ) {
    this.setupForm();
    this.initialize();
  }

  public resolveIsOther(key: any) {
    if (!this.config.otherResolver) {
      return key === "OTHER";
    }

    const pair = [key, this.map.get(key)];
    return this.config.otherResolver(pair);
  }

  public isOtherSelected() {
    const itemValue = this.form.get("item").value;
    const isOtherMulti = Array.isArray(itemValue) && itemValue.length > 0 && itemValue[0] === "OTHER";
    const isOther = this.resolveIsOther(itemValue);

    return isOtherMulti || isOther;
  }

  public onSelectClicked() {
    // console.log("form value: ", this.form.value);
    if (this.config.other && this.form.value.other) {
      const itemSelected: SelectorSidepaneItemSelected = {
        key: this.config.key,
        item: this.form.value.item,
        other: this.form.value.other,
      };

      this.selectorSidepaneService.itemSelected$.next(itemSelected);
    } else {
      this.selectorSidepaneService.itemSelected$.next(this.itemSelected);
    }
   
    this.onBack();
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }

  public onBack() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

  private async initialize() {
    const keys = this.map.keys();
    this.items = [...keys];
  }

  private setupForm() {
    this.form.get('item').valueChanges.pipe(takeUntil(this.destroy$)).subscribe(item => {
      // console.log("item: ", item);
      // console.log("last item: ", item.at(-1));

      if (this.config.other && item?.length > 1 && item.at(-1) === "OTHER") {
        this.form.get("item").setValue(["OTHER"]);
        return;
      }

      if (this.config.other && item?.length > 1 && item.includes("OTHER") && item.at(-1) !== "OTHER") {
        const items = item.filter(i => i !== "OTHER");
        this.form.get("item").setValue(items);
        this.form.get("other").setValue(null);
        return;
      }

      const itemSelected: SelectorSidepaneItemSelected = {
        key: this.config.key,
        item: item
      };

      this.itemSelected = itemSelected;

      if (!this.config.multiple) {
        this.selectorSidepaneService.itemSelected$.next(itemSelected);
        this.onBack();
      }
    });

    if (this.selectorSidepaneService.initialySelectedItem) {
      if (this.config.other) {
        this.form.patchValue(this.selectorSidepaneService.initialySelectedItem, { emitEvent: false });
      } else {
        // console.log("ini", this.selectorSidepaneService.initialySelectedItem);
        this.form.get("item").setValue(this.selectorSidepaneService.initialySelectedItem, { emitEvent: false });
      }
      this.selectorSidepaneService.initialySelectedItem = undefined;
    }
  }
}
