<div (click)="onBack()" class="overlay" [@fadeAnimation]></div>

<div class="sidepane-content">
  <div class="template-header flex-space-between-layout">
    <h1 class="title-area">{{interestedPartyId ? 'Edit Interested Party' : 'Add Interested Party'}}</h1>
    <hf-custom-button type="button"
                      class="button-plain-icon"
                      icon="close"
                      iconPos="center"
                      (onClick)="onBack()">
    </hf-custom-button>
  </div>

  <div class="template-main">
    <hf-custom-spinner *ngIf="isLoading" sidepaneLoading="true"></hf-custom-spinner>

    <form *ngIf="!isLoading" [formGroup]="form">
      <label [style.width.%]="100">
        First Name
        <hf-custom-text-field class="text-field" [group]="form" type="text" [capitalize]="true" inputFormControlName="firstName" fieldName="firstName"
          validateOnBlur marginElement>
        </hf-custom-text-field>
      </label>

      <label [style.width.%]="100">
        Last Name
        <hf-custom-text-field class="text-field" [group]="form" type="text" [capitalize]="true" inputFormControlName="lastName" fieldName="lastName"
          validateOnBlur marginElement>
        </hf-custom-text-field>
      </label>

      <label [style.width.%]="100">
        Email Address
        <hf-custom-text-field class="text-field" [group]="form" type="email" inputFormControlName="email" fieldName="email"
          validateOnBlur marginElement>
        </hf-custom-text-field>
      </label>

      <label>Phone (Optional)</label>
      <div [style.width.%]="100" class="flex-space-between-layout phone-line-wrap">
        <div class="flex-phone-prefix">
          <div class="hf-selector">
            <div class="hf-selector-container hf-selector-has-value hf-selector-one-line"
              [routerLink]="['./selectPhonePrefix']">
              <div class="hf-selector-content-area">
                <div class="title">{{form?.get('phonePrefix')?.value}}</div>
              </div>
              <i class="material-icons chevron-icon">chevron_right</i>
            </div>
          </div>
        </div>

        <div class="flex-phone">
          <hfc-text-input type="tel" mask="0*" style="margin-bottom: 12px;" formControlName="phone" hfcRemoveLeadingZero></hfc-text-input>
        </div>
      </div>

      <hf-selector-link label="Type"
                        placeholder="Select type"
                        [value]="INTERESTED_PARTY_TYPE_NAME.get(form.get('type').value)"
                        (click)="onSelectTypeClicked()">
      </hf-selector-link>
    </form>
  </div>

  <div class="template-footer">
    <hf-custom-button type="submit"
                      class="button-primary"
                      label="{{interestedPartyId ? 'Save' : 'Add'}}"
                      [disabled]="form.invalid"
                      [isLoading]="isSubmitting"
                      (onClick)="onSubmit()">
    </hf-custom-button>

    <hf-custom-button type="button"
                      class="button-secondary"
                      label="Cancel"
                      (onClick)="onBack()">
    </hf-custom-button>

    <hf-custom-button *ngIf="interestedPartyId"
                      type="button"
                      class="button-plain-icon delete-button keep-right"
                      icon="delete"
                      iconPos="center"
                      [isLoading]="isSubmitting"
                      (onClick)="onDeleteClicked()">
    </hf-custom-button>
  </div>
</div>

<div class="sidepane-child-anchor">
  <router-outlet></router-outlet>
</div>
