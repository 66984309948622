import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToastService } from '../../../../../../../projects/client/src/app/shared/services/toast.service';
import { NewComplianceHandoverPreferenceDTO } from '../../../../models/new-compliance-handover-preference.dto';
import { FormFieldService } from '../../../../services/form-field.service';
import { MortgageApiService } from '../../../../services/mortgage-api.service';
import { SelectorSidepaneService } from '../../../../shared-layout/side-panes/selector-sidepane/selector-sidepane.service';
import { setControlEnabled } from '../../../../utils/functions/set-control-enabled';
import { MortgageOffsetReasonName, MortgageProductTermName } from '../../../../utils/variables.data';
import { SelectObjectivesService } from '../../select-objectives/select-objectives.service';
import { isAwatingOrApproved, isAwatingOrApprovedNotLegacy } from '../new-compliance-handover-objective/new-compliance-handover-objective.component';
import { NewComplianceHandoverComponent } from '../new-compliance-handover.component';

@Component({
  selector: 'hf-new-compliance-handover-preference',
  templateUrl: './new-compliance-handover-preference.component.html',
  styleUrls: ['./new-compliance-handover-preference.component.scss'],
  providers: [FormFieldService],
})
export class NewComplianceHandoverPreferenceComponent implements OnDestroy {
  public form = this.fb.group({
    offsetReason: [null],
    fixedRate: [false, Validators.required],
    cappedRate: [false, Validators.required],
    productTerm: [null, Validators.required],
    productTermReason: [null, Validators.required],
    avoidHigherCharge: [false, Validators.required],
    addFees: [false, Validators.required],
    flexibleOverpayments: [false, Validators.required],
    portable: [false, Validators.required],
    freeLegals: [false, Validators.required],
    cashback: [false, Validators.required],
    freeValuation: [false, Validators.required],
    noErc: [false, Validators.required],
    maximumArrangmentFee: [null, Validators.required],
    otherPreferences: [null, Validators.required],
    cappedRateReason: [null, Validators.required],
    flexibleOverpaymentsReason: [null],
    portableReason: [null, Validators.required],
    noErcReason: [null, Validators.required],

    reasonForChange: [null],
  });

  public get value() { return this.form.value as NewComplianceHandoverPreferenceDTO }
  public get mortgageId() { return this.route.snapshot.paramMap.get("id") }
  public get handoverId() { return this.sidepane.handoverId }

  public control(name: string) { return this.form.get(name) }
  public enabled(name: string) { return this.form.get(name).enabled }

  public MortgageOffsetReasonName = MortgageOffsetReasonName;
  public MortgageProductTermName = MortgageProductTermName;

  private destroy$ = new Subject();

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private mortgageApi: MortgageApiService,
    private selectObjectivesService: SelectObjectivesService,
    private selectorSidepaneService: SelectorSidepaneService,
    private toast: ToastService,
    private sidepane: NewComplianceHandoverComponent,
    private formFieldService: FormFieldService,
  ) {
    this.setup();

    if (isAwatingOrApproved(this.sidepane.dto.state)) {
      this.control("reasonForChange").setValidators(Validators.required);
    }

    // mock delete
    // this.form.get("objective").setValue("RESI_RE");
    // this.form.get("objective").updateValueAndValidity();

    this.form.patchValue(this.sidepane.dto.preference);
    this.sidepane.assignForm(this.form);
  }

  public onSubmit(draft: boolean) {
    if (isAwatingOrApprovedNotLegacy(this.sidepane.dto) && !this.formFieldService.checkFormValidity(this.form)) {
      return;
    }

    const value = {...this.value};
    if (!value.offsetReason?.length) {
      value.offsetReason = null;
    }

    const api = draft
      ? () => this.mortgageApi.saveComplianceHandoverPreferenceDraft(this.mortgageId, this.handoverId, this.value)
      : () => this.mortgageApi.saveComplianceHandoverPreference(this.mortgageId, this.handoverId, this.value);

    this.sidepane.save(this.form, api);
  }

  public onSelectProductTermClicked() {
    this.selectorSidepaneService.initialySelectedItem = this.value.productTerm
    this.router.navigate(["selectProductTerm"], { relativeTo: this.route });
  }

  public onSelectOffsetReasonClicked() {
    this.selectorSidepaneService.initialySelectedItem = this.value.offsetReason
    this.router.navigate(["selectOffsetReason"], { relativeTo: this.route });
  }

  // public onSelectDebtConsolidationReasonClicked() {
  //   if (this.value.debtConsolidationReason) {
  //     this.selectorSidepaneService.initialySelectedItem = {
  //       item: this.value.debtConsolidationReason,
  //       other: this.value.otherDebtConsolidationReason,
  //     }
  //   }
  //   this.router.navigate(["selectDebtConsolidationReason"], { relativeTo: this.route });
  // }

  private setup() {
    this.selectorSidepaneService.subscribeByKey("selectProductTerm", this.destroy$).subscribe(productTerm => {
      this.control("productTerm").setValue(productTerm);
    });
    this.selectorSidepaneService.subscribeByKey("selectOffsetReason", this.destroy$).subscribe(reason => {
      this.control("offsetReason").setValue(reason);
    });

    // this.selectorSidepaneService.subscribeItemSelectedByKey("selectRemoReason", this.destroy$).subscribe((reason: SelectorSidepaneItemSelected<RemortgageReason>) => {
    //   if (reason.item[0] === "OTHER") {
    //     this.control("remortgageReason").setValue(reason.item);
    //     this.control("otherRemortgageReason").setValue(reason.other)
    //   } else {
    //     this.control("remortgageReason").setValue(reason.item);
    //     this.control("otherRemortgageReason").setValue(null);
    //   }
    // });
    //

    this.control("cappedRate").valueChanges.subscribe(cappedRate => {
      setControlEnabled(this.control("cappedRateReason"), cappedRate);
    });
    this.control("cappedRate").updateValueAndValidity();

    this.control("flexibleOverpayments").valueChanges.subscribe(flexibleOverpayments => {
      setControlEnabled(this.control("flexibleOverpaymentsReason"), flexibleOverpayments);
    });
    this.control("flexibleOverpayments").updateValueAndValidity();

    this.control("portable").valueChanges.subscribe(portable => {
      setControlEnabled(this.control("portableReason"), portable);
    });
    this.control("portable").updateValueAndValidity();

    this.control("noErc").valueChanges.subscribe(noErc => {
      setControlEnabled(this.control("noErcReason"), noErc);
    });
    this.control("noErc").updateValueAndValidity();

    setControlEnabled(this.control("reasonForChange"), isAwatingOrApproved(this.sidepane.dto.state));

    this.sidepane.saveClick$.pipe(takeUntil(this.destroy$)).subscribe(draft => this.onSubmit(draft));
    this.form.statusChanges.subscribe(status => {
      // console.log(status, this.form.valid);
      setTimeout(() => this.sidepane.isValid = this.form.valid);
    });
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }
}
