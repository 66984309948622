<div class="ev-details-container">
  <div class="ev-details-column">
    <div class="ev-details-entry">
      <div class="ev-details-entry-label">Product term</div>
      <div class="ev-details-entry-value">{{ MortgageProductTermName.get(data.productTerm) }}</div>
    </div>

    <div class="ev-details-entry">
      <div class="ev-details-entry-label">Reason for product term preference</div>
      <div class="ev-details-entry-value">{{ data.productTermReason }}</div>
    </div>

    <div class="ev-details-entry">
      <div class="ev-details-entry-label">Fixed rate</div>
      <div class="ev-details-entry-value">{{ yesno(data.fixedRate) }}</div>
    </div>

    <div class="ev-details-entry">
      <div class="ev-details-entry-label">Capped rate</div>
      <div class="ev-details-entry-value">{{ yesno(data.cappedRate) }}</div>
    </div>

    <div *ngIf="data.cappedRate" class="ev-details-entry">
      <div class="ev-details-entry-label">Reason for capped rate preference</div>
      <div class="ev-details-entry-value">{{ data.cappedRateReason }}</div>
    </div>

    <div class="ev-details-entry">
      <div class="ev-details-entry-label">Avoid higher lending charge</div>
      <div class="ev-details-entry-value">{{ yesno(data.avoidHigherCharge) }}</div>
    </div>

    <div class="ev-details-entry">
      <div class="ev-details-entry-label">Add fees to loan</div>
      <div class="ev-details-entry-value">{{ yesno(data.addFees) }}</div>
    </div>
  </div>

  <div class="ev-details-column">
    <div class="ev-details-entry">
      <div class="ev-details-entry-label">Portable</div>
      <div class="ev-details-entry-value">{{ yesno(data.portable) }}</div>
    </div>

    <div *ngIf="data.portable" class="ev-details-entry">
      <div class="ev-details-entry-label">Reason for portability</div>
      <div class="ev-details-entry-value">{{ data.portableReason }}</div>
    </div>

    <div class="ev-details-entry">
      <div class="ev-details-entry-label">Free legals</div>
      <div class="ev-details-entry-value">{{ yesno(data.freeLegals) }}</div>
    </div>

    <div class="ev-details-entry">
      <div class="ev-details-entry-label">Cashback</div>
      <div class="ev-details-entry-value">{{ yesno(data.cashback) }}</div>
    </div>

    <div class="ev-details-entry">
      <div class="ev-details-entry-label">Free valuation</div>
      <div class="ev-details-entry-value">{{ yesno(data.freeValuation) }}</div>
    </div>

    <div class="ev-details-entry">
      <div class="ev-details-entry-label">No ERC</div>
      <div class="ev-details-entry-value">{{ yesno(data.noErc) }}</div>
    </div>

    <div *ngIf="data.noErc" class="ev-details-entry">
      <div class="ev-details-entry-label">Reason for no ERC</div>
      <div class="ev-details-entry-value">{{ data.noErcReason }}</div>
    </div>

    <div class="ev-details-entry">
      <div class="ev-details-entry-label">Flexible overpayments</div>
      <div class="ev-details-entry-value">{{ yesno(data.flexibleOverpayments) }}</div>
    </div>

    <div *ngIf="data.flexibleOverpayments && data.flexibleOverpaymentsReason" class="ev-details-entry">
      <div class="ev-details-entry-label">Reason for overpayments (Optional)</div>
      <div class="ev-details-entry-value">{{ data.flexibleOverpaymentsReason }}</div>
    </div>
  </div>

  <div class="ev-details-column">
    <div *ngIf="data.offsetReason?.length" class="ev-details-entry">
      <div class="ev-details-entry-label">Offset reasons</div>
      <div class="ev-details-entry-value">{{ valueOther(data.offsetReason, MortgageOffsetReasonName) }}</div>
    </div>

    <div class="ev-details-entry">
      <div class="ev-details-entry-label">Maximum arrangement fee</div>
      <div class="ev-details-entry-value"><hf-currency [value]="data.maximumArrangmentFee"></hf-currency></div>
    </div>

    <div class="ev-details-entry">
      <div class="ev-details-entry-label">Any other preferences</div>
      <div class="ev-details-entry-value">{{ data.otherPreferences }}</div>
    </div>
  </div>
</div>
