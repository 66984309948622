<div class="header">
  <div class="activity">
    <span [title]="item.activity">{{ item.activityLabel || "Activity Label" }}: </span>
    {{ item.executor.fullName }}
  </div>

  <div class="date">
    {{ item.createdDate | date:'d MMM, y HH:mm' }}
  </div>
</div>

<div *ngIf="isWhite" class="triangle"></div>

<div class="summary">
  {{ item.summary }}
</div>

<div *ngIf="item.documents?.length" class="documents">
  <hfc-document-thumbnail *ngFor="let document of item.documents" [document]="getDocumentThumbnail(document)" [showFilename]="true"></hfc-document-thumbnail>
</div>

<div *ngIf="item.details" class="details" [innerHtml]="item.details">
</div>

<div *ngIf="item.values?.length" class="values">
  <div *ngFor="let item of item.values" class="value-item">
    {{ item.key }}:
    <span class="value" [innerHtml]="item.value"></span>
  </div>
</div>

<div *ngIf="item.application?.lenderLogo" class="lender-logo">
  <hf-attachment-image [attachment]="item.application.lenderLogo" class="provider-logo"></hf-attachment-image>
</div>

<!-- <div> -->
<!--   {{ item.summary }} -->
<!-- </div> -->
<!-- <div> -->
<!--   {{ item.category }} -->
<!-- </div> -->
<!-- <div> -->
<!--   {{ item.activity }} -->
<!-- </div> -->
<!-- <div> -->
<!--   {{ item.activityLabel }} -->
<!-- </div> -->
