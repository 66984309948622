<div (click)="onBack()" class="overlay" [@fadeAnimation]></div>

<div class="sidepane-content">
  <div class="flex-space-between-layout template-header">
    <h1>{{config.heading}}</h1>
    <hf-custom-button type="button" class="button-plain-icon" icon="close" iconPos="center" (onClick)="onBack()">
    </hf-custom-button>
  </div>

  <div class="template-main">
    <form [formGroup]="form">
      <hf-custom-selector formControlName="item" [items]="items" [multi]="config.multiple">
        <ng-template #itemTemplate let-item>
          <div>
            {{ map.get(item) }}
          </div>
        </ng-template>
      </hf-custom-selector>

      <div *ngIf="(form.get('item').value || [])[0] === 'OTHER'" class="hint" [innerHtml]="config.otherHint">
      </div>

      <hf-custom-text-field *ngIf="isOtherSelected()" [group]="form" inputFormControlName="other" style="margin-top: 8px"></hf-custom-text-field>
    </form>
  </div>

  <div *ngIf="config.multiple || isOtherSelected()" class="template-footer">
    <hf-custom-button type="submit" class="button-primary" label="Select" (click)="onSelectClicked()"></hf-custom-button>
    <hf-custom-button type="button" class="button-secondary" label="Cancel" (click)="onBack()"></hf-custom-button>
  </div>
</div>
