import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomSelectorComponent } from './custom-selector.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CustomTextFieldModule } from '../custom-text-field/custom-text-field.module';

@NgModule({
  declarations: [
    CustomSelectorComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CustomTextFieldModule
  ],
  exports: [
    CustomSelectorComponent
  ]
})
export class CustomSelectorModule { }
