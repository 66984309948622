import {ComponentFactory, ComponentFactoryResolver, Injectable, ViewContainerRef} from '@angular/core';

@Injectable()
export class TemplateService {
  public rootRef:ViewContainerRef;

  private sidebarOpened: boolean;

  constructor(private resolver:ComponentFactoryResolver) { } 

  public openSidebar() {
    this.sidebarOpened = true;
  }

  public closeSidebar() {
    this.sidebarOpened = false;
  }

  public getIfSidebarOpened() {
    return this.sidebarOpened;
  }

  public createComponent(component) {
    const factory: ComponentFactory<any> = this.resolver.resolveComponentFactory(component);
    return this.rootRef.createComponent(factory);
  }

  public deleteComponent(){
    this.rootRef.remove(0);
  }
}
