import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '../../../../../../projects/client/src/app/shared/services/toast.service';
import { MortgageApiService } from '../../../services/mortgage-api.service';
import { fadeAnimation } from '../../../shared/animations/fade.animation';
import { sidepaneMove } from '../../../shared/animations/sidepane-animation';
import { CLIENT_CATEGORY_NAME } from '../../../utils/variables.data';
import { MortgagesService } from '../mortgages.service';

@Component({
  selector: 'hf-switch-client-category',
  templateUrl: './switch-client-category.component.html',
  styleUrls: ['./switch-client-category.component.scss', '../../../styles/sidepanes.partial.scss'],
  animations: [sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove]': 'true' },
})
export class SwitchClientCategoryComponent {
  public form = this.fb.group({
    clientCategory: [null, Validators.required]
  });

  public categories = [...CLIENT_CATEGORY_NAME.keys()];
  public CLIENT_CATEGORY_NAME = CLIENT_CATEGORY_NAME;

  public isSubmitting = false;
  public get mortgageId() { return this.route.snapshot.paramMap.get("id") }

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private mortgageApiService: MortgageApiService,
    private mortgagesService: MortgagesService,
    private toastService: ToastService,
  ) {
    this.initialize();
   }

  public async onSubmit() {
    const value = this.form.value;

    this.isSubmitting = true;

    try {
      await this.mortgageApiService.switchClientCategory(this.mortgageId, value);

      this.toastService.add("Category switched.");
      this.mortgagesService.reloadMortgageDashboard.next();
      this.onBack();
    } catch (e) {
      this.toastService.add("Error when submitting", "error", e);
    }

    this.isSubmitting = false;
  }

  public onBack() {
    this.router.navigate([".."], { relativeTo: this.route });
  }

  private initialize() {
    const clientCategory = this.mortgagesService.data?.clientCategory;

    clientCategory && this.form.patchValue({ clientCategory });
  }
}
