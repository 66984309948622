import { BehaviorSubject, Observable } from "rxjs";
import { map, distinctUntilChanged, tap } from "rxjs/operators";
import * as R from "ramda";

export type FilteringItemType = string;
export type FilteringItemValue = string;

export interface FilteringItemDefinition {
  type: FilteringItemType;
  typeLabel: string;
  dataType?: "date";
  value: FilteringItemValue;
  valueLabel: string;
}

export interface FilteringItem {
  type: FilteringItemType;
  value: FilteringItemValue;
}

export interface FilteringStateModel {
  filters: FilteringItem[];
}

export interface FilteringRequest {
  type: FilteringItemType;
  values: FilteringItemValue[];
}

export type FilteringRequests = Map<FilteringItemType, FilteringItemValue[]>;

export function itemsToFilteringItemDefinitions<T>(items: T[], mapFn: (T) => Partial<FilteringItemDefinition>, type: FilteringItemType, typeLabel: string): FilteringItemDefinition[] {
  return items.map(item => {
    const definition: FilteringItemDefinition = {
      ...mapFn(item),
      type,
      typeLabel,
    } as any;

    return definition;
  });
}

export function mapToFilteringItemDefinitions(map: Map<string, string>, type: FilteringItemType, typeLabel: string): FilteringItemDefinition[] {
  const entries = [...map.entries()];

  return entries.map(([value, valueLabel]) => ({
    type,
    typeLabel,
    value,
    valueLabel,
  }));
}

export function itemsToFilteringRequest(items: FilteringItem[], type: FilteringItemType): FilteringRequest {
  return {
    type,
    values: items.map(i => i.value),
  }
}

export class FilteringService {
  private _filteringItemDefinitions: FilteringItemDefinition[] = [];
  private _activeFilteringItems = new BehaviorSubject<FilteringItem[]>([]);

  public get filteringItemDefinitions() { return this._filteringItemDefinitions }

  public activeFilteringItems$: Observable<FilteringItem[]> = this._activeFilteringItems.asObservable();
  public activeFilteringItemDefinitions$: Observable<FilteringItemDefinition[]> = this._activeFilteringItems.pipe(map(items => this.toDefinitions(items)));
  public activeFilteringItemsSerialized$: Observable<string> = this.activeFilteringItems$.pipe(map(items => this.serialize(items)));
  public numOfFilters$: Observable<number> = this.activeFilteringItems$.pipe(map(items => items.length));

  private get value() { return this._activeFilteringItems.value }

  public applyFilter(type: FilteringItemType, values: FilteringItemValue[]) {
    const cleared = this.clearByType(type, this._activeFilteringItems.value);
    const added = values.map(value => ({ type, value }));
    const sum = [...cleared, ...added];

    this.next(sum);
  }

  public applyFilters(filters: FilteringRequests) {
    const entries = [...filters.entries()];
    const types = [...filters.keys()];

    const cleared = this.value.filter(i => !types.includes(i.type));
    const added = entries.flatMap(([type, values]) => values.map(value => ({ type, value })));
    const sum = [...cleared, ...added];

    this.next(sum);
  }

  public clearFilter(item: FilteringItem) {
    const cleared = this.clearByItem(item, this._activeFilteringItems.value);

    this.next(cleared);
  }

  public clearAll() {
    this.next([]);
  }

  public getActiveFilteringItemsByType(type: string): Observable<FilteringItem[]> {
    const filterByType = (items: FilteringItem[]) => items.filter(i => i.type === type);
    const compareFn = (prev: FilteringItem[], next: FilteringItem[]) => R.equals(prev, next);

    return this.activeFilteringItems$.pipe(
      map(filterByType),
      distinctUntilChanged(compareFn),
    );
  }

  public getActiveFilteringItemDefinitionsByType(type: string): Observable<FilteringItemDefinition[]> {
    return this.getActiveFilteringItemsByType(type).pipe(map(items => this.toDefinitions(items)));
  }

  public setFilteringItemDefinitions(definitions: FilteringItemDefinition[]) {
    this._filteringItemDefinitions = definitions;
  }

  public getFilteringItemDefinitionsByType(type: FilteringItemType): FilteringItemDefinition[] {
    return this._filteringItemDefinitions.filter(d => d.type == type);
  }

  public parseSerialized(filters: string) {
    let parsed: FilteringItem[] = [];

    try {
      parsed = JSON.parse(filters);
    } catch (e) { }

    if (!Array.isArray(parsed)) {
      return;
    }

    // const safe = parsed.filter(p => p.

    this.next(parsed);
  }

  private clearByType(type: FilteringItemType, items: FilteringItem[]): FilteringItem[] {
    return items.filter(i => i.type !== type);
  }

  private clearByItem(itemToClear: FilteringItem, items: FilteringItem[]): FilteringItem[] {
    return items.filter(i => i.type !== itemToClear.type || i.value !== itemToClear.value);
  }

  private toDefinitions(items: FilteringItem[]): FilteringItemDefinition[] {
    const definitions = this._filteringItemDefinitions || [];
    const toDef = (item: FilteringItem) => {
      const def = definitions.find(d => d.type === item.type);
      const exactdef = definitions.find(d => d.type === item.type && d.value === item.value);

      return def.dataType === "date"
        ? {...def, value: item.value, valueLabel: item.value.toString() }
        : exactdef;
    }

    return items.map(toDef);
  }

  private serialize(items: FilteringItem[]): string {
    return items.length
      ? JSON.stringify(items)
      : "";
  }

  private next(items: FilteringItem[]): FilteringItem[] {
    const sorted = this.sort(items);

    if (R.equals(sorted, this.value)) {
      return;
    }

    this._activeFilteringItems.next(sorted);
  }

  private sort(items: FilteringItem[]): FilteringItem[] {
    return R.sortWith([
      R.ascend(R.prop("type")),
      R.ascend(R.prop("value")),
    ], items);
  }
}
