import { Params } from "@angular/router";
import { Subject } from "rxjs";
import { UserSimple } from "../../../models/user";

export abstract class SelectAdvisorForAssignmentService {
  public advisorSelected$ = new Subject<UserSimple>();

  public heading: string;
  public abstract selectAdvisorsFn(routeParamMap: Params): Promise<UserSimple[]>;
}
