<div class="overlay" [routerLink]="['..']" [@fadeAnimation]></div>

<div class="sidepane-content">
  <div class="template-header flex-space-between-layout">
    <h1 class="title-area">Offer Qualification</h1>
    <hf-custom-button type="button"
                      class="button-plain-icon"
                      icon="close"
                      iconPos="center"
                      [routerLink]="['..']">
    </hf-custom-button>
  </div>

  <div class="template-main">
    <form class="form" [formGroup]="form">
      <label>Desired Qualification Amount</label>
      <hf-text-field prefix="£" isCurrency="true" formControlName="desiredQualification"></hf-text-field>

      <label>Comments</label>
      <hf-textarea [group]="form" inputFormControlName="comments">
      </hf-textarea>
    </form>

    <!-- <pre>{{form.value | json}}</pre> -->
  </div>

  <div class="template-footer">
    <hf-custom-button class="button-primary"
                      label="Request"
                      [callable]="true"
                      [disabled]="form.invalid"
                      (onClick)="onSubmit()">
    </hf-custom-button>

    <hf-custom-button type="button"
                      class="button-secondary"
                      label="Cancel"
                      [routerLink]="['..']">
    </hf-custom-button>
  </div>
</div>
