import { Component, Input } from '@angular/core';
import { ExperianCreditSearchCategoryDTO } from '../../../../../../services/experian-credit-report-api.service';

@Component({
  selector: 'hf-category-status-box',
  templateUrl: './category-status-box.component.html',
  styleUrls: ['./category-status-box.component.scss']
})
export class CategoryStatusBoxComponent {
  @Input()
  public category: ExperianCreditSearchCategoryDTO;

  @Input()
  public categoryName: string;
}
