<div class="container">
 <div class="ev-dashboard-header">
    <hf-nav-button *ifMedia="'desktop'"></hf-nav-button>

    <div class="heading">
      <hf-custom-button type="button" class="button-plain-icon" icon="arrow_back" iconPos="center" (click)="onBack()">
      </hf-custom-button>

      <ng-container *ifMedia="'desktop'">
        <img src="/assets/icons/Shield.svg">
      </ng-container>

      <div class="heading-content">
        <h1>
          {{ protection?.client.fullName }}
        </h1>
        <hf-warning-badge *ngIf="containsVulnerableCustomer"></hf-warning-badge>
      </div>
    </div>

    <div class="status-wrapper">
      <hf-midas-failed-badge *ngIf="protection?.midasImportStatus === 'FAILURE'"></hf-midas-failed-badge>
      <span [ngClass]="protection?.state.toLowerCase().replace('/_/g', '-')" class="mortgage-status">
        {{ protection|mortgageStatus }}
      </span>
    </div>

    <div *ngIf="!protectionDashboardService.isLoading" class="single-button-wrap">
      <hf-custom-button *ngIf="primaryButtonDefinition as definition" type="button" class="button-primary" textPos="left" iconPos="left" [label]="definition.label" [icon]="definition.icon" (click)="onPrimaryButtonClicked()">
      </hf-custom-button>
    </div>

    <hf-protection-toolbar-with-menu [items]="menuItems" (itemClick)="onItemSelected($event)"></hf-protection-toolbar-with-menu>
  </div>

  <hf-custom-spinner *ngIf="protectionDashboardService.isLoading || isReplacingProtectionCase" style="margin-top: 20px;" [darkTheme]="false">
  </hf-custom-spinner>

  <hf-tab-group *ngIf="!protectionDashboardService.isLoading" [activeKey]="tab" (activeKeyChange)="onActiveTabKeyChanged($event)">
    <hf-tab-panel label="General">
      <ng-template #content>
        <hf-protection-dashboard-general></hf-protection-dashboard-general>
      </ng-template>
    </hf-tab-panel>
    <hf-tab-panel key="imported" label="Imported" *ngIf="protection?.imported">
      <ng-template #content>
        <hf-protection-dashboard-imported>
        </hf-protection-dashboard-imported>
      </ng-template>
    </hf-tab-panel>
    <hf-tab-panel key="journal" label="Journal">
      <ng-template #content>
        <hf-journal-tab [pinnedNote]="protection.pinnedNote" [dataSourceFactory]="journalDataSourceFactory" permissionsToManage="PROTECTION_MANAGE">
        </hf-journal-tab>
      </ng-template>
    </hf-tab-panel>
    <hf-tab-panel key="documents" label="Documents" *ifPermission="'PROTECTION_MANAGE'">
      <ng-template #content>
        <hf-documents-tab [dataSourceFactory]="documentsDataSourceFactory" [downloadZipParams]="'protectionCaseId=' + protection.id">
        </hf-documents-tab>
      </ng-template>
    </hf-tab-panel>
    <hf-tab-panel *ngIf="protection?.factFind" key="fact-find" label="Fact Find">
      <ng-template #content>
        <hf-protection-dashboard-fact-find></hf-protection-dashboard-fact-find>
      </ng-template>
    </hf-tab-panel>
    <hf-tab-panel *ngIf="protection?.clientSheetAvailable" key="client-sheet" label="Client Sheet">
      <ng-template #content>
        <hf-protection-dashboard-client-sheet *ngIf="protection?.clientSheetAvailable"></hf-protection-dashboard-client-sheet>
      </ng-template>
    </hf-tab-panel>
    <hf-tab-panel key="tasks" label="Tasks" [badge]="protection?.tasksDueCount">
      <ng-template #content>
        <hf-task-tab [dataSourceFactory]="taskDataSourceFactory" permissionsToManage="PROTECTION_MANAGE">
        </hf-task-tab>
      </ng-template>
    </hf-tab-panel>
  </hf-tab-group>
</div>

<div class="sidepanes-anchor">
  <router-outlet></router-outlet>
</div>
