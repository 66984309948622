import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RequestRequalificationComponent } from './request-requalification.component';
import { CustomButtonModule } from '../../../shared/custom-button/custom-button.module';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomTextFieldModule } from '../../../shared/custom-text-field/custom-text-field.module';
import { CustomTextAreaModule } from '../../../shared/custom-textarea/custom-text-area.module';
import { TextFieldModule } from '../../../shared/text-field/text-field.module';

@NgModule({
  declarations: [RequestRequalificationComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    CustomButtonModule,
    CustomTextFieldModule,
    CustomTextAreaModule,
    TextFieldModule
  ],
  exports: [RequestRequalificationComponent]
})
export class RequestRequalificationModule { }
