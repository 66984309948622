import { Component, Input } from '@angular/core';
import { APPLICATION_STEP_STATUS } from '../../../../../projects/client/src/app/fact-find/enums.module';

@Component({
  selector: 'hf-fact-find-step-status-icon',
  templateUrl: './fact-find-step-status-icon.component.html',
  styleUrls: ['./fact-find-step-status-icon.component.scss']
})
export class FactFindStepStatusIconComponent {
  @Input()
  public status: APPLICATION_STEP_STATUS;
}
