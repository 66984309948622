import { Component, Input } from '@angular/core';
import { Attachment } from '../../models/attachment';

@Component({
  selector: 'hf-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent {
  @Input()
  public photo: Attachment;
}
