<div class="container">
  <hf-custom-spinner *ngIf="isLoading" [darkTheme]="false"></hf-custom-spinner>

  <ng-container *ngIf="!isLoading">
    <div class="header">
      <hf-dropdown [items]="handovers" [(ngModel)]="handover" (ngModelChange)="onHandoverChange()">
        <ng-template #valueTemplate let-item>
          <div class="lender-value">
            <hf-attachment-image *ngIf="item?.lenderLogo" [attachment]="item?.lenderLogo" [alt]="item?.lender"></hf-attachment-image>
            <ng-container *ngIf="!item?.lenderLogo">{{ item?.lender || 'Unspecified lender' }}</ng-container>
          </div>
        </ng-template>

        <ng-template #itemTemplate let-item let-selected="isSelected">
          <div class="lender-item" [class.selected]="selected">
            <div class="name">{{ item?.lender || 'Unspecified lender' }}</div>
            <div class="reference">
              <ng-container *ngIf="item?.lenderReference">Ref: {{ item?.lenderReference }}</ng-container>
              <ng-container *ngIf="!item?.lenderReference">Awaiting reference</ng-container>
            </div>

            <i class="material-icons">done</i>
          </div>
        </ng-template>

        <ng-template #drawerBottomTemplate>
          <div *ngIf="isAddClientSheetVisible" class="dropdown-button-container">
            <hfc-button type="tertiary" size="small" (click)="onAddClientSheetClicked()">
              Add Client Sheet
            </hfc-button>
          </div>
        </ng-template>
      </hf-dropdown>

      <div class="status">
        <i *ngIf="data.state === 'AWAITING_APPROVAL'" class="material-icons">hourglass_empty</i>
        <i *ngIf="data.state === 'APPROVED'" class="material-icons">done</i>
        <i *ngIf="isToComplianceVisible()" class="material-icons">hourglass_empty</i>

        <ng-container *ngIf="isToComplianceVisible()">
          Ready for Compliance
        </ng-container>
        <ng-container *ngIf="!isToComplianceVisible()">
          {{COMPLIANCE_HANDOVER_STATE_NAME.get(data.state)}}
        </ng-container>
      </div>

      <div class="desktop-buttons">
        <hfc-button type="secondary" *ngIf="data.state === 'AWAITING_APPROVAL'" (click)="onApproveClicked()">Approve</hfc-button>
        <hfc-button type="secondary" *ngIf="data.state === 'APPROVED'" (click)="onUndoApproveClicked()">Undo Approval</hfc-button>
        <hfc-button type="secondary" *ngIf="isToComplianceVisible()" [isLoading]="isSubmitting" (click)="onToComplianceClicked()">To Compliance</hfc-button>
        <hfc-button type="tertiary" postfixIcon="launch" *ngIf="data.twentySevenTecApplyCompatible && data.state === 'APPROVED'" [isLoading]="isApplySubmitting" (click)="onApplyClicked()">Apply</hfc-button>
      </div>
    </div>

    <div *ngIf="data && isLegacy" class="boxes-area">
      <hf-information-box heading="Loan Amount" value="{{ data.loanAmount | currency:'GBP':undefined:'1.0-0' }}"></hf-information-box>
      <hf-information-box heading="Mortgage Type" [value]="MORTGAGE_PRODUCT_DATA_TYPE_NAME.get(data.type)"></hf-information-box>
      <hf-information-box heading="Mortgage Term" value="{{data.mortgageTerm?.years}} years {{data.mortgageTerm?.months}} months"></hf-information-box>
      <div style="width: 100%"></div>
      <hf-information-box *ngIf="data.endDateType === 'DATE'" heading="Product End Date" [value]="data.endDate|date:'d MMM y'"></hf-information-box>
      <hf-information-box *ngIf="data.endDateType === 'YEARS_MONTHS'" heading="Product End Date" value="{{data.endYearsMonths.years}} years {{data.endYearsMonths.months}} months"></hf-information-box>
      <hf-information-box heading="Rate" value="{{data.rate|number:'1.2-2'}}%"></hf-information-box>
      <hf-information-box heading="Lender Reference">
        <ng-template #valueTemplate>
          <div style="display: flex; align-items: center;">
            {{data.lenderReference || '--'}}
            <hf-icon-button *ngIf="isEditLenderReferenceVisible" icon="edit" [isCircle]="true" (click)="onEditLenderReferenceClicked()" style="margin-left: 16px;"></hf-icon-button>
          </div>
        </ng-template>
      </hf-information-box>
      <hf-information-box heading="Product Code" [value]="data.productCode"></hf-information-box>
    </div>

    <div *ngIf="data && !isLegacy" class="boxes-area">
      <hf-information-box heading="Loan Amount" value="{{ data.recommended.loanAmount | currency:'GBP':undefined:'1.0-0' }}"></hf-information-box>
      <hf-information-box heading="Mortgage Type" [value]="MORTGAGE_PRODUCT_DATA_TYPE_NAME.get(data.recommended.type)"></hf-information-box>
      <hf-information-box heading="Mortgage Term" value="{{data.recommended.mortgageTerm?.years}} years {{data.recommended.mortgageTerm?.months}} months"></hf-information-box>
      <div style="width: 100%"></div>
      <hf-information-box *ngIf="data.recommended.endDateType === 'DATE'" heading="Product End Date" [value]="data.recommended.endDate|date:'d MMM y'"></hf-information-box>
      <hf-information-box *ngIf="data.recommended.endDateType === 'YEARS_MONTHS'" heading="Product End Date" value="{{data.recommended.endYearsMonths.years}} years {{data.recommended.endYearsMonths.months}} months"></hf-information-box>
      <hf-information-box heading="Initial interest rate" value="{{data.recommended.initialRate | number:'1.2-2'}}%"></hf-information-box>
      <hf-information-box heading="Lender Reference">
        <ng-template #valueTemplate>
          <div style="display: flex; align-items: center; font-size: 20px;">
            {{data.lenderReference || '--'}}
            <hf-icon-button *ngIf="isEditLenderReferenceVisible" icon="edit" [isCircle]="true" (click)="onEditLenderReferenceClicked()" style="margin-left: 16px;"></hf-icon-button>
          </div>
        </ng-template>
      </hf-information-box>
      <hf-information-box heading="Product Code" [value]="data.recommended.productCode"></hf-information-box>
    </div>

    <hf-new-client-sheet *ngIf="data && !isLegacy" [data]="data" (editClick)="onEditClicked()"></hf-new-client-sheet>

    <div *ngIf="data && isLegacy">
      <div class="separator"></div>

      <div class="section">
        <div class="title">
          Application Details
          <hfc-button type="tertiary" size="small" (click)="onEditClicked()">Edit</hfc-button>
        </div>
        <hfc-review-section-item label="Current Situation">{{data.currentSituation}}</hfc-review-section-item>
        <hfc-review-section-item label="Objectives">{{data.otherObjective || MORTGAGE_OBJECTIVE_NAME.get(data.objective)}}</hfc-review-section-item>
      </div>

      <div class="section income">
        <div class="title">Income</div>
        <hfc-review-section-item label="Income Calculation">{{data.incomeCalculationExplanation}}</hfc-review-section-item>
        <hfc-review-section-item *ngIf="data.knownIncomeChanges" label="Known Income Changes">{{data.knownIncomeChangesExplanation}}</hfc-review-section-item>
        <hfc-review-section-item label="Will the mortgage take the client beyond state retirement age">{{yesno(data.mortgageBeyondRetirement)}}</hfc-review-section-item>
      </div>

      <div class="section">
        <div *ngFor="let applicantIncome of data.applicantsIncomes" class="applicant-income">
          <div class="applicant-title">{{applicantIncome.applicant.fullName}}</div>

          <div class="annual-breakdown">Annual Breakdown</div>

          <hfc-review-section-item *ngFor="let income of applicantIncome.incomes" [label]="income.description">
            {{income.amount|currency:'GBP':undefined:'1.0-2'}}
          </hfc-review-section-item>
          <hfc-review-section-item label="Total">{{applicantIncome.total|currency:'GBP':undefined:'1.0-2'}}</hfc-review-section-item>
          <hfc-review-section-item label="Net Monthly Income" class="net-income">{{getNetMontlyIncome(applicantIncome.applicant.id)|currency:'GBP':undefined:'1.0-2'}}</hfc-review-section-item>
        </div>
      </div>

      <div class="section">
        <div class="title">Mortgage Notes</div>
        <hfc-review-section-item label="Client mortgage need and source of deposit">{{data.clientMortgageNeed}}</hfc-review-section-item>
        <hfc-review-section-item label="Choice of lender and choice of Term">{{data.choiceOfLenderExplanation}}</hfc-review-section-item>
        <hfc-review-section-item label="Interest Only">{{yesno(data.interestOnly)}}</hfc-review-section-item>
        <hfc-review-section-item *ngIf="data.interestOnly" label="Interest Only Explanation">{{data.interestOnlyExplanation}}</hfc-review-section-item>
        <hfc-review-section-item *ngIf="data.debtConsolidation" label="Debt Consolidation">{{data.otherDebtConsolidationExplanation || DEBT_CONSOLIDATION_EXPLANATION_NAME.get(data.debtConsolidationExplanation)}}</hfc-review-section-item>
        <hfc-review-section-item *ngIf="!data.debtConsolidation" label="Debt Consolidation">No</hfc-review-section-item>
        <hfc-review-section-item label="Adding fees to loan">{{yesno(data.feesToLoan)}}</hfc-review-section-item>
      </div>

      <div class="section">
        <div class="title">Documents</div>
        <div class="thumbnails-container">
          <hfc-document-thumbnail *ngIf="data.kfi" [document]="getDocumentThumbnail(data.kfi, 'KFI Document')"></hfc-document-thumbnail>
          <hfc-document-thumbnail *ngIf="data.recommendationEvidence" [document]="getDocumentThumbnail(data.recommendationEvidence, 'Evidence of Recommendation')"></hfc-document-thumbnail>
        </div>
      </div>
    </div>

    <div *ngIf="data?.changeHistory?.length" class="section">
      <div class="title">Change History</div>
      <div class="change-history">
        <hf-edit-changes-group *ngFor="let changesGroup of data.changeHistory" [changesGroup]="changesGroup"></hf-edit-changes-group>
      </div>
    </div>
  </ng-container>
</div>

<div *ngIf="data" class="buttons">
  <hfc-button type="secondary" *ngIf="data.state === 'AWAITING_APPROVAL'" (click)="onApproveClicked()">Approve</hfc-button>
  <hfc-button type="secondary" *ngIf="data.state === 'APPROVED'" (click)="onUndoApproveClicked()">Undo Approval</hfc-button>
  <hfc-button type="secondary" *ngIf="isToComplianceVisible()" [isLoading]="isSubmitting" (click)="onToComplianceClicked()">To Compliance</hfc-button>
  <hfc-button type="tertiary" postfixIcon="launch" *ngIf="data.twentySevenTecApplyCompatible && data.state === 'APPROVED'" [isLoading]="isApplySubmitting" (click)="onApplyClicked()">Apply</hfc-button>
</div>
