<div class="heading" (click)="isOpen = !isOpen">
  {{ heading }}

  <ng-container *ngIf="numOfSelected">
    ({{ numOfSelected }} selected)
  </ng-container>

  <i class="material-icons">expand_more</i>
</div>
  
<div class="content" *ngIf="isOpen && form" [@expandAnimation]>
  <form [formGroup]="form">
    <div *ngFor="let def of defs; let i=index">
      <div class="label">{{ def.label }}</div>
      <hf-date-input [formControlName]="i" [propertyName]="def.type" minDate="none"></hf-date-input>
    </div>
  </form>
</div>
