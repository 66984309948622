import { ComponentPortal } from '@angular/cdk/portal';
import { Component, ComponentRef, OnInit } from '@angular/core';

@Component({
  selector: 'hf-tooltip-text-content',
  templateUrl: './tooltip-text-content.component.html',
  styleUrls: ['./tooltip-text-content.component.scss']
})
export class TooltipTextContentComponent implements OnInit {
  public content: string;

  public component: any;
  public componentInputs: {};

  public componentPortal: ComponentPortal<any>;

  public ngOnInit() {
    if (!this.component) {
      return;
    }

    this.componentPortal = new ComponentPortal(this.component);
  }

  public onAttached(ref: ComponentRef<any>) {
    if (!this.componentInputs) {
      return;
    }

    Object.entries(this.componentInputs).forEach(([k, v]) => {
      ref.instance[k] = v;
    });
  }
}
