import { Component, Input } from '@angular/core';

@Component({
  selector: 'hf-open-filter-composer-button',
  templateUrl: './open-filter-composer-button.component.html',
  styleUrls: ['./open-filter-composer-button.component.scss']
})
export class OpenFilterComposerButtonComponent {
  @Input()
  public numOfFilters: number;
}
