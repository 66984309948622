import {Component, OnDestroy} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {User} from '../../../models';
import {NavigationService} from '../../../services/navigation.service';
import {ToasterService} from '../../../services/toaster.service';
import {UserApiService} from '../../../services/user-api.service';
import {UserService} from '../../../services/user.service';
import {ValidatorsService} from '../../../services/validators.service';
import {fadeAnimation, sidepaneMove} from '../../../shared/animations/sidepane-animation';
import {FileType} from '../../../shared/fileuploader/utils/fileuploader.enum';
import {FileControlsConfigBuilder} from '../../../shared/fileuploader/utils/fileuploader.utils';
import {getTwoFactorSetupStatus, TwoFactorSetupStatus} from '../../../shared/two-factor-status/two-factor-status.component';
import {PhonePrefixService} from '../../introducers/select-phone-prefix/phone-prefix.service';
import { getUkPhoneValidator } from '../../../../../../projects/client/src/app/shared/functions/uk-phone.validator';
import { ToastService } from '../../../../../../projects/client/src/app/shared/services/toast.service';
import { Confirmation, DialogService } from '../../../../../../projects/client/src/app/shared/services/dialog.service';


@Component({
  selector: 'hf-account',
  templateUrl: './account.component.html',
  styleUrls: [
    './account.component.scss',
    '../../../styles/sidepanes.partial.scss'
  ],
  animations: [sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove]': 'true' }
})
export class AccountComponent implements OnDestroy {
  readonly ALLOWED_FILE_TYPES: FileType[] = [FileType.IMAGE_ALL];

  public user: User;
  public form: FormGroup;
  public twoFactorSetupStatus: TwoFactorSetupStatus;
  public isLoading = true;
  public twenty7tecIsLoading = false;

  public get isIntroducer() { return this.userService.isIntroducer() }

  private destroy$ = new Subject();

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private userApiService: UserApiService,
    private phonePrefixService: PhonePrefixService,
    private toasterService: ToasterService,
    private toastService: ToastService,
    private dialogService: DialogService,
    private validatorsService: ValidatorsService,
    private navigationService: NavigationService,
  ) {
    this.form = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', { validators: [Validators.required, Validators.email], updateOn: 'blur' }],
      phone: ['', [Validators.required, getUkPhoneValidator()]],
      phonePrefix: ['+44', Validators.required],
      photo: this.fb.group(
        new FileControlsConfigBuilder()
          .allowedFileTypes(this.ALLOWED_FILE_TYPES, 'Photo must be an image')
          .maxFileSizeDisk()
          .build()
      ),
    });

    this.phonePrefixService.phonePrefixUpdated.pipe(takeUntil(this.destroy$))
      .subscribe(phonePrefix => this.form.patchValue({ phonePrefix }));

    // Preventing multiple async validator trigers
    this.initialize().then(() => {
      setTimeout(() => this.form.get("email").setAsyncValidators(this.validatorsService.validateEmailAddressForEditProfile()))
    });
  }

  public onLinkClicked() {
    if (this.user.microsoftAuthorised) {
      this.unlink();
    } else {
      this.router.navigateByUrl("/login/link-office-365");
    }
  }

  private unlink() {
    const confirmation: Confirmation = {
      title: "Unlink Office 365 Account",
      message: "Unlinking your account will prevent emails from appearing in Everglades. Are you sure you want to unlink?",
      acceptLabel: "Unlink Account",
      acceptButtonType: "primary",
    }

    const doUnlink = async () => {
      try {
        await this.userApiService.unlinkOffice365().pipe(takeUntil(this.destroy$)).toPromise();

        this.toastService.add("Account unlinked with Office 365");
        this.onBack();
      } catch (e) {
        this.toastService.add("Failed to unlink", "error", e);
        this.onBack();
      }
    }

    this.dialogService.confirmAndDo(confirmation, doUnlink);
  }

  public onTwoFactorSetupClicked() {
    switch (this.twoFactorSetupStatus) {
      case "NONE":
        return this.navigationService.goToSetupMobileNumber();
      case "SMS":
        return this.navigationService.goToSetupAuthApp();
      case "FULL":
        return this.navigationService.goToSetupMobileNumber();
    }
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }

  public async onRequest() {
    try {
      const result = await this.updateProfile();
      this.userService.setUser(result);
      this.toasterService.callToaster({ severity: 'info', summary: 'Info', detail: 'User profile updated successfully' });
    } catch (err) {
      this.toasterService.callToaster({ severity: 'error', summary: 'Error', detail: `Failed to update user profile, please try again. ${err.error.error.message}` });
    }

    this.onBack();
  }

  public onBack() {
    const url = this.router.routerState.snapshot.url;
    const before = url.replace("(settings:account)", "");
    this.router.navigateByUrl(before);
  }

  public onPhotoDownload() {
    this.userApiService.triggerAttachmentDownload(this.form.get('photo').value);
  }

  public onTwenty7tecLinkClicked() {
    this.router.navigateByUrl("/login/link-twenty-seven-tec");
  }

  public onTwenty7tecUnlinkClicked() {
    this.twenty7tecIsLoading = true;

    this.userApiService.unlinkTwentySevenTec().subscribe(
      result => {
        this.toastService.add("Twenty7tec Account Unlinked");
        this.twenty7tecIsLoading = false;
        this.onBack();
      },
      e => {
        this.toastService.add("Failed to unlink Twenty7tec", "error", e);
        this.twenty7tecIsLoading = false;
        this.onBack();
      }
    );
  }

  private updateProfile(): Promise<void> {
    const user: User = { ...this.form.value };

    !user.photo.filename && delete user.photo;

    return this.userApiService.editProfile(user);
  }

  private async initialize() {
    this.isLoading = true;

    this.user = await this.userApiService.getProfileInfo();
    this.form.patchValue(this.user);
    this.twoFactorSetupStatus = getTwoFactorSetupStatus(this.user);

    this.isLoading = false;
  }
}
