import { BehaviorSubject } from "rxjs";
import { HelpToBuyRegion } from "../../../services/mortgage-api.service";
import { Injectable } from "@angular/core";

@Injectable()
export class SelectHelpToBuyRegionService {
  private readonly _region = new BehaviorSubject<HelpToBuyRegion>(undefined);

  public region$ = this._region.asObservable();
  public get region() { return this._region.getValue(); }

  public setRegion(region: HelpToBuyRegion) {
    this._region.next(region);
  }
}
