import { Component, Input } from '@angular/core';

export interface TwoFactorStatusModel {
  mobilePrefix: string;
  mobile: string;
  mobileVerified: boolean;
  twoFactorAuthEnabled: boolean;
}

export type TwoFactorSetupStatus =
  | "NONE"
  | "SMS"
  | "FULL"

export function getTwoFactorSetupStatus(user: TwoFactorStatusModel): TwoFactorSetupStatus {
  if (!user.mobileVerified && !user.twoFactorAuthEnabled) {
    return "NONE";
  }

  if (user.mobileVerified && !user.twoFactorAuthEnabled) {
    return "SMS";
  }

  return "FULL";
}

@Component({
  selector: 'hf-two-factor-status',
  templateUrl: './two-factor-status.component.html',
  styleUrls: ['./two-factor-status.component.scss']
})
export class TwoFactorStatusComponent {
  @Input()
  public status: TwoFactorStatusModel;

  constructor() { }
}
