import { Component, OnInit, Input } from '@angular/core';
import { FilteringItemType, FilteringRequest } from '../../../classes/filtering-service.class';
import { RemortgageFilteringService } from '../../../modules/remortgage/services/remortgage-filtering.service';
import { FilterComposerComponent } from '../filter-composer.component';
import { FormControl } from '@angular/forms';
import { take } from 'rxjs/operators';

@Component({
  selector: 'hf-filter-composer-toggle',
  templateUrl: './filter-composer-toggle.component.html',
  styleUrls: ['./filter-composer-toggle.component.scss']
})
export class FilterComposerToggleComponent implements OnInit {
  @Input()
  public type: FilteringItemType;

  @Input()
  public heading: string;

  public toggleControl = new FormControl();

  constructor(
    private remortgageFilteringService: RemortgageFilteringService,
    private filterComposer: FilterComposerComponent,
  ) { }

  public ngOnInit(): void {
    this.toggleControl.valueChanges.subscribe(toggle => {
      const filter: FilteringRequest = {
        type: this.type,
        values: toggle ? ["true"] : [],
      }

      this.filterComposer.onFilterChange(filter);
    });

    this.remortgageFilteringService.getActiveFilteringItemsByType(this.type).pipe(take(1)).subscribe(items => {
      const value = items?.length > 0 
        ? true
        : false;

      this.toggleControl.setValue(value);
    });
  }
}
