import { Component, Input } from '@angular/core';
import { JournalEntry } from '../../models/journal-details';
import { JournalCategoryIcon } from '../../utils/journal.enum';
import { JOURNAL_ACTIVITY_WHITE } from '../../utils/variables.data';

function getIcon(item: JournalEntry): string {
  return JournalCategoryIcon.get(item.category);
}

@Component({
  selector: 'hf-journal-item-container',
  templateUrl: './journal-item-container.component.html',
  styleUrls: ['./journal-item-container.component.scss']
})
export class JournalItemContainerComponent {
  @Input()
  public item: JournalEntry;

  @Input()
  public first = false;

  @Input()
  public last = false;

  public get isWhite() { return JOURNAL_ACTIVITY_WHITE.has(this.item?.activity) }

  public getIcon() {
    return getIcon(this.item);
  }
}
