import { APPOINTMENT_STATE, CLIENT_CATEGORY, CLIENT_SCHEME, COMPLIANCE_HANDOVER_STATE, FACT_FIND_STATE, HELP_TO_BUY_STATUS, MORTGAGE_STATE_TYPE, OFFER_QUALIFICATION_STATUS, APPLICATION_STATE, AppointmentSpecificTime } from '../utils/variables.data';
import { ApplicationListDTO } from './application';
import { HelpToBuyRegion } from '../services/mortgage-api.service';
import { Introducer } from './Introducer';
import { TableParams } from '../shared/table-utils/table-settings.service';
import { UserSelectorDTO } from './user';
import { SignatureRequestState } from '../../../../projects/client/src/app/root/services/client.service';
import {Attachment} from './attachment';
import { ComplianceHandoverSuitabilityLetterState } from './suitability-letter.dto';

export interface MortgageDashboardFactFind {
  status: FACT_FIND_STATE;
  lastAction: string;
}

export interface MortgageDashboardAppointment {
  id: string;
  state: APPOINTMENT_STATE;
  appointmentDate: string;
  createdDate: string;
  nonSpecificTime: AppointmentSpecificTime;
}

export interface MortgageDashboardHelpToBuy {
  region: HelpToBuyRegion;
  status: HELP_TO_BUY_STATUS;
  lastAction: string;
  submittedDate: string;
}

export interface MortgageDashboardOfferQualification {
  status: OFFER_QUALIFICATION_STATUS;
  lastAction: string;
  qualifiedUpTo: number;
}

export type ProductTransferDeclarationState =
  | "NOT_REQUESTED"
  | "REQUESTED"
  | "IN_PROGRESS"
  | "SIGNED"

export const ProductTransferDeclarationStateName = new Map<ProductTransferDeclarationState, string>([
  ["NOT_REQUESTED", "Not requested yet"],
  ["REQUESTED", "Requested"],
  ["IN_PROGRESS", "Requested"],
  ["SIGNED", "Everybody signed"],
])

export interface ProductTransferDeclarationDTO {
  total: number;
  signed: number;
  state: ProductTransferDeclarationState;
  applicantStates: {
    applicant: string;
    state: SignatureRequestState;
  }[];
}

export interface MortgageDashboardHandover {
  id: string;
  createdDate: Date;
  lender: string;
  lenderLogo: Attachment;
  lenderReference: string;
  lenderRefRegex: string;
  loanAmount?: number;
  handoverState: COMPLIANCE_HANDOVER_STATE;
  suitabilityLetterState: ComplianceHandoverSuitabilityLetterState;
}

export interface MortgageDashboardApplication {
  id: string;
  createdDate: Date;
  lenderId: string;
  lender: string;
  lenderLogo: Attachment;
  lenderReference: string;
  lenderRefRegex: string;
  loanAmount: number;
  submittedDate: Date;
  offerExpiryDate: Date;
  state: APPLICATION_STATE;
  caseProgression: string;

  propCode: string;
  propCodeId: string;
  propCodeDescription: string;
}

export interface MortgageDashboard {
  factFind?: MortgageDashboardFactFind
  application?: ApplicationListDTO;
  appointment?: MortgageDashboardAppointment;
  helpToBuy?: MortgageDashboardHelpToBuy;
  offerQualification?: MortgageDashboardOfferQualification;
  desiredQualification?: number;
  introducer?: Introducer;
  division?: { id: string, name: string };
  client?: UserSelectorDTO;
  clientScheme?: keyof typeof CLIENT_SCHEME;
  clientSheetAvailable?: boolean;
  clientCategory?: CLIENT_CATEGORY;
  complianceHandoverState?: COMPLIANCE_HANDOVER_STATE;
  lender?: { id: string, name: string };
  tasksDueCount?: number;
  introducerLinkBroken?: boolean;
  state?: MORTGAGE_STATE_TYPE;
  hasActiveProtectionCase?: boolean;
  hasCreditReport?: boolean;
  subApplicants?: string[];
  creditSearchOptedOut?: string[];
  productTransferDeclaration?: ProductTransferDeclarationDTO;
  canCreateApplication: boolean;

  handovers?: MortgageDashboardHandover[];
  handoverDrafts?: MortgageDashboardHandover[];
  applications?: MortgageDashboardApplication[];
  imported?: boolean;

  exchangedDate?: Date;
  targetCompletionDate?: Date;
  targetExchangedDate?: Date;

  midasImportStatus?: MidasCaseStatus;

  [key: string]: any;
}

export interface MortgageTableParams extends TableParams {
  mortgageId: string;
  handoverId?: string;
}

type MidasCaseStatus = 'FULL_FAILURE' | 'PARTIAL_FAILURE' | 'WAITING' | 'SUCCESS';
