<div class="sms" [class.active]="status.mobileVerified">
  <div>
    SMS
    <span *ngIf="status.mobileVerified">
      with
      {{ status.mobilePrefix }} {{ status.mobile }}
    </span>
  </div>
</div>

<div class="app" [class.active]="status.twoFactorAuthEnabled">
  Authenticator App
</div>
