import { EventEmitter, Injectable, OnDestroy } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms/";
import { BehaviorSubject, ReplaySubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import {
  Confirmation,
  DialogService,
} from "../../../../../projects/client/src/app/shared/services/dialog.service";
import { Client } from "../../models/client";
import { Lender } from "../../models/lender";
import {
  MortgageDashboard,
  MortgageDashboardHandover,
} from "../../models/mortgage-dashboard";
import { UserService } from "../../services/user.service";
import { SelectOrAddClientService } from "../../shared-layout/side-panes/select-or-add-client/select-or-add-client.service";
import { atLeastOne } from "../../utils/form.validators";
import {
  APPLICATION_STATE,
  CLIENT_CATEGORY,
  CLIENT_CATEGORY_TYPES,
} from "../../utils/variables.data";

export interface ReloadMortgageDashboardEvent {
  tab: string;
  handover?: MortgageDashboardHandover;
}

@Injectable({ providedIn: "root"})
export class MortgagesService implements OnDestroy {
  public mortgageForm: FormGroup;

  public reloadMortgageDashboard = new EventEmitter();

  public mortgageDashboardDataLoaded = new ReplaySubject(1);

  public lenderSelected$ = new BehaviorSubject<Lender>(undefined);
  public lenders$ = new BehaviorSubject<Lender[]>(undefined);
  public statusSelected$ = new BehaviorSubject<APPLICATION_STATE>(undefined);

  public hostSelected = new EventEmitter();

  public data: MortgageDashboard = {} as any;

  public applicationHandovers: MortgageDashboardHandover[];

  public userStore: {
    complianceHandover?: any;
  } = {};

  public get approvedHandovers() {
    return this.data?.handovers?.filter((h) => h.handoverState == "APPROVED");
  }
  public get latestHandover() {
    const approved = this.approvedHandovers;
    return approved.length ? approved[0] : null;
  }

  public getDefaultHandoverForSidepane() {
    const draft = this.data?.handoverDrafts?.length
      ? this.data.handoverDrafts[0]
      : null;

    if (draft) return draft;

    return this.data.handovers?.length ? this.data.handovers[0] : null;
  }

  public get isRemo() {
    const remos: CLIENT_CATEGORY[] = [
      "FURTHER_ADVANCE",
      "PRODUCT_TRANSFER",
      "REMORTGAGE",
    ];
    return remos.includes(this.data?.clientCategory);
  }

  private destroy$ = new Subject();

  constructor(
    private fb: FormBuilder,
    private selectOrAddClientService: SelectOrAddClientService,
    private userService: UserService,
    private dialogService: DialogService
  ) {
    this.selectOrAddClientService.add
      .pipe(takeUntil(this.destroy$))
      .subscribe((client) => {
        this.patchClient(client);
      });
    this.selectOrAddClientService.select
      .pipe(takeUntil(this.destroy$))
      .subscribe((client) => {
        this.patchClient(client);
      });
  }

  private patchClient(client: Client) {
    if (this.mortgageForm && this.mortgageForm.get("client")) {
      this.mortgageForm.get("client").patchValue(client);
    }
  }

  public initForm() {
    const salesAdvisorValidator = this.userService.hasPermission([
      "MORTGAGE_NEW",
      "MORTGAGE_NEW_INTRODUCER",
      "MORTGAGE_NEW_DIVISION",
    ])
      ? Validators.required
      : undefined;

    this.mortgageForm = this.fb.group({
      propertyValue: ["", Validators.required],
      clientCategory: ["", Validators.required],
      clientType: ["", Validators.required],
      firstTimeBuyer: [null, Validators.required],
      clientScheme: [""],
      referralType: ["", Validators.required],
      desiredQualification: [
        { value: null, disabled: true },
        Validators.required,
      ],
      contactBy: this.fb.group(
        {
          "contactBy-0": [""],
          "contactBy-1": [""],
          "contactBy-2": [""],
        },
        { validator: atLeastOne(Validators.required) }
      ),
      notes: ["", Validators.required],
      reference: [""],
      client: this.fb.group(
        {
          id: [""],
          fullName: [""],
          email: ["", Validators.required],
          firstName: ["", Validators.required],
          lastName: ["", Validators.required],
          phonePrefix: ["+44", Validators.required],
          phone: ["", [Validators.required]], // do not use uk phone validator here EV-1377
        },
        Validators.required
      ),
      introducer: this.fb.group(
        {
          id: ["", Validators.required],
          name: [""],
          type: [""], // just for division wording
          primaryContactFirstName: [""],
          primaryContactLastName: [""],
          division: [""],
        },
        Validators.required
      ),
      division: this.fb.group(
        {
          id: ["", Validators.required],
          name: [""],
          region: [""],
          details: [""],
        },
        Validators.required
      ),
      salesAdvisor: this.fb.group(
        {
          id: ["", salesAdvisorValidator],
          fullName: [""],
        },
        salesAdvisorValidator
      ),
      advisor: this.fb.group({
        id: [""],
        fullName: [""],
      }),
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  public async showSuitabilityLetterRequiredConfirmation() {
    const confirmation: Confirmation = {
      title: "Suitability Letter",
      message:
        "You must send the suitability letter before submitting an application",
      acceptButtonType: "primary",
      rejectLabel: "View Suitability Letter",
    };

    const result = await this.dialogService.confirm(confirmation);

    return result;
  }
}
