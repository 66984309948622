import { ParamMap } from "@angular/router";
import { Subject } from "rxjs";

import { UserSimpleDTO } from "../../../models/user-simple.dto";
import { IntroducerApiService } from "../../../services/introducer-api.service";
import { MortgageApiService } from "../../../services/mortgage-api.service";
import { SwitchSalesAdvisorService } from "../../../shared-layout/side-panes/switch-sales-advisor/switch-sales-advisor.component";
import { MortgagesService } from "../mortgages.service";
import { Injectable } from "@angular/core";

@Injectable()
export class MortgageSwitchSalesAdvisorService implements SwitchSalesAdvisorService {
  public heading = "Change Sales Advisor";
  public advisorChange$ = new Subject<void>();

  constructor(
    private mortgageApiService: MortgageApiService,
    private introducerApiService: IntroducerApiService,
    private mortgagesService: MortgagesService,
  ) { }

  public async getAdvisors(): Promise<UserSimpleDTO[]> {
    const introducerId = this.mortgagesService.data.introducer.id;
    const divisionId = this.mortgagesService.data.division.id;
    const result = await this.introducerApiService.getDivisionSalesAdvisors(introducerId, divisionId);

    return result.items;
  }

  public changeSalesAdvisor(paramMap: ParamMap, salesAdvisorId: string): Promise<void> {
    const mortgageId = paramMap.get("id");

    return this.mortgageApiService.changeSalesAdvisor(mortgageId, salesAdvisorId);
  }
}
