import { Injectable } from "@angular/core";
import { Params } from "@angular/router";
import { UserSimple } from "../../../models";
import { UserApiService } from "../../../services/user-api.service";
import { SelectAdvisorForAssignmentService } from "../../../shared-layout/side-panes/select-advisor-for-assignment/select-advisor-for-assignment.service";

@Injectable()
export class RemortgageSelectAdvisorService extends SelectAdvisorForAssignmentService {
  constructor(private userApiService: UserApiService) {
    super();
  }

  public heading = "Select Advisor";

  public selectAdvisorsFn(routeParamMap: Params): Promise<UserSimple[]> {
    return this.userApiService.getMortgageAdvisors();
  }
}
