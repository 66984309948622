<div (click)="onBack()" class="overlay" [@fadeAnimation]></div>

<div class="sidepane-content">
  <div class="flex-space-between-layout template-header">
    <h1>Edit {{heading}} Reference</h1>
    <hf-custom-button type="button" class="button-plain-icon" icon="close" iconPos="center" (onClick)="onBack()">
    </hf-custom-button>
  </div>

  <div class="template-main">
    <form [formGroup]="form">
      <label>
        {{heading}} Reference
      </label>
      <hf-custom-text-field [group]="form"
                            inputFormControlName="reference"
                            validateOnBlur>
      </hf-custom-text-field>

      <div *ngIf="form.get('reference').errors?.pattern" class="hf-invalid-error" style="margin-top: 8px">
        Lender reference format invalid
      </div>
    </form>
  </div>

  <div class="template-footer">
    <hfc-button type="primary" [disabled]="form.invalid" [isLoading]="isSubmitting" (click)="onSubmit()">Save</hfc-button>
    <hfc-button type="secondary" (click)="onBack()">Cancel</hfc-button>
  </div>
</div>
