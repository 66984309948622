import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { Confirmation, DialogService } from '../../../../../../projects/client/src/app/shared/services/dialog.service';
import { environment } from '../../../../../environments/environment';
import { DocumentWithIdDTO } from '../../../models/document';
import { DocumentApiService } from '../../../services/document-api.service';
import { ToasterService } from '../../../services/toaster.service';
import { fadeAnimation, sidepaneMove } from '../../../shared/animations/sidepane-animation';
import { DocumentsTabService } from '../../tabs/documents/documents-tab.service';
import { JournalTabService } from '../../tabs/journal/journal-tab.service';
import { SelectDocumentTypeService } from '../select-document-type/select-document-type.service';
import { ToastService } from '../../../../../../projects/client/src/app/shared/services/toast.service';
import {Attachment} from "../../../models/attachment";
import {DocumentThumbnail} from "../../../../../../projects/client/src/app/shared/components/document-thumbnail/document-thumbnail.component";

@Component({
  selector: 'hf-view-document',
  templateUrl: 'view-document.component.html',
  styleUrls: ['view-document.component.scss',
    '../../../styles/sidepanes.partial.scss'],
  animations: [sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove]': 'true' }
})
export class ViewDocumentComponent implements OnInit, OnDestroy {
  public editDocument: FormGroup;
  private documentTypeSelectedSubscription: Subscription;
  public isLoading: boolean = true;
  private documentId: string;
  public documentWithIdDTO: DocumentWithIdDTO;
  private apiURL = environment.apiUrl;
  public submitResponse;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private journalApiService: DocumentApiService,
    private toasterService: ToasterService,
    private toastService: ToastService,
    private dialogService: DialogService,
    public selectDocumentTypeService: SelectDocumentTypeService,
    private journalTabService: JournalTabService,
    private documentsTabService: DocumentsTabService
  ) { }

  ngOnInit() {
    this.documentId = this.route.snapshot.params['documentId'];

    this.editDocument = this.fb.group({
      'type': ['', Validators.required],
      'otherTypeLabel': [{ value: '', disabled: true }, Validators.required]
    });

    this.editDocument.get('type').valueChanges.subscribe(value => {
      if (value == 'OTHER')
        this.editDocument.get('otherTypeLabel').enable();
      else
        this.editDocument.get('otherTypeLabel').disable();
    });

    this.documentTypeSelectedSubscription = this.selectDocumentTypeService.documentTypeChanged.subscribe(type => {
      this.editDocument.get('type').setValue(type);
    });

    this.journalApiService.viewDocument(this.documentId).then(documentWithIdDTO => {
      this.selectDocumentTypeService.selectedDocumentType = documentWithIdDTO.type;
      this.documentWithIdDTO = documentWithIdDTO;
      this.editDocument.patchValue(documentWithIdDTO);
      this.isLoading = false;
    }).catch(() => {
      this.toasterService.callToaster({ severity: 'error', summary: 'Error', detail: `Document not found` });
      this.onBack();
    });
  }

  public onDocumentDownload() {
    let attachment = this.documentWithIdDTO?.attachment;
    if (!this.documentWithIdDTO || !attachment)
      return;

    if (attachment.externalURL)
      window.open(attachment.externalURL);
    else
      window.open(this.apiURL + 'servlet/gcs/' + attachment.folder + '/' + attachment.filename);
  }

  public onBack() {
    this.router.navigate(['./../..'], { relativeTo: this.route });
  }

  ngOnDestroy(): void {
    this.documentTypeSelectedSubscription && this.documentTypeSelectedSubscription.unsubscribe();
    this.selectDocumentTypeService.selectedDocumentType = undefined;
  }

  public onSubmit() {
    this.journalApiService.editDocument(this.documentId, this.editDocument.value).then(() => {
      this.toasterService.callToaster({ severity: 'info', summary: 'Info', detail: `Document edited` });
      this.selectDocumentTypeService.documentTypeChanged.emit();
      this.journalTabService.reloadJournalTab.emit();
      this.documentsTabService.reloadDocumentsTab.emit();
      this.onBack();
    }).catch(err => {
      this.toasterService.callToaster({ severity: 'error', summary: 'Error', detail: `${err.error.error.message}` });
      this.onBack();
    });
  }

  private deleteDocument() {
    this.journalApiService.deleteDocument(this.documentId).then(() => {
      this.toasterService.callToaster({ severity: 'info', summary: 'Info', detail: `Document deleted` });
      this.selectDocumentTypeService.documentTypeChanged.emit();
      this.journalTabService.reloadJournalTab.emit();
      this.documentsTabService.reloadDocumentsTab.emit();
      this.onBack();
    }).catch(err => {
      this.toasterService.callToaster({ severity: 'error', summary: 'Error', detail: `${err.error.error.message}` });
      this.onBack();
    });
  }

  public async onRemove() {
    const confirmation: Confirmation = {
      title: "Delete Document",
      message: "Are you sure you want to delete this document? You will not be able to retrieve it.",
      acceptLabel: "Delete"
    };

    if (!await this.dialogService.confirm(confirmation)) {
      return;
    }

    this.deleteDocument();
  }
}
