import { Injectable } from "@angular/core";
import { ParamMap } from "@angular/router";

import { MortgageApiService } from "../../../services/mortgage-api.service";
import { ApproveComplianceService } from "../../../shared-layout/side-panes/approve-compliance/approve-compliance.component";

@Injectable()
export class MortgageApproveComplianceService implements ApproveComplianceService {
  constructor(private mortgageApiService: MortgageApiService) { }

  approveComplianceHandover(paramMap: ParamMap, body: { value: string; }): Promise<void> {
    return this.mortgageApiService.approveComplianceHandover(paramMap.get("id"), paramMap.get("handoverId"), body);
  }
}
