import { Injectable } from "@angular/core";

import { ApiService } from './api.service';
import { APPLICATION_STEP } from "../../../../projects/client/src/app/fact-find/enums.module";
import { FactFindData, ApplicantData, FactFindStepStatus, ApplicantUser } from "../../../../projects/client/src/app/fact-find/services/fact-find-api.service";
import { FACT_FIND_STATE } from "../utils/variables.data";
import { ListResult } from "./api-models";

export interface ExperianAMLResult {
  amlSuccessful: boolean;
  createdDate: Date;
  decision: "ACCEPT" | "REFER" | "ERROR";
  score: number;
  decisionText: string;
  decisionReasons: string;
  dobMatch: "YES" | "NO" | "NA";
  currentAddressMatch: "YES" | "NO" | "NA";
}

export interface HeronApplicantData extends ApplicantData {
  stepStatuses: FactFindStepStatus[];

  experianAMLResult: ExperianAMLResult;
}

export interface HeronFactFindData extends FactFindData {
  applicantsData: HeronApplicantData[];
  overallStepStatuses: FactFindStepStatus[];
  factFindStatus: FACT_FIND_STATE;
}

export interface ReturnSection {
  applicantId: string;
  step: APPLICATION_STEP;
  returnComment?: string;
}

@Injectable()
export class FactFindApiService {
  private readonly API_NAME: string = 'factFind';

  constructor(private apiService: ApiService) { }

  public async getFactFindDataHeron(mortgageId: string): Promise<HeronFactFindData> {
    const params = { mortgageId };

    return this.apiService
      .get(this.API_NAME, "getFactFindDataHeron", { params })
      .toPromise();
  }

  public async markSectionReturned(mortgageId: string, returnSection: ReturnSection): Promise<void> {
    const params = { mortgageId };

    returnSection.applicantId || delete returnSection.applicantId;

    return this.apiService
      .post(this.API_NAME, "markSectionReturned", returnSection, { params })
      .toPromise();
  }

  public async undoReturn(mortgageId: string, returnSection: ReturnSection): Promise<void> {
    const params = { mortgageId };

    returnSection.applicantId || delete returnSection.applicantId;

    return this.apiService
      .post(this.API_NAME, "undoReturn", returnSection, { params })
      .toPromise();
  }

  public async returnFactFind(mortgageId: string): Promise<void> {
    const params = { mortgageId };

    return this.apiService
      .post(this.API_NAME, "returnFactFind", undefined, { params })
      .toPromise();
  }

  public async reactivateFactFind(mortgageId: string): Promise<void> {
    const params = { mortgageId };

    return this.apiService
      .post(this.API_NAME, "reactivateFactFind", undefined, { params })
      .toPromise();
  }

  public async approveFactFind(mortgageId: string): Promise<void> {
    const params = { mortgageId };

    return this.apiService
      .post(this.API_NAME, "approveFactFind", undefined, { params })
      .toPromise();
  }

  public async getApplicants(mortgageId: string): Promise<ApplicantUser[]> {
    const result = await this.apiService
      .get(this.API_NAME, "getApplicants", { params: { mortgageId } })
      .toPromise<ListResult<ApplicantUser>>();

    return result.items.map((applicant: any) => {
      applicant.isLead = applicant.lead;
      delete applicant.lead

      return applicant;
    });
  }
}
