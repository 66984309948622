import { Component, Input, ElementRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

interface ParsedValue {
  formatted: string;
  numeric: number;
  expanded: boolean;
}

function parseRawValue(value: string): ParsedValue {
  const numeric = value.replace(/\D/g, "");
  const formatted = numeric.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const expanded = formatted.length > value.length;

  return { numeric: formatted.length ? +numeric : null, formatted, expanded };
}

@Component({
  selector: 'hf-text-field',
  templateUrl: './text-field.component.html',
  styleUrls: ['./text-field.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: TextFieldComponent,
    multi: true
  }]
})
export class TextFieldComponent implements ControlValueAccessor {
  @Input()
  public prefix: string;

  @Input()
  public isCurrency: boolean = false;

  @ViewChild("input")
  public inputElement: ElementRef;

  public focus() {
    this.inputElement && this.inputElement.nativeElement.focus();
  }

  public inputModel: string;

  public onInputModelChange() {
    const parsed = parseRawValue(this.inputModel);
    const input = this.inputElement.nativeElement;
    let selectionStart: number = input.selectionStart;

    if (parsed.expanded) {
      selectionStart++;
    }

    this.inputModel = parsed.formatted;

    setTimeout(() => {
      input.selectionStart = selectionStart;
      input.selectionEnd = selectionStart;
    })

    this.onChangeFn && this.onChangeFn(parsed.numeric);
  }

  private onChangeFn: (value: number) => void;

  writeValue(obj: any): void {
    // console.log("writeValue: ", obj);
    const parsed = parseRawValue(`${obj}`);

    this.inputModel = parsed.formatted;
  }
  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }
  registerOnTouched(fn: any): void {
    // throw new Error("Method not implemented.");
  }
  setDisabledState?(isDisabled: boolean): void {
    // throw new Error("Method not implemented.");
  }
}
