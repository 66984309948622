import { CommonModule, DatePipe } from '@angular/common';
import { Injectable, NgModule } from '@angular/core';

import { SelectPhonePrefixModule } from '../introducers/select-phone-prefix/select-phone-prefix.module';
import { AddMortgageModule } from './add-mortgage/add-mortgage.module';
import { AddNoteModule } from './add-note/add-note.module';
import { AssignCaseProgressionModule } from './assign-case-progression/assign-case-progression.module';
import { AssignMortgageModule } from './assign-mortgage/assign-mortgage.module';
import { CreateApplicationModule } from './create-application/create-application.module';
import { MortgageDashboardModule } from './mortgage-dashboard/mortgage-dashboard.module';
import { MortgagesListModule } from './mortgages-list/mortgages-list.module';
import { MortgagesService } from './mortgages.service';
import { OfferQualificationModule } from './offer-qualification/offer-qualification.module';
import { RequestRequalificationModule } from './request-requalification/request-requalification.module';
import { SelectAdvisorModule } from './select-advisor/select-advisor.module';
import { SelectApplicationModule } from './select-application/select-application.module';
import { SelectCaseProgressionModule } from './select-case-progression/select-case-progression.module';
import { SelectDateAndTimeModule } from './select-date-and-time/select-date-and-time.module';
import { SelectDivisionModule } from './select-division/select-division.module';
import { SelectHelpToBuyRegionModule } from './select-help-to-buy-region/select-help-to-buy-region.module';
import { SelectHelpToBuyStatusModule } from './select-help-to-buy-status/select-help-to-buy-status.module';
import { SelectIntroducerModule } from './select-introducer/select-introducer.module';
import { SelectOrAddClientModule } from '../../shared-layout/side-panes/select-or-add-client/select-or-add-client.module';
import { SelectSalesAdvisorModule } from './select-sales-advisor/select-sales-advisor.module';
import { SelectSolicitorModule } from './select-solicitor/select-solicitor.module';
import { SelectStatusModule } from './select-status/select-status.module';
import { UpdateStatusModule } from './update-application/update-application.module';
import { UpdateHelpToBuyModule } from './update-help-to-buy/update-help-to-buy.module';
import { ViewJournalEntryModule } from '../../shared-layout/side-panes/view-journal-entry/view-journal-entry.module';
import { OverlayModule } from '@angular/cdk/overlay';
import { ComplianceHandoverModule } from './compliance-handover/compliance-handover.module';
import { MonthlyIncomeModule } from './monthly-income/monthly-income.module';
import { SelectDebtConsolidationModule } from './select-debt-consolidation/select-debt-consolidation.module';
import { NotificationsComponent } from './notifications/notifications.component';
import { CustomButtonModule } from '../../shared/custom-button/custom-button.module';
import { RouterModule } from '@angular/router';
import { InterestedPartyApiService } from '../../services/interested-party-api.service';
import { ClientSharedModule } from '../../../../../projects/client/src/app/shared/client-shared.module';
import { CustomSpinnerModule } from '../../shared/custom-spinner/custom-spinner.module';
import { AddInterestedPartyComponent } from './add-interested-party/add-interested-party.component';
import { CustomTextFieldModule } from '../../shared/custom-text-field/custom-text-field.module';
import { SelectInterestedPartyTypeService } from './select-interested-party-type/select-interested-party-type.service';
import { SelectInterestedPartyTypeComponent } from './select-interested-party-type/select-interested-party-type.component';
import { SharedModule } from '../../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomSelectorModule } from '../../shared/custom-selector/custom-selector.module';
import { NotificationsService } from './notifications/notifications.service';
import { SwitchSalesAdvisorComponent, SWITCH_SALES_ADVISOR_SERVICE } from '../../shared-layout/side-panes/switch-sales-advisor/switch-sales-advisor.component';
import { ToggleNotificationsComponent } from './toggle-notifications/toggle-notifications.component';
import { ToggleNotificationService } from './toggle-notifications/toggle-notifications.service';
import { HfCheckboxModule } from '../../shared/hf-checkbox-component/hf-checkbox.module';
import { AddProtectionModule } from '../../shared-layout/side-panes/add-protection/add-protection.module';
import { TASK_API_SERVICE_CREATE_TASK_FN, TaskApiService, TaskApiServiceCreateTaskFn } from '../task/task-api.service';
import { SharedLayoutModule } from '../../shared-layout/shared-layout.module';
import {
  ARRANGE_APPOINTMENT_COMPONENT_ARRANGE_APPOINTMENT_FN,
  ArrangeAppointmentComponentArrangeAppointmentFn
} from '../../shared-layout/side-panes/arrange-appointment/arrange-appointment.component';
import { MortgageApproveComplianceService } from './services/mortgage-approve-compliance.service';
import { APPROVE_COMPLIANCE_SERVICE } from '../../shared-layout/side-panes/approve-compliance/approve-compliance.component';
import { MortgageEditReferenceService } from './services/mortgage-edit-reference.service';
import { EDIT_REFERENCE_SERVICE } from '../../shared-layout/side-panes/edit-reference/edit-reference.component';
import { NOT_PROCEEDING_SERVICE } from '../../shared-layout/side-panes/not-proceeding/not-proceeding.component';
import { MortgageNotProceedingService } from './services/mortgage-not-proceeding.service';
import { MortgageSwitchSalesAdvisorService } from './services/mortgage-switch-sales-advisor.service';
import { REQUEST_FACTFIND_SERVICE } from '../../shared-layout/side-panes/request-fact-find/request-fact-find.component';
import { MortgageRequestFactFindService } from './services/mortgage-request-factfind.service';
import { CreditReportOptComponent } from './credit-report-opt/credit-report-opt.component';
import { TriggerCreditReportComponent } from './trigger-credit-report/trigger-credit-report.component';
import { CustomSwitchToggleModule } from '../../shared/custom-switch-toggle/custom-switch-toggle.module';
import { AddTaskService } from '../../shared-layout/side-panes/add-task/add-task.component';
import { MortgageAddTaskService } from './services/mortgage-add-task.service';
import { MortgageDashboardFactFindService } from './mortgage-dashboard/fact-find/mortgage-dashboard-fact-find.service';
import { AddRemortgageComponent } from './add-remortgage/add-remortgage.component';
import { MortgageSelectAdvisorForAssignmentService } from './services/mortgage-select-advisor-for-assignment.service';
import { SelectAdvisorForAssignmentService } from '../../shared-layout/side-panes/select-advisor-for-assignment/select-advisor-for-assignment.service';
import { CustomTextAreaModule } from '../../shared/custom-textarea/custom-text-area.module';
import { RequestDocumentService } from '../../shared-layout/side-panes/request-document/request-document.component';
import { MortgageRequestDocumentService } from './services/mortgage-request-document.service';
import { MortgageSelectRequestDocumentTypeService } from './services/mortgage-select-request-document-type.service';
import { SelectRequestDocumentTypeService } from '../../shared-layout/side-panes/select-request-document-type/select-request-document-type.service';
import { SwitchClientCategoryComponent } from './switch-client-category/switch-client-category.component';
import {SelectApplicationLenderComponent} from './select-application-lender/select-application-lender.component';
import { MortgagesSharedModule } from './mortgages-shared.module';
import { SharedSelectApplicantService } from '../../shared-layout/side-panes/select-applicant/select-applicant.component';
import { UpdateTargetCompletionDateComponent } from './update-target-completion-date/update-target-completion-date.component';
import { UpdateTargetExchangeDateComponent } from './update-target-exchange-date/update-target-exchange-date.component';
import { NewComplianceHandoverModule } from './new-compliance-handover/new-compliance-handover.module';
import { MortgageSuitabilityPdfPreviewComponent } from './mortgage-suitability-pdf-preview/mortgage-suitability-pdf-preview.component';
import { ChangeIntroducerComponent } from './components/change-introducer/change-introducer.component';
import { EvergladesSharedMorule } from '../../shared/everglades-shared.module';
import { IfPermissionModule } from '../../directives/if-permission/if-permission.module';
import { SelectPropcoCodeComponent } from './select-propco-code/select-propco-code.component';
import { SelectPropcoService } from './select-propco-code/select-propco-code.service';

const mortgageTaskApiServiceCreateTaskFn: TaskApiServiceCreateTaskFn = (task, paramMap, taskApiService) => {
  const mortgageId = paramMap.get("id");

  return taskApiService.createMortgageTask(task, mortgageId);
};

const ArrangeMortgageFn: ArrangeAppointmentComponentArrangeAppointmentFn = (appointment, routeParamMap, appointmentApiService) =>
  appointmentApiService.arrangeMortgageAppointment(routeParamMap.get("id"), appointment);

const importedComponentModules = [
  CommonModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  ClientSharedModule,
  SharedModule,
  SharedLayoutModule,
  EvergladesSharedMorule,

  CustomButtonModule,
  CustomSpinnerModule,
  CustomTextFieldModule,
  CustomSelectorModule,
  HfCheckboxModule,
];

@Injectable()
class MortgageSelectApplicantService implements SharedSelectApplicantService {
  constructor(private mortgagesService: MortgagesService) { }

  public getApplicants() {
    return this.mortgagesService.data.applicants;
  }
}

@NgModule({
  imports: [
    OverlayModule,
    MortgagesListModule,
    MortgageDashboardModule,
    AssignMortgageModule,
    AddMortgageModule,
    SelectOrAddClientModule,
    SelectIntroducerModule,
    SelectDivisionModule,
    SelectSalesAdvisorModule,
    SelectAdvisorModule,
    SelectCaseProgressionModule,
    SelectDateAndTimeModule,
    SelectPhonePrefixModule,
    AddNoteModule,
    ViewJournalEntryModule,
    CreateApplicationModule,
    UpdateStatusModule,
    MortgagesSharedModule,
    SelectStatusModule,
    AssignCaseProgressionModule,
    SelectApplicationModule,
    SelectSolicitorModule,
    UpdateHelpToBuyModule,
    SelectHelpToBuyStatusModule,
    SelectHelpToBuyRegionModule,
    OfferQualificationModule,
    RequestRequalificationModule,
    ComplianceHandoverModule,
    NewComplianceHandoverModule,
    MonthlyIncomeModule,
    SelectDebtConsolidationModule,
    AddProtectionModule,
    CustomSwitchToggleModule,
    CustomTextAreaModule,
    IfPermissionModule,

    ...importedComponentModules
  ],
  exports: [],
  declarations: [
    NotificationsComponent,
    AddInterestedPartyComponent,
    SelectInterestedPartyTypeComponent,
    SwitchSalesAdvisorComponent,
    ToggleNotificationsComponent,
    CreditReportOptComponent,
    TriggerCreditReportComponent,
    AddRemortgageComponent,
    SwitchClientCategoryComponent,
    SelectApplicationLenderComponent,
    UpdateTargetCompletionDateComponent,
    UpdateTargetExchangeDateComponent,
    MortgageSuitabilityPdfPreviewComponent,
    ChangeIntroducerComponent,
    SelectPropcoCodeComponent,
  ],
  providers: [
    DatePipe,
    MortgagesService,
    InterestedPartyApiService,
    SelectInterestedPartyTypeService,
    NotificationsService,
    ToggleNotificationService,
    MortgageDashboardFactFindService,
    TaskApiService,
    SelectPropcoService,

    { provide: SelectAdvisorForAssignmentService, useClass: MortgageSelectAdvisorForAssignmentService },
    { provide: AddTaskService, useClass: MortgageAddTaskService },
    { provide: RequestDocumentService, useClass: MortgageRequestDocumentService },
    { provide: SelectRequestDocumentTypeService, useClass: MortgageSelectRequestDocumentTypeService },
    { provide: SharedSelectApplicantService, useClass: MortgageSelectApplicantService },

    { provide: TASK_API_SERVICE_CREATE_TASK_FN, useValue: mortgageTaskApiServiceCreateTaskFn },
    { provide: ARRANGE_APPOINTMENT_COMPONENT_ARRANGE_APPOINTMENT_FN, useValue: ArrangeMortgageFn },
    { provide: APPROVE_COMPLIANCE_SERVICE, useClass: MortgageApproveComplianceService },
    { provide: EDIT_REFERENCE_SERVICE, useClass: MortgageEditReferenceService },
    { provide: NOT_PROCEEDING_SERVICE, useClass: MortgageNotProceedingService },
    { provide: SWITCH_SALES_ADVISOR_SERVICE, useClass: MortgageSwitchSalesAdvisorService },
    { provide: REQUEST_FACTFIND_SERVICE, useClass: MortgageRequestFactFindService },
  ],
})

export class MortgagesModule { }
