import { Injectable } from "@angular/core";
import { ParamMap } from "@angular/router";
import { JournalBasic, MortgageApiService } from "../../../services/mortgage-api.service";
import { RequestFactFindService } from "../../../shared-layout/side-panes/request-fact-find/request-fact-find.component";
import { Subject } from "rxjs";
import { Store } from "@ngxs/store";
import { Mortgage } from "../state/mortgage.actions";

@Injectable()
export class MortgageRequestFactFindService implements RequestFactFindService {
  requestFrom: string[];
  requested$ = new Subject<void>();

  constructor(
    private store: Store,
    private mortgageApiService: MortgageApiService,
  ) { }

  public async requestFactFind(paramMap: ParamMap, journal: JournalBasic): Promise<void> {
    return this.mortgageApiService
      .requestFactFind(paramMap.get("id"), journal)
      .then(() => this.store.dispatch(new Mortgage.FactFindRequested()) as any);
  }
}
