<div class="title">
  Application Details
  <hfc-button type="tertiary" size="small" (click)="onEditClicked()">Edit</hfc-button>
</div>

<hf-expander heading="Circumstances and Objectives" iconUrl="/assets/icons/Home move.svg">
  <hf-new-client-sheet-objective [data]="data.objective"></hf-new-client-sheet-objective>
</hf-expander>

<hf-expander heading="Preferences" iconUrl="/assets/icons/Fact Find.svg">
  <hf-new-client-sheet-preference [data]="data.preference"></hf-new-client-sheet-preference>
</hf-expander>

<hf-expander heading="Budget and Affordability" iconUrl="/assets/icons/Loan.svg">
  <hf-new-client-sheet-budget [data]="data.budget" [applicantsIncomes]="data.applicantsIncomes"></hf-new-client-sheet-budget>
</hf-expander>

<hf-expander heading="Recommended Mortgage" iconUrl="/assets/icons/Mortgage.svg">
  <hf-new-client-sheet-recommended [data]="data.recommended" [dto]="data"></hf-new-client-sheet-recommended>
</hf-expander>

<div class="text-16-medium-grey24" style="margin-top: 8px">
  Documents
</div>

<div class="ev-thumbnails-container" style="margin-top: 24px">
  <hfc-document-thumbnail *ngIf="data.recommended.kfi" [document]="getDocumentThumbnail(data.recommended.kfi, 'KFI Document')"></hfc-document-thumbnail>
  <hfc-document-thumbnail *ngIf="data.recommended.recommendationEvidence" [document]="getDocumentThumbnail(data.recommended.recommendationEvidence, 'Evidence of Recommendation')"></hfc-document-thumbnail>
  <hfc-document-thumbnail *ngIf="data.recommended.complianceIllustration" [document]="getDocumentThumbnail(data.recommended.complianceIllustration, 'Compliance Illustration')"></hfc-document-thumbnail>
  <hfc-document-thumbnail *ngIf="data.recommended.affordabilityCalculator" [document]="getDocumentThumbnail(data.recommended.affordabilityCalculator, 'Affordability Calculator')"></hfc-document-thumbnail>
</div>

