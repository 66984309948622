import { Injectable } from '@angular/core';
import { MortgageApiService } from '../../../../services/mortgage-api.service';
import { ContactAttemptAdder } from '../../../../shared-layout/side-panes/log-contact-attempt/log-contact-attempt.component';
import { ContactAttemptDTO } from '../../../../models/contact-attempt.dto';

@Injectable()
export class MortgageContactAttemptAdderService implements ContactAttemptAdder {

  constructor(private mortgageApiService: MortgageApiService) {
  }

  addContactAttempt(contactAttemptDTO: ContactAttemptDTO, id: string): Promise<void> {
    return this.mortgageApiService.addContactAttempt(contactAttemptDTO, id);
  }

}
