import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserListComponent } from './user-list.component';
import { CustomTextFieldModule } from '../../../shared/custom-text-field/custom-text-field.module';
import { ItemsPerPageSelectorModule } from '../../../shared/item-per-page-selector/items-per-page-selector.module';
import { SharedModule } from '../../../shared/shared.module';
import { RouterModule } from '@angular/router';
import { PaginationSummaryBoxModule } from '../../../shared/pagination-summary-box/pagination-summary-box.module';
import { PaginatorModule } from '../../../shared/paginator/paginator.module';
import { CustomButtonModule } from '../../../shared/custom-button/custom-button.module';
import { IfPermissionModule } from '../../../directives/if-permission/if-permission.module';
import { SelectPhonePrefixModule } from '../../introducers/select-phone-prefix/select-phone-prefix.module';
import { EvergladesSharedMorule } from '../../../shared/everglades-shared.module';

@NgModule({
  declarations: [UserListComponent],
  imports: [
    CommonModule,
    RouterModule,
    CustomTextFieldModule,
    ItemsPerPageSelectorModule,
    PaginationSummaryBoxModule,
    PaginatorModule,
    CustomButtonModule,
    IfPermissionModule,
    SharedModule,
    EvergladesSharedMorule,
    SelectPhonePrefixModule
  ],
  exports: [UserListComponent]
})
export class UserListModule { }
