import { CurrencyPipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { BenefitCategoryName } from "../../../models/benefit.dto";
import { ClientNeed } from "../../../models/client-need";
import { ProtectionClientSheetDTO } from "../../../models/protection-client-sheet.dto";
import { ProtectionDashboardService } from "../components/protection-dashboard/protection-dashboard.service";

@Injectable()
export class ProtectionHandoverHelperService {
  public clientSheet: ProtectionClientSheetDTO;

  constructor(
    private protectionDashboardService: ProtectionDashboardService,
    private currencyPipe: CurrencyPipe,
  ) { }

  public get protectionDashboardDTO() { return this.protectionDashboardService.protectionDashboardDTO }

  public formatNeed(needId: string, withDetails = false) {
    const needs = this.protectionDashboardDTO?.clientNeeds || [];
    const need: ClientNeed = needs.find(n => n.id == needId);

    if (need) {
      const type = BenefitCategoryName.get(need.type);
      let formatted = "";

      switch(need.type) {
        case "MORTGAGE":
          if (need.mortgageType === 'LENDER')
            formatted = `${type}: ${this.formatCurrency(need.balance)}`;
          else
            formatted = `Equity loan: ${this.formatCurrency(need.balance)}`;
          break;
        case "INCOME_PROTECTION":
          formatted = `${type}: ${this.formatCurrency(need.amount)}`;
          break;
        case "PERSONAL_FAMILY":
          formatted = `${type}: ${this.formatCurrency(need.amount)}`;
          break;
      }

      if (withDetails) {
        formatted = `${formatted} ${need.details}`;
      }

      return formatted;
    }
  }

  public formatCover(coverId: string) {
    console.log("covers: ", this.clientSheet.covers, coverId);
    const index = this.clientSheet.covers.findIndex(c => c.id == coverId);
    const cover = this.clientSheet.covers[index];
    let rep = `Cover #${index+1}`;
    if (cover.provider) rep = `${rep} (${cover.provider})`;
    return rep;
  }

  private formatCurrency(value: number) {
    return this.currencyPipe.transform(value, "GBP", undefined, "1.0-0");
  }
}
