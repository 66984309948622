import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OfferQualificationComponent } from './offer-qualification.component';
import { RouterModule } from '@angular/router';
import { CustomButtonModule } from '../../../shared/custom-button/custom-button.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomRadioChecksFieldModule } from '../../../shared/custom-radio-checks-field/custom-radio-checks-field.module';
import { CustomTextAreaModule } from '../../../shared/custom-textarea/custom-text-area.module';
import { CustomTextFieldModule } from '../../../shared/custom-text-field/custom-text-field.module';
import { HfCheckboxModule } from '../../../shared/hf-checkbox-component/hf-checkbox.module';
import { SharedModule } from '../../../shared/shared.module';
import { CustomSpinnerModule } from '../../../shared/custom-spinner/custom-spinner.module';
import { TextFieldModule } from '../../../shared/text-field/text-field.module';

@NgModule({
  declarations: [OfferQualificationComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    CustomButtonModule,
    CustomTextAreaModule,
    CustomRadioChecksFieldModule,
    CustomTextFieldModule,
    TextFieldModule,
    HfCheckboxModule,
    CustomSpinnerModule
  ]
})
export class OfferQualificationModule { }
