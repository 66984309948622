import { Injectable } from "@angular/core";
import { Subject, Observable, NEVER } from "rxjs";
import { filter, map, takeUntil } from "rxjs/operators";

export interface SelectorSidepaneItemSelected<T = any> {
  key: string;
  item: T;
  other?: string;
  otherHint?: string;
}

@Injectable()
export class SelectorSidepaneService {
  public itemSelected$ = new Subject<SelectorSidepaneItemSelected>();
  public initialySelectedItem: any;
  public map: Map<string, string>;

  public subscribeByKey(key: string, takeUntilOservable?: Observable<any>) {
    return this.itemSelected$.pipe(
      takeUntil(takeUntilOservable || NEVER),
      filter(i => i.key === key),
      map(i => i.item)
    );
  }

  public subscribeItemSelectedByKey(key: string, takeUntilOservable?: Observable<any>) {
    return this.itemSelected$.pipe(
      takeUntil(takeUntilOservable || NEVER),
      filter(i => i.key === key),
    );
  }
}
