import { CurrencyPipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ExperianCreditSearchReportDTO } from '../../../../../../services/experian-credit-report-api.service';

@Component({
  selector: 'hf-credit-cards-category-details',
  templateUrl: './credit-cards-category-details.component.html',
  styleUrls: ['./credit-cards-category-details.component.scss'],
  providers: [CurrencyPipe]
})
export class CreditCardsCategoryDetailsComponent {
  @Input()
  public report: ExperianCreditSearchReportDTO;
}
