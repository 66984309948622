import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { NetMonthlyIncome, ApplicantIncome } from '../../../services/mortgage-api.service';
import { fadeAnimation, sidepaneMove } from '../../../shared/animations/sidepane-animation';
import { MonthlyIncomeService } from './monthly-income.service';
import { FormGroup, FormArray, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'hf-monthly-income',
  templateUrl: './monthly-income.component.html',
  styleUrls: [
    './monthly-income.component.scss',
    '../../../styles/sidepanes.partial.scss'
  ],
  animations: [sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove]': 'true' }
})
export class MonthlyIncomeComponent implements OnDestroy {
  public form = new FormGroup({ items: new FormArray([]) });
  public applicantsIncomes: ApplicantIncome[];

  private destroy$ = new Subject();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private monthlyIncomeService: MonthlyIncomeService
  ) {
    this.monthlyIncomeService.applicantsIncomes$.pipe(takeUntil(this.destroy$)).subscribe(applicantsIncomes => {
      if (!applicantsIncomes) {
        return;
      }

      this.applicantsIncomes = applicantsIncomes;

      const controls = this.applicantsIncomes.map(applicantIncome => new FormGroup({
        applicantId: new FormControl(applicantIncome.applicant.id),
        amount: new FormControl(null, Validators.required)
      }));

      this.form = new FormGroup({
        items: new FormArray(controls)
      });
    });

    const netMonthlyIncomes = this.monthlyIncomeService.netMonthlyIncomes$.value;

    if (netMonthlyIncomes) {
      const value = this.applicantsIncomes.map<NetMonthlyIncome>(ai => {
        const montlyIncome = netMonthlyIncomes.find(nmi => nmi.applicantId == ai.applicant.id);
        return { applicantId: ai.applicant.id, amount: montlyIncome ? montlyIncome.amount : 0 };
      })

      this.form.get("items").setValue(value);
    }
  }

  public onSubmit() {
    const value: NetMonthlyIncome[] = this.form.value.items;

    this.monthlyIncomeService.netMonthlyIncomes$.next(value);

    this.onBack();
  }

  public onBack() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }
}
