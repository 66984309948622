import { BehaviorSubject } from "rxjs";
import { MORTGAGE_OBJECTIVE } from "../../../utils/variables.data";
import { Injectable } from "@angular/core";

export interface SelectedObjective {
  objective: MORTGAGE_OBJECTIVE;
  otherObjective?: string;
}

@Injectable()
export class SelectObjectivesService {
  private readonly _objective = new BehaviorSubject<SelectedObjective>(undefined);

  public objective$ = this._objective.asObservable();
  public get objective() { return this._objective.getValue(); }

  public setObjective(objective: SelectedObjective) {
    this._objective.next(objective);
  }
}
