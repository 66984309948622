<div class="heading" (click)="isOpen = !isOpen">
  {{ heading }}

  <ng-container *ngIf="numOfSelected">
    ({{ numOfSelected }} selected)
  </ng-container>

  <i class="material-icons">expand_more</i>
</div>
  
<div class="content" *ngIf="isOpen" [@expandAnimation]>
  <form [formGroup]="form" class="flex-col">
    <hf-custom-text-field *ngIf="filterWith"
                           [listSearchMode]="true"
                           [group]="form"
                           listSearchModeControlName="filter"
                           fieldName="filter"
                           autocomplete="off"
                           type="text"
                           [placeholder]="filterPlaceholder"
                           icon="search"
                           preventValidation="true">
    </hf-custom-text-field>

    <div *ngIf="lazyFilter" class="hf-radio-element" [class.is-selected]="unassignedSelected">
      <div class="hf-radio-wrapper">
        <input type="radio" [attr.id]="'cs-' + inputId + '-unassigned'" [checked]="unassignedSelected" (click)="onUnassignedItemClicked()">
        <label [attr.for]="'cs-' + inputId + '-unassigned'">
          <span class="radio">Unassigned</span>
        </label>
      </div>
    </div>

    <ng-container *ngFor="let item of items; let i=index;">
      <div class="hf-radio-element" [class.is-selected]="isItemSelected(item)">
        <div class="hf-radio-wrapper">
          <input type="radio" [attr.id]="'cs-' + inputId + '-' + i" [checked]="isItemSelected(item)" (click)="onItemClick(item)">
          <label [attr.for]="'cs-' + inputId + '-' + i">
            <span class="radio">
              <ng-container *ngTemplateOutlet="itemTemplate || defaultItemTemplate, context: { $implicit: item }">
              </ng-container>
            </span>
          </label>
        </div>
      </div>
    </ng-container>
  </form>

  <ng-template #defaultItemTemplate let-item>{{ item.valueLabel }}</ng-template>
</div>
