<hf-dropdown *ngIf="handovers?.length > 1" [items]="handovers" [(ngModel)]="value" (ngModelChange)="onHandoverChange()">
  <ng-template #valueTemplate let-item>
    <div class="lender-value">
      <hf-attachment-image *ngIf="item?.lenderLogo" [attachment]="item?.lenderLogo" [alt]="item?.lender"></hf-attachment-image>
      <ng-container *ngIf="!item?.lenderLogo">{{ item?.lender || 'Unspecified lender' }}</ng-container>
    </div>
  </ng-template>

  <ng-template #itemTemplate let-item let-selected="isSelected">
    <div class="lender-item" [class.selected]="selected">
      <div class="name">{{ item?.lender || 'Unspecified lender' }}</div>
      <div class="reference">
        <ng-container *ngIf="item?.lenderReference">Ref: {{ item?.lenderReference }}</ng-container>
        <ng-container *ngIf="!item?.lenderReference">Awaiting reference</ng-container>
      </div>
    </div>
  </ng-template>
</hf-dropdown>
