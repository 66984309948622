<div class="sign-off-content">
  <hfc-application-details-review-section [data]="data" [config]="getConfig('APPLICATION_DETAILS')" (buttonClick)="onButtonClicked($event)" id="APPLICATION_DETAILS"></hfc-application-details-review-section>
  <div class="separator"></div>

  <ng-container *ngFor="let applicantData of data.applicantsData">
    <hfc-personal-details-review-section [applicantData]="applicantData" [config]="getConfig('YOUR_DETAILS', applicantData)" (buttonClick)="onButtonClicked($event)" id="YOUR_DETAILS"></hfc-personal-details-review-section>
    <div class="separator"></div>
  </ng-container>

  <hfc-dependants-review-section [data]="data" [config]="getConfig('DEPENDANTS')" (buttonClick)="onButtonClicked($event)" id="DEPENDANTS"></hfc-dependants-review-section>
  <div class="separator"></div>

  <ng-container *ngFor="let applicantData of data.applicantsData">
    <hfc-address-review-section [applicantData]="applicantData" [config]="getConfig('ADDRESS_HISTORY', applicantData)" (buttonClick)="onButtonClicked($event)" id="ADDRESS_HISTORY"></hfc-address-review-section>
    <div class="separator"></div>
  </ng-container>

  <ng-container *ngFor="let applicantData of data.applicantsData">
    <hfc-income-review-section [applicantData]="applicantData" [config]="getConfig('EMPLOYMENT_INCOME', applicantData)" (buttonClick)="onButtonClicked($event)" id="EMPLOYMENT_INCOME"></hfc-income-review-section>
    <div class="separator"></div>
  </ng-container>

  <ng-container *ngFor="let applicantData of data.applicantsData">
    <hfc-applicant-outgoings-review-section [applicantData]="applicantData" [config]="getConfig('MONTHLY_OUTGOINGS', applicantData)" (buttonClick)="onButtonClicked($event)" id="MONTHLY_OUTGOINGS"></hfc-applicant-outgoings-review-section>
    <div class="separator"></div>
  </ng-container>

  <hfc-total-outgoings-review-section [data]="data"></hfc-total-outgoings-review-section>
  <div class="separator"></div>

  <ng-container *ngFor="let applicantData of data.applicantsData">
    <hfc-credit-review-section [applicantData]="applicantData" [config]="getConfig('CREDIT_LIABILITIES', applicantData)" (buttonClick)="onButtonClicked($event)" id="CREDIT_LIABILITIES"></hfc-credit-review-section>

    <div class="separator"></div>
  </ng-container>

  <hfc-assets-review-section [data]="data" [config]="getConfig('ASSETS')" (buttonClick)="onButtonClicked($event)" id="ASSETS"></hfc-assets-review-section>
  <div class="separator"></div>

  <hfc-property-review-section [data]="data" [config]="getConfig('PROPERTY_DETAILS')" (buttonClick)="onButtonClicked($event)" id="PROPERTY_DETAILS"></hfc-property-review-section>
  <div class="separator"></div>

  <hfc-payment-review-section [data]="data" [config]="getConfig('PAYMENT_DETAILS')" (buttonClick)="onButtonClicked($event)" id="PAYMENT_DETAILS"></hfc-payment-review-section>

  <ng-container *ngIf="applicantSectionConfigs['DOCUMENTS']">
    <div class="separator"></div>

    <ng-container *ngFor="let applicantData of data.applicantsData">
      <hfc-applicant-documents-review-section [applicantData]="applicantData" [config]="getConfig('DOCUMENTS', applicantData)" (buttonClick)="onButtonClicked($event)" id="DOCUMENTS"></hfc-applicant-documents-review-section>

      <div class="separator"></div>
    </ng-container>

    <hfc-common-documents-review-section [data]="data"></hfc-common-documents-review-section>
  </ng-container>

  <div class="separator"></div>
</div>
