import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from "rxjs";
import { takeUntil } from 'rxjs/operators';

import { Task } from '../../../modules/task/task-api.service';
import { TaskService } from '../../../modules/task/task.service';
import { DataSourceFactory, TableSettingsService, TABLE_PARAMS_STORAGE_KEY, TableParams } from '../../../shared/table-utils/table-settings.service';
import { TaskTableParams } from '../../../models/task';

@Component({
  selector: 'hf-task-tab',
  templateUrl: './task-tab.component.html',
  styleUrls: ['./task-tab.component.scss'],
  providers: [TableSettingsService, { provide: TABLE_PARAMS_STORAGE_KEY, useValue: "app/mortgages/?tab=tasks" }]
})
export class TaskTabComponent implements AfterViewInit, OnDestroy {
  @Input()
  public dataSourceFactory: DataSourceFactory<TaskTableParams>;

  @Input()
  public showApplication: boolean = false;

  public data;
  public viewCompleted = false;

  private destroy$ = new Subject();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private tableSettingsService: TableSettingsService,
    private taskService: TaskService
  ) {
    this.tableSettingsService.data$.pipe(takeUntil(this.destroy$))
      .subscribe(data => this.data = data);

    this.taskService.reloadTasks$.pipe(takeUntil(this.destroy$))
      .subscribe(() => this.tableSettingsService.refresh());
  }

  public onTaskRowClicked(row: Task) {
    this.router.navigate(["viewTask", row.id], { relativeTo: this.route });
  }

  public onViewCompletedChanged() {
    setTimeout(() => this.tableSettingsService.refresh());
  }

  public ngAfterViewInit() {
    const taskDataSourceFactory: DataSourceFactory<TaskTableParams> = params => {
      params.viewCompleted = this.viewCompleted;
      return this.dataSourceFactory(params);
    };
    this.tableSettingsService.setDataSourceFactory(taskDataSourceFactory);
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }
}
