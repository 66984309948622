import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'hf-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: ToggleComponent,
    multi: true
  }]
})
export class ToggleComponent implements ControlValueAccessor {
  @Input()
  public theme: string;

  public value: boolean;

  public onChange(event: InputEvent) {
    this.value = (event.target as any).checked;

    this.changeFn && this.changeFn(this.value);
  }

  private changeFn: (value: boolean) => void;

  writeValue(obj: boolean): void {
    this.value = obj;
  }
  registerOnChange(fn: any): void {
    this.changeFn = fn;
  }
  registerOnTouched(fn: any): void {
    // throw new Error('Method not implemented.');
  }
  setDisabledState?(isDisabled: boolean): void {
    // throw new Error('Method not implemented.');
  }
}
