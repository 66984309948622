import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { APPLICANT_SCHEME_NAMES_ALL } from '../../../../../../../projects/client/src/app/fact-find/enums.module';
import { ToastService } from '../../../../../../../projects/client/src/app/shared/services/toast.service';
import { NewComplianceHandoverObjectiveDTO } from '../../../../models/new-compliance-handover-objective.dto';
import { NewComplianceHandoverViewDTO } from '../../../../models/new-compliance-handover-view.dto';
import { FormFieldService } from '../../../../services/form-field.service';
import { MortgageApiService } from '../../../../services/mortgage-api.service';
import { SelectorSidepaneItemSelected, SelectorSidepaneService } from '../../../../shared-layout/side-panes/selector-sidepane/selector-sidepane.service';
import { setControlEnabled } from '../../../../utils/functions/set-control-enabled';
import { COMPLIANCE_HANDOVER_STATE, MortgageDebtConsolidationReason, MortgageDebtConsolidationReasonName, MORTGAGE_OBJECTIVE, MORTGAGE_OBJECTIVE_NAME, RemortgageReason, RemortgageReasonName } from '../../../../utils/variables.data';
import { SelectedObjective, SelectObjectivesService } from '../../select-objectives/select-objectives.service';
import { NewComplianceHandoverComponent } from '../new-compliance-handover.component';

export const isAwatingOrApproved = (state: COMPLIANCE_HANDOVER_STATE) => {
  // console.log("isAwatingOrApproved: ", state);
  return state === "AWAITING_APPROVAL" || state === "APPROVED";
}

export const isLegacy = (dto: NewComplianceHandoverViewDTO) => {
  return dto.objectiveState === "LEGACY"
  || dto.preferenceState === "LEGACY"
  || dto.budgetState === "LEGACY"
  || dto.recommendedState === "LEGACY"
}

export const isAwatingOrApprovedNotLegacy = (dto: NewComplianceHandoverViewDTO) => {
  const isAwatingOrApproved = dto.state === "AWAITING_APPROVAL" || dto.state === "APPROVED";
  const izLegacy = isLegacy(dto);

  return isAwatingOrApproved && !izLegacy;
}

const isRemo = (o: MORTGAGE_OBJECTIVE, other: string) => {
  // console.log("isRemo: ", o, other);
  const remoObjectives: MORTGAGE_OBJECTIVE[] = ["RESI_RE", "RESI_RE_CR", "BTL_RE", "BTL_RE_CR", "RESI_PT", "BTL_PT"];
  const remoKeywords = ["Remortgage", "Remo"];

  if (!o) return false;

  return o === "OTHER"
    ? remoKeywords.some(k => other?.match(new RegExp(`\\b${k}\\b`, "i")))
    : remoObjectives.includes(o);
}

@Component({
  selector: 'hf-new-compliance-handover-objective',
  templateUrl: './new-compliance-handover-objective.component.html',
  styleUrls: ['./new-compliance-handover-objective.component.scss'],
  providers: [FormFieldService],
})
export class NewComplianceHandoverObjectiveComponent implements OnDestroy {
  public form = this.fb.group({
    objective: [null, Validators.required],
    otherObjective: [null],
    scheme: [null, Validators.required],
    remortgageReason: [null, Validators.required],
    otherRemortgageReason: [null],
    increasedBorrowingReason: [null, Validators.required],
    amountUsedToRepay: [null, Validators.required],
    debtConsolidation: [false, Validators.required],
    debtConsolidationNote: [null, Validators.required],
    debtConsolidationReason: [null, Validators.required],
    otherDebtConsolidationReason: [null],

    reasonForChange: [null],
  });

  public get value() { return this.form.value as NewComplianceHandoverObjectiveDTO }
  public get mortgageId() { return this.route.snapshot.paramMap.get("id") }
  public get handoverId() { return this.sidepane.handoverId }

  public control(name: string) { return this.form.get(name) }
  public enabled(name: string) { return this.form.get(name).enabled }

  public APPLICANT_SCHEME_NAMES = APPLICANT_SCHEME_NAMES_ALL;
  public MORTGAGE_OBJECTIVE_NAME = MORTGAGE_OBJECTIVE_NAME;
  public RemortgageReasonName = RemortgageReasonName;
  public MortgageDebtConsolidationReasonName = MortgageDebtConsolidationReasonName;

  private destroy$ = new Subject();

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private mortgageApi: MortgageApiService,
    private selectObjectivesService: SelectObjectivesService,
    private selectorSidepaneService: SelectorSidepaneService,
    private toast: ToastService,
    private sidepane: NewComplianceHandoverComponent,
    private formFieldService: FormFieldService,
  ) {
    this.setup();

    if (isAwatingOrApproved(this.sidepane.dto.state)) {
      this.control("reasonForChange").setValidators(Validators.required);
    }

    // mock delete
    // this.form.get("objective").setValue("RESI_RE");
    // this.form.get("objective").updateValueAndValidity();

    this.form.patchValue(this.sidepane.dto.objective);
    this.sidepane.assignForm(this.form);
  }

  public onSubmit(draft: boolean) {
    if (isAwatingOrApprovedNotLegacy(this.sidepane.dto) && !this.formFieldService.checkFormValidity(this.form)) {
      return;
    }

    const api = draft
      ? () => this.mortgageApi.saveComplianceHandoverObjectiveDraft(this.mortgageId, this.handoverId, this.value)
      : () => this.mortgageApi.saveComplianceHandoverObjective(this.mortgageId, this.handoverId, this.value);

    this.sidepane.save(this.form, api);
  }

  public onSelectDebtConsolidationReasonClicked() {
    if (this.value.debtConsolidationReason) {
      this.selectorSidepaneService.initialySelectedItem = {
        item: this.value.debtConsolidationReason,
        other: this.value.otherDebtConsolidationReason,
      }
    }
    this.router.navigate(["selectDebtConsolidationReason"], { relativeTo: this.route });
  }

  public onSelectRemortgageReasonClicked() {
    if (this.value.remortgageReason) {
      this.selectorSidepaneService.initialySelectedItem = {
        item: this.value.remortgageReason,
        other: this.value.otherRemortgageReason,
      }
    }
    this.router.navigate(["selectRemoReason"], { relativeTo: this.route });
  }

  public onSelectSchemeClicked() {
    this.selectorSidepaneService.initialySelectedItem = this.value.scheme;
    this.router.navigate(["selectScheme"], { relativeTo: this.route });
  }

  public onSelectObjectivesClicked() {
    const { objective, otherObjective } = this.value;
    const selectedObjective: SelectedObjective = { objective, otherObjective };

    this.selectObjectivesService.setObjective(selectedObjective);

    this.router.navigate(["selectObjectives"], { relativeTo: this.route });
  }

  private setup() {
    this.selectObjectivesService.objective$.pipe(takeUntil(this.destroy$)).subscribe((objective: SelectedObjective) => {
      // console.log("selected objective:", objective);
      objective && this.form.patchValue(objective);
    });

    this.selectorSidepaneService.subscribeByKey("selectScheme", this.destroy$).subscribe(scheme => {
      this.control("scheme").setValue(scheme);
    });

    this.selectorSidepaneService.subscribeItemSelectedByKey("selectRemoReason", this.destroy$).subscribe((reason: SelectorSidepaneItemSelected<RemortgageReason>) => {
      // console.log("selectRemoReason");
      if (reason.item[0] === "OTHER") {
        this.control("remortgageReason").setValue(reason.item);
        this.control("otherRemortgageReason").setValue(reason.other)
      } else {
        this.control("remortgageReason").setValue(reason.item);
        this.control("otherRemortgageReason").setValue(null);
      }
    });

    this.selectorSidepaneService.subscribeItemSelectedByKey("selectDebtConsolidationReason", this.destroy$).subscribe((reason: SelectorSidepaneItemSelected<MortgageDebtConsolidationReason>) => {
      if (reason.item[0] === "OTHER") {
        this.control("debtConsolidationReason").setValue(reason.item);
        this.control("otherDebtConsolidationReason").setValue(reason.other)
      } else {
        this.control("debtConsolidationReason").setValue(reason.item);
        this.control("otherDebtConsolidationReason").setValue(null);
      }
    });

    this.control("objective").valueChanges.subscribe(objective => {
      // console.log("objective: ", objective);
      setControlEnabled(this.control("remortgageReason"), isRemo(objective, this.value.otherObjective));
      // setTimeout(() => setControlEnabled(this.control("remortgageReason"), isRemo(objective, this.value.otherObjective)));
    });

    // this.control("otherObjective").valueChanges.subscribe(other => {
    //   setControlEnabled(this.control("remortgageReason"), isRemo(this.value.objective, other));
    // });

    this.control("debtConsolidation").valueChanges.subscribe(debtConsolidation => {
      setControlEnabled(this.control("amountUsedToRepay"), debtConsolidation);
      setControlEnabled(this.control("debtConsolidationNote"), debtConsolidation);
      setControlEnabled(this.control("debtConsolidationReason"), debtConsolidation);
    });

    this.control("objective").updateValueAndValidity();
    this.control("debtConsolidation").updateValueAndValidity();

    setControlEnabled(this.control("increasedBorrowingReason"), this.sidepane.dto.borrowingAmountIncreasing);
    setControlEnabled(this.control("reasonForChange"), isAwatingOrApproved(this.sidepane.dto.state));
    // console.log("borrowingAmountIncreasing: ", this.sidepane.dto.borrowingAmountIncreasing);

    this.sidepane.saveClick$.pipe(takeUntil(this.destroy$)).subscribe(draft => this.onSubmit(draft));
    this.form.statusChanges.subscribe(status => {
      setTimeout(() => this.sidepane.isValid = this.form.valid);
    });

  }

  public ngOnDestroy() {
    this.destroy$.next();
  }
}
