import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { UserSimple } from '../../../models';
import { ToasterService } from '../../../services/toaster.service';
import { UserApiService } from '../../../services/user-api.service';
import { fadeAnimation, sidepaneMove } from '../../../shared/animations/sidepane-animation';
import { USER_TYPE } from '../../../utils/variables.data';
import { HeronUserService } from '../user.service';

@Component({
  selector: 'hf-select-user-for-delete',
  templateUrl: 'select-user-for-delete.component.html',
  styleUrls: ['select-user-for-delete.component.scss',
    '../../../styles/sidepanes.partial.scss'
  ],
  animations: [sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove]': 'true' }
})

export class SelectUserForDeleteComponent implements OnInit {

  public submitResp;
  public userFirstName: string;
  public userLastName: string;
  public userId: string;
  public userType: USER_TYPE;
  public users: UserSimple[];
  public selectedUser: UserSimple;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userApiService: UserApiService,
    private userService: HeronUserService,
    private toasterService: ToasterService
  ) { }

  ngOnInit() {
    this.getRouteQueryParams();
    this.loadUsers();
  }

  private getRouteQueryParams() {
    this.userId = this.activatedRoute.snapshot.params['userId'];
    this.userFirstName = this.activatedRoute.snapshot.queryParams['userFirstName'];
    this.userLastName = this.activatedRoute.snapshot.queryParams['userLastName'];
    this.userType = this.activatedRoute.snapshot.queryParams['userType'];
  }

  private loadUsers() {
    if (this.userType === "ADVISOR_MORTGAGES") {
      this.userApiService.getMortgageAdvisors(this.userId).then(users => {
        this.users = users;
      });
    }

    if (this.userType === "ADVISOR_PROTECTION") {
      this.userApiService.getProtectionAdvisors(this.userId).then(users => {
        this.users = users;
      });
    }

    if (this.userType === "CASE_PROGRESSION") {
      this.userApiService.getCaseProgressionUsers().then(users => {
        this.users = this.filterUsers(users.items);
      })
    }
  }

  private filterUsers(users: UserSimple[]) {
    return users.filter(user => user.id != this.userId);
  }

  public submit() {
    if (!this.selectedUser)
      return;

    const assignMortgagesToId = this.userType !== "ADVISOR_PROTECTION" ? this.selectedUser.id : null;
    const assignProtectionCasesToId = this.userType === "ADVISOR_PROTECTION" ? this.selectedUser.id : null;

    this.userApiService.removeHeronUser(this.userId, assignMortgagesToId, assignProtectionCasesToId).then(() => {
      this.userService.reloadUsersDashboard.emit();
      this.toasterService.callToaster({ severity: 'info', summary: 'Info', detail: 'User deleted successfully' });
      this.router.navigate(['./../..'], { relativeTo: this.activatedRoute });
    }).catch(() => {
      this.toasterService.callToaster({ severity: 'error', summary: 'Error', detail: 'A problem occurred deleting this user. Please try again' });
      this.router.navigate(['./../..'], { relativeTo: this.activatedRoute });
    });
  }

  public onBack() {
    this.router.navigate(['./..'], { relativeTo: this.activatedRoute });
  }
}
