import { Injectable } from '@angular/core';
import { UserSimple } from '../../../../models';
import { MortgageApiService } from '../../../../services/mortgage-api.service';
import { ClientsGetter } from '../../../../shared-layout/side-panes/select-client/select-client.component';

@Injectable()
export class MortgageClientsGetterService implements ClientsGetter {

  constructor(private mortgageApiService: MortgageApiService) {
  }

  public getClients(id: string): Promise<UserSimple[]> {
    return this.mortgageApiService.getMortgageApplicants(id);
  }
}
