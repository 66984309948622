import {Component, OnDestroy, Optional} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {MortgageDashboardHandover} from '../../../models/mortgage-dashboard';
import {UserSimple} from '../../../models/user';
import {MortgagesService} from '../../../modules/mortgages/mortgages.service';
import {SelectApplicationLenderService, fixHandoverId} from '../../../modules/mortgages/select-application-lender/select-application-lender.service';
import {TaskApiService, Task} from '../../../modules/task/task-api.service';
import {TaskService} from '../../../modules/task/task.service';
import {ToasterService} from '../../../services/toaster.service';
import {fadeAnimation, sidepaneMove} from '../../../shared/animations/sidepane-animation';
import {setControlEnabled} from '../../../utils/functions/set-control-enabled';
import {SelectUserService} from '../select-user/select-user.service';
import {ToastService} from '../../../../../../projects/client/src/app/shared/services/toast.service';
import {MortgageApiService} from '../../../services/mortgage-api.service';
import { RemortgageDashboardService } from '../../../modules/remortgage/pages/remortgage-dashboard/remortgage-dashboard.service';
import { FormFieldService } from '../../../services/form-field.service';

export abstract class AddTaskService {
  public abstract getHeronUsers(paramMap: ParamMap): Promise<UserSimple[]>;
}

@Component({
  selector: 'hf-add-task',
  templateUrl: './add-task.component.html',
  styleUrls: [
    './add-task.component.scss',
    '../../../styles/sidepanes.partial.scss'
  ],
  animations: [sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove]': 'true' },
  providers: [
    SelectApplicationLenderService,
    FormFieldService,
  ],
})
export class AddTaskComponent implements OnDestroy {
  public form = this.fb.group({
    assignee: [null, Validators.required],
    summary: [null, Validators.required],
    description: [null, Validators.required],
    dueDate: [null, Validators.required],
    _handover: [null],
  });

  public isLoading = false;
  public isSubmitted = false;
  public isSubmitting = false;
  public handover: MortgageDashboardHandover;
  public get taskId() { return this.route.snapshot.paramMap.get("taskId") }
  public get value() { return this.form.value }
  public get handovers() { return this.mortgagesService.data?.handovers }
  public control(name: string) { return this.form.get(name) }

  private task: Task;
  private get isMortgage() { return !!this.route.snapshot.data.isMortgage }
  private destroy$ = new Subject();

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private selectUserService: SelectUserService,
    private toasterService: ToasterService,
    private toastService: ToastService,
    private addTaskService: AddTaskService,
    private taskApiService: TaskApiService,
    private taskService: TaskService,
    private mortgagesService: MortgagesService,
    private mortgageApiService: MortgageApiService,
    private selectApplicationLenderService: SelectApplicationLenderService,
    private formFieldService: FormFieldService,
    @Optional() private remortgageDashboardService: RemortgageDashboardService,
  ) {
    this.selectUserService.userSelected$.pipe(takeUntil(this.destroy$)).subscribe(userSelected => {
      this.form.get("assignee").setValue(userSelected);
    });

    this.selectApplicationLenderService.application$.pipe(takeUntil(this.destroy$))
      .subscribe(handover => this.control("_handover").setValue(handover?.id ? handover : null));

    setControlEnabled(this.control("_handover"), this.isMortgage);

    this.taskId && this.initialize();
  }

  private async initialize() {
    this.isLoading = true;

    try {
      this.task = await this.taskApiService.viewTask(this.taskId);

      setControlEnabled(this.control("_handover"), !!this.task.mortgageId);

      this.form.patchValue(this.task);
      this.task.application && this.control("_handover").setValue(this.task.application);

      this.selectApplicationLenderService.handovers = await this.mortgageApiService.getMortgageHandovers(this.task.mortgageId);
    } catch (e) {
      this.toastService.add("Error", "error", e);
    }

    this.isLoading = false;
  }

  public onSelectLender() {
    this.router.navigate(["selectApplication"], { relativeTo: this.route });
  }

  public onSelectAssigneeClicked() {
    this.selectUserService.initial = this.form.get("assignee").value;
    this.selectUserService.users$ = this.addTaskService.getHeronUsers(this.route.snapshot.paramMap);

    this.router.navigate(['selectUser'], { relativeTo: this.route });
  }

  public async onSubmitClicked() {
    this.isSubmitted = true;

    if (!this.formFieldService.checkFormValidity(this.form)) return;

    this.isSubmitting = true;

    const value = fixHandoverId(this.value);

    try {
      this.taskId
        ? await this.taskApiService.editTask(value, this.taskId)
        : await this.taskApiService.createTask(value, this.route.snapshot.paramMap)

      this.taskService.reloadTasks$.next();
      this.taskId && this.taskService.reloadCurrentTask$.next();
      this.mortgagesService.reloadMortgageDashboard.next();
      this.remortgageDashboardService && this.remortgageDashboardService.refreshJournal$.next();

      this.toasterService.callToaster({ severity: 'info', summary: 'Info', detail: 'Task saved' });

      this.onBack();
    } catch (err) {
      this.toasterService.callToaster({ severity: 'error', summary: 'Error', detail: `Failed to save task. Please try again ${err.error.error.message}` });
    }
    finally {
      this.isSubmitting = false;
    }
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }

  public onBack() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }
}
