import { EventEmitter, Inject, Injectable, InjectionToken } from '@angular/core';
import { MORTGAGE_DOCUMENT_TYPE, PROTECTION_DOCUMENT_TYPE } from '../../../utils/variables.data';

export const SELECT_DOCUMENT_TYPE_SERVICE_DOCUMENT_TYPE_NAME = new InjectionToken<Map<MORTGAGE_DOCUMENT_TYPE | PROTECTION_DOCUMENT_TYPE, string>>('DOCUMENT_TYPE_NAME');

@Injectable()
export class SelectDocumentTypeService {

  public documentTypeChanged = new EventEmitter<MORTGAGE_DOCUMENT_TYPE | PROTECTION_DOCUMENT_TYPE>();
  public selectedDocumentType: MORTGAGE_DOCUMENT_TYPE | PROTECTION_DOCUMENT_TYPE;

  constructor(
    @Inject(SELECT_DOCUMENT_TYPE_SERVICE_DOCUMENT_TYPE_NAME) public documentTypeName: Map<MORTGAGE_DOCUMENT_TYPE | PROTECTION_DOCUMENT_TYPE, string>
  ) {}
}
