import { Component, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { groupBy } from '../../../../../../../projects/client/src/app/shared/functions/group-by';
import { Confirmation, DialogService } from '../../../../../../../projects/client/src/app/shared/services/dialog.service';
import { ToastService } from '../../../../../../../projects/client/src/app/shared/services/toast.service';
import { BenefitCategory, BenefitTypeName } from '../../../../models/benefit.dto';
import { BenefitHandoverStateDTO, ProtectionComplianceHandoverViewDTO } from '../../../../models/protection-compliance-handover-view.dto';
import { ComplianceHandoverCover, ProtectionHandoverGeneralDTO, UnmetNeedDTO } from '../../../../models/protection-handover-general.dto';
import { FormFieldService } from '../../../../services/form-field.service';
import { ProtectionApiService } from '../../../../services/protection-api.service';
import { fadeAnimation, sidepaneMove2Cols } from '../../../../shared/animations/sidepane-animation';
import { setControlEnabled } from '../../../../utils/functions/set-control-enabled';
import { BenefitHandoverStateName } from '../../../../utils/variables.data';
import { ProtectionDashboardService } from '../../components/protection-dashboard/protection-dashboard.service';
import { ProtectionComplianceHandoverService } from './protection-compliance-handover.service';


export interface SwapBenefitCategoryButton {
  benefit: BenefitHandoverStateDTO;
  label: string;
}

const benefitCategory2route: { [key in BenefitCategory]: string } = {
  MORTGAGE: "mortgageProtection",
  PERSONAL_FAMILY: "personalProtection",
  INCOME_PROTECTION: "incomeProtection",
};

const benefitCategory2RefusedControl: { [key in BenefitCategory]: string } = {
  MORTGAGE: "clientRefusedMortgageProtection",
  PERSONAL_FAMILY: "clientRefusedPersonalProtection",
  INCOME_PROTECTION: "clientRefusedIncomeProtection",
};

@Component({
  selector: 'hf-protection-compliance-handover',
  templateUrl: './protection-compliance-handover.component.html',
  styleUrls: [
    './protection-compliance-handover.component.scss',
    '../../../../styles/sidepanes.partial.scss'
  ],
  animations: [sidepaneMove2Cols, fadeAnimation],
  host: { '[@sidepaneMove2Cols]': 'true' },
  providers: [FormFieldService]
})
export class ProtectionComplianceHandoverComponent implements OnDestroy {
  public form = this.fb.group({
    currentMonthlyBudget: [null, Validators.required],
    hasExistingProvisions: [false, Validators.required],

    // new compliance fields
    covers: [[]],
    shortfall: [false, Validators.required],
    shortfallDetails: [null, Validators.required],
    clientPrioritydetails: [null, Validators.required],
    notes: [null],

    clientRefusedMortgageProtection: [false],
    clientRefusedMortgageProtectionReason: [null],
    clientRefusedIncomeProtection: [false],
    clientRefusedIncomeProtectionReason: [null],
    clientRefusedPersonalProtection: [false],
    clientRefusedPersonalProtectionReason: [null],

    sendDocumentationToApplicants: [null, Validators.required],
    messageToApplicants: [null],

    unmetNeeds: this.fb.array([]),

    _MORTGAGE: [null, Validators.requiredTrue],
    _PERSONAL_FAMILY: [null, Validators.requiredTrue],
    _INCOME_PROTECTION: [null, Validators.requiredTrue],
  });

  public BenefitTypeName = BenefitTypeName;
  public BenefitHandoverStateName = BenefitHandoverStateName;

  public isLoading = false;
  public isSubmitting = false;
  public isSubmitted = false;
  public dto: ProtectionHandoverGeneralDTO;

  public benefits: { [key in BenefitCategory]?: BenefitHandoverStateDTO[] } = {};

  public get value() { return this.form.value as ProtectionHandoverGeneralDTO }
  // TODO: get from BE
  public get needs() { return this.protectionDashboardService.protectionDashboardDTO?.clientNeeds || []}

  public get unmetNeeds() { return this.control("unmetNeeds") as FormArray }

  public control(name: string) { return this.form.get(name) }
  public enabled(name: string) { return this.form.get(name).enabled }
  public swapBenefitCategoryButton: SwapBenefitCategoryButton;

  private get protectionCaseId() { return this.route.snapshot.paramMap.get("id") }
  private destroy$ = new Subject();
  private uncommitedChanges: ProtectionComplianceHandoverViewDTO;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private protectionApiService: ProtectionApiService,
    private protectionComplianceHandoverService: ProtectionComplianceHandoverService,
    private protectionDashboardService: ProtectionDashboardService,
    private toastService: ToastService,
    private dialogService: DialogService,
    private formDriverService: FormFieldService,
  ) {
    this.setupForm();
    this.initialize();

    this.protectionComplianceHandoverService.benefitChange$.pipe(takeUntil(this.destroy$)).subscribe(() => this.initialize());
  }

  public onAddCoverClicked() {
    this.router.navigate(["cover/add"], { relativeTo: this.route });
  }

  public onEditCoverClicked(cover: ComplianceHandoverCover) {
    this.router.navigate(["cover", cover.id], { relativeTo: this.route });
  }

  public getBenefitConverFor(benefit: BenefitHandoverStateDTO) {
    return benefit.coverFor.map(c => c).join(", ");
  }

  public async onSubmit() {
    this.isSubmitted = true;

    if (!this.checkValidity()) {
      this.formDriverService.scrollAndFocusInvalidControl();
      return;
    }

    this.isSubmitting = true;

    try {
      await this.protectionApiService.saveHandoverGeneral(this.protectionCaseId, this.form.value);

      this.toastService.add("Compliance Handover saved.");
      this.protectionComplianceHandoverService.handoverChange$.next("client-sheet");
      this.router.navigate([".."], { relativeTo: this.route });
    } catch (err) {
      this.toastService.add("Failed. Please try again.", "error", err);
    } finally {
      this.isSubmitting = false;
    }
  }

  public onBack() {
    this.router.navigate([".."], { relativeTo: this.route });
  }

  public onBenefitClicked(benefit: BenefitHandoverStateDTO) {
    this.uncommitedChanges = this.form.value;
    this.setupSwapBenefitCategoryButton(benefit);

    this.router.navigate([benefitCategory2route[benefit.category], benefit.id], { relativeTo: this.route });
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }

  public onSwapBenefitCategoryButtonClicked() {
    const benefit = this.swapBenefitCategoryButton.benefit;

    const toPersonalConfirmation: Confirmation = {
      title: "Switch to Personal/Family",
      message: "The contents of this side pane will be lost when switching the benefit type to Personal/Family",
      acceptLabel: "Switch"
    };
    const toMortgageConfirmation: Confirmation = {
      title: "Switch to Mortgage",
      message: "The contents of this side pane will be lost when switching the benefit type to Mortgage",
      acceptLabel: "Switch"
    };

    const confirmation = this.swapBenefitCategoryButton.benefit.category === "PERSONAL_FAMILY"
      ? toMortgageConfirmation
      : toPersonalConfirmation;

    const action = async () => {
      try {
        await this.protectionApiService.switchBenefitCategory(this.protectionCaseId, benefit.id);

        this.router.navigate(["."], { relativeTo: this.route });
        this.initialize();
      } catch (err) {
        this.toastService.add("Failed to swap benefit category.", "error", err);
      }
    }

    this.dialogService.confirmAndDo(confirmation, action);
  }

  private setupSwapBenefitCategoryButton(benefit: BenefitHandoverStateDTO) {
    this.swapBenefitCategoryButton = undefined;

    if (benefit.type !== "LEVEL_TERM_ASSURANCE" || benefit.category === "INCOME_PROTECTION") {
      return;
    }

    const label = benefit.category === "MORTGAGE"
      ? "Switch to Personal / Family"
      : "Switch to Mortgage";

    this.swapBenefitCategoryButton = { benefit, label };
  }

  private checkValidity(): boolean {
    Object.entries(this.form.controls).forEach(([_, control]) => {
      control.markAsDirty();
      control.updateValueAndValidity();
    });

    return this.form.valid;
  }

  private checkBenefitsValidity() {
    Object.entries(this.benefits).forEach(([key, benefits]) => {
      const control = this.control(`_${key}`);

      control.setValue(control.enabled && benefits.every(b => b.state === "COMPLETE"));
      // TODO: delete
      control.setValue(true);
    });
  }

  private async initialize() {
    this.isLoading = true;

    const value = await this.protectionApiService.viewComplianceHandover(this.protectionCaseId);
    const handover = this.protectionDashboardService.protectionDashboardDTO$.subscribe(() => {
      this.dto = value;
      this.form.patchValue(value);

      if (this.uncommitedChanges) {
        this.form.patchValue(this.uncommitedChanges);
        this.uncommitedChanges = undefined;
      }

      this.setupBenefits(value.benefits);
      this.checkBenefitsValidity();
      this.setupUnmetNeeds();

      this.isLoading = false;
    });
  }

  private setupUnmetNeeds() {
    console.log("isAllBenefitsComplete", this.isAllBenefitsComplete());
    const allComplete = this.isAllBenefitsComplete();
    setControlEnabled(this.control("unmetNeeds"), allComplete);

    if (!allComplete) return;

    this.unmetNeeds.clear();

    this.dto.unmetNeeds.forEach((need: UnmetNeedDTO) => {
      const unmet = this.fb.group({
        needId: [need.needId],
        reason: [need.reason, Validators.required],
      });

      this.unmetNeeds.push(unmet);
    });
  }

  private setupForm() {
    this.control("hasExistingProvisions").valueChanges.subscribe(hasExistingProvisions => {
      setControlEnabled(this.control("covers"), hasExistingProvisions);
      setControlEnabled(this.control("shortfall"), hasExistingProvisions);

      if (!hasExistingProvisions) {
        this.control("shortfall").setValue(false);
      }
    });
    this.control("hasExistingProvisions").setValue(false);

    this.control("shortfall").valueChanges
      .subscribe(shortfall => setControlEnabled(this.control("shortfallDetails"), shortfall));

    this.control("sendDocumentationToApplicants").valueChanges
      .subscribe(sendDocumentationToApplicants => setControlEnabled(this.control("messageToApplicants"), sendDocumentationToApplicants === true));
    this.control("sendDocumentationToApplicants").setValue(false);
  }

  private setupBenefits(benefits: BenefitHandoverStateDTO[]) {
    if (benefits.findIndex(benefit => !benefit.category) > -1) {
      this.toastService.add("There is an error with benefits. Please contact support.", "error");
      this.onBack();
      return;
    }

    this.benefits = groupBy(benefits, "category");
    this.benefits.MORTGAGE = this.benefits.MORTGAGE || [];
    this.benefits.PERSONAL_FAMILY = this.benefits.PERSONAL_FAMILY || [];
    this.benefits.INCOME_PROTECTION = this.benefits.INCOME_PROTECTION || [];


    Object.entries(this.benefits).forEach(([key, benefits]) => {
      const enabled = !!benefits.length || this.isCategoryInNeed(key as any);
      setControlEnabled(this.control(`_${key}`), enabled);
      const refusedEnabled = !benefits.length && this.isCategoryInNeed(key as any); 
      setControlEnabled(this.control(benefitCategory2RefusedControl[key]), refusedEnabled);

      this.control(benefitCategory2RefusedControl[key]).valueChanges.subscribe(refused => {
        setControlEnabled(this.control(`${benefitCategory2RefusedControl[key]}Reason`), refused);
      });
      this.control(benefitCategory2RefusedControl[key]).updateValueAndValidity();
    })
  }

  private isCategoryInNeed(cat: BenefitCategory): boolean {
    return this.needs.some(need => need.type === cat);
  }

  private isAllBenefitsComplete() {
    return (this.dto as any)?.benefits.every(b => b.state === "COMPLETE");
  }
}
