<div class="container" [class.hasDropdown]="handovers?.length > 1">
  <div *ngIf="isSpinnerVisible" class="loader">
    <hfc-spinner></hfc-spinner>
    Generating suitability letter, please wait…
  </div>

  <iframe *ngIf="!isLoading && url" [src]="url" [class.isPdfLoading]="isPdfLoading" (load)="onLoaded()" (error)="onError($event)" height="100%" width="100%"></iframe>
</div>

<div class="footer-container">
  <div class="footer">
    <div class="ev-buttons-layout">
      <hfc-button type="primary" postfixIcon="send" [isLoading]="isSubmitting" (click)="sendClick.emit()">Send</hfc-button>
      <hfc-button type="secondary" (click)="cancelClick.emit()">Cancel</hfc-button>
    </div>

    <hf-handover-dropdown *ngIf="handovers?.length > 1" [handovers]="handovers" [(ngModel)]="handover" (ngModelChange)="onHandoverChange()"></hf-handover-dropdown>
  </div>
</div>
