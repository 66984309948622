<div class="overlay" (click)="onBack()" [@fadeAnimation]></div>

<div class="sidepane-content">
  <div class="flex-space-between-layout template-header">

    <h1>Select {{'Division' | wording:mortgagesService.mortgageForm.get('introducer.type').value}}</h1>
    <hf-custom-button type="button"
                      class="button-plain-icon"
                      icon="close"
                      iconPos="center"
                      (click)="onBack()">
    </hf-custom-button>
  </div>

  <div class="template-main">
    <ng-container *ngIf="isLoading">
      <hf-custom-spinner sidepaneLoading="true"></hf-custom-spinner>
    </ng-container>

    <ng-container *ngIf="!isLoading">
      <form [formGroup]="selectDivisionFormGroup"
            class="flex-col">
        <ng-container *ngFor="let division of selectDivisionFormGroup.controls['divisions'].controls"
                      [formGroup]="division">
          <div class="hf-radio-element"
               [ngClass]="division.controls.id.value == selectDivisionFormGroup.controls['selectedItem'].value ? 'is-selected' : ''">
            <div class="hf-radio-wrapper">
              <input type="radio"
                     formControlName="radio"
                     name="radio"
                     [value]="division.controls.name.value"
                     [id]="'division_'+division.controls.id.value"
                     (click)="selectRadio($event)">
              <label [for]="'division_'+division.controls.id.value">
                <span class="radio">
                  {{division.controls.name.value}}
                  <span class="radio-details">
                    {{ division.controls.region?.value }} <br>
                    {{ division.controls.details?.value }}
                  </span>
                </span>
              </label>
            </div>
          </div>
        </ng-container>
      </form>
    </ng-container>

  </div>
</div>
