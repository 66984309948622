import {
  animate, animateChild, query,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';

export const fadeAnimation = trigger('fadeAnimation',
  [
    state('*',
      style({
        opacity: 1,
      })),
    state('void',
      style({
        opacity: 0,
      })),
    transition('* <=> void', animate('75ms')),
    transition('void <=> *', animate('75ms'))
  ]
);

// export const fadeAnimation = trigger('fadeAnimation', [
//   state('*', style({'margin-right':'0'})),
//   state('void', style({'margin-right':'-788px'})),
//   transition('* => void', animate('300ms')),
//   transition('void => *', animate('300ms'))
// ]);
