<div class="ev-details-heading" style="margin-top: 8px">
  Product Data
</div>

<div class="ev-details-container">
  <div class="ev-details-column">
    <div class="ev-details-entry">
      <div class="ev-details-entry-label">Scheme</div>
      <div class="ev-details-entry-value">{{ APPLICANT_SCHEME_NAMES_ALL.get(data.scheme) }}</div>
    </div>

    <div class="ev-details-entry">
      <div class="ev-details-entry-label">Lender</div>
      <div class="ev-details-entry-value">{{ data.lender?.name }}</div>
    </div>

    <div class="ev-details-entry">
      <div class="ev-details-entry-label">Loan amount</div>
      <div class="ev-details-entry-value"><hf-currency [value]="data.loanAmount" format="1.0-2"></hf-currency></div>
    </div>

    <div class="ev-details-entry">
      <div class="ev-details-entry-label">Reason for loan amount</div>
      <div class="ev-details-entry-value">{{ data.loanAmountReason }}</div>
    </div>

    <div class="ev-details-entry">
      <div class="ev-details-entry-label">Mortgage term</div>
      <div class="ev-details-entry-value">{{ data.mortgageTerm?.years }} years {{ data.mortgageTerm?.months }} months</div>
    </div>

    <div class="ev-details-entry">
      <div class="ev-details-entry-label">Reason for mortgage term</div>
      <div class="ev-details-entry-value">{{ data.mortgageTermReason }}</div>
    </div>

    <div class="ev-details-entry">
      <div class="ev-details-entry-label">Existing mortgage ERC?</div>
      <div class="ev-details-entry-value">{{ yesno(data.erc) }}</div>
    </div>

    <div *ngIf="data.erc" class="ev-details-entry">
      <div class="ev-details-entry-label">Existing mortgage ERC Amount</div>
      <div class="ev-details-entry-value"><hf-currency [value]="data.ercAmount" format="1.0-2"></hf-currency></div>
    </div>

    <div *ngIf="data.erc" class="ev-details-entry">
      <div class="ev-details-entry-label">Reason for ERC being incurred</div>
      <div class="ev-details-entry-value">{{ data.ercReason }}</div>
    </div>
  </div>

  <div class="ev-details-column">
    <div class="ev-details-entry">
      <div class="ev-details-entry-label">Initial interest rate</div>
      <div class="ev-details-entry-value"><hf-decimal [value]="data.initialRate"></hf-decimal>%</div>
    </div>

    <div *ngIf="data.scheme === 'HELP_TO_BUY'"class="ev-details-entry">
      <div class="ev-details-entry-label">HTB Percentage</div>
      <div class="ev-details-entry-value"><hf-decimal [value]="data.htb"></hf-decimal>%</div>
    </div>

    <div *ngIf="data.scheme === 'SHARED_OWNERSHIP'"class="ev-details-entry">
      <div class="ev-details-entry-label">Shared Ownership Percentage</div>
      <div class="ev-details-entry-value"><hf-decimal [value]="data.sharedOwnership"></hf-decimal>%</div>
    </div>

    <div *ngIf="data.scheme === 'SHARED_OWNERSHIP'"class="ev-details-entry">
      <div class="ev-details-entry-label">Justificaiton for shared ownership recommendation</div>
      <div class="ev-details-entry-value">{{ data.sharedOwnershipReason }}</div>
    </div>

    <div class="ev-details-entry">
      <div class="ev-details-entry-label">Monthly repayment</div>
      <div class="ev-details-entry-value"><hf-currency [value]="data.monthlyRepayment" format="1.0-2"></hf-currency></div>
    </div>

    <div class="ev-details-entry">
      <div class="ev-details-entry-label">Rate type</div>
      <div class="ev-details-entry-value">{{ MORTGAGE_PRODUCT_DATA_TYPE_NAME.get(data.type) }}</div>
    </div>

    <div class="ev-details-entry">
      <div class="ev-details-entry-label">Repayment method</div>
      <div class="ev-details-entry-value">{{ PROPERTY_PAYMENT_METHOD_NAME.get(data.repaymentMethod) }}</div>
    </div>

    <div *ngIf="data.repaymentMethod === 'PART_AND_PART'" class="ev-details-entry">
      <div class="ev-details-entry-label">Interest only</div>
      <div class="ev-details-entry-value"><hf-currency [value]="data.partAndPartInterest" format="1.0-2"></hf-currency></div>
    </div>

    <div *ngIf="data.repaymentMethod === 'PART_AND_PART'" class="ev-details-entry">
      <div class="ev-details-entry-label">Capital repayment</div>
      <div class="ev-details-entry-value"><hf-currency [value]="data.partAndPartRepayment" format="1.0-2"></hf-currency></div>
    </div>

    <div class="ev-details-entry">
      <div class="ev-details-entry-label">Transfer of equity</div>
      <div class="ev-details-entry-value">{{ yesno(data.transferOfEquity) }}</div>
    </div>

    <div *ngIf="data.illustrationDate" class="ev-details-entry">
      <div class="ev-details-entry-label">Date of illustration</div>
      <div class="ev-details-entry-value">{{ data.illustrationDate | date:'d MMM y' }}</div>
    </div>
  </div>

  <div class="ev-details-column">
    <div class="ev-details-entry">
      <div class="ev-details-entry-label">Product code</div>
      <div class="ev-details-entry-value">{{ data.productCode }}</div>
    </div>

    <div class="ev-details-entry">
      <div class="ev-details-entry-label">Reason for choosing product with this lender</div>
      <div class="ev-details-entry-value">{{ data.productReason }}</div>
    </div>

    <div *ngIf="objective === 'FA'" class="ev-details-entry">
      <div class="ev-details-entry-label">Reason for further advance</div>
      <div class="ev-details-entry-value">{{ data.furtherAdvanceReason }}</div>
    </div>

    <div *ngIf="objective === 'RESI_PT' || objective === 'BTL_PT'" class="ev-details-entry">
      <div class="ev-details-entry-label">Reason for product transfer</div>
      <div class="ev-details-entry-value">{{ data.productTransferReason }}</div>
    </div>

    <div *ngIf="objective === 'PORT'" class="ev-details-entry">
      <div class="ev-details-entry-label">Reason for porting</div>
      <div class="ev-details-entry-value">{{ data.portingReason }}</div>
    </div>

    <div *ngIf="objective === 'PORT_FA'" class="ev-details-entry">
      <div class="ev-details-entry-label">Reason for port and further advance</div>
      <div class="ev-details-entry-value">{{ data.portFurtherAdvanceReason }}</div>
    </div>

    <!-- TODO: add condition -->
    <div class="ev-details-entry">
      <div class="ev-details-entry-label">Reason for not porting current mortgage</div>
      <div class="ev-details-entry-value">{{ data.notPortingReason }}</div>
    </div>

    <div class="ev-details-entry">
      <div class="ev-details-entry-label">Product End Date Type</div>
      <div class="ev-details-entry-value">{{ MORTGAGE_PRODUCT_END_TYPE_NAME_ALL.get(data.endDateType) }}</div>
    </div>

    <div *ngIf="data.endDateType === 'DATE'" class="ev-details-entry">
      <div class="ev-details-entry-label">End Date</div>
      <div class="ev-details-entry-value">{{ data.endDate | date:'d MMM y' }}</div>
    </div>

    <div *ngIf="data.endDateType === 'YEARS_MONTHS'" class="ev-details-entry">
      <div class="ev-details-entry-label">End Date</div>
      <div class="ev-details-entry-value">{{ data.endYearsMonths.years }} years {{ data.endYearsMonths.months }} months</div>
    </div>

    <div class="ev-details-entry">
      <div class="ev-details-entry-label">Reason for product term</div>
      <div class="ev-details-entry-value">{{ data.productEndDateReason }}</div>
    </div>

    <div *ngIf="data.endDateType === 'LIFETIME'"class="ev-details-entry">
      <div class="ev-details-entry-label">Explain why lifetime product rate</div>
      <div class="ev-details-entry-value">{{ data.lifetimeProductRateReason }}</div>
    </div>
  </div>
</div>


<div class="ev-separator"></div>

<div class="ev-details-heading">
  Mortgage Notes
</div>

<div class="ev-details-container">
  <div class="ev-details-column">
    <div class="ev-details-entry">
      <div class="ev-details-entry-label">Offset</div>
      <div class="ev-details-entry-value">{{ yesno(data.offset) }}</div>
    </div>

    <div *ngIf="data.offset" class="ev-details-entry">
      <div class="ev-details-entry-label">Reason for offset</div>
      <div class="ev-details-entry-value">{{ data.offsetReason }}</div>
    </div>

    <div class="ev-details-entry">
      <div class="ev-details-entry-label">New ERC applicable?</div>
      <div class="ev-details-entry-value">{{ yesno(data.newErc) }}</div>
    </div>

    <div *ngIf="data.newErc" class="ev-details-entry">
      <div class="ev-details-entry-label">New ERC amount</div>
      <div class="ev-details-entry-value"><hf-currency [value]="data.newErcAmount" format="1.0-2"></hf-currency></div>
    </div>

    <div *ngIf="!data.newErc"class="ev-details-entry">
      <div class="ev-details-entry-label">Reason no ERC selected</div>
      <div class="ev-details-entry-value">{{ data.noErcReason }}</div>
    </div>

    <div class="ev-details-entry">
      <div class="ev-details-entry-label">Portable</div>
      <div class="ev-details-entry-value">{{ yesno(data.portable) }}</div>
    </div>
  </div>


  <div class="ev-details-column">
    <div class="ev-details-entry">
      <div class="ev-details-entry-label">Debt consolidation</div>
      <div class="ev-details-entry-value">{{ yesno(data.debtConsolidation) }}</div>
    </div>

    <div *ngIf="data.debtConsolidation" class="ev-details-entry">
      <div class="ev-details-entry-label">Debt consolidation matches circumstances and objectives?</div>
      <div class="ev-details-entry-value">{{ yesno(data.debtConsolidationMatchesObjective) }}</div>
    </div>

    <!-- objectives values -->
    <div *ngIf="data.debtConsolidation && data.debtConsolidationMatchesObjective" class="ev-details-entry">
      <div class="ev-details-entry-label">Amount of new loan used to repay existing debt</div>
      <div class="ev-details-entry-value"><hf-currency [value]="dto.objective.amountUsedToRepay" format="1.0-2"></hf-currency></div>
    </div>

    <div *ngIf="data.debtConsolidation && data.debtConsolidationMatchesObjective" class="ev-details-entry">
      <div class="ev-details-entry-label">Debt consolidation notes</div>
      <div class="ev-details-entry-value">{{ dto.objective.debtConsolidationNote }}</div>
    </div>

    <div *ngIf="data.debtConsolidation && data.debtConsolidationMatchesObjective" class="ev-details-entry">
      <div class="ev-details-entry-label">Reason for Debt Consolidation</div>
      <div class="ev-details-entry-value">{{ valueOther(dto.objective.debtConsolidationReason, MortgageDebtConsolidationReasonName, dto.objective.otherDebtConsolidationReason) }}</div>
    </div>

    <!-- recommeded values -->
    <div *ngIf="data.debtConsolidation && !data.debtConsolidationMatchesObjective" class="ev-details-entry">
      <div class="ev-details-entry-label">Amount of new loan used to repay existing debt</div>
      <div class="ev-details-entry-value"><hf-currency [value]="data.amountUsedToRepay" format="1.0-2"></hf-currency></div>
    </div>

    <div *ngIf="data.debtConsolidation && !data.debtConsolidationMatchesObjective" class="ev-details-entry">
      <div class="ev-details-entry-label">Debt consolidation notes</div>
      <div class="ev-details-entry-value">{{ data.debtConsolidationNote }}</div>
    </div>

    <div *ngIf="data.debtConsolidation && !data.debtConsolidationMatchesObjective" class="ev-details-entry">
      <div class="ev-details-entry-label">Reason for Debt Consolidation</div>
      <div class="ev-details-entry-value">{{ valueOther(data.debtConsolidationReason, MortgageDebtConsolidationReasonName, data.otherDebtConsolidationReasonNotAppropriateReason) }}</div>
    </div>
  </div>

  <div class="ev-details-column">
    <div class="ev-details-entry">
      <div class="ev-details-entry-label">Will the mortgage take the client beyond state retirement age?</div>
      <div class="ev-details-entry-value">{{ yesno(data.mortgageBeyondRetirement) }}</div>
    </div>

    <div *ngIf="data.mortgageBeyondRetirement" class="ev-details-entry">
      <div class="ev-details-entry-label">Detail retirement income</div>
      <div class="ev-details-entry-value">{{ data.retirementIncomeDetail }}</div>
    </div>

    <div *ngIf="dto.hasNotRepaidLiabilities"class="ev-details-entry">
      <div class="ev-details-entry-label">Reason for some existing liabilities not being repaid inline with new mortgage?</div>
      <div class="ev-details-entry-value">{{ data.liabilitiesNotRepaidReason }}</div>
    </div>

    <div class="ev-details-entry">
      <div class="ev-details-entry-label">Other notes</div>
      <div class="ev-details-entry-value">{{ data.notes }}</div>
    </div>
  </div>
</div>

<div class="ev-separator"></div>

<div class="ev-details-heading">
  Costs and Fees
</div>

<div class="ev-details-container">
  <div class="ev-details-column">
    <div class="ev-details-entry">
      <div class="ev-details-entry-label">Fees</div>
      <div class="ev-details-entry-value"><hf-currency [value]="data.fees" format="1.0-2"></hf-currency></div>
    </div>

    <div class="ev-details-entry">
      <div class="ev-details-entry-label">Add fees to loan</div>
      <div class="ev-details-entry-value">{{ yesno(data.feesToLoan) }}</div>
    </div>
  </div>

  <div class="ev-details-column">
    <div *ngIf="data.feesToLoan" class="ev-details-entry">
      <div class="ev-details-entry-label">Reason for adding</div>
      <div class="ev-details-entry-value">{{ valueOther(data.feesToLoanReason, FeesToLoanReasonName, data.otherFeesToLoanReason) }}</div>
    </div>
  </div>
</div>

