import { Injectable } from "@angular/core";
import { Router, ActivatedRoute, Data } from "@angular/router";
import { subscribeLastActivatedRouteSnapshotData } from "../../../../projects/client/src/app/fact-find/functions/subscribe-last-activated-route-snapshot-data";
import { Title } from "@angular/platform-browser";

export interface TitleParams {
  client?: string;
  introducer?: string;
  taskType?: string;
}

@Injectable({ providedIn: "root" })
export class DocumentTitleService {
  private latestRouteData: Data;
  private latestTitleParams: TitleParams;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private title: Title
  ) {
    subscribeLastActivatedRouteSnapshotData(router, route).subscribe(data => {
      this.latestRouteData = data;

      this.composeTitle();
    })
  }

  private composeTitle() {
    let title: string = this.latestRouteData?.title || this.latestRouteData?.name;

    if (!title) {
      return;
    }

    for (const key in this.latestTitleParams) {
      title = title.replace(`%${key}%`, this.latestTitleParams[key]);
    }

    if ((title.match(/%/g) || []).length >= 2) {
      return;
    }

    const fullTitle = `${title} - Everglades`;

    this.title.setTitle(fullTitle);
  }

  public setTitleParams(titleParams: TitleParams) {
    this.latestTitleParams = { ...this.latestTitleParams, ...titleParams };

    this.composeTitle();
  }

  public clearTitleParams() {
    this.latestTitleParams = {};
  }
}
