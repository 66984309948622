import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { CustomMenuComponent } from '../../../../shared/custom-menu/custom-menu.component';
import { ToolbarWithMenuConfig } from '../../../../shared/toolbar-with-menu/toolbar-with-menu.component';
import { DashboardDropdownMenuItem } from '../../../protection/components/protection-dashboard/protection-dashboard.component';
import { Mortgage } from '../../state/mortgage.actions';

@Component({
  selector: 'hf-mortgage-toolbar-with-menu',
  templateUrl: './mortgage-toolbar-with-menu.component.html',
  styleUrls: ['./mortgage-toolbar-with-menu.component.scss']
})
export class MortgageToolbarWithMenuComponent implements OnChanges {
  @Input()
  public items: DashboardDropdownMenuItem[];

  @Output()
  public itemClick = new EventEmitter<any>();

  @ViewChild(CustomMenuComponent)
  public dropdownMenu: CustomMenuComponent;

  public menuItems: DashboardDropdownMenuItem[];
  public mobileMenuItems: DashboardDropdownMenuItem[];

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  public ngOnChanges(changes: SimpleChanges) {
    if ("items" in changes) {
      this.initializeMenuItems();
    }
  }

  public onSendMessageClicked() {
    this.router.navigate(["sendMessage"], { relativeTo: this.route });
  }

  public onRequestDocumentClicked() {
    this.router.navigate(["requestDocument"], { relativeTo: this.route });
  }

  public onAddDocumentClicked() {
    this.router.navigate(["addDocument"], { relativeTo: this.route });
  }

  public onAddTask() {
    this.router.navigate(["addTask"], { relativeTo: this.route });
  }

  public onAppointmentClicked() {
    this.store.dispatch(new Mortgage.MenuAction("arrangeAppointment"));
  }

  public onAddNoteClicked() {
    this.router.navigate(["addNote"], { relativeTo: this.route });
  }

  public onLogContactClicked() {
    this.store.dispatch(new Mortgage.MenuAction("addContactAttempt"));
  }

  public onPinnedNoteClicked() {
    this.router.navigate(["pinned-note"], { relativeTo: this.route });
  }

  public onOpenMenuClicked() {
    this.dropdownMenu.toggleMenu();
  }

  public onItemSelected(item: DashboardDropdownMenuItem) {
    if (item.callback) {
      item.callback();
      return;
    }

    this.itemClick.next(item);
  }

  private initializeMenuItems() {
    this.menuItems = this.items.filter((i: any) => !i.button);
    this.mobileMenuItems = this.items;
  }
}
