import { Injectable } from "@angular/core";
import { AbstractControl, FormGroup } from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { ToastService } from "../../../../../projects/client/src/app/shared/services/toast.service";
import { setControlEnabled } from "../../utils/functions/set-control-enabled";
import * as R from "ramda";

export abstract class SidepaneHost {
  sh: SidepaneHelper;
  form?: FormGroup;
  ngOnDestroy?: () => void;

  // TODO: provide id from route?
  spOnSubmit?: () => void;
}

// TODO:
// - router navigation helper methods

export class SidepaneHelper {
  public toastService: ToastService;

  constructor(private sidepaneHost: SidepaneHost) {
    // console.log("sidepane host: ", this.sidepaneHost);
    // console.log("sidepane host injector: ", (this.sidepaneHost as any).injector as any);
    // (this.sidepaneHost as any).ngOnDestroy = () => console.log("destroy$ jooo");
  }


  public get value() { return R.clone(this.sidepaneHost.form?.value) }
  public get valid() { return this.sidepaneHost.form?.valid }
  public isSubmitting = false;
  public destroy$ = new Subject();

  public control(name: string): AbstractControl { return this.sidepaneHost.form?.get(name) }
  public enabled(name: string): boolean { return this.sidepaneHost.form?.get(name).enabled }

  public setEnabled(name: string, enabled: boolean): void {
    const control = this.control(name);
    setControlEnabled(control, enabled);
  }

  public goBack: () => void;
}
