import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'hf-details-container',
  templateUrl: './details-container.component.html',
  styleUrls: ['./details-container.component.scss']
})
export class DetailsContainerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
