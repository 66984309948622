<div class="left">
  <div *ngIf="isWhite" class="stuffing first" [class.background]="!first"></div>

  <hf-named-icon [icon]="getIcon()" [title]="item.category"></hf-named-icon>

  <div class="stuffing last" [class.background]="!last"></div>
</div>

<div class="right">
  <ng-content></ng-content>
</div>
