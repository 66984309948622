<form [formGroup]="form" class="flex-col">
  <label *ngIf="title">
    {{title}}
  </label>
  <hf-custom-text-field *ngIf="filterWith"
                        [listSearchMode]="true"
                        [group]="form"
                        listSearchModeControlName="filter"
                        fieldName="filter"
                        autocomplete="off"
                        type="text"
                        [placeholder]="filterPlaceholder"
                        icon="search"
                        preventValidation="true">
  </hf-custom-text-field>
  <ng-container *ngFor="let decoratedItem of decoratedItems; let i=index;">
    <div class="hf-radio-element" [class.is-selected]="decoratedItem.isSelected">
      <div class="hf-radio-wrapper">
        <input type="radio" [attr.id]="'cs-' + inputId + '-' + i" [checked]="decoratedItem.isSelected ? true : null" (click)="onClick(decoratedItem)">
        <label [attr.for]="'cs-' + inputId + '-' + i">
          <span class="radio">
            <ng-container *ngTemplateOutlet="itemTemplate || defaultItemTemplate, context: { $implicit: decoratedItem.item, index: i}">
            </ng-container>
          </span>
        </label>
      </div>
    </div>
  </ng-container>
</form>

<ng-template #defaultItemTemplate let-item>{{decoratedItem.item}}</ng-template>
