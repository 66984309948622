import {Component, OnDestroy} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {UserSimple} from '../../../models';
import {DocumentRequestDTO, DocumentTypeDTO, DocumentWithIdDTO} from '../../../models/document';
import {SelectApplicationLenderService} from '../../../modules/mortgages/select-application-lender/select-application-lender.service';
import {ToasterService} from '../../../services/toaster.service';
import {fadeAnimation, sidepaneMove} from '../../../shared/animations/sidepane-animation';
import {DocumentsTabService} from '../../tabs/documents/documents-tab.service';
import {JournalTabService} from '../../tabs/journal/journal-tab.service';
import {SelectClientService} from '../select-client/select-client.service';
import {SelectRequestDocumentTypeService} from '../select-request-document-type/select-request-document-type.service';
import {MortgagesService} from '../../../modules/mortgages/mortgages.service';
import { Store } from '@ngxs/store';
import * as R from "ramda";

export abstract class RequestDocumentService {
  abstract requestDocument(documentRequestDTO: DocumentRequestDTO, id: string): Promise<DocumentWithIdDTO>;
}

@Component({
  selector: 'hf-request-document',
  templateUrl: './request-document.component.html',
  styleUrls: [
    './request-document.component.scss',
    '../../../styles/sidepanes.partial.scss'
  ],
  animations: [sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove]': 'true' },
  providers: [SelectApplicationLenderService],
})
export class RequestDocumentComponent implements OnDestroy {
  public form = this.fb.group({
    clientId: [null, Validators.required],
    messageToClient: [null, Validators.required],
    internalNotes: [null],
    requestedTypes: [null, Validators.required],
    _handover: [null],
  })

  public client: UserSimple;
  public get map() { return this.selectRequestDocumentTypeService.map }
  public get value() { return R.clone(this.form.value) }
  public get handovers() { return this.mortgagesService.data?.handovers }
  public control(name: string) { return this.form.get(name) }

  private destroy$ = new Subject();

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private toasterService: ToasterService,
    private journalTabService: JournalTabService,
    private documentsTabService: DocumentsTabService,
    private selectClientService: SelectClientService,
    private requestDocumentService: RequestDocumentService,
    private selectRequestDocumentTypeService: SelectRequestDocumentTypeService,
    private selectApplicationLenderService: SelectApplicationLenderService,
    private mortgagesService: MortgagesService,
    private store: Store,
  ) {
    this.selectRequestDocumentTypeService.selected$.pipe(takeUntil(this.destroy$)).subscribe(requestedTypes => {
      this.form.patchValue({ requestedTypes });
    });

    this.selectClientService.clientChanged.pipe(takeUntil(this.destroy$)).subscribe(client => {
      this.client = client;
      this.form.get("clientId").setValue(client.id);
    });

    this.selectApplicationLenderService.application$.pipe(takeUntil(this.destroy$))
      .subscribe(handover => this.control("_handover").setValue(handover?.id ? handover : null));
  }

  public onSelectLender() {
    this.router.navigate(["selectApplication"], { relativeTo: this.route });
  }

  public onSelectDocumentTypeClicked() {
    if (this.value.requestedTypes?.length) {
      this.selectRequestDocumentTypeService.initial = this.value.requestedTypes;
    }

    this.router.navigate(["selectDocumentType"], { relativeTo: this.route });
  }

  public get id(): string {
    return this.route.snapshot.params['id'];
  }

  public getDocumentTypeName(document: DocumentTypeDTO): string {
    return this.selectRequestDocumentTypeService.map.get(document.type);
  }

  public async onRequest() {
    const value = this.value;

    if (value._handover) {
      value.handoverId = value._handover.id;
      delete value._handover;
    }

    try {
      await this.requestDocumentService.requestDocument(value, this.id);

      const saveAction = this.route.snapshot.data.saveAction;
      if (saveAction) {
        this.store.dispatch(new saveAction());
      }

      this.toasterService.callToaster({ severity: 'info', summary: 'Info', detail: 'Document requested from client' });
      this.journalTabService.reloadJournalTab.emit();
      this.documentsTabService.reloadDocumentsTab.emit();

      this.onBack();
    } catch (err) {
      this.toasterService.callToaster({ severity: 'error', summary: 'Error', detail: `Failed to request document. Please try again. ${err.error.error.message}` });
    }
  }

  public onBack() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.selectClientService.selectedClient = undefined;
  }
}
