<div (click)="onBack()" class="overlay" [@fadeAnimation]></div>

<div class="sidepane-content">
  <div class="flex-space-between-layout template-header">

    <h1>Delete {{userFirstName}} {{userLastName}}</h1>
    <hf-custom-button type="button"
                      class="button-plain-icon"
                      icon="close"
                      iconPos="center"
                      (click)="onBack()">
    </hf-custom-button>
  </div>

  <div class="template-main">
    <form>
      <!-- Mortgage Advisor selector -->
      <div class="hf-selector">
        <label>Mortgage Advisor</label>
        <div class="hf-selector-container hf-selector-has-value" [class.hf-selector-black-color]="!!selectedMortgageAdvisor"
             (click)="onSelectMortgageAdvisor()">
          <div class="hf-selector-label-area">
            <div *ngIf="selectedMortgageAdvisor" class="title">{{selectedMortgageAdvisor.fullName}}</div>
            <div *ngIf="!selectedMortgageAdvisor" class="title">Select Mortgage Advisor</div>
          </div>
          <i class="material-icons chevron-icon">chevron_right</i>
        </div>
      </div>
      <!-- Default Protection Advisor selector -->
      <div class="hf-selector">
        <label>Default Protection Advisor</label>
        <div class="hf-selector-container hf-selector-has-value" [class.hf-selector-black-color]="!!selectedDefaultProtectionAdvisor"
             (click)="onSelectProtectionAdvisor()">
          <div class="hf-selector-label-area">
            <div *ngIf="selectedDefaultProtectionAdvisor" class="title">{{selectedDefaultProtectionAdvisor.fullName}}</div>
            <div *ngIf="!selectedDefaultProtectionAdvisor" class="title">Select Protection Advisor</div>
          </div>
          <i class="material-icons chevron-icon">chevron_right</i>
        </div>
      </div>
    </form>
  </div>

  <div class="template-footer">
    <hf-custom-button type="submit"
                      class="button-primary"
                      label="Delete User"
                      [callable]="true"
                      [disabled]="!selectedMortgageAdvisor || !selectedDefaultProtectionAdvisor"
                      (onClick)="submit()">
    </hf-custom-button>

    <hf-custom-button type="button"
                      class="button-secondary"
                      label="Cancel"
                      (click)="onBack()">
    </hf-custom-button>
  </div>
</div>

<div class="sidepane-child-anchor">
  <router-outlet></router-outlet>
</div>
