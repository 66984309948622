<div class="overlay" (click)="onBack()" [@fadeAnimation]></div>

<div class="sidepane-content">
  <div class="flex-space-between-layout template-header">

    <h1>Select Introducer</h1>
    <hf-custom-button type="button"
                      class="button-plain-icon"
                      icon="close"
                      iconPos="center"
                      (click)="onBack()">
    </hf-custom-button>
  </div>

  <div class="template-main">
    <div class="select-introducer-pane-search">
      <hf-custom-text-field [listSearchMode]="true"
                            [group]="searchBoxFormGroup"
                            listSearchModeControlName="introducerNameFilter"
                            fieldName="introducerNameFilter"
                            autocomplete="off"
                            type="text"
                            placeholder="Search introducer …"
                            icon="search"
                            preventValidation="true">
      </hf-custom-text-field>
    </div>


    <ng-container *ngIf="isLoading">
      <hf-custom-spinner sidepaneLoading="true"></hf-custom-spinner>
    </ng-container>

    <ng-container *ngIf="!isLoading">
      <form [formGroup]="selectIntroducerFormGroup"
            class="flex-col">
        <ng-container *ngFor="let introducer of selectIntroducerFormGroup.controls['introducers'].controls | inputFieldTextSearch:searchBoxFormGroup.get('introducerNameFilter').value:'name'"
                      [formGroup]="introducer">
          <div class="hf-radio-element"
               [ngClass]="introducer.controls.id.value == selectIntroducerFormGroup.controls['selectedItem'].value ? 'is-selected' : ''">
            <div class="hf-radio-wrapper">
              <input type="radio"
                     formControlName="radio"
                     name="radio"
                     [value]="introducer.controls.name.value"
                     [id]="'introducer_'+introducer.controls.id.value"
                     (click)="selectRadio($event)">
              <label [for]="'introducer_'+introducer.controls.id.value">
                <span class="radio">
                  {{introducer.controls.name.value}}
                  <span class="radio-details">
                    {{introducer.controls.primaryContactFirstName?.value}} {{introducer.controls.primaryContactLastName?.value}}
                  </span>
                </span>
              </label>
            </div>
          </div>
        </ng-container>
      </form>
    </ng-container>

  </div>
</div>
