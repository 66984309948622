import { Injectable } from "@angular/core";
import { Environment } from "./environment.service";

@Injectable({ providedIn: "root" })
export class SolutionBuilderApiService {
  constructor(private environment: Environment) { }

  public openWithClientData(protectionCaseId: string) {
    const url = `${this.environment.apiUrl}servlet/solutionBuilder/openWithClientData?protectionCaseId=${protectionCaseId}`;

    window.open(url);
  }

  public directApply(protectionApplicationId: string) {
    const url = `${this.environment.apiUrl}servlet/solutionBuilder/directApply?protectionApplicationId=${protectionApplicationId}`;

    window.open(url);
  }
}
