import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'hf-client-selector',
  templateUrl: './client-selector.component.html',
  styleUrls: ['./client-selector.component.scss']
})
export class ClientSelectorComponent {
  @Input()
  public form: FormGroup;

  @Input()
  public readonly: boolean;

  @Output()
  public selectClient = new EventEmitter();
}
