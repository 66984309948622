<div class="container">
  <div class="ev-dashboard-header">
    <hf-nav-button *ifMedia="'desktop'"></hf-nav-button>

    <div class="heading">
      <hf-custom-button type="button" class="button-plain-icon" icon="arrow_back" iconPos="center" (click)="onBack()">
      </hf-custom-button>

      <ng-container *ifMedia="'desktop'">
        <img src="/assets/icons/Mortgage.svg">
      </ng-container>

      <div class="heading-content">
        <h1>
          {{ data?.client?.fullName }}
        </h1>
        <hf-warning-badge *ngIf="containsVulnerableCustomer"></hf-warning-badge>
      </div>
    </div>

    <hf-mortgage-status-badge
      [mortgage]="data"
      [showFailedMidasBadge]="data?.midasImportStatus === 'PARTIAL_FAILURE' || data?.midasImportStatus === 'FULL_FAILURE'"
    ></hf-mortgage-status-badge>

    <ng-container *ifPermission="'MORTGAGE_MANAGE'">
      <div *ngIf="primaryActionButtonLabel" class="single-button-wrap">
        <hf-custom-button type="button"
                          class="button-primary"
                          label="{{primaryActionButtonLabel}}"
                          icon="{{primaryActionButtonIcon}}"
                          iconPos="left"
                          textPos="left"
                          (onClick)="onPrimaryActionButtonClick()">
        </hf-custom-button>
      </div>

      <hf-mortgage-toolbar-with-menu [items]="mortgageMenuItems" (itemClick)="onItemSelected($event)"></hf-mortgage-toolbar-with-menu>
    </ng-container>
  </div>

  <hf-custom-spinner *ngIf="!data?.id" style="margin-top: 20px;" [darkTheme]="false"></hf-custom-spinner>

  <hf-tab-group *ngIf="data?.id" [activeKey]="tab" (activeKeyChange)="onActiveTabKeyChanged($event)">
    <hf-tab-panel label="General">
      <ng-template #content>
        <hf-mortgage-dashboard-general [data]="data">
        </hf-mortgage-dashboard-general>
      </ng-template>
    </hf-tab-panel>
    <hf-tab-panel key="imported" label="Imported" *ngIf="data?.imported">
      <ng-template #content>
        <hf-mortgage-dashboard-imported>
        </hf-mortgage-dashboard-imported>
      </ng-template>
    </hf-tab-panel>
    <hf-tab-panel key="journal" label="Journal">
      <ng-template #content>
        <hf-journal-tab [pinnedNote]="data.pinnedNote" [dataSourceFactory]="journalDataSourceFactory" [showApplication]="!isIntroducer" permissionsToManage="MORTGAGE_MANAGE">
        </hf-journal-tab>
      </ng-template>
    </hf-tab-panel>
    <hf-tab-panel key="documents" label="Documents" *ifPermission="'MORTGAGE_MANAGE'">
      <ng-template #content>
        <hf-documents-tab [dataSourceFactory]="documentsDataSourceFactory" [showApplication]="true" [downloadZipParams]="'mortgageId=' + id">
        </hf-documents-tab>
      </ng-template>
    </hf-tab-panel>
    <hf-tab-panel key="fact-find" label="Fact Find" *ngIf="isFactFindVisible">
      <ng-template #content>
        <hf-mortgage-dashboard-fact-find>
        </hf-mortgage-dashboard-fact-find>
      </ng-template>
    </hf-tab-panel>
    <hf-tab-panel key="client-sheet" label="Client Sheet" *ngIf="isClientSheetVisible">
      <ng-template #content>
        <hf-mortgage-dashboard-client-sheet>
        </hf-mortgage-dashboard-client-sheet>
      </ng-template>
    </hf-tab-panel>
    <hf-tab-panel key="credit-report" label="Credit Report" *ngIf="isCreditReportVisible">
      <ng-template #content>
        <hf-mortgage-dashboard-credit-report>
        </hf-mortgage-dashboard-credit-report>
      </ng-template>
    </hf-tab-panel>
    <hf-tab-panel key="tasks" label="Tasks" [badge]="data?.tasksDueCount" *ifPermission="'TASK_MANAGE'">
      <ng-template #content>
        <hf-task-tab [dataSourceFactory]="taskDataSourceFactory" [showApplication]="true" permissionsToManage="MORTGAGE_MANAGE">
        </hf-task-tab>
      </ng-template>
    </hf-tab-panel>
  </hf-tab-group>
</div>

<div class="sidepanes-anchor">
  <router-outlet></router-outlet>
</div>
