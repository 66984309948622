import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { UserApiService } from '../../../services/user-api.service';
import { fadeAnimation, sidepaneMove } from "../../../shared/animations/sidepane-animation";
import { MortgagesService } from "../mortgages.service";

@Component({
  selector: 'hf-select-advisor',
  templateUrl: './select-advisor.component.html',
  styleUrls: ['./select-advisor.component.scss',
    '../../../styles/sidepanes.partial.scss'],
  animations: [sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove]': 'true' }
})
export class SelectAdvisorComponent implements OnInit {
  public isLoading: boolean = true;
  public searchBoxFormGroup: FormGroup;
  public selectAdvisorsFormGroup: FormGroup = this.fb.group({});

  private introducerId;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private mortgagesService: MortgagesService,
    private userApiService: UserApiService) {
    this.introducerId = this.activatedRoute.snapshot.queryParams.introducerId;

    this.searchBoxFormGroup = this.fb.group({
      advisorsNameFilter: []
    });
  }

  async ngOnInit() {
    const advisors = this.fb.array([]);
    await this.userApiService.getIntroducerAdvisors(this.introducerId).then(users => {
      for (const user of users) {
        advisors.push(this.fb.group({
          fullName: [user.fullName],
          id: [user.id, Validators.required],
          radio: new FormControl(false)
        }));
      }

      this.selectAdvisorsFormGroup = this.fb.group({
        advisors: advisors,
        selectedItem: new FormControl(this.mapItems(advisors.value), Validators.required)
      });

      setTimeout(() => {
        if (this.mortgagesService.mortgageForm.get('advisor.id').value !== '') {
          const radioEl = (<HTMLInputElement>document.getElementById('advisor_' + this.mortgagesService.mortgageForm.get('advisor.id').value));
          radioEl !== null ? radioEl.checked = true : null;

          if (radioEl !== null) {
            Object.keys((this.selectAdvisorsFormGroup.controls['advisors'] as FormArray).controls).forEach(index => {
              (this.selectAdvisorsFormGroup.controls['advisors'] as FormArray).controls[index].value.radio =
                radioEl.id === 'advisor_' + (this.selectAdvisorsFormGroup.controls['advisors'] as FormArray).controls[index].value.id;
            });
            this.selectAdvisorsFormGroup.get('selectedItem').setValue(this.mapItems((this.selectAdvisorsFormGroup.controls['advisors'] as FormArray).value));
          }
        }
      }, 250);

      this.isLoading = false;
    });
  }

  onBack() {
    this.router.navigate(['./..'], { relativeTo: this.activatedRoute });
  }

  mapItems(items) {
    const selectedItem = items.filter((item) => item.radio).map((item) => item.id);
    return selectedItem.length ? selectedItem : null;
  }

  selectRadio(event) {
    Object.keys((this.selectAdvisorsFormGroup.controls['advisors'] as FormArray).controls).forEach(index => {
      (this.selectAdvisorsFormGroup.controls['advisors'] as FormArray).controls[index].value.radio =
        event.srcElement.id === 'advisor_' + (this.selectAdvisorsFormGroup.controls['advisors'] as FormArray).controls[index].value.id;
    });
    this.selectAdvisorsFormGroup.get('selectedItem').setValue(this.mapItems((this.selectAdvisorsFormGroup.controls['advisors'] as FormArray).value));

    Object.keys((this.selectAdvisorsFormGroup.controls['advisors'] as FormArray).controls).forEach(index => {
      if (this.selectAdvisorsFormGroup.get('selectedItem').value[0] === (this.selectAdvisorsFormGroup.controls['advisors'] as FormArray).controls[index].value.id) {
        this.mortgagesService.mortgageForm.get('advisor').patchValue(
          (this.selectAdvisorsFormGroup.controls['advisors'] as FormArray).controls[index].value
        );
      }
    });

    this.router.navigate(['./..'], { relativeTo: this.activatedRoute });
  }
}
