<div (click)="onBack()" class="overlay" [@fadeAnimation]></div>

<div class="sidepane-content">
  <div class="flex-space-between-layout template-header">
    <h1>View Document</h1>
    <hf-custom-button type="button"
                      class="button-plain-icon"
                      icon="close"
                      iconPos="center"
                      (onClick)="onBack()">
    </hf-custom-button>
  </div>

  <div class="template-main">
    <ng-container *ngIf="isLoading">
      <hf-custom-spinner sidepaneLoading="true"></hf-custom-spinner>
    </ng-container>

    <ng-container *ngIf="!isLoading">
      <form [formGroup]="editDocument"
            (ngSubmit)="onSubmit()">

        <label>Timestamp of File Upload</label>
        <div class="hf-form-static-value">
          {{documentWithIdDTO?.createdDate | date:'d MMM y, HH:mm'}}
        </div>

        <div class="hf-selector" style="margin-bottom: 12px">
          <label>Document Type</label>
          <div *ngIf="!editDocument.value?.type"
               class="hf-selector-container hf-selector-has-value"
               [routerLink]="['./selectDocumentType']">
            <div class="hf-selector-label-area">
              <div class="title">Select Document Type</div>
            </div>
            <i class="material-icons chevron-icon">chevron_right</i>
          </div>

          <ng-container *ngIf="editDocument.value?.type">
            <div
              class="hf-selector-container hf-selector-has-value hf-selector-black-color"
              [routerLink]="['./selectDocumentType']">
              <div class="hf-selector-label-area">
                <div class="title">{{selectDocumentTypeService.documentTypeName.get(editDocument.value.type)}}</div>
              </div>
              <i class="material-icons chevron-icon">chevron_right</i>
            </div>
          </ng-container>
        </div>

        <ng-container *ngIf="editDocument.get('otherTypeLabel').enabled">
          <label>Please Specify</label>

          <hf-custom-text-field
            type="text"
            [group]="editDocument"
            inputFormControlName="otherTypeLabel"
            fieldName="otherTypeLabel"
            marginElement
            validateOnBlur>
          </hf-custom-text-field>
        </ng-container>

        <div class="mark-area"
             [ngClass]="{'passed': documentWithIdDTO.sendToClient, 'canceled': !documentWithIdDTO.sendToClient}">
          <i class="material-icons">{{documentWithIdDTO.sendToClient ? 'check_circle' : 'cancel'}}</i>
          <span>Sent to Client</span>
        </div>

        <label>File Attachment</label>
        <div class="file-attachment" (click)="onDocumentDownload()">
          <div>{{documentWithIdDTO.attachment?.originalFilename}}</div>
          <i class="material-icons">get_app</i>
        </div>

      </form>
    </ng-container>
  </div>

  <div class="template-footer">
    <hf-custom-button type="submit"
                      class="button-primary"
                      label="Edit Document"
                      [disabled]="editDocument.invalid"
                      [callable]="true"
                      [callableApi]="submitResponse"
                      (onClick)="onSubmit()">
    </hf-custom-button>

    <hf-custom-button type="button"
                      class="button-secondary"
                      label="Cancel"
                      (onClick)="onBack()">
    </hf-custom-button>

    <hf-custom-button type="button" *ngIf="!documentWithIdDTO?.requested"
                      class="button-plain-icon delete-button keep-right"
                      icon="delete"
                      iconPos="center"
                      (onClick)="onRemove()">
    </hf-custom-button>
  </div>
</div>

<div class="sidepane-child-anchor">
  <router-outlet></router-outlet>
</div>
