<div *ngFor="let applicant of applicants" class="applicant-container">
  <div class="applicant" (click)="onApplicantClicked(applicant)">
    <i class="material-icons status" [ngClass]="applicant.applicantStatus"></i>
    {{ applicant.applicant.fullName }}
    <i [@rotateAnimation]="isApplicantActive(applicant)" class="material-icons arrow">chevron_right</i>
  </div>

  <div class="separator"></div>

  <hf-link-list *ngIf="isApplicantActive(applicant)" [items]="applicant.stepStatuses" (itemClicked)="onMenuItemClicked($event, applicant)" [@rollAnimation]>
    <ng-template #itemTemplate let-item>
      <div style="display: flex; align-items: center;">
        <hf-fact-find-step-status-icon [status]="item.status" style="width: 30px; flex: 0 0 30px;"></hf-fact-find-step-status-icon>
        {{ PROTECTION_APPLICATION_STEP_NAME.get(item.step) }}
      </div>
    </ng-template>
  </hf-link-list>
</div>
