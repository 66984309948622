<div class="overlay" (click)="onBack()" [@fadeAnimation]></div>

<div class="sidepane-content" [ngClass]="{'cols-2': !isEdit}">
  <div class="template-header flex-space-between-layout">
    <h1 class="title-area">{{isEdit ? 'Edit Mortgage' : 'Add Referral'}}</h1>

    <hf-custom-button type="button" icon="close" iconPos="center"
                      class="button-plain-icon"
                      (click)="onBack()">
    </hf-custom-button>
  </div>

  <div class="template-main">
    <hf-custom-spinner *ngIf="isLoading" sidepaneLoading="true"></hf-custom-spinner>

    <form *ngIf="!isLoading" [formGroup]="mortgagesService.mortgageForm">
      <div [ngClass]="{'form-col': !isEdit, 'left': !isEdit}">
        <label>
          Referral Type
          <hf-custom-radio-checks-field [group]="mortgagesService.mortgageForm"
                                        [type]="'radio'"
                                        [elemId]="'referralType'"
                                        [dataObj]="referralTypeName"
                                        marginElement>
          </hf-custom-radio-checks-field>
        </label>

        <label *ngIf="mortgagesService.mortgageForm.get('desiredQualification').enabled">
          Desired Qualification Amount
          <hf-text-field #desiredQualification prefix="£" isCurrency="true" formControlName="desiredQualification" style="margin-top: 8px"></hf-text-field>
        </label>

        <label *ngIf="mortgagesService.mortgageForm.get('propertyValue').enabled">
          Property Value
          <hf-custom-radio-checks-field [group]="mortgagesService.mortgageForm"
                                        [type]="'radio'"
                                        [elemId]="'propertyValue'"
                                        [dataObj]="propertyValueEnum"
                                        marginElement>
          </hf-custom-radio-checks-field>
        </label>

        <label>
          Client Category
          <hf-custom-radio-checks-field [group]="mortgagesService.mortgageForm"
                                        [type]="'radio'"
                                        [elemId]="'clientCategory'"
                                        [dataObj]="clientCategoryName"
                                        marginElement>
          </hf-custom-radio-checks-field>
        </label>

        <label *ngIf="mortgagesService.mortgageForm.get('firstTimeBuyer').enabled">
          First Time Buyer
          <hf-custom-radio-checks-field [group]="mortgagesService.mortgageForm"
                                        [type]="'radio'"
                                        [elemId]="'firstTimeBuyer'"
                                        [dataObj]="yesno"
                                        marginElement>
          </hf-custom-radio-checks-field>
        </label>

        <label>
          Client Type
          <hf-custom-radio-checks-field [group]="mortgagesService.mortgageForm"
                                        [type]="'radio'"
                                        [elemId]="'clientType'"
                                        [dataObj]="clientTypeName"
                                        marginElement>
          </hf-custom-radio-checks-field>
        </label>

        <label>
          Scheme (Optional)
          <hf-custom-radio-checks-field [group]="mortgagesService.mortgageForm"
                                        [type]="'radio'"
                                        [elemId]="'clientScheme'"
                                        [dataObj]="clientSchemeEnum"
                                        marginElement>
          </hf-custom-radio-checks-field>
        </label>

        <label>
          Contact by
          <hf-custom-radio-checks-field [group]="mortgagesService.mortgageForm"
                                        [type]="'checks'"
                                        [elemId]="'contactBy'"
                                        [dataObj]="contactByEnum"
                                        [ngClass]="'contact-by-wrapper'"
                                        marginElement>
          </hf-custom-radio-checks-field>
        </label>

        <label>
          <ng-container *ngIf="userService.getUser().introducer?.type == 'HOUSE_BUILDER' || mortgagesService.mortgageForm?.value?.introducer?.type == 'HOUSE_BUILDER'; else default_label">Plot Number (Optional)</ng-container>
          <ng-template #default_label>
            Reference (Optional)
          </ng-template>
          <hf-custom-text-field type="text"
                                fieldName="reference"
                                [group]="mortgagesService.mortgageForm"
                                inputFormControlName="reference"
                                marginElement>
          </hf-custom-text-field>
        </label>

        <label>
          Notes

          <textarea formControlName="notes"
                    class="hf-textarea"
                    marginElement>
          </textarea>
        </label>
      </div>

      <div *ngIf="!isEdit" [ngClass]="{'form-col': !isEdit, 'right':!isEdit}">
        <div class="client">
          <!-- Client selector -->
          <div *ifPermission="'MORTGAGE_NEW'; else newClientTemplate" class="hf-selector">
            <label>Client</label>

            <div *ngIf="!mortgagesService.mortgageForm.get('client.fullName').value"
                 class="hf-selector-container hf-selector-has-value"
                (click)="onSelectOrAddClientClick()">
              <div class="hf-selector-label-area">
                <div class="title">Select Client</div>
              </div>
              <i class="material-icons chevron-icon">chevron_right</i>
            </div>

            <ng-container *ngIf="mortgagesService.mortgageForm.get('client.fullName').value">
              <div class="hf-selector-container hf-selector-has-value hf-selector-black-color"
                   (click)="onSelectOrAddClientClick()">
                <div class="hf-selector-content-area">
                  <div class="title">{{mortgagesService.mortgageForm.get('client.fullName').value}}</div>
                  <div class="description">{{mortgagesService.mortgageForm.get('client.email').value}}</div>
                  <div class="description-extended">
                    {{mortgagesService.mortgageForm.get('client.phonePrefix').value}}&nbsp;
                    {{mortgagesService.mortgageForm.get('client.phone').value}}
                  </div>
                </div>
                <i class="material-icons chevron-icon">chevron_right</i>
              </div>
            </ng-container>
          </div>

          <!-- New client form -->
          <ng-template #newClientTemplate>
            <div class="flex">
              <label [style.margin-right.px]="8">Client First Name
                <hf-custom-text-field type="text"
                                      [group]="mortgagesService.mortgageForm.get('client')"
                                      inputFormControlName="firstName"
                                      fieldName="firstName"
                                      [capitalize]="true"
                                      marginElement
                                      validateOnBlur>
                </hf-custom-text-field>
              </label>

              <label>Last Name
                <hf-custom-text-field type="text"
                                      [group]="mortgagesService.mortgageForm.get('client')"
                                      inputFormControlName="lastName"
                                      fieldName="lastName"
                                      [capitalize]="true"
                                      marginElement
                                      validateOnBlur>
                </hf-custom-text-field>
              </label>
            </div>

            <label>Client Email
              <hf-custom-text-field type="email"
                                    [group]="mortgagesService.mortgageForm.get('client')"
                                    inputFormControlName="email"
                                    fieldName="email"
                                    marginElement
                                    validateOnBlur>
              </hf-custom-text-field>
            </label>

            <div [style.width.%]="100" class="col-2-phone-line-wrap">
              <div class="flex-phone-prefix">
                <div class="hf-selector">
                  <label>Client Phone</label>
                  <div class="hf-selector-container hf-selector-has-value hf-selector-one-line"
                       [routerLink]="['./selectPhonePrefix']">
                    <div class="hf-selector-content-area">
                      <div class="title">{{mortgagesService.mortgageForm.get('client').get('phonePrefix').value}}</div>
                    </div>
                    <i class="material-icons chevron-icon">chevron_right</i>
                  </div>
                </div>
              </div>

              <div class="flex-phone" [formGroup]="mortgagesService.mortgageForm.get('client')">
                <hfc-text-input type="tel" mask="0*" style="margin-bottom: 12px;" formControlName="phone" hfcRemoveLeadingZero></hfc-text-input>
              </div>
            </div>
          </ng-template>
        </div>

        <!-- Introducer selector -->
        <ng-container *ngIf="isIntroducerVisible">
          <div *ifPermission="'MORTGAGE_NEW'" class="introducer">
            <div class="hf-selector">
              <label>Introducer</label>

              <div *ngIf="mortgagesService.mortgageForm.get('introducer') !== null && !mortgagesService.mortgageForm.get('introducer.name').value"
                  class="hf-selector-container hf-selector-has-value"
                  [routerLink]="['./selectIntroducer']">
                <div class="hf-selector-label-area">
                  <div class="title">Select Introducer</div>
                </div>
                <i class="material-icons chevron-icon">chevron_right</i>
              </div>

              <ng-container *ngIf="mortgagesService.mortgageForm.get('introducer.name').value">
                <div class="hf-selector-container hf-selector-has-value hf-selector-black-color"
                    [routerLink]="['./selectIntroducer']">
                  <div class="hf-selector-content-area">
                    <div class="title">{{mortgagesService.mortgageForm.get('introducer.name').value}}</div>
                    <div class="description">
                      {{mortgagesService.mortgageForm.get('introducer.primaryContactFirstName').value}}&nbsp;
                      {{mortgagesService.mortgageForm.get('introducer.primaryContactLastName').value}}
                    </div>
                  </div>
                  <i class="material-icons chevron-icon">chevron_right</i>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>

        <!-- Division selector -->
        <div *ngIf="isDivisionVisible" class="division">
          <div class="hf-selector">
            <label>{{'Division' | wording:getValue('introducer.type')}}</label>

            <div *ngIf="!mortgagesService.mortgageForm.get('division.name').value"
                 class="hf-selector-container hf-selector-has-value"
                 [routerLink]="['./selectDivision']" [queryParams]="{introducerId: introducerId}">
              <div class="hf-selector-label-area">
                <div class="title">Select {{'Division' | wording:getValue('introducer.type')}}</div>
              </div>
              <i class="material-icons chevron-icon">chevron_right</i>
            </div>

            <ng-container *ngIf="mortgagesService.mortgageForm.get('division.name').value">
              <div class="hf-selector-container hf-selector-has-value hf-selector-black-color"
                   [routerLink]="['./selectDivision']" [queryParams]="{introducerId: introducerId}">
                <div class="hf-selector-content-area">
                  <div class="title">{{mortgagesService.mortgageForm.get('division.name').value}}</div>
                  <div class="description">
                    {{ mortgagesService.mortgageForm.get('division').value?.region }} <br>
                    {{ mortgagesService.mortgageForm.get('division').value?.details }}
                  </div>
                </div>
                <i class="material-icons chevron-icon">chevron_right</i>
              </div>
            </ng-container>
          </div>
        </div>

        <!-- Sales advisor selector -->
        <div *ngIf="hasPermission(['MORTGAGE_NEW', 'MORTGAGE_NEW_INTRODUCER', 'MORTGAGE_NEW_DIVISION']) && isSalesAdvisorVisible" class="sales-advisor">
          <div class="hf-selector">
            <label>Sales Advisor</label>

            <div *ngIf="!mortgagesService.mortgageForm.get('salesAdvisor.fullName').value"
                 class="hf-selector-container hf-selector-has-value"
                 [routerLink]="['./selectSalesAdvisor']" [queryParams]="{introducerId: introducerId, divisionId: divisionId}">
              <div class="hf-selector-label-area">
                <div class="title">Select Sales Advisor</div>
              </div>
              <i class="material-icons chevron-icon">chevron_right</i>
            </div>

            <ng-container *ngIf="mortgagesService.mortgageForm.get('salesAdvisor.fullName').value">
              <div class="hf-selector-container hf-selector-has-value hf-selector-black-color"
                   [routerLink]="['./selectSalesAdvisor']" [queryParams]="{introducerId: introducerId, divisionId: divisionId}">
                <div class="hf-selector-content-area">
                  <div class="title">{{mortgagesService.mortgageForm.get('salesAdvisor.fullName').value}}</div>
                </div>
                <i class="material-icons chevron-icon">chevron_right</i>
              </div>
            </ng-container>
          </div>
        </div>

        <!-- Advisor selector -->
        <div *ngIf="hasPermission('MORTGAGE_NEW') && isAdvisorVisible" class="advisor">
          <div class="hf-selector">
            <label>Advisor</label>

            <div *ngIf="!mortgagesService.mortgageForm.get('advisor.fullName').value"
                 class="hf-selector-container hf-selector-has-value"
                 (click)="onSelectAdvisorClicked()">
              <div class="hf-selector-label-area">
                <div class="title">Select Advisor</div>
              </div>
              <i class="material-icons chevron-icon">chevron_right</i>
            </div>

            <ng-container *ngIf="mortgagesService.mortgageForm.get('advisor.fullName').value">
              <div class="hf-selector-container hf-selector-has-value hf-selector-black-color"
                   (click)="onSelectAdvisorClicked()">
                <div class="hf-selector-content-area">
                  <div class="title">{{mortgagesService.mortgageForm.get('advisor.fullName').value}}</div>
                </div>
                <i class="material-icons chevron-icon">chevron_right</i>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </form>

    <!-- <hfc-debug-form [form]="mortgagesService.mortgageForm"></hfc-debug-form> -->
  </div>

  <div class="template-footer" [ngClass]="{'submit-area-edit': isEdit}">
    <hf-custom-button type="submit"
                      class="button-primary"
                      label="{{isEdit ? 'Edit Mortgage' : 'Add Referral'}}"
                      [disabled]="mortgagesService.mortgageForm.invalid"
                      [isLoading]="isSubmitting"
                      (onClick)="isEdit ? onEditSubmit() : onSubmit(mortgagesService.mortgageForm)">
    </hf-custom-button>

    <hf-custom-button type="button"
                      label="Cancel"
                      class="button-secondary"
                      (click)="onBack()">
    </hf-custom-button>
  </div>
</div>

<div class="sidepane-child-anchor">
  <router-outlet></router-outlet>
</div>
