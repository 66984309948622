import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { filterMap } from '../../../../../../projects/client/src/app/shared/functions/filter-map';
import { ToastService } from '../../../../../../projects/client/src/app/shared/services/toast.service';
import { DivisionSelectorDTO } from '../../../models/division-selector.dto';
import { IntroducerApiService } from '../../../services/introducer-api.service';
import { MortgageApiService } from '../../../services/mortgage-api.service';
import { SidepaneService } from '../../../services/sidepane-service';
import { UserService } from '../../../services/user.service';
import { SelectAdvisorForAssignmentService } from '../../../shared-layout/side-panes/select-advisor-for-assignment/select-advisor-for-assignment.service';
import { SelectDivisionService } from '../../../shared-layout/side-panes/select-division/select-division.service';
import { SelectOrAddClientService } from '../../../shared-layout/side-panes/select-or-add-client/select-or-add-client.service';
import { fadeAnimation, sidepaneMove, sidepaneMove2Cols } from '../../../shared/animations/sidepane-animation';
import { TableSettingsService } from '../../../shared/table-utils/table-settings.service';
import { TextFieldComponent } from '../../../shared/text-field/text-field.component';
import { setControlEnabled } from '../../../utils/functions/set-control-enabled';
import { CLIENT_CATEGORIES_ADD_MORTGAGE, CLIENT_CATEGORY, CLIENT_CATEGORY_NAME, CLIENT_SCHEME, CLIENT_TYPE, CLIENT_TYPE_NAME, CONTACT_BY_TYPES, PROPERTY_VALUE_TYPES, REFERRAL_TYPE, REFERRAL_TYPE_NAME, REFERRAL_TYPE_TYPES } from '../../../utils/variables.data';
import { PhonePrefixService } from '../../introducers/select-phone-prefix/phone-prefix.service';
import { MortgagesService } from '../mortgages.service';
import { MortgageExistingClientSelectAdvisorService } from './mortgage-existing-client-select-advisor.service';
import { AddMortgageService } from './add-mortgage.service';

const clientCategoryToClientTypes = new Map<CLIENT_CATEGORY, CLIENT_TYPE[]>([
  ["PURCHASE", ["FIRST_TIME_BUYER", "LET_TO_BUY", "BUY_TO_LET", "MOVER", "OTHER"]],
  ["REMORTGAGE", ["LET_TO_BUY", "BUY_TO_LET", "RESIDENTIAL", "OTHER"]],
  ["PRODUCT_TRANSFER", ["RESIDENTIAL", "BUY_TO_LET", "OTHER"]],
  ["FURTHER_ADVANCE", ["RESIDENTIAL", "BUY_TO_LET", "OTHER"]],
])

export function composeClientTypeName(referralType: REFERRAL_TYPE, clientCategory: CLIENT_CATEGORY, firstTimeBuyer?: boolean) {
  const types = clientCategoryToClientTypes.get(clientCategory) || clientCategoryToClientTypes.get("PURCHASE");

  const map: typeof CLIENT_TYPE_NAME = filterMap(CLIENT_TYPE_NAME, ([type]) => types.includes(type));

  if (referralType === "EXISTING_CLIENT") {
    map.delete("FIRST_TIME_BUYER");
  }

  if (firstTimeBuyer === true) {
    map.delete("MOVER")
    map.delete("OTHER")
    map.set("RESIDENTIAL", "Residential");
    map.set("OTHER", "Other");
  }

  return map;
}

@Component({
  selector: 'hf-add-mortgage',
  templateUrl: './add-mortgage.component.html',
  styleUrls: ['./add-mortgage.component.scss',
    '../../../styles/sidepanes.partial.scss'],
  animations: [sidepaneMove2Cols, sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove2Cols]': 'true' },
  providers: [
    { provide: SelectAdvisorForAssignmentService, useClass: MortgageExistingClientSelectAdvisorService },
    { provide: SelectDivisionService, useExisting: AddMortgageComponent },
  ]
})
export class AddMortgageComponent implements OnInit, OnDestroy, SelectDivisionService {
  public hasPermission(permisisons) { return this.userService.hasPermission(permisisons); }
  public get introducerId() { return this.getValue("introducer.id"); }
  public get divisionId() { return this.getValue("division.id"); }
  public get isExistingClient() { return this.getValue("referralType") === "EXISTING_CLIENT"; }

  public get isIntroducerVisible() { return !this.isExistingClient }
  public get isDivisionVisible() { return !this.isExistingClient && !!this.introducerId }
  public get isSalesAdvisorVisible() { return !this.isExistingClient && !!this.divisionId }
  public get isAdvisorVisible() { return this.isExistingClient || !!this.divisionId }

  public getValue(path: string) { return this.mortgagesService.mortgageForm.get(path).value; }
  public setValue(path: string, value: any) { this.mortgagesService.mortgageForm.get(path).setValue(value); }
  public patchValue(path: string, value: any) { this.mortgagesService.mortgageForm.get(path).patchValue(value); }

  public isLoading: boolean;
  public isEdit: boolean;
  public isSubmitting: boolean;

  @ViewChild("desiredQualification")
  public desiredQualificationElement: TextFieldComponent;

  public referralTypeName: typeof REFERRAL_TYPE_NAME;
  public clientTypeName: typeof CLIENT_TYPE_NAME;
  public clientCategoryName: typeof CLIENT_CATEGORY_NAME;

  public clientCategoryEnum = CLIENT_CATEGORIES_ADD_MORTGAGE;
  public referralTypeEnum = REFERRAL_TYPE_TYPES;
  public contactByEnum = CONTACT_BY_TYPES;
  public clientSchemeEnum = CLIENT_SCHEME;
  public propertyValueEnum = PROPERTY_VALUE_TYPES;
  public yesno = new Map<boolean, string>([
    [true, "Yes"],
    [false, "No"],
  ]);

  private destroy$ = new Subject();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sidepaneService: SidepaneService,
    private mortgageApiService: MortgageApiService,
    private introducerApiService: IntroducerApiService,
    public mortgagesService: MortgagesService,
    public userService: UserService,
    private tableSettingsService: TableSettingsService,
    private phonePrefixService: PhonePrefixService,
    private toastService: ToastService,
    private selectOrAddClientService: SelectOrAddClientService,
    private selectAdvisorForAssignmentService: SelectAdvisorForAssignmentService,
    private addMortgageService: AddMortgageService,
  ) {
    this.isEdit = this.router.url.indexOf('edit') > -1;

    this.prepareReferralTypeName();

    this.mortgagesService.initForm();

    this.setValue("introducer.id", this.userService.introducerId);

    if (this.userService.getUser().introducer) {
      this.setValue("introducer.type", this.userService.getUser().introducer.type);
      this.initializeDivision();
    }
  }

  async getDivisions(): Promise<DivisionSelectorDTO[]> {
    const divisionsResult = await this.introducerApiService.getIntroducerDivisions(this.introducerId);

    return divisionsResult.items;
  }

  selectDivision(division: DivisionSelectorDTO) {
    this.mortgagesService.mortgageForm.get('division').patchValue(division);
  }

  public get introducerType() { return this.mortgagesService.mortgageForm.get('introducer.type').value }

  ngOnInit() {
    this.mortgagesService.mortgageForm.get('introducer').valueChanges.pipe(takeUntil(this.destroy$)).subscribe(introducer => {
      this.mortgagesService.mortgageForm.get('division').reset();
      this.mortgagesService.mortgageForm.get('salesAdvisor').reset();
      this.mortgagesService.mortgageForm.get('advisor').reset();
    });

    this.mortgagesService.mortgageForm.get('division').valueChanges.pipe(takeUntil(this.destroy$)).subscribe(division => {
      this.mortgagesService.mortgageForm.get('salesAdvisor').reset();
      this.mortgagesService.mortgageForm.get('advisor').reset();
    });

    this.mortgagesService.mortgageForm.get('clientCategory').valueChanges.pipe(takeUntil(this.destroy$)).subscribe(clientCategory => {
      this.mortgagesService.mortgageForm.get('clientType').reset();
      this.mortgagesService.mortgageForm.get('firstTimeBuyer').reset();
      this.prepareClientTypeName();
      this.prepareFirstTimeBuyer();
    });

    this.phonePrefixService.phonePrefixUpdated.pipe(takeUntil(this.destroy$))
      .subscribe(prefix => this.setValue('client.phonePrefix', prefix));

    this.selectAdvisorForAssignmentService.advisorSelected$.pipe(takeUntil(this.destroy$))
      .subscribe(advisor => this.mortgagesService.mortgageForm.patchValue({ advisor }));

    this.mortgagesService.mortgageForm.get('referralType').valueChanges.pipe(takeUntil(this.destroy$)).subscribe(referralType => {
      if (referralType === "OFFER_QUALIFICATION") {
        this.mortgagesService.mortgageForm.get('desiredQualification').enable();
        this.mortgagesService.mortgageForm.get('propertyValue').disable();
      } else {
        this.mortgagesService.mortgageForm.get('desiredQualification').disable();
        this.mortgagesService.mortgageForm.get('propertyValue').enable();
      }

      setTimeout(() => {
        if (referralType === "OFFER_QUALIFICATION" && this.desiredQualificationElement) {
          this.desiredQualificationElement.focus();
        }
      });

      if (this.isExistingClient && !this.isEdit) {
        this.mortgagesService.mortgageForm.get("introducer").disable();
        this.mortgagesService.mortgageForm.get("division").disable();
        this.mortgagesService.mortgageForm.get("salesAdvisor").disable();
      } else if (!this.isEdit) {
        setControlEnabled(this.mortgagesService.mortgageForm.get("introducer"), true);
        setControlEnabled(this.mortgagesService.mortgageForm.get("division"), true);
        setControlEnabled(this.mortgagesService.mortgageForm.get("salesAdvisor"), true);
      }

      this.prepareClientCategoryName();
      this.prepareClientTypeName();
      this.prepareFirstTimeBuyer();
    });

    this.mortgagesService.mortgageForm.get('firstTimeBuyer').valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      setTimeout(() => this.prepareClientTypeName());
    });

    this.prepareClientCategoryName();
    this.prepareClientTypeName();
    this.prepareFirstTimeBuyer();

    if (this.isEdit) {
      this.mortgagesService.mortgageDashboardDataLoaded.pipe(take(1)).subscribe(() => {
        this.prepareEditForm();
      });
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  onSelectAdvisorClicked() {
    const route = this.isExistingClient
      ? "selectHeronAdvisor"
      : "selectAdvisor";
    const queryParams = this.isExistingClient
      ? {}
      : { introducerId: this.introducerId };

    this.router.navigate([route], { relativeTo: this.activatedRoute, queryParams });
  }

  async onBack() {
    if (this.mortgagesService.mortgageForm.dirty && !await this.sidepaneService.confirmDiscardChanges()) {
      return;
    }

    this.goBack();
  }

  async onSubmit(mortgageForm) {
    const value = { ...mortgageForm.value };

    this.isSubmitting = true;

    value.client.fullName = `${value.client.firstName} ${value.client.lastName}`;

    value.contactByEmail = !(!value.contactBy['contactBy-0'] || value.contactBy['contactBy-0'] === '');
    value.contactByPhone = !(!value.contactBy['contactBy-1'] || value.contactBy['contactBy-1'] === '');
    value.contactByMessage = !(!value.contactBy['contactBy-2'] || value.contactBy['contactBy-2'] === '');

    try {
      this.hasPermission('MORTGAGE_NEW')
        ? await this.mortgageApiService.addMortgageHeronUser(value)
        : await this.mortgageApiService.addMortgageIntroducer(value);

      const message = value.client.id === ''
        ? "Mortgage and Client added"
        : "Mortgage added";

      this.toastService.add(message);
      this.tableSettingsService.refresh();
      this.router.navigate(['..'], { relativeTo: this.activatedRoute });
      this.addMortgageService.added$.next();
    } catch (e) {
      this.toastService.add("Failed: ", "error", e);
    }

    this.isSubmitting = false;
  }

  public async onEditSubmit() {
    const value = { ...this.mortgagesService.mortgageForm.value, ...this.getCorrectValues() };

    this.isSubmitting = true;

    try {
      await this.mortgageApiService.editMortgage(this.mortgagesService.data.id, value);

      this.toastService.add("Mortgage Edit successful");
      this.mortgagesService.reloadMortgageDashboard.emit();
      this.router.navigate(['..'], { relativeTo: this.activatedRoute });
    } catch (e) {
      this.toastService.add("Failed: ", "error", e);
    }

    this.isSubmitting = false;
  }

  private prepareEditForm() {
    this.mortgagesService.mortgageForm.removeControl('advisor');
    this.mortgagesService.mortgageForm.removeControl('salesAdvisor');
    this.mortgagesService.mortgageForm.removeControl('client');
    this.mortgagesService.mortgageForm.removeControl('division');
    this.mortgagesService.mortgageForm.removeControl('introducer');

    this.mortgagesService.mortgageForm.patchValue(this.mortgagesService.data);
    this.mortgagesService.mortgageForm.get('contactBy.contactBy-0').patchValue(this.mortgagesService.data.contactByEmail);
    this.mortgagesService.mortgageForm.get('contactBy.contactBy-1').patchValue(this.mortgagesService.data.contactByPhone);
    this.mortgagesService.mortgageForm.get('contactBy.contactBy-2').patchValue(this.mortgagesService.data.contactByMessage);
  }

  private getCorrectValues() {
    const dataToSend: any = {};

    dataToSend.contactByEmail = this.mortgagesService.mortgageForm.get('contactBy.contactBy-0').value;
    dataToSend.contactByPhone = this.mortgagesService.mortgageForm.get('contactBy.contactBy-1').value;
    dataToSend.contactByMessage = this.mortgagesService.mortgageForm.get('contactBy.contactBy-2').value;

    return dataToSend;
  }

  private goBack() {
    this.router.navigate(['..'], { relativeTo: this.activatedRoute });
  }

  onSelectOrAddClientClick() {
    const route = this.isExistingClient
      ? "selectClient"
      : "selectOrAddClient";

    this.selectOrAddClientService.client = this.mortgagesService.mortgageForm.value.client;

    this.router.navigate([route], { relativeTo: this.activatedRoute });
  }

  private async initializeDivision() {
    this.isLoading = true;

    const divisions = await this.introducerApiService.getIntroducerDivisions(this.introducerId);

    if (divisions.items.length === 1) {
      this.patchValue("division", divisions.items[0]);
    }

    this.isLoading = false;
  }

  private prepareReferralTypeName() {
    this.referralTypeName = this.userService.hasPermission("MORTGAGE_MANAGE")
      ? REFERRAL_TYPE_NAME
      : new Map([...REFERRAL_TYPE_NAME.entries()].filter(([type]) => type !== "EXISTING_CLIENT"));
  }

  private prepareClientCategoryName() {
    const categoriesForExisting: CLIENT_CATEGORY[] = ["PRODUCT_TRANSFER", "FURTHER_ADVANCE"];

    this.clientCategoryName = this.isExistingClient
      ? CLIENT_CATEGORY_NAME
      : filterMap(CLIENT_CATEGORY_NAME, ([category]) => !categoriesForExisting.includes(category));
  }

  private prepareClientTypeName() {
    const { referralType, clientCategory, firstTimeBuyer } = this.mortgagesService.mortgageForm.value;

    this.clientTypeName = composeClientTypeName(referralType, clientCategory, firstTimeBuyer);
  }

  private prepareFirstTimeBuyer() {
    const referralType: REFERRAL_TYPE = this.mortgagesService.mortgageForm.value.referralType;
    const clientCategory: CLIENT_CATEGORY = this.mortgagesService.mortgageForm.value.clientCategory;

    setControlEnabled(this.mortgagesService.mortgageForm.get('firstTimeBuyer'), referralType !== "EXISTING_CLIENT" && clientCategory !== "REMORTGAGE" && !!clientCategory)
  }
}
