import { Component, Input, ViewEncapsulation } from "@angular/core";
import { User } from "../../models/user";
import { USER_TYPE_NAMES } from "../../utils/variables.data";

@Component({
  selector: "hf-custom-dropdown",
  templateUrl: "./custom-dropdown.component.html",
  styleUrls: ["./custom-dropdown.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CustomDropdownComponent {
  @Input() public user: User;

  public USER_TYPE_NAMES = USER_TYPE_NAMES;
  public activeDropdown: boolean = false;

  public onClickDropdown(event, menu) {
    this.activeDropdown = !this.activeDropdown;
    menu.toggle(event);
  }
}
