import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from 'rxjs/operators';

import { TableSettingsService, DataSourceFactory, TableParams } from '../../../shared/table-utils/table-settings.service';
import { MORTGAGE_DOCUMENT_TYPE_NAME, DOCUMENT_REQUEST_STATUS_NAME } from "../../../utils/variables.data";
import { DocumentsTabService } from './documents-tab.service';
import {DocumentListDTO, DocumentWithIdDTO} from '../../../models/document';
import { SelectDocumentTypeService } from '../../side-panes/select-document-type/select-document-type.service';

@Component({
  selector: 'hf-documents-tab',
  templateUrl: './documents-tab.component.html',
  styleUrls: ['./documents-tab.component.scss'],
  providers: [TableSettingsService]
})
export class DocumentsTabComponent implements AfterViewInit, OnDestroy, OnInit {
  @Input()
  public dataSourceFactory: DataSourceFactory<TableParams>;

  @Input()
  public showApplication: boolean = false;

  @Input()
  public downloadZipParams: string;

  public data: DocumentListDTO[];
  public MORTGAGE_DOCUMENT_TYPE_NAME = MORTGAGE_DOCUMENT_TYPE_NAME;
  public DOCUMENT_REQUEST_STATUS_NAME = DOCUMENT_REQUEST_STATUS_NAME;

  private destroy$ = new Subject();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private tableSettingsService: TableSettingsService,
    private documentsTabService: DocumentsTabService,
    private selectDocumentTypeService: SelectDocumentTypeService
  ) {
    this.tableSettingsService.data$
      .pipe(takeUntil(this.destroy$)).subscribe((data: DocumentListDTO[]) => this.data = data);

    this.documentsTabService.reloadDocumentsTab
      .pipe(takeUntil(this.destroy$)).subscribe(() => this.tableSettingsService.refresh());
  }

  public getDocumentType(row: DocumentListDTO) {
    if (row.type == "OTHER") {
      return row.otherTypeLabel;
    } else {
      return this.selectDocumentTypeService.documentTypeName.get(row.type);
    }
  }

  public ngOnInit(): void {
    this.tableSettingsService.composeAndSetupTableParamsStorageKey(this.router);
  }

  public ngAfterViewInit() {
    this.tableSettingsService.setDataSourceFactory(this.dataSourceFactory);
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }

  public viewDocumentEntry(document: DocumentListDTO) {
    if (document.feo_status === "REQUESTED") {
      this.router.navigate(['./documentRequest/' + document.id], { relativeTo: this.activatedRoute });
    } else {
      this.router.navigate(['./editDocument/' + document.id], { relativeTo: this.activatedRoute });
    }
  }
}
