import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'hf-custom-spinner',
  templateUrl: './custom-spinner.component.html',
  styleUrls: ['./custom-spinner.component.scss']
})
export class CustomSpinnerComponent implements OnInit {

  @Input() public saveButton: boolean = true;
  @Input() public sidepaneLoading: boolean = false;
  @Input() public darkTheme?: boolean = true;

  constructor() {
  }

  ngOnInit() {
  }

}
