<div class="hf-selector">
  <label *ngIf="label">{{ label }}</label>

  <div *ngIf="!value"
        class="hf-selector-container hf-selector-has-value"
        [class.isInvalid]="isInvalid"
        [routerLink]="routerLink" [queryParams]="buildQueryParams()" >
    <div class="hf-selector-label-area">
      <div class="title">Select Date</div>
    </div>
    <i class="material-icons chevron-icon">chevron_right</i>
  </div>

  <ng-container *ngIf="value">
    <div class="hf-selector-container hf-selector-has-value hf-selector-black-color"
         [class.isInvalid]="isInvalid"
          [routerLink]="routerLink" [queryParams]="buildQueryParams()">
      <div class="hf-selector-content-area">
        <div class="title">{{ value | date:'d MMM y'}}</div>
      </div>
      <i class="material-icons chevron-icon">chevron_right</i>
    </div>
  </ng-container>
</div>
