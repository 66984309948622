<div class="header" (click)="onHeaderClicked()">
  <hf-named-icon icon="chevron_right" class="chevron" [class.isOpen]="isOpen"></hf-named-icon>

  <hf-named-icon [icon]="getOverallIcon(email.state)" [hfTooltip]="EmailJournalStateName.get(email.state)"></hf-named-icon>

  <div>
    <span class="strong">Opens: </span>{{ email.opens || 0 }}
    &nbsp;
    <span class="strong">Clicks: </span>{{ email.clicks || 0 }}
  </div>

  <hfc-spinner *ngIf="isLoading" class="secondary" size="small"></hfc-spinner>
</div>
<div  *ngIf="isOpen" class="details" @expandAnimation>
  <div *ngFor="let event of events" class="event">
    <hf-named-icon [icon]="getIcon(event.type)" [hfTooltip]="EmailEventTypeName.get(event.type)"></hf-named-icon>

    <div>
      {{ event.recipient.fullName }}: 

      <span class="strong">{{ EmailEventTypeName.get(event.type) }}: </span>

      {{ event.timestamp | date:'d MMM y HH:mm' }}
    </div>
  </div>
</div>
