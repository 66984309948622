import { Component, EventEmitter, Input, Output, ContentChild, TemplateRef } from '@angular/core';

@Component({
  selector: 'hf-tab-section',
  templateUrl: './tab-section.component.html',
  styleUrls: ['./tab-section.component.scss']
})
export class TabSectionComponent {
  @Input()
  public heading: string;

  @ContentChild("headerTemplate")
  public headerTemplate: TemplateRef<any>;
}
