import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { insertItem, patch } from '@ngxs/store/operators';
import { MortgageImportedDataDTO } from '../../../../../models/mortgage-imported-data.dto';
import { MortgageApiService } from '../../../../../services/mortgage-api.service';
import { MortgageImportedData } from './mortgage-imported-data.actions';
import GetImportedData = MortgageImportedData.GetImportedData;

export interface MortgageImportedDataItem {
  mortgageId: string;
  importedData: MortgageImportedDataDTO;
}

export interface MortgageImportedDataModel {
  items: MortgageImportedDataItem[];
}

@State<MortgageImportedDataModel>({
  name: "mortgageImportedData",
  defaults: {
    items: []
  }
})
@Injectable()
export class MortgageImportedDataState {

  @Selector()
  static importedDataItems(state: MortgageImportedDataModel): MortgageImportedDataItem[] {
    return state.items;
  }

  constructor(
    private store: Store,
    private mortgageApiService: MortgageApiService,
  ) { }

  @Action(GetImportedData)
  public async getImportedData(ctx: StateContext<MortgageImportedDataModel>, action: GetImportedData) {
    const item = ctx.getState().items.find(i => i.mortgageId === action.mortgageId);
    if (!item) {
      const importedData = await this.mortgageApiService.getImportedData(action.mortgageId).toPromise();
      ctx.setState(
        patch({
          items: insertItem({
            mortgageId: action.mortgageId,
            importedData,
          }),
        })
      );
    }
  }
}
