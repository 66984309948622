import {Injectable} from "@angular/core";
import {Subject} from "rxjs";
import {MortgageDashboardHandover} from "../../../models/mortgage-dashboard";

export const fixHandoverId = (formValue: any): any => {
  const value = {...formValue};

  if (value._handover) {
    value.handoverId = value._handover.id;
    delete value._handover;
  }

  return value;
}

@Injectable({ providedIn: "root" })
export class SelectApplicationLenderService {
  public application$ = new Subject<MortgageDashboardHandover>();

  public handovers: MortgageDashboardHandover[];
}
