import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../../models/user';
import { UserService } from '../../services/user.service';
import { TemplateService } from '../../services/template.service';
import { IntroducerRoute } from '../../modules/dashboard/dashboard-routing.module';
import { SessionStoreService } from '../../services/session-store.service';

@Component({
  selector: 'hf-custom-sidenav',
  templateUrl: './custom-sidenav.component.html',
  styleUrls: ['./custom-sidenav.component.scss']
})
export class CustomSidenavComponent {

  @Input() public routes: ActivatedRoute;

  public get user() { return this.userService.getUser() }
  public newTab: string = '_self' || '_blank';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private sessionStoreService: SessionStoreService,
    public templateService: TemplateService,
  ) { }

  clickItem(event) {
    event.preventDefault();
  }

  onLinkClicked(link: string) {
    this.sessionStoreService.set(`app/${link}`);
  }

  getIfSecondLevelGranted(subLink) {
    return this.userService.hasPermission(subLink.permission);
  }

  isActive(route: string): boolean {
    return this.router.url.indexOf(route) > -1;
  }
}
