import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { APPLICANT_SCHEME, APPLICANT_SCHEME_NAMES, PROPERTY_PAYMENT_METHOD, PROPERTY_PAYMENT_METHOD_NAME } from '../../../../../../../projects/client/src/app/fact-find/enums.module';
import { getToday } from '../../../../../../../projects/client/src/app/shared/functions/get-today';
import { ToastService } from '../../../../../../../projects/client/src/app/shared/services/toast.service';
import { Lender } from '../../../../models/lender';
import { NewComplianceHandoverRecommendedDTO, PropCode } from '../../../../models/new-compliance-handover-recommended.dto';
import { FormFieldService } from '../../../../services/form-field.service';
import { MortgageApiService } from '../../../../services/mortgage-api.service';
import { SelectorSidepaneItemSelected, SelectorSidepaneService } from '../../../../shared-layout/side-panes/selector-sidepane/selector-sidepane.service';
import { setControlEnabled } from '../../../../utils/functions/set-control-enabled';
import { FeesToLoanReason, FeesToLoanReasonName, MortgageDebtConsolidationReason, MortgageDebtConsolidationReasonName, MORTGAGE_PRODUCT_DATA_TYPE_NAME, MORTGAGE_PRODUCT_END_TYPE, MORTGAGE_PRODUCT_END_TYPE_NAME_ALL } from '../../../../utils/variables.data';
import { MonthlyIncomeService } from '../../monthly-income/monthly-income.service';
import { MortgagesService } from '../../mortgages.service';
import { SelectPropcoService } from '../../select-propco-code/select-propco-code.service';
import { isAwatingOrApproved, isAwatingOrApprovedNotLegacy } from '../new-compliance-handover-objective/new-compliance-handover-objective.component';
import { NewComplianceHandoverComponent } from '../new-compliance-handover.component';
import { getForm } from "./new-compliance-handover-recommended.form";
import * as R from "ramda";
import { Twenty7tecApiService, Twenty7tecRecommendedProduct } from '../../../../services/twenty7tec-api.service';
import { SourceTwentySevenTecButtonModeChange } from '../../components/source-twenty-seven-tec-button/source-twenty-seven-tec-button.component';

@Component({
  selector: 'hf-new-compliance-handover-recommended',
  templateUrl: './new-compliance-handover-recommended.component.html',
  styleUrls: ['./new-compliance-handover-recommended.component.scss'],
  providers: [FormFieldService],
})
export class NewComplianceHandoverRecommendedComponent implements OnDestroy {
  public form = getForm(this.fb);

  public get value() { return this.form.value as NewComplianceHandoverRecommendedDTO }
  public get mortgageId() { return this.route.snapshot.paramMap.get("id") }
  public get handoverId() { return this.sidepane.handoverId }
  public get objective() { return this.sidepane?.dto?.objective?.objective }
  public get firstTimeBuyer() { return this.mortgagesService.data?.firstTimeBuyer }
  public get twentySevenTecAuthorised() { return this.sidepane?.dto?.twentySevenTecAuthorised }

  public control(name: string) { return this.form.get(name) }
  public enabled(name: string) { return this.form.get(name).enabled }
  public today = getToday();

  public APPLICANT_SCHEME_NAMES = APPLICANT_SCHEME_NAMES;
  public MORTGAGE_PRODUCT_DATA_TYPE_NAME = MORTGAGE_PRODUCT_DATA_TYPE_NAME;
  public PROPERTY_PAYMENT_METHOD_NAME = PROPERTY_PAYMENT_METHOD_NAME;
  public MORTGAGE_PRODUCT_END_TYPE_NAME_ALL = MORTGAGE_PRODUCT_END_TYPE_NAME_ALL;
  public MortgageDebtConsolidationReasonName = MortgageDebtConsolidationReasonName;
  public FeesToLoanReasonName = FeesToLoanReasonName;
  public isLoading = false;
  public twenty7tecMode = "source";
  public isSourceButtonLoading = false;

  private destroy$ = new Subject();

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private mortgageApi: MortgageApiService,
    private monthlyIncomeService: MonthlyIncomeService,
    private toast: ToastService,
    private sidepane: NewComplianceHandoverComponent,
    private mortgagesService: MortgagesService,
    private selectorSidepaneService: SelectorSidepaneService,
    private formFieldService: FormFieldService,
    private selectPropcoService: SelectPropcoService,
    private twenty7tecApiService: Twenty7tecApiService,
    private mortgageApiService: MortgageApiService,
  ) {
    this.setup();

    if (isAwatingOrApproved(this.sidepane.dto.state)) {
      this.control("reasonForChange").setValidators(Validators.required);
    }

    this.setupControls();

    this.form.patchValue(this.sidepane.dto.recommended);
    this.sidepane.assignForm(this.form);
    // this.form.patchValue(formMock);

    this.sidepane.dto$.pipe(takeUntil(this.destroy$)).subscribe(dto => {
      this.form.patchValue(this.sidepane.dto.recommended);
    });
  }

  public onTwenty7tecModeChanged(change: SourceTwentySevenTecButtonModeChange) {
    change.mode === "select" && this.confirmTwenty7tecMortgage(change.mortgage);
    change.mode === "manual" && (this.twenty7tecMode = "manual");
    change.mode === "source" && this.onSourceTwenty7tecMortgage();
  }

  private onSourceTwenty7tecMortgage() {
    this.isSourceButtonLoading = true;

    this.twenty7tecApiService.syncClientData(this.mortgageId, this.handoverId).subscribe(
      url => {
        this.twenty7tecMode = "select";
        window.open(url, "_blank");
        this.isSourceButtonLoading = false;
      },
      e => {
        this.toast.add("Failed to sync client data", "error", e);
        this.isSourceButtonLoading = false;
      }
    );
  }

  private confirmTwenty7tecMortgage(mortgage: Twenty7tecRecommendedProduct) {
    this.isLoading = true;

    this.twenty7tecApiService.selectRecommendedProduct(this.handoverId, mortgage.documentId).subscribe(
      dto => {
        this.form.patchValue(dto.recommended);
        this.isLoading = false;
      },
      e => {
        this.toast.add("Failed to select the mortgage", "error", e);
        this.isLoading = false;
      }
    );
  }

  public onSelectPropcodeClicked() {
    if (R.isNil(this.value.lender?.id)) {
      return this.toast.add("Please select a Lender first", "error");
    }

    this.router.navigate(["selectPropco"], { relativeTo: this.route, queryParams: { lenderId: this.value.lender.id } });
  }

  public onSubmit(draft: boolean) {
    if (isAwatingOrApprovedNotLegacy(this.sidepane.dto) && !this.formFieldService.checkFormValidity(this.form)) {
      return;
    }

    const api = draft
      ? () => this.mortgageApi.saveComplianceHandoverRecommendedDraft(this.mortgageId, this.handoverId, this.value)
      : () => this.mortgageApi.saveComplianceHandoverRecommended(this.mortgageId, this.handoverId, this.value);

    this.sidepane.save(this.form, api);
  }

  public onSelectFeesReasonClicked() {
    if (this.value.feesToLoanReason) {
      this.selectorSidepaneService.initialySelectedItem = {
        item: this.value.feesToLoanReason,
        other: this.value.otherFeesToLoanReason,
      }
    }
    this.router.navigate(["selectFeesReason"], { relativeTo: this.route });
  }

  public onSelectDebtConsolidationReasonClicked() {
    if (this.value.debtConsolidationReason) {
      this.selectorSidepaneService.initialySelectedItem = {
        item: this.value.debtConsolidationReason,
        other: this.value.otherDebtConsolidationReasonNotAppropriateReason,
      }
    }
    this.router.navigate(["selectDebtConsolidationReasonRecommended"], { relativeTo: this.route });
  }

  public onSelectSchemeClicked() {
    this.selectorSidepaneService.initialySelectedItem = this.value.scheme;
    this.router.navigate(["selectScheme"], { relativeTo: this.route });
  }

  public onSelectLenderClicked() {
    const { lender } = this.value;

    this.mortgagesService.lenderSelected$.next(lender);

    this.router.navigate(["selectLender"], { relativeTo: this.route });
  }

  private setup() {
    this.mortgagesService.lenderSelected$.pipe(takeUntil(this.destroy$)).subscribe((lender: Lender) => {
      lender && this.form.get("lender").setValue(lender);
    });

    this.selectorSidepaneService.subscribeByKey("selectScheme", this.destroy$).subscribe(scheme => {
      this.control("scheme").setValue(scheme);
    });

    this.selectorSidepaneService.subscribeItemSelectedByKey("selectDebtConsolidationReason", this.destroy$).subscribe((reason: SelectorSidepaneItemSelected<MortgageDebtConsolidationReason>) => {
      if (reason.item[0] === "OTHER") {
        this.control("debtConsolidationReason").setValue(reason.item);
        this.control("otherDebtConsolidationReasonNotAppropriateReason").setValue(reason.other)
      } else {
        this.control("debtConsolidationReason").setValue(reason.item);
        this.control("otherDebtConsolidationReasonNotAppropriateReason").setValue(null);
      }
    });

    this.selectorSidepaneService.subscribeItemSelectedByKey("selectFeesReason", this.destroy$).subscribe((reason: SelectorSidepaneItemSelected<FeesToLoanReason>) => {
      if (reason.item === "OTHER") {
        this.control("feesToLoanReason").setValue(reason.item);
        this.control("otherFeesToLoanReason").setValue(reason.other)
      } else {
        this.control("feesToLoanReason").setValue(reason.item);
        this.control("otherFeesToLoanReason").setValue(null);
      }
    });


    this.selectPropcoService.code$.pipe(takeUntil(this.destroy$)).subscribe(code => {
      code && this.control("propCode").setValue(code);
    });

    // this.control("knownIncomeChanges").valueChanges.subscribe(knownIncomeChanges => {
    //   setControlEnabled(this.control("knownIncomeChangesExplanation"), knownIncomeChanges);
    // });
    // this.control("knownIncomeChanges").updateValueAndValidity();

    this.sidepane.saveClick$.pipe(takeUntil(this.destroy$)).subscribe(draft => this.onSubmit(draft));
    this.form.statusChanges.subscribe(status => {
      // console.log(status, this.form.valid);
      setTimeout(() => this.sidepane.isValid = this.form.valid);
    });
  }

  private setupControls() {
    this.control("complianceIllustration").valueChanges.subscribe(complianceIllustration => {
      setControlEnabled(this.control("illustrationDate"), !!complianceIllustration?.filename);
    });
    this.control("complianceIllustration").updateValueAndValidity();

    this.control("scheme").valueChanges.subscribe((scheme: APPLICANT_SCHEME) => {
      setControlEnabled(this.control("htb"), scheme === "HELP_TO_BUY");
      setControlEnabled(this.control("sharedOwnership"), scheme === "SHARED_OWNERSHIP");
      setControlEnabled(this.control("sharedOwnershipReason"), scheme === "SHARED_OWNERSHIP");
    });
    this.control("scheme").updateValueAndValidity();

    this.control("repaymentMethod").valueChanges.subscribe((method: PROPERTY_PAYMENT_METHOD) => {
      setControlEnabled(this.control("partAndPartInterest"), method === "PART_AND_PART");
      setControlEnabled(this.control("partAndPartRepayment"), method === "PART_AND_PART");
    });
    this.control("repaymentMethod").updateValueAndValidity();

    this.control("erc").valueChanges.subscribe(erc => {
      setControlEnabled(this.control("ercAmount"), erc);
      setControlEnabled(this.control("ercReason"), erc);
    })
    this.control("erc").updateValueAndValidity();

    this.control("endDateType").valueChanges.subscribe((endDateType: MORTGAGE_PRODUCT_END_TYPE) => {
      setControlEnabled(this.control("endDate"), endDateType === "DATE");
      setControlEnabled(this.control("endYearsMonths"), endDateType === "YEARS_MONTHS");
      setControlEnabled(this.control("lifetimeProductRateReason"), endDateType === "LIFETIME");
      setControlEnabled(this.control("offset"), endDateType === "LIFETIME");
    })
    this.control("endDateType").updateValueAndValidity();

    this.control("offset").valueChanges.subscribe(offset => {
      setControlEnabled(this.control("offsetReason"), offset);
    });
    this.control("offset").updateValueAndValidity();

    this.control("newErc").valueChanges.subscribe(newErc => {
      setControlEnabled(this.control("newErcAmount"), newErc);
      setControlEnabled(this.control("noErcReason"), !newErc);
    });
    this.control("newErc").updateValueAndValidity();

    this.control("mortgageBeyondRetirement").valueChanges.subscribe(mortgageBeyondRetirement => {
      setControlEnabled(this.control("retirementIncomeDetail"), mortgageBeyondRetirement);
    });
    this.control("mortgageBeyondRetirement").updateValueAndValidity();

    this.control("debtConsolidation").valueChanges.subscribe(debtConsolidation => {
      setControlEnabled(this.control("debtConsolidationMatchesObjective"), debtConsolidation);
      setControlEnabled(this.control("notProceedingReason"), debtConsolidation);
    });
    this.control("debtConsolidation").updateValueAndValidity();

    this.form.valueChanges.subscribe((value: NewComplianceHandoverRecommendedDTO) => {
      setControlEnabled(this.control("amountUsedToRepay"), value.debtConsolidation && !value.debtConsolidationMatchesObjective);
      setControlEnabled(this.control("debtConsolidationNote"), value.debtConsolidation && !value.debtConsolidationMatchesObjective);
      setControlEnabled(this.control("debtConsolidationReason"), value.debtConsolidation && !value.debtConsolidationMatchesObjective);
    });

    this.control("feesToLoan").valueChanges.subscribe(feesToLoan => {
      setControlEnabled(this.control("feesToLoanReason"), feesToLoan);
    });
    this.control("feesToLoan").updateValueAndValidity();

    setControlEnabled(this.control("liabilitiesNotRepaidReason"), this.sidepane.dto.hasNotRepaidLiabilities);

    setControlEnabled(this.control("furtherAdvanceReason"), this.objective === "FA");
    setControlEnabled(this.control("productTransferReason"), this.objective === "RESI_PT" || this.objective === "BTL_PT");
    setControlEnabled(this.control("portingReason"), this.objective === "PORT");
    setControlEnabled(this.control("portFurtherAdvanceReason"), this.objective === "PORT_FA");

    setControlEnabled(this.control("reasonForChange"), isAwatingOrApproved(this.sidepane.dto.state));

    setControlEnabled(this.control("notPortingReason"), this.isNotProtingReasonVisible());

  }

  private isNotProtingReasonVisible() {
    return !this.firstTimeBuyer && this.objective != "PORT" && this.objective != "PORT_FA";
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }

  public formatPropco(value: PropCode) {
    if (!value) return null;

    return `${value.productDescription} (${value.code})`;
  }
}

