import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';

export enum E {}

let instanceCounter = 1;

@Component({
  selector: 'hf-custom-radio-checks-field',
  templateUrl: './custom-radio-checks-field.component.html',
  styleUrls: ['./custom-radio-checks-field.component.scss'],
})
export class CustomRadioChecksFieldComponent implements OnChanges {
  @Input() group: FormGroup;
  @Input() dataObj: E;
  @Input() type: string;
  @Input() elemId: string;

  public instanceCounter = instanceCounter++;

  private enumData: Array<[string, any]>;

  ngOnChanges(changes: SimpleChanges): void {
    if ("dataObj" in changes) {
      const dataObj = changes["dataObj"].currentValue;

      this.enumData = dataObj && this.parseEnum(this.dataObj);
    }
  }

  parseEnum(e: any): Array<[string, any]> {
    return e instanceof Map
      ? [...e.entries()]
      : Object.entries(e);
  }

  public onLabelClicked(value) {
    if (this.group.get(this.elemId).validator)
      return;

    if(this.group.get(this.elemId).value == value) {
      setTimeout(() => {
        this.group.get(this.elemId).reset();
      }, 20);
    }
  }
}
