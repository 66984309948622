import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

import { HELP_TO_BUY_STATUS } from "../../../utils/variables.data";

@Injectable()
export class SelectHelpToBuyStatusService {
  private readonly _status = new BehaviorSubject<HELP_TO_BUY_STATUS>(undefined);

  public status$ = this._status.asObservable();
  public get status() { return this._status.getValue(); }

  public setStatus(status: HELP_TO_BUY_STATUS) {
    this._status.next(status);
  }
}
