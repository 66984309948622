
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectHelpToBuyRegionComponent } from './select-help-to-buy-region.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomButtonModule } from '../../../shared/custom-button/custom-button.module';
import { CustomSelectorModule } from '../../../shared/custom-selector/custom-selector.module';

@NgModule({
  declarations: [SelectHelpToBuyRegionComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CustomButtonModule,
    CustomSelectorModule
  ],
  exports: [SelectHelpToBuyRegionComponent]
})
export class SelectHelpToBuyRegionModule { }
