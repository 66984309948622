<div class="header">
  <div class="subject">
    {{ item.email.subject }}
  </div>

  <div class="date">
    {{ item.createdDate | date:'d MMM, y HH:mm' }}
  </div>
</div>

<div *ngIf="isWhite" class="triangle"></div>

<hf-message-journal-item-message [item]="item" [noSeeLess]="!replies" #message></hf-message-journal-item-message>

<div class="footer">
  <span *ngIf="!replies && item.email.threadReplies" (click)="onSeeThreadClicked()">
    See thread ({{ item.email.threadReplies }})

    <hf-named-icon *ngIf="item.email.attachmentsInThread" icon="attachments.svg"></hf-named-icon>
  </span>

  <span *ngIf="replies" (click)="onSeeLess()" class="right">
    See less
  </span>

  <hfc-spinner *ngIf="isLoading" class="secondary" size="small"></hfc-spinner>
</div>

<div *ngIf="replies" @expandAnimation>

<ng-container *ngFor="let reply of replies; let first=first">
  <div class="separator" [class.first]="first"></div>

  <hf-message-journal-item-message [item]="reply" [showDate]="true"></hf-message-journal-item-message>
</ng-container>
</div>

<!-- <div class="email-header"> -->
<!--   <hf-avatar [photo]="item.executor.photo" class="size-24"></hf-avatar> -->

<!--   <div class="from"> -->
<!--     {{ item.executor.fullName }} -->

<!--     <hf-named-icon icon="reply"></hf-named-icon> -->
<!--   </div> -->

<!--   <div class="to"> -->
<!--     <strong>To:</strong> -->
<!--     {{ item.email.recipients }} -->
<!--   </div> -->

<!--   <hf-email-events [email]="item.email"></hf-email-events> -->
<!-- </div> -->

<!-- <div class="body" [class.clamp]="!seeMore" [innerHTML]="item.email.body" #body> -->
<!-- </div> -->

<!-- <div class="footer"> -->
<!--   <span ngIf="isClamped" (click)="seeMore = true">See more</span> -->
<!-- </div> -->


