import { Component, Inject, InjectionToken, OnDestroy } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { Subject } from "rxjs";
import { ToastService } from "../../../../../../projects/client/src/app/shared/services/toast.service";
import { JournalWithReasonDTO } from "../../../models/journal-with-reason.dto";
import { FormFieldService } from "../../../services/form-field.service";
import { NotProceedingReasons, NotProceedingReasonsService, NotProceedingReasonUpdateData } from "../../../services/not-proceeding-reasons.service";
import {
  fadeAnimation,
  sidepaneMove
} from "../../../shared/animations/sidepane-animation";
import { FileControlsConfigBuilder } from "../../../shared/fileuploader/utils/fileuploader.utils";
import { setControlEnabled } from "../../../utils/functions/set-control-enabled";
import { SelectDecliningReasonService } from "../select-declining-reason/select-declining-reason.service";


export interface NotProceedingService {
  hasReason: boolean;
  stateChange$: Subject<void>;

  setNotProceeding(
    paramMap: ParamMap,
    journalWithReason: JournalWithReasonDTO
  ): Promise<void>;
}

export const NOT_PROCEEDING_SERVICE = new InjectionToken<NotProceedingService>(
  "NOT_PROCEEDING_SERVICE"
);

@Component({
  selector: "hf-not-proceeding",
  templateUrl: "not-proceeding.component.html",
  styleUrls: [
    "not-proceeding.component.scss",
    "../../../styles/sidepanes.partial.scss",
  ],
  animations: [sidepaneMove, fadeAnimation],
  host: { "[@sidepaneMove]": "true" },
  providers: [FormFieldService],
})
export class NotProceedingComponent implements OnDestroy {
  public mortgageNotProceedingForm: FormGroup;
  public submitResponse;
  public mortgageId;
  public notProceedingReasons: NotProceedingReasons[];
  public notProceedingReasonsData: NotProceedingReasonUpdateData;

  public get notProceedingReason() {
    return this.mortgageNotProceedingForm.get("notProceedingReason").value;
  }

  public control(name: string) {
    return this.mortgageNotProceedingForm.get(name);
  }

  public enabled(name: string) {
    return this.mortgageNotProceedingForm.get(name).enabled;
  }

  public get value() {
    return this.mortgageNotProceedingForm.value;
  }

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastService,
    private selectDecliningReasonService: SelectDecliningReasonService,
    @Inject(NOT_PROCEEDING_SERVICE)
    private notProceedingService: NotProceedingService,
    private notProceedingReasonsService: NotProceedingReasonsService
  ) {
    this.mortgageNotProceedingForm = this.fb.group({
      journal: this.fb.group({
        sendToClient: [false],
        internalNotes: ["", Validators.required],
        messageToClient: ["", Validators.required],
        connectedDocument: this.fb.group({
          attachment: this.fb.group(
            new FileControlsConfigBuilder().maxFileSizeEmail().build()
          ),
        }),
      }),
      notProceedingReason: ["", Validators.required],
      notProceedingFollowUpReason: [""],
      reason: ["", Validators.required],
      reasonOther: [""],
      declinedReasonId: [null, Validators.required],
      declinedReasonOther: [""],
      _declinedReason: [""],
    });

    this.mortgageNotProceedingForm.get("declinedReasonId").disable();

    this.mortgageNotProceedingForm
      .get("journal.sendToClient")
      .valueChanges.subscribe((newValue) => {
        if (newValue) {
          this.mortgageNotProceedingForm
            .get("journal.messageToClient")
            .enable();
          this.mortgageNotProceedingForm
            .get("journal.connectedDocument")
            .enable();
        } else {
          this.mortgageNotProceedingForm
            .get("journal.messageToClient")
            .disable();
          this.mortgageNotProceedingForm
            .get("journal.connectedDocument")
            .disable();
        }
      });

    this.mortgageNotProceedingForm.get("journal.sendToClient").setValue(false);

    setControlEnabled(
      this.mortgageNotProceedingForm.get("reason"),
      this.notProceedingService.hasReason
    );

    this.notProceedingReasonsService.notProceedingReasonSelected$.subscribe(
      (reason) => {
        this.mortgageNotProceedingForm
          .get("notProceedingReason")
          .setValue(reason);
      }
    );

    this.notProceedingReasonsService.notProceedingReasonDataUpdated$.subscribe(
      (data) => this.setNotProceedingReasonsData(data)
    );

    this.selectDecliningReasonService.followup$
      .subscribe(reason => {
        this.control("_declinedReason").patchValue(reason);
        this.control("declinedReasonId").patchValue(reason.followup.id);
        this.control("declinedReasonOther").patchValue(reason.other);
      });
  }

  public onSelectDecliningReasonClicked() {
    this.selectDecliningReasonService.notProceedingFollowups = this.notProceedingReasonsData.notProceedingFollowups;

    this.router.navigate(["selectDecliningReason"], { relativeTo: this.route });
  }

  public setNotProceedingReasonsData(event: NotProceedingReasonUpdateData) {
    this.notProceedingReasonsData = event;

    setControlEnabled(this.control("declinedReasonId"), !!this.notProceedingReasonsData?.notProceedingFollowups?.length);
  }

  async onSubmit() {
    const data = this.mortgageNotProceedingForm.value;

    data.notProceeding = this.notProceedingReasonsData;
    data.notProceeding.declinedReasonOther =
      this.mortgageNotProceedingForm.get("declinedReasonOther").value || null;
      data.notProceeding.declinedReasonId =
          this.mortgageNotProceedingForm.get("declinedReasonId").value || null;
      data.notProceeding.declinedReasonOther =
        this.mortgageNotProceedingForm.get("declinedReasonOther").value || null;

    // deleting these from form as they were added earlier only to leverage functions of reactive forms
    delete data.reasonOther;
    delete data.notProceedingFollowUpReason;
    delete data.declinedReasonOther;
    delete data.notProceedingReason;
    delete data.notProceeding.notProceedingFollowups;

    try {
      await this.notProceedingService.setNotProceeding(
        this.route.snapshot.paramMap,
        data
      );
      this.notProceedingService.stateChange$.next();
      this.toastService.add("Marked as Not Proceeding");
      this.router.navigate([".."], { relativeTo: this.route });
    } catch (err) {
      this.toastService.add("Failed ", "error", err);
    }
  }

  ngOnDestroy(): void {
    this.notProceedingReasonsService.setNotProceedingReasonSelected(undefined);
  }
}
