import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserRoutingModule } from './user-routing.module';
import { UserListModule } from './user-list/user-list.module';
import { AddUserModule } from './add-user/add-user.module';
import { SelectUserTypeModule } from './select-user-type/select-user-type.module';
import { SelectUserModule } from '../../shared-layout/side-panes/select-user/select-user.module';
import {SelectUserForDeleteModule} from "./select-user-for-delete/select-user-for-delete.module";
import {HeronUserService} from "./user.service";
import { DeleteAdvisorBothModule } from './delete-advisor-both/delete-advisor-both.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    UserRoutingModule,
    UserListModule,
    AddUserModule,
    SelectUserTypeModule,
    SelectUserModule,
    SelectUserForDeleteModule,
    DeleteAdvisorBothModule
  ], providers: [
    HeronUserService
  ]
})
export class UserModule { }
