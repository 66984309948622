import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import { merge, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import {
  Confirmation,
  DialogService,
} from '../../../../../../../projects/client/src/app/shared/services/dialog.service';
import { ToastService } from '../../../../../../../projects/client/src/app/shared/services/toast.service';
import {
  ProtectionApplicationListDTO,
  ProtectionCaseTableParams,
} from '../../../../models/protection';
import { TaskTableParams } from '../../../../models/task';
import { DropdownMenuItem } from '../../../../shared/custom-menu/custom-menu.component';
import { DataSourceFactory } from '../../../../shared/table-utils/table-settings.service';
import {
  TaskApiService,
  TaskListFilterDTO,
  TaskListParamsDTO,
} from '../../../task/task-api.service';
import { ProtectionDashboardService } from './protection-dashboard.service';
import { TaskService } from '../../../task/task.service';
import { Mortgage } from '../../../mortgages/state/mortgage.actions';
import { PROTECTION_MENU_ACTIONS } from '../../../../utils/variables.data';
import {
  AddApplicantService,
  AddApplicationService,
  ProtectionComplianceHandoverService,
} from '../../side-panes';
import {
  ApproveComplianceService,
  APPROVE_COMPLIANCE_SERVICE,
  ArrangeAppointmentService,
  LogContactAttemptService,
  NotProceedingService,
  NOT_PROCEEDING_SERVICE,
  RequestFactFindService,
  REQUEST_FACTFIND_SERVICE,
  SwitchSalesAdvisorService,
  SWITCH_SALES_ADVISOR_SERVICE,
} from '../../../../shared-layout/side-panes';
import { ProtectionApiService } from '../../../../services/protection-api.service';
import { JournalApiService } from '../../../../services/journal-api.service';
import { DocumentApiService } from '../../../../services/document-api.service';
import { SessionStoreService } from '../../../../services/session-store.service';
import { NavigationService } from '../../../../services/navigation.service';
import { DocumentTitleService } from '../../../../services/document-title.service';
import { ToasterService } from '../../../../services/toaster.service';

export interface DashboardDropdownMenuItem extends DropdownMenuItem {
  queryParams?: {};
  callback?: () => void;
  button?: boolean;
  type?: PROTECTION_MENU_ACTIONS;
}

const addNoteDefinition = {
  label: 'Add Note',
  icon: 'post_add',
};

const replaceDefinition = {
  label: 'Replace',
  icon: 'dynamic_feed',
};

const primaryButtonDefinition: {
  [key: string]: { label: string; icon?: string };
} = {
  ATTEMPT_CONTACT: {
    label: 'Log Contact',
    icon: 'add_ic_call',
  },
  ARRANGE_APPOINTMENT: {
    label: 'Arrange Appointment',
    icon: 'event',
  },
  APPOINTMENT: addNoteDefinition,
  REQUEST_FACT_FIND: {
    label: 'Request Fact Find',
  },
  CLIENT_REGISTRATION: addNoteDefinition,
  COMPLETE_FACT_FIND: addNoteDefinition,
  SIGN_FACT_FIND: addNoteDefinition,
  REVIEW_FACT_FIND: {
    label: 'Review Fact Find',
  },
  CREATE_APPLICATIONS: {
    label: 'Open Solution Builder',
  },
  COMPLIANCE_HANDOVER: {
    label: 'Compliance Handover',
  },
  COMPLIANCE_APPROVAL: {
    label: 'View Client Sheet',
  },
  APPLICATION_SUBMISSION: addNoteDefinition,
  APPLICATION_COMPLETION: addNoteDefinition,
  UNABLE_TO_DETERMINE: addNoteDefinition,
  NONE: addNoteDefinition,
};

@Component({
  selector: 'hf-protection-dashboard',
  templateUrl: 'protection-dashboard.component.html',
  styleUrls: ['protection-dashboard.component.scss'],
})
export class ProtectionDashboardComponent implements OnInit, OnDestroy {
  public tab: string;
  public menuItems: DashboardDropdownMenuItem[];
  public applications: ProtectionApplicationListDTO[];
  public containsVulnerableCustomer = false;
  public isReplacingProtectionCase = false;
  private destroy$ = new Subject();

  constructor(
    private actions$: Actions,
    private route: ActivatedRoute,
    private router: Router,
    private protectionApiService: ProtectionApiService,
    public protectionDashboardService: ProtectionDashboardService,
    private journalApiService: JournalApiService,
    private documentApiService: DocumentApiService,
    private taskApiService: TaskApiService,
    private sessionStoreService: SessionStoreService,
    private arrangeAppointmentService: ArrangeAppointmentService,
    private logContactAttemptService: LogContactAttemptService,
    private addApplicationService: AddApplicationService,
    private addApplicantService: AddApplicantService,
    private protectionComplianceHandoverService: ProtectionComplianceHandoverService,
    private dialogService: DialogService,
    private toastService: ToastService,
    private navigationService: NavigationService,
    private taskService: TaskService,
    private documentTitleService: DocumentTitleService,
    private toasterService: ToasterService,
    @Inject(APPROVE_COMPLIANCE_SERVICE)
    private approveComplianceService: ApproveComplianceService,
    @Inject(NOT_PROCEEDING_SERVICE)
    private notProceedingService: NotProceedingService,
    @Inject(SWITCH_SALES_ADVISOR_SERVICE)
    private switchSalesAdvisorService: SwitchSalesAdvisorService,
    @Inject(REQUEST_FACTFIND_SERVICE)
    private requestFactFindService: RequestFactFindService,
  ) {
    this.route.queryParams
      .pipe(
        takeUntil(this.destroy$),
        filter((params) => params.tab),
      )
      .subscribe((params) => {
        this.tab = params.tab;
      });

    merge(
      this.arrangeAppointmentService.appointmentSubmitted$,
      this.logContactAttemptService.contactAttemptAdded$,
      this.addApplicationService.applicationAdded$,
      this.addApplicantService.applicantAdded$,
      this.protectionComplianceHandoverService.handoverChange$,
      this.approveComplianceService.approve$,
      this.notProceedingService.stateChange$,
      this.switchSalesAdvisorService.advisorChange$,
      this.requestFactFindService.requested$,
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe((tab) => this.initialize(tab as any));

    merge(this.taskService.reloadTasks$)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.refresh());

    this.actions$
      .pipe(takeUntil(this.destroy$), ofActionSuccessful(Mortgage.MenuAction))
      .subscribe((action: Mortgage.MenuAction) => {
        action.action === 'arrangeAppointment' &&
          this.protectionDashboardService.goToArrangeAppointment(
            this.protection,
          );
        action.action === 'addContactAttempt' && this.addContactAttempt();
      });
  }

  public get protection() {
    return this.protectionDashboardService.protectionDashboardDTO;
  }

  public get primaryButtonDefinition() {
    return this.protection.state === 'COMPLETED'
      ? replaceDefinition
      : primaryButtonDefinition[this.protection?.nextAction] ||
          addNoteDefinition;
  }

  private get protectionCaseId() {
    return this.route.snapshot.paramMap.get('id');
  }
  private get protectionDashboardDTO() {
    return this.protectionDashboardService.protectionDashboardDTO;
  }

  public journalDataSourceFactory: DataSourceFactory<ProtectionCaseTableParams> =
    (params) => {
      params.protectionCaseId = this.protectionCaseId;
      return this.journalApiService.listProtectionCaseJournal(params);
    };

  public documentsDataSourceFactory: DataSourceFactory<ProtectionCaseTableParams> =
    (params) => {
      params.protectionCaseId = this.protectionCaseId;
      return this.documentApiService.listProtectionCaseDocuments(params);
    };

  public taskDataSourceFactory: DataSourceFactory<TaskTableParams> = (
    params,
  ) => {
    const taskListParams: TaskListParamsDTO = {
      ...params,
      type: 'PROTECTION',
      protectionCaseId: this.protectionCaseId,
    };

    const taskListFilter: TaskListFilterDTO = {
      filter: params.filter,
      viewCompleted: params.viewCompleted,
    };

    return this.taskApiService.listTasks(taskListParams, taskListFilter);
  };

  public async onItemSelected(item: DashboardDropdownMenuItem) {
    if (item.type === PROTECTION_MENU_ACTIONS.NOT_PROCEEDING) {
      this.onClientNotProceedingClicked();
      return;
    }
    if (item.routerLink) {
      this.router.navigate([item.routerLink], {
        relativeTo: this.route,
        queryParams: item.queryParams,
      });
    }

    if (item.callback) {
      item.callback();
    }
  }

  public ngOnInit() {
    this.setup();
  }

  public ngOnDestroy(): void {
    this.protectionDashboardService.protectionDashboardDTO = undefined;
    this.destroy$.next();
    this.sessionStoreService.set('app/protection/#');
  }

  public async refresh() {
    this.protectionApiService
      .getDashboardData(this.protectionCaseId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data) => {
          this.protectionDashboardService.protectionDashboardDTO = data;
          this.initializeMenuItems();
        },
        (e) => {
          this.toastService.add('Failed to refresh protection', 'error', e);
        },
      );
  }

  public onActiveTabKeyChanged(tab: string) {
    this.tab = tab;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { tab },
    });
  }

  public onBack() {
    this.navigationService.protectionDashboardGoBack();
  }

  public onPrimaryButtonClicked() {
    const action = this.protection?.nextAction;

    switch (action) {
      case 'ATTEMPT_CONTACT':
        this.addContactAttempt();
        return;

      case 'ARRANGE_APPOINTMENT':
        this.protectionDashboardService.goToArrangeAppointment(this.protection);
        return;

      case 'REQUEST_FACT_FIND':
        this.router.navigate(['requestFactFind'], { relativeTo: this.route });
        return;

      case 'REVIEW_FACT_FIND':
        this.router.navigate([], { queryParams: { tab: 'fact-find' } });
        return;

      case 'CREATE_APPLICATIONS':
        this.protectionDashboardService.tryOpenSolutionBuilder(this.protection);
        return;

      case 'COMPLIANCE_HANDOVER':
        this.router.navigate(['complianceHandover'], {
          relativeTo: this.route,
        });
        return;

      case 'COMPLIANCE_APPROVAL':
        this.router.navigate([], { queryParams: { tab: 'client-sheet' } });
        return;

      default:
        if (this.protection.state === 'COMPLETED') {
          this.showReplacePopup();
        } else {
          this.router.navigate(['addNote'], { relativeTo: this.route });
        }
    }
  }

  public async showReplacePopup(): Promise<boolean> {
    const confirmation: Confirmation = {
      title: 'Replace Protection',
      message: `A new protection case will be created and the status of this case will change to ‘Replacement in Progress’.
                <br><br>Are you sure you want to replace this protection?`,
      acceptLabel: 'Replace',
      rejectLabel: 'Cancel',
    };

    const discard = await this.dialogService.confirm(confirmation);

    if (!discard) {
      return;
    }

    this.isReplacingProtectionCase = true;
    this.protectionApiService
      .replaceProtectionCase(this.protection.id, this.protection)
      .finally(() => (this.isReplacingProtectionCase = false))
      .then((res) => {
        this.router.navigate(['app/protection', res.id]);
        setTimeout(() => {
          this.setup();
        }, 500);
      })
      .catch((error) => {
        this.toasterService.callToaster({
          severity: 'error',
          summary: 'Error',
          detail: `${error.error.error.message}`,
        });
        return error;
      });
  }

  private onClientNotProceedingClicked() {
    this.protectionApiService
      .getApplicationList(this.protectionCaseId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (result) => {
        this.applications = result.applications;
        for (let i = 0; i < this.protection.applicationCount; i++) {
          if (
            this.applications[i].state !== 'NOT_PROCEEDING' &&
            this.applications[i].state !== 'ON_RISK'
          ) {
            const confirmation: Confirmation = {
              title: 'Client Not Proceeding',
              message:
                ' Please mark each individual application as not proceeding first.',
              acceptLabel: 'OK',
              acceptButtonType: 'primary',
              rejectButtonType: undefined,
            };
            await this.dialogService.confirm(confirmation);
            return;
          }
        }
        this.router.navigate(['./notProceeding'], { relativeTo: this.route });
      });
  }

  private setup() {
    this.initialize();
    this.initializeMenuItems();
  }

  private initialize(tab?: string) {
    this.protectionDashboardService.isLoading = true;

    this.protectionApiService
      .getDashboardData(this.protectionCaseId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data) => {
          this.containsVulnerableCustomer = data.client.vulnerableCustomer;
          this.protectionDashboardService.protectionDashboardDTO = data;
          this.protectionDashboardService._protectionDashboardDTO$.next(data);
          this.documentTitleService.setTitleParams({
            client: data.client.fullName,
          });

          this.protectionDashboardService.isLoading = false;
          setTimeout(() => tab && this.onActiveTabKeyChanged(tab));
        },
        (e) => {
          this.toastService.add('Protection not found', 'error', e);
          this.protectionDashboardService.isLoading = false;
        },
      );
  }

  private addContactAttempt() {
    this.arrangeAppointmentService.noSubmitMode = false;
    this.logContactAttemptService.currentAdvisor =
      this.protectionDashboardDTO.advisor;
    this.logContactAttemptService.client = this.protectionDashboardDTO.client;
    this.logContactAttemptService.state = this.protectionDashboardDTO.state;
    this.logContactAttemptService.appointmentVisible =
      !this.protectionDashboardDTO.appointment ||
      this.protectionDashboardDTO.appointment.state !== 'PENDING';

    this.router.navigate(['addContactAttempt'], { relativeTo: this.route });
  }

  private initializeMenuItems() {
    this.menuItems = [
      {
        label: 'Log Contact...',
        type: PROTECTION_MENU_ACTIONS.LOG_CONTACT,
        button: true,
        callback: () => this.addContactAttempt(),
      },
      {
        label: 'Prerequisites...',
        type: PROTECTION_MENU_ACTIONS.PREREQUISITES,
        routerLink: 'prerequisites',
      },
      {
        label: 'Compliance Handover...',
        type: PROTECTION_MENU_ACTIONS.COMPLIANCE_HANDOVER,
        visible: () => !!this.protectionDashboardDTO.applicationCount,
        routerLink: 'complianceHandover',
      },
      {
        label: 'Add Note...',
        type: PROTECTION_MENU_ACTIONS.ADD_NOTE,
        button: true,
        routerLink: 'addNote',
      },
      {
        label: 'Add Task...',
        type: PROTECTION_MENU_ACTIONS.ADD_TASK,
        button: true,
        routerLink: 'addTask',
      },
      {
        label: 'Replace',
        type: PROTECTION_MENU_ACTIONS.REPLACE,
        button: true,
        routerLink: 'replace',
      },
      {
        label: 'Add Document...',
        type: PROTECTION_MENU_ACTIONS.ADD_DOCUMENT,
        routerLink: 'addDocument',
      },
      {
        label: 'Request Document...',
        type: PROTECTION_MENU_ACTIONS.REQUEST_DOCUMENT,
        routerLink: 'requestDocument',
      },
      {
        label: 'Suitability Letter...',
        type: PROTECTION_MENU_ACTIONS.SUITABILITY_LETTER,
        routerLink: 'suitability-letter',
        visible: () => this.isSuitabilityLetterVisible(),
      },
      {
        label: 'Midas Resolution...',
        type: PROTECTION_MENU_ACTIONS.PUSH_TO_MIDAS,
        routerLink: 'push-to-midas',
      },
      {
        label: 'Client Not Proceeding...',
        type: PROTECTION_MENU_ACTIONS.NOT_PROCEEDING,
        visible: () => this.protectionDashboardDTO.state !== 'NOT_PROCEEDING',
        routerLink: 'notProceeding',
        styles: { color: '#ED3A26' },
      },
      {
        label: 'Undo Client Not Proceeding',
        type: PROTECTION_MENU_ACTIONS.UNDO_NOT_PROCEEDING,
        callback: () => this.onUndoClientNotProceedingClicked(),
        visible: () => this.protectionDashboardDTO.state === 'NOT_PROCEEDING',
      },
      {
        label: 'Delete',
        type: PROTECTION_MENU_ACTIONS.DELETE,
        callback: () => this.onDeleteMenuItemClicked(),
        styles: { color: '#ED3A26' },
      },
    ];
  }

  private async onUndoClientNotProceedingClicked() {
    const confirmation: Confirmation = {
      title: 'Undo Not Proceeding',
      message:
        'Are you sure you want reactivate this protection case back to Proceeding?',
      acceptLabel: 'Reactivate',
    };

    if (!(await this.dialogService.confirm(confirmation))) {
      return;
    }

    try {
      await this.protectionApiService.reactivateProtection(
        this.protectionCaseId,
      );

      this.toastService.add('Protection case reactivated');
      this.initialize();
    } catch (err) {
      this.toastService.add('Failed.', 'error', err);
    }
  }

  private async onDeleteMenuItemClicked() {
    if (this.protectionDashboardDTO.state === 'NOT_PROCEEDING') {
      const confirmation: Confirmation = {
        title: 'Delete Protection Case',
        message: 'Are you sure you want to delete this protection case?',
        acceptLabel: 'Delete',
      };

      if (!(await this.dialogService.confirm(confirmation))) {
        return;
      }

      await this.protectionApiService.deleteProtection(this.protectionCaseId);

      this.toastService.add('Protection case deleted');
      this.onBack();
    } else {
      const confirmation: Confirmation = {
        title: 'Delete Protection Case',
        message:
          'In order to delete this protection case you first have to mark it as Not Proceeding.',
        acceptLabel: 'Not Proceeding',
      };

      if (!(await this.dialogService.confirm(confirmation))) {
        return;
      }

      this.router.navigate(['notProceeding'], { relativeTo: this.route });
    }
  }

  private isSuitabilityLetterVisible(): boolean {
    return (
      this.protectionDashboardDTO.factFind?.status === 'APPROVED' &&
      this.protectionDashboardDTO.complianceHandoverState === 'APPROVED'
    );
  }
}
