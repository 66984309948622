import { Component, Inject, InjectionToken, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Subject } from 'rxjs';

import { ToastService } from '../../../../../../projects/client/src/app/shared/services/toast.service';
import { UserSimpleDTO } from '../../../models/user-simple.dto';
import { fadeAnimation, sidepaneMove } from '../../../shared/animations/sidepane-animation';

export interface SwitchSalesAdvisorService {
  heading: string;
  advisorChange$: Subject<void>;

  getAdvisors(): Promise<UserSimpleDTO[]>
  changeSalesAdvisor(paramMap: ParamMap, salesAdvisorId: string): Promise<void>;
}

export const SWITCH_SALES_ADVISOR_SERVICE = new InjectionToken<SwitchSalesAdvisorService>("SWITCH_SALES_ADVISOR_SERVICE");

@Component({
  selector: 'hf-switch-sales-advisor',
  templateUrl: './switch-sales-advisor.component.html',
  styleUrls: [
    './switch-sales-advisor.component.scss',
    '../../../styles/sidepanes.partial.scss'
  ],
  animations: [sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove]': 'true' }
})
export class SwitchSalesAdvisorComponent implements OnDestroy {
  public form = new FormGroup({
    advisor: new FormControl(null, Validators.required),
  });
  public items: any[];
  public isLoading = true;
  public isSubmitting = false;
  public get heading() { return this.switchSalesAdvisorService.heading }

  private destroy$ = new Subject();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    @Inject(SWITCH_SALES_ADVISOR_SERVICE) private switchSalesAdvisorService: SwitchSalesAdvisorService,
    private toastService: ToastService
  ) {
    this.initialize();
  }

  private async initialize() {
    this.isLoading = true;

    this.items = await this.switchSalesAdvisorService.getAdvisors();

    this.isLoading = false;
  }

  public async onSubmit() {
    this.isSubmitting = true;
    const salesAdvisorId = this.form.value.advisor.id;

    try {
      await this.switchSalesAdvisorService.changeSalesAdvisor(this.route.snapshot.paramMap, salesAdvisorId);

      this.toastService.add("Successfully changed.");
      this.switchSalesAdvisorService.advisorChange$.next();
      this.onBack();
    } catch (err) {
      this.toastService.add("Failed. Please try again.", "error", err);
    }

    this.isSubmitting = false;
  }

  public onBack() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }
}
