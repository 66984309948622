import { EventEmitter, Inject, Injectable, InjectionToken } from '@angular/core';
import { MORTGAGE_DOCUMENT_TYPE, PROTECTION_DOCUMENT_TYPE } from '../../../utils/variables.data';
import { JournalWithDocumentDTO } from '../../../models/journal';
import { DocumentApiService } from '../../../services/document-api.service';
import { ParamMap } from '@angular/router';
import { DocumentWithIdDTO } from '../../../models/document';

export type AddDocumentServiceAddDocumentFn = (journal: JournalWithDocumentDTO, paramMap: ParamMap, documentApiService: DocumentApiService) => Promise<DocumentWithIdDTO>;

export const ADD_DOCUMENT_SERVICE_ADD_DOCUMENT_FN = new InjectionToken<AddDocumentServiceAddDocumentFn>('ADD_DOCUMENT_SERVICE_ADD_DOCUMENT_FN');

@Injectable()
export class AddDocumentService {

  public selectedDocumentType: MORTGAGE_DOCUMENT_TYPE | PROTECTION_DOCUMENT_TYPE;

  constructor(
    private documentApiService: DocumentApiService,
    @Inject(ADD_DOCUMENT_SERVICE_ADD_DOCUMENT_FN) private addDocumentServiceAddDocumentFn: AddDocumentServiceAddDocumentFn
  ) {
  }

  public addDocument(journal: JournalWithDocumentDTO, paramMap: ParamMap): Promise<DocumentWithIdDTO> {
    return this.addDocumentServiceAddDocumentFn(journal, paramMap, this.documentApiService);
  }
}
