import { Directive, ViewContainerRef, TemplateRef, Input, EmbeddedViewRef } from "@angular/core";
import { UserService } from "../../services/user.service";
import { UserPermission } from "../../models";

@Directive({
  selector: '[ifPermission]'
})
export class IfPermissionDirective {
  private permission: UserPermission | UserPermission[];
  private viewRef: EmbeddedViewRef<any>;
  private elseViewRef: EmbeddedViewRef<any>;
  private elseTemplateRef: TemplateRef<any>;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private userService: UserService
  ) { }

  @Input()
  public set ifPermission(permission: UserPermission | UserPermission[]) {
    this.permission = permission;
    this.updateView();
  }

  @Input()
  public set ifPermissionElse(templateRef: TemplateRef<any>) {
    this.elseTemplateRef = templateRef;
    this.elseViewRef = undefined;
    this.updateView();
  }

  private updateView() {
    if (this.userService.hasPermission(this.permission)) {
      if (!this.viewRef) {
        this.viewContainer.clear();
        this.elseViewRef = undefined;
        this.viewRef = this.viewContainer.createEmbeddedView(this.templateRef);
      }
    } else {
      if (!this.elseViewRef) {
        this.viewContainer.clear();
        this.viewRef = undefined;
        this.elseTemplateRef && this.viewContainer.createEmbeddedView(this.elseTemplateRef);
      }
    }
  }
}
