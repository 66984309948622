import { Injectable } from '@angular/core';
import { Attachment } from '../models/attachment';
import { DOCUMENT_REQUEST_STATUS } from '../utils/variables.data';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { JournalWithDocumentDTO } from '../models/journal';
import {DocumentListDTO, DocumentRequestDTO, DocumentTypeDTO, DocumentWithIdDTO} from '../models/document';
import { Page } from '../models/common';
import { Observable } from 'rxjs';
import { ProtectionCaseTableParams } from '../models/protection';
import { MortgageTableParams } from '../models/mortgage-dashboard';
import { Environment } from './environment.service';

@Injectable()
export class DocumentApiService {

  private API_NAME = 'document';

  constructor(private apiService: ApiService, private environment: Environment) { }

  // Mortgage specific

  public addMortgageDocument(mortgageId: string, journalWithDocumentDTO: JournalWithDocumentDTO): Promise<DocumentWithIdDTO> {
    return this.apiService.post(this.API_NAME, 'addMortgageDocument?mortgageId=' + mortgageId, journalWithDocumentDTO).toPromise();
  }

  public listMortgageDocuments(params: MortgageTableParams): Observable<Page<DocumentListDTO>> {
    return this.apiService.get(this.API_NAME, 'listMortgageDocuments', { params }).pipe(map((page: Page<DocumentListDTO>) => {
      this.setupDocumentStatus(page);
      return page;
    }));
  }

  // Protection specific

  public addProtectionCaseDocument(protectionCaseId: string, journalWithDocumentDTO: JournalWithDocumentDTO): Promise<DocumentWithIdDTO> {
    return this.apiService.post(this.API_NAME, 'addProtectionCaseDocument?protectionCaseId=' + protectionCaseId, journalWithDocumentDTO).toPromise();
  }

  public listProtectionCaseDocuments(params: ProtectionCaseTableParams): Observable<Page<DocumentListDTO>>  {
    return this.apiService.get(this.API_NAME, 'listProtectionCaseDocuments', { params }).pipe(map(result => {
      this.setupDocumentStatus(result);
      return result;
    }));
  }

  // Common APIs

  public editDocument(documentId: string, documentTypeDTO: DocumentTypeDTO): Promise<DocumentWithIdDTO> {
    return this.apiService.post(this.API_NAME, 'editDocument?documentId=' + documentId, documentTypeDTO).toPromise();
  }

  public viewDocument(documentId: string): Promise<DocumentWithIdDTO> {
    return this.apiService.get(this.API_NAME, 'viewDocument?documentId=' + documentId).toPromise();
  }

  public deleteDocument(documentId: string): Promise<void> {
    return this.apiService.get(this.API_NAME, 'deleteDocument?documentId=' + documentId).toPromise();
  }

  public requestMortgageDocument(documentRequestDTO: DocumentRequestDTO, mortgageId: string): Promise<DocumentWithIdDTO> {
    return this.apiService.post(this.API_NAME, 'requestMortgageDocument?mortgageId=' + mortgageId, documentRequestDTO).toPromise();
  }

  public requestProtectionCaseDocument(documentRequestDTO: DocumentRequestDTO, protectionCaseId: string): Promise<DocumentWithIdDTO> {
    return this.apiService.post(this.API_NAME, 'requestProtectionCaseDocument?protectionCaseId=' + protectionCaseId, documentRequestDTO).toPromise();
  }

  public acceptRequestedDocument(documentId: string, attachment: Attachment): Promise<DocumentWithIdDTO> {
    return this.apiService.post(this.API_NAME, 'acceptRequestedDocument?documentId=' + documentId, attachment).toPromise();
  }

  public cancelDocumentRequest(documentId: string): Promise<void> {
    return this.apiService.post(this.API_NAME, 'cancelDocumentRequest?documentId=' + documentId).toPromise();
  }

  public triggerDownload(document: Attachment, download = true) {
    const url = `${this.environment.apiUrl}servlet/gcs/${document.folder}/${document.filename}${download ? '?download=true' : ''}`;

    window.open(url);
  }

  private setupDocumentStatus(result: Page<DocumentListDTO>) {
    if (Array.isArray(result.content)) {
      for (const document of result.content) {
        document.feo_status = this.getDocumentRequestStatus(document);
      }
    }
  }

  private getDocumentRequestStatus(document: DocumentListDTO): DOCUMENT_REQUEST_STATUS {
    if (document.requested && document.attachment) {
      return "PROVIDED";
    }

    if (document.requested) {
      return "REQUESTED";
    }

    if (document.sendToClient) {
      return "SENT_TO_CLIENT"
    }

    return "NOT_SENT_TO_CLIENT";
  }
}
