import { Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { TextInputComponent } from '../../../../../../projects/client/src/app/shared/components/text-input/text-input.component';
import { fadeAnimation, sidepaneMove } from '../../../shared/animations/sidepane-animation';
import { SelectRequestDocumentTypeService } from './select-request-document-type.service';

@Component({
  selector: 'hf-select-request-document-type',
  templateUrl: './select-request-document-type.component.html',
  styleUrls: [
    './select-request-document-type.component.scss',
    '../../../styles/sidepanes.partial.scss'
  ],
  animations: [sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove]': 'true' }
})
export class SelectRequestDocumentTypeComponent implements OnDestroy {
  public form: FormGroup = new FormGroup({
    type: new FormControl(),
    otherTypeLabel: new FormControl()
  });

  @ViewChild("otherTypeComponent")
  public otherTypeComponent: TextInputComponent;

  public items: string[];
  public get map() { return this.selectRequestDocumentTypeService.map }
  public get isOtherSelected() { return this.form.get("type").value?.some(v => v === "OTHER") }

  private destroy$ = new Subject();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    @Inject(SelectRequestDocumentTypeService) private selectRequestDocumentTypeService: SelectRequestDocumentTypeService
  ) {
    this.items = [...this.map.keys()];

    this.form.valueChanges.subscribe(() => setTimeout(() => {
      if (this.isOtherSelected && this.otherTypeComponent && this.form.get("type").value?.length === 1) {
        this.otherTypeComponent.setFocus();
      }
    }));

    if (this.selectRequestDocumentTypeService.initial) {
      const { initial } = this.selectRequestDocumentTypeService;

      this.form.get("type").setValue(initial.map(dt => dt.type));
      initial.forEach(t => t.otherTypeLabel && this.form.get("otherTypeLabel").setValue(t.otherTypeLabel));
      this.selectRequestDocumentTypeService.initial = null;
    }
  }

  public onSubmit() {
    this.selectRequestDocumentTypeService.selected$.next(this.finishValue());

    this.onBack();
  }

  public onBack() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }

  private finishValue() {
    const value: string[] = this.form.get("type").value || [];
    const otherTypeLabel: string = this.form.get("otherTypeLabel").value;

    return value.map(type => ({
      type: type as any,
      otherTypeLabel: type === "OTHER" ? otherTypeLabel : undefined
    }));
  }
}
