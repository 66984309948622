import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DivisionSelectorDTO } from '../../../models/division-selector.dto';
import { fadeAnimation, sidepaneMove } from '../../../shared/animations/sidepane-animation';
import { SelectDivisionService } from './select-division.service';

@Component({
  selector: 'hf-select-division',
  templateUrl: './select-division.component.html',
  styleUrls: ['./select-division.component.scss',
    '../../../styles/sidepanes.partial.scss'],
  animations: [sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove]': 'true' }
})
export class SelectDivisionComponent {
  public form: FormGroup = new FormGroup({
    division: new FormControl()
  });

  public divisions: DivisionSelectorDTO[];
  public get introducerType() { return this.selectDivisionService.introducerType }

  public isLoading: boolean = true;

  public filterFn = (division: DivisionSelectorDTO, term: string) => {
    const t = term.toLowerCase();

    return division.name?.toLowerCase().includes(t)
      || division.details?.toLowerCase().includes(t)
      || division.region?.toLowerCase().includes(t);
  }

  public compareFn = (division1: DivisionSelectorDTO, division2: DivisionSelectorDTO): boolean =>
    !!(division1 && division2 && division1.id === division2.id);

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private selectDivisionService: SelectDivisionService,
  ) {
    this.form.get("division").valueChanges.subscribe(division => {
      this.selectDivisionService.selectDivision(division);
      this.onBack();
    })

    this.initialize();
  }

  public onBack() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

  private async initialize() {
    this.isLoading = true;

    this.divisions = await this.selectDivisionService.getDivisions();

    this.isLoading = false;
  }
}
