import { Component, Input } from '@angular/core';

@Component({
  selector: 'hfc-aml-result-small',
  templateUrl: './aml-result-small.component.html',
  styleUrls: ['./aml-result-small.component.scss']
})
export class AmlResultSmallComponent {
  @Input()
  public match: "YES" | "NO" | "NA";
}
