<div class="flex-header table-page-header single-button-header">
  <hf-nav-button></hf-nav-button>
  <div class="flex-title">
    <h1>Tasks</h1>
  </div>
</div>

<hf-tab-group [activeKey]="type" (activeKeyChange)="onActiveKeyChanged($event)">
  <hf-tab-panel key="MY" label="My Tasks"></hf-tab-panel>
  <hf-tab-panel key="DELEGATED" label="Delegated Tasks"></hf-tab-panel>
  <hf-tab-panel key="TEAM" label="Team Tasks"></hf-tab-panel>
</hf-tab-group>

<div class="table-page-container">
  <div class="searchBar">
    <div class="search-box-line">
      <div class="search-bar-wrap">
        <hf-custom-text-field type="text"
                              [searchMode]="true"
                              placeholder="Search tasks …"
                              fieldName="searchTasks"
                              icon="search"
                              preventValidation="true">
        </hf-custom-text-field>
      </div>
      <hf-custom-button type="button"
                        class="button-plain-icon for-phone-show"
                        icon="more_horiz"
                        iconPos="center"
                        (click)="dashboardHeaderButtons.classList.add('dashboard-headers-buttons-visible')">
      </hf-custom-button>
    </div>

    <div *ngIf="type === 'TEAM'" class="filters">
      <hf-selector-link placeholder="Select Teams"
        [value]="teamsLabel"
        (click)="onSelectTeamsClicked()">
      </hf-selector-link>

      <hf-selector-link placeholder="Select Assignees"
        [value]="assigneesLabel"
        (click)="onSelectAssigneesClicked()">
      </hf-selector-link>
    </div>

    <hf-checkbox label="View Completed" [(ngModel)]="viewCompleted" (ngModelChange)="onViewCompletedChanged()">
    </hf-checkbox>
  </div>

  <div class="utils-area dashboard-header-buttons"
       #dashboardHeaderButtons
       (click)="dashboardHeaderButtons.classList.remove('dashboard-headers-buttons-visible')">
    <div class="pagination-area">
      <hf-item-per-page-selector>
      </hf-item-per-page-selector>
    </div>
  </div>

  <div class="everglades-table" hfTable>
    <div class="everglades-table-headings">
      <div sortBy="mortgageId" class="entity"><span>Type</span></div>
      <div sortBy="createdDate"><span>Creation Date</span></div>
      <div *ngIf="type === 'MY'" sortBy="createdByFirstName;createdByLastName"><span>Creator</span></div>
      <div *ngIf="isAssigneeColumnVisible" sortBy="assigneeFirstName;assigneeLastName"><span>Assignee</span></div>
      <div *ngIf="type === 'TEAM'" sortBy="assigneeUserType"><span>Team</span></div>
      <div sortBy="mortgageClientFirstName;protectionClientFirstName;mortgageClientLastName;protectionClientLastName"><span>Client</span></div>
      <div style="flex: 4"><span>Summary</span></div>
      <div *ngIf="!viewCompleted" sortBy="dueDate" [isDefault]="true"><span>Deadline</span></div>
      <div *ngIf="viewCompleted" sortBy="completed" [isDefault]="true"><span>Done Date</span></div>
    </div>

    <a *ngFor="let row of data" (click)="onTaskRowClicked(row)">
      <div class="table-row" [class.completed]="viewCompleted">
        <div data-mobileTitle="Type" class="entity" [ngClass]="row.entity">
          <i *ngIf="row.entity === 'MORTGAGE'" class="material-icons">home</i>
          <i *ngIf="row.entity === 'PROTECTION'" class="material-icons">security</i>
        </div>
        <div data-mobileTitle="Creation Date">{{row.createdDate|date:'d MMM y'}}</div>
        <div *ngIf="type === 'MY'" data-mobileTitle="Creator">{{row.createdBy?.fullName}}</div>
        <div *ngIf="isAssigneeColumnVisible" data-mobileTitle="Assignee">{{row.assignee?.fullName}}</div>
        <div *ngIf="type === 'TEAM'" data-mobileTitle="Team">{{ USER_TYPE_NAMES.get(row.assigneeTeam) }}</div>
        <div data-mobileTitle="Client">{{row.client?.fullName}}</div>
        <div data-mobileTitle="Summary" style="flex: 4">{{row.summary}}</div>
        <div *ngIf="!viewCompleted" data-mobileTitle="Deadline" [class.red]="row.due">{{row.dueDate|date:'d MMM y'}}</div>
        <div *ngIf="viewCompleted" data-mobileTitle="Done Date">{{row.completed|date:'d MMM y'}}</div>
      </div>
    </a>
  </div>

  <div class="pagination-row">
    <hf-pagination-summary-box></hf-pagination-summary-box>
    <hf-paginator></hf-paginator>
  </div>
</div>

<div class="sidepanes-anchor">
  <router-outlet></router-outlet>
</div>
