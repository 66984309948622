import {
  animate, animateChild, query,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export interface DynamicAnimation {
  value: string;
  params?: object;
}

export const sidepaneMove = trigger('sidepaneMove', [
  // state('*', style({'transform': 'translateX(0)'})),
  // state('void', style({'transform': 'translateX(100%)'})),
  state('*', style({'margin-right':'0'})),
  state('void', style({'margin-right':'-788px'})),
  transition('* => void', [query('@fadeAnimation', [animateChild()]), animate('450ms ease')]),
  transition('void => *',[query('@fadeAnimation', [animateChild()]), animate('450ms ease')])
]);

export const sidepaneMoveNew = trigger('sidepaneMoveNew', [
  state('*', style({'transform': 'translateX(0)'})),
  state('void', style({'transform': 'translateX(100%)'})),
  // state('*', style({'margin-right':'0'})),
  // state('void', style({'margin-right':'-788px'})),
  transition('* => void', [query('@fadeAnimation', [animateChild()]), animate('450ms ease')]),
  transition('void => *',[query('@fadeAnimation', [animateChild()]), animate('450ms ease')])
]);

// TODO: delete and use just the above versionn with transform
export const sidepaneMove2Cols = trigger('sidepaneMove2Cols', [
  state('*', style({'margin-right':'0'})),
  state('void', style({'margin-right':'-788px'})),
  transition('* => void', [query('@fadeAnimation', [animateChild()]), animate('450ms ease')]),
  transition('void => *',[query('@fadeAnimation', [animateChild()]), animate('450ms ease')])
]);

export const fadeAnimation = trigger('fadeAnimation', [
  state('*', style({'opacity':1})),
  state('void', style({'opacity':0})),
  transition('* => void', animate('70ms')),
  transition('void => *', animate('70ms'))
]);

export const ngIfAnimation = trigger('ngIfAnimation', [
    transition(':enter, :leave', [
      query('@*', animateChild(), { optional: true })
    ])
  ])
