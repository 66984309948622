<div class="container" *ngIf="mode == 'source'">
  <div class="top">
    <div class="heading">
      Source the mortgage with
      <hfc-button type="tertiary" postfixIcon="launch" [isLoading]="isSourceButtonLoading" (click)="onSourceClicked()">Twenty7tec</hfc-button>
    </div>
  </div>

  <div class="content">
    <hf-blue-link-button (click)="onManualClicked()" >Add details manually</hf-blue-link-button>
  </div>
</div>

<div class="container" *ngIf="mode == 'select'">
  <div class="top">
    <div class="heading">
      After sourcing from Twenty7tec, select a mortgage below
    </div>
  </div>

  <div class="content">
    <div class="ev-sidepane-form-column-full-width">
      <div class="ev-form-field info-box-container">
        <label>Select Mortgage</label>
        <hfc-info-box-container class="info-box-container-full-width">
          <hfc-info-box *ngIf="!mortgage" (click)="onSelectClicked()">
            <ng-template #detailsTemplate>
              <span class="text-16-medium-grey72">
                Select Mortgage
              </span>
            </ng-template>
          </hfc-info-box>

          <hfc-info-box *ngIf="mortgage" (click)="onSelectClicked()">
            <ng-template #detailsTemplate>
              {{ mortgage.productOverview }}
              <div>
                {{ mortgage.documentId }}
              </div>
              <!-- <span class="text-16-medium-grey72"> -->
              <!--   Select Mortgage -->
              <!-- </span> -->
            </ng-template>
          </hfc-info-box>
        </hfc-info-box-container>
      </div>
    </div>
  </div>

  <div class="bottom-links">
    <hf-blue-link-button (click)="onManualClicked()">Add details manually</hf-blue-link-button>
    <hf-blue-link-button icon="launch" [isLoading]="isSourceButtonLoading" (click)="onSourceClicked()">Source again with Twenty7tec</hf-blue-link-button>
  </div>
</div>

<div class="container" *ngIf="mode == 'manual'">
  <div class="top">
    <div class="heading">
      You have chosen to add mortgage details manually
    </div>
  </div>

  <div class="content">
    <hf-blue-link-button icon="launch" [isLoading]="isSourceButtonLoading" (click)="onSourceClicked()">Source again with Twenty7tec</hf-blue-link-button>
  </div>
</div>
