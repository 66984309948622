import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { getToday } from '../../../../../../projects/client/src/app/shared/functions/get-today';
import { Confirmation, DialogService } from '../../../../../../projects/client/src/app/shared/services/dialog.service';
import { HelpToBuy, MortgageApiService } from '../../../services/mortgage-api.service';
import { ToasterService } from '../../../services/toaster.service';
import { JournalTabService } from '../../../shared-layout/tabs/journal/journal-tab.service';
import { fadeAnimation, sidepaneMove } from '../../../shared/animations/sidepane-animation';
import { HfValidators } from '../../../utils/form.validators';
import { HELP_TO_BUY_STATUS_NAMES } from '../../../utils/variables.data';
import { MortgagesService } from '../mortgages.service';
import { SelectDateAndTimeService } from '../select-date-and-time/select-date-and-time.service';
import { SelectHelpToBuyRegionService } from '../select-help-to-buy-region/select-help-to-buy-region.service';
import { SelectHelpToBuyStatusService } from '../select-help-to-buy-status/select-help-to-buy-status.service';

interface ControlDefinition {
  name: string;
  enabled?: (formValue: any) => boolean;
}

@Component({
  selector: 'hf-update-help-to-buy',
  templateUrl: './update-help-to-buy.component.html',
  styleUrls: [
    './update-help-to-buy.component.scss',
    '../../../styles/sidepanes.partial.scss'
  ],
  animations: [sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove]': 'true' },
  providers: [
    SelectHelpToBuyStatusService,
    SelectHelpToBuyRegionService
  ]
})
export class UpdateHelpToBuyComponent implements OnDestroy {
  public HELP_TO_BUY_STATUS_NAMES = HELP_TO_BUY_STATUS_NAMES;
  public now: Date = getToday();
  public form: FormGroup = this.fb.group({
    status: [undefined, Validators.required],
    regionId: [undefined, Validators.required],
    regionName: [undefined],
    submittedDate: [undefined, [Validators.required, HfValidators.maxDate(this.now)]],
    amendmentDate: [undefined, HfValidators.maxDate(this.now)],
    dateOfIssue: [undefined, HfValidators.maxDate(this.now)],
    cancelReason: [undefined, Validators.required],
    journal: this.fb.group({
      internalNotes: [undefined]
    })
  });
  public get status() { return this.form.get("status").value }
  public get isClearButtonVisible(): boolean { return this.mortgagesService.data && !!this.mortgagesService.data.helpToBuy }

  private get mortgageId() { return this.route.snapshot.paramMap.get("id") }
  private destroy$ = new Subject();

  private definition: ControlDefinition[] = [
    { name: "regionId", enabled: value => value.status === "PIF_SUBMITTED" },
    { name: "submittedDate", enabled: value => value.status === "PIF_SUBMITTED" },
    { name: "amendmentDate", enabled: value => value.status === "AMENDMENT_SUBMITTED" },
    { name: "dateOfIssue", enabled: value => value.status === "ATP_ISSUED" },
    { name: "cancelReason", enabled: value => value.status === "CANCEL" }
  ];

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private selectHelpToBuyStatusService: SelectHelpToBuyStatusService,
    private selectHelpToBuyRegionService: SelectHelpToBuyRegionService,
    private selectDateAndTimeService: SelectDateAndTimeService,
    private mortgageApiService: MortgageApiService,
    private mortgagesService: MortgagesService,
    private toasterService: ToasterService,
    private dialogService: DialogService,
    private journalTabService: JournalTabService
  ) {
    this.selectHelpToBuyStatusService.status$.pipe(takeUntil(this.destroy$))
      .subscribe(status => this.form.patchValue({ status }));

    this.selectHelpToBuyRegionService.region$.pipe(takeUntil(this.destroy$))
      .subscribe(region => region && this.form.patchValue({ regionId: region.id, regionName: region.name }));

    this.selectDateAndTimeService.dateSelected$.pipe(takeUntil(this.destroy$))
      .subscribe(date => {
        const control = this.form.get(date.name);
        control && control.setValue(date.date);
      });

    this.form.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(formValue => {
        this.definition.filter(def => def.enabled).forEach(def => {
          const enabled = def.enabled(formValue);
          const control = this.form.get(def.name);

          if (control.enabled !== enabled) {
            enabled ? control.enable({ emitEvent: false }) : control.disable({ emitEvent: false });
          }
        });
      });
  }

  public onUpdate() {
    this.submit(false);
  }

  public async onClear() {
    const confirmation: Confirmation = {
      title: "Clear Help to Buy",
      message: "Are you sure you want to clear Help to Buy Status?",
      acceptLabel: "Clear",
    }

    if (!await this.dialogService.confirm(confirmation)) {
      return;
    }

    this.submit(true);
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }

  private async submit(clear: boolean) {
    try {
      if (clear) {
        await this.mortgageApiService.clearHelpToBuyStatus(this.mortgageId);
      } else {
        const data: HelpToBuy = this.form.value;

        if (data.amendmentDate) {
          data.amendmentDate = this.selectDateAndTimeService.toLocalDateISO(data.amendmentDate as any);
        }
        if (data.dateOfIssue) {
          data.dateOfIssue = this.selectDateAndTimeService.toLocalDateISO(data.dateOfIssue as any);
        }
        if (data.submittedDate) {
          data.submittedDate = this.selectDateAndTimeService.toLocalDateISO(data.submittedDate as any);
        }

        delete (data as any).regionName;

        await this.mortgageApiService.setHelpToBuyStatus(this.mortgageId, this.form.value);
      }
      this.toasterService.callToaster({ severity: 'info', summary: 'Info', detail: 'Help to Buy Status Updated' });
      this.mortgagesService.reloadMortgageDashboard.emit();
      this.journalTabService.reloadJournalTab.emit();

      this.onBack();
    } catch (error) {
      this.toasterService.callToaster({ severity: 'error', summary: 'Error', detail: `Error updating Help to Buy Status: ${error.error.error.message}` });
    }
  }

  public onBack() {
    this.router.navigate([".."], { relativeTo: this.route });
  }
}
