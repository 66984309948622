import { Component, Input } from '@angular/core';

@Component({
  selector: 'hf-missed-payments-box',
  templateUrl: './missed-payments-box.component.html',
  styleUrls: ['./missed-payments-box.component.scss']
})
export class MissedPaymentsBoxComponent {
  @Input()
  public heading: string;
}
