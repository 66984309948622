import { Inject, Injectable, InjectionToken } from "@angular/core";
import { ParamMap } from "@angular/router";
import * as moment from 'moment';
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { toLocalDateISO } from "../../../../../projects/client/src/app/shared/functions/to-local-date-iso";
import { UserSimple } from "../../models";
import { TASK_LIST_TYPE } from "../../models/task";
import { ApiService } from "../../services/api.service";
import { TableParams } from "../../shared/table-utils/table-settings.service";
import { USER_TYPE } from "../../utils/variables.data";

export type TaskApiServiceCreateTaskFn = (task: Task, paramMap: ParamMap, taskApiService: TaskApiService) => Promise<void>;

export const TASK_API_SERVICE_CREATE_TASK_FN = new InjectionToken<TaskApiServiceCreateTaskFn>('TASK_API_SERVICE_CREATE_TASK_FN');

export interface Task {
  id: string;
  createdDate: Date;
  createdBy: UserSimple;
  assignee: UserSimple;
  assigneeTeam: USER_TYPE;
  client: UserSimple;
  mortgageId?: string;
  handoverId?: string;
  protectionCaseId?: string;
  summary: string;
  description: string;
  dueDate: Date;
  completed: Date;
  entity: "MORTGAGE" | "PROTECTION";
  application?: any;
}

export interface TaskListFilterDTO {
  filter?: string;
  viewCompleted: boolean;
  teams?: USER_TYPE[];
  assigneeIds?: string[];
}

export interface TaskListParamsDTO extends TableParams {
  type: TASK_LIST_TYPE;
  mortgageId?: string;
  remortgageId?: string;
  protectionCaseId?: string;
}

@Injectable()
export class TaskApiService {
  private readonly API_NAME: string = 'task';

  constructor(
    private readonly apiService: ApiService,
    @Inject(TASK_API_SERVICE_CREATE_TASK_FN) private taskApiServiceCreateTaskFn: TaskApiServiceCreateTaskFn
  ) { }

  public listTasks(params: TaskListParamsDTO, filter: TaskListFilterDTO): Observable<any> {
    return this.apiService.post(this.API_NAME, "listTasks", filter, { params })
        .pipe(map(this.mapTaskList()));
  }

  public listMortgageTasks(params: TaskListParamsDTO, filter: TaskListFilterDTO): Observable<any> {
    return this.apiService.post(this.API_NAME, "listMortgageTasks", filter, { params })
        .pipe(map(this.mapTaskList()));
  }

  private mapTaskList() {
    return result => {
      result.content = result.content.map(task => ({...task, due: moment(task.dueDate).isSameOrBefore(undefined, "day")}));
      return result;
    };
  }

  public createTask(task: Task, paramMap: ParamMap): Promise<void> {
    return this.taskApiServiceCreateTaskFn(task, paramMap, this);
  }

  public createMortgageTask(task: Task, mortgageId: string): Promise<void> {
    const params = { mortgageId };

    if (task.dueDate) {
      task.dueDate = toLocalDateISO(task.dueDate) as any;
    }

    return this.apiService
      .put(this.API_NAME, "createMortgageTask", task, { params })
      .toPromise();
  }

  public createProtectionTask(task: Task, protectionCaseId: string): Promise<void> {
    const params = { protectionCaseId };

    if (task.dueDate) {
      task.dueDate = toLocalDateISO(task.dueDate) as any;
    }

    return this.apiService
      .put(this.API_NAME, "createProtectionTask", task, { params })
      .toPromise();
  }

  public editTask(task: Task, taskId: string): Promise<void> {
    const params = { taskId };

    if (task.dueDate) {
      task.dueDate = toLocalDateISO(task.dueDate) as any;
    }

    return this.apiService
      .post(this.API_NAME, "editTask", task, { params })
      .toPromise();
  }

  public markTaskAsDone(taskNote: { note: string }, taskId: string): Promise<void> {
    const params = { taskId };

    return this.apiService
      .post(this.API_NAME, "markTaskAsDone", taskNote, { params })
      .toPromise();
  }

  public deleteTask(taskNote: { note: string }, taskId: string): Promise<void> {
    const params = { taskId };

    return this.apiService
      .post(this.API_NAME, "deleteTask", taskNote, { params })
      .toPromise();
  }

  public reassignTask(taskReassign: { newAssignee: { id: string }; note: string }, taskId: string): Promise<void> {
    const params = { taskId };

    return this.apiService
      .post(this.API_NAME, "reassignTask", taskReassign, { params })
      .toPromise();
  }

  public async viewTask(taskId: string): Promise<Task> {
    const params = { taskId };

    const data = await this.apiService
      .get(this.API_NAME, "viewTask", { params })
      .toPromise();

    if (data.dueDate) {
      data.dueDate = moment(data.dueDate).toDate();
      data.due = moment(data.dueDate).isSameOrBefore(undefined, "day")
    }

    return data;
  }
}
