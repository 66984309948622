<section class="section">
  <div class="table-page-container">
    <div class="searchBar">
      <div class="search-box-line">
        <div class="search-bar-wrap">
          <hf-custom-text-field type="text"
                                [searchMode]="true"
                                placeholder="Search tasks …"
                                fieldName="input12"
                                icon="search"
                                preventValidation="true">
          </hf-custom-text-field>
        </div>

        <hf-custom-button type="button"
                          class="button-plain-icon for-phone-show"
                          icon="more_horiz"
                          iconPos="center"
                          (click)="dashboardHeaderButtons.classList.add('dashboard-headers-buttons-visible')">
        </hf-custom-button>
      </div>

      <hf-checkbox label="View Completed" [(ngModel)]="viewCompleted" (ngModelChange)="onViewCompletedChanged()">
      </hf-checkbox>
    </div>

    <div class="utils-area dashboard-header-buttons"
         #dashboardHeaderButtons
         (click)="dashboardHeaderButtons.classList.remove('dashboard-headers-buttons-visible')">
      <div class="pagination-area">
        <hf-item-per-page-selector>
        </hf-item-per-page-selector>
      </div>
    </div>

    <div class="everglades-table" hfTable>
      <div class="everglades-table-headings">
        <div sortBy="createdDate" class="width-120"><span>Creation Date</span></div>
        <div sortBy="assigneeFirstName;assigneeLastName" class="width-160"><span>Assignee</span></div>
        <div *ngIf="showApplication" class="width-100"><span>Application</span></div>
        <div><span>Summary</span></div>
        <div *ngIf="!viewCompleted" sortBy="dueDate" [isDefault]="true" class="width-120"><span>Due Date</span></div>
        <div *ngIf="viewCompleted" sortBy="completed" [isDefault]="true" class="width-120"><span>Done Date</span></div>
      </div>

      <a *ngFor="let row of data" (click)="onTaskRowClicked(row)">
        <div class="table-row" [class.completed]="viewCompleted">
          <div data-mobileTitle="Creation Date" class="width-120">{{row.createdDate|date:'d MMM y'}}</div>
          <div data-mobileTitle="Assignee" class="width-160">{{row.assignee.fullName}}</div>
          <div *ngIf="showApplication" data-mobileTitle="Application" class="width-100">
            <hf-attachment-image *ngIf="row.application" [attachment]="row.application.lenderLogo" [alt]="row.application.lender" [hfTooltip]="row.application.lenderReference" class="provider-logo"></hf-attachment-image>
          </div>
          <div data-mobileTitle="Summary">{{row.summary}}</div>
          <div *ngIf="!viewCompleted" data-mobileTitle="Due Date" [class.red]="row.due" class="width-120">{{row.dueDate|date:'d MMM y'}}</div>
          <div *ngIf="viewCompleted" data-mobileTitle="Done Date" class="width-120">{{row.completed|date:'d MMM y'}}</div>
        </div>
      </a>
    </div>

    <div class="pagination-row">
      <hf-pagination-summary-box></hf-pagination-summary-box>
      <hf-paginator></hf-paginator>
    </div>
  </div>
</section>
