import { Component, Input, EventEmitter, Output } from '@angular/core';
import { Attachment } from '../../../models/attachment';

@Component({
  selector: 'hf-file-input-preview',
  templateUrl: './file-input-preview.component.html',
  styleUrls: ['./file-input-preview.component.scss']
})
export class FileInputPreviewComponent {
  @Input()
  public attachment: Attachment;

  @Output()
  public removeClick = new EventEmitter();

  public getThumbnailUrl() {
    if (!this.attachment) {
      return undefined;
    }

    const parts = [
      this.attachment.folder,
      this.attachment.thumbnail
    ];

    return parts.join("/");
  }
}
