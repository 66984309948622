import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { User, UserPermission } from '../models/user';
import { ChangePasswordComponent } from "../modules/dashboard/change-password/change-password.component";
import { heronUserTypes, USER_TYPE } from '../utils/variables.data';
import { TemplateService } from "./template.service";
import { WordingService } from './wording.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private userSubject = new ReplaySubject<User>(1);
  public user$: Observable<User> = this.userSubject.asObservable();

  private user: User;

  constructor(
    private templateService: TemplateService,
    private wordingService: WordingService,
  ) { }
  
  public isAutheticated() {
    const isAutheticated = !!this.user && this.user.userType !== "CLIENT" && !!this.user.id && !this.user.twoFactorNeeded;

    return isAutheticated;
  }

  public isAutheticatedNeedsVerify() {
    const isAutheticated = !!this.user && this.user.userType !== "CLIENT" && !!this.user.twoFactorNeeded;

    return isAutheticated;
  }

  public setUser(user) {
    this.user = user;

    if (this.user) {
      this.user.items = [
        { label: 'Account...', icon: '', routerLink: ['', { outlets: { settings: ['account'] } }] },
        { label: 'Change Password...', icon: '', routerLink: ['', { outlets: { settings: ['password'] } }] },
        { label: 'Logout', icon: '', routerLink: ['/login'], queryParams: { logout: 'out' } }
      ];
    }

    this.wordingService.introducerDictKey = this.user?.introducer?.type;
    this.userSubject.next(this.user);

    // console.log("YOUR PERMISISONS: ", this.user.permissions.sort());
  }

  public openPasswordSidepane() {
    this.templateService.closeSidebar();
    this.templateService.createComponent(ChangePasswordComponent);
  }

  public getUser() {
    return this.user;
  }

  public isAdmin(): boolean {
    return this.user && this.user.userType === "ADMINISTRATOR";
  }

  public isAdminOrManager(): boolean {
    const matches: USER_TYPE[] = ["ADMINISTRATOR", "LINE_MANAGER"];

    return this.user && matches.includes(this.user.userType);
  }

  public isAdvisor(): boolean {
    const advisors: USER_TYPE[] = ["ADVISOR_MORTGAGES", "ADVISOR_PROTECTION", "ADVISOR_BOTH"];

    return this.user && advisors.includes(this.user.userType);
  }

  public isCaseProgression(): boolean {
    return this.user && this.user.userType === "CASE_PROGRESSION";
  }

  public isHeronUser(): boolean {
    return heronUserTypes.includes(this.user?.userType);
  }

  public isIntroducer(): boolean {
    const introducerPerm: UserPermission[] = ['MORTGAGE_LIST_INTRODUCER', 'MORTGAGE_LIST_REGION', 'MORTGAGE_LIST_DIVISION'];

    return this.user && this.hasPermission(introducerPerm);
  }

  /**
   * Determines if logged user has specific permission.
   * Returns `true` if user has at least one permission from required.
   *
   * @param required Array of required user permissions.
   */
  public hasPermission(required: UserPermission | UserPermission[]): boolean {
    if (!required) {
      return true;
    }

    const reqs = Array.isArray(required)
      ? required
      : [required];

    return this.user.permissions.some(p => reqs.includes(p));
  }

  public get introducerId(): string {
    return this.user.introducer && this.user.introducer.id;
  }

  /**
  * Returns divisionId if logged user is bound just to the one division (SALES_ADVISOR with division admin right)
  */
  public get divisionId(): string {
    return this.user.division && this.user.division.id;
  }

  public closeSession() {
    this.user = null;
  }
}
