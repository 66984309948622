<form [formGroup]="form" class="ev-sidepane-form">
  <div class="ev-sidepane-form-section">
    <!-- <div class="ev-sidepane-form-heading">General Notes</div> -->

    <div class="ev-sidepane-form-content">
      <div class="ev-sidepane-form-column">
        <div [hfFormField]="control('productTerm')" class="ev-form-field">
          <label>Product Term</label>
          <hf-selector-link placeholder="Select Term"
                            [value]="MortgageProductTermName.get(value.productTerm)"
                            (click)="onSelectProductTermClicked()">
          </hf-selector-link>
        </div>

        <div [hfFormField]="control('productTermReason')" class="ev-form-field">
          <label>Reason for product term preference</label>
          <hf-textarea [group]="form" [smaller]="true" [inputFormControlName]="'productTermReason'" fieldName="productTermReason"></hf-textarea>
        </div>

        <div [hfFormField]="control('fixedRate')" class="ev-form-field">
          <!-- <label><wbr></label> -->
          <hf-checkbox label="Fixed rate" formControlName="fixedRate"></hf-checkbox>
        </div>

        <div [hfFormField]="control('cappedRate')" class="ev-form-field">
          <!-- <label><wbr></label> -->
          <hf-checkbox label="Capped rate" formControlName="cappedRate"></hf-checkbox>
        </div>

        <div *ngIf="enabled('cappedRateReason')" [hfFormField]="control('cappedRateReason')" class="ev-form-field">
          <label>Reason for capped rate preference</label>
          <hf-textarea [group]="form" [smaller]="true" [inputFormControlName]="'cappedRateReason'" fieldName="cappedRateReason"></hf-textarea>
        </div>

        <div [hfFormField]="control('avoidHigherCharge')" class="ev-form-field">
          <!-- <label><wbr></label> -->
          <hf-checkbox label="Avoid higher lending charge" formControlName="avoidHigherCharge"></hf-checkbox>
        </div>

        <div [hfFormField]="control('addFees')" class="ev-form-field">
          <!-- <label><wbr></label> -->
          <hf-checkbox label="Add fees to loan" formControlName="addFees"></hf-checkbox>
        </div>

        <div [hfFormField]="control('offsetReason')" class="ev-form-field">
          <label>Offset Reason(s)</label>
          <hf-selector-link *ngFor="let reason of value.offsetReason?.length ? value.offsetReason : [null]"
                            placeholder="Select Reason(s)"
                            [value]="MortgageOffsetReasonName.get(reason)"
                            (click)="onSelectOffsetReasonClicked()">
          </hf-selector-link>
        </div>

        <div *ngIf="enabled('maximumArrangmentFee')" [hfFormField]="control('maximumArrangmentFee')" class="ev-form-field">
          <label>Maximum Arrangment Fee</label>
          <hfc-text-input prefix="£" currency="true" formControlName="maximumArrangmentFee"></hfc-text-input>
        </div>
      </div>

      <div class="ev-sidepane-form-column">
        <div [hfFormField]="control('portable')" class="ev-form-field">
          <label><wbr></label>
          <hf-checkbox label="Portable" formControlName="portable"></hf-checkbox>
        </div>

        <div *ngIf="enabled('portableReason')" [hfFormField]="control('portableReason')" class="ev-form-field">
          <label>Reason for portability</label>
          <hf-textarea [group]="form" [smaller]="true" [inputFormControlName]="'portableReason'" fieldName="portableReason"></hf-textarea>
        </div>

        <div [hfFormField]="control('freeLegals')" class="ev-form-field">
          <!-- <label><wbr></label> -->
          <hf-checkbox label="Free legals" formControlName="freeLegals"></hf-checkbox>
        </div>

        <div [hfFormField]="control('cashback')" class="ev-form-field">
          <!-- <label><wbr></label> -->
          <hf-checkbox label="Cashback" formControlName="cashback"></hf-checkbox>
        </div>

        <div [hfFormField]="control('freeValuation')" class="ev-form-field">
          <!-- <label><wbr></label> -->
          <hf-checkbox label="Free valuation" formControlName="freeValuation"></hf-checkbox>
        </div>

        <div [hfFormField]="control('noErc')" class="ev-form-field">
          <!-- <label><wbr></label> -->
          <hf-checkbox label="No ERC" formControlName="noErc"></hf-checkbox>
        </div>

        <div *ngIf="enabled('noErcReason')" [hfFormField]="control('noErcReason')" class="ev-form-field">
          <label>Reason for no ERC</label>
          <hf-textarea [group]="form" [smaller]="true" [inputFormControlName]="'noErcReason'" fieldName="noErcReason"></hf-textarea>
        </div>

        <div [hfFormField]="control('flexibleOverpayments')" class="ev-form-field">
          <!-- <label><wbr></label> -->
          <hf-checkbox label="Flexible overpayments" formControlName="flexibleOverpayments"></hf-checkbox>
        </div>

        <div *ngIf="enabled('flexibleOverpaymentsReason')" [hfFormField]="control('flexibleOverpaymentsReason')" class="ev-form-field">
          <label>Reason for overpayments</label>
          <hf-textarea [group]="form" [smaller]="true" [inputFormControlName]="'flexibleOverpaymentsReason'" fieldName="flexibleOverpaymentsReason"></hf-textarea>
        </div>

        <div [hfFormField]="control('otherPreferences')" class="ev-form-field">
          <label>Any other preferences</label>
          <hf-textarea [group]="form" [smaller]="true" [inputFormControlName]="'otherPreferences'" fieldName="otherPreferences"></hf-textarea>
        </div>
      </div>

      <!-- <div class="ev-sidepane-form-column"> -->
      <!--   <div class="ev-form-field" style="margin-top: 8px;"> -->
      <!--     <hf-checkbox label="The client has existing provisions" formControlName="hasExistingProvisions"></hf-checkbox> -->
      <!--   </div> -->

      <!--   <div *ngIf="isControlEnabled('existingProvisions')" [hfFormField]="control('existingProvisions')" class="ev-form-field"> -->
      <!--     <label>Specify existing provisions</label> -->
      <!--     <hf-textarea [group]="form" [smaller]="true" [inputFormControlName]="'existingProvisions'" fieldName="existingProvisions"></hf-textarea> -->
      <!--   </div> -->
      <!-- </div> -->
    </div>

    <!-- <hfc-debug-form [form]="form"></hfc-debug-form> -->
  </div>

  <div *ngIf="form.get('reasonForChange').enabled" [hfFormField]="control('reasonForChange')" class="ev-sidepane-form-section top-border">
    <div class="ev-sidepane-form-content">
      <div class="ev-sidepane-form-column-full-width">
        <div class="ev-form-field">
          <label>Reason for Change</label>
          <hf-textarea [group]="form" [smaller]="true" [inputFormControlName]="'reasonForChange'" fieldName="reasonForChange"></hf-textarea>
        </div>
      </div>
    </div>
  </div>
</form>
