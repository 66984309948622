<div (click)="onBack()" class="overlay" [@fadeAnimation]></div>

<div class="sidepane-content">
  <div class="flex-space-between-layout template-header">
    <h1>Assign Case Progression</h1>
    <hf-custom-button type="button" class="button-plain-icon" icon="close" iconPos="center" (onClick)="onBack()">
    </hf-custom-button>
  </div>

  <div class="template-main">
    <ng-container *ngIf="isLoading">
      <hf-custom-spinner sidepaneLoading="true"></hf-custom-spinner>
    </ng-container>

    <form *ngIf="!isLoading" [formGroup]="form">
      <ng-container *ngIf="form.get('application').enabled">
        <label>Related Application</label>

        <hf-selector-link 
          placeholder="Select Application"
          [value]="form.value.application?.lender.name"
          (click)="onSelectApplicationClicked()">
        </hf-selector-link>
      </ng-container>

      <!-- Select case progression -->
      <hf-custom-selector formControlName="caseProgression"
                          [items]="items"
                          [compareWith]="compareFn"
                          title="Select Case Progression">
        <ng-template #itemTemplate let-item>
          <div>
            {{item.fullName}}
          </div>
        </ng-template>
      </hf-custom-selector>
    </form>
  </div>

  <div *ngIf="submitMode" class="template-footer">
    <hf-custom-button type="submit"
                      class="button-primary"
                      label="Assign"
                      [disabled]="form.invalid"
                      [callable]="true"
                      (onClick)="onSubmit()">
    </hf-custom-button>

    <hf-custom-button type="button"
                      class="button-secondary"
                      label="Cancel"
                      (onClick)="onBack()">
    </hf-custom-button>
  </div>
</div>

<div class="sidepane-child-anchor">
  <router-outlet></router-outlet>
</div>

