import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ClientSharedModule } from '../../../../../../projects/client/src/app/shared/client-shared.module';
import { IfPermissionModule } from '../../../directives/if-permission/if-permission.module';
import { CustomButtonModule } from '../../../shared/custom-button/custom-button.module';
import { CustomTextFieldModule } from '../../../shared/custom-text-field/custom-text-field.module';
import { EvergladesSharedMorule } from '../../../shared/everglades-shared.module';
import { HfCheckboxModule } from '../../../shared/hf-checkbox-component/hf-checkbox.module';
import { ItemsPerPageSelectorModule } from '../../../shared/item-per-page-selector/items-per-page-selector.module';
import { MortgageExportButtonModule } from '../../../shared/mortgage-export-button/mortgage-export-button.module';
import { PaginationSummaryBoxModule } from '../../../shared/pagination-summary-box/pagination-summary-box.module';
import { PaginatorModule } from '../../../shared/paginator/paginator.module';
import { SharedModule } from '../../../shared/shared.module';
import { MortgagesListComponent } from './mortgages-list.component';
import { QualificationTooltipComponent } from './qualification-tooltip/qualification-tooltip.component';

@NgModule({
  declarations: [
    MortgagesListComponent,
    QualificationTooltipComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ClientSharedModule,
    CustomButtonModule,
    CustomTextFieldModule,
    ItemsPerPageSelectorModule,
    SharedModule,
    EvergladesSharedMorule,
    PaginatorModule,
    PaginationSummaryBoxModule,
    IfPermissionModule,
    MortgageExportButtonModule,
    HfCheckboxModule,
  ],
  exports: [
    MortgagesListComponent
  ],
})
export class MortgagesListModule { }
