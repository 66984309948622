import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, filter, switchMap, takeUntil, tap } from 'rxjs/operators';

import { ClientApiService } from '../../../services/client-api.service';
import { fadeAnimation, sidepaneMove } from "../../../shared/animations/sidepane-animation";
import { PhonePrefixService } from "../../../modules/introducers/select-phone-prefix/phone-prefix.service";
import { SelectOrAddClientService } from './select-or-add-client.service';
import { Client } from '../../../models/client';
import { getUkPhoneValidator } from '../../../../../../projects/client/src/app/shared/functions/uk-phone.validator';

export interface SelectOrAddClientConfig {
  selectOnly: boolean;
}

@Component({
  selector: 'hf-select-or-add-client',
  templateUrl: './select-or-add-client.component.html',
  styleUrls: ['./select-or-add-client.component.scss',
    '../../../styles/sidepanes.partial.scss'],
  animations: [sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove]': 'true' }
})
export class SelectOrAddClientComponent implements OnDestroy {
  public isLoading: boolean = false;
  public clients: Client[];
  public addNewClient: boolean = false;
  public headerLabel: string = 'Select Client';

  public searchBoxFormGroup = this.fb.group({
    clientNameFilter: []
  });

  public clientFormGroup: FormGroup = this.fb.group({
    email: ['', Validators.required],
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    fullName: [''],
    phone: ['', [Validators.required, getUkPhoneValidator()]],
    phonePrefix: ['+44', Validators.required],
    id: ['']
  });

  public get config() { return this.route.snapshot.data as SelectOrAddClientConfig }

  private destroy$ = new Subject();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private clientApiService: ClientApiService,
    private phonePrefixService: PhonePrefixService,
    private selectOrAddClientService: SelectOrAddClientService
  ) {
    this.searchBoxFormGroup.get("clientNameFilter").valueChanges.pipe(
      takeUntil(this.destroy$),
      debounceTime(250),
      filter(term => term && term.length >= 3),
      tap(() => this.isLoading = true),
      switchMap(filter => this.clientApiService.getAllClients(filter))
    ).subscribe(result => {
      this.clients = result.items;
      this.isLoading = false;
    });

    this.phonePrefixService.phonePrefixUpdated.pipe(takeUntil(this.destroy$)).subscribe(phonePref => {
      this.clientFormGroup.get('phonePrefix').setValue(phonePref);
    });

    if (this.selectOrAddClientService.client?.id == '' && this.selectOrAddClientService.client?.firstName != '') {
      this.clientFormGroup.patchValue(this.selectOrAddClientService.client);
    }
  }

  public onSubmit(clientFormGroup: FormGroup) {
    const prefix = clientFormGroup.get('phonePrefix').value.hasOwnProperty('value') ? clientFormGroup.get('phonePrefix').value.value
      : clientFormGroup.get('phonePrefix').value;
    clientFormGroup.get('phonePrefix').patchValue(prefix);
    clientFormGroup.get('fullName').patchValue(clientFormGroup.get('firstName').value + ' ' + clientFormGroup.get('lastName').value);

    this.selectOrAddClientService.client = clientFormGroup.value;
    this.selectOrAddClientService.add.emit(clientFormGroup.value);

    this.router.navigate(['./..'], { relativeTo: this.route });
  }

  public showAddClientForm() {
    this.headerLabel = 'Add Client';
    this.addNewClient = true;
  }

  public onBack() {
    if (this.addNewClient) {
      this.headerLabel = 'Select Client';
      this.addNewClient = false;
      this.clientFormGroup.patchValue(this.selectOrAddClientService.client);
    } else
      this.router.navigate(['..'], { relativeTo: this.route });
  }

  public onClientSelected(client: any) {
    this.selectOrAddClientService.client = client;
    this.selectOrAddClientService.select.emit(client);

    this.onBack();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.phonePrefixService.initPrefixService();
  }

  getTitle(): string {
    return this.selectOrAddClientService.client?.id === '' && this.selectOrAddClientService.client?.firstName != '' ? 'Edit ' + this.selectOrAddClientService.client.fullName : 'Add Client';
  }

  isSelected(client: Client): boolean {
    return client.id == this.selectOrAddClientService.client?.id;
  }
}
