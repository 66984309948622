import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotProceedingFollowup } from '../../../services/not-proceeding-reasons.service';
import { fadeAnimation, sidepaneMove } from '../../../shared/animations/sidepane-animation';
import { SelectDecliningReasonService, SelectedDecliningReason } from './select-declining-reason.service';

@Component({
  selector: 'hf-select-declining-reason',
  templateUrl: 'select-declining-reason.component.html',
  styleUrls: ['select-declining-reason.component.scss',
    '../../../styles/sidepanes.partial.scss'],
  animations: [sidepaneMove, fadeAnimation],
  host: {'[@sidepaneMove]': 'true'}
})
export class SelectDecliningReasonComponent {
  public form = this.fb.group({
    other: [null],
  });

  public value: NotProceedingFollowup;
  public hasError = false;

  public get notProceedingFollowups() { return this.selectDecliningReasonService.notProceedingFollowups }

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private selectDecliningReasonService: SelectDecliningReasonService,
  ) { }

  public isOther() {
    return this.value?.reason === "Other";
  }

  public onBack() {
    this.router.navigate(['./..'], {relativeTo: this.activatedRoute});
  }

  public onSelectClicked() {
    const followup: SelectedDecliningReason = { followup: this.value, other: this.form.value.other };

    this.selectDecliningReasonService.followup$.next(followup);
    this.onBack();
  }

  public onSelected(followup: NotProceedingFollowup) {
    if (followup.displayName === 'Other' && !this.form.value.other) {
      this.hasError = true;
      return;
    }
    this.selectDecliningReasonService.followup$.next({ followup })

    if (!this.isOther()) {
      this.onBack();
    }
  }
}
