import { Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApplicationListDTO } from '../../../models/application';
import { fadeAnimation, sidepaneMove } from '../../../shared/animations/sidepane-animation';
import { SelectApplicationService } from './select-application.service';

@Component({
  selector: 'hf-select-application',
  templateUrl: './select-application.component.html',
  styleUrls: ['./select-application.component.scss',
    '../../../styles/sidepanes.partial.scss'
  ],
  animations: [sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove]': 'true' }
})
export class SelectApplicationComponent implements OnDestroy {
  public isLoading = false;
  public get items(): ApplicationListDTO[] { return this.selectApplicationService.applications }

  public form = new FormGroup({
    application: new FormControl()
  });

  public compareFn = (app1: ApplicationListDTO, app2: ApplicationListDTO): boolean =>
    !!(app1 && app2 && app1.id === app2.id);

  private destroy$ = new Subject();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private selectApplicationService: SelectApplicationService
  ) {
    this.form.get("application").valueChanges.pipe(takeUntil(this.destroy$)).subscribe(app => {
      this.selectApplicationService.applicationSelected$.next(app);
      this.onBack();
    })
  }

  public onBack() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }
}
