import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { EventUtils } from '../../utils/event.utils';
import { FileuploaderService } from './service/fileuploader.service';
import { FileControlsConfigBuilder } from './utils/fileuploader.utils';
import { Subject } from 'rxjs';
import { FileType, UploadType } from './utils/fileuploader.enum';
import { getId } from '../../utils/functions/get-id.function';

@Component({
  selector: 'hf-fileuploader',
  templateUrl: './fileuploader.component.html',
  styleUrls: ['./fileuploader.component.scss'],
  providers: [FileuploaderService]
})
export class FileuploaderComponent implements OnInit, OnChanges {

  @Input() group: FormGroup;
  @Input() controlName: string;
  @Input() fileControl: AbstractControl;
  @Input() allowedFileTypes: FileType[];
  @Input() uploadType: UploadType = UploadType.UPLOAD_FILE;
  @Input() makeSquare = false;
  @Input() showMaxHint = false;

  public controlId = `fileupload_${getId()}`;
  fileChanged = {
    fileType: null
  };
  isLoading = false;
  errorMessage: string;

  constructor(private fileuploaderService: FileuploaderService) {
  }

  ngOnInit() {
    if (this.group.value[this.controlName] && this.group.value[this.controlName][FileControlsConfigBuilder.FILE_NAME]) {
      this.reloadErrors();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.fileControl) {
      if (this.group.value[this.controlName]) {
        if (this.group.value[this.controlName][FileControlsConfigBuilder.FILE_NAME]) {
          this.reloadErrors();
        } else if (!this.group.value[this.controlName][FileControlsConfigBuilder.ORIGINAL_FILENAME]) {
          this.errorMessage = null;
        }
      }
    }
  }

  onFileChange(event) {
    this.isLoading = true;

    const file =  EventUtils.getFile(event);
    if (file) {
      const subject = new Subject<any>();

      subject.subscribe(() => {
        this.group.get(this.controlName).get(FileControlsConfigBuilder.FILE_NAME).disable();
        if (this.group.get(this.controlName).valid) {
          this.group.get(this.controlName).get(FileControlsConfigBuilder.FILE_NAME).enable();
          this.uploadFile(file);
        } else {
          this.group.get(this.controlName).get(FileControlsConfigBuilder.FILE_NAME).enable();
          this.handleError(file);
        }
      });

      this.fileuploaderService.mapFileToControl(file, this.group.get(this.controlName), subject);
    }
  }

  isFileSaved(): boolean {
    return this.group.value[this.controlName] && this.group.value[this.controlName][FileControlsConfigBuilder.ID];
  }

  uploadFile(file: File) {
    this.fileuploaderService.uploadFile(file, this.uploadType, this.makeSquare).subscribe((response) => {
      this.fileChanged = {
        fileType: file.type
      };
      this.errorMessage = null;
      this.group.get(this.controlName).patchValue(response);
      this.isLoading = false;
    });
  }

  handleError(file: File) {
    this.fileChanged = {
      fileType: file.type
    };
    this.initErrors();
    this.isLoading = false;
  }

  onDeleteFile() {
    this.errorMessage = null;
    this.group.get(this.controlName).reset();
    this.group.get(this.controlName).markAsDirty();
  }

  isFileUploaded(): boolean {
    return this.group.value[this.controlName] && this.group.value[this.controlName][FileControlsConfigBuilder.ORIGINAL_FILENAME] != null;
  }

  private reloadErrors() {
    if (this.group.get(this.controlName).invalid) {
      this.initErrors();
    } else {
      this.errorMessage = null;
    }
  }

  private initErrors() {
    const errors = this.fileuploaderService.getErrors(this.group.get(this.controlName));
    this.errorMessage = errors[Object.keys(errors)[0]];
  }
}
