<div (click)="onBack()" class="overlay" [@fadeAnimation]></div>

<div class="sidepane-content">
  <div class="flex-space-between-layout template-header">
    <h1>My Account</h1>
    <hf-custom-button type="button" class="button-plain-icon" icon="close" iconPos="center" (onClick)="onBack()">
    </hf-custom-button>
  </div>

  <div class="template-main">
    <hf-custom-spinner *ngIf="isLoading" sidepaneLoading="true"></hf-custom-spinner>

    <form *ngIf="!isLoading" [formGroup]="form">
      <label>Username
        <div class="username">
          {{ user.username }}
        </div>
      </label>
      <label [style.width.%]="100">
        First Name
        <hf-custom-text-field class="text-field" [group]="form" type="text" inputFormControlName="firstName" [capitalize]="true" fieldName="firstName"
          validateOnBlur marginElement>
        </hf-custom-text-field>
      </label>

      <label [style.width.%]="100">
        Last Name
        <hf-custom-text-field class="text-field" [group]="form" type="text" inputFormControlName="lastName" [capitalize]="true" fieldName="lastName"
          validateOnBlur marginElement>
        </hf-custom-text-field>
      </label>

      <label [style.width.%]="100">
        Email Address
        <hf-custom-text-field class="text-field" [group]="form" type="email" inputFormControlName="email" fieldName="email"
          validateOnBlur marginElement>
        </hf-custom-text-field>
      </label>

      <div [style.width.%]="100" class="flex-space-between-layout phone-line-wrap">
        <div class="flex-phone-prefix">
          <div class="hf-selector">
            <label>Phone</label>
            <div class="hf-selector-container hf-selector-has-value hf-selector-one-line"
              [routerLink]="['./selectPhonePrefix']">
              <div class="hf-selector-content-area">
                <div class="title">{{form?.get('phonePrefix')?.value}}</div>
              </div>
              <i class="material-icons chevron-icon">chevron_right</i>
            </div>
          </div>
        </div>

        <div class="flex-phone">
          <hfc-text-input type="tel" mask="0*" style="margin-bottom: 12px;" formControlName="phone" hfcRemoveLeadingZero></hfc-text-input>
        </div>
      </div>

      <div style="margin: 8px 0;">
        <label>
          Profile Photo
        </label>
        <div marginElement
             class="flex-space-between-layout"
             [style.width.%]="100">
          <hf-fileuploader controlName="photo"
                           [group]="form"
                           [fileControl]="form.value['photo']"
                           [allowedFileTypes]="ALLOWED_FILE_TYPES"
                           [makeSquare]="true">
          </hf-fileuploader>
        </div>
      </div>

      <label>
        2FA Status
      </label>
      <hf-two-factor-status [status]="user"></hf-two-factor-status>

      <div class="auth-buttons">
        <hfc-button type="tertiary" size="small" (click)="onTwoFactorSetupClicked()">
          <span *ngIf="twoFactorSetupStatus === 'NONE'">Setup 2 Factor Authentication</span>
          <span *ngIf="twoFactorSetupStatus === 'SMS'">Setup Authenticator app</span>
          <span *ngIf="twoFactorSetupStatus === 'FULL'">Setup 2 Factor Authentication Again</span>
        </hfc-button>
      </div>

      <ng-container *ngIf="!isIntroducer">
        <label style="margin-top: 24px">
          Office 365 Status
        </label>
        <hf-office-365-status [status]="user.microsoftAuthorised"></hf-office-365-status>

        <div class="auth-buttons">
          <hfc-button type="tertiary" size="small" [isLoading]="unlinkIsLoading" (click)="onLinkClicked()">
            <span *ngIf="!user.microsoftAuthorised">Link Office 365</span>
            <span *ngIf="user.microsoftAuthorised">Unlink Office 365</span>
          </hfc-button>
        </div>

        <label style="margin-top: 24px">
          Twenty7tec Status
        </label>
        <hf-office-365-status [status]="user.twentySevenTecAuthorised"></hf-office-365-status>

        <div class="auth-buttons">
          <hfc-button type="tertiary" size="small" [isLoading]="twenty7tecIsLoading" (click)="user.twentySevenTecAuthorised ? onTwenty7tecUnlinkClicked() : onTwenty7tecLinkClicked()">
            <span *ngIf="!user.twentySevenTecAuthorised">Link Twenty7tec</span>
            <span *ngIf="user.twentySevenTecAuthorised">Unlink Twenty7tec</span>
          </hfc-button>
        </div>
      </ng-container>
    </form>
  </div>

  <div class="template-footer">
    <hf-custom-button type="submit"
                      class="button-primary"
                      label="Update"
                      [disabled]="!form.valid"
                      [callable]="true"
                      (onClick)="onRequest()">
    </hf-custom-button>

    <hf-custom-button type="button"
                      class="button-secondary"
                      label="Cancel"
                      (onClick)="onBack()">
    </hf-custom-button>
  </div>
</div>

<div class="sidepane-child-anchor">
  <router-outlet></router-outlet>
</div>
