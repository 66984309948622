<div class="flex-header table-page-header single-button-header">
  <hf-nav-button></hf-nav-button>

  <div class="flex-title">
    <h1>
      Mortgages
      <i class="material-icons header-crumbs-divider heading-part">chevron_right</i>
      {{ routeData.name }}
    </h1>
  </div>

  <div class="single-button-wrap">
    <hf-custom-button *ifPermission="['MORTGAGE_NEW', 'MORTGAGE_NEW_INTRODUCER', 'MORTGAGE_NEW_DIVISION', 'MORTGAGE_NEW_OWN']"
                      class="button-primary"
                      label="Add Referral"
                      icon="add_circle"
                      iconPos="left"
                      textPos="left"
                      [routerLink]="['./addMortgage']">
    </hf-custom-button>
  </div>
</div>

<div class="table-page-container">
  <div class="searchBar">
    <div class="search-box-line">
      <div class="search-bar-wrap">
        <hf-custom-text-field type="text"
                              [searchMode]="true"
                              placeholder="Search mortgages …"
                              fieldName="input12"
                              icon="search"
                              preventValidation="true">
        </hf-custom-text-field>
      </div>

      <hf-custom-button type="button"
                        class="button-plain-icon for-phone-show"
                        icon="more_horiz"
                        iconPos="center"
                        (click)="dashboardHeaderButtons.classList.add('dashboard-headers-buttons-visible')">
      </hf-custom-button>
    </div>

    <hf-checkbox label="Assigned to Me" [(ngModel)]="assignedToMe" (ngModelChange)="onAssignedToMeChanged()">
    </hf-checkbox>
  </div>

  <div class="utils-area dashboard-header-buttons"
       #dashboardHeaderButtons
       (click)="dashboardHeaderButtons.classList.remove('dashboard-headers-buttons-visible')">

    <hf-mortgage-export-button [quickFilter]="quickFilter"></hf-mortgage-export-button>
  </div>

  <div class="everglades-table" hfTable>
    <div class="everglades-table-headings-sticky-wrapper" hfStickyTableHeadings>
      <div class="everglades-table-headings">
        <div *ngIf="isColumnVisible('clientCategory')" [sortBy]="'clientCategory'" class="width-60"><span class="center">Type</span></div>
        <div *ngIf="isColumnVisible('advisor')" [sortBy]="'advisor'" class="width-70"><span>Advisor</span></div>
        <div *ngIf="isColumnVisible('client')" [sortBy]="'clientFirstName;clientLastName'" class="min-100" style="flex: 1.3 1 0px"><span>Client</span></div>
        <div *ngIf="isColumnVisible('createdDate')" [sortBy]="'createdDate'" class="width-110"><span>Created</span></div>
        <div *ngIf="isColumnVisible('division')" [sortBy]="'division'" class="width-170"><span>{{ 'Division' | wording:introducerType }}</span></div>
        <div *ngIf="isColumnVisible('caseProgressor')" [sortBy]="'caseProgressor'" class="width-170"><span>Case Progressor</span></div>
        <div *ngIf="isColumnVisible('introducer')" [sortBy]="'introducer'" class="width-120"><span>Introducer</span></div>
        <div *ngIf="isColumnVisible('reference')" class="width-130"><span>Reference</span></div>
        <div *ngIf="isColumnVisible('lenderReference')" class="min-100"><span>Lender Ref.</span></div>
        <div *ngIf="isColumnVisible('lender')" class="width-100"><span>Lender</span></div>
        <div *ngIf="isColumnVisible('completionDate')" [sortBy]="'completionDate'" class="width-110"><span>Completion</span></div>
        <div *ngIf="isColumnVisible('productEndDate')" class="width-110"><span>Product End</span></div>
        <div *ngIf="isColumnVisible('appointmentDate')" [sortBy]="'appointmentDate'" class="width-110"><span>Appointment</span></div>
        <div *ngIf="isColumnVisible('proceedingState')" [sortBy]="'proceedingStateOrder'" class="width-100"><span>Proceeding</span></div>
        <div *ngIf="isColumnVisible('submittedDate')" [sortBy]="'submittedDate'" class="width-110"><span>Submitted</span></div>
        <div *ngIf="isColumnVisible('valuationState')" [sortBy]="'valuationState'" class="min-100"><span>Valuation Status</span></div>
        <div *ngIf="isColumnVisible('offerExpiryDate')" [sortBy]="'offerExpiryDate'" class="width-110"><span>Offer Expiry</span></div>
        <div *ngIf="isColumnVisible('nextHeronTaskDate')" [sortBy]="'nextHeronTaskDate'" class="width-110"><span>Next Heron Task Date</span></div>
        <div *ngIf="isColumnVisible('factFindState')" [sortBy]="'factFindStateOrder'" class="width-92 center"><span>Fact Find</span></div>
        <div *ngIf="isColumnVisible('helpToBuyStatus')" [sortBy]="'helpToBuyStatus'" class="width-60 center"><span>H2B</span></div>
        <div *ngIf="isColumnVisible('applicationState')" [sortBy]="'applicationStateOrder'"><span>Application</span></div>
        <div *ngIf="isColumnVisible('lastAction')" [sortBy]="'lastAction'" class="width-110"><span>Last Action</span></div>
        <div *ngIf="isColumnVisible('qualificationStatus')" class="width-170"><span>Qualified Up To</span></div>
        <div *ngIf="isColumnVisible('tasksAll')" [sortBy]="'tasksAll'" class="width-60"><span>Tasks</span></div>
        <div *ngIf="isColumnVisible('state')" [sortBy]="'stateOrder'" class="width-170"><span>Status</span></div>
      </div>
    </div>

    <a *ngFor="let row of data" (click)="openMortgageDashboard(row)">
      <div class="table-row" [class.introducerLinkBroken]="isIntroducer() && row.introducerLinkBroken">
        <div *ngIf="isColumnVisible('clientCategory')" data-mobileTitle="Type" class="center width-60 clientCategory">
          <hf-client-category-icon [category]="row.clientCategory" [hfTooltip]="CLIENT_CATEGORY_NAME.get(row.clientCategory)"></hf-client-category-icon>
          <i *ngIf="row.activeApplications > 1" class="material-icons activeApplications">add</i>
        </div>

        <div *ngIf="isColumnVisible('advisor')" data-mobileTitle="Advisor" class="avatar width-70">
          <hf-avatar *ngIf="row.advisor" [photo]="row.advisorPhoto" [hfTooltip]="row.advisor"></hf-avatar>
          <span class="status-text">{{row.advisor}}</span>
        </div>

        <div *ngIf="isColumnVisible('client')" data-mobileTitle="Client" class="min-100" style="flex: 1.3 1 0px">
          <span [hfTooltip]="getClientTooltip(row)" [class.selfReferral]="row.selfReferral && isIntroducer()">{{row.client ? row.client : '--'}}</span>
        </div>

        <div *ngIf="isColumnVisible('createdDate')" data-mobileTitle="Created" [hfTooltip]="row.createdDate | date:tooltipDateFormat" class="width-110">{{row.createdDate ? (row.createdDate | date:dateFormat) : '--'}}</div>

        <div *ngIf="isColumnVisible('division')" [attr.data-mobileTitle]="'Division' | wording:introducerType" class="width-170">{{ row.division }}</div>

        <div *ngIf="isColumnVisible('caseProgressor')" data-mobileTitle="Case Progressor" class="width-170">{{ row.caseProgressor }}</div>

        <div *ngIf="isColumnVisible('introducer')" data-mobileTitle="Introducer" class="width-120">
          <hf-attachment-image *ngIf="row.introducerLogo" [attachment]="row.introducerLogo" [hfTooltip]="getIntroducerTooltip(row)" class="provider-logo" [class.selfReferral]="row.selfReferral"></hf-attachment-image>

          <span *ngIf="!row.introducerLogo">
            {{row.introducer}}
          </span>
        </div>

        <div *ngIf="isColumnVisible('reference')" data-mobileTitle="Reference" class="width-130"><span [hfTooltip]="getReference(row)">{{ getReference(row) }}</span></div>

        <div *ngIf="isColumnVisible('lenderReference')" data-mobileTitle="Lender Ref." [hfTooltip]="row.lenderReference" class="min-100">{{ row.lenderReference }}</div>

        <div *ngIf="isColumnVisible('lender')" data-mobileTitle="Lender" class="width-100">
          <span *ngIf="row.lender && !row.lenderLogo?.filename" class="lender-name" [hfTooltip]="row.lender">{{ row.lender }}</span>
          <hf-attachment-image *ngIf="row.lender && row.lenderLogo?.filename" [attachment]="row.lenderLogo" [alt]="row.lender" [hfTooltip]="row.lender" class="provider-logo"></hf-attachment-image>
        </div>

        <div *ngIf="isColumnVisible('completionDate')" data-mobileTitle="Completion" class="completionDate width-110">
          <span [hfTooltip]="row.completionDate | date:tooltipDateFormat">{{row.completionDate | date:dateFormat}}</span>
        </div>

        <div *ngIf="isColumnVisible('productEndDate')" data-mobileTitle="Product End" class="productEndDate width-110">
          <span [hfTooltip]="row.productEndDate | date:tooltipDateFormat">{{row.productEndDate | date:dateFormat}}</span>
        </div>

        <div *ngIf="isColumnVisible('appointmentDate')" data-mobileTitle="Appointment" class="appointmentDate width-110" [class.previous]="isAppointmentPrevious(row)">
          <span [hfTooltip]="row.appointmentDate | date:tooltipDateFormat">{{row.appointmentDate | date:dateFormat}}</span>
        </div>

        <div *ngIf="isColumnVisible('proceedingState')" data-mobileTitle="Proceeding" class="width-100 center">
          <hf-status-icon type="PROCEEDING_STATE" [status]="row.proceedingState" [hfTooltip]="PROCEEDING_STATE_TYPE_NAME.get(row.proceedingState)"></hf-status-icon>
          <span class="status-text">{{PROCEEDING_STATE_TYPE_NAME.get(row.proceedingState)}}</span>
        </div>

        <div *ngIf="isColumnVisible('submittedDate')" data-mobileTitle="Submitted" [hfTooltip]="row.submittedDate | date:tooltipDateFormat" class="submittedDate width-110" [class.old]="isSubmittedDateOld(row)">{{row.submittedDate ? (row.submittedDate | date:dateFormat) : '--'}}</div>

        <div *ngIf="isColumnVisible('valuationState')" data-mobileTitle="Valuation Status" class="center min-100">
          <hf-status-icon type="ValuationState" [status]="row.valuationState" [hfTooltip]="ValuationStateName.get(row.valuationState)"></hf-status-icon>
          <span class="status-text">{{ValuationStateName.get(row.valuationState)}}</span>
        </div>

        <div *ngIf="isColumnVisible('offerExpiryDate')" data-mobileTitle="Offer Expiry" [hfTooltip]="row.offerExpiryDate | date:tooltipDateFormat" class="offerExpiryDate width-110" [class.old]="isOfferExpiryDateOld(row)">{{ row.offerExpiryDate | date:dateFormat }}</div>

        <div *ngIf="isColumnVisible('nextHeronTaskDate')" data-mobileTitle="Next Heron Task Date" [hfTooltip]="row.nextHeronTaskDate | date:tooltipDateFormat" class="width-110">{{ row.nextHeronTaskDate | date:dateFormat }}</div>

        <div *ngIf="isColumnVisible('factFindState')" data-mobileTitle="Fact Find" class="width-92 center">
          <hf-status-icon type="FACT_FIND_STATE" [status]="row.factFindState" [hfTooltip]="FACT_FIND_STATE_NAMES.get(row.factFindState)"></hf-status-icon>
          <span class="status-text">{{FACT_FIND_STATE_NAMES.get(row.factFindState)}}</span>
        </div>

	<div *ngIf="isColumnVisible('helpToBuyStatus')" data-mobileTitle="H2B" class="helpToBuyStatus width-60 center">
          <hf-status-icon type="HELP_TO_BUY_STATUS" [status]="row.helpToBuyStatus" [hfTooltip]="HELP_TO_BUY_STATUS_NAMES.get(row.helpToBuyStatus)"></hf-status-icon>
          <span class="status-text">{{HELP_TO_BUY_STATUS_NAMES.get(row.helpToBuyStatus)}}</span>
	</div>

        <div *ngIf="isColumnVisible('applicationState')" data-mobileTitle="Application" style="align-items: center;">
          <hf-status-icon type="APPLICATION_STATE" [status]="row.applicationState" [hfTooltip]="APPLICATION_STATE_NAMES.get(row.applicationState)"></hf-status-icon>
          <span class="status-text">{{APPLICATION_STATE_NAMES.get(row.applicationState)}}</span>
        </div>

        <div *ngIf="isColumnVisible('lastAction')" data-mobileTitle="Last Action" [hfTooltip]="getLastActionTooltip(row)" class="width-110">{{row.lastAction ? (row.lastAction|timeDifference:row.lastAction) : '--'}}</div>

        <div *ngIf="isColumnVisible('qualificationStatus')" data-mobileTitle="Qualified Up To" class="width-170 qualificationStatus">
          {{ qualificationValue(row) }}
          <span *ngIf="row.qualificationStatus === 'PARTIALLY_QUALIFIED'" hfTooltip [component]="qualificationTooltip" [componentInputs]="{ desiredQualification: row.desiredQualification }">
            <img src="/assets/icons/Partially Qualified.svg" class="desktop-only">
            <span class="mobile-only">
              Desired Qualification {{ row.desiredQualification | currency:'GBP':undefined:'1.0-0' }}
            </span>
          </span>
        </div>

        <div *ngIf="isColumnVisible('tasksAll')" data-mobileTitle="Tasks" class="width-60">
	  <span *ngIf="row.tasksAll > 0" class="tasksAll">
            {{ row.tasksAll }}
	    <i *ngIf="row.tasksDue > 0" [hfTooltip]="getTasksDueTooltip(row)" class="material-icons">error</i>
          </span>
        </div>

        <div *ngIf="isColumnVisible('state')" data-mobileTitle="Status" class="width-170" style="display: block; color: white">
          <span *ngIf="isIntroducer() && row.introducerLinkBroken" class="status-icon not_proceeding">Proceeding Elsewhere</span>
          <span *ngIf="!(isIntroducer() && row.introducerLinkBroken)" class="status-icon" [ngClass]="row.state|lowercase">{{row|mortgageStatus}}</span>
        </div>
      </div>
    </a>
  </div>

  <div class="pagination-row">
    <hf-pagination-summary-box></hf-pagination-summary-box>
    <hf-paginator></hf-paginator>
  </div>
</div>

<div class="sidepanes-anchor">
  <router-outlet></router-outlet>
</div>
