import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { ApiService } from './api.service';
import { AuthenticationService } from './authentication.service';
import { ClientApiService } from './client-api.service';
import { FactFindApiService } from './fact-find-api.service';
import { IntroducerApiService } from './introducer-api.service';
import { MortgageApiService } from './mortgage-api.service';
import { DocumentApiService } from "./document-api.service";
import { JournalApiService } from './journal-api.service';
import { ReportApiService } from './report-api.service';
import { UserApiService } from "./user-api.service";
import { ValidatorsService } from "./validators.service";
import { ProtectionApiService } from './protection-api.service';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    HttpClientModule
  ],
  exports: [
  ],
  providers: [
    ApiService,
    AuthenticationService,
    IntroducerApiService,
    ClientApiService,
    JournalApiService,
    ReportApiService,
    MortgageApiService,
    FactFindApiService,
    DocumentApiService,
    UserApiService,
    ValidatorsService,
    ProtectionApiService,
  ]
})
export class ServicesModule { }
