import { Component } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";

import { DocumentTitleService } from "./website/services/document-title.service";
import { NavigationService } from "./website/services/navigation.service";
import { environment } from "../environments/environment";
import { SwUpdateService } from "./website/services/sw-update.service";
import { Chart } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

@Component({
  selector: "hf-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private navigationService: NavigationService,
    private swUpdateService: SwUpdateService,
    private documentTitleService: DocumentTitleService // keep global instance
  ) {
    // EV-214: table filtering
    this.router.events.subscribe((ev) => {
      ev instanceof NavigationEnd &&
        this.navigationService.history.push(ev.url);

      try {
        this.swUpdateService.askUserToRefresh();
      } catch (e) {}
    });

    // unregestering datalabels plugin from charts module,
    // so it can manually be registered only where datalabels are needed
    Chart.unregister(ChartDataLabels);

    try {
      const meta = document.querySelector("meta[name=build]");
      const build = meta.getAttribute("content");
      const date = new Date(build.split("--")[0]);

      console.warn(`This version was build at: ${date}`);
    } catch (e) {}
  }
}
