import { Component, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Mortgage } from '../../modules/mortgages/state/mortgage.actions';
import { DashboardDropdownMenuItem } from '../../modules/protection/components/protection-dashboard/protection-dashboard.component';
import { CustomMenuComponent } from '../custom-menu/custom-menu.component';

export interface ToolbarWithMenuConfig {
  deleteAction: any;
  notProceedingAction: any;
}

@Component({
  selector: 'hf-toolbar-with-menu',
  templateUrl: './toolbar-with-menu.component.html',
  styleUrls: ['./toolbar-with-menu.component.scss']
})
export class ToolbarWithMenuComponent {
  @Input()
  public config: ToolbarWithMenuConfig;

  @ViewChild(CustomMenuComponent)
  public dropdownMenu: CustomMenuComponent;

  public menuItems: DashboardDropdownMenuItem[];
  public mobileMenuItems: DashboardDropdownMenuItem[];

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.initializeMenuItems();
  }

  public onSendMessageClicked() {
    this.router.navigate(["sendMessage"], { relativeTo: this.route });
  }

  public onDeleteClicked() {
    this.store.dispatch(new this.config.deleteAction());
  }

  public onNotProceedingClicked() {
    this.store.dispatch(new this.config.notProceedingAction());
  }

  public onRequestDocumentClicked() {
    this.router.navigate(["requestDocument"], { relativeTo: this.route });
  }

  public onPushToMidasClicked() {
    this.router.navigate(["push-to-midas"], { relativeTo: this.route });
  }

  public onAddDocumentClicked() {
    this.router.navigate(["addDocument"], { relativeTo: this.route });
  }

  public onAddTask() {
    this.router.navigate(["addTask"], { relativeTo: this.route });
  }

  public onAppointmentClicked() {
    this.store.dispatch(new Mortgage.MenuAction("arrangeAppointment"));
  }

  public onAddNoteClicked() {
    this.router.navigate(["addNote"], { relativeTo: this.route });
  }

  public onLogContactClicked() {
    this.router.navigate(["addContactAttempt"], { relativeTo: this.route });
  }

  public onPinnedNoteClicked() {
    this.router.navigate(["pinned-note"], { relativeTo: this.route });
  }

  public onOpenMenuClicked() {
    this.dropdownMenu.toggleMenu();
  }

  public onItemSelected(item: DashboardDropdownMenuItem) {
    if (item.callback) {
      item.callback();
      return;
    }
  }

  private initializeMenuItems() {
    this.menuItems = [
      { label: "Add Document...", callback: () => this.onAddDocumentClicked() },
      { label: "Request Document...", callback: () => this.onRequestDocumentClicked() },
      { label: "Midas Resolution...", callback: () => this.onPushToMidasClicked() },
      { label: "Not Proceeding", callback: () => this.onNotProceedingClicked(), styles: { 'color': '#ED3A26' } },
      { label: "Delete", callback: () => this.onDeleteClicked(), styles: { 'color': '#ED3A26' } },
    ];

    this.mobileMenuItems = [
      { label: "Send Message..." },
      { label: "Add Task...", callback: () => this.onAddTask() },
      { label: "Book Appointment...", callback: () => this.onAppointmentClicked() },
      { label: "Add Note...", callback: () => this.onAddNoteClicked() },
      { label: "Log Contact...", callback: () => this.onLogContactClicked() },
      { label: "Pinned Note..." },
      { label: "Add Document...", callback: () => this.onAddDocumentClicked() },
      { label: "Request Document...", callback: () => this.onRequestDocumentClicked() },
      { label: "Midas Resolution...", callback: () => this.onPushToMidasClicked() },
      { label: "Not Proceeding", callback: () => this.onNotProceedingClicked(), styles: { 'color': '#ED3A26' } },
      { label: "Delete", callback: () => this.onDeleteClicked(), styles: { 'color': '#ED3A26' } },
    ];
  }
}
