import { Component, Input } from '@angular/core';

@Component({
  selector: 'hf-office-365-status',
  templateUrl: './office-365-status.component.html',
  styleUrls: ['./office-365-status.component.scss']
})
export class Office365StatusComponent {
  @Input()
  public status: boolean;
}
