import { Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';

import { MortgageApiService } from '../../../services/mortgage-api.service';
import { fadeAnimation, sidepaneMove } from '../../../shared/animations/sidepane-animation';
import { MORTGAGE_OBJECTIVE, MORTGAGE_OBJECTIVE_NAME } from '../../../utils/variables.data';
import { SelectedObjective, SelectObjectivesService } from './select-objectives.service';

@Component({
  selector: 'hf-select-objectives',
  templateUrl: './select-objectives.component.html',
  styleUrls: [
    './select-objectives.component.scss',
    '../../../styles/sidepanes.partial.scss'
  ],
  animations: [sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove]': 'true' }
})
export class SelectObjectivesComponent implements OnDestroy {
  public form = new FormGroup({
    objective: new FormControl(),
    otherObjective: new FormControl()
  });

  public MORTGAGE_OBJECTIVE_NAME = new Map<MORTGAGE_OBJECTIVE, string>(
    [...MORTGAGE_OBJECTIVE_NAME.entries()].map(([k, v]) => [k, v.replace(" + Cap Raising", "")] as [MORTGAGE_OBJECTIVE, string])
  );
  public objectives = [...MORTGAGE_OBJECTIVE_NAME.keys()];

  private destroy$ = new Subject();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private mortgageApiService: MortgageApiService,
    private selectObjectivesService: SelectObjectivesService
  ) {
    const selectedObjective = this.selectObjectivesService.objective;

    if (selectedObjective) {
      this.form.patchValue(selectedObjective);
    }

    this.form.valueChanges.subscribe((value: SelectedObjective) => {
      if (value.objective !== "OTHER") {
        this.onSubmit();
      }
    })
  }

  public isCapRaising(objective: MORTGAGE_OBJECTIVE) {
    const capRaisingObjectives: MORTGAGE_OBJECTIVE[] = ["BTL_RE_CR", "RESI_RE_CR", "LET_TO_BUY_CR"];

    return capRaisingObjectives.includes(objective);
  }

  public onSubmit() {
    const value: SelectedObjective = this.form.value;

    if (value.objective !== "OTHER") {
      value.otherObjective = undefined;
    }

    this.selectObjectivesService.setObjective(value);

    this.onBack();
  }

  public onBack() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }
}
