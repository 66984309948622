import { Component, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'hf-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss']
})
export class IconButtonComponent {
  @Input()
  public icon: string;

  @Input()
  @HostBinding("class.isCircle")
  public isCircle: boolean;
}
