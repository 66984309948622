import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UpdateApplicationComponent } from "./update-application.component";
import { RouterModule } from "@angular/router";
import { CustomButtonModule } from "../../../shared/custom-button/custom-button.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CustomTextFieldModule } from "../../../shared/custom-text-field/custom-text-field.module";
import { HfCheckboxModule } from "../../../shared/hf-checkbox-component/hf-checkbox.module";
import { CustomTextAreaModule } from "../../../shared/custom-textarea/custom-text-area.module";
import { DatePickerModule } from "../../../shared/date-picker/date-picker.module";
import { SharedModule } from "../../../shared/shared.module";
import { ClientSharedModule } from "../../../../../../projects/client/src/app/shared/client-shared.module";
import { CustomSpinnerModule } from "../../../shared/custom-spinner/custom-spinner.module";
import { SharedLayoutModule } from "../../../shared-layout/shared-layout.module";

@NgModule({
  declarations: [UpdateApplicationComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    CustomButtonModule,
    CustomTextFieldModule,
    CustomTextAreaModule,
    HfCheckboxModule,
    DatePickerModule,
    ClientSharedModule,
    CustomSpinnerModule,
    SharedLayoutModule,
  ],
  exports: [UpdateApplicationComponent],
})
export class UpdateStatusModule {}
