import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DocumentThumbnail } from '../../../../../../projects/client/src/app/shared/components/document-thumbnail/document-thumbnail.component';
import { Attachment } from '../../../models/attachment';
import { NewComplianceHandoverViewDTO } from '../../../models/new-compliance-handover-view.dto';

@Component({
  selector: 'hf-new-client-sheet',
  templateUrl: './new-client-sheet.component.html',
  styleUrls: ['./new-client-sheet.component.scss']
})
export class NewClientSheetComponent {
  @Input()
  public data: NewComplianceHandoverViewDTO;

  @Output()
  public editClick = new EventEmitter();

  public onEditClicked() {
    this.editClick.emit();
  }

  public getDocumentThumbnail(attachment: Attachment, customType: string): DocumentThumbnail {
    return {
      file: attachment,
      customType
    };
  }
}
