<div class="overlay" (click)="onBack()" [@fadeAnimation]></div>

<div class="sidepane-content">
  <div class="template-header flex-space-between-layout">
    <h1 *ngIf="!editMode" class="title-area">Arrange Appointment</h1>
    <h1 *ngIf="editMode" class="title-area">Edit Appointment</h1>
    <hf-custom-button type="button" icon="close" iconPos="center"
                      class="button-plain-icon"
                      (click)="onBack()">
    </hf-custom-button>
  </div>

  <div class="template-main">
    <ng-container *ngIf="isLoading">
      <hf-custom-spinner sidepaneLoading="true"></hf-custom-spinner>
    </ng-container>

    <form *ngIf="!isLoading"
          [formGroup]="form"
          class="flex-col"
          [style.height.%]="100">

      <div class="hf-selector">
        <label>Host</label>
          <div class="hf-selector-container hf-selector-has-value" [class.hf-selector-black-color]="form.value.appointmentHost.fullName"
            [routerLink]="['./selectHost']" [queryParams]="form?.value?.appointmentHost?.id ? {hostId: form?.value?.appointmentHost?.id} : null">
            <div class="hf-selector-label-area">
              <div class="title">{{form.value.appointmentHost.fullName ? form.value.appointmentHost.fullName : "Select Host"}}</div>
            </div>
            <i class="material-icons chevron-icon">chevron_right</i>
          </div>
      </div>
      <div class="flex-space-between-layout desktop-date-time"
           [style.width.%]="100">

        <div class="hf-selector">
          <label>Date and Time</label>

          <div *ngIf="!form.get('appointmentDate').value"
               class="hf-selector-container hf-selector-has-value"
               [routerLink]="['./selectDateAndTime']"
               [queryParams]="{name: 'appointmentDate'}">
            <div class="hf-selector-label-area">
              <div class="title">Select Date and Time</div>
            </div>
            <i class="material-icons chevron-icon">chevron_right</i>
          </div>

          <ng-container *ngIf="form.get('appointmentDate').value">
            <div class="hf-selector-container hf-selector-has-value hf-selector-black-color"
                 [routerLink]="['./selectDateAndTime']"
                 [queryParams]="{name: 'appointmentDate', initialValue: form.get('appointmentDate').value.toISOString()}">
              <div class="hf-selector-content-area">
                <div class="title">{{form.get('appointmentDate').value | date:'d MMM y, HH:mm'}}</div>
              </div>
              <i class="material-icons chevron-icon">chevron_right</i>
            </div>
          </ng-container>
        </div>

      </div>
      <div class="flex-space-between-layout native-mobile-date-time"
           [style.width.%]="100" style="margin-bottom: 12px;">
        <label>Date and Time</label>
        <hf-custom-native-mobile-datetime formControlName="appointmentDate" [invalid]="form.get('appointmentDate').errors?.minDate">
        </hf-custom-native-mobile-datetime>
      </div>

      <label>
        Type
      </label>
      <hf-custom-radio-checks-field [group]="form"
                                    [type]="'radio'"
                                    [elemId]="'type'"
                                    [dataObj]="appointmentTypes"
                                    marginElement>
      </hf-custom-radio-checks-field>

      <ng-container *ngIf="form.value.type == 'PHONE'">
        <ng-container *ngIf="!form.get('otherPhone').enabled">
          <label>Client Phone</label>
          <div class="client-phone">
            {{client.phonePrefix}} {{client.phone}}
          </div>
        </ng-container>

        <div *ngIf="!form.get('otherPhone').enabled" class="hf-selector">
          <div class="hf-selector-container" (click)="setDifferentPhoneNumber()">
            <div class="hf-selector-label-area">
              <i class="material-icons">add_circle</i>
              <div class="title">Use Different Phone Number</div>
            </div>

            <i class="material-icons chevron-icon">chevron_right</i>
          </div>
        </div>

        <div *ngIf="form.get('otherPhone').enabled"
             class="flex-space-between-layout phone-line-wrap"
             marginElement>
          <div class="flex-phone-prefix">
            <div class="hf-selector">
              <label>Phone</label>
              <div class="hf-selector-container hf-selector-has-value hf-selector-one-line"
                   [routerLink]="['./selectPhonePrefix']">
                <div class="hf-selector-content-area">
                  <div class="title">{{form.get('otherPhonePrefix').value}}</div>
                </div>
                <i class="material-icons chevron-icon">chevron_right</i>
              </div>
            </div>
          </div>

          <div class="flex-phone">
            <hfc-text-input type="tel" mask="0*" style="margin-bottom: 12px;" formControlName="otherPhone" hfcRemoveLeadingZero="otherPhonePrefix"></hfc-text-input>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="form.value.type == 'IN_PERSON'">
        <label>
          Location
          <hf-custom-radio-checks-field [group]="form"
                                        [type]="'radio'"
                                        [elemId]="'location'"
                                        [dataObj]="locationTypes"
                                        marginElement>
          </hf-custom-radio-checks-field>
        </label>

        <hf-custom-text-field
          *ngIf="form.get('address').enabled"
          [placeholder]="'Specify location'"
          type="text"
          [group]="form"
          inputFormControlName="address"
          fieldName="address"
          marginElement>
        </hf-custom-text-field>
      </ng-container>

      <label [style.width.%]="100" style="margin-top: 8px">
        Private Notes
      </label>
      <hf-textarea [group]="form.get('journal')"
                   [inputFormControlName]="'internalNotes'"
                   [smaller]="true"></hf-textarea>

      <hf-checkbox *ngIf="!data?.factFind && !noSubmitMode && form.get('sendFactFind').enabled" [formControl]="form.get('sendFactFind')" [label]="'Request Fact Find'"></hf-checkbox>

      <hf-textarea [group]="form.get('journal')"
                   [inputFormControlName]="'messageToClient'"
                   [smaller]="true"
                   [placeholder]="'Optional message to client'">
      </hf-textarea>

      <div *ngIf="form.get('journal.connectedDocument')"
           marginElement
           class="flex-space-between-layout"
           [style.width.%]="100">
        <hf-fileuploader controlName="attachment"
                         [group]="form.get('journal.connectedDocument')"
                         [fileControl]="form.get('journal.connectedDocument').value['attachment']">
        </hf-fileuploader>
      </div>

      <div *ngIf="form.get('appointmentDate')?.errors?.minDate" class="hf-invalid-error" style="margin-top: 12px">
        Appointment Date cannot be in the past.
      </div>
    </form>
  </div>

  <div *ngIf="!isLoading" class="template-footer">
    <hf-custom-button type="submit"
                      class="button-primary"
                      label="{{noSubmitMode ? 'Continue' : 'Send Invitation'}}"
                      [disabled]="form.invalid"
                      [isLoading]="isSubmitting"
                      (onClick)="onSubmit()">
    </hf-custom-button>

    <hf-custom-button type="button"
                      label="Cancel"
                      class="button-secondary"
                      (click)="onBack()">
    </hf-custom-button>

    <hf-custom-button *ngIf="editMode && !noSubmitMode"
                      type="button"
                      class="button-plain-icon delete-button keep-right"
                      icon="delete"
                      iconPos="center"
                      (onClick)="onCancelClicked()">
    </hf-custom-button>
  </div>
</div>

<div class="sidepane-child-anchor">
  <router-outlet></router-outlet>
</div>
