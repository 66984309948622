<div class="field-container">
  <hfc-field class="full">
    <hfc-agreebox [formControl]="form.get('_agree')">
      I confirm that all the information shown above is true and correct
    </hfc-agreebox>
  </hfc-field>
  <hfc-field label="Sign with" class="full">
    <hfc-checkbox-list [items]="[true, false]" [formControl]="form.get('_signWith')">
      <ng-template #itemTemplate let-item>
        <div>
            {{item ? 'Finger' : 'Text'}}
        </div>
      </ng-template>
    </hfc-checkbox-list>
  </hfc-field>
  <div style="width: 100%"></div>

  <hfc-field *ngIf="form.get('_signWith').value" label="Signature" [tooltipText]="showTooltip ? 'Please use your finger, mouse or track pad to sign in the box below. If you have trouble, switch to ‘Sign with Text’ instead.' : undefined">
    <hfc-signature-pad [formControl]="form.get('signature')"></hfc-signature-pad>
  </hfc-field>
  <hfc-field *ngIf="!form.get('_signWith').value" label="Type your surname to sign" tooltipText="Please type your surname with initials to sign">
    <hfc-text-input [formControl]="form.get('textSignature')"></hfc-text-input>
  </hfc-field>
</div>
