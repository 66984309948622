import {
  Component,
  Inject,
  InjectionToken,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ContactAttemptDTO } from "../../../models/contact-attempt.dto";
import { NotProceedingReasons, NotProceedingReasonsService, NotProceedingReasonUpdateData } from "../../../services/not-proceeding-reasons.service";
import { ToasterService } from "../../../services/toaster.service";
import {
  fadeAnimation,
  sidepaneMove,
} from "../../../shared/animations/sidepane-animation";
import { FileControlsConfigBuilder } from "../../../shared/fileuploader/utils/fileuploader.utils";
import { setControlEnabled } from "../../../utils/functions/set-control-enabled";
import {
  LOG_ATTEMPT_DIRECTION,
  LOG_ATTEMPT_SPOKEN_TO_TYPES,
  LOG_ATTEMPT_TYPE,
} from "../../../utils/variables.data";
import { JournalTabService } from "../../tabs/journal/journal-tab.service";
import { ArrangeAppointmentService } from "../arrange-appointment/arrange-appointment.service";
import { SelectDecliningReasonService } from "../select-declining-reason/select-declining-reason.service";
import { LogContactAttemptService } from "./log-contact-attempt.service";

export interface ContactAttemptAdder {
  addContactAttempt(
    contactAttemptDTO: ContactAttemptDTO,
    id: string
  ): Promise<void>;
}
export const LOG_CONTACT_ATTEMPT_COMPONENT_CONTACT_ATTEMPT_ADDER =
  new InjectionToken<ContactAttemptAdder>(
    "LOG_CONTACT_ATTEMPT_COMPONENT_CONTACT_ATTEMPT_ADDER"
  );

@Component({
  selector: "hf-log-contact-attempt",
  templateUrl: "log-contact-attempt.component.html",
  styleUrls: [
    "log-contact-attempt.component.scss",
    "../../../styles/sidepanes.partial.scss",
  ],
  animations: [sidepaneMove, fadeAnimation],
  host: { "[@sidepaneMove]": "true" },
})
export class LogContactAttemptComponent implements OnInit, OnDestroy {
  public logContactAttempt: FormGroup;
  public logContactAttemptTypes = LOG_ATTEMPT_TYPE;
  public logContactAttemptDirection = LOG_ATTEMPT_DIRECTION;
  public logContactAttemptSpokenToTypes = LOG_ATTEMPT_SPOKEN_TO_TYPES;
  public notProceedingReasons: NotProceedingReasons[];
  public selectedNotProceedingReason: NotProceedingReasons;
  public notProceedingReasonsData: NotProceedingReasonUpdateData;
  public isSubmitting = false;

  public get notProceedingReason() {
    return this.logContactAttempt.get("notProceedingReason").value;
  }

  public control(name: string) {
    return this.logContactAttempt.get(name);
  }

  public enabled(name: string) {
    return this.logContactAttempt.get(name).enabled;
  }

  public get value() {
    return this.logContactAttempt.value;
  }
  
  private id;
  private destroy$ = new Subject();
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toasterService: ToasterService,
    private journalTabService: JournalTabService,
    private notProceedingReasonsService: NotProceedingReasonsService,
    private arrangeAppointmentService: ArrangeAppointmentService,
    private logContactAttemptService: LogContactAttemptService,
    @Inject(LOG_CONTACT_ATTEMPT_COMPONENT_CONTACT_ATTEMPT_ADDER)
    private contactAttemptAdder: ContactAttemptAdder,
    private selectDecliningReasonService: SelectDecliningReasonService,
  ) {
    this.initForm();
    this.id = this.activatedRoute.snapshot.params.id;

    this.arrangeAppointmentService.appointmentSelected$
      .pipe(takeUntil(this.destroy$))
      .subscribe((appointment) => {
        this.logContactAttempt.get("appointment").setValue(appointment);
      });

    this.notProceedingReasonsService.notProceedingReasonSelected$.subscribe(
      (reason) => {
        this.logContactAttempt.get("notProceedingReason").setValue(reason);
      }
    );

    this.notProceedingReasonsService.notProceedingReasonDataUpdated$.subscribe(
      (data) => this.setNotProceedingReasonsData(data)
    );

    this.selectDecliningReasonService.followup$
      .pipe(takeUntil(this.destroy$))
      .subscribe(reason => {
        this.control("_declinedReason").patchValue(reason);
        this.control("declinedReasonId").patchValue(reason.followup.id);
        this.control("declinedReasonOther").patchValue(reason.other);
      });
  }

  private initForm() {
    this.logContactAttempt = this.fb.group({
      result: ["", Validators.required],
      direction: ["", Validators.required],
      subResult: [{ value: "", disabled: true }, Validators.required],
      otherSubResult: [{ value: "", disabled: true }, Validators.required],
      journal: this.fb.group({
        notifyIntroducer: [""],
        messageToIntroducer: ["", Validators.required],
        internalNotes: [""],
        messageToClient: [null],
        connectedDocument: this.fb.group({
          attachment: this.fb.group(
            new FileControlsConfigBuilder().maxFileSizeEmail().build()
          ),
        }),
      }),
      notProceedingReason: [""],
      notProceedingFollowUpReason: [""],
      reason: [""],
      reasonOther: [""],
      declinedReasonId: [null, Validators.required],
      declinedReasonOther: [""],
      _declinedReason: [""],
      sendFactFind: [false],
      appointment: [null],
    });

    this.prepareForm();

    if (this.logContactAttemptService.notifyIntroducerVisible) {
      this.logContactAttempt
        .get("journal.notifyIntroducer")
        .valueChanges.pipe(takeUntil(this.destroy$))
        .subscribe((value) => {
          if (value)
            this.logContactAttempt.get("journal.messageToIntroducer").enable();
          else
            this.logContactAttempt.get("journal.messageToIntroducer").disable();
        });
    } else {
      this.logContactAttempt.get("journal.notifyIntroducer").disable();
    }

    if (!this.logContactAttemptService.appointmentVisible) {
      this.logContactAttempt.get("appointment").disable();
    }
  }

  private prepareForm() {
    this.logContactAttempt.get("declinedReasonId").disable();
    this.logContactAttempt
      .get("journal.connectedDocument.attachment")
      .disable();
    this.logContactAttempt.get("journal.messageToIntroducer").disable();
    this.logContactAttempt.get("sendFactFind").disable();
  }

  ngOnInit() {
    this.initFormActions();
  }

  public onSelectDecliningReasonClicked() {
    this.selectDecliningReasonService.notProceedingFollowups = this.notProceedingReasonsData.notProceedingFollowups;

    this.router.navigate(["selectDecliningReason"], { relativeTo: this.route });
  }

  public setNotProceedingReasonsData(event: NotProceedingReasonUpdateData) {
    this.notProceedingReasonsData = event;

    setControlEnabled(this.control("declinedReasonId"), !!this.notProceedingReasonsData?.notProceedingFollowups?.length);
  }

  private initFormActions() {
    if (
      ["AWAITING_FIRST_CONTACT", "CONTACT_ATTEMPTED"].includes(
        this.logContactAttemptService.state
      )
    ) {
      this.logContactAttempt.get("result").valueChanges.subscribe((value) => {
        if (LOG_ATTEMPT_TYPE[value] == LOG_ATTEMPT_TYPE.SPOKEN_TO) {
          this.logContactAttempt.get("subResult").enable();
        } else {
          this.logContactAttempt.get("subResult").disable();
          this.logContactAttempt.get("otherSubResult").disable();
        }
      });
    }

    this.logContactAttempt.get("subResult").valueChanges.subscribe((value) => {
      if (
        LOG_ATTEMPT_SPOKEN_TO_TYPES[value] == LOG_ATTEMPT_SPOKEN_TO_TYPES.OTHER
      ) {
        this.logContactAttempt.get("otherSubResult").enable();
      } else {
        this.logContactAttempt.get("otherSubResult").disable();
      }
      if (
        LOG_ATTEMPT_SPOKEN_TO_TYPES[value] ==
        LOG_ATTEMPT_SPOKEN_TO_TYPES.PROCEEDING
      ) {
        this.logContactAttempt.get("sendFactFind").enable();
      } else {
        this.logContactAttempt.get("sendFactFind").disable();
      }
    });
  }
  public onNotifyClientSwitchChanged(notifyClient: boolean) {
    if (notifyClient) {
      this.logContactAttempt
        .get("journal.connectedDocument.attachment")
        .enable();
      this.logContactAttempt
        .get("journal.messageToClient")
        .setValidators(Validators.required);
    } else {
      this.logContactAttempt
        .get("journal.connectedDocument.attachment")
        .disable();
      this.logContactAttempt.get("journal.messageToClient").clearValidators();
    }

    this.logContactAttempt
      .get("journal.messageToClient")
      .updateValueAndValidity();
  }

  public onBack() {
    this.router.navigate(["./.."], { relativeTo: this.activatedRoute });
  }

  public async onSubmit() {
    this.isSubmitting = true;
    if (
      this.logContactAttempt.value.journal.connectedDocument &&
      !this.logContactAttempt.value.journal.connectedDocument.attachment
        .filename
    ) {
      (this.logContactAttempt.get("journal") as FormGroup).removeControl(
        "connectedDocument"
      );
    }

    // TODO: use cloneDeep
    const value = { ...this.logContactAttempt.value };

    if (this.notProceedingReasonsData) {
      value.notProceeding = this.notProceedingReasonsData;
      value.notProceeding.declinedReasonId = this.logContactAttempt.get("declinedReasonId").value || null;
        value.notProceeding.declinedReasonOther =
      this.logContactAttempt.get("declinedReasonOther").value || null;

    }

    // deleting these from form as they were added earlier only to leverage functions of reactive forms
    delete value.reasonOther;
    delete value.notProceedingFollowUpReason;
    delete value.notProceedingReason;
    delete value.declinedReasonOther;
    delete value.notProceedingReason;

    if (value.appointment) {
      value.appointment = { ...value.appointment };
    }

    await this.contactAttemptAdder
      .addContactAttempt(value, this.id)
      .then(() => {
        this.toasterService.callToaster({
          severity: "info",
          summary: "Info",
          detail: `Contact Attempt successfully logged`,
        });
        this.logContactAttemptService.contactAttemptAdded$.emit();
        this.journalTabService.reloadJournalTab.emit();
        this.onBack();
      })
      .catch((error) => {
        this.toasterService.callToaster({
          severity: "error",
          summary: "Error",
          detail: `${error.error.error.message}`,
        });
        const appointment = this.logContactAttempt.get("appointment").value;
        if (appointment) {
          appointment.appointmentDate = moment(
            appointment.appointmentDate
          ).toDate();
          this.logContactAttempt.get("appointment").patchValue(appointment);
        }
        console.log(this.logContactAttempt.value);
      })
      .finally(() => (this.isSubmitting = false));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.notProceedingReasonsService.setNotProceedingReasonSelected(undefined);
  }

  onArrangeAppointmentClicked() {
    this.arrangeAppointmentService.noSubmitMode = true;
    this.arrangeAppointmentService.initialAppointment =
      this.logContactAttempt.get("appointment").value;

    this.arrangeAppointmentService.currentAppointment = undefined;
    this.arrangeAppointmentService.currentAdvisor =
      this.logContactAttemptService.currentAdvisor;
    this.arrangeAppointmentService.client =
      this.logContactAttemptService.client;
    this.arrangeAppointmentService.isFactFindRequestAllowed = false;

    this.router.navigate(["arrangeAppointment"], {
      relativeTo: this.activatedRoute,
    });
  }
}
