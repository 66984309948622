import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from "../shared.module";
import { CustomButtonModule } from '../custom-button/custom-button.module';
import { InformationBoxComponent } from './information-box.component';

@NgModule({
  declarations: [
    InformationBoxComponent
  ],
  imports: [
    CommonModule,
    CustomButtonModule,
    SharedModule
  ],
  exports: [
    InformationBoxComponent
  ],
  providers: []
})

export class InformationBoxModule {

}
