import { Injectable } from "@angular/core";
import { ParamMap } from "@angular/router";
import { Subject } from "rxjs";

import { MortgageApiService } from "../../../services/mortgage-api.service";
import { EditReferenceService } from "../../../shared-layout/side-panes/edit-reference/edit-reference.component";

@Injectable()
export class MortgageEditReferenceService implements EditReferenceService {
  referenceChange$ = new Subject<void>();
  heading = "Lender";
  reference: string;

  constructor(private mortgageApiService: MortgageApiService) { }

  changeReference(paramMap: ParamMap, reference: string): Promise<void> {
    return this.mortgageApiService.changeLenderReference(paramMap.get("id"), paramMap.get("handoverId"), reference);
  }
}
