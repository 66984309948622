import { EventEmitter, Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { getUkPhoneValidator } from '../../../../../projects/client/src/app/shared/functions/uk-phone.validator';
import { ApiService } from "../../services/api.service";
import { ValidatorsService } from "../../services/validators.service";

@Injectable()
export class ClientsService {
  public addClientForm: FormGroup;
  public updateInfo = new EventEmitter();
  public isEditing:boolean;
  public initialStateBeforeEdit;

  constructor(private fb:FormBuilder,
              private apiService:ApiService,
              private validatorsService:ValidatorsService) {
  }

  public initClientsForm(clientId?) {
    this.addClientForm = this.fb.group({
      'firstName': ['', Validators.required],
      'lastName': ['', Validators.required],
      'email': ['',{validators: Validators.required, asyncValidators: this.validatorsService.validateEmailAddressExist({userType: "CLIENT", userId:clientId}), updateOn: "blur"}],
      'phone': ['', [Validators.required, getUkPhoneValidator()]],
      'phonePrefix': ['+44', Validators.required]
    });
  }
}
