<div (click)="onBack()" class="overlay" [@fadeAnimation]></div>

<div class="sidepane-content">
  <div class="flex-space-between-layout template-header">
    <h1>Compliance Approval</h1>
    <hf-custom-button type="button" class="button-plain-icon" icon="close" iconPos="center" (onClick)="onBack()">
    </hf-custom-button>
  </div>

  <div class="template-main">
    <form [formGroup]="form">
      <label>Optional Notes</label>
      <hf-textarea [group]="form" inputFormControlName="value"></hf-textarea>
    </form>
  </div>

  <div class="template-footer">
    <hf-custom-button type="submit"
                      class="button-primary"
                      label="Approve"
                      [disabled]="form.invalid"
                      [isLoading]="isSubmitting"
                      (onClick)="onSubmit()">
    </hf-custom-button>

    <hf-custom-button type="button"
                      class="button-secondary"
                      label="Cancel"
                      (onClick)="onBack()">
    </hf-custom-button>
  </div>
</div>

