import {Component, Inject, OnDestroy} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {Subject} from "rxjs";
import {take, takeUntil} from "rxjs/operators";
import {DocumentThumbnail} from "../../../../../../../projects/client/src/app/shared/components/document-thumbnail/document-thumbnail.component";
import {Confirmation, DialogService} from "../../../../../../../projects/client/src/app/shared/services/dialog.service";
import { ToastService } from "../../../../../../../projects/client/src/app/shared/services/toast.service";
import {Attachment} from "../../../../models/attachment";
import {MortgageDashboardHandover} from "../../../../models/mortgage-dashboard";
import { NewComplianceHandoverDTO } from "../../../../models/new-compliance-handover.dto";
import {ComplianceHandoverDataWithHistory, MortgageApiService} from "../../../../services/mortgage-api.service";
import {ToasterService} from "../../../../services/toaster.service";
import { Twenty7tecApiService } from "../../../../services/twenty7tec-api.service";
import {EditReferenceService, EDIT_REFERENCE_SERVICE} from "../../../../shared-layout/side-panes/edit-reference/edit-reference.component";
import {COMPLIANCE_HANDOVER_STATE, COMPLIANCE_HANDOVER_STATE_NAME, DEBT_CONSOLIDATION_EXPLANATION_NAME, MORTGAGE_OBJECTIVE_NAME, MORTGAGE_PRODUCT_DATA_TYPE_NAME, MORTGAGE_STATE_TYPE} from "../../../../utils/variables.data";
import {MortgagesService, ReloadMortgageDashboardEvent} from "../../mortgages.service";
import { isToComplianceVisible } from "../../new-compliance-handover/new-compliance-handover.component";

@Component({
  selector: 'hf-mortgage-dashboard-client-sheet',
  templateUrl: './mortgage-dashboard-client-sheet.component.html',
  styleUrls: ['./mortgage-dashboard-client-sheet.component.scss']
})
export class MortgageDashboardClientSheetComponent implements OnDestroy {
  public MORTGAGE_PRODUCT_DATA_TYPE_NAME = MORTGAGE_PRODUCT_DATA_TYPE_NAME;
  public MORTGAGE_OBJECTIVE_NAME = MORTGAGE_OBJECTIVE_NAME;
  public DEBT_CONSOLIDATION_EXPLANATION_NAME = DEBT_CONSOLIDATION_EXPLANATION_NAME;
  public COMPLIANCE_HANDOVER_STATE_NAME = COMPLIANCE_HANDOVER_STATE_NAME;

  public data: ComplianceHandoverDataWithHistory;
  public handover: MortgageDashboardHandover;
  public isLoading = false;
  public isLegacy = true;
  public isSubmitting = false;
  public isApplySubmitting = false;

  public get mortgageId(): string { return this.route.snapshot.paramMap.get("id") }
  public get handoverId(): string { return this.handover?.id }
  public get handovers(): MortgageDashboardHandover[] { return this.mortgagesService.data.handovers }
  public get isAddClientSheetVisible() { return !this.handovers?.find(h => h.handoverState === "DRAFT") }

  private destroy$ = new Subject();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private mortgageApiService: MortgageApiService,
    private mortgagesService: MortgagesService,
    @Inject(EDIT_REFERENCE_SERVICE) private editReferenceService: EditReferenceService,
    private dialogService: DialogService,
    private toasterService: ToasterService,
    private toast: ToastService,
    private twenty7tec: Twenty7tecApiService,
  ) {
    this.editReferenceService.referenceChange$.pipe(takeUntil(this.destroy$))
      .subscribe(() => this.mortgagesService.reloadMortgageDashboard.next());

    this.mortgagesService.reloadMortgageDashboard.pipe(takeUntil(this.destroy$))
      .subscribe(event => this.initialize(event));

    this.mortgagesService.mortgageDashboardDataLoaded.pipe(take(1))
      .subscribe(() => this.initialize());
  }

  public onAddClientSheetClicked() {
    this.router.navigate(["newComplianceHandover/new"], { relativeTo: this.route });
  }

  public onHandoverChange() {
    this.initialize();
  }

  public getNetMontlyIncome(applicantId: string) {
    const monthlyIncome = this.data.netMonthlyIncomes.find(i => i.applicantId == applicantId);
    return monthlyIncome && monthlyIncome.amount;
  }

  public yesno(value: boolean) {
    return value
      ? "Yes"
      : "No"
  }

  public getDocumentThumbnail(attachment: Attachment, customType: string): DocumentThumbnail {
    return {
      file: attachment,
      customType
    };
  }

  public onEditClicked() {
    this.router.navigate(["./newComplianceHandover", this.handoverId], { relativeTo: this.route });
  }

  public onApproveClicked() {
    this.router.navigate(["./approveCompliance", this.handover.id], { relativeTo: this.route });
  }

  public async onUndoApproveClicked() {
    const confirmation: Confirmation = {
      title: "Undo Approval",
      message: "Are you sure you want to undo the compliance approval? This will enable editing of the Client Sheet again.",
      acceptLabel: "Undo Approval",
      acceptButtonType: "primary"
    }

    const result = await this.dialogService.confirm(confirmation);
    if (!result) {
      return;
    }

    try {
      await this.mortgageApiService.undoComplianceHandoverApproval(this.mortgageId, this.handoverId);

      this.mortgagesService.reloadMortgageDashboard.emit();
      this.toasterService.callToaster({ severity: 'info', summary: 'Info', detail: 'Compliance approval undone.' });
    } catch (err) {
      this.toasterService.callToaster({ severity: 'error', summary: 'Error', detail: `Failed to undo compliance approval. ${err.error.error.message}` });
    }
  }

  public onEditLenderReferenceClicked() {
    this.editReferenceService.reference = this.data.lenderReference;
    this.editReferenceService.regex = this.handover.lenderRefRegex;
    this.editReferenceService.backPath = "../..";

    this.router.navigate(["editLenderReference", this.handoverId], { relativeTo: this.route })
  }

  public onToComplianceClicked() {
    this.isSubmitting = true;

    this.mortgageApiService.completeHandover(this.mortgageId, this.handoverId).subscribe(
      () => {
        this.isSubmitting = false;
        this.mortgagesService.reloadMortgageDashboard.emit();
        this.toast.add("Handover send for approval");
      },
      e => {
        this.isSubmitting = false;
        this.toast.add("Error: ", "error", e);
      }
    );
  }

  public get isEditLenderReferenceVisible() {
    const states: MORTGAGE_STATE_TYPE[] = ["SUBMITTED", "OFFERED", "COMPLETED"];

    return this.mortgagesService.data && states.includes(this.mortgagesService.data.state);
  }

  public isToComplianceVisible() { 
    return isToComplianceVisible(this.data as any);
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }

  public onApplyClicked() {
    this.isApplySubmitting = true;

    this.twenty7tec.syncApplicationData(this.handoverId).subscribe(
      r => {
        this.isApplySubmitting = false;

        if (!r.successful) {
          this.toast.add(r.error, "error");
          return;
        }

        window.open(r.redirectUrl, "_blank");
      },
      e => {
        this.isApplySubmitting = false;
        this.toast.add("Error: ", "error", e);
      }
    );
  }

  private async initialize(event?: ReloadMortgageDashboardEvent) {
    if (event?.handover) {
      this.handover = event.handover;

      const dto: NewComplianceHandoverDTO = this.handover as any;
      if (dto.recommended?.lender?.name) {
        this.handover.lender = dto.recommended.lender.name;
        this.handover.lenderLogo = dto.recommended.lender.logo;
      }
    }
    else if (!this.handover) {
      this.handover = this.handovers[0];
    }

    this.isLoading = true;

    // this.data = await this.mortgageApiService.viewClientSheet(this.mortgageId, this.handoverId);
    // this.data = await this.mortgageApiService.viewNewClientSheet(this.mortgageId, this.handoverId).toPromise() as any;
    const newOrLegacy = await this.mortgageApiService.viewNewOrLegacyClientSheet(this.mortgageId, this.handoverId).toPromise();

    this.data = (newOrLegacy.handover || newOrLegacy.newHandover) as any;
    this.isLegacy = !!newOrLegacy.handover;

    this.isLoading = false;
  }
}
