import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { slideUpBackdropAnimation } from '../../../../../projects/client/src/app/shared/animations/slide-up-backdrop.animation';

@Component({
  selector: 'hf-sidepane-navigation',
  templateUrl: './sidepane-navigation.component.html',
  styleUrls: ['./sidepane-navigation.component.scss'],
  animations: slideUpBackdropAnimation,
})
export class SidepaneNavigationComponent implements OnChanges {
  @Input()
  public inputItemTemplate: TemplateRef<any>;

  @Input()
  public items: any[];

  @Output()
  public itemClicked = new EventEmitter<any>();

  @Input()
  public selectedItem: any;

  @Input()
  public clickCallback: () => boolean;

  public popupVisible = false;

  public onItemClicked(item: any) {
    this.selectedItem = item;
    this.popupVisible = false;
    this.itemClicked.next(item);
  }

  public ngOnChanges(changes: SimpleChanges) {
    // if ("items" in changes) {
    //   const items = changes["items"].currentValue;
    //   if (items) {
    //     this.selectedItem = items[0];
    //   }
    // }
  }
}
