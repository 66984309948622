import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { dateToIso } from '../../../../../../projects/client/src/app/fact-find/services/fact-find-api.service';
import { expandAnimation } from '../../../../../../projects/client/src/app/shared/animations/expand.animation';
import { FilteringItemType, FilteringRequest } from '../../../classes/filtering-service.class';
import { RemortgageFilteringService } from '../../../modules/remortgage/services/remortgage-filtering.service';
import { FilterComposerComponent } from '../filter-composer.component';
import { take } from 'rxjs/operators';

@Component({
  selector: 'hf-filter-composer-date',
  templateUrl: './filter-composer-date.component.html',
  styleUrls: ['./filter-composer-date.component.scss'],
  animations: [expandAnimation],
})
export class FilterComposerDateComponent implements OnInit {
  @Input()
  public defs: { type: FilteringItemType, label: string }[];

  @Input()
  public heading: string;

  public isOpen = false;
  public numOfSelected: number;
  public form: FormArray;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private remortgageFilteringService: RemortgageFilteringService,
    private filterComposer: FilterComposerComponent,
  ) { }

  public onSelectDateClicked(type: FilteringItemType) {
  }

  ngOnInit(): void {
    this.form = new FormArray(this.defs.map(i => {
      const control = new FormControl();

      control.valueChanges.subscribe(date => {
        const value = dateToIso(date);
        const filter: FilteringRequest = { type: i.type, values: [value] };

        this.filterComposer.onFilterChange(filter);
      });

      return control;
    }));

    this.defs.forEach((def, i) => {
      this.remortgageFilteringService.getActiveFilteringItemsByType(def.type).pipe(take(1)).subscribe(items => {
        if (items.length) {
          const date = new Date(items[0].value as any);
          this.form.at(i).setValue(date);
        }
      });
    });
  }
}
