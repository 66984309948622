import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  fadeAnimation,
  sidepaneMove,
} from "../../../shared/animations/sidepane-animation";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PUSH_TO_MIDAS_TYPE } from "../../../../../../projects/client/src/app/fact-find/enums.module";
import { MortgagesService } from "../../../modules/mortgages/mortgages.service";
import { ProtectionDashboardService } from "../../../modules/protection/components/protection-dashboard/protection-dashboard.service";
import { ActivatedRoute, Router } from "@angular/router";
import { MortgageApiService } from "../../../services/mortgage-api.service";
import { ProtectionApiService } from "../../../services/protection-api.service";
import { ToasterService } from "../../../services/toaster.service";
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: "hf-push-to-midas",
  templateUrl: "./push-to-midas.component.html",
  styleUrls: [
    "./push-to-midas.component.scss",
    "../../../styles/sidepanes.partial.scss",
  ],
  animations: [sidepaneMove, fadeAnimation],
  host: { "[@sidepaneMove]": "true" },
})
export class PushToMidasComponent implements OnInit, OnDestroy {
  public mortgagePushToMidasForm: FormGroup;
  public PUSH_TO_MIDAS_TYPE = PUSH_TO_MIDAS_TYPE;

  private destroy$ = new Subject();

  constructor(
    private readonly fb: FormBuilder,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly mortgagesApiService: MortgageApiService,
    private readonly protectionApiService: ProtectionApiService,
    private readonly toasterService: ToasterService,
  ) {
    this.mortgagePushToMidasForm = this.fb.group({
      type: ["STANDARD"],
      successfullyExported: [false],
    });
  }

  get path(): "protection" | "mortgages" {
    return this.isOnProtectionPage ? "protection" : "mortgages";
  }

  get id(): string {
    return this.activatedRoute.snapshot.params.id;
  }

  get selectedType() {
    return this.mortgagePushToMidasForm.get("type");
  }

  get successfullyExported() {
    return this.mortgagePushToMidasForm.get("successfullyExported");
  }

  private get isOnProtectionPage(): boolean {
    return this.router.url.includes("protection");
  }

  onSubmit(): void {
    const selectedType = this.selectedType.value as PUSH_TO_MIDAS_TYPE;
    const isSuccessFullyExported = this.successfullyExported.value;

    if (this.isOnProtectionPage) {
      if (isSuccessFullyExported) {
        this.protectionApiService
          .markAsSuccessfulMidasExport(this.id)
          .subscribe(
            () =>
              this.onSuccessMidasAction(
                `This case has been marked as <br />successfully exported to Midas`,
              ),
            () => this.onFailMidasAction(),
          );
      } else {
        this.protectionApiService.pushToMidas(selectedType, this.id).subscribe(
          () =>
            this.onSuccessMidasAction(
              `Manual push attempted - check the<br />journal in a few minutes`,
            ),
          () => this.onFailMidasAction(),
        );
      }
    } else {
      if (isSuccessFullyExported) {
        this.mortgagesApiService.markAsSuccessfulMidasExport(this.id).subscribe(
          () =>
            this.onSuccessMidasAction(
              `This case has been marked as <br />successfully exported to Midas`,
            ),
          () => this.onFailMidasAction(),
        );
      } else {
        this.mortgagesApiService.pushToMidas(selectedType, this.id).subscribe(
          () =>
            this.onSuccessMidasAction(
              `Manual push attempted - check the<br />journal in a few minutes`,
            ),
          () => this.onFailMidasAction(),
        );
      }
    }
  }

  ngOnInit(): void {
    this.observeFOrmChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private onSuccessMidasAction(message: string): void {
    this.toasterService.callToaster({
      severity: "info",
      detail: message,
    });
    this.router.navigate(["./.."], { relativeTo: this.activatedRoute });
  }

  private onFailMidasAction(): void {
    this.toasterService.callToaster({
      severity: "error",
      detail: "Failed to complete Midas action. Please try again later.",
    });
  }

  private observeFOrmChanges(): void {
    this.successfullyExported.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((checked: boolean) => {
        if (checked) {
          this.selectedType.reset(null, { emitEvent: false });
        } else {
          this.selectedType.setValue("STANDARD", { emitEvent: false });
        }
      });

    this.selectedType.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        if (value) {
          this.successfullyExported.setValue(false, { emitEvent: false });
        } else {
          this.selectedType.setValue("STANDARD", { emitEvent: false });
        }
      });
  }
}
