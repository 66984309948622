<section class="section">
  <div class="table-page-container">
    <div class="search-box-line">
      <div class="search-bar-wrap">
        <hf-custom-text-field type="text"
                              [searchMode]="true"
                              placeholder="Search documents …"
                              fieldName="input12"
                              icon="search"
                              preventValidation="true">
        </hf-custom-text-field>
      </div>

      <hf-custom-button type="button"
                        class="button-plain-icon for-phone-show"
                        icon="more_horiz"
                        iconPos="center"
                        (click)="dashboardHeaderButtons.classList.add('dashboard-headers-buttons-visible')">
      </hf-custom-button>
    </div>

    <div class="utils-area dashboard-header-buttons"
         #dashboardHeaderButtons
         (click)="dashboardHeaderButtons.classList.remove('dashboard-headers-buttons-visible')">

      <div class="pagination-area">
        <hf-item-per-page-selector>
        </hf-item-per-page-selector>
      </div>

      <hf-documents-zip-button [params]="downloadZipParams"></hf-documents-zip-button>
    </div>

    <div class="everglades-table"
         hfTable>
      <div class="everglades-table-headings">
        <div [sortBy]="'originalFilename'"><span>Name</span></div>
        <div [sortBy]="'size'" class="width-80 right"><span>Size</span></div>
        <div [sortBy]="'type'" class="width-250"><span>Type</span></div>
        <div [sortBy]="'updatedBy'" class="width-160"><span>Uploaded By</span></div>
        <div *ngIf="showApplication" class="width-100"><span>Application</span></div>
        <div [sortBy]="'sendToClient'" class="width-170"><span>Status</span></div>
        <div [sortBy]="'createdDate'" [isDefault]="true" [defaultDirection]="'DESC'" class="width-160"><span>Timestamp</span></div>
      </div>

      <a *ngFor="let row of data"
         (click)="viewDocumentEntry(row)">
        <div class="table-row">
          <div data-mobileTitle="Name" style="word-break: break-all;">
            <ng-container *ngIf="row.feo_status === 'REQUESTED'">Awaiting file from {{row.requestedFrom}}</ng-container>
            <ng-container *ngIf="row.feo_status !== 'REQUESTED'">{{row.attachment?.originalFilename}}</ng-container>
          </div>
          <div data-mobileTitle="Size" class="width-80 right">{{ row.size | size }}</div>
          <div data-mobileTitle="Type" class="width-250">{{row.type ? getDocumentType(row) : '--'}}</div>
          <div data-mobileTitle="Uploaded By" class="width-160">{{ row.updatedBy }}</div>
          <div *ngIf="showApplication" data-mobileTitle="Application" class="width-100">
            <hf-attachment-image *ngIf="row.application" [attachment]="row.application.lenderLogo" [alt]="row.application.lender" [hfTooltip]="row.application.lenderReference" class="provider-logo"></hf-attachment-image>
          </div>
          <div data-mobileTitle="Status" class="width-170">{{ DOCUMENT_REQUEST_STATUS_NAME.get(row.feo_status) }}</div>
          <div data-mobileTitle="Timestamp" class="width-160">{{row.createdDate | date:'d MMM y, HH:mm'}}</div>
        </div>
      </a>
    </div>

    <div class="pagination-row">
      <hf-pagination-summary-box></hf-pagination-summary-box>
      <hf-paginator></hf-paginator>
    </div>
  </div>
</section>
