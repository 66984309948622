import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { USER_TYPE } from "../../../utils/variables.data";

@Injectable()
export class SelectUserTypeService {
  public typeSelected$ = new Subject<USER_TYPE>();

  public allowedUserTypes?: USER_TYPE[];
}
