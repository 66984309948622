import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import {HttpClient} from '@angular/common/http';
import {User} from '../models';

export interface TwoFactorMobileNumber {
  prefix: string;
  number: string;
}

export interface SmsVerification {
  lastMobileDigits: string;
}

@Injectable()
export class AuthenticationService {
  private apiName = "servlet/auth/";
  private legacyApiName = "auth";
  private apiService: ApiService;

  constructor(
    private httpClient: HttpClient,
    private legacyApiService: ApiService,
  ) {
    this.apiService = new ApiService(this.httpClient);
    this.apiService.rawApiURL = this.apiService.apiURL;
    this.apiService.apiVersion = "";
  }

  public checkLogin(body: any) {
    return this.apiService.post(this.apiName, 'checkLogin', body).toPromise();
  }

  public checkSigned() {
    return this.apiService.get(this.apiName, 'checkSigned').toPromise();
  }

  public logOut() {
    return this.apiService.get(this.apiName, 'logOut').toPromise();
  }

  public forgottenPassword(username) {
    return this.legacyApiService.post(this.legacyApiName, 'forgottenPassword', { username: username }).toPromise();
  }

  public getResetPasswordInfo(token) {
    return this.legacyApiService.get(this.legacyApiName, 'getResetPasswordInfo/' + token).toPromise();
  }

  public resetPassword(token, body) {
    return this.legacyApiService.post(this.legacyApiName, 'resetPassword/' + token, body).toPromise();
  }

  public getInvitationData(token) {
    return this.legacyApiService.get(this.legacyApiName, 'getInvitationData/' + token).toPromise();
  }

  public setPassword(token, body) {
    return this.legacyApiService.post(this.legacyApiName, 'setPassword/' + token, body).toPromise();
  }

  public changePassword(body) {
    return this.legacyApiService.post(this.legacyApiName, 'changePassword', body).toPromise();
  }

  public setupMobileNumber(mobileNumber: TwoFactorMobileNumber): Promise<void> {
    return this.apiService.post(this.apiName, "setupMobileNumber", mobileNumber).toPromise();
  }

  public verifySmsCode(code: string): Promise<User> {
    const params = { code };
    return this.apiService.get(this.apiName, "verifySmsCode", { params }).toPromise();
  }

  public requestNewSmsCode(): Promise<void> {
    return this.apiService.get(this.apiName, "requestNewSmsCode").toPromise();
  }

  public cancel2fa(): Promise<void> {
    return this.apiService.get(this.apiName, "cancel2fa").toPromise();
  }

  public setup2fa(): Promise<void> {
    return this.apiService.get(this.apiName, "setup2fa", { responseType: "blob" }).toPromise();
  }

  public verify2faCode(code: string): Promise<User> {
    const params = { code };
    return this.apiService.get(this.apiName, "verify2faCode", { params }).toPromise();
  }

  public requestSmsVerification(): Promise<SmsVerification> {
    return this.apiService.get(this.apiName, "requestSmsVerification").toPromise();
  }
}
