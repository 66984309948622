<div (click)="onBack()" class="overlay" [@fadeAnimation]></div>

<div class="sidepane-content">
  <div class="flex-space-between-layout template-header">
    <h1>Request Document</h1>
    <hf-custom-button type="button" class="button-plain-icon" icon="close" iconPos="center" (onClick)="onBack()">
    </hf-custom-button>
  </div>

  <div class="template-main">
    <form [formGroup]="form">
      <hf-custom-selector formControlName="clientId"
                          [items]="clients"
                          [compareWith]="compareFn"
                          [filterWith]="filterFn"
                          title="Select client"
                          filterPlaceholder="Search client …">
        <ng-template #itemTemplate let-item>
          <div>
            {{item.fullName}}
          </div>
        </ng-template>
      </hf-custom-selector>
    </form>
  </div>
</div>
