import { CurrencyPipe, DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ListMortgagesParams, MortgageApiService, MortgageListItem, MortgageQuickFilter } from '../../../services/mortgage-api.service';
import { UserService } from '../../../services/user.service';
import { DataSourceFactory, TableSettingsService, TABLE_PARAMS_STORAGE_KEY } from '../../../shared/table-utils/table-settings.service';
import { APPLICATION_STATE_NAMES, CLIENT_CATEGORY_NAME, FACT_FIND_STATE_NAMES, MORTGAGE_ACTIONS, MORTGAGE_WAITING_ON, PROCEEDING_STATE_TYPE_NAME, USER_TYPE_NAMES, HELP_TO_BUY_STATUS_NAMES, ValuationStateName, USER_TYPE } from '../../../utils/variables.data';
import { MortgageListRouteData } from '../mortgages.routing';
import {QualificationTooltipComponent} from './qualification-tooltip/qualification-tooltip.component';

type ColumnVisibilityDefinition = true|MortgageQuickFilter[];

const columnVisibility: Map<string, ColumnVisibilityDefinition> = new Map<string, ColumnVisibilityDefinition>([
  ["clientCategory", true],
  ["advisor", true],
  ["client", true],
  ["reference", true],
  ["introducer", true],
  ["lastAction", true],
  ["tasksAll", true],
  ["state", true],
  ["proceedingState", ["INITIAL"]],
  ["createdDate", ["INITIAL", "ACTIVE"]],
  ["appointmentDate", ["INITIAL"]],
  ["factFindState", ["ACTIVE"]],
  ["lenderReference", ["SUBMITTED", "OFFERED", "COMPLETED"]],
  ["lender", ["SUBMITTED", "OFFERED", "COMPLETED"]],
  ["submittedDate", ["SUBMITTED", "OFFERED"]],
  ["valuationState", ["SUBMITTED"]],
  ["helpToBuyStatus", ["SUBMITTED", "OFFERED"]],
  ["offerExpiryDate", ["OFFERED"]],
  ["completionDate", ["COMPLETED"]],
  ["productEndDate", ["COMPLETED"]],
])

const introducerColumnVisibility: Map<string, ColumnVisibilityDefinition> = new Map<string, ColumnVisibilityDefinition>([
  ["clientCategory", true],
  ["advisor", true],
  ["client", true],
  ["createdDate", true],
  ["division", true],
  ["reference", true],
  ["caseProgressor", ["INTRODUCER_LIVE", "INTRODUCER_COMPLETED"]],
  ["factFindState", ["INTRODUCER_LIVE", "INTRODUCER_ACTIVE"]],
  ["lender", ["INTRODUCER_LIVE"]],
  ["submittedDate", ["INTRODUCER_LIVE"]],
  ["offerExpiryDate", ["INTRODUCER_LIVE"]],
  ["nextHeronTaskDate", ["INTRODUCER_LIVE"]],
  ["helpToBuyStatus", ["INTRODUCER_LIVE"]],
  ["qualificationStatus", ["INTRODUCER_ACTIVE"]],
  ["state", true],
]);

export function getMonthAgo() {
  const now = new Date();
  now.setDate(now.getDate() - 28);

  return now;
}

export function getInWeeks(weeks: number) {
  const now = new Date();
  now.setDate(now.getDate() + weeks * 7);

  return now;
}

@Component({
  selector: 'hf-mortgages-list',
  templateUrl: 'mortgages-list.component.html',
  styleUrls: ['mortgages-list.component.scss'],
  providers: [TableSettingsService, CurrencyPipe, { provide: TABLE_PARAMS_STORAGE_KEY, useValue: "app/mortgages" }]
})

export class MortgagesListComponent implements OnDestroy, AfterViewInit {
  public FACT_FIND_STATE_NAMES = FACT_FIND_STATE_NAMES;
  public APPLICATION_STATE_NAMES = APPLICATION_STATE_NAMES;
  public PROCEEDING_STATE_TYPE_NAME = PROCEEDING_STATE_TYPE_NAME;
  public CLIENT_CATEGORY_NAME = CLIENT_CATEGORY_NAME;
  public HELP_TO_BUY_STATUS_NAMES = HELP_TO_BUY_STATUS_NAMES;
  public ValuationStateName = ValuationStateName;

  public data: MortgageListItem[];
  public dateFormat = "d MMM y";
  public tooltipDateFormat = "E dd MMM y H:mm";
  // public assignedToMe: boolean = this.user.userType === "CASE_PROGRESSION" || this.userService.isAdvisor();
  public assignedToMe: boolean = this.routeData.defaultAssignedToMe || false;
  public qualificationTooltip = QualificationTooltipComponent;

  public get quickFilter(): MortgageQuickFilter { return this.routeData.mortgageQuickFilter };
  public get routeData() { return this.route.snapshot.data as MortgageListRouteData };
  public get user() { return this.userService.getUser() }
  public get introducerType() { return this.userService.getUser().introducer.type };

  private now = new Date();
  private monthAgo = getMonthAgo();
  private in6Weeks = getInWeeks(6);
  private in4Weeks = getInWeeks(4);
  private destroy$ = new Subject();

  constructor(
    private mortgageApiService: MortgageApiService,
    private tableSettingsService: TableSettingsService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private currencyPipe: CurrencyPipe,
    private datePipe: DatePipe,
  ) {
    this.tableSettingsService.composeAndSetupTableParamsStorageKey(this.router);

    this.tableSettingsService.data$
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => { this.data = data; });
  }

  public isColumnVisible(name: string) {
    const isIntroducer = this.userService.isIntroducer();
    const definition = isIntroducer
      ? introducerColumnVisibility.get(name)
      : columnVisibility.get(name);

    if (!definition) {
      return false;
    }

    return definition === true || definition.includes(this.quickFilter);
  }

  public onAssignedToMeChanged() {
    this.tableSettingsService.refresh();
  }

  public isOfferExpiryDateOld(row: MortgageListItem): boolean {
    const offerExpiryDate = new Date(row.offerExpiryDate);

    if (this.quickFilter === "OFFERED") {
      return offerExpiryDate.getTime() < this.in4Weeks.getTime();
    }

    return offerExpiryDate.getTime() < this.in6Weeks.getTime();
  }

  public isSubmittedDateOld(row: MortgageListItem): boolean {
    if (this.quickFilter === "OFFERED") {
      return false;
    }

    const submittedDate = new Date(row.submittedDate);

    return submittedDate.getTime() < this.monthAgo.getTime();
  }

  public isAppointmentPrevious(row: MortgageListItem): boolean {
    const appointmentDate = new Date(row.appointmentDate);

    return appointmentDate.getTime() < this.now.getTime();
  }

  public getLastActionTooltip(row: MortgageListItem): string {
    return `${this.datePipe.transform(row.lastAction, this.tooltipDateFormat)}<br>${row.lastActionBy} (${USER_TYPE_NAMES.get(row.lastActionByUsertype)})`;
  }

  public getTasksDueTooltip(row: MortgageListItem): string {
    return `${row.tasksDue} task due`;
  }

  public getIntroducerTooltip(row: MortgageListItem): string {
    return row.selfReferral
      ? `${row.introducer} (Self Referred)`
      : row.introducer;
  }

  public getClientTooltip(row: MortgageListItem): string {
    return row.selfReferral
      ? "Self Referred"
      : null;
  }

  public getReference(row: MortgageListItem): string {
    return [row.yourkeysReferenceId, row.reference]
      .filter(v => !!v)
      .join(" - ");
  }

  public getNextActionType(type) {
    return MORTGAGE_ACTIONS[type];
  }

  public getWaitingOnType(type) {
    return MORTGAGE_WAITING_ON[type];
  }

  public isIntroducer() {
    return this.userService.isIntroducer();
  }

  public qualificationValue(row) {
    if (row.referralType === "OFFER_QUALIFICATION") {
      if (row.qualificationStatus === "PARTIALLY_QUALIFIED" || row.qualificationStatus === "QUALIFIED") {
        return this.currencyPipe.transform(row.qualifiedUpTo || row.desiredQualification, "GBP", undefined, "1.0-0");
      }

      if (row.qualificationStatus === "NOT_QUALIFIED") {
        return "Not qualified";
      }

      return "Awaiting";
    }

    return "";
  }

  public openMortgageDashboard(row) {
    if (row.introducerLinkBroken) {
      return;
    }

    this.router.navigate(['/app/mortgages/' + row.id]);
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }

  public ngAfterViewInit() {
    const dataSourceFactory: DataSourceFactory<ListMortgagesParams> = params => {
      params.quickFilter = this.quickFilter;
      params.assignedToMe = this.assignedToMe;
      return this.mortgageApiService.listMortgages(params);
    };

    this.tableSettingsService.setDataSourceFactory(dataSourceFactory);
  }
}
