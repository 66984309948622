import { Component, OnDestroy } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Confirmation, DialogService } from '../../../../../../projects/client/src/app/shared/services/dialog.service';
import { User } from '../../../models';
import { ToasterService } from '../../../services/toaster.service';
import { UserApiService } from '../../../services/user-api.service';
import { UserService } from '../../../services/user.service';
import { ValidatorsService } from '../../../services/validators.service';
import { SelectUserHeadingType, SelectUserService } from '../../../shared-layout/side-panes/select-user/select-user.service';
import { fadeAnimation, sidepaneMove } from '../../../shared/animations/sidepane-animation';
import { FileType } from '../../../shared/fileuploader/utils/fileuploader.enum';
import { FileControlsConfigBuilder } from '../../../shared/fileuploader/utils/fileuploader.utils';
import { EnumUtils } from '../../../utils/enum.utils';
import { USER_TYPE, USER_TYPE_NAMES } from '../../../utils/variables.data';
import { PhonePrefixService } from '../../introducers/select-phone-prefix/phone-prefix.service';
import { SelectUserTypeService } from '../select-user-type/select-user-type.service';
import { HeronUserService } from '../user.service';
import {TwoFactorStatusModel} from '../../../shared/two-factor-status/two-factor-status.component';
import { getUkPhoneValidator } from '../../../../../../projects/client/src/app/shared/functions/uk-phone.validator';

@Component({
  selector: 'hf-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: [
    './add-user.component.scss',
    '../../../styles/sidepanes.partial.scss'
  ],
  animations: [sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove]': 'true' }
})
export class AddUserComponent implements OnDestroy {

  readonly ALLOWED_FILE_TYPES: FileType[] = [FileType.IMAGE_ALL];

  public form: FormGroup;
  /** ID of user being edited, null for add user mode */
  public userId: string;
  public isLoading = true;

  public USER_TYPE_NAMES = USER_TYPE_NAMES;
  public isAdminOrLineManager() { return this.userService.isAdmin() || this.userService.getUser().userType === "LINE_MANAGER" }

  private destroy$ = new Subject();
  public user: User;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private userApiService: UserApiService,
    private userService: UserService,
    private heronUserService: HeronUserService,
    private phonePrefixService: PhonePrefixService,
    private selectUserTypeService: SelectUserTypeService,
    private selectUserService: SelectUserService,
    private toasterService: ToasterService,
    private dialogService: DialogService,
    private validatorsService: ValidatorsService
  ) {
    this.userId = route.snapshot.paramMap.get('userId');

    this.form = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', { validators: [Validators.required, Validators.email], updateOn: 'blur' }],
      phone: ['', [Validators.required, getUkPhoneValidator()]],
      phonePrefix: ['+44', Validators.required],
      userType: [undefined, Validators.required],
      lineManager: this.fb.group({
        id: [''],
        fullName: ['']
      }),
      defaultProtectionAdvisor: this.fb.group({
        id: ['', Validators.required],
        fullName: ['', Validators.required]
      }),
      photo: this.fb.group(
        new FileControlsConfigBuilder()
          .allowedFileTypes(this.ALLOWED_FILE_TYPES, 'Photo must be an image')
          .maxFileSizeDisk()
          .build()
      ),
      midasId: [null],
    });

    this.form.get('userType').valueChanges.subscribe(newValue => {
      const defaultProtectionAdvisor = this.form.get('defaultProtectionAdvisor');
      if (this.isMortgageAdvisor(newValue)) {
        defaultProtectionAdvisor.enable();
      } else {
        defaultProtectionAdvisor.reset();
        defaultProtectionAdvisor.disable();
      }
    });

    this.phonePrefixService.phonePrefixUpdated.pipe(takeUntil(this.destroy$))
      .subscribe(phonePrefix => this.form.patchValue({ phonePrefix }));

    this.selectUserTypeService.typeSelected$.pipe(takeUntil(this.destroy$))
      .subscribe(userType => this.form.patchValue({ userType }));

    this.selectUserService.userSelected$.pipe(takeUntil(this.destroy$))
      .subscribe(user => {
        const userControl = this.getUserControl();
        userControl.patchValue(user);
      });

    (async () => {
      if (this.userId) {
        this.user = await this.userApiService.viewHeronUser(this.userId);

        this.form.patchValue(this.user);

        setTimeout(() => {
          this.form.get("email").setAsyncValidators(this.validatorsService.validateEmailAddressExist({ userType: "ADVISOR_MORTGAGES", userId: this.userId }));
        });
      } else {
        this.form.get("email").setAsyncValidators(this.validatorsService.validateEmailAddressExist({ userType: "ADVISOR_MORTGAGES", userId: this.userId }));
      }

      this.isLoading = false;
    })();
  }

  private getUserControl(): AbstractControl {
    return this.selectUserService.heading === SelectUserHeadingType.SELECT_PROTECTION_ADVISOR ? this.form.get('defaultProtectionAdvisor') : this.form.get('lineManager');
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }

  public async onRequest() {
    try {
      await this.saveUser();
      this.toasterService.callToaster({ severity: 'info', summary: 'Info', detail: 'User profile updated successfully' });
      this.heronUserService.reloadUsersDashboard.emit();
    } catch (err) {
      this.heronUserService.reloadUsersDashboard.emit();
      this.toasterService.callToaster({ severity: 'error', summary: 'Error', detail: `Failed to update user profile, please try again. ${err.error.error.message}` });
    }

    this.onBack();
  }

  public async onRemove() {
    if ((this.user.hasActiveMortgages && !this.user.assignedAsDefaultProtectionAdvisor && !this.user.hasActiveProtectionCases)
      || (!this.user.hasActiveMortgages && (this.user.assignedAsDefaultProtectionAdvisor || this.user.hasActiveProtectionCases))) {
      this.router.navigate(['./selectUserForDelete'], {
        relativeTo: this.route,
        queryParams: {
          userFirstName: this.user.firstName,
          userLastName: this.user.lastName,
          userType: this.getUserType(),
        }
      });
    } else if (this.user.hasActiveMortgages && (this.user.assignedAsDefaultProtectionAdvisor || this.user.hasActiveProtectionCases)) {
      this.router.navigate(['./deleteAdvisorBoth'], {
        relativeTo: this.route,
        queryParams: {
          userId: this.userId,
          userFirstName: this.user.firstName,
          userLastName: this.user.lastName,
        }
      });
    } else {
      const confirmation: Confirmation = {
        title: "Delete User",
        message: `Are you sure you want to delete ${this.user.firstName} ${this.user.lastName} ?`,
        acceptLabel: "Delete User"
      };

      if (!await this.dialogService.confirm(confirmation)) {
        return;
      }

      this.onDeleteUser();
    }
  }

  private getUserType() {
    if (this.user.userType !== 'ADVISOR_BOTH') {
      return this.user.userType;
    }
    return this.user.hasActiveMortgages ? 'ADVISOR_MORTGAGES' : 'ADVISOR_PROTECTION';
  }

  public onDeleteUser() {
    this.userApiService.removeHeronUser(this.userId).then(() => {
      this.heronUserService.reloadUsersDashboard.emit();
      this.toasterService.callToaster({ severity: 'info', summary: 'Info', detail: 'User deleted successfully' });
      this.onBack();
    }).catch(() => {
      this.toasterService.callToaster({ severity: 'error', summary: 'Error', detail: 'A problem occurred deleting this user. Please try again' });
      this.onBack();
    });
  }

  public onBack() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

  public onPhotoDownload() {
    this.userApiService.triggerAttachmentDownload(this.form.get('photo').value);
  }

  isMortgageAdvisor(userType: USER_TYPE): boolean {
    return EnumUtils.isMortgageAdvisor(userType);
  }

  onSelectLineManager() {
    this.selectUserService.users$ = this.userApiService.getLineManagers();
    this.selectUserService.heading = SelectUserHeadingType.SELECT_LINE_MANAGER;
    this.router.navigate(["selectLineManager"], { relativeTo: this.route });
  }

  onSelectProtectionAdvisor() {
    this.selectUserService.users$ = this.userApiService.getProtectionAdvisors();
    this.selectUserService.heading = SelectUserHeadingType.SELECT_PROTECTION_ADVISOR;
    this.router.navigate(["selectProtectionAdvisor"], { relativeTo: this.route });
  }

  private saveUser(): Promise<void> {
    const user: User = { ...this.form.value };

    !user.lineManager.id && delete user.lineManager;
    !user.photo.filename && delete user.photo;

    return this.userId
      ? this.userApiService.editHeronUser(this.userId, user)
      : this.userApiService.addHeronUser(user);
  }
}
