<hf-source-twenty-seven-tec-button *ngIf="twentySevenTecAuthorised" [mode]="twenty7tecMode" [isSourceButtonLoading]="isSourceButtonLoading" (modeChange)="onTwenty7tecModeChanged($event)"></hf-source-twenty-seven-tec-button>

<hf-custom-spinner *ngIf="isLoading" sidepaneLoading="true"></hf-custom-spinner>

<form *ngIf="!isLoading" [formGroup]="form" class="ev-sidepane-form">
  <div class="ev-sidepane-form-section">
    <div class="ev-sidepane-form-heading">Attachments</div>

    <div class="ev-sidepane-form-content">
      <div class="ev-sidepane-form-column">
        <div [hfFormField]="control('kfi')" class="ev-form-field">
          <label>Key Facts Illustration</label>
          <hf-fileuploader controlName="kfi"
                           [group]="form"
                           allowedFileTypes="application/pdf"
                           [fileControl]="form.value['kfi']">
          </hf-fileuploader>
        </div>

        <div [hfFormField]="control('recommendationEvidence')" class="ev-form-field">
          <label>Evidence of Recommendation</label>
          <hf-fileuploader controlName="recommendationEvidence"
                           [group]="form"
                           allowedFileTypes="application/pdf"
                           [fileControl]="form.value['recommendationEvidence']">
          </hf-fileuploader>
        </div>


        <!-- <div [hfFormField]="control('adverseCredit')" class="ev-form-field"> -->
        <!--   <!-1- <label><wbr></label> -1-> -->
        <!--   <hf-checkbox label="Adverse Credit" formControlName="adverseCredit"></hf-checkbox> -->
        <!-- </div> -->
      </div>

      <div class="ev-sidepane-form-column">

        <div [hfFormField]="control('complianceIllustration')" class="ev-form-field">
          <label>Compliance Illustration (Optional)</label>
          <hf-fileuploader controlName="complianceIllustration"
                           [group]="form"
                           allowedFileTypes="application/pdf"
                           [fileControl]="form.value['complianceIllustration']">
          </hf-fileuploader>
        </div>

        <div *ngIf="enabled('illustrationDate')" class="ev-form-field" [hfFormField]="control('illustrationDate')">
          <label>Date of Illustration</label>
          <hf-date-input formControlName="illustrationDate" propertyName="illustrationDate" [maxDate]="today"></hf-date-input>
        </div>

        <div [hfFormField]="control('affordabilityCalculator')" class="ev-form-field">
          <label>Affordability Calculator</label>
          <hf-fileuploader controlName="affordabilityCalculator"
                           [group]="form"
                           allowedFileTypes="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                           [fileControl]="form.value['affordabilityCalculator']">
          </hf-fileuploader>
        </div>
      </div>
    </div>
  </div>

  <div class="ev-sidepane-form-section top-border">
    <div class="ev-sidepane-form-heading">Product Data</div>

    <div class="ev-sidepane-form-content">
      <div class="ev-sidepane-form-column">
        <div [hfFormField]="control('scheme')" class="ev-form-field">
          <label>Scheme</label>
          <hf-selector-link placeholder="Select Scheme"
                            [value]="APPLICANT_SCHEME_NAMES.get(control('scheme').value)"
                            (click)="onSelectSchemeClicked()">
          </hf-selector-link>
        </div>

        <div *ngIf="enabled('htb')" class="ev-form-field">
          <label>HTB Percentage</label>
          <hfc-text-input sufix="%" mask="separator.2" formControlName="htb"></hfc-text-input>
        </div>

        <div *ngIf="enabled('sharedOwnership')" class="ev-form-field">
          <label>Shared Ownership Percentage</label>
          <hfc-text-input sufix="%" mask="separator.2" formControlName="sharedOwnership"></hfc-text-input>
        </div>

        <div [hfFormField]="control('lender')" class="ev-form-field">
          <hf-selector-link label="Lender"
                            placeholder="Select Lender"
                            [value]="control('lender').value && control('lender').value.name"
                            (click)="onSelectLenderClicked()">
          </hf-selector-link>
        </div>

        <div class="ev-form-field">
          <label>Loan Amount</label>
          <hfc-text-input prefix="£" [currency]="true" formControlName="loanAmount"></hfc-text-input>
        </div>

        <div [hfFormField]="control('loanAmountReason')" class="ev-form-field">
          <label>Reason for loan amount</label>
          <hf-textarea [group]="form" [smaller]="true" [inputFormControlName]="'loanAmountReason'" fieldName="loanAmountReason"></hf-textarea>
        </div>

        <div [hfFormField]="control('transferOfEquity')" class="ev-form-field">
          <!-- <label><wbr></label> -->
          <hf-checkbox label="Transfer of equity" formControlName="transferOfEquity"></hf-checkbox>
        </div>

        <div class="ev-form-field">
          <label>Mortgage Term</label>
          <div class="years-months" formGroupName="mortgageTerm">
            <hfc-text-input sufix="years" type="number" formControlName="years"></hfc-text-input>
            <hfc-text-input sufix="months" type="number" formControlName="months"></hfc-text-input>
          </div>
        </div>

        <div [hfFormField]="control('mortgageTermReason')" class="ev-form-field">
          <label>Reason for mortgage term</label>
          <hf-textarea [group]="form" [smaller]="true" [inputFormControlName]="'mortgageTermReason'" fieldName="mortgageTermReason"></hf-textarea>
        </div>

        <div class="ev-form-field">
          <label>Initial Interest Rate</label>
          <hfc-text-input sufix="%" mask="separator.2" formControlName="initialRate"></hfc-text-input>
        </div>

        <div class="ev-form-field">
          <label>Monthly Repayment</label>
          <hfc-text-input prefix="£" [currency]="true" formControlName="monthlyRepayment"></hfc-text-input>
        </div>
      </div>

      <div class="ev-sidepane-form-column">
        <div class="ev-form-field">
          <label>Rate Type</label>
          <hfc-checkbox-list [map]="MORTGAGE_PRODUCT_DATA_TYPE_NAME" formControlName="type"></hfc-checkbox-list>
        </div>

        <div class="ev-form-field">
          <label>Repayment Method</label>
          <hfc-checkbox-list [map]="PROPERTY_PAYMENT_METHOD_NAME" formControlName="repaymentMethod"></hfc-checkbox-list>
        </div>

        <div *ngIf="enabled('partAndPartInterest')" class="ev-form-field">
          <label>Interest only</label>
          <hfc-text-input prefix="£" [currency]="true" formControlName="partAndPartInterest"></hfc-text-input>
        </div>
        <div *ngIf="enabled('partAndPartRepayment')" class="ev-form-field">
          <label>Capital repayment</label>
          <hfc-text-input prefix="£" [currency]="true" formControlName="partAndPartRepayment"></hfc-text-input>
        </div>

        <div class="ev-form-field">
          <label>Product Code</label>
          <hfc-text-input formControlName="productCode"></hfc-text-input>
        </div>

        <div [hfFormField]="control('productReason')" class="ev-form-field">
          <label>Reason for choosing product with this lender</label>
          <hf-textarea [group]="form" [smaller]="true" [inputFormControlName]="'productReason'" fieldName="productReason"></hf-textarea>
        </div>

        <div *ngIf="enabled('furtherAdvanceReason')" [hfFormField]="control('furtherAdvanceReason')" class="ev-form-field">
          <label>Reason for further advance</label>
          <hf-textarea [group]="form" [smaller]="true" [inputFormControlName]="'furtherAdvanceReason'" fieldName="furtherAdvanceReason"></hf-textarea>
        </div>

        <div *ngIf="enabled('productTransferReason')" [hfFormField]="control('productTransferReason')" class="ev-form-field">
          <label>Reason for product transfer</label>
          <hf-textarea [group]="form" [smaller]="true" [inputFormControlName]="'productTransferReason'" fieldName="productTransferReason"></hf-textarea>
        </div>

        <div *ngIf="enabled('portingReason')" [hfFormField]="control('portingReason')" class="ev-form-field">
          <label>Reason for porting</label>
          <hf-textarea [group]="form" [smaller]="true" [inputFormControlName]="'portingReason'" fieldName="portingReason"></hf-textarea>
        </div>

        <div *ngIf="enabled('portFurtherAdvanceReason')" [hfFormField]="control('portFurtherAdvanceReason')" class="ev-form-field">
          <label>Reason for port and further advance</label>
          <hf-textarea [group]="form" [smaller]="true" [inputFormControlName]="'portFurtherAdvanceReason'" fieldName="portFurtherAdvanceReason"></hf-textarea>
        </div>

        <div *ngIf="enabled('notPortingReason')" [hfFormField]="control('notPortingReason')" class="ev-form-field">
          <label>Reason for not porting current mortgage</label>
          <hf-textarea [group]="form" [smaller]="true" [inputFormControlName]="'notPortingReason'" fieldName="notPortingReason"></hf-textarea>
        </div>

        <div class="ev-form-field">
          <label>Product End Date Type</label>
          <hfc-checkbox-list [map]="MORTGAGE_PRODUCT_END_TYPE_NAME_ALL" formControlName="endDateType"></hfc-checkbox-list>
        </div>

        <div *ngIf="enabled('lifetimeProductRateReason')" [hfFormField]="control('lifetimeProductRateReason')" class="ev-form-field">
          <label>Explain why lifetime product rate</label>
          <hf-textarea [group]="form" [smaller]="true" [inputFormControlName]="'lifetimeProductRateReason'" fieldName="lifetimeProductRateReason"></hf-textarea>
        </div>

        <div *ngIf="form.get('endDate').enabled" class="ev-form-field">
          <label>Select Product End Date</label>
          <hf-date-picker formControlName="endDate" propertyName="endDate" [onlyDate]="true" class="desktop-date-time"></hf-date-picker>

          <div class="flex-space-between-layout native-mobile-date-time">
            <hf-custom-native-mobile-datetime formControlName="endDate" onlyDate="true" [invalid]="form.get('endDate').errors?.minDate" class="native-mobile-date-time">
            </hf-custom-native-mobile-datetime>
          </div>
        </div>
        <div *ngIf="form.get('endYearsMonths').enabled" class="ev-form-field">
          <label>Specify End Date</label>
          <div class="years-months" formGroupName="endYearsMonths">
            <hfc-text-input sufix="years" type="number" formControlName="years"></hfc-text-input>
            <hfc-text-input sufix="months" type="number" formControlName="months"></hfc-text-input>
          </div>
        </div>

        <div [hfFormField]="control('productEndDateReason')" class="ev-form-field">
          <label>Reason for product end date type</label>
          <hf-textarea [group]="form" [smaller]="true" [inputFormControlName]="'productEndDateReason'" fieldName="productEndDateReason"></hf-textarea>
        </div>

        <div [hfFormField]="control('erc')" class="ev-form-field">
          <!-- <label><wbr></label> -->
          <hf-checkbox label="Existing mortgage ERC" formControlName="erc"></hf-checkbox>
        </div>

        <div *ngIf="enabled('ercAmount')" class="ev-form-field">
          <label>Existing mortgage ERC Amount</label>
          <hfc-text-input prefix="£" [currency]="true" formControlName="ercAmount"></hfc-text-input>
        </div>
        
        <div *ngIf="enabled('ercReason')" [hfFormField]="control('ercReason')" class="ev-form-field">
          <label>Reason for ERC being incurred</label>
          <hf-textarea [group]="form" [smaller]="true" [inputFormControlName]="'ercReason'" fieldName="ercReason"></hf-textarea>
        </div>

        <div *ngIf="enabled('sharedOwnershipReason')" [hfFormField]="control('sharedOwnershipReason')" class="ev-form-field">
          <label>Justificaiton for shared ownership recommendation</label>
          <hf-textarea [group]="form" [smaller]="true" [inputFormControlName]="'sharedOwnershipReason'" fieldName="sharedOwnershipReason"></hf-textarea>
        </div>
      </div>
    </div>
  </div>

  <div class="ev-sidepane-form-section top-border">
    <div class="ev-sidepane-form-heading">Mortgage Notes</div>

    <div class="ev-sidepane-form-content">
      <div class="ev-sidepane-form-column">
        <div [hfFormField]="control('portable')" class="ev-form-field">
          <!-- <label><wbr></label> -->
          <hf-checkbox label="Portable" formControlName="portable"></hf-checkbox>
        </div>

        <div *ngIf="enabled('offset')" [hfFormField]="control('offset')" class="ev-form-field">
          <!-- <label><wbr></label> -->
          <hf-checkbox label="Offset" formControlName="offset"></hf-checkbox>
        </div>

        <div *ngIf="enabled('offsetReason')" [hfFormField]="control('offsetReason')" class="ev-form-field">
          <label>Reason for offset</label>
          <hf-textarea [group]="form" [smaller]="true" [inputFormControlName]="'offsetReason'" fieldName="offsetReason"></hf-textarea>
        </div>

        <div [hfFormField]="control('newErc')" class="ev-form-field">
          <!-- <label><wbr></label> -->
          <hf-checkbox label="New ERC Applicable" formControlName="newErc"></hf-checkbox>
        </div>

        <div *ngIf="enabled('newErcAmount')" class="ev-form-field">
          <label>New ERC amount</label>
          <hfc-text-input prefix="£" [currency]="true" formControlName="newErcAmount"></hfc-text-input>
        </div>

        <div *ngIf="enabled('noErcReason')" [hfFormField]="control('noErcReason')" class="ev-form-field">
          <label>Reason no ERC selected</label>
          <hf-textarea [group]="form" [smaller]="true" [inputFormControlName]="'noErcReason'" fieldName="noErcReason"></hf-textarea>
        </div>
      </div>

      <div class="ev-sidepane-form-column">
        <div [hfFormField]="control('mortgageBeyondRetirement')" class="ev-form-field">
          <label>Will the mortgage take the client beyond state retirement age?</label>
          <hfc-yes-no-checkbox formControlName="mortgageBeyondRetirement"></hfc-yes-no-checkbox>
        </div>

        <div *ngIf="enabled('retirementIncomeDetail')" [hfFormField]="control('retirementIncomeDetail')" class="ev-form-field">
          <label>Detail of retirement income</label>
          <hf-textarea [group]="form" [smaller]="true" [inputFormControlName]="'retirementIncomeDetail'" fieldName="retirementIncomeDetail"></hf-textarea>
        </div>

        <div [hfFormField]="control('debtConsolidation')" class="ev-form-field">
          <label><wbr></label>
          <hf-checkbox label="Debt Consolidation" formControlName="debtConsolidation"></hf-checkbox>
        </div>

        <div *ngIf="enabled('debtConsolidationMatchesObjective')" [hfFormField]="control('debtConsolidationMatchesObjective')" class="ev-form-field">
          <label><wbr></label>
          <hf-checkbox label="Debt consolidation matches circumstances and objectives?" formControlName="debtConsolidationMatchesObjective"></hf-checkbox>
        </div>

        <div *ngIf="enabled('amountUsedToRepay')" class="ev-form-field">
          <label>Amount of loan used to repay existing debt</label>
          <hfc-text-input prefix="£" [currency]="true" formControlName="amountUsedToRepay"></hfc-text-input>
        </div>

        <div *ngIf="enabled('debtConsolidationNote')" [hfFormField]="control('debtConsolidationNote')" class="ev-form-field">
          <label>Debt consolidation note</label>
          <hf-textarea [group]="form" [smaller]="true" [inputFormControlName]="'debtConsolidationNote'" fieldName="debtConsolidationNote"></hf-textarea>
        </div>

        <div *ngIf="enabled('debtConsolidationReason')" [hfFormField]="control('debtConsolidationReason')" class="ev-form-field">
          <label>Reason for debt consolidation</label>
          <hf-selector-link *ngIf="value.otherDebtConsolidationReasonNotAppropriateReason" placeholder="Select Explanation"
                            [value]="value.otherDebtConsolidationReasonNotAppropriateReason"
                            (click)="onSelectDebtConsolidationReasonClicked()">
          </hf-selector-link>

          <ng-container *ngIf="!value.otherDebtConsolidationReasonNotAppropriateReason">
            <hf-selector-link *ngFor="let reason of (value.debtConsolidationReason || [null])" placeholder="Select Explanation"
                                                                                   [value]="MortgageDebtConsolidationReasonName.get(reason)"
                                                                                   (click)="onSelectDebtConsolidationReasonClicked()">
            </hf-selector-link>
          </ng-container>
        </div>

        <div *ngIf="enabled('notProceedingReason')" [hfFormField]="control('notProceedingReason')" class="ev-form-field">
          <label>
            Reason for not proceeding with the following:
            <div class="notProceedingReasonHint">
              • Leaving some of your debts in place<br>
              • Switching your mortgage to an alternative deal to release additional income which would service your existing debts<br>
              • Leaving your existing mortgage in place and using a Secured Loan to repay your debts<br>
            </div>
          </label>
          <hf-textarea [group]="form" [smaller]="true" [inputFormControlName]="'notProceedingReason'" fieldName="notProceedingReason"></hf-textarea>
        </div>

        <div *ngIf="enabled('liabilitiesNotRepaidReason')" [hfFormField]="control('liabilitiesNotRepaidReason')" class="ev-form-field">
          <label>Reason for some existing liabilities not being repaid inline with new mortgage?</label>
          <hf-textarea [group]="form" [smaller]="true" [inputFormControlName]="'liabilitiesNotRepaidReason'" fieldName="liabilitiesNotRepaidReason"></hf-textarea>
        </div>

        <div [hfFormField]="control('notes')" class="ev-form-field">
          <label>Other notes</label>
          <hf-textarea [group]="form" [smaller]="true" [inputFormControlName]="'notes'" fieldName="notes"></hf-textarea>
        </div>

      </div>
    </div>
  </div>

  <div class="ev-sidepane-form-section top-border">
    <div class="ev-sidepane-form-heading">Costs and Fees</div>

    <div class="ev-sidepane-form-content">
      <div class="ev-sidepane-form-column">
        <div class="ev-form-field">
          <label>Fees</label>
          <hfc-text-input prefix="£" [currency]="true" formControlName="fees"></hfc-text-input>
        </div>

      </div>

      <div class="ev-sidepane-form-column">
        <div [hfFormField]="control('feesToLoan')" class="ev-form-field">
          <label>Add fees to loan</label>
          <hfc-yes-no-checkbox formControlName="feesToLoan"></hfc-yes-no-checkbox>
        </div>

        <div *ngIf="enabled('feesToLoanReason')" [hfFormField]="control('feesToLoanReason')" class="ev-form-field">
          <label>Reason for adding</label>
          <hf-selector-link placeholder="Select Reason"
                            [value]="value.otherFeesToLoanReason || FeesToLoanReasonName.get(control('feesToLoanReason').value)"
                            (click)="onSelectFeesReasonClicked()">
          </hf-selector-link>
        </div>
      </div>
    </div>
  </div>

  <div class="ev-sidepane-form-section top-border">
    <div class="ev-sidepane-form-heading">Midas Commission</div>

    <div class="ev-sidepane-form-content">
      <div class="ev-sidepane-form-column">
        <div class="ev-form-field">
          <label>L&G Prop Code</label>
          <hf-selector-link placeholder="Select L&G Prop Code"
                            [value]="formatPropco(value.propCode)"
                            (click)="onSelectPropcodeClicked()">
          </hf-selector-link>
        </div>

      </div>
    </div>
  </div>

  <div *ngIf="form.get('reasonForChange').enabled" [hfFormField]="control('reasonForChange')" class="ev-sidepane-form-section top-border">
    <div class="ev-sidepane-form-content">
      <div class="ev-sidepane-form-column-full-width">
        <div class="ev-form-field">
          <label>Reason for Change</label>
          <hf-textarea [group]="form" [smaller]="true" [inputFormControlName]="'reasonForChange'" fieldName="reasonForChange"></hf-textarea>
        </div>
      </div>
    </div>
  </div>

  <!-- <hfc-debug-form [form]="form"></hfc-debug-form> -->
</form>
