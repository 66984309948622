import { Injectable, NgModule } from "@angular/core";
import { AddNoteComponent } from "./add-note.component";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from "../../../shared/shared.module";
import { CustomButtonModule } from "../../../shared/custom-button/custom-button.module";
import { CustomSelectContainerModule } from "../../../shared/custom-select-container/custom-select-container.module";
import { CustomRadioChecksFieldModule } from "../../../shared/custom-radio-checks-field/custom-radio-checks-field.module";
import { CustomTextFieldModule } from "../../../shared/custom-text-field/custom-text-field.module";
import { RouterModule } from "@angular/router";
import { CustomTextAreaModule } from "../../../shared/custom-textarea/custom-text-area.module";
import { HfCheckboxModule } from "../../../shared/hf-checkbox-component/hf-checkbox.module";

@NgModule({
  declarations: [
    AddNoteComponent
  ], exports: [
    AddNoteComponent
  ], imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    CustomButtonModule,
    CustomSelectContainerModule,
    CustomRadioChecksFieldModule,
    CustomTextFieldModule,
    RouterModule,
    CustomTextAreaModule,
    HfCheckboxModule
  ]
})
@Injectable()
export class AddNoteModule {
}
