<ng-container [formGroup]="group">
  <div [ngClass]="customInputGroupClass"
       class="hf-input-group">

    <textarea
      [formControlName]="inputFormControlName"
      [ngClass]="{
        'hf-form-control-with-asset': asset,
        'hf-form-control-with-icon': icon,
        'hf-invalid-field': isValid == false,
        'hf-textarea-smaller': smaller}"

      class="form-control hf-form-control"
      [name]="fieldName"
      [id]="fieldName"
      [placeholder]="placeholder"
      [autofocus]="autofocus">
    </textarea>
    <span class="hf-validity-icon"
          [ngClass]="{ 'hf-valid-status': isValid == true, 'hf-invalid-status': isValid == false }">
      <i *ngIf="isValid == true" class="material-icons">check</i>
      <i *ngIf="isValid == false" class="material-icons">error</i>
    </span>
  </div>
</ng-container>
