<div class="overlay" (click)="onBack()" [@fadeAnimation]></div>

<div class="sidepane-content">
  <div class="flex-space-between-layout template-header">

    <h1>Assign Advisor</h1>
    <hf-custom-button type="button"
                      class="button-plain-icon"
                      icon="close"
                      iconPos="center"
                      (click)="onBack()">
    </hf-custom-button>
  </div>

  <div class="template-main">
    <ng-container *ngIf="isLoading">
      <hf-custom-spinner sidepaneLoading="true"></hf-custom-spinner>
    </ng-container>

    <ng-container *ngIf="!isLoading">
      <form [formGroup]="selectAssignerFormGroup"
            (ngSubmit)="onSubmit()"
            class="flex-col"
            novalidate>

        <ng-container *ngFor="let assigner of selectAssignerFormGroup.controls['assigners'].controls"
                      [formGroup]="assigner">
          <div class="hf-radio-element"
               [ngClass]="(assigner.controls.id.value == selectAssignerFormGroup.controls['selectedItem'].value) ? 'is-selected' : ''">
            <div class="hf-radio-wrapper">
              <input type="radio"
                     formControlName="radio"
                     name="radio"
                     [checked]="selectedHostId == assigner.value.id"
                     [value]="assigner.controls.fullName.value"
                     [id]="'assigner_'+assigner.controls.id.value"
                     (click)="selectRadio($event)">
              <label [for]="'assigner_'+assigner.controls.id.value">
                <span class="radio">
                  {{assigner.controls.fullName.value}}
                  {{assigner.controls.signedUser.value ? ' (You)' : ''}}
                  {{assigner.controls.defaultAdvisor.value ? ' (Default)' : ''}}
                </span>
              </label>
            </div>
          </div>
        </ng-container>
      </form>
    </ng-container>

  </div>

  <div *ngIf="!_hostSelectionActive" class="template-footer">
    <hf-custom-button type="submit"
                      class="button-primary"
                      label="Assign Advisor"
                      [disabled]="selectAssignerFormGroup.invalid"
                      [isLoading]="isSubmitting"
                      (onClick)="onSubmit()">
    </hf-custom-button>

    <hf-custom-button type="button"
                      class="button-secondary"
                      label="Cancel"
                      [routerLink]="['./..']">
    </hf-custom-button>
  </div>
</div>
