import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '../../../../../../projects/client/src/app/shared/services/toast.service';
import { CreditSearchOptOutApplicant, CreditSearchOptOutDTO, MortgageApiService } from '../../../services/mortgage-api.service';
import { fadeAnimation, sidepaneMove } from '../../../shared/animations/sidepane-animation';
import { MortgagesService } from '../mortgages.service';

@Component({
  selector: 'hf-credit-report-opt',
  templateUrl: './credit-report-opt.component.html',
  styleUrls: ['./credit-report-opt.component.scss',
    '../../../styles/sidepanes.partial.scss'],
  animations: [sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove]': 'true' }
})
export class CreditReportOptComponent {
  public form = new FormGroup({});

  public applicants: CreditSearchOptOutApplicant[];
  public isLoading = false;
  public isSubmitting = false;

  public get mortgageId() { return this.route.snapshot.paramMap.get("id"); }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private mortgageApiService: MortgageApiService,
    private mortgagesService: MortgagesService,
    private toastService: ToastService,
  ) {
    this.initialize();
  }

  private async initialize() {
    this.isLoading = true;

    const creditSearchOptOut = await this.mortgageApiService.getApplicantsWithCreditSearchOptOut(this.mortgageId);
    this.applicants = creditSearchOptOut.applicants;

    this.buildForm();

    this.isLoading = false;
  }

  private buildForm() {
    this.form = new FormGroup({});

    this.applicants.forEach(applicant => {
      const control = new FormControl(applicant.creditSearchOptOut);
      this.form.addControl(`${applicant.id}`, control);
    });
  }

  public onBack() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

  public async onSubmit() {
    this.isSubmitting = true;

    try {
      const value = (this.form.value as Record<string, boolean>);
      const applicants = this.applicants.map(a => ({ ...a, creditSearchOptOut: value[`${a.id}`] }));
      const creditSearchOptOutDTO: CreditSearchOptOutDTO = { applicants };

      await this.mortgageApiService.saveCreditSearchOptOutOptions(this.mortgageId, creditSearchOptOutDTO);
      this.mortgagesService.reloadMortgageDashboard.next();

      this.onBack();
    } catch (err) {
      this.toastService.add("Error submitting.", "error", err);
    }

    this.isSubmitting = false;
  }
}
