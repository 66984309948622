<div class="overlay" (click)="onBackClicked()" [@fadeAnimation]></div>

<div class="sidepane-content">
  <div class="flex-space-between-layout template-header">
    <h1>
      <ng-content select="header"></ng-content>
    </h1>
    <hf-custom-button type="button"
                      class="button-plain-icon"
                      icon="close"
                      iconPos="center"
                      (click)="onBackClicked()">
    </hf-custom-button>
  </div>

  <div class="template-main">
    <ng-content select="body"></ng-content>
  </div>

  <div class="template-footer">
    <hf-custom-button type="submit"
                      class="button-primary"
                      label="Submit"
                      [isLoading]="sh.isSubmitting"
                      [disabled]="!sh.valid"
                      (onClick)="onSubmitClicked()">
    </hf-custom-button>

    <hf-custom-button type="button"
                      class="button-secondary"
                      label="Cancel"
                      (click)="onBackClicked()">
    </hf-custom-button>
  </div>
</div>

<div class="sidepane-child-anchor">
  <router-outlet></router-outlet>
</div>
