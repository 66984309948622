import { Component, Input } from '@angular/core';
import { ExperianCreditSearchReportDTO } from '../../../../../../services/experian-credit-report-api.service';

@Component({
  selector: 'hf-current-accounts-category-details',
  templateUrl: './current-accounts-category-details.component.html',
  styleUrls: ['./current-accounts-category-details.component.scss']
})
export class CurrentAccountsCategoryDetailsComponent {
  @Input()
  public report: ExperianCreditSearchReportDTO;
}
