import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {ApiService} from "../../../services/api.service";
import {ToasterService} from "../../../services/toaster.service";
import {UserService} from "../../../services/user.service";
import {JournalTabService} from '../../../shared-layout/tabs/journal/journal-tab.service';
import {fadeAnimation, sidepaneMove} from "../../../shared/animations/sidepane-animation";
import {SelectApplicationLenderService} from '../select-application-lender/select-application-lender.service';
import {MortgagesService} from '../mortgages.service';
import { Store } from '@ngxs/store';
import { Mortgage } from '../state/mortgage.actions';

@Component({
  selector: 'hf-add-note',
  templateUrl: 'add-note.component.html',
  styleUrls: ['add-note.component.scss',
    '../../../styles/sidepanes.partial.scss'],
  animations: [sidepaneMove, fadeAnimation],
  host: {'[@sidepaneMove]': 'true'},
  providers: [SelectApplicationLenderService],
})

export class AddNoteComponent implements OnInit, OnDestroy {
  public addNoteFG:FormGroup;
  public submitResponse;
  public get value() { return this.addNoteFG.value }
  public get handovers() { return this.mortgagesService.data?.handovers }
  public get hasNotifyIntroducer() { return !!this.route.snapshot.data.hasNotifyIntroducer }

  private mortgageId;
  private destroy$ = new Subject();
  private control(name: string) { return this.addNoteFG.get(name) }

  constructor(
    private store: Store,
    private fb:FormBuilder,
    private apiService:ApiService,
    private router:Router,
    private route: ActivatedRoute,
    private toasterService:ToasterService,
    private activatedRoute:ActivatedRoute,
    private userService:UserService,
    private selectApplicationLenderService: SelectApplicationLenderService,
    private journalTabService: JournalTabService,
    private mortgagesService: MortgagesService,
  ) {
    this.selectApplicationLenderService.application$.pipe(takeUntil(this.destroy$))
      .subscribe(handover => this.control("_handover").setValue(handover?.id ? handover : null));
  }

  ngOnInit() {
    this.addNoteFG = this.fb.group({
      internalNotes : [null, Validators.required],
      title: ['', Validators.required],
      _handover: [null],
    });

    if(this.userService.hasPermission("MORTGAGE_MANAGE")) {
      this.addNoteFG.addControl('notifyIntroducer', this.fb.control(false, Validators.required));
      this.addNoteFG.addControl('messageToIntroducer', this.fb.control('', Validators.required));

      this.addNoteFG.get('notifyIntroducer').valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
        if(value)
          this.addNoteFG.get('messageToIntroducer').enable();
        else
          this.addNoteFG.get('messageToIntroducer').disable();
      });

      this.addNoteFG.get('messageToIntroducer').disable();
    }

    this.mortgageId = this.activatedRoute.snapshot.params['id'];
  }

  public onSelectLender() {
    this.router.navigate(["selectApplication"], { relativeTo: this.route });
  }

  public async onSubmit() {
    const value = {...this.addNoteFG.value};

    if (value._handover) {
      value.handoverId = value._handover.id;
      delete value._handover;
    }

    this.submitResponse = await this.apiService.post('journal', 'addMortgageNote?mortgageId=' + this.mortgageId, value).toPromise().then(() => {
      this.toasterService.callToaster({severity: 'info', summary: 'Info', detail: `Note successfully added`});
      this.journalTabService.reloadJournalTab.emit();
      this.store.dispatch(new Mortgage.NoteSaved());
      this.onBack();
    }).catch(error => {
      this.toasterService.callToaster({severity: 'error', summary: 'Error', detail: `${error.error.error.message}`});
      this.onBack();
    });
  }

  onBack() {
    this.router.navigate(['./..'], {relativeTo:this.activatedRoute});
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
