<div class="hf-selector">
  <label>Client</label>

  <div *ngIf="!form.get('client').value?.fullName"
       class="hf-selector-container hf-selector-has-value"
       [class.readonly]="readonly"
       (click)="!readonly && selectClient.emit()">
    <div class="hf-selector-label-area">
      <div class="title">Select Client</div>
    </div>
    <em class="material-icons chevron-icon">chevron_right</em>
  </div>

  <ng-container *ngIf="form.get('client').value?.fullName">
    <div class="hf-selector-container hf-selector-has-value hf-selector-black-color"
         [class.readonly]="readonly"
         (click)="!readonly && selectClient.emit()">
      <div class="hf-selector-content-area">
        <div class="title">{{form.get('client').value.fullName}}</div>
        <div class="description">{{form.get('client').value.email}}</div>
        <div class="description-extended">
          {{form.get('client').value.phonePrefix}}&nbsp;
          {{form.get('client').value.phone}}
        </div>
      </div>
      <em class="material-icons chevron-icon">chevron_right</em>
    </div>
  </ng-container>
</div>
