import { BehaviorSubject } from "rxjs";
import { DEBT_CONSOLIDATION_EXPLANATION } from "../../../utils/variables.data";
import { Injectable } from "@angular/core";

export interface SelectedDebtConsolidation {
  debtConsolidationExplanation: DEBT_CONSOLIDATION_EXPLANATION;
  otherDebtConsolidationExplanation?: string;
}

@Injectable()
export class SelectDebtConsolidationService {
  private readonly _debtConsolidation = new BehaviorSubject<SelectedDebtConsolidation>(undefined);

  public debtConsolidation$ = this._debtConsolidation.asObservable();
  public get debtConsolidation() { return this._debtConsolidation.getValue(); }

  public setDebtConsolidation(debtConsolidation: SelectedDebtConsolidation) {
    this._debtConsolidation.next(debtConsolidation);
  }
}
