import { Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { fadeAnimation, sidepaneMove } from '../../../shared/animations/sidepane-animation';
import { USER_TYPE, USER_TYPE_NAMES } from '../../../utils/variables.data';
import { SelectUserTypeService } from './select-user-type.service';

@Component({
  selector: 'hf-select-user-type',
  templateUrl: './select-user-type.component.html',
  styleUrls: [
    './select-user-type.component.scss',
    '../../../styles/sidepanes.partial.scss'
  ],
  animations: [sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove]': 'true' }
})
export class SelectUserTypeComponent implements OnDestroy {
  public types: USER_TYPE[];
  public USER_TYPE_NAMES = USER_TYPE_NAMES;

  public form: FormGroup = new FormGroup({
    type: new FormControl()
  });

  private destroy$ = new Subject();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private selectUserTypeService: SelectUserTypeService
  ) {
    const type = this.route.snapshot.queryParamMap.get('initialValue');
    type && this.form.setValue({ type });

    this.form.get('type').valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(type => {
        this.selectUserTypeService.typeSelected$.next(type);
        this.onBack();
      })

    this.types = [...this.selectUserTypeService.allowedUserTypes || USER_TYPE_NAMES.keys()];
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }

  public onBack() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }
}
