import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControlName } from '@angular/forms';
import { SelectDateAndTimeService } from '../../modules/mortgages/select-date-and-time/select-date-and-time.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Params } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'hf-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: DatePickerComponent,
    multi: true
  }]
})
export class DatePickerComponent implements OnDestroy, ControlValueAccessor {
  private onChange: (value: Date) => (void);
  private destroy$ = new Subject();

  public value: Date;

  @Input() public label: string;
  @Input() public routerLink: any[] = ["./selectDateAndTime"];
  @Input() public propertyName: string;
  @Input() public onlyDate: boolean;
  @Input() public minDate: Date | "none";
  @Input() public maxDate: Date;
  @Input() public isInvalid: boolean;

  constructor(
    private selectDateAndTimeService: SelectDateAndTimeService
  ) {
    this.selectDateAndTimeService.dateSelected$.pipe(takeUntil(this.destroy$)).subscribe(dateProperty => {
      if (dateProperty.name === this.propertyName) {
        this.value = dateProperty.date;
        this.onChange && this.onChange(this.value);
      }
    })
  }

  public buildQueryParams(): Params {
    const minDate = moment.isDate(this.minDate)
      ? this.minDate.toISOString()
      : this.minDate;

    return {
      name: this.propertyName,
      onlyDate: this.onlyDate,
      minDate,
      maxDate: this.maxDate && this.maxDate.toISOString(),
      initialValue: this.value && this.value.toISOString()
    }
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }

  public writeValue(obj: any): void {
    this.value = obj;
  }
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  public registerOnTouched(fn: any): void {
    // throw new Error("Method not implemented.");
  }
  public setDisabledState?(isDisabled: boolean): void {
    // throw new Error("Method not implemented.");
  }
}
