<div class="container">
  <div class="content">
    <img class="logo" width="496px" height="90px" src="https://www.twenty7tec.com/site/wp-content/uploads/2023/03/T7T-Logo-GreenSymbol-BlueText-RGB.png">

    <hfc-spinner *ngIf="mode === 'link' && isLoading"></hfc-spinner>

    <div class="error">
      {{ error }}
    </div>

    <div *ngIf="mode === 'success'" class="message">
      Your Twenty7tec account has been successfully linked with your Everglades account.
    </div>

    <div *ngIf="mode === 'failed'" class="message">
      Failed to link your Twenty7tec account with Everglades.
      <pre>
        {{ error }}
      </pre>
    </div>
  </div>

  <div *ngIf="mode === 'success'" class="buttons">
    <hfc-button type="primary" routerLink="/apps">Return to Everglades</hfc-button>
  </div>

  <div *ngIf="mode === 'failed'" class="buttons">
    <hfc-button type="primary" [isLoading]="isSubmitting" (click)="onTryClicked()">Try Again</hfc-button>
    <hfc-button type="secondary" routerLink="/apps">Return to Everglades</hfc-button>
  </div>
</div>
