<div class="container">
  <div class="content">
    <img class="logo" src="assets/external/Office 365.svg">

    <hfc-spinner *ngIf="mode === 'link' && isLoading"></hfc-spinner>

    <div class="error">
      {{ error }}
    </div>

    <div *ngIf="mode === 'success'" class="message">
      Your Office 365 account has been successfully linked with your Everglades account.
    </div>

    <div *ngIf="mode === 'failed'" class="message">
      Failed to link your Office 365 account with Everglades.
      <pre>
        {{ error }}
      </pre>
    </div>
  </div>

  <div *ngIf="mode === 'success'" class="buttons">
    <hfc-button type="primary" routerLink="/apps">Return to Everglades</hfc-button>
  </div>

  <div *ngIf="mode === 'failed'" class="buttons">
    <hfc-button type="primary" [isLoading]="isSubmitting" (click)="onTryClicked()">Try Again</hfc-button>
    <hfc-button type="secondary" routerLink="/apps">Return to Everglades</hfc-button>
  </div>
</div>
