import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { takeUntil } from 'rxjs/operators';
import { ToastService } from '../../../../../../projects/client/src/app/shared/services/toast.service';
import { MortgageApiService } from '../../../services/mortgage-api.service';
import { ngIfAnimation, sidepaneMove } from "../../../shared/animations/sidepane-animation";
import { SidepaneHelper, SidepaneHost } from '../../../shared/sidepane/sidepane-helper.class';
import { Sidepane } from '../../../shared/sidepane/sidepane.decorator';
import { MortgagesService } from '../mortgages.service';
import { SelectDateAndTimeService } from '../select-date-and-time/select-date-and-time.service';
import { Mortgage } from '../state/mortgage.actions';

@Sidepane()
@Component({
  selector: 'hf-update-target-exchange-date',
  templateUrl: './update-target-exchange-date.component.html',
  styleUrls: ['./update-target-exchange-date.component.scss'],
  animations: [ngIfAnimation, sidepaneMove],
  host: { '[@ngIfAnimation]': 'true' },
  providers: [{ provide: SidepaneHost, useExisting: UpdateTargetExchangeDateComponent }]
})
export class UpdateTargetExchangeDateComponent implements SidepaneHost {
  public sh = new SidepaneHelper(this);

  public form = this.fb.group({
    targetExchangeDate: [null, Validators.required],
  });

  public get mortgageId() { return this.route.snapshot.paramMap.get("id") }

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private route: ActivatedRoute,
    private mortgagesService: MortgagesService,
    private mortgageApiService: MortgageApiService,
    private selectDateAndTimeService: SelectDateAndTimeService,
    private toastService: ToastService,
  ) 
  { 
    this.setup();

    const { targetExchangedDate } = this.mortgagesService.data;
    targetExchangedDate && this.form.patchValue({ targetExchangeDate: targetExchangedDate });
  }

  public spOnSubmit(): void {
    this.sh.isSubmitting = true;

    const value = this.sh.value;

    this.mortgageApiService.editTargetExchangeDate(this.mortgageId, value).subscribe(
      () => {
        this.store.dispatch(new Mortgage.TargetExchangeDateUpdated());
        this.sh.toastService.add("Target exchange date updated.");
        this.sh.isSubmitting = false;
        this.sh.goBack();
      },
      e  => {
        this.sh.isSubmitting = false;
        this.toastService.add("Failed", "error", e);
      }
    )
  }

  private setup() {
    this.selectDateAndTimeService.dateSelected$.pipe(takeUntil(this.sh.destroy$)).subscribe(selected => {
      this.sh.control(selected.name) && this.sh.control(selected.name).setValue(selected.date);
    });
  }
}
