import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { IntroducerApiService } from "../../../services/introducer-api.service";
import { fadeAnimation, sidepaneMove } from "../../../shared/animations/sidepane-animation";
import { USER_TYPE_NAMES } from '../../../utils/variables.data';
import { MortgagesService } from "../mortgages.service";

@Component({
  selector: 'hf-select-sales-advisor',
  templateUrl: './select-sales-advisor.component.html',
  styleUrls: ['./select-sales-advisor.component.scss',
    '../../../styles/sidepanes.partial.scss'],
  animations: [sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove]': 'true' }
})
export class SelectSalesAdvisorComponent implements OnInit {
  public USER_TYPE_NAMES = USER_TYPE_NAMES;

  public isLoading: boolean = true;
  public searchBoxFormGroup: FormGroup;
  public selectSalesAdvisorsFormGroup: FormGroup = this.fb.group({});

  private introducerId;
  private divisionId;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private introducerApiService: IntroducerApiService,
    private mortgagesService: MortgagesService
  ) {
    this.introducerId = this.activatedRoute.snapshot.queryParams.introducerId;
    this.divisionId = this.activatedRoute.snapshot.queryParams.divisionId;

    this.searchBoxFormGroup = this.fb.group({
      salesAdvisorsNameFilter: []
    });
  }

  async ngOnInit() {
    const salesAdvisors = this.fb.array([]);
    await this.introducerApiService.getDivisionSalesAdvisors(this.introducerId, this.divisionId).then(result => {
      for (const item of result.items) {
        salesAdvisors.push(this.fb.group({
          fullName: [item.fullName],
          id: [item.id, Validators.required],
          userType: [item.userType],
          radio: new FormControl(false)
        }));
      }

      this.selectSalesAdvisorsFormGroup = this.fb.group({
        salesAdvisors: salesAdvisors,
        selectedItem: new FormControl(this.mapItems(salesAdvisors.value), Validators.required)
      });

      setTimeout(() => {
        if (this.mortgagesService.mortgageForm.get('salesAdvisor.id').value !== '') {
          const radioEl = (<HTMLInputElement>document.getElementById('salesAdvisor_' + this.mortgagesService.mortgageForm.get('salesAdvisor.id').value));
          radioEl !== null ? radioEl.checked = true : null;

          if (radioEl !== null) {
            Object.keys((this.selectSalesAdvisorsFormGroup.controls['salesAdvisors'] as FormArray).controls).forEach(index => {
              (this.selectSalesAdvisorsFormGroup.controls['salesAdvisors'] as FormArray).controls[index].value.radio =
                radioEl.id === 'salesAdvisor_' + (this.selectSalesAdvisorsFormGroup.controls['salesAdvisors'] as FormArray).controls[index].value.id;
            });
            this.selectSalesAdvisorsFormGroup.get('selectedItem').setValue(this.mapItems((this.selectSalesAdvisorsFormGroup.controls['salesAdvisors'] as FormArray).value));
          }
        }
      }, 250);

      this.isLoading = false;
    });
  }

  onBack() {
    this.router.navigate(['./..'], { relativeTo: this.activatedRoute });
  }

  mapItems(items) {
    const selectedItem = items.filter((item) => item.radio).map((item) => item.id);
    return selectedItem.length ? selectedItem : null;
  }

  selectRadio(event) {
    Object.keys((this.selectSalesAdvisorsFormGroup.controls['salesAdvisors'] as FormArray).controls).forEach(index => {
      (this.selectSalesAdvisorsFormGroup.controls['salesAdvisors'] as FormArray).controls[index].value.radio =
        event.srcElement.id === 'salesAdvisor_' + (this.selectSalesAdvisorsFormGroup.controls['salesAdvisors'] as FormArray).controls[index].value.id;
    });
    this.selectSalesAdvisorsFormGroup.get('selectedItem').setValue(this.mapItems((this.selectSalesAdvisorsFormGroup.controls['salesAdvisors'] as FormArray).value));

    Object.keys((this.selectSalesAdvisorsFormGroup.controls['salesAdvisors'] as FormArray).controls).forEach(index => {
      if (this.selectSalesAdvisorsFormGroup.get('selectedItem').value[0] === (this.selectSalesAdvisorsFormGroup.controls['salesAdvisors'] as FormArray).controls[index].value.id) {
        this.mortgagesService.mortgageForm.get('salesAdvisor').patchValue(
          (this.selectSalesAdvisorsFormGroup.controls['salesAdvisors'] as FormArray).controls[index].value
        );
      }
    });

    this.router.navigate(['./..'], { relativeTo: this.activatedRoute });
  }
}
