export type RemortgageStatus = 
  | "KEEPING_WARM"
  | "WARMING_UP"
  | "ENGAGED"
  | "OPTED_OUT"
  | "MISSED"
  | "NOT_PROCEEDING"
  | "COMPLETED"

// To match mortgage
  | "SUBMITTED"
  | "OFFERED"

export const RemortgageStatusName = new Map<RemortgageStatus, string>([
  ["KEEPING_WARM", "Keeping Warm"],
  ["WARMING_UP", "Warming Up"],
  ["ENGAGED", "Engaged"],
  ["OPTED_OUT", "Opted Out"],
  ["MISSED", "Missed"],
  ["NOT_PROCEEDING", "Not Proceeding"],
  ["COMPLETED", "Completed"],

  ["SUBMITTED", "Submitted"],
  ["OFFERED", "Offered"],
])

export type RemortgageNotProceedingStatus =
  | "ANOTHER_BROKER"
  | "PROPERTY_SOLD"
  | "DONT_CONTACT"
  | "WENT_DIRECT"
  | "MORTGAGE_PAID"
  | "CANCELLED"
  | "NO_RESPONSE"
  | "OTHER"

export const RemortgageNotProceedingStatusName = new Map<RemortgageNotProceedingStatus, string>([
  ["ANOTHER_BROKER", "Another Broker"],
  ["PROPERTY_SOLD", "Property Sold"],
  ["DONT_CONTACT", "Don't Contact"],
  ["WENT_DIRECT", "Went Direct"],
  ["MORTGAGE_PAID", "Mortgage Paid"],
  ["CANCELLED", "Cancelled"],
  ["NO_RESPONSE", "No Response"],
  ["OTHER", "Other"],
])

export type RemortgageLeadNotProceedingState = 
  | "ANOTHER_BROKER"
  | "PROPERTY_SOLD"
  | "DONT_CONTACT"
  | "WENT_DIRECT"
  | "MORTGAGE_PAID"
  | "CANCELLED"
  | "NO_RESPONSE"
  | "OTHER"

export const RemortgageLeadNotProceedingStateName = new Map<RemortgageLeadNotProceedingState, string>([
  ["ANOTHER_BROKER", "Another Broker"],
  ["PROPERTY_SOLD", "Property Sold"],
  ["DONT_CONTACT", "Don't Contact"],
  ["WENT_DIRECT", "Went Direct"],
  ["MORTGAGE_PAID", "Mortgage Paid"],
  ["CANCELLED", "Cancelled"],
  ["NO_RESPONSE", "No Response"],
  ["OTHER", "Other"],
])

