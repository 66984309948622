import { Component, Inject, InjectionToken } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Subject } from 'rxjs';

import { MortgagesService } from '../../../modules/mortgages/mortgages.service';
import { ToasterService } from '../../../services/toaster.service';
import { fadeAnimation, sidepaneMove } from '../../../shared/animations/sidepane-animation';

export interface ApproveComplianceConfig {
  backPath: string;
}

export interface ApproveComplianceService {
  approveComplianceHandover(paramMap: ParamMap, body: { value: string }): Promise<void>;
  approve$?: Subject<void>;
}

export const APPROVE_COMPLIANCE_SERVICE = new InjectionToken<ApproveComplianceService>("APPROVE_COMPLIANCE_SERVICE");

@Component({
  selector: 'hf-approve-compliance',
  templateUrl: './approve-compliance.component.html',
  styleUrls: ['./approve-compliance.component.scss',
    '../../../styles/sidepanes.partial.scss'
  ],
  animations: [sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove]': 'true' }
})
export class ApproveComplianceComponent {
  public get mortgageId() { return this.route.snapshot.paramMap.get("id"); }
  public get config() { return this.route.snapshot.data as ApproveComplianceConfig }

  public form = new FormGroup({
    value: new FormControl(undefined),
  });

  public isSubmitting = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    @Inject(APPROVE_COMPLIANCE_SERVICE) private approveComplianceService: ApproveComplianceService,
    private mortgagesService: MortgagesService,
    private toasterService: ToasterService
  ) { }

  public async onSubmit() {
    const value = this.form.value;

    try {
      this.isSubmitting = true;

      await this.approveComplianceService.approveComplianceHandover(this.route.snapshot.paramMap, value);

      this.approveComplianceService.approve$?.next();
      this.mortgagesService.reloadMortgageDashboard.next();

      this.toasterService.callToaster({ severity: 'info', summary: 'Info', detail: 'Compliance approved.' });

      this.onBack();
    } catch (err) {
      this.toasterService.callToaster({ severity: 'error', summary: 'Error', detail: `Failed to approve compliance. Please try again. ${err.error.error.message}` });
    } finally {
      this.isSubmitting = false;
    }
  }

  public onBack() {
    const path = this.config.backPath || "..";
    this.router.navigate([path], { relativeTo: this.route });
  }
}
