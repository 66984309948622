<div class="overlay" [routerLink]="['./..']" [@fadeAnimation]></div>

<div class="sidepane-content cols-2">
  <div class="template-header flex-space-between-layout">
    <h1 class="title-area">Offer Qualification</h1>
    <hf-custom-button type="button"
                      class="button-plain-icon"
                      icon="close"
                      iconPos="center"
                      [routerLink]="['./..']">
    </hf-custom-button>
  </div>

  <div class="template-main">
    <hf-custom-spinner *ngIf="isLoading" sidepaneLoading="true"></hf-custom-spinner>

    <form *ngIf="!isLoading" class="form" [formGroup]="form">
      <div class="form-column">
        <label>Are you qualified?</label>
        <hf-custom-radio-checks-field [group]="form"
                                      [type]="'radio'"
                                      [elemId]="'status'"
                                      [dataObj]="qualifiedOptions"
                                      marginElement>
        </hf-custom-radio-checks-field>

        <ng-container *ngIf="form.get('qualifiedUpTo').enabled">
          <label>Qualified up to</label>
          <hf-text-field prefix="£" isCurrency="true" formControlName="qualifiedUpTo"></hf-text-field>
        </ng-container>

        <label>Purchase price (optional)</label>
        <hf-text-field prefix="£" isCurrency="true" formControlName="purchasePrice"></hf-text-field>

        <label>Help to buy loan (optional)</label>
        <hf-text-field prefix="£" isCurrency="true" formControlName="helpToBuyLoan"></hf-text-field>

        <label>Client's deposit (optional)</label>
        <hf-text-field prefix="£" isCurrency="true" formControlName="depositAmount"></hf-text-field>

        <label>Client's mortgage (optional)</label>
        <hf-text-field prefix="£" isCurrency="true" formControlName="mortgageAmount"></hf-text-field>

        <label>Private Notes (optional)</label>
        <hf-textarea [group]="form.get('journal')" inputFormControlName="internalNotes" [smaller]="true">
        </hf-textarea>
      </div>

      <div class="form-column">
        <label>Message to Introducer (optional)</label>
        <hf-textarea [group]="form.get('journal')" inputFormControlName="messageToIntroducer" [smaller]="true">
        </hf-textarea>

        <hf-checkbox label="Notify Client" [formControl]="form.get('journal.notifyClient')"></hf-checkbox>

        <ng-container *ngIf="form.get('journal.messageToClient').enabled">
          <label>Message to Client</label>
          <hf-textarea [group]="form.get('journal')" inputFormControlName="messageToClient" [smaller]="true">
          </hf-textarea>
        </ng-container>

        <hf-checkbox *ngIf="form.get('clientProceeding').enabled" label="Client Wants To Proceed Now" formControlName="clientProceeding"></hf-checkbox>

        <hf-checkbox *ngIf="form.get('sendFactFind').enabled" label="Request Fact Find" formControlName="sendFactFind"></hf-checkbox>

        <hf-selector-link *ngIf="form.get('appointment').enabled"
                          label="Arrange Appointment (optional)"
                          placeholder="Select Date and time"
                          [value]="form.get('appointment').value?.appointmentDate | date:'d MMM y, HH:mm'"
                          (click)="onArrangeAppointmentClicked()">
        </hf-selector-link>
      </div>
    </form>

    <!-- <pre>{{form.value | json}}</pre>
    <pre>
      <span *ngFor="let c of mortgagesService.mortgageForm.controls | keyvalue">
        {{c.key}}: {{c.value.status}} <br>
      </span>
    </pre> -->
  </div>

  <div class="template-footer">
    <hf-custom-button class="button-primary"
                      label="Proceed Now"
                      [callable]="true"
                      [disabled]="form.invalid"
                      (onClick)="onSubmit()">
    </hf-custom-button>

    <hf-custom-button type="button"
                      class="button-secondary"
                      label="Cancel"
                      [routerLink]="['./..']">
    </hf-custom-button>
  </div>
</div>

<div class="sidepane-child-anchor">
  <router-outlet></router-outlet>
</div>
