import { Component, OnDestroy } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { ToastService } from "../../../../../../projects/client/src/app/shared/services/toast.service";
import { FormFieldService } from "../../../services/form-field.service";
import { MortgageApiService } from "../../../services/mortgage-api.service";
import { NotProceedingReasonsService, NotProceedingReasonUpdateData } from "../../../services/not-proceeding-reasons.service";
import { SelectDecliningReasonService } from "../../../shared-layout/side-panes/select-declining-reason/select-declining-reason.service";
import {
  fadeAnimation,
  sidepaneMove,
} from "../../../shared/animations/sidepane-animation";
import { FileControlsConfigBuilder } from "../../../shared/fileuploader/utils/fileuploader.utils";
import { HfValidators } from "../../../utils/form.validators";
import { setControlEnabled } from "../../../utils/functions/set-control-enabled";
import {
  APPLICATION_STATE,
  APPLICATION_STATE_NAMES,
} from "../../../utils/variables.data";
import { isTransferOrAdvance } from "../create-application/create-application.component";
import { MortgagesService } from "../mortgages.service";
import { SelectDateAndTimeService } from "../select-date-and-time/select-date-and-time.service";
import { SelectPropcoService } from "../select-propco-code/select-propco-code.service";

@Component({
  selector: "hf-update-application",
  templateUrl: "./update-application.component.html",
  styleUrls: [
    "./update-application.component.scss",
    "../../../styles/sidepanes.partial.scss",
  ],
  animations: [sidepaneMove, fadeAnimation],
  host: { "[@sidepaneMove]": "true" },
  providers: [FormFieldService],
})
export class UpdateApplicationComponent implements OnDestroy {
  public form: FormGroup;
  public isLoading = false;
  public isSubmitting = false;
  public isSubmitted = false;
  public isRemortgageDashboard = false;
  public APPLICATION_STATE_NAMES = APPLICATION_STATE_NAMES;
  public now = new Date().toISOString();
  public nowNative: Date;
  public notProceedingReasonsData: NotProceedingReasonUpdateData;

  private get applicationId() {
    return this.route.snapshot.paramMap.get("applicationId");
  }

  private get application() {
    return this.mortgagesService.data.applications.find(
      (a) => a.id == this.applicationId
    );
  }

  public get value() {
    return this.form.value;
  }

  public get state(): APPLICATION_STATE {
    return this.form.get("state").value;
  }

  public get notProceedingReason() {
    return this.form.get("notProceedingReason").value;
  }

  public control(name: string) {
    return this.form.get(name);
  }
  public enabled(name: string) {
    return this.form.get(name).enabled;
  }

  private destroy$ = new Subject();
  private isTransfer: boolean;
  private commission: number;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private mortgagesService: MortgagesService,
    private mortgageApiService: MortgageApiService,
    private toastService: ToastService,
    private selectDateAndTimeService: SelectDateAndTimeService,
    private selectPropcoService: SelectPropcoService,
    private formFieldService: FormFieldService,
    private notProceedingReasonsService: NotProceedingReasonsService,
    private selectDecliningReasonService: SelectDecliningReasonService,
  ) {
    if (!this.mortgagesService.data.applications) {
      this.onBack();
      return;
    }

    // check if client is coming from remortgage dashboard
    this.isRemortgageDashboard = this.route.snapshot.data.name === 'Remortgages';
  
    this.nowNative = new Date();
    this.nowNative.setHours(0, 0, 0, 0);

    this.form = this.fb.group({
      state: [null, Validators.required],
      valuationDate: [undefined],
      valuationAmount: [undefined],
      completionDate: [undefined],
      productEndDate: [undefined],
      notProceedingReason: [""],
      notProceedingFollowUpReason: [""],
      declinedReasonId: [null, Validators.required],
      declinedReasonOther: [""],
      _declinedReason: [""],
      reasonOther: [""],
      offerExpiryDate: [
        undefined,
        [Validators.required, HfValidators.minDate(this.nowNative)],
      ],
      journal: this.fb.group({
        notifyClient: [false],
        internalNotes: [""],
        messageToClient: [""],
        connectedDocument: this.fb.group({
          attachment: this.fb.group(
            new FileControlsConfigBuilder().maxFileSizeEmail().build()
          ),
        }),
      }),
      mortgageOffer: this.fb.group(
        new FileControlsConfigBuilder().maxFileSizeEmail().build()
      ),
      kfiDocument: this.fb.group(
        new FileControlsConfigBuilder().maxFileSizeEmail().build()
      ),
      commission: [null, Validators.required],

      exchangedDate: [null, Validators.required],
      targetCompletionDate: [null, Validators.required],
      lenderReference: [null, Validators.required],

      propCodeId: [null, Validators.required],
      _propCode: [null],
      _propCodeDescription: [null],
    });

    this.form.get("declinedReasonId").disable();

    this.mortgagesService.mortgageDashboardDataLoaded
      .pipe(take(1))
      .subscribe(() => {
        const application = this.application;
        let state = application.state;

        state != "SUBMITTED" || (state = "RECEIVED_BY_LENDER");
        this.mortgagesService.statusSelected$.next(state);

        this.isTransfer = isTransferOrAdvance(this.mortgagesService.data);

        if (application.propCodeId) {
          this.control("propCodeId").setValue(application.propCodeId);
          this.control("_propCode").setValue(application.propCode);
          this.control("_propCodeDescription").setValue(
            application.propCodeDescription
          );
        }
      });

    this.mortgagesService.statusSelected$
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => this.onStatusChanged(status));

    this.notProceedingReasonsService.notProceedingReasonSelected$
      .pipe(takeUntil(this.destroy$))
      .subscribe((reason) => this.onNotProceedingReasonChanged(reason));

    this.notProceedingReasonsService.notProceedingReasonDataUpdated$
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => this.setNotProceedingReasonsData(data));

    this.selectDateAndTimeService.dateSelected$
      .pipe(takeUntil(this.destroy$))
      .subscribe((date) => {
        const control = this.form.get(date.name);
        control && control.setValue(date.date);
      });

    this.selectPropcoService.code$
      .pipe(takeUntil(this.destroy$))
      .subscribe((code) => {
        if (code) {
          this.control("propCodeId").setValue(code.id);
          this.control("_propCode").setValue(code.code);
          this.control("_propCodeDescription").setValue(
            code.productDescription
          );
        }
      });

    this.selectDecliningReasonService.followup$
      .pipe(takeUntil(this.destroy$))
      .subscribe(reason => {
        this.control("_declinedReason").patchValue(reason);
        this.control("declinedReasonId").patchValue(reason.followup.id);
        this.control("declinedReasonOther").patchValue(reason.other);
      });

    this.initialize();
  }

  public onSelectDecliningReasonClicked() {
    this.selectDecliningReasonService.notProceedingFollowups = this.notProceedingReasonsData.notProceedingFollowups;

    this.router.navigate(["selectDecliningReason"], { relativeTo: this.route });
  }

  public setNotProceedingReasonsData(event: NotProceedingReasonUpdateData) {
    this.notProceedingReasonsData = event;

    setControlEnabled(this.control("declinedReasonId"), !!this.notProceedingReasonsData?.notProceedingFollowups?.length);
  }

  public onSelectPropcodeClicked() {
    const lenderId = this.application.lenderId;

    this.router.navigate(["selectPropco"], {
      relativeTo: this.route,
      queryParams: { lenderId: lenderId },
    });
  }

  public setupDocumentAttachments() {
    const notifyClient: boolean = this.form.get("journal.notifyClient").value;
    const state = this.state;

    if (state === "MORTGAGE_OFFER_ISSUED" || notifyClient) {
      this.form.get("journal.connectedDocument").enable();
      this.form.get("journal.messageToClient").enable();
      this.form
        .get("mortgageOffer")
        .get(FileControlsConfigBuilder.FILE_NAME)
        .setValidators(HfValidators.required("Mortgage offer is required"));

      if (this.isTransfer) {
        this.form
          .get("kfiDocument")
          .get(FileControlsConfigBuilder.FILE_NAME)
          .clearValidators();
      } else {
        this.form
          .get("kfiDocument")
          .get(FileControlsConfigBuilder.FILE_NAME)
          .setValidators(
            HfValidators.required("Key Facts Illustration (KFI) is required")
          );
      }
    } else {
      this.form.get("journal.connectedDocument").disable();
      this.form.get("journal.messageToClient").disable();
      this.form
        .get("mortgageOffer")
        .get(FileControlsConfigBuilder.FILE_NAME)
        .clearValidators();
      this.form
        .get("kfiDocument")
        .get(FileControlsConfigBuilder.FILE_NAME)
        .clearValidators();
    }

    this.form
      .get("journal.messageToClient")
      .setValidators(
        state === "MORTGAGE_OFFER_ISSUED" ? [] : Validators.required
      );
    this.form.get("journal.connectedDocument").updateValueAndValidity();
    this.form.get("journal.messageToClient").updateValueAndValidity();
    this.form
      .get("mortgageOffer")
      .get(FileControlsConfigBuilder.FILE_NAME)
      .updateValueAndValidity();
    this.form
      .get("kfiDocument")
      .get(FileControlsConfigBuilder.FILE_NAME)
      .updateValueAndValidity();
  }

  public onNotProceedingReasonChanged(reason: string) {
    this.form.get("notProceedingReason").setValue(reason);
  }

  public onStatusChanged(status: APPLICATION_STATE) {
    this.form.get("state").setValue(status);
    this.form.get("journal.notifyClient").enable();
    this.form.get("valuationDate").clearValidators();
    this.form.get("valuationDate").disable();
    this.form.get("valuationAmount").clearValidators();
    this.form.get("valuationAmount").disable();
    this.form.get("completionDate").clearValidators();
    this.form.get("completionDate").disable();
    this.form.get("productEndDate").clearValidators();
    this.form.get("productEndDate").disable();
    this.form.get("mortgageOffer").disable();
    this.form.get("kfiDocument").disable();
    this.form.get("offerExpiryDate").disable();
    this.form.get("commission").disable();
    this.form.get("exchangedDate").disable();
    this.form.get("targetCompletionDate").disable();
    this.form.get("lenderReference").disable();
    this.form.get("propCodeId").disable();

    switch (status) {
      case "MORTGAGE_OFFER_ISSUED":
        this.form.get("journal.notifyClient").disable();
        this.form.get("mortgageOffer").enable();
        this.form.get("kfiDocument").enable();
        this.form.get("offerExpiryDate").enable();
        this.form.get("commission").enable();
        if (this.commission) {
          this.form.get("commission").setValue(this.commission);
        }
        break;

      case "VALUATION_RECEIVED":
        this.form.get("valuationDate").enable();
        this.form
          .get("valuationDate")
          .setValidators([
            Validators.required,
            HfValidators.maxDate(this.nowNative),
          ]);
        this.form.get("valuationAmount").enable();
        this.form.get("valuationAmount").setValidators(Validators.required);
        break;

      case "VALUATION_BOOKED":
        this.form.get("valuationDate").enable();
        this.form
          .get("valuationDate")
          .setValidators([
            Validators.required,
            HfValidators.minDate(this.nowNative),
          ]);
        break;

      case "COMPLETED":
        this.form.get("completionDate").enable();
        this.form
          .get("completionDate")
          .setValidators([
            Validators.required,
            HfValidators.maxDate(this.nowNative),
          ]);
        this.form.get("productEndDate").enable();
        this.form
          .get("productEndDate")
          .setValidators([
            Validators.required,
            HfValidators.minDate(this.nowNative),
          ]);

        if (this.mortgagesService.isRemo) {
          this.form.get("lenderReference").enable();
          this.application?.lenderRefRegex
            ? this.form
                .get("lenderReference")
                .setValidators([
                  Validators.required,
                  Validators.pattern(this.application.lenderRefRegex),
                ])
            : this.form
                .get("lenderReference")
                .setValidators(Validators.required);

          this.form.get("propCodeId").enable();
          this.initializePropco();
        }
        break;

      case "EXCHANGED":
        this.form.get("exchangedDate").enable();
        this.form
          .get("exchangedDate")
          .setValidators([
            Validators.required,
            HfValidators.maxDate(this.nowNative),
          ]);
        this.form.get("targetCompletionDate").enable();
        this.form
          .get("targetCompletionDate")
          .setValidators([
            Validators.required,
            HfValidators.minDate(this.nowNative),
          ]);

        this.form.get("lenderReference").enable();
        this.application?.lenderRefRegex
          ? this.form
              .get("lenderReference")
              .setValidators([
                Validators.required,
                Validators.pattern(this.application.lenderRefRegex),
              ])
          : this.form.get("lenderReference").setValidators(Validators.required);

        this.form.get("propCodeId").enable();
        this.initializePropco();
        break;
    }

    this.form.get("valuationDate").setValue(undefined);

    for (let k in this.form.controls) {
      this.form.controls[k].updateValueAndValidity();
    }

    this.setupDocumentAttachments();
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.mortgagesService.statusSelected$.next(undefined);
    this.notProceedingReasonsService.setNotProceedingReasonSelected(undefined);
  }

  public async onSubmit() {
    this.isSubmitted = true;

    if (!this.formFieldService.checkFormValidity(this.form)) return;

    const application = this.form.value;

    if (this.notProceedingReasonsData) {
      application.notProceeding = this.notProceedingReasonsData;
        application.notProceeding.declinedReasonId =
          this.form.get("declinedReasonId").value || null;
      application.notProceeding.declinedReasonOther =
        this.form.get("declinedReasonOther").value || null;

        // deleting these from form as they were added earlier only to leverage functions of reactive forms
        delete application.reasonOther;
        delete application.notProceedingFollowUpReason;
        delete application.declinedReasonOther;
        delete application.notProceedingReason;
        delete application.notProceeding.notProceedingFollowups;
        delete application._declinedReason;
        delete application.notProceedingFollowups;
    }

    if (application.valuationDate) {
      application.valuationDate = this.selectDateAndTimeService.toLocalDateISO(
        application.valuationDate as any
      );
    }
    if (application.completionDate) {
      application.completionDate = this.selectDateAndTimeService.toLocalDateISO(
        application.completionDate as any
      );
    }
    if (application.productEndDate) {
      application.productEndDate = this.selectDateAndTimeService.toLocalDateISO(
        application.productEndDate as any
      );
    }
    if (application.offerExpiryDate) {
      application.offerExpiryDate =
        this.selectDateAndTimeService.toLocalDateISO(
          application.offerExpiryDate as any
        );
    }

    if (application.exchangedDate) {
      application.exchangedDate = this.selectDateAndTimeService.toLocalDateISO(
        application.exchangedDate as any
      );
    }
    if (application.targetCompletionDate) {
      application.targetCompletionDate =
        this.selectDateAndTimeService.toLocalDateISO(
          application.targetCompletionDate as any
        );
    }

    if (
      application.journal.connectedDocument &&
      !application.journal.connectedDocument.attachment.filename
    ) {
      delete application.journal.connectedDocument;
    }

    application.journal.internalNotes ||
      delete application.journal.internalNotes;
    application.journal.messageToClient ||
      delete application.journal.messageToClient;

    (application.kfiDocument && application.kfiDocument.filename) ||
      delete application.kfiDocument;
    (application.mortgageOffer && application.mortgageOffer.filename) ||
      delete application.mortgageOffer;

    this.isSubmitting = true;

    try {
      await this.mortgageApiService.setApplicationStatus(
        this.applicationId,
        application
      );

      this.toastService.add(
        `Application status now ${this.APPLICATION_STATE_NAMES.get(
          application.state
        )}`
      );
      this.mortgagesService.reloadMortgageDashboard.emit();
      this.onBack();
    } catch (err) {
      this.toastService.add(
        "Failed to change application status",
        "error",
        err
      );
    }

    this.isSubmitting = false;
  }

  public onBack() {
    this.router.navigate(["../.."], { relativeTo: this.route });
  }

  public getPropcoValue() {
    return this.value.propCodeId
      ? `${this.value._propCodeDescription} (${this.value._propCode})`
      : null;
  }

  private initializePropco() {
    const application = this.application;

    if (application.propCodeId) {
      this.control("propCodeId").setValue(application.propCodeId);
      this.control("_propCode").setValue(application.propCode);
      this.control("_propCodeDescription").setValue(
        application.propCodeDescription
      );
    }
  }

  private async initialize() {
    this.isLoading = true;

    try {
      const commissionDto = await this.mortgageApiService.getCommission(
        this.applicationId
      );

      if (commissionDto?.commission) {
        this.commission = commissionDto.commission;

        if (this.state === "MORTGAGE_OFFER_ISSUED") {
          this.form.get("commission").setValue(this.commission);
        }
      }
    } catch (e) {
      this.toastService.add("Error getting commision amount", "error", e);
    }

    if (this.application?.lenderReference) {
      this.form
        .get("lenderReference")
        .setValue(this.application.lenderReference);
    }

    this.isLoading = false;
  }
}
