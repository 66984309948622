<hfc-review-section heading="Signatures">
  <ng-container *ngFor="let applicant of data?.applicants">
    <div *ngIf="applicant.signed" class="person">
      <div *ngIf="!applicant.signedByHeron" class="applicant">
        Signed by {{ applicant.client.fullName }} <br>
        {{applicant.signed | dateOrdinal:'E R MMM y HH:mm'}}
      </div>

      <div *ngIf="applicant.signedByHeron" class="applicant">
        Signed on behalf of {{ applicant.client.fullName }} <br>
        by {{ applicant.signedBy.fullName }} at Heron <br>
        {{applicant.signed | dateOrdinal:'E R MMM y HH:mm'}}
      </div>

      <div *ngIf="!applicant.signedByHeron" class="sign">
        <img *ngIf="applicant.signature" [src]="getSignatureUrl(applicant.signature)">

        <div *ngIf="applicant.textSignature">{{ applicant.textSignature }}</div>
      </div>
    </div>
  </ng-container>

  <div *ngIf="!hasSignature()" class="notReady">
    Waiting for applicant to complete
  </div>
</hfc-review-section>
