import { Component, Input } from '@angular/core';
import { ImportedClientDataDTO } from '../../models/mortgage-imported-client-data.dto';
import { GENDER_NAME } from '../../../../../projects/client/src/app/fact-find/enums.module';

@Component({
  selector: 'hf-client-imported-data',
  templateUrl: './client-imported-data.component.html',
  styleUrls: ['./client-imported-data.component.scss'],
  host: { class: "hf-imported-data" },
})
export class ClientImportedDataComponent {
  public readonly GENDER_NAME = GENDER_NAME;

  @Input()
  public applicants: ImportedClientDataDTO[];

  public getApplicantNameHeading(applicant: ImportedClientDataDTO, lead: boolean): string {
    const prefix = lead ? 'Lead' : 'Sub';
    return `${prefix} Applicant - ${applicant.client?.fullName}`;
  }
}
