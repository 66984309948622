import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { ToastService } from '../../../../../../projects/client/src/app/shared/services/toast.service';
import { Attachment } from '../../../models/attachment';
import { SuitabilityLetterDTO } from '../../../models/suitability-letter.dto';

export interface SuitabilityPdfPreviewAdapter {
  getHandovers?(): any[];
  getHandover?(): any;
}

export abstract class SuitabilityPdfPreviewAdapter {
  abstract initialize(handoverId?: string): Observable<SuitabilityLetterDTO>;
  abstract initializeTrigger(): Observable<any>;
  abstract send(handoverId?: string): Observable<any>;
}

const checkAgainDelay = 5000;

@Component({
  selector: 'hf-suitability-pdf-preview',
  templateUrl: './suitability-pdf-preview.component.html',
  styleUrls: ['./suitability-pdf-preview.component.scss']
})
export class SuitabilityPdfPreviewComponent implements OnDestroy {
  public isLoading = true;
  public isSubmitting = false;
  public attachment: Attachment;
  public handovers: any[];
  public handover: any;

  private timeoutId = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
    private adapter: SuitabilityPdfPreviewAdapter,
  ) {
    this.adapter.initializeTrigger().pipe(take(1)).subscribe(() => this.initialize());
  }

  public onSendClicked() {
    this.isSubmitting = true;

    this.adapter.send(this.handover?.id).subscribe(
      () => {
        this.toastService.add("Suitability letter was queued for sending");
        this.isSubmitting = false;
        this.onCancelClicked();
      },
      e => {
        this.toastService.add("Failed to send suitability letter", "error", e);
        this.isSubmitting = false;
      }
    );
  }

  public onCancelClicked() {
    this.router.navigate([".."], { relativeTo: this.route });
  }

  public onHandoverChange(handover: any) {
    console.log("onHandoverChange", handover);
    this.handover = handover;
    this.initializeLetter();
  }

  public ngOnDestroy() {
    this.timeoutId && clearTimeout(this.timeoutId);
  }

  private initializeLetter() {
    this.isLoading = true;

    this.adapter.initialize(this.handover?.id).subscribe(
      result => {
        if (result.state === "READY" || result.state === "SENT_TO_CLIENT") {
          this.attachment = result.attachment;
          this.isLoading = false;
        }
        if (result.state === "GENERATING") {
          this.timeoutId = setTimeout(() => this.initializeLetter(), checkAgainDelay);
        }
        if (result.state === "ERROR") {
          this.toastService.add("Error getting letter", "error");
          this.isLoading = false;
          this.onCancelClicked();
        }
      },
      e => {
        this.toastService.add("Error getting letter", "error", e);
        this.isLoading = false;
      }
    );
  }

  private initialize() {
    this.initializeLetter();

    if (this.adapter.getHandovers) {
      this.handovers = this.adapter.getHandovers();
      this.handover = this.adapter.getHandover();
    }
  }
}
