import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';

import { EMPLOYMENT_STATUS_NAME, PERSONAL_TITLE_NAME } from '../../../../../../../projects/client/src/app/fact-find/enums.module';
import { getToday } from '../../../../../../../projects/client/src/app/shared/functions/get-today';
import { ApplicantPrerequisitesAddDTO, GenderNoOtherName, MetricTypeName, MetricType } from '../../../../models/applicant-prerequisites-add.dto';
import { SelectorSidepaneService } from '../../../../shared-layout/side-panes/selector-sidepane/selector-sidepane.service';
import { fadeAnimation, sidepaneMove } from '../../../../shared/animations/sidepane-animation';
import { SelectOrigoCodeService } from '../select-origo-code/select-origo-code.service';
import { takeUntil } from 'rxjs/operators';
import { ProtectionApiService } from '../../../../services/protection-api.service';
import { AddApplicantService } from './add-applicant.service';
import { ToasterService } from '../../../../services/toaster.service';
import { setControlEnabled } from '../../../../utils/functions/set-control-enabled';
import { SelectOrAddClientService } from '../../../../shared-layout/side-panes/select-or-add-client/select-or-add-client.service';
import { Confirmation, DialogService } from '../../../../../../../projects/client/src/app/shared/services/dialog.service';

@Component({
  selector: 'hf-add-applicant',
  templateUrl: './add-applicant.component.html',
  styleUrls: [
    './add-applicant.component.scss',
    '../../../../styles/sidepanes.partial.scss'
  ],
  animations: [sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove]': 'true' }
})
export class AddApplicantComponent {
  public form = this.fb.group({
    client: [null, Validators.required],
    title: [null],
    gender: [null, Validators.required],
    smokerLastYear: [false, Validators.required],
    dateOfBirth: [null, Validators.required],
    metricType: [null],
    heightFeet: [null],
    heightInches: [null],
    heightCm: [null],
    weightStone: [null],
    weightPounds: [null],
    weightKg: [null],
    employmentStatus: [null],
    origoCode: [null, Validators.required],
    annualIncome: [null],
    lead: [null],
  });

  public PERSONAL_TITLE_NAME = PERSONAL_TITLE_NAME;
  public EMPLOYMENT_STATUS_NAME = EMPLOYMENT_STATUS_NAME;
  public GenderNoOtherName = GenderNoOtherName;
  public MetricTypeName = MetricTypeName;

  public isLoading = false;
  public isSubmitting = false;
  public today = getToday();
  public get value() { return this.form.value as ApplicantPrerequisitesAddDTO }
  public get canEdit() { return this.addApplicantService.canEdit }
  public control(name: string) { return this.form.get(name) }

  public isFinanciallyActive() { return ["EMPLOYED", "SELF_EMPLOYED", "CONTRACTOR"].includes(this.value.employmentStatus) }
  public get isEditMode() { return !!this.clientId }

  private get protectionCaseId() { return this.route.snapshot.paramMap.get("id") }
  private get clientId() { return this.route.snapshot.paramMap.get("clientId") }
  private destroy$ = new Subject();

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private selectorSidepaneService: SelectorSidepaneService,
    private selectOrigoCodeService: SelectOrigoCodeService,
    private protectionApiService: ProtectionApiService,
    private addApplicantService: AddApplicantService,
    private selectOrAddClientService: SelectOrAddClientService,
    private toasterService: ToasterService,
    private dialogService: DialogService,
  ) {
    this.selectorSidepaneService.subscribeByKey("selectTitle", this.destroy$).subscribe(title => {
      this.form.patchValue({ title });
    });

    this.selectOrigoCodeService.codeSelected$.pipe(takeUntil(this.destroy$)).subscribe(origoCode => {
      this.form.patchValue({ origoCode })
    });

    this.selectOrAddClientService.add.pipe(takeUntil(this.destroy$))
      .subscribe(client => this.form.get('client').patchValue(client));
    this.selectOrAddClientService.select.pipe(takeUntil(this.destroy$))
      .subscribe(client => this.form.get('client').patchValue(client));

    this.control("metricType").valueChanges.subscribe((metricType: MetricType) => {
      setControlEnabled(this.control("heightCm"), metricType === "METRIC");
      setControlEnabled(this.control("weightKg"), metricType === "METRIC");

      setControlEnabled(this.control("heightFeet"), metricType === "IMPERIAL");
      setControlEnabled(this.control("heightInches"), metricType === "IMPERIAL");
      setControlEnabled(this.control("weightStone"), metricType === "IMPERIAL");
      setControlEnabled(this.control("weightPounds"), metricType === "IMPERIAL");
    })

    this.control("employmentStatus").valueChanges.subscribe(() => {
      setTimeout(() => {
        this.control("employmentStatus").setValidators(this.isFinanciallyActive() ? [Validators.required] : undefined);

        this.isFinanciallyActive()
          ? this.control("origoCode").enable()
          : this.control("origoCode").disable();
      });
    });

    this.control("metricType").setValue("IMPERIAL");
    this.control("employmentStatus").setValue(null);

    this.isEditMode && this.initialize();
  }

  public onSelectTitleClicked() {
    this.router.navigate(["selectTitle"], { relativeTo: this.route });
  }

  public onSelectOrigoCodeClicked() {
    this.router.navigate(["selectOrigoCode"], { relativeTo: this.route });
  }

  public onSelectClientClicked() {
    this.selectOrAddClientService.client = this.form.value?.client;

    this.router.navigate(["selectOrAddClient"], { relativeTo: this.route });
  }

  public async onSubmit() {
    this.isSubmitting = true;

    try {
      await this.save();

      this.toasterService.callToaster({ severity: 'info', summary: 'Info', detail: 'Applicant saved.' });
      this.addApplicantService.applicantAdded$.next();
      this.onBack();
    } catch (err) {
      this.toasterService.callToaster({ severity: 'error', summary: 'Error', detail: `Failed. Please try again. ${err.error.error.message}` });
    } finally {
      this.isSubmitting = false;
    }
  }

  public onBack() {
    const route = this.isEditMode
      ? ["../.."]
      : [".."];

    this.router.navigate(route, { relativeTo: this.route });
  }

  public async onRemove() {
    const confirmation: Confirmation = {
      title: "Delete Applicant",
      message: "Are you sure you want to delete this applicant?",
      acceptLabel: "Delete Applicant",
      acceptButtonType: "red"
    }

    if (!await this.dialogService.confirm(confirmation)) {
      return;
    }

    this.isSubmitting = true;

    try {
      await this.protectionApiService.deleteApplicant(this.protectionCaseId, this.clientId);
      this.toasterService.callToaster({ severity: 'info', summary: 'Info', detail: 'Applicant deleted.' });
      this.addApplicantService.applicantAdded$.next();
      this.onBack();
    } catch (err) {
      this.toasterService.callToaster({ severity: 'error', summary: 'Error', detail: `Failed. Please try again. ${err.error.error.message}` });
    } finally {
      this.isSubmitting = false;
    }
  }

  private save() {
    return this.isEditMode
      ? this.protectionApiService.editApplicantPrerequisites(this.protectionCaseId, this.clientId, this.value)
      : this.protectionApiService.addApplicantWithPrerequisites(this.protectionCaseId, this.value);
  }

  private async initialize() {
    this.isLoading = true;

    const value = await this.protectionApiService.viewApplicantPrerequisites(this.protectionCaseId, this.clientId);
    this.form.patchValue(value);

    this.isLoading = false;
  }
}
