<div (click)="onBack()" class="overlay" [@fadeAnimation]></div>

<div class="sidepane-content">
  <div class="flex-space-between-layout template-header">
    <h1>{{toggleNotificationService.applicantName}}</h1>
    <hf-custom-button type="button" class="button-plain-icon" icon="close" iconPos="center" (onClick)="onBack()">
    </hf-custom-button>
  </div>

  <div class="template-main">
    <form [formGroup]="form">
      <hf-checkbox label="Send Mortgage Notifications" formControlName="value">
      </hf-checkbox>
    </form>
  </div>

  <div class="template-footer">
    <hfc-button type="primary" [isLoading]="isSubmitting" (click)="onSubmit()">Done</hfc-button>
    <hfc-button type="secondary" (click)="onBack()">Cancel</hfc-button>
  </div>
</div>
