import { Injectable } from "@angular/core";
import { ParamMap } from "@angular/router";
import { Store } from "@ngxs/store";
import { Subject } from "rxjs";

import { JournalWithReasonDTO } from "../../../models/journal-with-reason.dto";
import { MortgageApiService } from "../../../services/mortgage-api.service";
import { NotProceedingService } from "../../../shared-layout/side-panes/not-proceeding/not-proceeding.component";
import { Mortgage } from "../state/mortgage.actions";

@Injectable()
export class MortgageNotProceedingService implements NotProceedingService {
  hasReason = true;
  stateChange$ = new Subject<void>();

  constructor(
    private store: Store,
    private mortgageApiService: MortgageApiService
  ) { }

  public setNotProceeding(paramMap: ParamMap, journalWithReason: JournalWithReasonDTO): Promise<void> {
    const mortgageId = paramMap.get("id");

    return this.mortgageApiService.setMortgageNotProceeding(mortgageId, journalWithReason)
      .then(() => this.store.dispatch(new Mortgage.SetNotProceeding()) as any);
  }
}
