import { Component, Input } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'hf-decimal',
  templateUrl: './decimal.component.html',
  styleUrls: ['./decimal.component.scss'],
  providers: [DecimalPipe],
})
export class DecimalComponent {
  @Input()
  public value: number;
}
