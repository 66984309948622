import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { merge, Subscription } from "rxjs";
import { AuthenticationService } from "../../../services/authentication.service";
import { TemplateService } from "../../../services/template.service";
import { ToasterService } from "../../../services/toaster.service";
import { UserService } from '../../../services/user.service';
import { fadeAnimation, sidepaneMove } from "../../../shared/animations/sidepane-animation";
import {Router} from '@angular/router';

@Component({
  selector: 'hf-change-password',
  templateUrl: 'hf-change-password.component.html',
  styleUrls: ['hf-change-password.component.scss',
    '../../../styles/sidepanes.partial.scss'],
  animations: [sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove]': 'true' }
})

export class ChangePasswordComponent implements OnInit, OnDestroy {
  public changePassFG: FormGroup;
  private _passwordsUpdatedSub: Subscription;

  public notValidPassword: string;
  public email: string;
  public submitted: boolean;

  constructor(
    private router: Router,
    private templateService: TemplateService,
    private fb: FormBuilder,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private toasterService: ToasterService
  ) {
    this.changePassFG = this.fb.group({
      'currentPassword': ['', Validators.required],
      'newPassword': ['', Validators.minLength(8)],
      'newPasswordRep': ['', Validators.required],
      'newPasswordNotValid': ['', Validators.required],
    });

    this.email = this.userService.getUser().email;
  }

  ngOnInit() {
    const passwordsUpdated = merge(
      this.changePassFG.get('newPassword').valueChanges,
      this.changePassFG.get('newPasswordRep').valueChanges,
    );

    this._passwordsUpdatedSub = passwordsUpdated.subscribe(() => {
      if (this.changePassFG.get('newPassword').value != this.changePassFG.get('newPasswordRep').value) {
        this.changePassFG.get('newPasswordNotValid').setErrors({ 'passwordsDontMatch': true });
      } else {
        this.changePassFG.get('newPasswordNotValid').setErrors(null);
      }
    });
  }

  onBack() {
    const url = this.router.routerState.snapshot.url;
    const before = url.replace("(settings:password)", "");
    this.router.navigateByUrl(before);
  }

  ngOnDestroy() {
    this._passwordsUpdatedSub.unsubscribe();
  }

  public onSubmit() {
    this.submitted = true;
    this.authenticationService.changePassword(this.changePassFG.value).then(() => {
      this.toasterService.callToaster({ severity: 'info', summary: 'Info', detail: `Password Changed` });
      this.onBack();
    }).catch(err => {
      this.submitted = false;

      if (err.status == 409) {
        this.notValidPassword = this.changePassFG.get('currentPassword').value;
      } else {
        this.toasterService.callToaster({ severity: 'error', summary: 'Error', detail: `${err.error.error.message}` });
      }
    })
  }
}
