import { Component, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ToastService } from '../../../../../../projects/client/src/app/shared/services/toast.service';
import { NewComplianceHandoverViewDTO } from '../../../models/new-compliance-handover-view.dto';
import { NewComplianceHandoverDTO } from '../../../models/new-compliance-handover.dto';
import { MortgageApiService } from '../../../services/mortgage-api.service';
import { fadeAnimation, sidepaneMoveNew } from '../../../shared/animations/sidepane-animation';
import { HandoverSection, HandoverSectionName, HandoverSectionState } from '../../../utils/variables.data';
import { MortgagesService, ReloadMortgageDashboardEvent } from '../mortgages.service';
import * as R from "ramda";
import { Confirmation, DialogService } from '../../../../../../projects/client/src/app/shared/services/dialog.service';
import { take } from 'rxjs/operators';

// const mock = {
//   objectiveState: "COMPLETED",
//   preferenceState: "STARTED",
//   budgetState: "NOT_STARTED",
//   recommendedState: "LEGACY",
// } as NewComplianceHandoverDTO;

interface SectionItem {
  section: HandoverSection;
  name: string;
  state: HandoverSectionState;
}

export const isToComplianceVisible = (dto: NewComplianceHandoverViewDTO) => {
  return dto?.state === "DRAFT"
    && dto?.objectiveState === "COMPLETED"
    && dto?.preferenceState === "COMPLETED"
    && dto?.budgetState === "COMPLETED"
    && dto?.recommendedState === "COMPLETED"
}

const getSections = (dto: NewComplianceHandoverDTO) => {
  const sections: SectionItem[] = [
    {
      section: "CIRCUMSTANCES_AND_OBJECTIVES",
      name: HandoverSectionName.get("CIRCUMSTANCES_AND_OBJECTIVES"),
      state: dto.objectiveState,
    },
    {
      section: "PREFERENCES",
      name: HandoverSectionName.get("PREFERENCES"),
      state: dto.preferenceState,
    },
    {
      section: "BUDGET_AND_AFFORDABILITY",
      name: HandoverSectionName.get("BUDGET_AND_AFFORDABILITY"),
      state: dto.budgetState,
    },
    {
      section: "RECOMMENDED_MORTGAGE",
      name: HandoverSectionName.get("RECOMMENDED_MORTGAGE"),
      state: dto.recommendedState,
    },
  ]

  return sections;
}

@Component({
  selector: 'hf-new-compliance-handover',
  templateUrl: './new-compliance-handover.component.html',
  styleUrls: ['./new-compliance-handover.component.scss',
    '../../../styles/sidepanes.partial.scss'],
  animations: [fadeAnimation, sidepaneMoveNew],
  host: {'[@sidepaneMoveNew]': 'true'},
})
export class NewComplianceHandoverComponent implements OnDestroy {
  public dto: NewComplianceHandoverViewDTO;
  public dto$ = new Subject<NewComplianceHandoverViewDTO>();
  public saveClick$ = new Subject<boolean>();
  public isValid = false;
  public sections: SectionItem[];

  private initSections() {
    this.sections = getSections(this.dto);
    this.goToNextUncompleted(this.activeSection);
  }

  public activeSection: SectionItem;

  public HandoverSectionName = HandoverSectionName;

  public get mortgageId() { return this.route.snapshot.paramMap.get("id") }
  public get handoverId() { return this.route.snapshot.paramMap.get("handoverId") }
  public get isEdit() { return !!this.handoverId && !this.isDefault && !this.isNew }
  public get isLegacy() { return this.dto?.objectiveState === "LEGACY" }
  public get isDefault() { return this.handoverId === "default" }
  public get isNew() { return this.handoverId === "new" }

  public isLoading = true;
  public isSubmitting = false;
  public isSaving = false;

  private destroy$ = new Subject();

  public form: FormGroup;
  private oldFormValue: any = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toast: ToastService,
    private mortgagesService: MortgagesService,
    private mortgageApiService: MortgageApiService,
    private dialogService: DialogService,
  ) {
    this.route.paramMap.subscribe(() =>
      this.mortgagesService.mortgageDashboardDataLoaded.pipe(take(1)).subscribe(() => this.initialize())
    )
  }

  public assignForm(form: FormGroup) {
    this.form = form;
    this.oldFormValue = R.clone(this.form.value);
  }

  public save(form: FormGroup, api) {
    if (form.pending) {
      return;
    }

    this.isSaving = true;
    this.oldFormValue = null;

    form.updateValueAndValidity();

    const call = api();

    call.subscribe(
      dto => {
        this.isSaving = false;
        this.toast.add(`${this.activeSection.name} saved`);
        this.dto = dto;
        this.dto$.next(dto);
        this.initSections();

        if (this.dto.state === "AWAITING_APPROVAL" || this.dto.state === "APPROVED") {
          this.onBack(true);
        }

        setTimeout(() => {
          const event: ReloadMortgageDashboardEvent = { tab: "client-sheet", handover: dto };
          this.mortgagesService.reloadMortgageDashboard.emit(event);
        });
      },
      e => {
        this.isSaving = false;
        this.toast.add("Error: ", "error", e);
      }
    );
  }

  public onSectionClicked(section: SectionItem) {
    this.activeSection = section;
  }

  public onSaveClicked() {
    this.saveClick$.next(false);
  }

  public onSaveDraftClicked() {
    this.saveClick$.next(true);
  }

  public onToComplianceClicked() {
    this.isSubmitting = true;

    this.mortgageApiService.completeHandover(this.mortgageId, this.handoverId).subscribe(
      () => {
        this.isSubmitting = false;
        this.toast.add("Handover send for approval");
        this.mortgagesService.reloadMortgageDashboard.next();
        this.onBack(true);
      },
      e => {
        this.isSubmitting = false;
        this.toast.add("Error: ", "error", e);
      }
    );
  }

  public async onBack(doNotAsk = false) {
    if (!doNotAsk) {
      const could = await this.couldLeave();
      if (!could) return;
    }

    const path = this.isEdit ? ["../.."] : [".."];
    this.router.navigate(path, { relativeTo: this.route });
  }

  public ngOnDestroy() {
    console.log("destroy");
    this.destroy$.next();
  }

  public isSaveDraftVisible() {
    return this.activeSection?.state !== "COMPLETED" && !this.isValid;
  }

  public isSaveVisible() {
    return this.activeSection?.state === "COMPLETED" || this.isValid;
  }

  public isToComplianceVisible() { 
    return isToComplianceVisible(this.dto);
  }

  public async showDiscardChangesPopup(): Promise<boolean> {
    const confirmation: Confirmation = {
      title: "Unsaved Changes",
      message: "Your changes will be lost if you navigate away. You can discard them or go back to save.",
      acceptLabel: "Discard",
      rejectLabel: "Go Back",
    }

    const discard = await this.dialogService.confirm(confirmation);

    return discard;
  }

  public async couldLeave(): Promise<boolean> {
    if (!this.oldFormValue) return true;

    const equal = R.equals(this.oldFormValue, this.form.value);
    if (equal) return true;

    const discard = await this.showDiscardChangesPopup();

    return discard;
  }

  public clickCallback = async () => {
    return this.couldLeave();
  }

  private goToNextUncompleted(current: SectionItem) {
    if (current) {
      current = this.sections.find(s => s.section === current?.section);
    }
    const first = this.sections.find(s => s.state === "NOT_STARTED" || s.state !== "COMPLETED");
    if (first) {
      this.activeSection = first;
    } else {
      this.activeSection = current || this.sections[0];
    }
  }

  private initialize() {
    if (this.isEdit) {
      this.mortgageApiService.viewNewClientSheet(this.mortgageId, this.handoverId).subscribe(
        dto => {
          this.dto = dto;
          this.initSections();
          this.isLoading = false;
        },
        e => {
          this.toast.add("Error: ", "error", e);
          this.isLoading = false;
        }
      )
    } else {
      if (!this.isNew) {
        // Check for the last time the is no existing handover
        const handover = this.mortgagesService.getDefaultHandoverForSidepane();

        if (handover?.id) {
          this.router.navigate(["..", handover.id], { relativeTo: this.route });
          return;
        }
      }

      this.mortgageApiService.createNewComplianceHandover(this.mortgageId).subscribe(
        dto => {
          this.router.navigate(["..", dto.id], { relativeTo: this.route });
          this.mortgagesService.reloadMortgageDashboard.emit();
          return;
          // this.dto = dto;
          // this.initSections();
          // this.isLoading = false;
        },
        e => {
          this.toast.add("Error: ", "error", e);
          this.isLoading = false;
        }
      )
    }
  }
}

