<ng-container [formGroup]="group">
  <p-calendar [inline]="isInline"
              [minDate]="minDate"
              [maxDate]="maxDate"
              [monthNavigator]="monthNavigator"
              [yearNavigator]="yearNavigator"
              [yearRange]="yearRange"
              [formControlName]="pickerFormControlName"
              (onMonthChange)="onMonthChanged($event)"
              (onYearChange)="onMonthChanged($event)">
  </p-calendar>
</ng-container>
