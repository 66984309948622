import { Component } from '@angular/core';

import { TableSettingsService } from '../table-utils/table-settings.service';

@Component({
  selector: 'hf-paginator',
  templateUrl: 'paginator.component.html',
  styleUrls: ['paginator.component.scss']
})

export class PaginatorComponent {
  public get totalPages() { return this.tableSettingsService.totalPages; }

  public isPageActive(page: number) {
    return page == this.tableSettingsService.pageNum;
  }

  constructor(private tableSettingsService: TableSettingsService) { }

  public getIfShouldBeDisplayed(key) {
    if (this.tableSettingsService.pageNum < 4 && key < 6) {
      return true;
    }
    if (key > (this.tableSettingsService.pageNum - 3) && key < (this.tableSettingsService.pageNum + 3)) {
      return true;
    }
  }

  public pageClicked(page: number) {
    this.tableSettingsService.pageRequest(page);
  }

  public pageRelativeClicked(pageRelative: number) {
    const page = this.tableSettingsService.pageNum + pageRelative;

    if (page >= 1 && page <= this.tableSettingsService.totalElements) {
      this.tableSettingsService.pageRequest(page);
    }
  }

  public lastPageClicked() {
    this.tableSettingsService.pageRequest(this.tableSettingsService.totalPages);
  }
}
