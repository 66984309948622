import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, Inject, Input, OnDestroy, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { getToday } from '../../../../../../../../../projects/client/src/app/shared/functions/get-today';
import { MortgageDashboardApplication, MortgageDashboardAppointment, MortgageDashboardHelpToBuy, MortgageDashboardOfferQualification } from '../../../../../../models/mortgage-dashboard';
import { Environment } from '../../../../../../services/environment.service';
import { UserService } from '../../../../../../services/user.service';
import { ArrangeAppointmentService } from '../../../../../../shared-layout/side-panes/arrange-appointment/arrange-appointment.service';
import { RequestFactFindService, REQUEST_FACTFIND_SERVICE } from '../../../../../../shared-layout/side-panes/request-fact-find/request-fact-find.component';
import { InformationBoxAction, InformationBoxValue } from '../../../../../../shared/information-box/information-box.component';
import { APPLICATION_STATE_NAMES, FACT_FIND_STATE_NAMES, HELP_TO_BUY_STATUS_NAMES, MORTGAGE_ACTIONS, MORTGAGE_STATE_TYPE, MORTGAGE_WAITING_ON } from '../../../../../../utils/variables.data';
import * as moment from "moment";
import { Actions, ofActionSuccessful } from '@ngxs/store';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Mortgage } from '../../../../state/mortgage.actions';
import { mapAppointmentToValue } from '../../mortgage-dashboard-general.component';

@Component({
  selector: 'hf-mortgage-dashboard-general-boxes',
  templateUrl: './mortgage-dashboard-general-boxes.component.html',
  styleUrls: ['./mortgage-dashboard-general-boxes.component.scss'],
  providers: [CurrencyPipe],
})
export class MortgageDashboardGeneralBoxesComponent implements OnDestroy {
  @Input()
  public mortgage: any;

  public MORTGAGE_ACTIONS = MORTGAGE_ACTIONS;
  public MORTGAGE_WAITING_ON = MORTGAGE_WAITING_ON;

  public isFactFindVisible = false;
  public factFindValue: InformationBoxValue;
  public factFindAction: InformationBoxAction = {
    label: "Request",
    callback: () => this.onFactFindClicked(),
    disabled: !this.environment.requestFactFindEnabled
  }

  public isAppointmentVisible = false;
  public appointmentValue: InformationBoxValue;
  public appointmentAction: InformationBoxAction;

  public isApplicationVisible = false;
  public applicationValue: InformationBoxValue;
  public applicationAction: InformationBoxAction = {
    label: "Update Status",
    callback: () => this.onApplicationClicked()
  }

  public helpBuyValue: InformationBoxValue;
  public helpBuyAction: InformationBoxAction = {
    label: "Update Status",
    callback: () => { this.router.navigate(["updateHelpToBuy"], { relativeTo: this.route }) }
  }
  public get isHelpBuyVisible() { return this.mortgage?.clientScheme === "HELP_TO_BUY" }

  public qualificationValue: InformationBoxValue;
  public qualificationAction: InformationBoxAction;

  public today = getToday();

  private destroy$ = new Subject();

  constructor(
    private actions$: Actions,
    private route: ActivatedRoute,
    private router: Router,
    private environment: Environment,
    private userService: UserService,
    private currencyPipe: CurrencyPipe,
    private datePipe: DatePipe,
    private arrangeAppointmentService: ArrangeAppointmentService,
    @Inject(REQUEST_FACTFIND_SERVICE) private requestFactFindService: RequestFactFindService,
  ) { 
    this.actions$.pipe(takeUntil(this.destroy$), ofActionSuccessful(Mortgage.MenuAction)).subscribe((action: Mortgage.MenuAction) => {
      action.action === "arrangeAppointment" && this.onArrangeAppointmentClicked();
    });
  }

  public formatProductEndDate() {
    const productEndDate = this.mortgage.productEndDate;

    if (!productEndDate) {
      return "";
    }

    const days = moment(productEndDate).diff(moment(this.today), "days");

    return `${days} days`;
  }

  public onCreateApplicationClicked() {
    this.router.navigate(["createApplication"], { relativeTo: this.route });
  }

  public onApplicationStatusClicked(application: MortgageDashboardApplication) {
    this.router.navigate(["updateApplication", application.id], { relativeTo: this.route });
  }

  public onFactFindClicked() {
    if (this.mortgage?.factFind) {
      return this.router.navigate(["."], { relativeTo: this.route, queryParams: { tab: "fact-find" } });
    }

    this.requestFactFindService.requestFrom = [this.mortgage?.client.email];

    this.router.navigate(["requestFactFind"], { relativeTo: this.route });
  }

  public onApplicationClicked() {
    const route = this.mortgage.application ? "updateApplication" : "createApplication";

    this.router.navigate([route], { relativeTo: this.route });
  }

  public convertEnumToValue(type, en): string {
    if (!en)
      return null;
    return Object.entries(type).filter(([key, value]) => key === en)[0][1] as string;
  };

  public ngOnChanges(changes: SimpleChanges) {
    if (!("mortgage" in changes)) {
      return;
    }

    const factFind = this.mortgage.factFind;
    if (factFind) {
      this.factFindValue = { text: FACT_FIND_STATE_NAMES.get(factFind.status) }
      this.factFindAction.label = "View";
    }
    this.isFactFindVisible = this.hasMortgageManage() && this.matchesState(["QUALIFIED", "PARTIALLY_QUALIFIED", "IN_PROGRESS"]);

    const application = this.mortgage.application;
    if (application) {
      this.applicationValue = { text: APPLICATION_STATE_NAMES.get(application.state) }
      this.applicationAction.label = "Update Status";
    } else {
      this.applicationValue = undefined;
      this.applicationAction.label = "Create Application";
    }
    this.isApplicationVisible = (this.hasMortgageManage() && !!application) ||
      (
        this.matchesState(["QUALIFIED", "PARTIALLY_QUALIFIED", "IN_PROGRESS", "PREPARED", "SUBMITTED", "OFFERED"])
        && (this.mortgage && this.mortgage.complianceHandoverState === "APPROVED")
        && this.hasMortgageManage()
      )

    const mapAppointmentToAction = (appointment: MortgageDashboardAppointment): InformationBoxAction => ({
      label: appointment && (appointment.state !== "CANCELED" && appointment.state !== "PASSED")
        ? "View"
        : "Arrange",
      callback: () => this.onArrangeAppointmentClicked()
    });
    this.appointmentValue = mapAppointmentToValue(this.mortgage.appointment, this.datePipe);
    this.appointmentAction = mapAppointmentToAction(this.mortgage.appointment);
    this.isAppointmentVisible = this.hasMortgageManage() && (this.mortgage && this.mortgage.state) && !this.matchesState(["NOT_ASSIGNED", "NOT_PROCEEDING"]);

    const mapHelpToBuyToValue = (helpToBuy: MortgageDashboardHelpToBuy): InformationBoxValue => ({
      text: (helpToBuy && HELP_TO_BUY_STATUS_NAMES.get(helpToBuy.status))
    });
    this.helpBuyValue = mapHelpToBuyToValue(this.mortgage.helpToBuy);

    const mapQualificationToValue = (qualification: MortgageDashboardOfferQualification): InformationBoxValue => {
      const value: InformationBoxValue = { text: "" };
      let amount: number;

      if (!qualification || !qualification.status) {
        value.text = "Awaiting Qualification";
        return value;
      }

      if (qualification.status === "QUALIFIED") {
        amount = this.mortgage.desiredQualification;
        value.text = `Up to ${this.currencyPipe.transform(amount, "GBP", undefined, "1.0-0")}`;
      }

      if (qualification.status === "PARTIALLY_QUALIFIED") {
        amount = this.mortgage.offerQualification.qualifiedUpTo;
        value.text = `Up to ${this.currencyPipe.transform(amount, "GBP", undefined, "1.0-0")}`;
      }

      if (qualification.status === "RE_QUALIFICATION_REQUESTED") {
        amount = this.mortgage.desiredQualification;
        value.text = `Re-qualification Requested`;
      }

      if (qualification.status === "NOT_QUALIFIED") {
        value.text = "Not Qualified";
      }

      return value;
    }
    const mapQualificationToAction = (qualification: MortgageDashboardOfferQualification): InformationBoxAction => {
      const action: InformationBoxAction = {
        label: "Qualify Client",
        callback: () => {
          this.router.navigate(["offer-qualification"], { relativeTo: this.route })
        }
      };

      if (this.userService.hasPermission(["MORTGAGE_VIEW_INTRODUCER", "MORTGAGE_VIEW_DIVISION"])
        && this.matchesState(["AWAITING_QUALIFICATION", "QUALIFIED", "PARTIALLY_QUALIFIED", "NOT_QUALIFIED", "IN_PROGRESS", "PREPARED"])) {
        action.label = "Request Re-qualification ";
        action.callback = () => {
          this.router.navigate(["request-requalification"], { relativeTo: this.route })
        }

        return action;
      }

      const states: MORTGAGE_STATE_TYPE[] = ["AWAITING_FIRST_CONTACT", "CONTACT_ATTEMPTED", "AWAITING_QUALIFICATION", "QUALIFIED", "PARTIALLY_QUALIFIED", "IN_PROGRESS", "PREPARED"];

      if (!states.includes(this.mortgage.state) || !this.mortgage.desiredQualification) {
        return undefined;
      }

      if (qualification && (qualification.status === "PARTIALLY_QUALIFIED" || qualification.status === "QUALIFIED")) {
        action.label = "Qualify Client Again";
      }

      return action;
    }
    this.qualificationValue = mapQualificationToValue(this.mortgage.offerQualification);
    this.qualificationAction = mapQualificationToAction(this.mortgage.offerQualification);
  }

  private hasMortgageManage() {
    return this.userService.hasPermission("MORTGAGE_MANAGE");
  }

  private matchesState(state: MORTGAGE_STATE_TYPE[]): boolean {
    if (!this.mortgage || !this.mortgage.state) {
      return false;
    }

    return state.includes(this.mortgage.state);
  }

  private onArrangeAppointmentClicked() {
    this.arrangeAppointmentService.noSubmitMode = false;
    this.arrangeAppointmentService.currentAdvisor = this.mortgage.advisor;
    this.arrangeAppointmentService.currentAppointment = this.mortgage.appointment;
    this.arrangeAppointmentService.client = this.mortgage.client;

    const allowedMortgageStateForFactFindRequest: MORTGAGE_STATE_TYPE[] = ["IN_PROGRESS", "QUALIFIED", "PARTIALLY_QUALIFIED"];

    this.arrangeAppointmentService.isFactFindRequestAllowed = allowedMortgageStateForFactFindRequest.includes(this.mortgage.state)
      && !this.mortgage.factFind;

    this.router.navigate(['arrangeAppointment'], { relativeTo: this.route })
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }
}
