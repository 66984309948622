<div class="container">
  <header class="flex-header table-page-header dashboard-header">

    <div class="flex-item for-phone-show left-header">
      <hf-nav-button></hf-nav-button>

      <hf-custom-button type="button"
                        class="button-plain-icon"
                        icon="arrow_back"
                        iconPos="center"
                        (click)="onBack()">
      </hf-custom-button>

      <div class="header-text-content">
        <span class="client-header">
          <h1>
            <div class="heading-part">Clients</div>
            <i class="material-icons header-crumbs-divider heading-part">chevron_right</i>
            <div class="heading-part">{{clientData?.fullName ? clientData?.fullName : '&hellip;'}}</div>
          </h1>
        </span>
        <hf-warning-badge *ngIf="clientData?.vulnerableCustomer"></hf-warning-badge>
      </div>
    </div>

    <div class="flex-item-justify-end right-header">
      <div class="single-button-wrap">
        <hf-custom-button *ifPermission="'MORTGAGE_NEW'"
                          class="button-primary"
                          label="Add Mortgage"
                          icon="add_circle"
                          iconPos="left"
                          textPos="left"
                          (click)="onAddMortgage()">
        </hf-custom-button>
      </div>
      <div class="single-button-wrap">
        <hf-custom-button *ifPermission="'PROTECTION_NEW'"
                          class="button-primary"
                          label="Add Protection"
                          icon="add_circle"
                          iconPos="left"
                          textPos="left"
                          (click)="onAddProtection()">
        </hf-custom-button>
      </div>

      <hf-custom-button *ifPermission="'CLIENT_EDIT'"
                        type="button"
                        class="button-plain-icon"
                        icon="edit"
                        iconPos="center"
                        [routerLink]="['./edit']">
      </hf-custom-button>
    </div>
  </header>

  <div class="client-dashboard-content">
    <div class="info-summary-table">
      <div>Name</div>
      <div>{{clientData?.fullName}}</div>

      <div>Email</div>
      <div>{{clientData?.email}}</div>

      <div>Phone</div>
      <div>{{clientData?.phonePrefix}} {{clientData?.phone}}</div>

      <div>Date Added</div>
      <div>{{clientData?.createdDate ? (clientData?.createdDate | date:'d MMM y') : ''}}</div>

      <div>Credit Report</div>
      <div>
        {{ clientData?.creditSearchOptOut ? 'No' : 'Yes' }}
        <hf-icon-button class="credit-report-opt-out-btn" icon="swap_horizontal_circle" (click)="onOptOutClicked()"></hf-icon-button>
      </div>
    </div>

    <hf-information-box
      [heading]="'Last Activity'"
      [value]="clientData?.lastLogin ? ({text: clientData?.lastLogin | date:'d MMM y'}) : ''">

    </hf-information-box>

    <div style="flex: 4;"></div>
  </div>

  <div class="table-wrap">
    <div class="everglades-table">
      <div class="everglades-table-headings">
        <div class="entity"><span>Type</span></div>
        <div><span>Created</span></div>
        <div><span>Role</span></div>
        <div style="flex: 2.25"><span>Reference</span></div>
        <div><span>End/Claw Back Date</span></div>
        <div style="min-width: 161px"><span>Status</span></div>
      </div>

      <a *ngFor="let row of clientData?.productList" [routerLink]="['../..', getProductUrl(row), row.id]">
        <div class="table-row">
          <div data-mobileTitle="Type" class="entity" [ngClass]="row.type">
            <i *ngIf="row.type === 'MORTGAGE'" class="material-icons">home</i>
            <i *ngIf="row.type === 'PROTECTION'" class="material-icons">security</i>
          </div>
          <div data-mobileTitle="Created">{{row.createdDate ? (row.createdDate | date:'d MMM y') : '--'}}</div>
          <!-- <div data-mobileTitle="Type">{{row.type ? PRODUCT_TYPE[row.type] : '--'}}</div> -->
          <div data-mobileTitle="Role">{{row.role ? PRODUCT_ROLE[row.role] : '--'}}</div>
          <div data-mobileTitle="Reference" style="flex: 2.25">{{ getReference(row) }}</div>
          <div data-mobileTitle="End/Claw Back Date">{{row.productEndDate ? (row.productEndDate | date:'d MMM y') : '--'}}</div>
          <div data-mobileTitle="Status" style="display: block; color: white"><span class="status-icon" [ngClass]="row.state|lowercase">{{row.state ? getStateType(row.state) : '--'}}</span></div>
        </div>
      </a>
    </div>

    <div
      *ngIf="!clientData?.productList || clientData?.productList.length == 0"
      class="no-results">Client has no mortgages or protection yet</div>
  </div>
</div>

<div class="sidepanes-anchor">
  <router-outlet></router-outlet>
</div>
