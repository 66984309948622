import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'hf-custom-select-container',
  templateUrl: './custom-select-container.component.html',
  styleUrls: ['./custom-select-container.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CustomSelectContainerComponent),
    multi: true
  }]
})
export class CustomSelectContainerComponent implements OnInit, OnDestroy {
  @ViewChild('addItem') addItem: ElementRef;
  @ViewChild('hfSelectContainer') container;
  @ViewChild('singleItem') singleItem: ElementRef;
  @ViewChild('singleContainer') singleContainer: ElementRef;
  @ViewChildren('multiItem') multiItem: QueryList<ElementRef>;

  @Input() componentLabel: string;
  @Input() addButtonLabel: string;
  @Input() multi = false;
  @Input() name: string;
  @Input() subform: AbstractControl;
  @Input() showAddButton = true;
  @Input() filter: any[];
  @Input() redirect: any[];

  @Output() onClick = new EventEmitter();

  items = [];
  serviceDataSub: any;

  private lastIndex = -1;
  private editIndex = -1;
  private internalIdProperty: number;
  private onChange: any;
  private unsubscribe$ = new Subject();

  constructor(
    private router: Router,
    private renderer: Renderer2,
  ) {
  }

  ngOnInit() {
    if (this.subform) {

      this.parseSubformData(this.subform);
      this.subform.valueChanges.pipe(
        takeUntil(this.unsubscribe$))
        .subscribe(item => {
          this.parseSubformData(this.subform);
        });

    }
  }

  parseSubformData(subform: FormGroup | FormArray | FormControl | AbstractControl) {
    if (subform.valid || subform.disabled) {
      if (subform instanceof FormGroup) {
        const filtered = this.filter.reduce((obj, value, index) => {
          const stringVal = Array.isArray(value) ? this.concatArray(subform, value) : subform.value[value];
          return {
            ...obj,
            ...(index > 0 ?
                {
                  ...obj,
                  props: [
                    ...obj.props,
                    stringVal
                  ]
                } : {title: stringVal, form: subform, disabled: subform.disabled, props: []}
            )
          };
        }, {});

        this.items = [filtered];
        setTimeout(() => {
          this.singleItem.nativeElement.focus();
        }, 150);

      } else if (subform instanceof FormControl) {
        this.items = [{title: subform.value}];
        setTimeout(() => {
          this.singleItem.nativeElement.focus();
        }, 150);

      } else if (subform instanceof FormArray) {
        this.lastIndex = this.items.length - 1;
        this.items = subform.controls.map((data, pos) => {
          return this.filter.reduce((obj, value, index) => {
            const stringVal = Array.isArray(value) ? this.concatArray(subform, value) : data.value[value];
            return {
              ...obj,
              ...(index > 0 ? {
                ...obj,
                props: [
                  ...obj.props,
                  stringVal
                ]
              } : {title: stringVal, index: pos, form: subform, disabled: data.disabled, props: []})
            };
          }, {});
        });
        setTimeout(() => {
          if (this.addItem) {

            this.editIndex >= 0 && this.addItem.nativeElement.focus();

            (this.editIndex >= 0 && this.lastIndex === this.items.length - 1 && this.items.length > 0) &&
            this.multiItem.toArray()[this.editIndex].nativeElement.focus();

          } else if (!this.addItem && this.multiItem.toArray().length > 0) {
            this.multiItem.toArray()[0].nativeElement.focus();
          }
        }, 150);
      }

    } else {
      this.items = [];

      setTimeout(() => {
        if (this.addItem) {

          this.editIndex >= 0 && this.addItem.nativeElement.focus();
        } else if (!this.addItem && this.multiItem.toArray().length > 0) {
          this.multiItem.toArray()[0].nativeElement.focus();
        }

      }, 150);
    }

  }

  onAddAction(event) {
    event.preventDefault();
    this.editIndex = -1;
    const [path, query] = this.redirect;
    this.router.navigate(path, query);
  }

  onEditAction(event, form: FormGroup, index?) {
    event.preventDefault();
    const [path, query] = this.redirect;
    this.editIndex = index;
    this.router.navigate(path, {
      ...query,
      queryParams: {
        key: this.name,
        index,
        id: this.internalIdProperty
      }
    });
  }

  shrinkElement(element) {
    return element.offsetWidth < 200;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next('');
  }

  /* ControlValueAccessor functions */
  writeValue(value) {
    if (value !== null) {
      if (typeof value === 'string') {
        this.items = [{title: value}];
      }
      if (value instanceof Object && value.hasOwnProperty('value') && value.hasOwnProperty('id')) {
        if (value.value !== null && value.id !== null) {
          this.items = [{title: value.value}];
          this.internalIdProperty = value.id;
        }
      }
    } else {
      this.items = [{}];
    }
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
  }

  setDisabledState(isDisabled: boolean): void {
    const action = isDisabled ? 'addClass' : 'removeClass';
    this.renderer[action](this.singleContainer.nativeElement, 'hf-container-disabled');
  }

  private concatArray(abstractControl, array: any[]) {
    return array.reduce((prev, item, index) => {
      const space = `${index < array.length - 1 ? ' ' : ''}`;
      return `${prev}${abstractControl.value[item]}${space}`;
    }, '');
  }
}
