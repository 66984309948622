import { EventEmitter, HostListener } from '@angular/core';
import { Component, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastService } from '../../../../../../projects/client/src/app/shared/services/toast.service';
import { Attachment } from '../../../models/attachment';
import { Environment } from '../../../services/environment.service';

@Component({
  selector: 'hf-pdf-preview',
  templateUrl: './pdf-preview.component.html',
  styleUrls: ['./pdf-preview.component.scss']
})
export class PdfPreviewComponent implements OnChanges {
  @Input()
  public isLoading = true;

  @Input()
  public isSubmitting = false;

  @Input()
  public attachment: Attachment;
  
  @Input()
  public handovers: any[];

  @Input()
  public handover: any;

  @Output()
  public cancelClick = new EventEmitter();

  @Output()
  public sendClick = new EventEmitter();

  @Output()
  public handoverChange = new EventEmitter<any>();

  public url: string;
  public isPdfLoading = false;

  public get isSpinnerVisible() { return this.isLoading || this.isPdfLoading }

  constructor(
    private environment: Environment,
    private sanitizer: DomSanitizer,
    private toast: ToastService,
  ) { }

  @HostListener('click', ['$event'])
  public onClicked(event: MouseEvent) {
    if (event.target == event.currentTarget) {
      this.cancelClick.emit();
    }
  }

  public onHandoverChange() {
    console.log("pdf-preview onHandoverChange", this.handover);
    this.handoverChange.emit(this.handover);
  }

  public ngOnChanges(changes: SimpleChanges) {
    if ("attachment" in changes && changes["attachment"].currentValue) {
      const value = changes["attachment"].currentValue;

      this.url = `${this.environment.apiUrl}servlet/gcs/${value.folder}/${value.filename}`;
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.url) as any;

      this.isPdfLoading = true;
    }
  }

  // private getPdf(url: string) {
  //   fetch(url, { credentials: "include", mode: "cors" })
  //     .then(r => r.blob())
  //     .then(blob => {
  //       console.log("blob", blob);
  //       const pdf = new Blob([blob], {type: "application/pdf"})
  //       console.log("pdf", pdf);
  //       const objecturl = window.URL.createObjectURL(pdf);
  //       console.log("objecturl", objecturl);
  //       this.url = objecturl;
  //       this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.url) as any;
  //     });
  // }

  public onLoaded() {
    // console.log("onLoaded");
    // this.isPdfLoading = false;
  }

  public onError(e: any) {
    console.log("onError");
    console.log(e);
    this.isPdfLoading = false;
    this.toast.add("Error loading pdf", "error", e);
  }
}
