<div class="overlay" (click)="onBack()" [@fadeAnimation]></div>

<div class="sidepane-content">
  <div class="flex-space-between-layout template-header">
    <h1>Change Password</h1>
    <hf-custom-button type="button" class="button-plain-icon" icon="close" iconPos="center" (onClick)="onBack()">
    </hf-custom-button>
  </div>

  <div class="template-main">
    <form [formGroup]="changePassFG"
          novalidate
          (ngSubmit)="onSubmit()">

      <input type="text" id="username" name="username" autocomplete="username" [value]="email" style="display: none;">

      <label>Current Password
        <hf-custom-text-field type="password"
                              [group]="changePassFG"
                              inputFormControlName="currentPassword"
                              fieldName="currentPassword"
                              autocomplete="current-password"
                              marginElement
                              validateOnBlur>
        </hf-custom-text-field>
      </label>

      <label>New Password
        <hf-custom-text-field type="password"
                              [group]="changePassFG"
                              inputFormControlName="newPassword"
                              fieldName="newPassword"
                              autocomplete="new-password"
                              marginElement
                              validateOnBlur>
        </hf-custom-text-field>
      </label>

      <label>Confirm New Password
        <hf-custom-text-field type="password"
                              [group]="changePassFG"
                              inputFormControlName="newPasswordRep"
                              autocomplete="new-password"
                              fieldName="newPasswordRep"
                              marginElement
                              validateOnBlur>
        </hf-custom-text-field>
      </label>
      <div *ngIf="changePassFG.get('newPasswordNotValid').invalid && changePassFG.get('newPasswordRep').touched"
           class="error-message">
        Passwords don't match
      </div>

      <div *ngIf="notValidPassword == changePassFG.get('currentPassword').value"
           class="error-message">
        Current password is incorrect
      </div>
    </form>
  </div>

  <div class="template-footer">
    <hf-custom-button type="submit"
                      class="button-primary"
                      label="Submit"
                      [disabled]="submitted || changePassFG.invalid"
                      (onClick)="onSubmit()">
    </hf-custom-button>

    <hf-custom-button type="button"
                      class="button-secondary"
                      label="Cancel"
                      (click)="onBack()">
    </hf-custom-button>
  </div>
</div>
