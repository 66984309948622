import { Component, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ToasterService } from '../../../../services/toaster.service';
import { fadeAnimation, sidepaneMove } from "../../../../shared/animations/sidepane-animation";
import { Task, TaskApiService } from '../../task-api.service';
import { TaskService } from '../../task.service';
import { ReassignTaskService } from '../reassign-task/reassign-task.service';
import { MortgagesService } from '../../../mortgages/mortgages.service';

export interface ViewTaskConfig {
  hasViewDetailButton?: boolean;
}

@Component({
  selector: 'hf-view-task',
  templateUrl: 'view-task.component.html',
  styleUrls: ['view-task.component.scss',
    '../../../../styles/sidepanes.partial.scss'],
  animations: [sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove]': 'true' }
})

export class ViewTaskComponent implements OnDestroy {
  public form = this.fb.group({
    note: [{ value: null, disabled: true }],
    _markAsDone: [false, Validators.requiredTrue]
  });

  public data: Task;
  public isLoading = true;
  public isSubmitting = false;

  public hasPermissionToDeleteTask = false;

  public canReassign = false;
  public canEdit = false;
  public canDone = false;
  public get config() { return this.route.snapshot.data as ViewTaskConfig }

  private destroy$ = new Subject();
  private get taskId() { return this.route.snapshot.paramMap.get("taskId") }

  @ViewChild("note", { read: ElementRef })
  public note: ElementRef<any>;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private taskApiService: TaskApiService,
    private taskService: TaskService,
    private reassignTaskService: ReassignTaskService,
    private mortgagesService: MortgagesService,
    private toasterService: ToasterService,
  ) {
    this.form.get("_markAsDone").valueChanges.subscribe(_markAsDone => {
      if (_markAsDone) {
        setTimeout(() => {
          this.note && this.note.nativeElement.scrollIntoView({ behavior: "smooth" });
        });

        this.form.get("note").enable();
      } else {
        this.form.get("note").disable();
      }
    })

    this.taskService.reloadCurrentTask$
      .pipe(takeUntil(this.destroy$)).subscribe(() => this.initialize());

    this.initialize();
  }

  public onViewClicked() {
    const detailRoute = this.data.entity === "MORTGAGE"
      ? "mortgages"
      : "protection";
    const id = this.data.entity === "MORTGAGE"
      ? this.data.mortgageId
      : this.data.protectionCaseId;

    this.router.navigate(["app", detailRoute, id]);
  }

  public onAssigneeClicked() {
    if (!this.taskService.hasPermissionToReassignTask(this.data)) {
      return;
    }

    this.reassignTaskService.initial = this.data.assignee as any;

    this.router.navigate(['reassign'], { relativeTo: this.route });
  }

  public async onDoneClicked() {
    this.isSubmitting = true;

    try {
      const value = this.form.value;

      await this.taskApiService.markTaskAsDone(value, this.taskId);

      this.taskService.reloadTasks$.next();
      this.mortgagesService.reloadMortgageDashboard.next();
      this.toasterService.callToaster({ severity: 'info', summary: 'Info', detail: 'Task marked as done.' });

      this.onBack();
    } catch (err) {
      this.toasterService.callToaster({ severity: 'error', summary: 'Error', detail: `Failed to mark task as done. Please try again ${err.error.error.message}` });
    } finally {
      this.isSubmitting = false;
    }
  }

  public onEditClicked() {
    this.router.navigate(["edit"], { relativeTo: this.route });
  }


  public onDeleteClicked() {
    this.router.navigate(["delete"], { relativeTo: this.route });
  }

  public onBack() {
    this.router.navigate(['../..'], { relativeTo: this.route });
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }

  private initialize() {
    this.taskApiService.viewTask(this.taskId).then(task => {
      this.data = task;
      this.hasPermissionToDeleteTask = this.taskService.hasPermissionToDeleteTask(task);

      this.canReassign = !task.completed && this.taskService.hasPermissionToReassignTask(task);
      this.canDone = !task.completed && this.taskService.hasPermissionToDoneTask(task)

      if (!this.canDone) {
        this.form.get("_markAsDone").disable();
      }

      this.isLoading = false;
    })
  }
}
