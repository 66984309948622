<div class="dasboard-layout">
  <div *ngIf="isVisible" class="nav" [ngClass]="{'nav-opened':templateService.getIfSidebarOpened()}">
    <hf-custom-sidenav [routes]="templateRoutes">
    </hf-custom-sidenav>

    <hf-navigation></hf-navigation>
  </div>

  <div class="section">
    <router-outlet></router-outlet>

    <div class="dynamic-sidepanes-anchor">
      <div #dynamicSidepanesAnchor></div>
    </div>
  </div>
</div>

<hfc-toast></hfc-toast>
<hfc-dialog></hfc-dialog>
