import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, Inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SignatureRequestStateNameHeron } from '../../../../../../../projects/client/src/app/root/services/client.service';
import { MortgageDashboard, MortgageDashboardApplication, MortgageDashboardAppointment, MortgageDashboardHelpToBuy, MortgageDashboardOfferQualification, ProductTransferDeclarationStateName } from '../../../../models/mortgage-dashboard';
import { Environment } from '../../../../services/environment.service';
import { UserService } from '../../../../services/user.service';
import { ArrangeAppointmentService } from '../../../../shared-layout/side-panes/arrange-appointment/arrange-appointment.service';
import { RequestFactFindService, REQUEST_FACTFIND_SERVICE } from '../../../../shared-layout/side-panes/request-fact-find/request-fact-find.component';
import { InformationBoxAction, InformationBoxValue } from '../../../../shared/information-box/information-box.component';
import { APPLICATION_STATE_NAMES, AppointmentSpecificTimeName, CLIENT_CATEGORY_NAME, CLIENT_SCHEME, CLIENT_TYPES_ALL, CLIENT_TYPE_NAME, FACT_FIND_STATE_NAMES, HELP_TO_BUY_STATUS_NAMES, MORTGAGE_ACTIONS, MORTGAGE_STATE_TYPE, MORTGAGE_WAITING_ON, PROPERTY_VALUE_TYPES, REFERRAL_TYPE_NAME } from "../../../../utils/variables.data";
import { MortgagesService } from '../../mortgages.service';

export const mapAppointmentToValue = (appointment: MortgageDashboardAppointment, datePipe: DatePipe): InformationBoxValue => {
  const value: InformationBoxValue = { text: undefined };

  if (!appointment || appointment.state === "CANCELED") {
    return value;
  }

  if (appointment.nonSpecificTime && appointment.nonSpecificTime !== "SPECIFIC_TIME") {
    const date = datePipe.transform(appointment.appointmentDate, "d MMM y");
    const part = AppointmentSpecificTimeName.get(appointment.nonSpecificTime);
    value.text = `${date} ${part}`;

    return value;
  }

  value.text = datePipe.transform(appointment.appointmentDate, "d MMM y HH:mm");

  return value;
};

@Component({
  selector: 'hf-mortgage-dashboard-general',
  templateUrl: './mortgage-dashboard-general.component.html',
  styleUrls: ['./mortgage-dashboard-general.component.scss'],
  providers: [CurrencyPipe]
})
export class MortgageDashboardGeneralComponent implements OnChanges {
  @Input() public data: MortgageDashboard;

  public REFERRAL_TYPE_NAME = REFERRAL_TYPE_NAME;
  public CLIENT_CATEGORY_NAME = CLIENT_CATEGORY_NAME;
  public CLIENT_TYPE_NAME = CLIENT_TYPE_NAME;

  public MORTGAGE_ACTIONS = MORTGAGE_ACTIONS;
  public MORTGAGE_WAITING_ON = MORTGAGE_WAITING_ON;
  public PROPERTY_VALUE_TYPES = PROPERTY_VALUE_TYPES;
  public CLIENT_TYPES_ALL = CLIENT_TYPES_ALL;
  public CLIENT_SCHEME = CLIENT_SCHEME;

  public isFactFindVisible = false;
  public factFindValue: InformationBoxValue;
  public factFindAction: InformationBoxAction = {
    label: "Request",
    callback: () => this.onFactFindClicked(),
    disabled: !this.environment.requestFactFindEnabled
  }

  public isAppointmentVisible = false;
  public appointmentValue: InformationBoxValue;
  public appointmentAction: InformationBoxAction;

  public isApplicationVisible = false;
  public applicationValue: InformationBoxValue;
  public applicationAction: InformationBoxAction = {
    label: "Update Status",
    callback: () => this.onApplicationClicked()
  }

  public helpBuyValue: InformationBoxValue;
  public helpBuyAction: InformationBoxAction = {
    label: "Update Status",
    callback: () => { this.router.navigate(["updateHelpToBuy"], { relativeTo: this.route }) }
  }
  public get isHelpBuyVisible() { return this.data.clientScheme === "HELP_TO_BUY" }

  public get productTransferDeclarationValue() { return ProductTransferDeclarationStateName.get(this.data?.productTransferDeclaration?.state) }

  public get isChangeSalesAdvisorVisible() { return this.userService.hasPermission("MORTGAGE_CHANGE_SALES_ADVISOR") && this.data && !this.data.introducerLinkBroken }

  public get isIntroducer() { return this.userService.isIntroducer() }

  public qualificationValue: InformationBoxValue;
  public qualificationAction: InformationBoxAction;

  public get isTargetExchangeDateVisible() { 
    const isBeyondExchanged = application => ["EXCHANGED", "COMPLETED"].includes(application.state);
    const { applications } = this.data;
    return this.data.clientCategory !== "REMORTGAGE" && (!applications || !applications?.some(isBeyondExchanged));
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private datePipe: DatePipe,
    private currencyPipe: CurrencyPipe,
    private environment: Environment,
    private arrangeAppointmentService: ArrangeAppointmentService,
    private mortgagesService: MortgagesService,
    @Inject(REQUEST_FACTFIND_SERVICE) private requestFactFindService: RequestFactFindService,
  ) { }

  public onChangeIntroducerClicked() {
    this.router.navigate(["changeIntroducer"], { relativeTo: this.route });
  }

  public async onCreateApplicationClicked() {
    this.router.navigate(["createApplication"], { relativeTo: this.route });
  }

  public onApplicationStatusClicked(application: MortgageDashboardApplication) {
    this.router.navigate(["updateApplication", application.id], { relativeTo: this.route });
  }

  public onChangeClientCategoryClicked() {
    this.router.navigate(["switchCategory"], { relativeTo: this.route });
  }

  public getSubApplicantsTooltip(data: MortgageDashboard) {
    return data?.subApplicants?.join("<br>");
  }

  public getOptedOut(data: MortgageDashboard) {
    return data?.creditSearchOptedOut?.join(", ") || "None";
  }

  public onOptOutClicked() {
    this.router.navigate(["creditReportOpt"], { relativeTo: this.route });
  }

  public get productTransferDeclarationTooltip() {
    const applicantStates = this.data?.productTransferDeclaration?.applicantStates;

    if (!applicantStates) {
      return null;
    }

    const tooltip = applicantStates
      .map(a => `${a.applicant} - ${SignatureRequestStateNameHeron.get(a.state)}`)
      .join("<br>");

    return tooltip;
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (!("data" in changes)) {
      return;
    }

    const factFind = this.data.factFind;
    if (factFind) {
      this.factFindValue = { text: FACT_FIND_STATE_NAMES.get(factFind.status) }
      this.factFindAction.label = "View";
    }
    this.isFactFindVisible = this.hasMortgageManage() && this.matchesState(["QUALIFIED", "PARTIALLY_QUALIFIED", "IN_PROGRESS"]);

    const application = this.data.application;
    if (application) {
      this.applicationValue = { text: APPLICATION_STATE_NAMES.get(application.state) }
      this.applicationAction.label = "Update Status";
    } else {
      this.applicationValue = undefined;
      this.applicationAction.label = "Create Application";
    }
    this.isApplicationVisible = (this.hasMortgageManage() && !!application) ||
      (
        this.matchesState(["QUALIFIED", "PARTIALLY_QUALIFIED", "IN_PROGRESS", "PREPARED", "SUBMITTED", "OFFERED"])
        && (this.data && this.data.complianceHandoverState === "APPROVED")
        && this.hasMortgageManage()
      )

    const mapAppointmentToAction = (appointment: MortgageDashboardAppointment): InformationBoxAction => ({
      label: appointment && (appointment.state !== "CANCELED" && appointment.state !== "PASSED")
        ? "View"
        : "Arrange",
      callback: () => this.onArrangeAppointmentClicked()
    });
    this.appointmentValue = mapAppointmentToValue(this.data.appointment, this.datePipe);
    this.appointmentAction = mapAppointmentToAction(this.data.appointment);
    this.isAppointmentVisible = this.hasMortgageManage() && (this.data && this.data.state) && !this.matchesState(["NOT_ASSIGNED", "NOT_PROCEEDING"]);

    const mapHelpToBuyToValue = (helpToBuy: MortgageDashboardHelpToBuy): InformationBoxValue => ({
      text: (helpToBuy && HELP_TO_BUY_STATUS_NAMES.get(helpToBuy.status))
    });
    this.helpBuyValue = mapHelpToBuyToValue(this.data.helpToBuy);

    const mapQualificationToValue = (qualification: MortgageDashboardOfferQualification): InformationBoxValue => {
      const value: InformationBoxValue = { text: "" };
      let amount: number;

      if (!qualification || !qualification.status) {
        value.text = "Awaiting Qualification";
        return value;
      }

      if (qualification.status === "QUALIFIED") {
        amount = this.data.desiredQualification;
        value.text = `Up to ${this.currencyPipe.transform(amount, "GBP", undefined, "1.0-0")}`;
      }

      if (qualification.status === "PARTIALLY_QUALIFIED") {
        amount = this.data.offerQualification.qualifiedUpTo;
        value.text = `Up to ${this.currencyPipe.transform(amount, "GBP", undefined, "1.0-0")}`;
      }

      if (qualification.status === "RE_QUALIFICATION_REQUESTED") {
        amount = this.data.desiredQualification;
        value.text = `Re-qualification Requested`;
      }

      if (qualification.status === "NOT_QUALIFIED") {
        value.text = "Not Qualified";
      }

      return value;
    }
    const mapQualificationToAction = (qualification: MortgageDashboardOfferQualification): InformationBoxAction => {
      const action: InformationBoxAction = {
        label: "Qualify Client",
        callback: () => {
          this.router.navigate(["offer-qualification"], { relativeTo: this.route })
        }
      };

      if (this.userService.hasPermission(["MORTGAGE_VIEW_INTRODUCER", "MORTGAGE_VIEW_DIVISION"])
        && this.matchesState(["AWAITING_QUALIFICATION", "QUALIFIED", "PARTIALLY_QUALIFIED", "NOT_QUALIFIED", "IN_PROGRESS", "PREPARED"])) {
        action.label = "Request Re-qualification ";
        action.callback = () => {
          this.router.navigate(["request-requalification"], { relativeTo: this.route })
        }

        return action;
      }

      const states: MORTGAGE_STATE_TYPE[] = ["AWAITING_FIRST_CONTACT", "CONTACT_ATTEMPTED", "AWAITING_QUALIFICATION", "QUALIFIED", "PARTIALLY_QUALIFIED", "IN_PROGRESS", "PREPARED"];

      if (!states.includes(this.data.state) || !this.data.desiredQualification) {
        return undefined;
      }

      if (qualification && (qualification.status === "PARTIALLY_QUALIFIED" || qualification.status === "QUALIFIED")) {
        action.label = "Qualify Client Again";
      }

      return action;
    }
    this.qualificationValue = mapQualificationToValue(this.data.offerQualification);
    this.qualificationAction = mapQualificationToAction(this.data.offerQualification);
  }

  public onFactFindClicked() {
    if (this.data.factFind) {
      return this.router.navigate(["."], { relativeTo: this.route, queryParams: { tab: "fact-find" } });
    }

    this.requestFactFindService.requestFrom = [this.data.client.email];

    this.router.navigate(["requestFactFind"], { relativeTo: this.route });
  }

  public onApplicationClicked() {
    const route = this.data.application ? "updateApplication" : "createApplication";

    this.router.navigate([route], { relativeTo: this.route });
  }

  public convertEnumToValue(type, en): string {
    if (!en)
      return null;
    return Object.entries(type).filter(([key, value]) => key === en)[0][1] as string;
  };

  public onChangeSalesAdvisorClicked() {
    this.router.navigate(["switchSalesAdvisor"], { relativeTo: this.route });
  }

  private hasMortgageManage() {
    return this.userService.hasPermission("MORTGAGE_MANAGE");
  }

  private matchesState(state: MORTGAGE_STATE_TYPE[]): boolean {
    if (!this.data || !this.data.state) {
      return false;
    }

    return state.includes(this.data.state);
  }

  private onArrangeAppointmentClicked() {
    this.arrangeAppointmentService.noSubmitMode = false;
    this.arrangeAppointmentService.currentAdvisor = this.data.advisor;
    this.arrangeAppointmentService.currentAppointment = this.data.appointment;
    this.arrangeAppointmentService.client = this.data.client;

    const allowedMortgageStateForFactFindRequest: MORTGAGE_STATE_TYPE[] = ["IN_PROGRESS", "QUALIFIED", "PARTIALLY_QUALIFIED"];

    this.arrangeAppointmentService.isFactFindRequestAllowed = allowedMortgageStateForFactFindRequest.includes(this.data.state)
      && !this.data.factFind;

    this.router.navigate(['arrangeAppointment'], { relativeTo: this.route })
  }

  public onEditTargetCompletionDate() {
    this.router.navigate(["updateTargetCompletion"], { relativeTo: this.route });
  }

  public onEditTargetExchangeDate() {
    this.router.navigate(["updateTargetExchange"], { relativeTo: this.route });
  }
}
