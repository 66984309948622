import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MenuModule } from 'primeng/menu';

import { EvergladesSharedMorule } from '../everglades-shared.module';
import { SharedModule } from '../shared.module';
import { CustomDropdownComponent } from './custom-dropdown.component';

@NgModule({
  declarations: [
    CustomDropdownComponent
  ],
  imports: [
    CommonModule,
    MenuModule,
    EvergladesSharedMorule,
    SharedModule,
  ],
  exports: [
    CustomDropdownComponent
  ],
  providers: []
})
export class CustomDropdownModule { }
