import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute, UrlTree } from '@angular/router';

import { UserService } from '../../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class MyIntroducerRedirectGuard implements CanActivate {
  constructor(private route: ActivatedRoute, private router: Router, private userService: UserService) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot, ): UrlTree {
    const introducerId = this.userService.getUser().introducer.id;

    const tree = this.router.createUrlTree(['app', 'introducers', introducerId]);
    return tree;
  }
}
