import { EventEmitter, Injectable } from '@angular/core';
import { UserSelectorDTO, UserSimple } from '../../../models';
import { MORTGAGE_STATE_TYPE, PROTECTION_CASE_STATE } from '../../../utils/variables.data';

@Injectable()
export class LogContactAttemptService {
  client: UserSelectorDTO;
  currentAdvisor: UserSimple;
  state: PROTECTION_CASE_STATE | MORTGAGE_STATE_TYPE;
  notifyIntroducerVisible = false;
  appointmentVisible = false;
  contactAttemptAdded$ = new EventEmitter();
}
