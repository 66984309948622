import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { APPLICATION_STEP, APPLICATION_STEP_NAME, APPLICATION_STEP_STATUS, COMMON_APPLICATION_STEPS, NOT_FOLLOW_APPLICATION_STEPS } from '../../../../../../../projects/client/src/app/fact-find/enums.module';
import { FactFindStepStatus } from '../../../../../../../projects/client/src/app/fact-find/services/fact-find-api.service';
import { ReviewSectionState } from '../../../../../../../projects/client/src/app/shared/components/review-section/review-section.component';
import { Confirmation, DialogService } from '../../../../../../../projects/client/src/app/shared/services/dialog.service';
import { Environment } from '../../../../services/environment.service';
import { FactFindApiService, HeronFactFindData, ReturnSection } from '../../../../services/fact-find-api.service';
import { ToasterService } from '../../../../services/toaster.service';
import { MortgagesService } from '../../mortgages.service';
import { MortgageDashboardFactFindService } from './mortgage-dashboard-fact-find.service';

export interface LeftMenuModel {
  steps: APPLICATION_STEP[];
  statuses: Record<APPLICATION_STEP, APPLICATION_STEP_STATUS>;
}

@Component({
  selector: 'hf-mortgage-dashboard-fact-find',
  templateUrl: './mortgage-dashboard-fact-find.component.html',
  styleUrls: ['./mortgage-dashboard-fact-find.component.scss']
})
export class MortgageDashboardFactFindComponent implements OnDestroy {
  public form = this.fb.group({
    signature: [undefined, Validators.required],
    textSignature: [undefined, Validators.required],
    _signWith: [true, Validators.required],
    _agree: [false, Validators.requiredTrue],
  });

  public data: HeronFactFindData;

  public leftMenu: LeftMenuModel
  public commonSectionConfigs: Record<APPLICATION_STEP, ReviewSectionState>
  public applicantSectionConfigs: Record<APPLICATION_STEP, Record<string, ReviewSectionState>>;

  public isLoading = true;
  public isReturnButtonEnabled = false;
  public isApproveButtonEnabled = false;
  public isReactivateButtonEnabled = false;

  public APPLICATION_STEP_NAME = APPLICATION_STEP_NAME;

  private get mortgageId() { return this.route.snapshot.paramMap.get("id") }
  private destroy$ = new Subject();

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store,
    @Inject(DOCUMENT) private document: Document,
    private mortgagesService: MortgagesService,
    private factFindApiService: FactFindApiService,
    private dialogService: DialogService,
    private toasterService: ToasterService,
    private environment: Environment,
    private mortgageDashboardFactFindService: MortgageDashboardFactFindService,
  ) {
    this.mortgageDashboardFactFindService.reload$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.reload());

    this.initialize();
  }

  public async onReactivateClicked() {
    const confirmation: Confirmation = {
      title: "Reactivate Fact Find",
      message: "The Fact Find has already been approved and should not be reactivated unless there is an issue with the data.",
      acceptLabel: "Reactivate Anyway",
    }

    const reactive = async () => {
      try {
        await this.factFindApiService.reactivateFactFind(this.mortgageId);
        await this.reload();

        this.mortgagesService.reloadMortgageDashboard.emit();
        this.toasterService.callToaster({ severity: 'info', summary: 'Info', detail: 'Fact Find reactivated successfully' });
      } catch (err) {
        this.toasterService.callToaster({ severity: 'error', summary: 'Error', detail: `Failed to reactivate Fact Find. ${err.error.error.message}` });
      }
    }

    this.dialogService.confirmAndDo(confirmation, reactive);
  }

  public async onEditClicked() {
    const leadApplicant = this.data.applicants.find(applicant => applicant.lead);
    const applicantId = leadApplicant?.client.id;

    if (!applicantId) {
      return;
    }

    const url = `${this.environment.clientWebUrl}/fact-find/${this.mortgageId}/crossroad?applicantId=${applicantId}`;

    window.open(url, "_blank");
  }

  public async onReturnClicked() {
    const confirmation: Confirmation = {
      title: "Return Fact Find",
      message: "Please make sure you have returned all relevant sections before continuing. The client will be asked to make the clarifications you have requested.",
      acceptLabel: "Return",
    }

    const returnFactFind = async () => {
      try {
        await this.factFindApiService.returnFactFind(this.mortgageId);
        await this.reload();

        this.toasterService.callToaster({ severity: 'info', summary: 'Info', detail: 'Fact Find returned successfully' });
      } catch (err) {
        this.toasterService.callToaster({ severity: 'error', summary: 'Error', detail: `Failed to return Fact Find. ${err.error.error.message}` });
      }
    }

    this.dialogService.confirmAndDo(confirmation, returnFactFind);
  }

  public async onApproveClicked() {
    const confirmation: Confirmation = {
      title: "Approve Fact Find",
      message: "Please confirm the Fact Find has been approved as accurate.",
      acceptLabel: "Approve",
      acceptButtonType: "primary"
    }

    const approve = async () => {
      try {
        await this.factFindApiService.approveFactFind(this.mortgageId);
        await this.reload();

        this.mortgagesService.reloadMortgageDashboard.emit();
        this.toasterService.callToaster({ severity: 'info', summary: 'Info', detail: 'Fact Find approved successfully' });
      } catch (err) {
        this.toasterService.callToaster({ severity: 'error', summary: 'Error', detail: `Failed to approve Fact Find. ${err.error.error.message}` });
      }
    }

    this.dialogService.confirmAndDo(confirmation, approve);
  }

  public async onButtonClicked(reviewSection: ReviewSectionState) {
    const { step, applicantId, markToReturn } = reviewSection;

    if (!markToReturn) {
      this.router.navigate(["returnFactFind"], { queryParams: { step, applicantId }, relativeTo: this.route });

      return;
    }

    const undoReturn = async () => {
      try {
        const value: ReturnSection = { applicantId, step };

        await this.factFindApiService.undoReturn(this.mortgageId, value);
        await this.reload();

        this.toasterService.callToaster({ severity: 'info', summary: 'Info', detail: 'Section return undone' });
      } catch (err) {
        this.toasterService.callToaster({ severity: 'error', summary: 'Error', detail: `Failed to undo section return. ${err.error.error.message}` });
      }
    }

    const confirmation: Confirmation = {
      title: "Undo Return",
      message: "Are you sure you want to undo the return of this section?",
      acceptLabel: "Undo Return",
      acceptButtonType: "primary"
    };

    this.dialogService.confirmAndDo(confirmation, undoReturn);
  }

  public onMenuItemClicked(item: any) {
    const element = this.document.querySelector(`#${item}`);

    element && element.scrollIntoView({ behavior: "smooth" });
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }

  private async reload() {
    this.data = await this.factFindApiService.getFactFindDataHeron(this.mortgageId);

    this.leftMenu = this.createLeftMenu(this.data);
    this.commonSectionConfigs = this.createCommonSectionConfigs(this.data);
    this.applicantSectionConfigs = this.createApplicantSectionConfigs(this.data);

    this.setupButtons();
  }

  private async initialize() {
    this.isLoading = true;

    this.data = await this.factFindApiService.getFactFindDataHeron(this.mortgageId);

    this.leftMenu = this.createLeftMenu(this.data);
    this.commonSectionConfigs = this.createCommonSectionConfigs(this.data);
    this.applicantSectionConfigs = this.createApplicantSectionConfigs(this.data);

    this.setupButtons();

    this.isLoading = false;
  }

  private setupButtons() {
    const stepStatuses = this.data.overallStepStatuses;

    this.isReturnButtonEnabled = stepStatuses.some(ss => ss.markToReturn);
    this.isApproveButtonEnabled = this.data.factFindStatus === "SIGNED" && !this.isReturnButtonEnabled;
    this.isReactivateButtonEnabled = this.data.factFindStatus === "APPROVED";
  }

  private createLeftMenu(data: HeronFactFindData): LeftMenuModel {
    const steps = [...APPLICATION_STEP_NAME.keys()]
      .filter(step => !NOT_FOLLOW_APPLICATION_STEPS.includes(step) && data.overallStepStatuses.some(ss => ss.step === step));
    const statuses = data.overallStepStatuses
      .reduce<Record<APPLICATION_STEP, APPLICATION_STEP_STATUS>>((statuses, s) => { statuses[s.step] = s.status; return statuses; }, {} as any);

    return { steps, statuses };
  }

  private createButtonConfig(data: HeronFactFindData, stepStatus: FactFindStepStatus, applicantId?: string) {
    let buttonLabel = undefined;

    if (data.factFindStatus !== "APPROVED") {
      if (stepStatus.markToReturn) {
        buttonLabel = "Undo Return";
      } else if (stepStatus.status === "DONE") {
        buttonLabel = "Return Section";
      }
    }

    const config: ReviewSectionState = {
      applicantId,
      step: stepStatus.step,
      stepStatus: stepStatus.status,
      buttonLabel,
      buttonType: buttonLabel ? "tertiary" : undefined,

      lastUpdated: stepStatus.lastUpdated,
      updatedBy: stepStatus.updatedBy,
      markToReturn: stepStatus.markToReturn,
      returnComment: stepStatus.returnComment,
    };

    return config;
  }

  private createCommonSectionConfigs(data: HeronFactFindData) {
    const configs: ReviewSectionState[] = COMMON_APPLICATION_STEPS.map(step => {
      const stepStatus = data.overallStepStatuses.find(status => status.step === step);
      const leadApplicantStepStatus = data.applicantsData
        .find(ad => ad.applicant.lead === true)
        ?.stepStatuses.find(status => status.step === step);

      if (leadApplicantStepStatus) {
        stepStatus.returnComment = leadApplicantStepStatus.returnComment;
      }

      return this.createButtonConfig(data, stepStatus);
    });

    return configs.reduce((acc, config) => { acc[config.step] = config; return acc; }, {} as Record<APPLICATION_STEP, ReviewSectionState>);
  }

  private createApplicantSectionConfigs(data: HeronFactFindData) {
    const steps = [...APPLICATION_STEP_NAME.keys()].filter(key => !COMMON_APPLICATION_STEPS.includes(key) && !NOT_FOLLOW_APPLICATION_STEPS.includes(key));

    let configs = {} as Record<APPLICATION_STEP, Record<string, ReviewSectionState>>;

    for (const applicantData of data.applicantsData) {
      for (const step of steps) {
        const stepStatus = applicantData.stepStatuses.find(status => status.step === step);

        if (!stepStatus) {
          continue;
        }

        const applicantId = applicantData.applicant.client.id;
        const config = this.createButtonConfig(data, stepStatus, applicantId);

        configs[step] = configs[step] || {};
        configs[step][applicantId] = config;
      }
    }

    return configs;
  }
}
