<div class="container">
  <div *ngFor="let doc of documents" class="document" (click)="onDownloadClicked(doc)">
    <hf-attachment-thumb-image [attachment]="doc.attachment" alt=" "></hf-attachment-thumb-image>

    <div class="name">
      {{ doc.attachment.originalFilename }}

      <div class="size">
        {{ doc.attachment.size | size }}
      </div>
    </div>

    <hf-named-icon icon="file_download"></hf-named-icon>
  </div>
</div>

<!-- <div *ngIf="documents.length > 1" class="count"> -->
<!--   {{ documents.length }} attachments -->
<!--   <span class="download-all">Download All</span> -->
<!-- </div> -->
