import {Injectable} from "@angular/core";
import {AuthenticationService} from "./authentication.service";
import {UserService} from "./user.service";
import {NavigationService} from "./navigation.service";
import {Router} from "@angular/router";
import {User} from "../models/user";

export interface LoginParams {
  username: string;
  password: string;
  rememberMe: boolean;
}

const evDebug = false;
export function evLog(...args) {
  if (!evDebug) {
    return; 
  }

  console.log(...args);
}

@Injectable({ providedIn: "root" })
export class LoginService {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private navigationService: NavigationService,
  ) { }

  public async login(params: LoginParams, returnUrl?: string) {
    evLog("login: ", { params, returnUrl });

    const user: User = await this.authenticationService.checkLogin(params);

    evLog("login: ", { user });
    this.userService.setUser(user);

    const isAutheticated = this.userService.isAutheticated();
    const isAutheticatedNeedsVerify = this.userService.isAutheticatedNeedsVerify();
    const twoFactorNeeded = user.twoFactorNeeded;

    evLog("login: ", { 
      isAutheticated, 
      isAutheticatedNeedsVerify,
      twoFactorNeeded,
    });

    if (this.userService.isAutheticatedNeedsVerify()) {
      if (twoFactorNeeded.type === "TWO_FACTOR_SMS") {
        return this.navigationService.goToVerifyMobileNumber();
      } else {
        return this.navigationService.goToVerifyAuthCode();
      }
    }

    if (this.userService.isAutheticated()) {
      if (returnUrl) {
        return this.router.navigateByUrl(returnUrl);
      } else {
        return this.navigationService.goToUserDefault();
      }
    }
  }

  public async tryCheckSign() {
    evLog("tryCheckSign: ");

    let user: User;

    try {
      user = await this.authenticationService.checkSigned();
    } catch (e) {
      console.log("Error in tryCheckSign(): ", e);
    }

    evLog("tryCheckSign: ", { user });

    this.userService.setUser(user);
  }

  public async tryCheckSignAndGoToDefault() {
    evLog("tryCheckSignAndGoToDefault: ");

    let user: User = this.userService.getUser();

    if (!user) {
      await this.tryCheckSign();
    }
    
    if (this.userService.isAutheticated()) {
      return this.navigationService.goToUserDefault();
    }
  }

  public async logOut() {
    evLog("logOut: ");

    this.userService.closeSession();
    await this.authenticationService.logOut();
  }
}
