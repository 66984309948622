import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { PresetRangeFilter } from "../modules/dashboard-charts/services/dashboard-charts.service";
import { toLocalDateISO } from "../../../../projects/client/src/app/shared/functions/to-local-date-iso";
import { Observable } from "rxjs";
import { ListResult } from "./api-models";
import { Environment } from "./environment.service";
import { HttpParams } from "@angular/common/http";
import * as R from "ramda";
import {
  DirectionType,
  NPWCountDTO,
  MortgageToProtectionConversionCountsDTO,
  NpwStageChartValue,
  SuccessIndicationColor,
  ReferralSource
} from "../modules/dashboard-charts/models/charts.model";
import { LineChartValue } from "../modules/dashboard-charts/components/line-chart/line-chart.component";

export type DateTypeFilter = "REFERRAL" | "SUBMITTED" | "OUTCOME";

export interface DashboardRequestDTO {
  range: PresetRangeFilter;
  from?: Date;
  to?: Date;
  assignedToMe?: boolean;
  advisorId?: number;
  introducerId?: number;
  dateTypeFilter?: DateTypeFilter;
  referralSource?: ReferralSource;
}

export interface MortgageProgressionDTO {
  leadConversion: LeadConversionDTO;
  mortgageTypes: MortgageTypesDTO;
  lenderSpread: NpwStageChartValue;
  dashboardSubmittedStats: {
    mortgagesSubmitted: {
      total: number;
      data: LineChartValue[];
    };
    mortgagesReSubmitted: {
      total: number;
      data: LineChartValue[];
    };
    resubmittedPercentage: string;
    avgPercentage: string;
    avgPercentageIndicator: DirectionType;
    avgPercentageIndicatorColor: SuccessIndicationColor;
  };
}

export interface ProtectionProgressionDTO {
  leadConversion: LeadConversionDTO;
  dashboardSubmittedStats: {
    mortgagesSubmitted: {
      total: number;
      data: LineChartValue[];
    };
    mortgagesReSubmitted: {
      total: number;
      data: LineChartValue[];
    };
  };
  providerSpread: NpwStageChartValue;
  referralSpread: NpwStageChartValue;
  typeCoverSplit: NpwStageChartValue;
  premiumOnRiskStats: {
    onRisk: {
      total: number;
      data: LineChartValue[];
    };
    onRiskAvg: {
      total: number;
      data: LineChartValue[];
    };
  };
  referralSourcePerformance: {
    advisorReferrals: {
      contacted: string;
      contactedPercentage: string;
      submitted: string;
      submittedPercentage: string;
      onRisk: string;
      onRiskPercentage: string;
    };
    replacements: {
      contacted: string;
      contactedPercentage: string;
      submitted: string;
      submittedPercentage: string;
      onRisk: string;
      onRiskPercentage: string;
    };
    manualReferrals: {
      contacted: string;
      contactedPercentage: string;
      submitted: string;
      submittedPercentage: string;
      onRisk: string;
      onRiskPercentage: string;
    };
  };
  replacementAndLapses: {
    lapsedPolicies: string;
    lapsedPoliciesAvg: string;
    lapsedPoliciesAvgIndicator: DirectionType;
    lapsedPoliciesAvgIndicatorColor: SuccessIndicationColor;
    lapsedPoliciesPercentage: string;
  };
}

export interface LeadConversionDTO {
  advisorId: string;
  totalReferrals: string;
  totalContacted: string;
  totalOnRisk: string;
  totalSubmitted: string;
  totalNPW: string;
  totalResubmitted: string;
  totalOffered: string;
  totalCompletion: string;
  totalNotProceeding: string;
  totalDeleted: string;
  refToSubPercentage: string;
  refToSubPercentageAvg: string;
  refToSubPercentageIndicator: DirectionType;
  refToSubPercentageIndicatorColor: SuccessIndicationColor;
  refToOfferedPercentage: string;
  refToOfferedPercentageAvg: string;
  refToOfferedPercentageIndicator: DirectionType;
  refToOfferedPercentageIndicatorColor: SuccessIndicationColor;
  refToCompPercentage: string;
  refToCompPercentageAvg: string;
  refToCompPercentageIndicator: DirectionType;
  refToCompPercentageIndicatorColor: SuccessIndicationColor;
  subToOfferedPercentage: string;
  subToOfferedPercentageAvg: string;
  subToOfferedPercentageIndicator: DirectionType;
  subToOfferedPercentageIndicatorColor: SuccessIndicationColor;
  offeredToCompPercentage: string;
  offeredToCompPercentageAvg: string;
  offeredToCompPercentageIndicator: DirectionType;
  offeredToCompPercentageIndicatorColor: SuccessIndicationColor;
  refToNPWPercentage: string;
  refToNPWPercentageAvg: string;
  refToNPWPercentageIndicator: DirectionType;
  refToNPWPercentageIndicatorColor: SuccessIndicationColor;
  subToNPWPercentage: string;
  subToNPWPercentageAvg: string;
  subToNPWPercentageIndicator: DirectionType;
  subToNPWPercentageIndicatorColor: SuccessIndicationColor;
  offeredToNPWPercentage: string;
  offeredToNPWPercentageAvg: string;
  offeredToNPWPercentageIndicator: DirectionType;
  offeredToNPWPercentageIndicatorColor: SuccessIndicationColor;
  compToNPWPercentage: string;
  compToNPWPercentageAvg: string;
  compToNPWPercentageIndicator: DirectionType;
  compToNPWPercentageIndicatorColor: SuccessIndicationColor;
  refToSubDaysAvg: string;
  refToSubDays: string;
  refToSubDaysIndicator: DirectionType;
  refToSubDaysIndicatorColor: SuccessIndicationColor;
  subToOfferedDaysAvg: string;
  subToOfferedDays: string;
  subToOfferedDaysIndicator: DirectionType;
  subToOfferedDaysIndicatorColor: SuccessIndicationColor;
  offeredToCompDaysAvg: string;
  offeredToCompDays: string;
  offeredToCompDaysIndicator: DirectionType;
  offeredToCompDaysIndicatorColor: SuccessIndicationColor;
  refToContactedPercentage: string;
  refToContactedPercentageAvg: string;
  refToContactedPercentageIndicator: DirectionType;
  refToContactedPercentageIndicatorColor: SuccessIndicationColor;
  refToOnRiskPercentage: string;
  refToOnRiskPercentageIndicator: DirectionType;
  refToOnRiskPercentageIndicatorColor: SuccessIndicationColor;
  refToOnRiskPercentageAvg: string;
  contactedToSubPercentage: string;
  contactedToSubPercentageIndicator: DirectionType;
  contactedToSubPercentageIndicatorColor: SuccessIndicationColor;
  contactedToSubPercentageAvg: string;
  subToOnRiskPercentage: string;
  subToOnRiskPercentageIndicator: DirectionType;
  subToOnRiskPercentageIndicatorColor: SuccessIndicationColor;
  subToOnRiskPercentageAvg: string;
  subToOnRiskDaysIndicator: DirectionType;
  contactedToNPWPercentage: string;
  contactedToNPWPercentageIndicator: DirectionType;
  contactedToNPWPercentageIndicatorColor: SuccessIndicationColor;
  contactedToNPWPercentageAvg: string;
  onRiskToNPWPercentage: string;
  onRiskToNPWPercentageIndicator: DirectionType;
  onRiskToNPWPercentageIndicatorColor: SuccessIndicationColor;
  onRiskToNPWPercentageAvg: string;
  refToContactedDaysAvg: string;
  refToContactedDaysAdvAvg: string;
  refToContactedDaysIndicator: DirectionType;
  refToContactedDaysIndicatorColor: SuccessIndicationColor;
  contactedToSubDaysAvg: string;
  contactedToSubDaysAdvAvg: string;
  contactedToSubDaysAvgIndicator: DirectionType;
  contactedToSubDaysAvgIndicatorColor: SuccessIndicationColor;
  subToOnRiskDaysAvg: string;
  subToOnRiskDaysAvgIndicator: DirectionType;
  subToOnRiskDaysAvgIndicatorColor: SuccessIndicationColor;
  subToOnRiskDaysAdvAvg: string;
  contactedToSubDaysIndicator: DirectionType;
}
export interface MortgageTypesDTO {
  debtPercentage: string;
  debtCount: string;
  debtPercentageAvg: string;
  debtPercentageAvgIndicator: DirectionType;
  debtPercentageAvgIndicatorColor: SuccessIndicationColor;
  noDebtPercentage: string;
  noDebtCount: string;
  intoRetirementPercentage: string;
  intoRetirementCount: string;
  intoRetirementPercentageAvg: string;
  intoRetirementPercentageAvgIndicator: DirectionType;
  intoRetirementPercentageAvgIndicatorColor: SuccessIndicationColor;
  notIntoRetirementPercentage: string;
  notIntoRetirementCount: string;
  decMortPercentage: string;
  decMortCount: string;
  decMortPercentageAvgIndicator: DirectionType;
  decMortPercentageAvgIndicatorColor: SuccessIndicationColor;
  notDecMortPercentage: string;
  notDecMortCount: string;
  ercPercentage: string;
  ercCount: string;
  ercPercentageAvg: string;
  ercPercentageAvgIndicator: DirectionType;
  ercPercentageAvgIndicatorColor: SuccessIndicationColor;
  noErcPercentage: string;
  noErcCount: string;
  prodTransPercentage: string;
  prodTransCount: string;
  prodTransPercentageAvg: string;
  prodTransPercentageAvgIndicator: DirectionType;
  prodTransAvgIndicatorColor: SuccessIndicationColor;
  remortPercentage: string;
  remortCount: string;
  mortPercentage: string;
  mortCount: string;
  fiveYearsFixedPercentage: string;
  fiveYearsFixedCount: string;
  fiveYearsTrackerPercentage: string;
  fiveYearsTrackerCount: string;
  twoYearsFixedPercentage: string;
  twoYearsFixedCount: string;
  twoYearsTrackerPercentage: string;
  twoYearsTrackerCount: string;
  otherMortSplitPercentage: string;
  otherMortSplitCount: string;
}

export interface ChartDataDTO {
  date: Date;
  value: number;
}

export interface ChartLongDTO {
  total: number;
  trend: number;
  data: ChartDataDTO[];
}

export interface DashboardStatsDTO {
  mortgage: {
    mortgagesSubmitted: ChartLongDTO;
    loanAmountSubmitted: ChartLongDTO;
    mortgagesCompleted: ChartLongDTO;
    offersObtained: ChartLongDTO;
    factFindRequested: ChartLongDTO;
    appointmentsBooked: ChartLongDTO;
  };
  protection: {
    submitted: ChartLongDTO;
    submittedPremium: ChartLongDTO;
    onRisk: ChartLongDTO;
    onRiskPremium: ChartLongDTO;
    factFindRequested: ChartLongDTO;
    appointmentsBooked: ChartLongDTO;
  };

  waitingCompletion: number;
  upcomingExpiry: number;
}

export interface ManagementReportDTO {
  advisorId: string;
  advisorName: string;
  submitted: number;
  submittedLoanAmount: number;
  commission: number;
  resubmissions: number;
  resubmissionsLoanAmount: number;
  resubmissionsCommission: number;
}

export interface ProtectionManagementReportDTO {
  advisorId: string;
  advisorName: string;
  submitted: number;
  submittedPremium: number;
  commission: number;
  clientsSoldTo: number;
  onRisk: number;
  onRiskPremium: number;
}

export interface ManagementRequestDTO {
  range: PresetRangeFilter;
  from: Date;
  to: Date;
  sortBy?: string;
  sortDirection?: "ASC" | "DESC";
}

@Injectable()
export class ReportApiService {
  private API_NAME = "report";

  constructor(
    private apiService: ApiService,
    private environment: Environment
  ) {}

  public getReport(body) {
    return this.apiService.post("report", "getReport", body).toPromise();
  }

  public getDashboardStats(params: DashboardRequestDTO) {
    params.to = toLocalDateISO(params.to) as any;
    params.from = toLocalDateISO(params.from) as any;

    return this.apiService
      .post(this.API_NAME, "getDashboardStats", params)
      .toPromise();
  }

  public getMortgageManagementReport(
    params: ManagementRequestDTO
  ): Observable<ListResult<ManagementReportDTO[]>> {
    params.to = toLocalDateISO(params.to) as any;
    params.from = toLocalDateISO(params.from) as any;

    return this.apiService.post(
      this.API_NAME,
      "getMortgageManagementReport",
      params
    );
  }

  public getProtectionManagementReport(
    params: ManagementRequestDTO
  ): Observable<ListResult<ProtectionManagementReportDTO[]>> {
    params.to = toLocalDateISO(params.to) as any;
    params.from = toLocalDateISO(params.from) as any;

    return this.apiService.post(
      this.API_NAME,
      "getProtectionManagementReport",
      params
    );
  }

  public getMortgageProgressionData(
    params: DashboardRequestDTO
  ): Observable<MortgageProgressionDTO> {
    params.to = toLocalDateISO(params.to) as any;
    params.from = toLocalDateISO(params.from) as any;

    return this.apiService.post(
      this.API_NAME,
      "getMortgageProgressionData",
      params
    );
  }

  public getProtectionProgressionData(
    params: DashboardRequestDTO
  ): Observable<ProtectionProgressionDTO> {
    params.to = toLocalDateISO(params.to) as any;
    params.from = toLocalDateISO(params.from) as any;

    return this.apiService.post(
      this.API_NAME,
      "getProtectionProgressionData",
      params
    );
  }

  public getMortgageToProtectionConversionCountData(
    params: DashboardRequestDTO
  ): Observable<MortgageToProtectionConversionCountsDTO> {
    return this.apiService.post(
      this.API_NAME,
      "getMortgageToProtectionConversionCounts",
      params
    );
  }

  public getProtectionNPWCount(
    params: DashboardRequestDTO
  ): Observable<NPWCountDTO> {
    return this.apiService.post(this.API_NAME, "getProtectionNPWCount", params);
  }

  public getMortgageNPWCountData(
    params: DashboardRequestDTO
  ): Observable<NPWCountDTO> {
    return this.apiService.post(this.API_NAME, "getMortgageNPWCount", params);
  }

  public triggerMortgageManagementReportDownload(params: ManagementRequestDTO) {
    this.triggerReportDownload("managementReportExport/mortgage", params);
  }

  public triggerAllMortgageReportDownload(params: ManagementRequestDTO) {
    this.triggerReportDownload("allMortgageExport", params);
  }

  public triggerAllProtectionReportDownload(params: ManagementRequestDTO) {
    this.triggerReportDownload("allProtectionExport", params);
  }

  public triggerProtectionManagementReportDownload(
    params: ManagementRequestDTO
  ) {
    this.triggerReportDownload("managementReportExport/protection", params);
  }

  private triggerReportDownload(name: string, params: ManagementRequestDTO) {
    params = R.reject(R.isNil, params as any);
    const url = `${this.environment.apiUrl}servlet/${name}?${new HttpParams({
      fromObject: params as any,
    })}`;

    window.open(url);
  }

  private triggerReportDownloadPost(
    name: string,
    params: ManagementRequestDTO
  ) {
    params = R.reject(R.isNil, params as any);
    const url = `${this.environment.apiUrl}servlet/${name}?${new HttpParams({
      fromObject: params as any,
    })}`;
    const form = document.createElement("form");
    const textarea = document.createElement("textarea");

    form.action = url;
    form.method = "POST";
    form.target = "_blank";
    form.style.display = "none";
    form.appendChild(textarea);
    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
  }
}
