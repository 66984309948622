import { Injectable } from '@angular/core';
import { ToastService, ToastSeverity } from '../../../../projects/client/src/app/shared/services/toast.service';

interface Message {
  severity?: string;
  summary?: string;
  detail?: string;
}

@Injectable({
  providedIn: 'root'
})

export class ToasterService {
  constructor(private toastService: ToastService) { }

  /**
   * @obsolete Use `ToastService` instead
   */
  callToaster(toaster: string | Message) {
    const message = typeof toaster === 'string' ? toaster : toaster.detail;
    const severity = typeof toaster === 'string' ? undefined : toaster.severity;

    this.toastService.add(message, severity as ToastSeverity);
  }
}
