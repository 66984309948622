import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { EventUtils } from '../../../utils/event.utils';
import { environment } from '../../../../../environments/environment';
import { AbstractControl, FormGroup } from '@angular/forms';
import { FileControlsConfigBuilder } from '../utils/fileuploader.utils';

@Component({
  selector: 'hf-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss']
})
export class FilePreviewComponent implements OnInit, OnChanges {

  @Input() group: FormGroup;
  @Input() controlName: string;
  @Input() fileControl: AbstractControl;
  @Input() fileChanged = {
    fileType: null
  };
  @Input() invalidFile = true;
  @Output() deleteFile = new EventEmitter();

  thumbnailUrl: string;
  hasThumbnail: boolean;

  ngOnInit(): void {
    if (this.group.value[this.controlName] && this.group.value[this.controlName].filename) {
      this.thumbnailUrl = this.getThumbnailUrl();
    }
    this.hasThumbnail = this.initHasThumbnail();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.fileControl && this.group.value[this.controlName] && this.group.value[this.controlName].filename) {
      this.thumbnailUrl = this.getThumbnailUrl();
    }
    if (changes.fileChanged) {
      this.hasThumbnail = this.initHasThumbnail();
    }
  }

  onDeleteFileBtnClick(event: Event) {
    EventUtils.preventDefaultAndStopPropagation(event);
    this.deleteFile.emit();
  }

  getFileName(): string {
    return this.group.value[this.controlName].originalFilename;
  }

  isFileSaved(): boolean {
    return this.group.value[this.controlName] && this.group.value[this.controlName][FileControlsConfigBuilder.ID];
  }

  private getThumbnailUrl() {
    return environment.apiUrl + 'servlet/gcs/' + this.group.value[this.controlName].folder + '/' + this.group.value[this.controlName].filename;
  }

  private initHasThumbnail(): boolean {
    return this.group.value[this.controlName].originalFilename.match(/.(jpg|jpeg|png|gif)$/i);
  }
}
