import { Component, Input } from '@angular/core';

@Component({
  selector: 'hf-toolbar-button',
  templateUrl: './toolbar-button.component.html',
  styleUrls: ['./toolbar-button.component.scss']
})
export class ToolbarButtonComponent {
  @Input()
  public icon: string;

  @Input()
  public label: string;
}
