import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MortgageExportButtonComponent } from './mortgage-export-button.component';

@NgModule({
  declarations: [MortgageExportButtonComponent],
  imports: [
    CommonModule
  ],
  exports: [MortgageExportButtonComponent]
})
export class MortgageExportButtonModule { }
