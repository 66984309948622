import {NgModule} from "@angular/core";
import {SelectUserForDeleteComponent} from "./select-user-for-delete.component";
import {CustomButtonModule} from "../../../shared/custom-button/custom-button.module";
import {CommonModule} from "@angular/common";
import {CustomSelectorModule} from "../../../shared/custom-selector/custom-selector.module";

@NgModule({
  imports: [
    CustomButtonModule,
    CommonModule,
    CustomSelectorModule
  ],
  declarations: [
    SelectUserForDeleteComponent
  ],
  exports: [
    SelectUserForDeleteComponent
  ]
})

export class SelectUserForDeleteModule {

}
