<div class="overlay" [routerLink]="['./..']" [@fadeAnimation]></div>

<div class="sidepane-content">
  <div class="template-header flex-space-between-layout">
    <h1 *ngIf="onlyDate" class="title-area">Select Date</h1>
    <h1 *ngIf="!onlyDate" class="title-area">Select Date and Time</h1>
    <hf-custom-button type="button"
                      class="button-plain-icon"
                      icon="close"
                      iconPos="center"
                      [routerLink]="['..']">
    </hf-custom-button>
  </div>

  <div class="template-main">
    <div [style.width.%]="100" class="padding-y">
      <form [formGroup]="form">
        <hf-custom-datepicker [group]="form"
                              [isInline]="true"
                              [minDate]="minDateValue"
                              [maxDate]="maxDateValue"
                              [monthNavigator]="true"
                              [yearNavigator]="true"
                              [yearRange]="yearRangeValue"
                              pickerFormControlName="date">
        </hf-custom-datepicker>

        <ng-container *ngIf="!onlyDate">
          <label>Time</label>
          <div [style.width.%]="100"
               class="flex-space-between-layout time-wrapper"
               marginElement>
            <div class="flex-time">
              <hf-custom-text-field type="text"
                                    [group]="form"
                                    [pattern]="'^(0[0-9]|1[0-9]|2[0-3])$'"
                                    [maxLength]="2"
                                    placeholder="HH"
                                    inputFormControlName="hours">
              </hf-custom-text-field>

              <div class="divider">:</div>

              <hf-custom-text-field type="text"
                                    [group]="form"
                                    [pattern]="'[0-5]?[0-9]'"
                                    [maxLength]="2"
                                    placeholder="MM"
                                    inputFormControlName="minutes">
              </hf-custom-text-field>
            </div>
          </div>
        </ng-container>

      </form>
    </div>
  </div>

  <div class="template-footer">
    <hf-custom-button type="submit"
                      class="button-primary"
                      label="Apply"
                      [disabled]="form.invalid"
                      (onClick)="onSubmit()">
    </hf-custom-button>

    <hf-custom-button type="button"
                      class="button-secondary"
                      label="Cancel"
                      [routerLink]="['./..']">
    </hf-custom-button>
  </div>
</div>

