import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ReviewSectionState } from '../../../../../../shared/components/review-section/review-section.component';
import { APPLICANT_CATEGORY_NAMES, APPLICANT_SCHEME_NAMES_ALL, APPLICATION_TYPE_NAME } from '../../../../../enums.module';
import { FactFindData } from '../../../../../services/fact-find-api.service';

@Component({
  selector: 'hfc-application-details-review-section',
  templateUrl: './application-details-review-section.component.html',
  styleUrls: ['./application-details-review-section.component.scss']
})
export class ApplicationDetailsReviewSectionComponent {
  public APPLICANT_CATEGORY_NAMES = APPLICANT_CATEGORY_NAMES;
  public APPLICANT_SCHEME_NAMES = APPLICANT_SCHEME_NAMES_ALL;
  public APPLICATION_TYPE_NAME = APPLICATION_TYPE_NAME;

  @Input()
  public data: FactFindData;

  @Input()
  public config: ReviewSectionState;

  @Output()
  public buttonClick = new EventEmitter<ReviewSectionState>();

  public yesno(value: boolean) {
    return value ? "Yes" : "No";
  }
}
