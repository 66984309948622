import { Injectable } from "@angular/core";
import { ParamMap } from "@angular/router";
import { UserSimple } from "../../../models";
import { UserApiService } from "../../../services/user-api.service";
import { AddTaskService } from "../../../shared-layout/side-panes/add-task/add-task.component";

@Injectable()
export class MortgageAddTaskService implements AddTaskService {
  constructor(private usersApiService: UserApiService) { }

  public getHeronUsers(paramMap: ParamMap): Promise<UserSimple[]> {
    const params = { mortgageId: paramMap.get("id") };

    return this.usersApiService.getHeronUsers(params);
  }
}
