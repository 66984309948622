<div
  *ngIf="popupOpened"
  class="hf-menu-wrapper"
  click-away
  [@fadeAnimation]
  (clickedAway)="closeMenu()">
    <ul>
      <ng-container *ngFor="let menuItem of items()">
        <li *ifPermission="menuItem.permission"
            [ngStyle]="menuItem.styles"
            [class.item-disabled]="menuItem.disabled"
            (click)="onItemClicked(menuItem)">
          {{menuItem.label}}
        </li>
      </ng-container>
    </ul>
</div>
