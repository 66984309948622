<div *ifMedia="'phone'" class="container" (click)="popupVisible=true">
  <ng-container *ngIf="selectedItem">
    <ng-container *ngTemplateOutlet="inputItemTemplate, context: { $implicit: selectedItem }">
    </ng-container>
  </ng-container>

  <i *ngIf="!noChevron" class="material-icons">chevron_right</i>
</div>

<div *ngIf="popupVisible" class="popup" @slideUp>
  <div class="header">
    <span class="text-h4-medium-grey24">Compliance</span>
    <i class="material-icons close-icon" (click)="popupVisible = false">close</i>
  </div>
  <hf-link-list [items]="items" [selectedItem]="selectedItem" [clickCallback]="clickCallback" (itemClicked)="onItemClicked($event)" [inputItemTemplate]="inputItemTemplate">
  </hf-link-list>
  
  <div class="backdrop" (click)="popupVisible = false" @backdrop></div>
</div>

<hf-link-list *ifMedia="'desktop'" [items]="items" [selectedItem]="selectedItem" [clickCallback]="clickCallback" (itemClicked)="itemClicked.next($event)" [inputItemTemplate]="inputItemTemplate" [noChevron]="true">
</hf-link-list>
