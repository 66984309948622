<div class="flex-header table-page-header single-button-header">
  <hf-nav-button></hf-nav-button>
  <div class="flex-title">
    <h1>Heron Users</h1>
  </div>

  <div class="single-button-wrap">
    <hf-custom-button *ifPermission="'HERON_USER_NEW'"
                      label="Add Heron User"
                      class="button-primary"
                      icon="add_circle"
                      iconPos="left"
                      textPos="left"
                      [routerLink]="['./addUser']">
    </hf-custom-button>
  </div>
</div>

<div class="table-page-container">
  <div class="search-box-line">
    <div class="search-bar-wrap">
      <hf-custom-text-field type="text"
                            [searchMode]="true"
                            placeholder="Search users …"
                            fieldName="input12"
                            icon="search"
                            preventValidation="true">
      </hf-custom-text-field>
    </div>
    <hf-custom-button type="button"
                      class="button-plain-icon for-phone-show"
                      icon="more_horiz"
                      iconPos="center"
                      (click)="dashboardHeaderButtons.classList.add('dashboard-headers-buttons-visible')">
    </hf-custom-button>
  </div>

  <div class="utils-area dashboard-header-buttons"
       #dashboardHeaderButtons
       (click)="dashboardHeaderButtons.classList.remove('dashboard-headers-buttons-visible')">
    <div class="pagination-area">
      <hf-item-per-page-selector>
      </hf-item-per-page-selector>
    </div>
  </div>

  <div class="everglades-table" hfTable>
    <div class="everglades-table-headings">
      <div [sortBy]="'firstName;lastName'" [isDefault]="true" style="flex: 1;padding-left: 48px;"><span>Name</span></div>
      <div [sortBy]="'userType'"><span>User Type</span></div>
      <div [sortBy]="'email'" style="flex: 2;"><span>Email</span></div>
      <div [sortBy]="'twoFactorStateOrder'" class="center" style="flex: 0.5"><span>2FA</span></div>
      <div [sortBy]="'microsoftLinkState'" class="center" style="flex: 0.5"><span>Office 365</span></div>
      <div [sortBy]="'twentySevenTecLinkState'" class="center" style="flex: 0.5"><span>Twenty7tec</span></div>
      <div><span>Line Manager</span></div>
      <div [sortBy]="'lastLogin'"><span>Last Login</span></div>
    </div>

    <a *ngFor="let row of data" (click)="viewUser(row.id)">
      <div class="table-row" [style.cursor]="hasPermission() ? 'pointer' : 'default'">
        <div data-mobileTitle="Photo" class="avatar" style="flex: 0 0 40px; padding: 0 0 0 8px;"><hf-avatar [photo]="row.photo"></hf-avatar></div>
        <div data-mobileTitle="Name" style="flex: 1">{{row.fullName}}</div>
        <div data-mobileTitle="User Type">{{USER_TYPE_NAMES.get(row.userType)}}</div>
        <div data-mobileTitle="Email" style="flex: 2">{{row.email}}</div>
        <div data-mobileTitle="2FA" class="center" style="flex: 0.5">
          <hf-status-icon type="TwoFactorState" [status]="row.twoFactorState" [hfTooltip]="getTwoFactorTooltip(row)"></hf-status-icon>
        </div>
        <div data-mobileTitle="Office 365" class="center" style="flex: 0.5">
          <hf-status-icon type="MicrosoftLinkState" [status]="row.microsoftLinkState" [hfTooltip]="getLinkTooltip(row.microsoftLinkState)"></hf-status-icon>
        </div>
        <div data-mobileTitle="Twenty7tec" class="center" style="flex: 0.5">
          <hf-status-icon type="MicrosoftLinkState" [status]="row.twentySevenTecLinkState" [hfTooltip]="getLinkTooltip(row.twentySevenTecLinkState)"></hf-status-icon>
        </div>
        <div data-mobileTitle="Line Manager">{{row.lineManager?.fullName ? row.lineManager?.fullName : '-'}}</div>
        <div data-mobileTitle="Last Login">{{row.lastLogin ? (row.lastLogin | date: 'd MMM y') : '-'}}</div>
      </div>
    </a>
  </div>

  <div class="pagination-row">
    <hf-pagination-summary-box></hf-pagination-summary-box>
    <hf-paginator></hf-paginator>
  </div>
</div>

<div class="sidepanes-anchor">
  <router-outlet></router-outlet>
</div>
