import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MortgageApiService } from "../../../services/mortgage-api.service";
import { ToasterService } from "../../../services/toaster.service";
import { UserApiService } from '../../../services/user-api.service';
import { JournalTabService } from '../../../shared-layout/tabs/journal/journal-tab.service';
import { fadeAnimation, sidepaneMove } from "../../../shared/animations/sidepane-animation";
import { MortgagesService } from "../mortgages.service";
import { ToastService } from '../../../../../../projects/client/src/app/shared/services/toast.service';

@Component({
  selector: 'hf-assign-mortgage',
  templateUrl: './assign-mortgage.component.html',
  styleUrls: ['./assign-mortgage.component.scss',
    '../../../styles/sidepanes.partial.scss'],
  animations: [sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove]': 'true' }
})
export class AssignMortgageComponent implements OnInit {
  public isLoading: boolean = true;
  public isSubmitting = false;
  public selectAssignerFormGroup: FormGroup = this.fb.group({});
  public submitResponse;
  public _hostSelectionActive: boolean;
  public selectedHostId;

  private mortgageId;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private mortgageApiService: MortgageApiService,
    private userApiService: UserApiService,
    private mortgagesService: MortgagesService,
    private toastService: ToastService,
    private journalTabService: JournalTabService) {

    if (this.router.url.indexOf('arrangeAppointment') > -1)
      this._hostSelectionActive = true;

    this.mortgageId = this.activatedRoute.snapshot.params.id;
  }

  async ngOnInit() {
    const assigners = this.fb.array([]);
    await this.userApiService.getMortgageAdvisorsForAssignment(this.mortgageId).then(result => {
      for (const item of result) {
        assigners.push(this.fb.group({
          fullName: [item.fullName],
          id: [item.id, Validators.required],
          signedUser: [item.signedUser],
          defaultAdvisor: [item.defaultAdvisor],
          radio: new FormControl(false)
        }));
      }

      this.selectAssignerFormGroup = this.fb.group({
        assigners: assigners,
        selectedItem: new FormControl(this.mapItems(assigners.value), Validators.required)
      });

      if (this.activatedRoute.snapshot.queryParams.hostId) {
        this.selectAssignerFormGroup.get('selectedItem').setValue([this.activatedRoute.snapshot.queryParams.hostId]);
        this.selectedHostId = this.activatedRoute.snapshot.queryParams.hostId;
      }

      this.isLoading = false;
    });
  }

  onBack() {
    this.router.navigate(['./..'], { relativeTo: this.activatedRoute });
  }

  mapItems(items) {
    const selectedItem = items.filter((item) => item.radio).map((item) => item.id);
    return selectedItem.length ? selectedItem : null;
  }

  selectRadio(event) {
    Object.keys((this.selectAssignerFormGroup.controls['assigners'] as FormArray).controls).forEach(index => {
      (this.selectAssignerFormGroup.controls['assigners'] as FormArray).controls[index].value.radio =
        event.srcElement.id === 'assigner_' + (this.selectAssignerFormGroup.controls['assigners'] as FormArray).controls[index].value.id;
    });
    this.selectAssignerFormGroup.get('selectedItem').setValue(this.mapItems((this.selectAssignerFormGroup.controls['assigners'] as FormArray).value));

    if (this._hostSelectionActive)
      this.onSubmit();
  }

  public async onSubmit() {
    let selectedAdvisor = this.selectAssignerFormGroup.value.assigners.find(assigner => assigner.id == this.selectAssignerFormGroup.value.selectedItem[0]);

    if (!this._hostSelectionActive) {
      this.isSubmitting = true;

      try {
        await this.mortgageApiService.assignAdvisor(this.selectAssignerFormGroup.value.selectedItem, this.mortgageId);
        this.toastService.add(`Mortgage successfully assigned to ${selectedAdvisor.fullName}`, "info");
        this.mortgagesService.reloadMortgageDashboard.emit();
        this.journalTabService.reloadJournalTab.emit();
        this.onBack();
      }
      catch (e) {
        this.toastService.add("Unable to assign advisor: ", "error", e);
      };

      this.isSubmitting = false;

    } else {
      this.mortgagesService.hostSelected.emit(selectedAdvisor);
      this.onBack();
    }
  }
}
