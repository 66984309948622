
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectHelpToBuyStatusComponent } from './select-help-to-buy-status.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomButtonModule } from '../../../shared/custom-button/custom-button.module';
import { CustomSelectorModule } from '../../../shared/custom-selector/custom-selector.module';

@NgModule({
  declarations: [SelectHelpToBuyStatusComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CustomButtonModule,
    CustomSelectorModule
  ],
  exports: [SelectHelpToBuyStatusComponent]
})
export class SelectHelpToBuyStatusModule { }
