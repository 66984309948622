import { Component, Input } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'hf-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.scss'],
  providers: [CurrencyPipe],
})
export class CurrencyComponent {
  @Input()
  public value: number;

  @Input()
  public format = "1.2-2";
}
