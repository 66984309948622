import { animate, state, style, transition, trigger } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input } from '@angular/core';
import { PROTECTION_APPLICATION_STEP_NAME } from '../../../../../../../../../projects/client/src/app/protection-fact-find/protection-fact-find.enum';
import { SignOffSectionConfig } from '../../../../../../../../../projects/client/src/app/protection-fact-find/steps/sign-off-component/components/sign-off-applicant-details/sign-off-applicant-details.component';
import { ProtectionFactFindApplicantRichDataDTO } from '../../../../services/protection-fact-find-api.service';
import { ProtectionDashboardFactFindComponent } from '../protection-dashboard-fact-find.component';

export const rollAnimation = trigger('rollAnimation',
  [
    state('*',
      style({
        'max-height': '700px',
        'opacity': '1',
      })),
    state('void',
      style({
        'max-height': '0',
        'opacity': '0.3',
      })),
    transition('* <=> void', animate('150ms')),
    transition('void <=> *', animate('150ms'))
  ]
);

export const rotateAnimation = trigger('rotateAnimation',
  [
    state('true',
      style({
        'transform': 'rotate(90deg)',
      })),
    state('false',
      style({
        'transform': 'rotate(0deg)',
      })),
    transition('* <=> *', animate('150ms')),
  ]
);

@Component({
  selector: 'hf-protection-dashboard-fact-find-navigation',
  templateUrl: './protection-dashboard-fact-find-navigation.component.html',
  styleUrls: ['./protection-dashboard-fact-find-navigation.component.scss'],
  animations: [rollAnimation, rotateAnimation]
})
export class ProtectionDashboardFactFindNavigationComponent {
  @Input()
  public applicants: ProtectionFactFindApplicantRichDataDTO[];

  public activeApplicantId: string;

  PROTECTION_APPLICATION_STEP_NAME = PROTECTION_APPLICATION_STEP_NAME;

  private activeApplicantIdManual: string;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private protectionDashboardFactFindComponent: ProtectionDashboardFactFindComponent
  ) {
    this.protectionDashboardFactFindComponent.visibleApplicantId.subscribe(visibleApplicantId => {
      // console.log("visibleApplicantId: ", visibleApplicantId);

      if (this.activeApplicantIdManual && this.activeApplicantIdManual != visibleApplicantId) {
        return;
      }

      this.activeApplicantId = visibleApplicantId;
      this.activeApplicantIdManual = undefined;
    });
  }

  isApplicantActive(applicant) { return applicant.applicant.id == this.activeApplicantId }

  public onApplicantClicked(applicant) {
    const applicantId = applicant.applicant.id;

    if (this.activeApplicantId == applicantId) {
      this.activeApplicantId = undefined;
    } else {
      this.activeApplicantId = applicantId;
    }
  }

  public onMenuItemClicked(config: SignOffSectionConfig, applicant: any) {
    const applicantId = applicant.applicant.id;
    const id = `#A${applicantId}-${config.step}`;
    const element = this.document.querySelector(id);

    this.activeApplicantIdManual = applicantId;

    element && element.scrollIntoView({ behavior: "smooth" });
  }
}
