import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from "@angular/router";

import { ToasterService } from '../../../../services/toaster.service';
import { fadeAnimation, sidepaneMove } from "../../../../shared/animations/sidepane-animation";
import { TaskApiService } from '../../task-api.service';
import { TaskService } from '../../task.service';
import { MortgagesService } from '../../../mortgages/mortgages.service';

@Component({
  selector: 'hf-delete-task',
  templateUrl: 'delete-task.component.html',
  styleUrls: ['delete-task.component.scss',
    '../../../../styles/sidepanes.partial.scss'],
  animations: [sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove]': 'true' }
})

export class DeleteTaskComponent {
  public form = this.fb.group({
    note: [null, Validators.required],
  });
  public isSubmitting = false;

  private get taskId() { return this.route.snapshot.paramMap.get("taskId") }

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private taskApiService: TaskApiService,
    private taskService: TaskService,
    private mortgagesService: MortgagesService,
    private toasterService: ToasterService,
  ) { }

  public async onDeletelicked() {
    this.isSubmitting = true;

    try {
      const value = this.form.value;

      await this.taskApiService.deleteTask(value, this.taskId);

      this.taskService.reloadTasks$.next();
      this.mortgagesService.reloadMortgageDashboard.next();
      this.toasterService.callToaster({ severity: 'info', summary: 'Info', detail: 'Task deleted.' });

      this.onBack();
    } catch (err) {
      this.toasterService.callToaster({ severity: 'error', summary: 'Error', detail: `Failed to delete the task. Please try again ${err.error.error.message}` });
    } finally {
      this.isSubmitting = false;
    }
  }

  public onBack() {
    this.router.navigate(['../../..'], { relativeTo: this.route });
  }
}
