<div class="header">
  <div class="heading">
    Address History
    <div class="applicant">{{ getApplicantName(applicantData) }}</div>

    <div *ngIf="config?.updatedBy" class="saved-by">
      Saved by {{ config.updatedBy.fullName }} at {{config?.lastUpdated | date:'HH:mm on E d MMM y'}}
    </div>
  </div>

  <hfc-button *ngIf="config.buttonType" [type]="config.buttonType" [isLoading]="config.buttonIsLoading" size="small" (click)="buttonClick.next(config)">{{ config.buttonLabel }}</hfc-button>
</div>

<div *ngIf="config.returnComment" class="returnComment">
  {{config.returnComment}}
  <div class="lastUpdated">{{config.lastUpdated | date:'d MMM y, HH:mm'}}</div>
</div>

<div *ngIf="config.stepStatus == 'NOT_STARTED'" class="notReady">
  Waiting for applicant to complete
</div>

<ng-container *ngIf="config.stepStatus !== 'NOT_STARTED'">
  <div *ngFor="let history of applicantData.addressHistory; let i=index" class="content repeated">
    <div class="header-2">
      {{ (i === 0 ? 'Current address' : 'Previous address') + ' since ' + (history.residentSince | date:'d MMM y') }}
      <hfc-aml-result-small *ngIf="i === 0 && applicantData.experianAMLResult" [match]="applicantData.experianAMLResult.currentAddressMatch" style="margin-left: 8px;"></hfc-aml-result-small>
    </div>

    <div class="column">
      <div class="record">
        <span class="label">House</span>
        <span class="value">{{ history.address.house }}</span>
      </div>
      <div class="record">
        <span class="label">Street</span>
        <span class="value">{{ history.address.street }}</span>
      </div>
      <div *ngIf="history.address.line2" class="record">
        <span class="label">Address Line 2</span>
        <span class="value">{{ history.address.line2 }}</span>
      </div>
      <div class="record">
        <span class="label">City</span>
        <span class="value">{{ history.address.city }}</span>
      </div>
      <div class="record">
        <span class="label">Region</span>
        <span class="value">{{ REGION_TYPE_NAME.get(history.address.region) }}</span>
      </div>
    </div>

    <div class="column">
      <div class="record">
        <span class="label">Country</span>
        <span class="value">{{ history.address.country }}</span>
      </div>
      <div class="record">
        <span class="label">Postcode</span>
        <span class="value">{{ history.address.postcode }}</span>
      </div>
      <div class="record">
        <span class="label">Residential Status</span>
        <span class="value">{{ history.otherResidentialStatus || RESIDENTIAL_STATUS_NAME.get(history.residentialStatus) }}</span>
      </div>
      <div *ngIf="history.landlordName" class="record">
        <span class="label">Landlord Name</span>
        <span class="value">{{ history.landlordName }}</span>
      </div>
    </div>
  </div>
</ng-container>

