import { RemortgageDashboardDTO } from "../services/remortgage-api.service";

export namespace Remortgage {
  export class Initialize {
    static readonly type = "[Remortgage] Initialize Remortgage Dashboard";
    constructor(public id: string, public remortgage: RemortgageDashboardDTO) { }
  }

  export class Refresh {
    static readonly type = "[Remortgage] Refresh Remortgage Dashboard";
    constructor(public remortgage: RemortgageDashboardDTO) { }
  }

  export class Clear {
    static readonly type = "[Remortgage] Clear Remortgage Dashboard";
    constructor(public isLoading = false) { }
  }

  export class AppointmentArranged {
    static readonly type = "[Remortgage] Appointment Arranged";
  }

  export class ContactLogged {
    static readonly type = "[Remortgage] Contact Logged";
  }

  export class RefreshJournal {
    static readonly type = "[Remortgage] Refresh Journal";
  }

  export class DeleteRemortgage {
    static readonly type = "[Remortgage Menu] Delete Remortgage";
  }

  export class SetNotProceedingRemortgage {
    static readonly type = "[Remortgage Menu] Set Not Proceeding Remortgage";
  }
}

