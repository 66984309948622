import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { Client } from "../../../models/client";
import { AppointmentFormDTO } from "../../../services/appointment-api.service";
import { UserSimple } from "../../../models";

export type Appointment = any;

@Injectable()
export class ArrangeAppointmentService {
  client: any;
  currentAdvisor: UserSimple;
  currentAppointment: Appointment;

  get editMode() { return this.currentAppointment && !["CANCELED", "PASSED"].includes(this.currentAppointment.state) }

  isFactFindRequestAllowed: boolean;

  noSubmitMode = false;
  appointmentSelected$ = new Subject<AppointmentFormDTO>();
  appointmentSubmitted$ = new Subject();
  initialAppointment?: Appointment;
}
