import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Client } from '../models/client';
import { ListResult } from './api-models';
import { ApiService } from './api.service';

@Injectable()
export class ClientApiService {

  constructor(private apiService: ApiService) { }

  public getAllClients(filter: string): Promise<ListResult<Client>> {
    const params = { filter };

    return this.apiService.get('client', 'getAllClients', { params }).toPromise();
  }

  public listClients(params: Params) {
    return this.apiService.get('client', 'listClients', { params });
  }

  public changeClientCreditSearchOptOutOption(clientId: string): Promise<void> {
    const params = { clientId };

    return this.apiService.post("client", "changeClientCreditSearchOptOutOption", undefined, { params })
      .toPromise();
  }
}
