<div class="hf-selector">
  <label *ngIf="label">{{label}}</label>

  <div class="hf-selector-container hf-selector-has-value" [class.readonly]="readonly" [class.hf-selector-black-color]="!!value" [class.isInvalid]="isInvalid">

    <div class="hf-selector-label-area">
      <div class="title">
        {{value}}

        <ng-container *ngIf="!value">
          {{placeholder}}
        </ng-container>
      </div>
    </div>
    <i class="material-icons chevron-icon">chevron_right</i>
  </div>
</div>
