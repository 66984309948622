import { Component, Input } from '@angular/core';
import { Attachment } from '../../models/attachment';
import { JournalEntryConnectedDocument } from '../../models/journal-details';
import { DocumentApiService } from '../../services/document-api.service';

@Component({
  selector: 'hf-email-attachments',
  templateUrl: './email-attachments.component.html',
  styleUrls: ['./email-attachments.component.scss']
})
export class EmailAttachmentsComponent {
  @Input()
  public documents: JournalEntryConnectedDocument[];

  constructor(
    private documentApiService: DocumentApiService,
  ) { }

  public onDownloadClicked(doc) {
    this.documentApiService.triggerDownload(doc.attachment, false);
  }
}
