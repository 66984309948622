import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FactFindSharedModule } from '../../../../../../projects/client/src/app/fact-find/fact-find-shared.module';
import { ClientSharedModule } from '../../../../../../projects/client/src/app/shared/client-shared.module';
import { IfPermissionModule } from '../../../directives/if-permission/if-permission.module';
import { SharedLayoutModule } from '../../../shared-layout/shared-layout.module';
import { AddDocumentService, AddDocumentServiceAddDocumentFn, ADD_DOCUMENT_SERVICE_ADD_DOCUMENT_FN } from '../../../shared-layout/side-panes/add-document/add-document.service';
import { LOG_CONTACT_ATTEMPT_COMPONENT_CONTACT_ATTEMPT_ADDER } from '../../../shared-layout/side-panes/log-contact-attempt/log-contact-attempt.component';
import { RETURN_FACT_FIND_SERVICE } from '../../../shared-layout/side-panes/return-fact-find/return-fact-find.component';
import { SELECT_CLIENT_COMPONENT_CLIENTS_GETTER } from '../../../shared-layout/side-panes/select-client/select-client.component';
import { SelectClientService } from '../../../shared-layout/side-panes/select-client/select-client.service';
import { SelectDocumentTypeService, SELECT_DOCUMENT_TYPE_SERVICE_DOCUMENT_TYPE_NAME } from '../../../shared-layout/side-panes/select-document-type/select-document-type.service';
import { DocumentsTabService } from '../../../shared-layout/tabs/documents/documents-tab.service';
import { JournalTabService } from '../../../shared-layout/tabs/journal/journal-tab.service';
import { CustomButtonModule } from '../../../shared/custom-button/custom-button.module';
import { CustomSpinnerModule } from '../../../shared/custom-spinner/custom-spinner.module';
import { CustomTextFieldModule } from '../../../shared/custom-text-field/custom-text-field.module';
import { EvergladesSharedMorule } from '../../../shared/everglades-shared.module';
import { HfCheckboxModule } from '../../../shared/hf-checkbox-component/hf-checkbox.module';
import { ItemsPerPageSelectorModule } from '../../../shared/item-per-page-selector/items-per-page-selector.module';
import { LinkListModule } from '../../../shared/link-list/link-list.module';
import { PaginationSummaryBoxModule } from '../../../shared/pagination-summary-box/pagination-summary-box.module';
import { PaginatorModule } from '../../../shared/paginator/paginator.module';
import { SharedModule } from '../../../shared/shared.module';
import { MORTGAGE_DOCUMENT_TYPE_NAME } from '../../../utils/variables.data';
import { MortgageDashboardGeneralComponent } from './general/mortgage-dashboard-general.component';
import { MortgageDashboardComponent } from './mortgage-dashboard.component';
import { MortgageClientsGetterService } from './services/mortgage-clients-getter.service';
import { MortgageContactAttemptAdderService } from './services/mortgage-contact-attempt-adder.service';
import { MortgageReturnFactFindService } from './services/mortgage-return-fact-find.service';
import { MortgageDashboardImportedComponent } from './imported/mortgage-dashboard-imported.component';
import { NgxsModule } from '@ngxs/store';
import { MortgageImportedDataState } from './imported/state/mortgage-imported-data.state';
import { PercentagePipe } from '../../../../../../projects/client/src/app/shared/pipes/percentage.pipe';
import { MortgagesSharedModule } from '../mortgages-shared.module';
import { InformationBoxModule } from '../../../shared/information-box/information-box.module';

const mortgageAddDocumentServiceAddDocumentFn: AddDocumentServiceAddDocumentFn = (journal, paramMap, documentApiService) => {
  const mortgageId = paramMap.get("id");

  return documentApiService.addMortgageDocument(mortgageId, journal);
};

const declared = [
  MortgageDashboardImportedComponent,
]

const exported = [
  MortgageDashboardComponent,
  MortgageDashboardGeneralComponent,
];

@NgModule({
  declarations: [...declared, ...exported],
  imports: [
    NgxsModule.forFeature([MortgageImportedDataState]),
    CommonModule,
    RouterModule,
    FormsModule,
    CustomButtonModule,
    CustomTextFieldModule,
    ItemsPerPageSelectorModule,
    PaginatorModule,
    PaginationSummaryBoxModule,
    SharedModule,
    MortgagesSharedModule,
    InformationBoxModule,
    SharedLayoutModule,
    EvergladesSharedMorule,
    IfPermissionModule,
    LinkListModule,
    CustomSpinnerModule,
    HfCheckboxModule,

    ClientSharedModule,
    FactFindSharedModule,
  ],
  exports: exported,
  providers: [
    JournalTabService,
    SelectClientService,
    DocumentsTabService,
    PercentagePipe,

    { provide: ADD_DOCUMENT_SERVICE_ADD_DOCUMENT_FN, useValue: mortgageAddDocumentServiceAddDocumentFn },
    AddDocumentService,

    { provide: SELECT_DOCUMENT_TYPE_SERVICE_DOCUMENT_TYPE_NAME, useValue: MORTGAGE_DOCUMENT_TYPE_NAME },
    SelectDocumentTypeService,

    { provide: SELECT_CLIENT_COMPONENT_CLIENTS_GETTER, useClass: MortgageClientsGetterService },
    { provide: LOG_CONTACT_ATTEMPT_COMPONENT_CONTACT_ATTEMPT_ADDER, useClass: MortgageContactAttemptAdderService },
    { provide: RETURN_FACT_FIND_SERVICE, useClass: MortgageReturnFactFindService },
  ]
})
export class MortgageDashboardModule { }
