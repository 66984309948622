<div class="container">
  <div class="header" (click)="isOpen = !isOpen">
    <img *ngIf="iconUrl" [src]="iconUrl">
    {{ heading }}
    <i class="material-icons" [@rotateAnimation]="isOpen">chevron_right</i>
  </div>

  <div *ngIf="isOpen" class="content">
    <ng-content></ng-content>
  </div>
</div>
