import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentApiService } from '../../../services/document-api.service';
import { ToasterService } from '../../../services/toaster.service';
import { fadeAnimation, sidepaneMove } from '../../../shared/animations/sidepane-animation';
import { MORTGAGE_DOCUMENT_TYPE_NAME } from '../../../utils/variables.data';
import { Confirmation, DialogService } from '../../../../../../projects/client/src/app/shared/services/dialog.service';
import { DocumentWithIdDTO } from '../../../models/document';
import { DocumentsTabService } from '../../tabs/documents/documents-tab.service';
import { SelectDocumentTypeService } from '../select-document-type/select-document-type.service';
import { FileControlsConfigBuilder } from '../../../shared/fileuploader/utils/fileuploader.utils';

@Component({
  selector: 'hf-document-request',
  templateUrl: 'document-request.component.html',
  styleUrls: ['document-request.component.scss',
    '../../../styles/sidepanes.partial.scss'],
  animations: [sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove]': 'true' }
})
export class DocumentRequestComponent {

  public data: DocumentWithIdDTO;
  public form = this.fb.group({
    attachment: this.fb.group(
      new FileControlsConfigBuilder()
        .required('Document is required')
        .build()),
  });

  public get documentId() { return this.route.snapshot.paramMap.get("documentId") }
  public isLoading: boolean = true;
  public isSubmitting = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private documentApiService: DocumentApiService,
    private toasterService: ToasterService,
    private dialogService: DialogService,
    private documentsTabService: DocumentsTabService,
    public selectDocumentTypeService: SelectDocumentTypeService
  ) {
    this.init();
  }

  private async init() {
    this.data = await this.documentApiService.viewDocument(this.documentId);

    this.isLoading = false;
  }

  public onBack() {
    this.router.navigate(['./../..'], { relativeTo: this.route });
  }

  public async onSubmit() {
    const document = this.form.get("attachment").value;

    this.isSubmitting = true;

    try {
      await this.documentApiService.acceptRequestedDocument(this.documentId, document);

      this.toasterService.callToaster({ severity: 'info', summary: 'Info', detail: `Document saved` });
      this.documentsTabService.reloadDocumentsTab.emit()
      this.onBack();
    } catch (err) {
      this.toasterService.callToaster({ severity: 'error', summary: 'Error', detail: `${err.error.error.message}` });
    }

    this.isSubmitting = false;
  }

  public async onRemove() {
    const confirmation: Confirmation = {
      title: "Delete Request",
      message: "Deleting this document request will disable the reminders to the client.",
      acceptLabel: "Delete"
    };

    const result = await this.dialogService.confirm(confirmation);

    if (!result) {
      return;
    }

    try {
      await this.documentApiService.cancelDocumentRequest(this.documentId);

      this.toasterService.callToaster({ severity: 'info', summary: 'Info', detail: `Document request deleted` });
      this.documentsTabService.reloadDocumentsTab.emit()
      this.onBack();
    } catch (err) {
      this.toasterService.callToaster({ severity: 'error', summary: 'Error', detail: `${err.error.error.message}` });
    }

    this.onBack();
  }
}
