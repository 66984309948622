import { Component, Input } from '@angular/core';
import { FACT_FIND_STATE } from '../../utils/variables.data';

export const BANNER_FACT_FIND_STATE_NAMES = new Map<FACT_FIND_STATE, string>([
  ["REQUESTED", "Request Sent to Client"],
  ["STARTED", "In Progress by Client"],
  ["COMPLETED", "Waiting for Client Sign-Off"],
  ["SIGNED", "Ready for Review"],
  ["RETURNED", "Returned to Client"],
  ["APPROVED", "Approved"]
])

@Component({
  selector: 'hf-fact-find-status-banner',
  templateUrl: './fact-find-status-banner.component.html',
  styleUrls: ['./fact-find-status-banner.component.scss']
})
export class FactFindStatusBannerComponent {
  @Input()
  public status: FACT_FIND_STATE;

  public BANNER_FACT_FIND_STATE_NAMES = BANNER_FACT_FIND_STATE_NAMES;

  public getIcon(): string {
    switch(this.status) {
      case "APPROVED":
        return "done";

      case "SIGNED":
        return "visibility";

      case "RETURNED":
        return "replay";

      default:
        return "hourglass_empty";
    }
  }
}
