import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToastService } from '../../../../../../../projects/client/src/app/shared/services/toast.service';
import { DivisionSelectorDTO } from '../../../../models/division-selector.dto';
import { FormFieldService } from '../../../../services/form-field.service';
import { IntroducerApiService } from '../../../../services/introducer-api.service';
import { MortgageApiService, UpdateIntroducerDto } from '../../../../services/mortgage-api.service';
import { SelectDivisionService } from '../../../../shared-layout/side-panes/select-division/select-division.service';
import { fadeAnimation, sidepaneMove } from '../../../../shared/animations/sidepane-animation';
import { MortgagesService } from '../../mortgages.service';

@Component({
  selector: 'hf-change-introducer',
  templateUrl: './change-introducer.component.html',
  styleUrls: ['./change-introducer.component.scss',
    '../../../../styles/sidepanes.partial.scss'],
  animations: [sidepaneMove, fadeAnimation],
  providers: [
    { provide: SelectDivisionService, useExisting: ChangeIntroducerComponent },
    FormFieldService,
  ],
  host: { '[@sidepaneMove]': 'true' }
})
export class ChangeIntroducerComponent implements OnDestroy {
  public form = this.fb.group({
    introducer: [null, Validators.required],
    division: [null, Validators.required],
    salesAdvisor: [null, Validators.required],
  });

  public isLoading = false;
  public isSubmitting = false;

  public get value() { return this.form.value }
  public get introducerId() { return this.value.introducer.id }
  public get divisionId() { return this.value.division.id }
  public get mortgageId() { return this.route.snapshot.paramMap.get("id") }

  public control(name: string) { return this.form.get(name) }

  private destroy$ = new Subject();

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private introducerApiService: IntroducerApiService,
    private formFieldService: FormFieldService,
    private mortgageApiService: MortgageApiService,
    private toast: ToastService,
    public mortgagesService: MortgagesService,
  ) {
    this.mortgagesService.initForm();

    this.mortgagesService.mortgageForm.get("introducer").valueChanges.pipe(takeUntil(this.destroy$)).subscribe(introducer => this.control("introducer").patchValue(introducer));
    this.mortgagesService.mortgageForm.get("salesAdvisor").valueChanges.pipe(takeUntil(this.destroy$)).subscribe(salesAdvisor => this.control("salesAdvisor").patchValue(salesAdvisor));
}
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.mortgagesService.mortgageForm.reset();
  }

  public onSubmit() {
    if (!this.formFieldService.checkFormValidity(this.form)) return;

    this.isSubmitting = true;

    const value: UpdateIntroducerDto = { 
      introducerId: this.value.introducer.id,
      divisionId: this.value.division.id,
      salesAdvisorId: this.value.salesAdvisor.id,
      mortgageId: this.mortgageId 
    };

    this.mortgageApiService.updateIntroducer(value).subscribe(
      () => {
        this.toast.add("Introducer changed successfully");
        this.mortgagesService.reloadMortgageDashboard.next();
        this.isSubmitting = false;
        this.goBack();
      },
      e => {
        this.toast.add("Failed", "error", e);
        this.isSubmitting = false;
      }
    );
  }

  public goBack() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

  async getDivisions(): Promise<DivisionSelectorDTO[]> {
    const divisionsResult = await this.introducerApiService.getIntroducerDivisions(this.introducerId);

    return divisionsResult.items;
  }

  selectDivision(division: DivisionSelectorDTO) {
    this.form.get("division").patchValue(division);
  }
}
