import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MortgageDashboardApplication, MortgageDashboard} from '../../../models/mortgage-dashboard';
import {APPLICATION_STATE_NAMES} from '../../../utils/variables.data';

@Component({
  selector: 'hf-application-list',
  templateUrl: './application-list.component.html',
  styleUrls: ['./application-list.component.scss']
})
export class ApplicationListComponent {
  @Input()
  public mortgage: MortgageDashboard;

  @Output()
  public statusClick = new EventEmitter<MortgageDashboardApplication>();

  @Output()
  public createClick = new EventEmitter();

  public APPLICATION_STATE_NAMES = APPLICATION_STATE_NAMES;
  public dateFormat = "d MMM y";

  public getCaseProgressionTooltip(application: MortgageDashboardApplication) {
    return `Case progression:<br>${ application.caseProgression }`;
  }
}
