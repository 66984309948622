import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ReviewSectionState } from '../../../../../../shared/components/review-section/review-section.component';
import { EMPLOYMENT_STATUS_NAME, INCOME_TYPE_NAME } from '../../../../../enums.module';
import { ApplicantData } from '../../../../../services/fact-find-api.service';
import { getApplicantName } from '../get-applicant-name.function';

@Component({
  selector: 'hfc-income-review-section',
  templateUrl: './income-review-section.component.html',
  styleUrls: ['./income-review-section.component.scss']
})
export class IncomeReviewSectionComponent {
  public EMPLOYMENT_STATUS_NAME = EMPLOYMENT_STATUS_NAME;
  public INCOME_TYPE_NAME = INCOME_TYPE_NAME;

  @Input()
  public applicantData: ApplicantData;

  @Input()
  public config: ReviewSectionState;

  @Output()
  public buttonClick = new EventEmitter<ReviewSectionState>();

  public getApplicantName = getApplicantName;
}
