import { Component, Inject, InjectionToken } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Subject } from 'rxjs';

import { ToastService } from '../../../../../../projects/client/src/app/shared/services/toast.service';
import { fadeAnimation, sidepaneMove } from '../../../shared/animations/sidepane-animation';

export interface EditReferenceService {
  changeReference(paramMap: ParamMap, reference: string): Promise<void>;

  referenceChange$: Subject<void>;

  heading: string;
  reference: string;
  regex?: string;
  backPath?: string;
}

export const EDIT_REFERENCE_SERVICE = new InjectionToken<EditReferenceService>("EDIT_REFERENCE_SERVICE");

@Component({
  selector: 'hf-edit-reference',
  templateUrl: './edit-reference.component.html',
  styleUrls: [
    './edit-reference.component.scss',
    '../../../styles/sidepanes.partial.scss'
  ],
  animations: [sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove]': 'true' }
})
export class EditReferenceComponent {
  public form = new FormGroup({
    reference: new FormControl(null, Validators.required),
  });
  public isSubmitting = false;

  public get heading() { return this.editReferenceService.heading }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    @Inject(EDIT_REFERENCE_SERVICE) private editReferenceService: EditReferenceService,
    private toastService: ToastService
  ) {
    this.editReferenceService.reference && this.form.get("reference").setValue(this.editReferenceService.reference);
    this.editReferenceService.regex && this.form.get("reference").setValidators([Validators.required, Validators.pattern(this.editReferenceService.regex)]);
  }

  public async onSubmit() {
    this.isSubmitting = true;
    const reference = this.form.get("reference").value;

    try {
      await this.editReferenceService.changeReference(this.route.snapshot.paramMap, reference);

      this.toastService.add("Lender Reference changed.")
      this.editReferenceService.referenceChange$.next();
    } catch (err) {
      this.toastService.add("Failed. Please try again.", "error", err);
    }

    this.isSubmitting = false;
    this.onBack();
  }

  public onBack() {
    const path = this.editReferenceService.backPath || "..";

    this.router.navigate([path], { relativeTo: this.route });
  }
}
