import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { fadeAnimation, sidepaneMove } from '../../../shared/animations/sidepane-animation';
import { INTERESTED_PARTY_TYPE_NAME } from '../../../utils/variables.data';
import { SelectInterestedPartyTypeService } from './select-interested-party-type.service';

@Component({
  selector: 'hf-select-interested-party-type',
  templateUrl: './select-interested-party-type.component.html',
  styleUrls: [
    './select-interested-party-type.component.scss',
    '../../../styles/sidepanes.partial.scss'
  ],
  animations: [sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove]': 'true' }
})
export class SelectInterestedPartyTypeComponent {
  public form = new FormGroup({
    interestedPartyType: new FormControl(null, Validators.required),
  });

  public types = [...INTERESTED_PARTY_TYPE_NAME.keys()];
  public INTERESTED_PARTY_TYPE_NAME = INTERESTED_PARTY_TYPE_NAME;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private selectInterestedPartyTypeService: SelectInterestedPartyTypeService
  ) {
    this.selectInterestedPartyTypeService.initialInterestedPartyType
      && this.form.get("interestedPartyType").setValue(this.selectInterestedPartyTypeService.initialInterestedPartyType);

    this.form.get("interestedPartyType").valueChanges.subscribe(value => {
      this.selectInterestedPartyTypeService.interestedPartyType$.next(value);
      this.onBack();
    })
  }

  public onBack() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }
}
