<div (click)="onBack()" class="overlay" [@fadeAnimation]></div>

<div class="sidepane-content">
  <div class="flex-space-between-layout template-header">
    <h1 *ngIf="!isAddMode">Select Solicitor</h1>
    <h1 *ngIf="isAddMode">Add  Solicitor</h1>
    <hf-custom-button type="button" class="button-plain-icon" icon="close" iconPos="center" (onClick)="onBack()">
    </hf-custom-button>
  </div>

  <div class="template-main">
    <ng-container *ngIf="isLoading">
      <hf-custom-spinner sidepaneLoading="true"></hf-custom-spinner>
    </ng-container>

    <!-- Solicitor Selector -->
    <form *ngIf="!isLoading && !isAddMode" [formGroup]="selectorForm">
      <div class="hf-selector" (click)="onAdd()">
        <div class="hf-selector-container">
          <div class="hf-selector-label-area">
            <i class="material-icons">add_circle</i>
            <div *ngIf="!addForm.value.name" class="title">Add Solicitor</div>
            <div *ngIf="!addForm.value.id && addForm.value.name" class="title">Edit {{ addForm.value.name }}</div>
          </div>
        </div>
      </div>

      <hf-custom-selector *ngIf="items.length"
                          formControlName="solicitor"
                          [items]="items"
                          [compareWith]="compareFn"
                          [filterWith]="filterFn"
                          filterPlaceholder="Search solicitor …">
        <ng-template #itemTemplate let-item>
          <div>
            {{ item.name || item.email }}
          </div>
        </ng-template>
      </hf-custom-selector>
    </form>

    <!-- Add Solicitor -->
    <form *ngIf="isAddMode" [formGroup]="addForm"
          [style.height.%]="100"
          class="flex-col">

      <label>Name
        <hf-custom-text-field type="text"
                              style="margin-top: 8px;"
                              [group]="addForm"
                              inputFormControlName="name"
                              fieldName="name"
                              validateOnBlur>
        </hf-custom-text-field>
      </label>

      <label>Email
        <hf-custom-text-field type="email"
                              style="margin-top: 8px;"
                              [group]="addForm"
                              inputFormControlName="email"
                              fieldName="email"
                              validateOnBlur>
        </hf-custom-text-field>
      </label>

      <div [style.width.%]="100"
            class="flex-space-between-layout phone-line-wrap">
        <div class="flex-phone-prefix">
          <div class="hf-selector">
            <label>Phone</label>
            <div class="hf-selector-container hf-selector-has-value hf-selector-one-line" [routerLink]="['./selectPhonePrefix']">
              <div class="hf-selector-content-area">
                <div class="title">{{addForm.get('phonePrefix').value}}</div>
              </div>
              <i class="material-icons chevron-icon">chevron_right</i>
            </div>
          </div>
        </div>
        <div class="flex-phone">
          <hfc-text-input type="tel" mask="0*" style="margin-bottom: 12px;" formControlName="phone" hfcRemoveLeadingZero></hfc-text-input>
        </div>
      </div>

      <label>Building Name / Number
        <hf-custom-text-field type="text"
                              style="margin-top: 8px;"
                              [group]="addForm.get('address')"
                              inputFormControlName="house"
                              fieldName="house"
                              validateOnBlur>
        </hf-custom-text-field>
      </label>

      <label>Street
        <hf-custom-text-field type="text"
                              style="margin-top: 8px;"
                              [group]="addForm.get('address')"
                              inputFormControlName="street"
                              fieldName="street"
                              validateOnBlur>
        </hf-custom-text-field>
      </label>

      <label>Address Line 2
        <hf-custom-text-field type="text"
                              style="margin-top: 8px;"
                              [group]="addForm.get('address')"
                              inputFormControlName="line2"
                              fieldName="line2"
                              validateOnBlur>
        </hf-custom-text-field>
      </label>

      <label>City
        <hf-custom-text-field type="text"
                              style="margin-top: 8px;"
                              [group]="addForm.get('address')"
                              inputFormControlName="city"
                              fieldName="city"
                              validateOnBlur>
        </hf-custom-text-field>
      </label>

      <label>Country
        <hf-custom-text-field type="text"
                              style="margin-top: 8px;"
                              [group]="addForm.get('address')"
                              inputFormControlName="country"
                              fieldName="country"
                              validateOnBlur>
        </hf-custom-text-field>
      </label>

      <label>Post Code
        <hf-custom-text-field type="text"
                              style="margin-top: 8px;"
                              [group]="addForm.get('address')"
                              inputFormControlName="postcode"
                              fieldName="postcode"
                              validateOnBlur>
        </hf-custom-text-field>
      </label>
    </form>
  </div>

  <div *ngIf="isAddMode" class="template-footer">
    <hf-custom-button type="submit"
                      class="button-primary"
                      label="Add Solicitor"
                      [disabled]="addForm.invalid"
                      [callable]="true"
                      (onClick)="onSubmit()">
    </hf-custom-button>

    <hf-custom-button type="button"
                      class="button-secondary"
                      label="Cancel"
                      (onClick)="onCancel()">
    </hf-custom-button>
  </div>
</div>

<div class="sidepane-child-anchor">
  <router-outlet></router-outlet>
</div>
