import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { APPLICANT_CATEGORY } from '../../../../../../../projects/client/src/app/fact-find/enums.module';
import { ToastService } from '../../../../../../../projects/client/src/app/shared/services/toast.service';
import { NewComplianceHandoverBudgetDTO } from '../../../../models/new-compliance-handover-budget.dto';
import { FormFieldService } from '../../../../services/form-field.service';
import { ApplicantIncome, MortgageApiService, NetMonthlyIncome } from '../../../../services/mortgage-api.service';
import { setControlEnabled } from '../../../../utils/functions/set-control-enabled';
import { MonthlyIncomeService } from '../../monthly-income/monthly-income.service';
import { MortgagesService } from '../../mortgages.service';
import { isAwatingOrApproved, isAwatingOrApprovedNotLegacy } from '../new-compliance-handover-objective/new-compliance-handover-objective.component';
import { NewComplianceHandoverComponent } from '../new-compliance-handover.component';

@Component({
  selector: 'hf-new-compliance-handover-budget',
  templateUrl: './new-compliance-handover-budget.component.html',
  styleUrls: ['./new-compliance-handover-budget.component.scss'],
  providers: [FormFieldService],
})
export class NewComplianceHandoverBudgetComponent implements OnDestroy {
  public form = this.fb.group({
    netMonthlyIncomes: [null, Validators.required],
    incomeCalculationExplanation: [null, Validators.required],
    agreedMonthlyBudget: [null, Validators.required],
    knownIncomeChanges: [false, Validators.required],
    knownIncomeChangesExplanation: [null, Validators.required],
    depositObservedEvidence: [null, Validators.required],
    giftedDeposit: [false, Validators.required],
    adverseCredit: [false, Validators.required],
    applicantsIncomes: [null],
    reasonForChange: [null],
    surplus: [false, Validators.required],
    surplusAmount: [null, Validators.required],
    surplusDetails: [null, Validators.required],
  });

  public get value() { return this.form.value as NewComplianceHandoverBudgetDTO }
  public get mortgageId() { return this.route.snapshot.paramMap.get("id") }
  public get handoverId() { return this.sidepane.handoverId }

  public control(name: string) { return this.form.get(name) }
  public enabled(name: string) { return this.form.get(name).enabled }

  private destroy$ = new Subject();

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private mortgageApi: MortgageApiService,
    private monthlyIncomeService: MonthlyIncomeService,
    private toast: ToastService,
    private sidepane: NewComplianceHandoverComponent,
    private mortgagesService: MortgagesService,
    private formFieldService: FormFieldService,
  ) {
    this.setup();

    if (isAwatingOrApproved(this.sidepane.dto.state)) {
      this.control("reasonForChange").setValidators(Validators.required);
    }

    this.form.patchValue(this.sidepane.dto.budget);
    this.monthlyIncomeService.applicantsIncomes$.next(this.sidepane.dto.applicantsIncomes);
    this.form.get("applicantsIncomes").setValue(this.sidepane.dto.applicantsIncomes);
    this.sidepane.assignForm(this.form);
  }

  public onSubmit(draft: boolean) {
    if (isAwatingOrApprovedNotLegacy(this.sidepane.dto) && !this.formFieldService.checkFormValidity(this.form)) {
      return;
    }

    const api = draft
      ? () => this.mortgageApi.saveComplianceHandoverBudgetDraft(this.mortgageId, this.handoverId, this.value)
      : () => this.mortgageApi.saveComplianceHandoverBudget(this.mortgageId, this.handoverId, this.value);

    this.sidepane.save(this.form, api);
  }

  public onMonthlyIncomeClicked() {
    const netMonthlyIncomes: NetMonthlyIncome[] = this.form.get("netMonthlyIncomes").value;

    this.monthlyIncomeService.netMonthlyIncomes$.next(netMonthlyIncomes);

    this.router.navigate(["monthlyIncome"], { relativeTo: this.route });
  }

  public getApplicantName(applicantId: string) {
    const applicantsIncomes = this.form.get("applicantsIncomes").value as ApplicantIncome[];
    const applicantIncome = applicantsIncomes.find(a => a.applicant.id == applicantId)

    return applicantIncome && applicantIncome.applicant.fullName;
  }

  private setup() {
    this.monthlyIncomeService.netMonthlyIncomes$.pipe(takeUntil(this.destroy$)).subscribe(netMonthlyIncomes => {
      if (netMonthlyIncomes !== this.form.get("netMonthlyIncomes").value) {
        this.form.get("netMonthlyIncomes").setValue(netMonthlyIncomes);
      }
    });

    this.control("knownIncomeChanges").valueChanges.subscribe(knownIncomeChanges => {
      setControlEnabled(this.control("knownIncomeChangesExplanation"), knownIncomeChanges);
    });
    this.control("knownIncomeChanges").updateValueAndValidity();

    this.control("surplus").valueChanges.subscribe(surplus => {
      setControlEnabled(this.control("surplusAmount"), surplus);
      setControlEnabled(this.control("surplusDetails"), surplus);
    });
    this.control("surplus").updateValueAndValidity();

    const categories: APPLICANT_CATEGORY[] = ["BUY_TO_LET", "RESIDENTIAL"];
    const isEvidenceVisible = this.mortgagesService.data.clientCategory === "PURCHASE" || categories.includes(this.sidepane.dto.factFindCategory);
    setControlEnabled(this.control("depositObservedEvidence"), isEvidenceVisible);

    setControlEnabled(this.control("reasonForChange"), isAwatingOrApproved(this.sidepane.dto.state));

    this.sidepane.saveClick$.pipe(takeUntil(this.destroy$)).subscribe(draft => this.onSubmit(draft));
    this.form.statusChanges.subscribe(status => {
      setTimeout(() => this.sidepane.isValid = this.form.valid);
    });
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }
}
