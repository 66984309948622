import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

export interface NotProceedingReasonsDTO {
  items: NotProceedingReasons[];
}

export interface NotProceedingFollowup {
  id: string;
  productType: string;
  reason: string;
  displayName: string;
  followupText: string;
}

export interface NotProceedingReasons {
  id: number;
  productType?: string;
  stageName?: string;
  reason?: string;
  followupText?: string;
  displayName: string;
  notProceedingFollowups?: NotProceedingFollowup[];
}

export interface NotProceedingReasonUpdateData {
  reasonId: number;
  reasonOther?: string;
  declinedReasonId?: number;
  declinedReasonOther?: string;
  notProceedingFollowups: NotProceedingFollowup[];
}
@Injectable({
  providedIn: "root",
})
export class NotProceedingReasonsService {
  public notProceedingReasonSelected$ = new BehaviorSubject<any>(undefined);
  public notProceedingReasonDataUpdated$ =
    new BehaviorSubject<NotProceedingReasonUpdateData>(undefined);

  constructor() {}

  setNotProceedingReasonSelected(reason: string) {
    this.notProceedingReasonSelected$.next(reason);
    if (!reason) {
      this.notProceedingReasonDataUpdated$.next(undefined);
    }
  }
}
