<div class="overlay" (click)="goBack()" [@fadeAnimation]></div>

<div class="sidepane-content">
  <div class="template-header flex-space-between-layout">
    <h1 class="title-area">Change Introducer</h1>

    <hf-custom-button type="button" icon="close" iconPos="center"
                                                 class="button-plain-icon"
                                                 (click)="goBack()">
    </hf-custom-button>
  </div>

  <div class="template-main">
    <hf-custom-spinner *ngIf="isLoading" sidepaneLoading="true"></hf-custom-spinner>

    <form *ngIf="!isLoading" [formGroup]="mortgagesService.mortgageForm">
      <!-- Introducer selector -->

      <div *ifPermission="'MORTGAGE_NEW'" class="introducer">
        <div class="hf-selector" [formField]="mortgagesService.mortgageForm.get('introducer')">
          <label>Introducer</label>

          <div *ngIf="mortgagesService.mortgageForm.get('introducer') !== null && !mortgagesService.mortgageForm.get('introducer.name').value"
             class="hf-selector-container hf-selector-has-value" [class.invalid]="control('introducer').invalid && control('introducer').dirty" 
             [routerLink]="['./selectIntroducer']">
            <div class="hf-selector-label-area">
              <div class="title">Select Introducer</div>
            </div>
            <i class="material-icons chevron-icon">chevron_right</i>
          </div>

          <ng-container *ngIf="mortgagesService.mortgageForm.get('introducer.name').value">
            <div class="hf-selector-container hf-selector-has-value hf-selector-black-color"
                 [routerLink]="['./selectIntroducer']">
              <div class="hf-selector-content-area">
                <div class="title">{{mortgagesService.mortgageForm.get('introducer.name').value}}</div>
                <div class="description">
                  {{mortgagesService.mortgageForm.get('introducer.primaryContactFirstName').value}}&nbsp;
                  {{mortgagesService.mortgageForm.get('introducer.primaryContactLastName').value}}
                </div>
              </div>
              <i class="material-icons chevron-icon">chevron_right</i>
            </div>
          </ng-container>
        </div>
      </div>

      <!-- Division selector -->
      <hf-selector-link *ngIf="value.introducer" label="{{ 'Division' | wording:value.introducer?.type }}"
                        placeholder="Select Division"
                        [value]="value.division?.name"
                        [isInvalid]="control('division').invalid && control('division').dirty"
                        style="display: block; margin-bottom: 12px"
                        routerLink="./selectDivision">
      </hf-selector-link>

      <!-- Sales advisor selector -->
      <hf-selector-link *ngIf="value.division" label="Sales Advisor"
                        placeholder="Select Sales Advisor"
                        [value]="value.salesAdvisor?.fullName"
                        style="display: block; margin-bottom: 12px"
                        [isInvalid]="control('salesAdvisor').invalid && control('salesAdvisor').dirty"
                        [formField]="control('salesAdvisor')"
                        [routerLink]="['./selectSalesAdvisor']" [queryParams]="{introducerId: introducerId, divisionId: divisionId}">
      </hf-selector-link>
    </form>

    <!-- <hfc-debug-form [form]="form"></hfc-debug-form> -->
  </div>

  <div class="template-footer">
    <hf-custom-button type="submit"
                      class="button-primary"
                      label="Change"
                      [isLoading]="isSubmitting"
                      (onClick)="onSubmit()">
    </hf-custom-button>

    <hf-custom-button type="button"
                      label="Cancel"
                      class="button-secondary"
                      (click)="goBack()">
    </hf-custom-button>
  </div>
</div>

<div class="sidepane-child-anchor">
  <router-outlet></router-outlet>
</div>
