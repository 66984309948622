import { EventEmitter, Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class PhonePrefixService {
  public phonePrefixUpdated = new EventEmitter();
  public phonePrefixActiveIndex = 284;

  public initPrefixService() {
    this.phonePrefixActiveIndex = 284;
  }
}
