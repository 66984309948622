import { Data, Route } from "@angular/router";
import { CanAccessGuard } from "../../../app-can-access-guard.routing";
import { UserPermission } from "../../models";
import { MyIntroducerRedirectGuard } from "../introducers/my-introducer-redirect.guard";
import { routes as mortgageRoutes } from "../mortgages/mortgages.routing";
import { routes as taskRoutes } from "../task/task.module";
import { DashboardTemplateComponent } from "./dashboard-template/dashboard-template.component";

interface IntroducerRouteData extends Data {
  name?: string;
  /** Required permission for route access */
  permission?: UserPermission | UserPermission[];
  /** Required permission for route link visibility in the side nav, if not defined `permission` is used */
  menuPermission?: UserPermission | UserPermission[];
  /** Path used to check if route is active in the side nav, if not defined `path` is used */
  activeLinkPath?: string;
}
export interface IntroducerRoute extends Route {
  children?: IntroducerRoute[];
  data?: IntroducerRouteData;
}

export const dashboardRouters: IntroducerRoute[] = [
  {
    path: "",
    component: DashboardTemplateComponent,
    canActivate: [CanAccessGuard],
    canActivateChild: [CanAccessGuard],
    children: [
      {
        path: "profile",
        loadChildren: () => import("app/website/modules/profile/profile.module").then(m => m.ProfileModule),
      },
      {
        path: "my-introducer",
        data: { name: "My Introducer", permission: "MY_INTRODUCER_MENU", activeLinkPath: "introducers" },
        canActivate: [MyIntroducerRedirectGuard],
        children: []
      },
      {
        path: "introducers",
        loadChildren: () => import("app/website/modules/introducers/introducers.module").then(m => m.IntroducersModule),
        data: {
          name: "Introducers", permission: ["INTRODUCERS_MENU", "MY_INTRODUCER_MENU"],
          menuPermission: "INTRODUCERS_MENU",
        },
      },
      {
        path: "mortgages",
        children: mortgageRoutes,
        data: {
          permission: "MORTGAGES_MENU"
        }
        // data: {
        //   name: "Mortgages", permission: "MORTGAGES_MENU", menuChildren: [
        //     { name: "Assigned to Me", path: "assigned-to-me", permission: "MORTGAGES_ASSIGNED_MENU" },
        //     { name: "Not Assigned", path: "not-assigned", permission: "MORTGAGES_NOT_ASSIGNED_MENU" },
        //     { name: "My Referrals", path: "my-referrals", permission: "MORTGAGES_REFERRALS_MENU" },
        //     { name: "Managed by Me", path: "managed-by-me", permission: "MORTGAGES_MANAGED_MENU" },
        //     { name: "Archived", path: "archive", permission: "MORTGAGES_ARCHIVE_MENU" }
        //   ]
        // }
      },
      {
        path: "protection",
        loadChildren: () => import("app/website/modules/protection/protection.module").then(m => m.ProtectionModule),
        data: {
          name: "Protection",
          permission: "PROTECTION_MENU",
          menuChildren: [
            { name: "Assigned to Me", path: "assigned-to-me", permission: "PROTECTION_ASSIGNED_MENU" },
            { name: "Archived", path: "archive", permission: "PROTECTION_ARCHIVE_MENU" },
          ]
        }
      },
      {
        path: "remortgages",
        loadChildren: () => import("app/website/modules/remortgage/remortgage.module").then(m => m.RemortgageModule),
        data: {
          name: "Remortgages",
          permission: "REMORTGAGES_MENU",
        }
      },
      {
        path: "tasks",
        children: taskRoutes,
        data: {
          name: "Tasks",
          permission: "TASKS_MENU",
          title: "%taskType%"
        }
      },
      {
        path: "clients",
        loadChildren: () => import("app/website/modules/clients/clients.module").then(m => m.ClientsModule),
        data: { name: "Clients", permission: "CLIENTS_MENU" }
      },
      {
        path: "reports",
        loadChildren: () => import("app/website/modules/reports/reports.module").then(m => m.ReportsModule),
        data: { name: "Reports", permission: "REPORTS_MENU" }
      },
      {
        path: "users",
        loadChildren: () => import("app/website/modules/user/user.module").then(m => m.UserModule),
        data: { name: "Heron Users", permission: "USERS_MENU" }
      },
      {
        path: "dashboard",
        loadChildren: () => import("app/website/modules/dashboard-charts/dashboard-charts.module").then(m => m.DashboardChartsModule),
        data: { name: "Dashboard", permission: "DASHBOARD_ALL" }
      },
    ]
  }
];
