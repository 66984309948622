import { Component, ElementRef, OnDestroy } from '@angular/core';
import { TableSettingsService } from '../table-utils/table-settings.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'hf-infinite-scroll-trigger',
  templateUrl: './infinite-scroll-trigger.component.html',
  styleUrls: ['./infinite-scroll-trigger.component.scss']
})
export class InfiniteScrollTriggerComponent implements OnDestroy {
  public isLoading = false;
  public initialized = false;

  private intersectionObserver: IntersectionObserver;
  private destroy$ = new Subject()

  constructor(
    private el: ElementRef,
    private tableSettingsService: TableSettingsService,
  ) {
    this.tableSettingsService.noScrollTop = true;
    this.setup();

    this.tableSettingsService.tableParams$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.isLoading = true;
    });

    this.tableSettingsService.data$.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.isLoading = false;
      this.initialized = true;
    })
  }


  private loadMore() {
    if (this.isLoading) {
      return;
    }

    const page = this.tableSettingsService.pageNum;
    const total = this.tableSettingsService.totalPages;

    if (total > page ) {
      this.tableSettingsService.pageRequest(page + 1, true);
    }
  }

  private setup() {
    if (!IntersectionObserver) {
      return;
    }

    const callback: IntersectionObserverCallback = (entries) => entries.forEach(e => e.isIntersecting && this.loadMore());

    this.intersectionObserver = new IntersectionObserver(callback);
    this.intersectionObserver.observe(this.el.nativeElement);
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }
}
