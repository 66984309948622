<label [for]="controlId" hfDragover (drop)="onFileChange($event)">
  <div>
    <hf-file-input-preview *ngFor="let attachment of attachments" [attachment]="attachment" (removeClick)="onRemoveClicked(attachment)"></hf-file-input-preview>

    <div *ngIf="isHintVisible" class="hint">Drag & Drop or click to add attachment</div>
  </div>

  <hf-custom-spinner [sidepaneLoading]="true" *ngIf="isLoading"></hf-custom-spinner>
</label>

<input type="file" [multiple]="multiple" [id]="controlId" (change)="onFileChange($event)" [accept]="allowedFileTypes"/>

<!-- <hfc-field-error>{{errorMessage}}</hfc-field-error> -->
