import {
  Component,
  OnInit,
  Input,
  TemplateRef,
  ContentChild,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  Output, EventEmitter
} from '@angular/core';
import { FormGroup, FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

let nextUniqueId = 0;

interface DecoratedItem<T> {
  item: T;
  isSelected: boolean;
}

@Component({
  selector: 'hf-custom-selector',
  templateUrl: './custom-selector.component.html',
  styleUrls: ['./custom-selector.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: CustomSelectorComponent,
    multi: true
  }]
})
export class CustomSelectorComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {
  @ContentChild("itemTemplate") itemTemplate: TemplateRef<any>;

  @Input() title: string;
  @Input() items: any[];
  @Input() filterPlaceholder: string = "Search …";
  @Input() multi: boolean;
  @Input() lazyFilter: boolean;

  @Input() compareWith: (o1: any, o2: any) => boolean = (o1, o2) => o1 === o2;
  @Input() filterWith: (o: any, term: string) => boolean;

  @Output() itemSelected = new EventEmitter<any>();

  public form = new FormGroup({
    filter: new FormControl('')
  });
  public inputId: string = `${++nextUniqueId}`;
  public decoratedItems: DecoratedItem<any>[];

  private selectedItems: any[] = [];
  private onChange: any;
  private destroy$ = new Subject();

  private buildDecoratedItems() {
    const term: string = this.form.get('filter').value;

    const filterByTerm = item => {
      return term
        ? this.filterWith(item, term)
        : item;
    }
    const decorate = item => ({
      item,
      isSelected: this.selectedItems.some(s => this.compareWith(s, item))
    })

    const sourceItems = (this.lazyFilter && (term || "").length < 3)
      ? []
      : (this.items || []);

    const items = sourceItems
      .filter(filterByTerm)
      .map(decorate);

    this.decoratedItems = items;
  }

  public isItemSelected(item: any): boolean {
    if (typeof this.compareWith !== "function") {
      throw new Error(`Input property [compareWith] of ${CustomSelectorComponent.name} is not a function.`)
    }

    return this.selectedItems.some(i => this.compareWith(i,item));
  }

  onClick(decoratedItem: DecoratedItem<any>) {
    if (this.multi) {
      this.selectedItems = decoratedItem.isSelected
        ? this.selectedItems.filter(i => !this.compareWith(i, decoratedItem.item))
        : [...this.selectedItems, decoratedItem.item];
    } else {
      this.selectedItems = [decoratedItem.item];
    }

    this.buildDecoratedItems();
    this.propagateChange();
  }

  ngOnInit() {
    this.form.get("filter").valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.buildDecoratedItems();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ("items" in changes || "filterWith" in changes) {
      this.buildDecoratedItems();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  writeValue(obj: any): void {
    this.selectedItems = obj == null
      ? []
      : (this.multi ? obj : [obj]);

    this.buildDecoratedItems();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void { }
  setDisabledState?(isDisabled: boolean): void { }

  private propagateChange() {
    const value = this.multi
      ? this.selectedItems
      : (this.selectedItems[0]);

    this.itemSelected.emit(value);
    this.onChange && this.onChange(value);
  }
}
