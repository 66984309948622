<div (click)="onBack()" class="overlay" [@fadeAnimation]></div>

<div class="sidepane-content">
  <div *ngIf="!isLoading" class="flex-space-between-layout template-header">
    <h1>
      {{ isEdit ? 'Edit' : 'Add' }}
      Pinned Note
    </h1>
    <hf-custom-button type="button" class="button-plain-icon" icon="close" iconPos="center" (onClick)="onBack()">
    </hf-custom-button>
  </div>

  <div class="template-main">
    <hf-custom-spinner *ngIf="isLoading" sidepaneLoading="true"></hf-custom-spinner>

    <form *ngIf="!isLoading" [formGroup]="form" class="ev-sidepane-form">
      <div class="ev-sidepane-form-section">
        <div class="ev-sidepane-form-content">
          <div class="ev-sidepane-form-column-full-width">
            <div class="ev-form-field">
              <label>Pinned Note</label>
              <hfc-textarea-input [rows]="4" [autoexpand]="true" formControlName="body"></hfc-textarea-input>
            </div>
          </div>
        </div>
      </div>
    </form>

    <!-- <hfc-debug-form [form]="form"></hfc-debug-form> -->
  </div>

  <div *ngIf="!isLoading" class="template-footer button-spacing">
    <hf-custom-button type="submit"
                      class="button-primary"
                      label="Save"
                      [isLoading]="isSubmitting"
                      (onClick)="onSubmit()">
    </hf-custom-button>

    <hf-custom-button type="button"
                      class="button-secondary"
                      label="Cancel"
                      (click)="onBack()">
    </hf-custom-button>

    <hf-custom-button *ngIf="isEdit" type="button"
                      class="button-plain-icon delete-button keep-right"
                      icon="delete"
                      iconPos="center"
                      (onClick)="onDeleteClicked()">
    </hf-custom-button>
  </div>
</div>

