import { Injectable } from "@angular/core";

import { ApiService } from "../services/api.service";
import { NOTIFICATION_PARTY_TYPE } from "../utils/variables.data";

export interface NotificationParty {
  interestedPartyId: string;
  name: string;
  email: string;
  type: NOTIFICATION_PARTY_TYPE;
  applicantId: string;
  applicantUnsubscribed: boolean;
}

export interface InterestedParty {
  email: string;
  firstName: string;
  lastName: string;
  fullName: string;
  phonePrefix: string;
  phone: string;
  type: NOTIFICATION_PARTY_TYPE;
}

@Injectable()
export class InterestedPartyApiService {
  private readonly API_NAME: string = 'interestedParty';

  constructor(private readonly apiService: ApiService) { }

  public async getNotificationsList(mortgageId: string): Promise<NotificationParty[]> {
    const params = { mortgageId };

    const data = await this.apiService
      .get(this.API_NAME, "getNotificationsList", { params })
      .toPromise();

    return data.items;
  }

  public unlinkIntroducer(mortgageId: string): Promise<void> {
    const params = { mortgageId };

    return this.apiService
      .post(this.API_NAME, "unlinkIntroducer", undefined, { params })
      .toPromise();
  }

  public relinkIntroducer(mortgageId: string): Promise<void> {
    const params = { mortgageId };

    return this.apiService
      .post(this.API_NAME, "relinkIntroducer", undefined, { params })
      .toPromise();
  }

  public viewInterestedParty(interestedPartyId: string): Promise<InterestedParty> {
    const params = { interestedPartyId };

    return this.apiService
      .get(this.API_NAME, "viewInterestedParty", { params })
      .toPromise();
  }

  public editInterestedParty(interestedPartyId: string, interestedParty: InterestedParty): Promise<void> {
    const params = { interestedPartyId };

    return this.apiService
      .post(this.API_NAME, "editInterestedParty", interestedParty, { params })
      .toPromise();
  }

  public addInterestedParty(mortgageId: string, interestedParty: InterestedParty): Promise<void> {
    const params = { mortgageId };

    return this.apiService
      .post(this.API_NAME, "addInterestedParty", interestedParty, { params })
      .toPromise();
  }

  public deleteInterestedParty(interestedPartyId: string): Promise<void> {
    const params = { interestedPartyId };

    return this.apiService
      .delete(this.API_NAME, "deleteInterestedParty", { params })
      .toPromise();
  }

  public subapplicantNotifications(mortgageId: string, applicantId: string, value: boolean): Promise<void> {
    const params = { mortgageId, applicantId };

    return this.apiService
      .post(this.API_NAME, "subapplicantNotifications", { value }, { params })
      .toPromise();
  }

  public unsubscribe(token: string): Promise<void> {
    const params = { token };

    return this.apiService
      .post(this.API_NAME, "unsubscribe", undefined, { params })
      .toPromise();
  }
}
