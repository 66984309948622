<div (click)="onBack()" class="overlay"  [@fadeAnimation]></div>

<div class="sidepane-content">
  <div class="flex-space-between-layout template-header">
    <h1>Switch Client Category</h1>
    <hf-custom-button type="button" class="button-plain-icon" icon="close" iconPos="center" (onClick)="onBack()">
    </hf-custom-button>
  </div>

  <div class="template-main">
    <form [formGroup]="form" class="ev-sidepane-form">
      <hf-custom-selector formControlName="clientCategory"
                          [items]="categories">
        <ng-template #itemTemplate let-item>
          <div>
            {{ CLIENT_CATEGORY_NAME.get(item) }}
          </div>
        </ng-template>
      </hf-custom-selector>

    </form>

    <!-- <hfc-debug-form [form]="form"></hfc-debug-form> -->
  </div>

  <div class="template-footer">
    <hfc-button type="primary" [disabled]="form.invalid" [isLoading]="isSubmitting" (click)="onSubmit()">Switch</hfc-button>
    <hfc-button type="secondary" (click)="onBack()">Cancel</hfc-button>
  </div>
</div>
