import { UserSimpleDTO } from "./user-simple.dto"

export type BenefitCategory =
  | "MORTGAGE"
  | "PERSONAL_FAMILY"
  | "INCOME_PROTECTION"

export const BenefitCategoryName = new Map<BenefitCategory, string>([
  ["MORTGAGE", "Mortgage"],
  ["PERSONAL_FAMILY", "Personal / Family"],
  ["INCOME_PROTECTION", "Income Protection"],
])

export type BenefitType =
  | "LEVEL_TERM_ASSURANCE"
  | "DECREASING_TERM_ASSURANCE"
  | "FAMILY_INCOME_BENEFIT"
  | "INCOME_PROTECTION"
  | "BUSINESS_PROTECTION"
  | "WHOLE_OF_LIFE"

export const BenefitTypeName = new Map<BenefitType, string>([
  ["LEVEL_TERM_ASSURANCE", "Level Term Assurance"],
  ["DECREASING_TERM_ASSURANCE", "Decreasing Term Assurance"],
  ["FAMILY_INCOME_BENEFIT", "Family Income Benefit"],
  ["INCOME_PROTECTION", "Income Protection"],
  ["BUSINESS_PROTECTION", "Business Protection"],
  ["WHOLE_OF_LIFE", "Whole Of Life"],
])

export type BusinessProtectionProductType =
  | "LEVEL"
  | "DECREASING"

export const BusinessProtectionProductTypeName = new Map<BusinessProtectionProductType, string>([
  ["LEVEL", "Level"],
  ["DECREASING", "Decreasing"],
])

export type PremiumType =
  | "GUARANTEED"
  | "REVIEWABLE"

export const PremiumTypeName = new Map<PremiumType, string>([
  ["GUARANTEED", "Guaranteed"],
  ["REVIEWABLE", "Reviewable "],
])

export type TermType =
  | "YEARS"
  | "TO_AGE"

export const TermTypeName = new Map<TermType, string>([
  ["YEARS", "Years"],
  ["TO_AGE", "To Age"],
])

export type BenefitBasis =
  | "MAXIMUM"
  | "MONTHLY"

export const BenefitBasisName = new Map<BenefitBasis, string>([
  ["MAXIMUM", "Maximum"],
  ["MONTHLY", "Monthly"],
])

export type DeferredPeriod =
  | "ZERO"
  | "ONE_WEEK"
  | "TWO_WEEKS"
  | "ONE"
  | "TWO"
  | "THREE"
  | "SIX"
  | "TWELVE"
  | "TWENTY_FOUR"

export const DeferredPeriodName = new Map<DeferredPeriod, string>([
  ["ZERO", "0 weeks"],
  ["ONE_WEEK", "1 week"],
  ["TWO_WEEKS", "2 weeks"],
  ["ONE", "1 month"],
  ["TWO", "2 months"],
  ["THREE", "3 months"],
  ["SIX", "4 months"],
  ["TWELVE", "12 months"],
  ["TWENTY_FOUR", "24 months"],
])

export type WaiverOfPremiumFractureCoverOptions =
  | "SINGLE"
  | "NONE"
  | "ALL"
  | "MULTIPLE"

export const WaiverOfPremiumFractureCoverOptionsName = new Map<WaiverOfPremiumFractureCoverOptions, string>([
  ["NONE", "No"],
  ["SINGLE", "Yes"],
  ["ALL", "All"],
  ["MULTIPLE", "Specify"],
])

export type LtaIndexation =
  | "LEVEL"
  | "AWEI"
  | "RPI"
  | "ONE"
  | "TWO"
  | "THREE"
  | "FOUR"
  | "FIVE"
  | "SIX"
  | "SEVEN"
  | "EIGHT"
  | "NINE"
  | "TEN"

export const LtaIndexationName = new Map<LtaIndexation, string>([
  ["LEVEL", "Level"],
  ["AWEI", "AWEI"],
  ["RPI", "RPI"],
  ["ONE", "1%"],
  ["TWO", "2%"],
  ["THREE", "3%"],
  ["FOUR", "4%"],
  ["FIVE", "5%"],
  ["SIX", "6%"],
  ["SEVEN", "7%"],
  ["EIGHT", "8%"],
  ["NINE", "9%"],
  ["TEN", "10%"],
])

export type IpIndexation =
  | "LEVEL"
  | "RPI"
  | "THREE"
  | "FIVE"

export const IpIndexationName	= new Map<IpIndexation, string>([
  ["LEVEL", "Level"],
  ["RPI", "RPI"],
  ["THREE", "3%"],
  ["FIVE", "5%"],
])

export type WolIndexation =
  | "LEVEL"
  | "INCREASING"

export const WolIndexationName = new Map<WolIndexation, string>([
  ["LEVEL", "Level"],
  ["INCREASING", "Increasing"],
])

export type FibIndexation =
 | "LEVEL"
 | "RPI"

export const FibIndexationName = new Map<FibIndexation, string>([
  ["LEVEL", "Level"],
  ["RPI", "RPI"],
])

export interface BenefitDTO {
  id: string;
  coverFor: UserSimpleDTO[];
  category: BenefitCategory;
  type: BenefitType;
  overFiftySelected: boolean;
  businessProtectionProductType: BusinessProtectionProductType;
  relevantLifePlansOnly: boolean;
  premiumType: PremiumType;
  excludeLowStart: boolean;
  termType: TermType;
  term: number;
  includeLimitedPaymentPlans: boolean;
  benefitBasis: BenefitBasis;
  includeDualDeferredPeriods: boolean;
  deferredPeriod: DeferredPeriod;
  lifeCoverAmount: number;
  criticalIllnessCoverAmount: number;
  lifeOrCriticalIllnessCoverAmount: number;
  benefitAmount: number;
  additionalBenefitAmount: number;
  additionalDeferredPeriod: DeferredPeriod;
  totalPermanentDisability: boolean;
  waiverOfPremium: WaiverOfPremiumFractureCoverOptions;
  waiverOfPremiumApplicants: UserSimpleDTO[];
  fractureCover: WaiverOfPremiumFractureCoverOptions;
  fractureCoverApplicants: UserSimpleDTO[];
  indexation: LtaIndexation;
  ipIndexation: IpIndexation;
  wolIndexation: WolIndexation;
  fibIndexation: FibIndexation;
  policyInterestRate: number;
}
