import { Directive, HostBinding, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TableSettingsService } from './table-settings.service';
import { TableSorting } from './table-sorting.model';

@Directive({ selector: '[sortBy]' })
export class TableSortingDirective implements OnInit, OnDestroy {
  @Input() sortBy: string;
  @Input() isDefault: boolean;
  @Input() defaultDirection: TableSorting;

  @HostBinding('style.cursor') cursor = 'pointer';
  @HostBinding('class') arrowClass = '';

  private destroy$ = new Subject();

  constructor(private tableSettingsService: TableSettingsService) { }

  ngOnInit() {
    if (this.isDefault) {
      this.tableSettingsService.setDefaultSortBy(this.sortBy, this.defaultDirection);
    }

    this.tableSettingsService.tableParams$.pipe(takeUntil(this.destroy$)).subscribe(params => {
      if (params.sortBy === this.sortBy) {
        this.arrowClass = params.sortDirection === TableSorting.DESC
          ? 'table-arrow-desc'
          : 'table-arrow-asc';
      } else {
        this.arrowClass = '';
      }
    })
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  @HostListener('click')
  public onHeadingClicked() {
    this.tableSettingsService.sortByRequest(this.sortBy);
  }
}
