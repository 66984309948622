import { Injectable } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { filter } from "rxjs/operators";

@Injectable()
export class SessionStoreService {
  private storage = {};

  constructor(private router: Router) {
    this.router.events.pipe(filter(e => e instanceof NavigationStart)).subscribe((e: NavigationStart) => {
      if (!e.restoredState || !e.restoredState.sessionStoreService) {
        return;
      }
      const sessionStoreService = e.restoredState.sessionStoreService;

      for (const key in sessionStoreService) {
        this.set(key, sessionStoreService[key]);
      }
    });
  }

  public get(keyOrPath: string | string[]) {
    const path = this.buildPath(keyOrPath);
    const data = { ...this.storage[path] };

    return data;
  }

  public set(keyOrPath: string | string[], data?: any) {
    const path = this.buildPath(keyOrPath);

    if (data) {
      this.storage[path] = { ...data };

      return;
    }

    for (const key in this.storage) {
      if (key.startsWith(path)) {
        delete this.storage[key];
      }
    }
  }

  public putToHistoryState(keyOrPath: string | string[]) {
    const data = this.getAll(keyOrPath);

    if (!Object.keys(data).length) {
      return;
    }

    history.replaceState({...history.state, sessionStoreService: data}, "");
  }

  public clear() {
    this.storage = {};
  }

  private getAll(keyOrPath: string | string[]) {
    const path = this.buildPath(keyOrPath);
    const all = {};

    for (const key in this.storage) {
      if (key.startsWith(path)) {
        all[key] = {...this.storage[key]};
      }
    }

    return all;
  }

  private buildPath(keyOrPath: string | string[]) {
    return Array.isArray(keyOrPath)
      ? keyOrPath.join("/")
      : keyOrPath;
  }
}
