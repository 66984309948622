import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { CustomButtonModule } from '../../../shared/custom-button/custom-button.module';
import { CustomDatepickerModule } from '../../../shared/custom-datepicker/custom-datepicker.module';
import { CustomRadioChecksFieldModule } from '../../../shared/custom-radio-checks-field/custom-radio-checks-field.module';
import { CustomSelectContainerModule } from '../../../shared/custom-select-container/custom-select-container.module';
import { CustomTextFieldModule } from '../../../shared/custom-text-field/custom-text-field.module';
import { SharedModule } from '../../../shared/shared.module';
import { SelectDateAndTimeComponent } from './select-date-and-time.component';
import { SelectDateAndTimeService } from "./select-date-and-time.service";

@NgModule({
  declarations: [
    SelectDateAndTimeComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    CustomButtonModule,
    CustomSelectContainerModule,
    CustomRadioChecksFieldModule,
    CustomTextFieldModule,
    CustomDatepickerModule
  ],
  exports: [
    SelectDateAndTimeComponent,
  ], providers: [
    SelectDateAndTimeService
  ]
})
export class SelectDateAndTimeModule {
}
