<hf-custom-spinner *ngIf="isLoading" [darkTheme]="false"></hf-custom-spinner>

<div *ngIf="!isLoading" class="container">
  <div *ifMedia="'desktop'" class="navigation">
    <hf-protection-dashboard-fact-find-navigation [applicants]="data.applicants">
    </hf-protection-dashboard-fact-find-navigation>
  </div>

  <div class="view">
    <div class="header">
      <hf-fact-find-status-banner [status]="data.factFindStatus"></hf-fact-find-status-banner>

      <div class="desktop-buttons">
        <ng-container *ngIf="data.factFindStatus !== 'APPROVED'">
          <hfc-button type="primary" postfixIcon="launch" (click)="onEditClicked()">Edit</hfc-button>
          <hfc-button type="primary" [disabled]="!isApproveButtonEnabled" (click)="onApproveClicked()">Approve</hfc-button>
          <hfc-button type="primary" [disabled]="!isReturnButtonEnabled" (click)="onReturnClicked()">Return</hfc-button>
        </ng-container>

        <hfc-button type="primary" *ngIf="isReactivateButtonEnabled" (click)="onReactivateClicked()">Reactivate</hfc-button>
      </div>
    </div>

    <hf-protection-dashboard-fact-find-applicant
      *ngFor="let applicant of data?.applicants"
      [data]="applicant"
      [factFindStatus]="data.factFindStatus"
      (buttonClick)="onSectionButtonClicked($event, applicant.applicant.id)"
      id="applicant-{{applicant.applicant.id}}"></hf-protection-dashboard-fact-find-applicant>
  </div>
</div>

<div *ngIf="!isLoading" class="buttons-wrapper">
  <div class="buttons">
    <ng-container *ngIf="data.factFindStatus !== 'APPROVED'">
      <hfc-button type="primary" postfixIcon="launch" (click)="onEditClicked()">Edit</hfc-button>
      <hfc-button *ngIf="isApproveButtonEnabled" type="primary" (click)="onApproveClicked()">Approve</hfc-button>
      <hfc-button *ngIf="isReturnButtonEnabled" type="primary" (click)="onReturnClicked()">Return</hfc-button>
    </ng-container>

    <hfc-button type="primary" *ngIf="isReactivateButtonEnabled" (click)="onReactivateClicked()">Reactivate</hfc-button>
  </div>
</div>
