import { Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserSimple } from '../../../models';
import { fadeAnimation, sidepaneMove } from '../../../shared/animations/sidepane-animation';
import { USER_TYPE_NAMES } from '../../../utils/variables.data';
import { SelectUserHeadingType, SelectUserService } from './select-user.service';

@Component({
  selector: 'hf-select-user',
  templateUrl: './select-user.component.html',
  styleUrls: [
    './select-user.component.scss',
    '../../../styles/sidepanes.partial.scss'
  ],
  animations: [sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove]': 'true' }
})
export class SelectUserComponent implements OnDestroy {
  public readonly USER_TYPE_NAMES = USER_TYPE_NAMES;
  public form: FormGroup = new FormGroup({
    user: new FormControl()
  });

  public users: UserSimple[];
  public isLoading = true;
  public multi: boolean;
  public showDetails: boolean;
  public heading: string;

  private destroy$ = new Subject();
  private userSelected: UserSimple[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private selectUserService: SelectUserService
  ) {
    this.initForm();
    this.initModel();
  }

  public compareFn = (user1: UserSimple, user2: UserSimple): boolean => !!(user1 && user2 && user1.id === user2.id);
  public filterFn = (user: UserSimple, filter: string): boolean => user && filter && user.fullName.toLowerCase().includes(filter.toLowerCase());

  public onSelectClicked() {
    this.selectUserService.userSelected$.next(this.userSelected);
    this.onBack();
  }

  public getUserLabel(user: any) {
    if (user.caseProgression) {
      return `${user.fullName} (Case Progression)`;
    }

    if (user.advisor) {
      return `${user.fullName} (Advisor)`;
    }

    return user.fullName;
  }

  public ngOnDestroy() {
    this.selectUserService.heading = SelectUserHeadingType.SELECT_USER;
    this.destroy$.next();
  }

  public onBack() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

  private initForm() {
    if (this.selectUserService.initial) {
      this.form.get('user').setValue(this.selectUserService.initial);
    }

    this.form.get('user').valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(user => {
        if (this.multi) {
          this.userSelected = user;
          return;
        }

        this.selectUserService.userSelected$.next(user);
        this.onBack();
      });
  }

  private async initModel() {
    this.heading = this.selectUserService.heading;
    this.multi = this.selectUserService.multi;
    this.showDetails = this.selectUserService.detailed;
    this.users = await this.selectUserService.users$;
    this.isLoading = false;
  }
}
