import { Component, ElementRef } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { MortgageApiService } from "../../../services/mortgage-api.service";
import { NotProceedingReasons, NotProceedingReasonsService, NotProceedingReasonUpdateData } from "../../../services/not-proceeding-reasons.service";
import { ProtectionApiService } from "../../../services/protection-api.service";

import { fadeAnimation, sidepaneMove } from "../../../shared/animations/sidepane-animation";
import { trimmedMinLength } from "../../../utils/form.validators";
import { setControlEnabled } from "../../../utils/functions/set-control-enabled";

@Component({
  selector: "hf-not-proceeding-reasons-list",
  templateUrl: "./not-proceeding-reasons-list.component.html",
  styleUrls: [
    "./not-proceeding-reasons-list.component.scss",
    "../../../styles/sidepanes.partial.scss",
  ],
  animations: [sidepaneMove, fadeAnimation],
  host: { "[@sidepaneMove]": "true" },
})
export class NotProceedingReasonsListComponent {
  public notProceedingReasons: NotProceedingReasons[];

  public form = new FormGroup({
    inputFormControlName: new FormControl(),
    notProceedingFollowUpReasonControl: new FormControl(),
    notProceedingReasonControl: new FormControl(),
    otherReasonControl: new FormControl(null, [Validators.required, trimmedMinLength(3)]),
    declinedReasonOtherControl: new FormControl(),
  });

  public selectedNotProceedingReason: NotProceedingReasons;
  public selectedNotProceedingFollowUp: NotProceedingReasons;

  public isButtonsVisible = false;

  private destroy$ = new Subject();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private mortgageApiService: MortgageApiService,
    private protectionApiService: ProtectionApiService,
    private elementRef: ElementRef, 
    private notProceedingReasonsService: NotProceedingReasonsService
  ) {
    this.loadNotProceedingReasons();
    setControlEnabled(this.form.get("otherReasonControl"), false);
  }

  public ngOnInit() {
    this.notProceedingReasonsService.notProceedingReasonSelected$
      .pipe(take(1))
      .subscribe((status) => {
        this.form.get("notProceedingReasonControl").setValue(status);
      });

    this.notProceedingReasonsService.notProceedingReasonDataUpdated$
      .subscribe((data) => {
        if (data?.reasonOther) {
         this.form.get("otherReasonControl").enable();
         this.form.get("otherReasonControl").setValue(data.reasonOther);
         this.selectedNotProceedingReason = {
            displayName: "Other",
            id: data.reasonId
         }
         this.isButtonsVisible = true;
        } else {
         this.form.get("otherReasonControl").disable();
        }
      }); 

    // this.form
    //   .get("notProceedingReasonControl")
    //   .valueChanges.pipe(takeUntil(this.destroy$))
    //   .subscribe((value) => {
    //     this.notProceedingReasonsService.notProceedingReasonSelected$.next(value);
    //     setControlEnabled(this.form.get("otherReasonControl"), !!value.reasonOther);
    //   });
  }

  private getUpdateData(item: NotProceedingReasons): NotProceedingReasonUpdateData {
    const data: NotProceedingReasonUpdateData = {
      reasonId: item.id,
      reasonOther: this.form.get("otherReasonControl").enabled ? this.form.get("otherReasonControl").value.trim() : null,
      notProceedingFollowups: item.notProceedingFollowups,
    };

    return data;
  }

  public selectNotProceedingReason(item: NotProceedingReasons) {
    this.selectedNotProceedingReason = item;
    this.notProceedingReasonsService.notProceedingReasonSelected$.next(
      item.displayName
    );
    if (item.reason === "Other") {
      this.form.get("otherReasonControl").enable();
      this.isButtonsVisible = true;

      setTimeout(() => {
        const element = this.elementRef.nativeElement.querySelector('textarea');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      });
      return;
    } else {
      this.form.get("otherReasonControl").disable();
    }

    const data = this.getUpdateData(item);
    this.notProceedingReasonsService.notProceedingReasonDataUpdated$.next(data);
    this.isButtonsVisible = false;
    this.onBack();
  }

  public onSelectClicked() {
    if (this.form.invalid) {
      this.form.get("otherReasonControl").markAsDirty();
      return;
    }

    const data = this.getUpdateData(this.selectedNotProceedingReason);

    this.notProceedingReasonsService.notProceedingReasonDataUpdated$.next(data);
    this.onBack();
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }

  public onBack() {
    this.router.navigate([".."], { relativeTo: this.route });
  }

  private loadNotProceedingReasons() {
    const id = this.route.snapshot.paramMap.get("id");
    const request =
      this.route.snapshot?.data?.name?.toLowerCase() === "protection"
        ? this.protectionApiService.getNotProceedingReasons(id)
        : this.mortgageApiService.getNotProceedingReasons(id);
    request.then((res) => {
      this.notProceedingReasons = res.items;
    });
  }
}
