<div (click)="onBack()" class="overlay"  [@fadeAnimation]></div>

<div class="sidepane-content">
  <div class="flex-space-between-layout template-header">
    <h1>Compliance Handover</h1>

    <!-- <pre> -->
    <!-- isValid: {{ isValid }} -->
    <!-- isSaveVisible: {{ isSaveVisible() }} -->
    <!-- isSaveDraftVisible: {{ isSaveDraftVisible() }} -->
    <!-- </pre> -->

    <hf-custom-button type="button" class="button-plain-icon" icon="close" iconPos="center" (onClick)="onBack()">
    </hf-custom-button>
  </div>

  <div class="body">
    <div class="navigation">
      <hf-sidepane-navigation [items]="sections" [selectedItem]="activeSection" [clickCallback]="clickCallback" (itemClicked)="onSectionClicked($event)" [inputItemTemplate]="inputItemTemplate">
      </hf-sidepane-navigation>

      <ng-template #inputItemTemplate let-item>
        <div style="display: flex; align-items: center;">
          <hf-status-icon type="HandoverSectionState" [status]="item.state"></hf-status-icon>
          {{ item.name }}
        </div>
      </ng-template>
    </div>

    <div class="template-main">
      <ng-container *ngIf="!isLoading && activeSection">
        <hf-new-compliance-handover-objective  *ngIf="activeSection.section === 'CIRCUMSTANCES_AND_OBJECTIVES'"></hf-new-compliance-handover-objective>
        <hf-new-compliance-handover-preference *ngIf="activeSection.section === 'PREFERENCES'"></hf-new-compliance-handover-preference>
        <hf-new-compliance-handover-budget *ngIf="activeSection.section === 'BUDGET_AND_AFFORDABILITY'"></hf-new-compliance-handover-budget>
        <hf-new-compliance-handover-recommended *ngIf="activeSection.section === 'RECOMMENDED_MORTGAGE'"></hf-new-compliance-handover-recommended>
      </ng-container>

      <hf-custom-spinner *ngIf="isLoading" sidepaneLoading="true"></hf-custom-spinner>

      <!-- <form *ngIf="!isLoading" [formGroup]="form" class="ev-sidepane-form"> -->
      <!--   <div class="ev-sidepane-form-section"> -->
      <!--     <div class="ev-sidepane-form-heading">General Notes</div> -->
      <!--     <div class="ev-sidepane-form-content"> -->
      <!--       <div class="ev-sidepane-form-column"> -->
      <!--         <div [hfFormField]="control('currentSituation')" class="ev-form-field"> -->
      <!--           <label>Current Situation</label> -->
      <!--           <hf-textarea [group]="form" [smaller]="true" [inputFormControlName]="'currentSituation'" fieldName="currentSituation"></hf-textarea> -->
      <!--         </div> -->

      <!--         <div [hfFormField]="control('currentMonthlyBudget')" class="ev-form-field"> -->
      <!--           <label>Current Monthly Budget</label> -->
      <!--           <hfc-text-input prefix="£" currency="true" formControlName="currentMonthlyBudget"></hfc-text-input> -->
      <!--         </div> -->
      <!--       </div> -->

      <!--       <div class="ev-sidepane-form-column"> -->
      <!--         <div class="ev-form-field" style="margin-top: 8px;"> -->
      <!--           <hf-checkbox label="The client has existing provisions" formControlName="hasExistingProvisions"></hf-checkbox> -->
      <!--         </div> -->

      <!--         <div *ngIf="isControlEnabled('existingProvisions')" [hfFormField]="control('existingProvisions')" class="ev-form-field"> -->
      <!--           <label>Specify existing provisions</label> -->
      <!--           <hf-textarea [group]="form" [smaller]="true" [inputFormControlName]="'existingProvisions'" fieldName="existingProvisions"></hf-textarea> -->
      <!--         </div> -->
      <!--       </div> -->
      <!--     </div> -->
      <!--   </div> -->

      <!--   <div *ngIf="isControlEnabled('_MORTGAGE')" [hfFormField]="control('_MORTGAGE')" class="ev-sidepane-form-section top-border"> -->
      <!--     <div class="ev-sidepane-form-heading">Mortgage Protection</div> -->
      <!--     <div *ngIf="isSubmitted && control('_MORTGAGE').invalid" class="ev-form-error"> -->
      <!--       Please add all benefit details before submitting. -->
      <!--     </div> -->
      <!--     <div class="ev-sidepane-form-content"> -->
      <!--       <div class="ev-sidepane-form-column-full-width"> -->
      <!--         <div class="ev-form-field info-box-container"> -->
      <!--           <hfc-info-box-container> -->
      <!--             <hfc-info-box *ngFor="let benefit of benefits['MORTGAGE']" [title]="BenefitTypeName.get(benefit.type)" [details]="koala" (click)="onBenefitClicked(benefit)"> -->
      <!--               <ng-template #detailsTemplate> -->
      <!--                 <div class="benefit"> -->
      <!--                   <div>{{benefit.provider.name}} <span class="bullet">•</span> {{getBenefitConverFor(benefit)}}</div> -->
      <!--                   <div class="benefit-state" [ngClass]="benefit.state">{{BenefitHandoverStateName.get(benefit.state)}}</div> -->
      <!--                 </div> -->
      <!--               </ng-template> -->
      <!--             </hfc-info-box> -->
      <!--           </hfc-info-box-container> -->
      <!--         </div> -->
      <!--       </div> -->
      <!--     </div> -->
      <!--   </div> -->

      <!--   <div *ngIf="isControlEnabled('_PERSONAL_FAMILY')" [hfFormField]="control('_PERSONAL_FAMILY')" class="ev-sidepane-form-section top-border"> -->
      <!--     <div class="ev-sidepane-form-heading">Personal / Family Protection</div> -->
      <!--     <div *ngIf="isSubmitted && control('_PERSONAL_FAMILY').invalid" class="ev-form-error"> -->
      <!--       Please add all benefit details before submitting. -->
      <!--     </div> -->
      <!--     <div class="ev-sidepane-form-content"> -->
      <!--       <div class="ev-sidepane-form-column-full-width"> -->
      <!--         <div class="ev-form-field info-box-container"> -->
      <!--           <hfc-info-box-container> -->
      <!--             <hfc-info-box *ngFor="let benefit of benefits['PERSONAL_FAMILY']" [title]="BenefitTypeName.get(benefit.type)" [details]="koala" (click)="onBenefitClicked(benefit)"> -->
      <!--               <ng-template #detailsTemplate> -->
      <!--                 <div class="benefit"> -->
      <!--                   <div>{{benefit.provider.name}} <span class="bullet">•</span> {{getBenefitConverFor(benefit)}}</div> -->
      <!--                   <div class="benefit-state" [ngClass]="benefit.state">{{BenefitHandoverStateName.get(benefit.state)}}</div> -->
      <!--                 </div> -->
      <!--               </ng-template> -->
      <!--             </hfc-info-box> -->
      <!--           </hfc-info-box-container> -->
      <!--         </div> -->
      <!--       </div> -->
      <!--     </div> -->
      <!--   </div> -->

      <!--   <div *ngIf="isControlEnabled('_INCOME_PROTECTION')" [hfFormField]="control('_INCOME_PROTECTION')" class="ev-sidepane-form-section top-border"> -->
      <!--     <div class="ev-sidepane-form-heading">Income Protection</div> -->
      <!--     <div *ngIf="isSubmitted && control('_INCOME_PROTECTION').invalid" class="ev-form-error"> -->
      <!--       Please add all benefit details before submitting. -->
      <!--     </div> -->
      <!--     <div class="ev-sidepane-form-content"> -->
      <!--       <div class="ev-sidepane-form-column-full-width"> -->
      <!--         <div class="ev-form-field info-box-container"> -->
      <!--           <hfc-info-box-container> -->
      <!--             <hfc-info-box *ngFor="let benefit of benefits['INCOME_PROTECTION']" [title]="BenefitTypeName.get(benefit.type)" [details]="koala" (click)="onBenefitClicked(benefit)"> -->
      <!--               <ng-template #detailsTemplate> -->
      <!--                 <div class="benefit"> -->
      <!--                   <div>{{benefit.provider.name}} <span class="bullet">•</span> {{getBenefitConverFor(benefit)}}</div> -->
      <!--                   <div class="benefit-state" [ngClass]="benefit.state">{{BenefitHandoverStateName.get(benefit.state)}}</div> -->
      <!--                 </div> -->
      <!--               </ng-template> -->
      <!--             </hfc-info-box> -->
      <!--           </hfc-info-box-container> -->
      <!--         </div> -->
      <!--       </div> -->
      <!--     </div> -->
      <!--   </div> -->

      <!--   <div class="ev-sidepane-form-section top-border"> -->
      <!--     <div class="ev-sidepane-form-content"> -->
      <!--       <div class="ev-sidepane-form-column"> -->
      <!--         <div class="ev-form-field"> -->
      <!--           <hf-checkbox label="Send Documentation to All Applicants" formControlName="sendDocumentationToApplicants"></hf-checkbox> -->
      <!--         </div> -->
      <!--       </div> -->
      <!--       <div class="ev-sidepane-form-column"> -->
      <!--         <div *ngIf="isControlEnabled('messageToApplicants')" [hfFormField]="control('messageToApplicants')" class="ev-form-field"> -->
      <!--           <label>Message to All Applicants (Optional)</label> -->
      <!--           <hf-textarea [group]="form" [smaller]="true" [inputFormControlName]="'messageToApplicants'" fieldName="messageToApplicants"></hf-textarea> -->
      <!--         </div> -->
      <!--       </div> -->
      <!--     </div> -->
      <!--   </div> -->
      <!-- </form> -->

      <!-- <hfc-debug-form [form]="form"></hfc-debug-form> -->
    </div>
  </div>

  <div class="template-footer">
    <ng-container *ngIf="!isLoading">
      <hfc-button *ngIf="isSaveVisible()" type="primary" [isLoading]="isSaving" (click)="onSaveClicked()">Save</hfc-button>
      <hfc-button *ngIf="isSaveDraftVisible()" type="primary" [isLoading]="isSaving" (click)="onSaveDraftClicked()">Save Draft</hfc-button>
      <hfc-button *ngIf="isToComplianceVisible()" type="secondary" [isLoading]="isSubmitting" (click)="onToComplianceClicked()">To Compliance</hfc-button>
      <hfc-button type="secondary" (click)="onBack()">Cancel</hfc-button>
    </ng-container>
  </div>
</div>

<div class="sidepane-child-anchor">
  <router-outlet></router-outlet>
</div>
