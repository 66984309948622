import { Component, Input } from '@angular/core';
import { FilteringItemDefinition } from '../../classes/filtering-service.class';
import { RemortgageFilteringService } from '../../modules/remortgage/services/remortgage-filtering.service';

@Component({
  selector: 'hf-filtering-badges',
  templateUrl: './filtering-badges.component.html',
  styleUrls: ['./filtering-badges.component.scss']
})
export class FilteringBadgesComponent {
  @Input()
  public filters: FilteringItemDefinition[];

  constructor(
    private remortgageFilteringService: RemortgageFilteringService
  )
  { }

  public onItemClearClicked(item: FilteringItemDefinition) {
    this.remortgageFilteringService.clearFilter(item);
  }

  public onClearAllClicked() {
    this.remortgageFilteringService.clearAll();
  }
}
