import { Component, Inject, InjectionToken, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { fadeAnimation, sidepaneMove } from '../../../shared/animations/sidepane-animation';
import { UserSimple } from '../../../models';
import { SelectClientService } from './select-client.service';


export interface ClientsGetter {
  getClients(id: string): Promise<UserSimple[]>;
}
export const SELECT_CLIENT_COMPONENT_CLIENTS_GETTER = new InjectionToken<ClientsGetter>('SELECT_CLIENT_COMPONENT_CLIENTS_GETTER');


@Component({
  selector: 'hf-select-client',
  templateUrl: './select-client.component.html',
  styleUrls: [
    './select-client.component.scss',
    '../../../styles/sidepanes.partial.scss'
  ],
  animations: [sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove]': 'true' }
})
export class SelectClientComponent implements OnInit, OnDestroy {
  public clients: UserSimple[];

  public form: FormGroup = new FormGroup({
    clientId: new FormControl()
  });

  public compareFn = (user1: UserSimple, user2: UserSimple) =>
    user1 && user2 && user1.id === user2.id
  public filterFn = (user: UserSimple, filter: string) =>
    user && filter && user.fullName.toLowerCase().includes(filter.toLowerCase());

  private destroy$ = new Subject();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private selectClientService: SelectClientService,
    @Inject(SELECT_CLIENT_COMPONENT_CLIENTS_GETTER) private clientsGetter: ClientsGetter
  ) { }

  async ngOnInit() {
    this.clients = await this.clientsGetter.getClients(this.mortgageId);
    this.selectClientService.selectedClient && this.form.get('clientId').setValue(this.selectClientService.selectedClient);
    this.form.get('clientId').valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      this.selectClientService.clientChanged.next(value);
      this.selectClientService.selectedClient = value;
      this.onBack();
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  public get mortgageId(): string { return this.route.snapshot.params['id']; }

  public onBack() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }
}
