import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { IntroducerApiService } from "../../../services/introducer-api.service";
import { fadeAnimation, sidepaneMove } from "../../../shared/animations/sidepane-animation";
import { MortgagesService } from "../mortgages.service";

@Component({
  selector: 'hf-select-division',
  templateUrl: './select-division.component.html',
  styleUrls: ['./select-division.component.scss',
    '../../../styles/sidepanes.partial.scss'],
  animations: [sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove]': 'true' }
})
export class SelectDivisionComponent implements OnInit {
  public isLoading: boolean = true;
  public selectDivisionFormGroup: FormGroup = this.fb.group({});

  private introducerId;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private introducerApiService: IntroducerApiService,
    public mortgagesService: MortgagesService
  ) {
    this.introducerId = this.activatedRoute.snapshot.queryParams.introducerId;
  }

  async ngOnInit() {
    const divisions = this.fb.array([]);
    await this.introducerApiService.getIntroducerDivisions(this.introducerId).then(result => {
      for (const item of result.items) {
        divisions.push(this.fb.group({
          name: [item.name],
          details: [item.details],
          region: [item.region],
          id: [item.id, Validators.required],
          radio: new FormControl(false)
        }));
      }

      this.selectDivisionFormGroup = this.fb.group({
        divisions: divisions,
        selectedItem: new FormControl(this.mapItems(divisions.value), Validators.required)
      });

      setTimeout(() => {
        if (this.mortgagesService.mortgageForm.get('division.id').value !== '') {
          const radioEl = (<HTMLInputElement>document.getElementById('division_' + this.mortgagesService.mortgageForm.get('division.id').value));
          radioEl !== null ? radioEl.checked = true : null;

          if (radioEl !== null) {
            Object.keys((this.selectDivisionFormGroup.controls['divisions'] as FormArray).controls).forEach(index => {
              (this.selectDivisionFormGroup.controls['divisions'] as FormArray).controls[index].value.radio =
                radioEl.id === 'division_' + (this.selectDivisionFormGroup.controls['divisions'] as FormArray).controls[index].value.id;
            });
            this.selectDivisionFormGroup.get('selectedItem').setValue(this.mapItems((this.selectDivisionFormGroup.controls['divisions'] as FormArray).value));
          }
        }
      }, 250);

      this.isLoading = false;
    });
  }

  onBack() {
    this.router.navigate(['./..'], { relativeTo: this.activatedRoute });
  }

  mapItems(items) {
    const selectedItem = items.filter((item) => item.radio).map((item) => item.id);
    return selectedItem.length ? selectedItem : null;
  }

  selectRadio(event) {
    Object.keys((this.selectDivisionFormGroup.controls['divisions'] as FormArray).controls).forEach(index => {
      (this.selectDivisionFormGroup.controls['divisions'] as FormArray).controls[index].value.radio =
        event.srcElement.id === 'division_' + (this.selectDivisionFormGroup.controls['divisions'] as FormArray).controls[index].value.id;
    });
    this.selectDivisionFormGroup.get('selectedItem').setValue(this.mapItems((this.selectDivisionFormGroup.controls['divisions'] as FormArray).value));

    Object.keys((this.selectDivisionFormGroup.controls['divisions'] as FormArray).controls).forEach(index => {
      if (this.selectDivisionFormGroup.get('selectedItem').value[0] === (this.selectDivisionFormGroup.controls['divisions'] as FormArray).controls[index].value.id) {
        this.mortgagesService.mortgageForm.get('division').patchValue(
          (this.selectDivisionFormGroup.controls['divisions'] as FormArray).controls[index].value
        );
      }
    });

    this.router.navigate(['./..'], { relativeTo: this.activatedRoute });
  }
}
