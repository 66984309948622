import { MortgageDashboard } from "../../../models/mortgage-dashboard";
import { PinnedNoteViewDTO } from "../../../models/pinned-note-view.dto";

export namespace Mortgage {
  export class Initialize {
    static readonly type = "[Mortgage] Initialize Mortgage Dashboard";
    constructor(public id: string, public mortgage: MortgageDashboard) { }
  }

  export class MenuAction {
    static readonly type = "[Mortgage Toolbar] Menu Action";
    constructor(public action: string) { }
  }

  export class FactFindRequested {
    static readonly type = "[Sidepane] Fact Find Requested";
  }

  export class SetNotProceeding {
    static readonly type = "[Sidepane] Set Not Proceeding";
  }

  export class UndidNotProceeding {
    static readonly type = "[Sidepane] Undid Not Proceeding";
  }

  export class NoteSaved {
    static readonly type = "[Sidepane] Note Saved";
  }

  export class PinnedNoteSaved {
    static readonly type = "[Sidepane] Pinned Note Saved";
    constructor(public pinnedNote: PinnedNoteViewDTO) { }
  }

  export class CreditReportTriggered {
    static readonly type = "[Sidepane] Credit Report Triggered";
  }

  export class MessageSaved {
    static readonly type = "[Sidepane] Message Saved"
  }

  export class TargetCompletionDateUpdated {
    static readonly type = "[Sidepane] Target Completion Date Updated"
  }

  export class TargetExchangeDateUpdated {
    static readonly type = "[Sidepane] Target Exchange Date Updated"
  }
}

