import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddUserComponent } from './add-user.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomButtonModule } from '../../../shared/custom-button/custom-button.module';
import { RouterModule } from '@angular/router';
import { CustomTextFieldModule } from '../../../shared/custom-text-field/custom-text-field.module';
import { CustomSpinnerModule } from '../../../shared/custom-spinner/custom-spinner.module';
import { IfPermissionModule } from '../../../directives/if-permission/if-permission.module';
import { SharedModule } from '../../../shared/shared.module';
import { SelectUserModule } from '../../../shared-layout/side-panes/select-user/select-user.module';
import { ClientSharedModule } from '../../../../../../projects/client/src/app/shared/client-shared.module';

@NgModule({
  declarations: [AddUserComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    CustomButtonModule,
    CustomTextFieldModule,
    CustomSpinnerModule,
    IfPermissionModule,
    SharedModule,
    ClientSharedModule,
    SelectUserModule,
  ],
  exports: [AddUserComponent]
})
export class AddUserModule { }
