import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { InterestedPartyApiService } from '../../../services/interested-party-api.service';
import { ToasterService } from '../../../services/toaster.service';
import { fadeAnimation, sidepaneMove } from '../../../shared/animations/sidepane-animation';
import { NotificationsService } from '../notifications/notifications.service';
import { ToggleNotificationService } from './toggle-notifications.service';

@Component({
  selector: 'hf-toggle-notifications',
  templateUrl: './toggle-notifications.component.html',
  styleUrls: [
    './toggle-notifications.component.scss',
    '../../../styles/sidepanes.partial.scss'
  ],
  animations: [sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove]': 'true' }
})
export class ToggleNotificationsComponent {
  public form = new FormGroup({
    value: new FormControl(!this.toggleNotificationService.applicantUnsubscribed, Validators.required),
  });

  public isSubmitting = false;

  private get mortgageId() { return this.route.snapshot.paramMap.get("id") }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private interestedPartyApiService: InterestedPartyApiService,
    private notificationsService: NotificationsService,
    private toasterService: ToasterService,
    public toggleNotificationService: ToggleNotificationService,
  ) { }

  public async onSubmit() {
    this.isSubmitting = true;
    const value = this.form.get("value").value;

    try {
      await this.interestedPartyApiService.subapplicantNotifications(this.mortgageId, this.toggleNotificationService.applicantId, value);

      this.notificationsService.refresh$.next();
      this.toasterService.callToaster({ severity: 'info', summary: 'Info', detail: 'Notification settings saved.' });
    } catch (err) {
      this.toasterService.callToaster({ severity: 'error', summary: 'Error', detail: `Failed. Please try again. ${err.error.error.message}` });
    }

    this.isSubmitting = false;
    this.onBack();
  }

  public onBack() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }
}
