<div class="overlay" [routerLink]="['./..']" [@fadeAnimation]></div>

<div class="sidepane-content">
  <div class="template-header flex-space-between-layout">
    <h1 class="title-area">Add Document</h1>
    <hf-custom-button type="button"
                      class="button-plain-icon"
                      icon="close"
                      iconPos="center"
                      [routerLink]="['./..']">
    </hf-custom-button>
  </div>

  <div class="template-main">
      <form [formGroup]="addDocumentForm">
        <hf-selector-link *ngIf="handovers?.length > 0"
          label="Related Application (Optional)"
          placeholder="Select Application"
          [value]="value._handover?.lender"
          (click)="onSelectLender()"
          style="display: block; margin-top: 8px; margin-bottom: 12px">
        </hf-selector-link>

        <div class="hf-selector">
          <label>Upload Document</label>
          <div *ngIf="!addDocumentForm.value?.connectedDocument?.type"
               class="hf-selector-container hf-selector-has-value"
               (click)="selectDocumentType()">
            <div class="hf-selector-label-area">
              <div class="title">Select Document Type</div>
            </div>
            <i class="material-icons chevron-icon">chevron_right</i>
          </div>

          <ng-container *ngIf="addDocumentForm.value?.connectedDocument?.type">
            <div
              class="hf-selector-container hf-selector-has-value hf-selector-black-color"
              [routerLink]="['./selectDocumentType']">
              <div class="hf-selector-label-area">
                <div class="title">{{selectDocumentTypeService.documentTypeName.get(addDocumentForm.value.connectedDocument.type)}}</div>
              </div>
              <i class="material-icons chevron-icon">chevron_right</i>
            </div>
          </ng-container>
        </div>

        <ng-container *ngIf="addDocumentForm.get('connectedDocument.otherTypeLabel').enabled">
          <label>Type</label>

          <hf-custom-text-field
            type="text"
            [group]="addDocumentForm.get('connectedDocument')"
            inputFormControlName="otherTypeLabel"
            fieldName="otherTypeLabel"
            marginElement
            validateOnBlur>
          </hf-custom-text-field>
        </ng-container>

        <div marginElement
             class="flex-space-between-layout"
             [style.width.%]="100">
          <hf-fileuploader controlName="attachment"
                           [group]="addDocumentForm.get('connectedDocument')"
                           [fileControl]="addDocumentForm.get('connectedDocument').value['attachment']">
          </hf-fileuploader>
        </div>

        <label style="margin-top: 8px">Private Notes</label>
        <hf-textarea [group]="addDocumentForm"
                     [inputFormControlName]="'internalNotes'"></hf-textarea>

        <div marginElement
             class="flex-space-between-layout switch-toggle"
             [style.width.%]="100">
          <hf-custom-switch-toggle label="Send to Client"
                                   inputFormControlName="sendToClient"
                                   [checked]="false"
                                   [group]="addDocumentForm.get('connectedDocument')">
          </hf-custom-switch-toggle>
        </div>

        <ng-container *ngIf="addDocumentForm.get('messageToClient').enabled">
          <hf-textarea [group]="addDocumentForm"
                       [inputFormControlName]="'messageToClient'"></hf-textarea>
        </ng-container>

      </form>
  </div>

  <div class="template-footer">
    <hf-custom-button type="submit"
                      class="button-primary"
                      label="Add Document"
                      [disabled]="addDocumentForm.invalid"
                      [isLoading]="isSubmitting"
                      (onClick)="onSubmit()">
    </hf-custom-button>

    <hf-custom-button type="button"
                      class="button-secondary"
                      label="Cancel"
                      [routerLink]="['./..']">
    </hf-custom-button>
  </div>
</div>

<div class="sidepane-child-anchor">
  <router-outlet></router-outlet>
</div>
