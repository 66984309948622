<div (click)="onBack()" class="overlay" [@fadeAnimation]></div>

<div class="sidepane-content">
  <div class="flex-space-between-layout template-header">
    <h1>{{taskId ? 'Edit Task' : 'Add Task'}}</h1>
    <hf-custom-button type="button" class="button-plain-icon" icon="close" iconPos="center" (onClick)="onBack()">
    </hf-custom-button>
  </div>

  <div class="template-main">
    <hf-custom-spinner *ngIf="isLoading" sidepaneLoading="true"></hf-custom-spinner>

    <form *ngIf="!isLoading" [formGroup]="form">
      <hf-selector-link label="Assignee"
                        placeholder="Select Assignee"
                        [value]="form.get('assignee').value && form.get('assignee').value.fullName"
                        style="display: block; margin-bottom: 12px"
                        [hfFormField]="control('assignee')"
                        [isInvalid]="control('assignee').invalid && isSubmitted"
                        (click)="onSelectAssigneeClicked()">
      </hf-selector-link>

      <hf-selector-link *ngIf="control('_handover').enabled && handovers?.length > 0"
        label="Related Application (Optional)"
        placeholder="Select Application"
        [value]="value._handover?.lender"
        (click)="onSelectLender()"
        style="display: block; margin-top: 8px; margin-bottom: 12px">
      </hf-selector-link>

        <div class="ev-form-field" style="margin-top: 4px">
          <label>Summary</label>
          <hfc-text-input formControlName="summary" [hfFormField]="control('summary')"></hfc-text-input>
        </div>
      <!-- <label style="margin: 8px 0 0 0">Summary</label> -->
      <!-- <hf-custom-text-field class="text-field" [group]="form" type="text" inputFormControlName="summary" fieldName="summary" [hfFormField]="control('summary')"> -->
      <!-- </hf-custom-text-field> -->

      <div class="ev-form-field">
        <label>Description</label>
        <hfc-textarea-input formControlName="description" [rows]="7" [hfFormField]="control('description')"></hfc-textarea-input>
      </div>
      <!-- <label style="margin-top: 8px">Description</label> -->
      <!-- <hf-textarea [group]="form" inputFormControlName="description" fieldName="description" [hfFormField]="control('description')"> -->
      <!-- </hf-textarea> -->

      <label style="margin-top: 8px">Due Date</label>
      <hf-date-picker formControlName="dueDate" 
                      propertyName="dueDate" 
                      [onlyDate]="true" 
                      class="desktop-date-time" 
                      [isInvalid]="control('dueDate').invalid && isSubmitted"
                      [hfFormField]="control('dueDate')">
      </hf-date-picker>
      <div class="flex-space-between-layout native-mobile-date-time">
        <hf-custom-native-mobile-datetime formControlName="dueDate" onlyDate="true" [invalid]="form.get('dueDate').errors?.minDate" class="native-mobile-date-time">
        </hf-custom-native-mobile-datetime>
      </div>

    </form>

    <!-- <hfc-debug-form [form]="form"></hfc-debug-form> -->
  </div>

  <div class="template-footer">
    <hf-custom-button type="submit"
                      class="button-primary"
                      [label]="taskId ? 'Edit Task' : 'Add Task'"
                      [isLoading]="isSubmitting"
                      (onClick)="onSubmitClicked()">
    </hf-custom-button>

    <hf-custom-button type="button"
                      class="button-secondary"
                      label="Cancel"
                      [routerLink]="['..']">
    </hf-custom-button>
  </div>
</div>

<div class="sidepane-child-anchor">
  <router-outlet></router-outlet>
</div>
