import { Component, Input } from '@angular/core';
import { OUTGOINGS_CATEGORY_NAME } from '../../../../../enums.module';
import { Expense, FactFindData } from '../../../../../services/fact-find-api.service';

@Component({
  selector: 'hfc-total-outgoings-review-section',
  templateUrl: './total-outgoings-review-section.component.html',
  styleUrls: ['./total-outgoings-review-section.component.scss']
})
export class TotalOutgoingsReviewSectionComponent {
  public OUTGOINGS_CATEGORY_NAME = OUTGOINGS_CATEGORY_NAME;

  public totalOutgoings: Expense[];
  public grandTotal = 0;

  @Input()
  public set data(value: FactFindData) { this._data = value; this.totalOutgoings = this.getTotalOutgoings(); this.grandTotal = this.totalOutgoings.reduce((a, to) => a + to.amount, 0) };
  public _data: FactFindData;

  private getTotalOutgoings(): Expense[] {
    if (!this._data) {
      return [];
    }

    const totalByCategory = {};

    this._data.applicantsData
      .map(a => a.outgoings)
      .flat()
      .reduce((total, expense) => {
        if (!(expense.category in total)) {
          total[expense.category] = { ...expense };
        } else {
          total[expense.category].amount += expense.amount;
        }

        return total;
      }, totalByCategory);

    return Object.values(totalByCategory);
  }
}
