import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';

import { ClientSharedModule } from '../../../../../../projects/client/src/app/shared/client-shared.module';
import { CustomButtonModule } from '../../../shared/custom-button/custom-button.module';
import { CustomSidenavModule } from '../../../shared/custom-sidenav/custom-sidenav.module';
import { SharedModule } from '../../../shared/shared.module';
import { DashboardTemplateComponent } from './dashboard-template.component';
import { SessionStoreService } from '../../../services/session-store.service';

@NgModule({
  declarations: [
    DashboardTemplateComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ButtonModule,
    CustomSidenavModule,
    CustomButtonModule,
    SharedModule,
    ClientSharedModule,
  ],
  exports: [
    DashboardTemplateComponent,
  ],
  providers: [SessionStoreService]
})
export class DashboardTemplateModule {
}
