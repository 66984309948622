import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'hf-custom-button',
  templateUrl: './custom-button.component.html',
  styleUrls: ['./custom-button.component.scss']
})
export class CustomButtonComponent implements OnInit, OnChanges {
  @Input() public type: string;

  @Input() public icon?: string;

  @Input() public textPos: string = 'center';

  @Input() public iconPos?: string;

  @Input() public label?: string;

  @Input() public style?: any;

  @Input() public class?: string;

  @Input() public darkTheme?: boolean = true;

  @Input() public sm?: boolean = false;

  @Input() public disabled?: boolean = false;

  @Input() public callable?: boolean = false;

  @Input() public callableApi: Promise<any>;

  @Output() public onFocus = new EventEmitter();

  @Output() public onBlur = new EventEmitter();

  @Output() public onClick = new EventEmitter();

  @Input()
  public isLoading: boolean = false;

  public isClicked: boolean = false;

  constructor(public el: ElementRef) {
  }

  ngOnInit() {
  }

  ngOnChanges(change) {
    if (change.callableApi) {
      const {currentValue} = change.callableApi;
      if (currentValue && currentValue.ok === false && this.isLoading) {
        this.allowButton(false);
      } else if (this.isLoading) {
        this.allowButton(true);
      }

    }

  }

  onButtonClick(e) {
    e.preventDefault();

    if (this.isLoading) {
      return;
    }

    if (this.callable) {

      if (!this.isLoading && !this.isClicked && !this.disabled) {
        this.isLoading = !this.isLoading;
        this.onClick.emit(e);
        this.disabled = !this.disabled;
        this.isClicked = true;
      }

    } else {
      this.onClick.emit(e);
    }

  }

  private allowButton(success: boolean) {
    this.isLoading = false;
    this.isClicked = false;
    this.disabled = !success ? this.disabled : !this.disabled;
  }
}
