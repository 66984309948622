<div class="overlay" (click)="onBack()" [@fadeAnimation]></div>

<div class="sidepane-content">
  <div class="template-header flex-space-between-layout">
    <h1>Select {{ 'Division' | wording:introducerType }}</h1>

    <hf-custom-button type="button"
                      icon="close"
                      iconPos="center"
                      class="button-plain-icon"
                      (onClick)="onBack()">
    </hf-custom-button>
  </div>

  <div class="template-main">
    <ng-container *ngIf="isLoading">
      <hf-custom-spinner sidepaneLoading="true"></hf-custom-spinner>
    </ng-container>

    <ng-container *ngIf="!isLoading">
      <form *ngIf="!isLoading" [formGroup]="form">
        <hf-custom-selector formControlName="division"
                            [items]="divisions"
                            [filterWith]="filterFn"
                            [compareWith]="compareFn">
          <ng-template #itemTemplate let-item>
            <div>
              {{ item.name }}
              <div class="division-details">
                <span class="region"> {{ item.region }} </span><br>
                {{ item.details }}
              </div>
            </div>
          </ng-template>
        </hf-custom-selector>
      </form>
    </ng-container>
  </div>

  <!-- <div *ngIf="config.multi" class="template-footer">
    <hf-custom-button type="submit" class="button-primary" label="Select" (click)="onSelectClicked()"></hf-custom-button>
    <hf-custom-button type="button" class="button-secondary" label="Cancel" (click)="onBack()"></hf-custom-button>
  </div> -->
</div>

