import { Component, Input } from '@angular/core';
import { environment } from "../../../../environments/environment";
import { TableSettingsService } from '../table-utils/table-settings.service';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'hf-documents-zip-button',
  templateUrl: './documents-zip-button.component.html',
  styleUrls: ['./documents-zip-button.component.scss']
})
export class DocumentsZipButton {
  @Input()
  public params: string;

  public onClicked() {
    const url = `${environment.apiUrl}servlet/documentsZip?` + this.params;

    window.open(url);
  }
}
