<div (click)="onBack()" class="overlay" [@fadeAnimation]></div>

<div class="sidepane-content">
  <div class="flex-space-between-layout template-header">
    <h1>Request Fact Find</h1>
    <hf-custom-button type="button" class="button-plain-icon" icon="close" iconPos="center" (onClick)="onBack()">
    </hf-custom-button>
  </div>

  <div class="template-main">
    <form [formGroup]="form">
      <label>The Fact Find will be requested from:</label>
      <ul class="hf-form-static-value" style="margin-left: 16px;">
        <li *ngFor="let item of requestFrom">{{item}}</li>
      </ul>

      <label>Private Notes</label>
      <hf-textarea [group]="form" inputFormControlName="internalNotes"></hf-textarea>

      <label>Message To Client</label>
      <hf-textarea [group]="form" inputFormControlName="messageToClient"></hf-textarea>
    </form>
  </div>

  <div class="template-footer">
    <hfc-button type="primary" [disabled]="form.invalid" [isLoading]="isSubmitting" (click)="onRequest()">Request Fact Find</hfc-button>
    <hfc-button type="secondary" (click)="onBack()">Cancel</hfc-button>
</div>
