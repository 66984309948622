<div class="container">
  <div *ngFor="let item of items" class="item" (click)="onItemClicked(item)" [class.selected]="selectedItem === item">
    <div class="item_content">
      <ng-container *ngTemplateOutlet="itemTemplate || inputItemTemplate || defaultItemTemplate, context: { $implicit: item }">
      </ng-container>
    </div>
    <i *ngIf="!noChevron" class="material-icons">chevron_right</i>
  </div>
</div>

<ng-template #defaultItemTemplate let-item>{{item}}</ng-template>
