import { Injectable } from '@angular/core';
import { DocumentRequestDTO, DocumentWithIdDTO } from '../../../models/document';
import { DocumentApiService } from '../../../services/document-api.service';
import { RequestDocumentService } from '../../../shared-layout/side-panes/request-document/request-document.component';

@Injectable()
export class MortgageRequestDocumentService extends RequestDocumentService {
  constructor(private documentApiService: DocumentApiService) {
    super();
  }

  requestDocument(documentRequestDTO: DocumentRequestDTO, id: string): Promise<DocumentWithIdDTO> {
    return this.documentApiService.requestMortgageDocument(documentRequestDTO, id);
  }
}
