<div class="top">
  <div class="score">{{ score }}</div>

  <div class="hint">Credit score</div>

  <img src="/assets/external/experian-logo.svg" alt="Experian logo" class="logo">
</div>

<div class="bottom">
  <div class="bar" [class.active]="score >= levels[0]"></div>
  <div class="bar" [class.active]="score >= levels[1]"></div>
  <div class="bar" [class.active]="score >= levels[2]"></div>
  <div class="bar" [class.active]="score >= levels[3]"></div>
  <div class="bar" [class.active]="score >= levels[4]"></div>
</div>
