import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface SelectedDateProperty {
  name: string;
  date: Date;
}

@Injectable()
export class SelectDateAndTimeService {
  /**
   * Emits whenever new date is confirmed in SelectDateAndTimeComponent.
   */
  public dateSelected$ = new Subject<SelectedDateProperty>();

  // TODO: Remove this method and use function from client
  /**
   * Converts Date object into ISO string in local time zone.
   * @param date Date to convert
   * @param stripTime Strip time of the date
   */
  public toLocalDateISO(date: Date, stripTime = true): string {
    const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    stripTime && localDate.setUTCHours(0, 0, 0, 0);

    return localDate.toISOString();
  }
}
