
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ClientSharedModule } from '../../../../../../projects/client/src/app/shared/client-shared.module';
import { CustomButtonModule } from '../../../shared/custom-button/custom-button.module';
import { CustomSelectorModule } from '../../../shared/custom-selector/custom-selector.module';
import { CustomTextFieldModule } from '../../../shared/custom-text-field/custom-text-field.module';
import { SelectDebtConsolidationComponent } from './select-debt-consolidation.component';
import { SelectDebtConsolidationService } from './select-debt-consolidation.service';

@NgModule({
  declarations: [SelectDebtConsolidationComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CustomButtonModule,
    CustomTextFieldModule,
    CustomSelectorModule,
    ClientSharedModule
  ],
  exports: [SelectDebtConsolidationComponent],
  providers: [SelectDebtConsolidationService]
})
export class SelectDebtConsolidationModule { }
