import {NgModule} from "@angular/core";
import {DeleteAdvisorBothComponent} from "./delete-advisor-both.component";
import {CustomButtonModule} from "../../../shared/custom-button/custom-button.module";
import {CommonModule} from "@angular/common";
import {CustomSelectorModule} from "../../../shared/custom-selector/custom-selector.module";
import { CustomSpinnerModule } from '../../../shared/custom-spinner/custom-spinner.module';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CustomButtonModule,
    CommonModule,
    CustomSelectorModule,
    CustomSpinnerModule,
    RouterModule
  ],
  declarations: [
    DeleteAdvisorBothComponent
  ],
  exports: [
    DeleteAdvisorBothComponent
  ]
})

export class DeleteAdvisorBothModule {

}
