import { Injectable } from '@angular/core';
import { Attachment } from '../models/attachment';
import { UserSimpleDTO } from '../models/user-simple.dto';
import { ApiService } from './api.service';

export interface SendEmailDTO {
  subject: string;
  body: string;
  recipients: UserSimpleDTO[];
  attachments: Attachment[];
}

@Injectable({ providedIn: "root" })
export class CommunicationApiService {
  private readonly API_NAME: string = 'communication';

  constructor(private readonly apiService: ApiService) { }

  public sendEmailMortgage(mortgageId: string, email: SendEmailDTO): Promise<void> {
    const params = { mortgageId };

    return this.apiService.post(this.API_NAME, "sendEmailMortgage", email, { params }).toPromise();
  }

  public sendEmailProtection(protectionCaseId: string, email: SendEmailDTO): Promise<void> {
    const params = { protectionCaseId };

    return this.apiService.post(this.API_NAME, "sendEmailProtection", email, { params }).toPromise();
  }
}
