import { Injectable } from "@angular/core";
import { ProtectionFactFindStepStatus } from "../../../../../../projects/client/src/app/protection-fact-find/protection-fact-find.model";
import { ProtectionFactFindDataDTO } from "../../../../../../projects/client/src/app/protection-fact-find/services/protection-fact-find-api.service";
import { Attachment } from "../../../models/attachment";
import { UserSimpleDTO } from "../../../models/user-simple.dto";
import { ApiService } from '../../../services/api.service';
import { FACT_FIND_STATE } from "../../../utils/variables.data";
import { PROTECTION_APPLICATION_STEP_NAME } from "../../../../../../projects/client/src/app/protection-fact-find/protection-fact-find.enum";
import { ReturnSection } from "../../../services/fact-find-api.service";


export interface ProtectionFactFindApplicantRichDataDTO extends ProtectionFactFindDataDTO {
  stepStatuses: ProtectionFactFindStepStatus[];
  signed: Date;
  signature: Attachment;
  textSignature: string;
  signedByHeron: boolean;
  signedBy: UserSimpleDTO;
}

export interface ProtectionFactFindDataAllDTO {
  factFindStatus: FACT_FIND_STATE;
  applicants: ProtectionFactFindApplicantRichDataDTO[];
}

@Injectable()
export class ProtectionFactFindApiService {
  private readonly API_NAME: string = 'protectionFactFind';

  constructor(private apiService: ApiService) { }

  public async getFactFindDataHeron(protectionCaseId: string): Promise<ProtectionFactFindDataAllDTO> {
    const params = { protectionCaseId };

    const data = await this.apiService
      .get(this.API_NAME, "getFactFindDataHeron", { params })
      .toPromise<ProtectionFactFindDataAllDTO>();

    const steps = [...PROTECTION_APPLICATION_STEP_NAME.keys()];
    for (const applicant of data.applicants) {
      if (!applicant.stepStatuses) {
        continue;
      }

      applicant.stepStatuses = applicant.stepStatuses
        .filter(s => s.step !== "TERMS_CONDITIONS")
        .sort((a, b) => steps.indexOf(a.step) - steps.indexOf(b.step));
    }

    return data;
  }

  public async markSectionReturned(protectionCaseId: string, returnSection: ReturnSection): Promise<void> {
    const params = { protectionCaseId };

    returnSection.applicantId || delete returnSection.applicantId;

    return this.apiService
      .post(this.API_NAME, "markSectionReturned", returnSection, { params })
      .toPromise();
  }

  public async undoReturn(protectionCaseId: string, returnSection: ReturnSection): Promise<void> {
    const params = { protectionCaseId };

    returnSection.applicantId || delete returnSection.applicantId;

    return this.apiService
      .post(this.API_NAME, "undoReturn", returnSection, { params })
      .toPromise();
  }

  public async returnFactFind(protectionCaseId: string): Promise<void> {
    const params = { protectionCaseId };

    return this.apiService
      .post(this.API_NAME, "returnFactFind", undefined, { params })
      .toPromise();
  }

  public async approveFactFind(protectionCaseId: string): Promise<void> {
    const params = { protectionCaseId };

    return this.apiService
      .post(this.API_NAME, "approveFactFind", undefined, { params })
      .toPromise();
  }

  public async reactivateFactFind(protectionCaseId: string): Promise<void> {
    const params = { protectionCaseId };

    return this.apiService
      .post(this.API_NAME, "reactivateFactFind", undefined, { params })
      .toPromise();
  }
}
