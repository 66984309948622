import { NgModule } from '@angular/core';
import { AddProtectionComponent } from './add-protection.component';
import { AddProtectionService } from './add-protection.service';
import { ReactiveFormsModule } from '@angular/forms';
import { CustomTextAreaModule } from '../../../shared/custom-textarea/custom-text-area.module';
import { CustomButtonModule } from '../../../shared/custom-button/custom-button.module';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SelectOrAddClientModule } from '../select-or-add-client/select-or-add-client.module';
import { SelectUserModule } from '../select-user/select-user.module';

@NgModule({
  declarations: [
    AddProtectionComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CustomTextAreaModule,
    CustomButtonModule,
    RouterModule,
    SelectOrAddClientModule,
    SelectUserModule
  ],
  providers: [
    AddProtectionService
  ],
  exports: [
    AddProtectionComponent, SelectOrAddClientModule, SelectUserModule
  ]
})
export class AddProtectionModule { }
