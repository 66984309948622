import { AfterViewInit, Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { gsap } from "gsap";

const partWidth = 112;
const circleWidth = 40; 
const fullWidth = 5 * partWidth + 4 * circleWidth;

function getFactor(milestone: number) {
  let factor = 0;

  if (milestone <= 1) {
    factor = partWidth * milestone;
  }

  if (milestone > 1 && milestone <= 3) {
    factor = partWidth * (milestone - 1) / 2;
    factor += partWidth + circleWidth;
  }

  if (milestone > 3 && milestone <= 5) {
    factor = partWidth * (milestone - 3) / 2;
    factor += 2 * partWidth + 2 * circleWidth;
  }

  if (milestone > 5 && milestone <= 9) {
    factor = partWidth * (milestone - 5) / 4;
    factor += 3 * partWidth + 3 * circleWidth;
  }

  if (milestone > 9) {
    factor = partWidth * (milestone - 9) / 3;
    factor += 4 * partWidth + 4 * circleWidth;
  }

  const calculated = fullWidth - factor;
  const perc = calculated / fullWidth * 100;

  return perc;
}

@Component({
  selector: 'hf-milestone-timeline',
  templateUrl: './milestone-timeline.component.svg',
  styleUrls: ['./milestone-timeline.component.scss']
})
export class MilestoneTimelineComponent implements AfterViewInit{
  @Input()
  public milestone: number;

  @Input()
  public crossed: boolean;

  @Input()
  public animated = false;

  constructor(private domSanitize: DomSanitizer) { 
  }

  private animate() {
    if (false) {
      const ease = "elastic";
      const duration = 0.5;

      gsap.from("#circle1", { delay: 0.10, ease, duration, opacity: 0 });
      gsap.from("#circle2", { delay: 0.20, ease, duration, x: -100, opacity: 0 });
      gsap.from("#circle3", { delay: 0.30, ease, duration, x: -100, opacity: 0 });
      gsap.from("#circle4", { delay: 0.40, ease, duration, x: -100, opacity: 0 });
      gsap.from("#circle5", { delay: 0.50, ease, duration, x: -100, opacity: 0 });
      gsap.from("#circle6", { delay: 0.60, ease, duration, x: -100, opacity: 0 });

      gsap.from("polygon[fill*='Gradient']", { duration: 2, ease: "power2.in", opacity: 0 });
    }

    if (true) {
      const ease = "back";
      const duration = 0.5;

      gsap.from("#circle1", { delay: 0.10, ease, duration, scale: 0, transformOrigin:"50% 50%" });
      gsap.from("[id='12']", { delay: 0.10, ease, duration, scale: 0, transformOrigin:"50% 50%" });

      gsap.from("#circle2", { delay: 0.20, ease, duration, scale: 0, transformOrigin:"50% 50%" });
      gsap.from("[id='9']", { delay: 0.20, ease, duration, scale: 0, transformOrigin:"50% 50%" });

      gsap.from("#circle3", { delay: 0.30, ease, duration, scale: 0, transformOrigin:"50% 50%" });
      gsap.from("[id='5']", { delay: 0.30, ease, duration, scale: 0, transformOrigin:"50% 50%" });

      gsap.from("#circle4", { delay: 0.40, ease, duration, scale: 0, transformOrigin:"50% 50%" });
      gsap.from("[id='3']", { delay: 0.40, ease, duration, scale: 0, transformOrigin:"50% 50%" });

      gsap.from("#circle5", { delay: 0.50, ease, duration, scale: 0, transformOrigin:"50% 50%" });
      gsap.from("[id='1']", { delay: 0.50, ease, duration, scale: 0, transformOrigin:"50% 50%" });

      gsap.from("#circle6", { delay: 0.60, ease, duration, scale: 0, transformOrigin:"50% 50%" });
      gsap.from("[id='0']", { delay: 0.60, ease, duration, scale: 0, transformOrigin:"50% 50%" });

      // gsap.from("polygon[fill*='Gradient']", { duration: 2, ease: "power2.in", opacity: 0 });
    }
  }

  public ngAfterViewInit() {
    this.animated && this.animate();
  }

  public isVisible(what: string, milestone?: number) {
    if (what === "circle") {
      return milestone >= this.milestone;
    }

    if (what === "grey") {
      return milestone < this.milestone && !this.crossed;
    }

    if (what === "white_circle") {
      return milestone < this.milestone;
    }

    if (what === "crossed") {
      return milestone < this.milestone && this.crossed;
    }
    
    return true;
  }

  public getClipPathStyle() {
    const factor = getFactor(this.milestone);
    const style = `clip-path: polygon(${factor}% 0%, 100% 0%, 100% 100%, ${factor}% 100%)`;

    return this.domSanitize.bypassSecurityTrustStyle(style);
  }
}
