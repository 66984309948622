import { NgModule } from '@angular/core';
import { PaginationSummaryBoxComponent } from './pagination-summary-box.component';

@NgModule({
  declarations: [
    PaginationSummaryBoxComponent
  ], exports: [
    PaginationSummaryBoxComponent
  ]
})
export class PaginationSummaryBoxModule {
}
