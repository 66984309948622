import { Injectable } from "@angular/core";
import { RemortgageDashboardDTO } from "../../services/remortgage-api.service";
import { Subject } from "rxjs";

@Injectable()
export class RemortgageDashboardService {
  public remortgage: RemortgageDashboardDTO;

  public isLoading = false;
  public refresh$ = new Subject();
  public refreshJournal$ = new Subject();
}
