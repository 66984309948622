<div class="overlay" (click)="onBack()" [@fadeAnimation]></div>

<div class="sidepane-content">
  <div class="flex-space-between-layout template-header">

    <h1>Delete {{userFirstName}} {{userLastName}}</h1>
    <hf-custom-button type="button"
                      class="button-plain-icon"
                      icon="close"
                      iconPos="center"
                      (click)="onBack()">
    </hf-custom-button>
  </div>

  <div class="template-main">
    <form>
      <label>Assign their items to:</label>
      <hf-custom-selector [items]="users" (itemSelected)="selectedUser = $event">
        <ng-template #itemTemplate let-item>
          <div>
            {{item.fullName}}
          </div>
        </ng-template>
      </hf-custom-selector>
    </form>
  </div>

  <div class="template-footer">
    <hf-custom-button type="submit"
                      class="button-primary"
                      label="Delete User"
                      [callable]="true"
                      [callableApi]="submitResp"
                      [disabled]="!selectedUser"
                      (onClick)="submit()">
    </hf-custom-button>

    <hf-custom-button type="button"
                      class="button-secondary"
                      label="Cancel"
                      (click)="onBack()">
    </hf-custom-button>
  </div>
</div>
