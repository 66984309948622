import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CanAccessGuard } from './app-can-access-guard.routing';
import { dashboardRouters } from "./website/modules/dashboard/dashboard-routing.module";
import {AccountComponent} from './website/modules/profile/account/account.component';
import {SelectPhonePrefixComponent} from './website/modules/introducers/select-phone-prefix/select-phone-prefix.component';
import {ChangePasswordComponent} from './website/modules/dashboard/change-password/change-password.component';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('app/website/modules/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'app',
    canActivate: [CanAccessGuard],
    children: dashboardRouters,
  },
  {
    path: "account",
    component: AccountComponent,
    outlet: "settings",
    children: [
      {
        path: "selectPhonePrefix",
        component: SelectPhonePrefixComponent,
      },
    ],
  },
  {
    path: "password",
    component: ChangePasswordComponent,
    outlet: "settings",
  },
  {
    path: "debug",
    loadChildren: () => import("app/website/modules/debug/debug.module").then(m => m.DebugModule),
  },
  {
    path: '**',
    redirectTo: 'login'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always',
      // enableTracing: true
    })
  ],
  exports: [
    RouterModule
  ],
  declarations: []
})

export class AppRoutingModule { }
