import { ConnectedPosition, Overlay, OverlayRef } from "@angular/cdk/overlay";
import { TemplatePortal } from "@angular/cdk/portal";
import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Router } from "@angular/router";

@Component({
  selector: "hf-dropdown",
  templateUrl: "./dropdown.component.html",
  styleUrls: ["./dropdown.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DropdownComponent,
      multi: true,
    },
  ],
})
export class DropdownComponent implements ControlValueAccessor {
  @Input()
  public items: any[];

  @Input() value: any;

  @Output() onItemSelected = new EventEmitter();

  @ContentChild("itemTemplate")
  itemTemplate: TemplateRef<any>;

  @ContentChild("valueTemplate")
  valueTemplate: TemplateRef<any>;

  @ContentChild("drawerBottomTemplate")
  drawerBottomTemplate: TemplateRef<any>;

  @ViewChild("drawer")
  public drawer: TemplateRef<any>;
  public isOpen: boolean = false;

  private destroy$ = new Subject();

  constructor(
    private el: ElementRef,
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef,
    private router: Router
  ) {
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.close();
    });
  }

  public onItemClicked(item: any) {
    this.value = item;
    this.onItemSelected.emit(this.value);

    this.onChangeFn && this.onChangeFn(item);
    this.close();
  }

  public onClicked() {
    this.isOpen ? this.close() : this.open();
  }

  private open() {
    const t = new TemplatePortal(this.drawer, this.viewContainerRef);

    this.overlayRef.attach(t);
    this.isOpen = true;
  }

  private close() {
    this.overlayRef.detach();
    this.isOpen = false;
  }

  private _overlayRef: OverlayRef;
  private get overlayRef() {
    if (this._overlayRef) {
      return this._overlayRef;
    }

    const positions: ConnectedPosition[] = [
      {
        originX: "start",
        originY: "bottom",
        overlayX: "start",
        overlayY: "top",
      },
      {
        originX: "start",
        originY: "top",
        overlayX: "start",
        overlayY: "bottom",
      },
    ];

    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.el)
      .withViewportMargin(20)
      .withPositions(positions);
    const scrollStrategy = this.overlay.scrollStrategies.reposition();
    const hasBackdrop = true;
    const backdropClass = "cdk-overlay-transparent-backdrop";

    this._overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy,
      hasBackdrop,
      backdropClass,
    });

    this._overlayRef
      .backdropClick()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.close());

    return this._overlayRef;
  }

  private onChangeFn: (item: any) => void;

  writeValue(obj: any): void {
    this.value = obj;
  }
  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }
  registerOnTouched(fn: any): void {
    // throw new Error("Method not implemented.");
  }
  setDisabledState?(isDisabled: boolean): void {
    // throw new Error("Method not implemented.");
  }
}
