import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { take, takeUntil } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';

import { sidepaneMove, fadeAnimation } from '../../../shared/animations/sidepane-animation';
import { MortgageApiService } from '../../../services/mortgage-api.service';
import { Lender } from '../../../models/lender';
import { Subject } from 'rxjs';
import { MortgagesService } from '../mortgages.service';
import { SelectLenderService } from './select-lender.service';

@Component({
  selector: 'hf-select-lender',
  templateUrl: './select-lender.component.html',
  styleUrls: [
    './select-lender.component.scss',
    '../../../styles/sidepanes.partial.scss'
  ],
  animations: [sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove]': 'true' }
})
export class SelectLenderComponent implements OnInit, OnDestroy {
  public isLoading = true;
  public lenders: Lender[];
  public form: FormGroup = new FormGroup({
    lender: new FormControl()
  });
  public compareFn = (user1: Lender, user2: Lender): boolean =>
    !!(user1 && user2 && user1.id === user2.id);
  public filterFn = (user: Lender, filter: string): boolean =>
    user && filter && user.name.toLowerCase().includes(filter.toLowerCase())

  private destroy$ = new Subject();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private mortgageApiService: MortgageApiService,
    private mortgagesService: MortgagesService,
    private selectLenderService: SelectLenderService,
  ) { }

  public async ngOnInit() {
    this.mortgagesService.lenders$.pipe(takeUntil(this.destroy$)).subscribe(async lenders => {
      if (lenders) {
        this.lenders = lenders
      } else {
        this.isLoading = true;
        const lenders = await this.mortgageApiService.getAllLenders();
        this.isLoading = false;
        this.mortgagesService.lenders$.next(lenders);
      }
    })
    this.mortgagesService.lenderSelected$.pipe(take(1))
      .subscribe(lender => this.form.get("lender").setValue(lender));
    this.form.get("lender").valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(lender => {
        this.mortgagesService.lenderSelected$.next(lender);
        this.selectLenderService.lenderSelected$.next(lender);
        this.onBack();
      });
    this.isLoading = false;
  }

  public async ngOnDestroy() {
    this.destroy$.next();
  }

  public onBack() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }
}
