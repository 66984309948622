import { Component, ContentChild, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'hf-details-row',
  templateUrl: './details-row.component.html',
  styleUrls: ['./details-row.component.scss']
})
export class DetailsRowComponent {
  @Input()
  public label: string;

  @Input()
  public text: string;

  @Input()
  public additionalText: string;

  @ContentChild("contentTemplate")
  contentTemplate: TemplateRef<any>;
}
