<hf-custom-spinner *ngIf="isLoading" [darkTheme]="false"></hf-custom-spinner>

<div *ngIf="!isLoading" class="container">
  <div *ngIf="reportData" class="export-button">
    <hfc-button type="secondary" postfixIcon="save_alt" (click)="onExportClicked()">Export Report</hfc-button>
  </div>

  <span *ngIf="reportData" class="export-button-desktop" (click)="onExportClicked()">
    Export Report
    <i class="material-icons">save_alt</i>
  </span>

  <div class="switch-and-score">
    <div class="left">
      <hf-dropdown [items]="applicants" [(ngModel)]="applicant" (ngModelChange)="onApplicantChange()">
        <ng-template #valueTemplate let-item>
          {{ item?.fullName }}
        </ng-template>

        <ng-template #itemTemplate let-item let-selected="isSelected">
          <div class="applicant-item" [class.selected]="selected">
            <div class="name">{{ item.fullName }}</div>
            <div class="type">{{ item.isLead ? 'Lead Applicant' : 'Sub Applicant' }}</div>

            <i class="material-icons">done</i>
          </div>
        </ng-template>
      </hf-dropdown>

      <span *ngIf="reportData" class="date">Generated {{ reportData.reportDate | date:'E d MMM y' }}</span>
    </div>

    <hf-credit-score *ngIf="reportData" [score]="reportData.creditScore"></hf-credit-score>
  </div>

  <ng-container *ngIf="reportData">
    <div class="categories">
      <hf-category-status-box *ngFor="let category of categories" [category]="reportData[category[0]]" [categoryName]="category[1]">
      </hf-category-status-box>
    </div>

    <div class="missed-payments">
      <hf-missed-payments-box heading="Total default payments">
        <hf-missed-payments-box-row label="Defaults within 6 months" [value]="reportData.totalDefaultPayments6Months"></hf-missed-payments-box-row>
        <hf-missed-payments-box-row label="Defaults within 12 months" [value]="reportData.totalDefaultPayments12Months"></hf-missed-payments-box-row>
        <hf-missed-payments-box-row label="Defaults within 60 months" [value]="reportData.totalDefaultPayments60Months"></hf-missed-payments-box-row>
        <hf-missed-payments-box-row label="Defaults within 72 months" [value]="reportData.totalDefaultPayments72Months"></hf-missed-payments-box-row>
      </hf-missed-payments-box>

      <hf-missed-payments-box heading="Total missed payments">
        <hf-missed-payments-box-row label="Missed payments within 6 months" [value]="reportData.totalMissedPayments6Months"></hf-missed-payments-box-row>
        <hf-missed-payments-box-row label="Missed payments within 12 months" [value]="reportData.totalMissedPayments12Months"></hf-missed-payments-box-row>
        <hf-missed-payments-box-row label="Missed payments within 60 months" [value]="reportData.totalMissedPayments60Months"></hf-missed-payments-box-row>
        <hf-missed-payments-box-row label="Missed payments within 72 months" [value]="reportData.totalMissedPayments72Months"></hf-missed-payments-box-row>
      </hf-missed-payments-box>

      <hf-missed-payments-box heading="Bankruptcy or IVA detected">
        <hf-missed-payments-box-row label="Bankruptcy or IVA detected" [icon]="reportData.bankruptcyDetected ? 'check' : 'close'"></hf-missed-payments-box-row>
        <hf-missed-payments-box-row label="Outstanding bankruptcy or IVA detected" [icon]="reportData.bankruptcyOutstandingDetected ? 'check' : 'close'"></hf-missed-payments-box-row>
      </hf-missed-payments-box>
    </div>

    <div class="details">
      <hf-expander *ngIf="reportData.mortgages?.accounts?.length" heading="Mortgages">
        <hf-mortgage-category-details [report]="reportData"></hf-mortgage-category-details>
      </hf-expander>

      <hf-expander *ngIf="reportData.creditCards?.accounts?.length" heading="Credit Cards">
        <hf-credit-cards-category-details [report]="reportData"></hf-credit-cards-category-details>
      </hf-expander>

      <hf-expander *ngIf="reportData.loans?.accounts?.length" heading="Loans">
        <hf-loans-category-details [report]="reportData"></hf-loans-category-details>
      </hf-expander>

      <hf-expander *ngIf="reportData.hirePurchases?.accounts?.length" heading="Hire Purchase">
        <hf-hire-purchases-category-details [report]="reportData"></hf-hire-purchases-category-details>
      </hf-expander>

      <hf-expander *ngIf="reportData.currentAccounts?.accounts?.length" heading="Current Accounts">
        <hf-current-accounts-category-details [report]="reportData"></hf-current-accounts-category-details>
      </hf-expander>

      <hf-expander *ngIf="reportData.other?.accounts?.length" heading="Other">
        <hf-other-category-details [report]="reportData"></hf-other-category-details>
      </hf-expander>
    </div>
  </ng-container>

  <div *ngIf="!reportData" class="no-data">
    A credit search has not been performed on this applicant.
    They must be opted in and have signed the Fact Find for a credit report to appear.
  </div>
</div>

