import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {CustomSwitchToggleComponent} from "./custom-switch-toggle.component";

@NgModule({
  declarations: [
    CustomSwitchToggleComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule
  ],
  exports: [
    CustomSwitchToggleComponent
  ],
  providers: []
})

export class CustomSwitchToggleModule {

}
