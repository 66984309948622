<div class="header">
  <div class="heading">
    Credit Liabilities
    <div class="applicant">{{ getApplicantName(applicantData) }}</div>

    <div *ngIf="config?.updatedBy" class="saved-by">
      Saved by {{ config.updatedBy.fullName }} at {{config?.lastUpdated | date:'HH:mm on E d MMM y'}}
    </div>
  </div>

  <hfc-button *ngIf="config.buttonType" [type]="config.buttonType" [isLoading]="config.buttonIsLoading" size="small" (click)="buttonClick.next(config)">{{ config.buttonLabel }}</hfc-button>
</div>

<div *ngIf="config.returnComment" class="returnComment">
  {{config.returnComment}}
  <div class="lastUpdated">{{config.lastUpdated | date:'d MMM y, HH:mm'}}</div>
</div>

<div *ngIf="config.stepStatus == 'NOT_STARTED'" class="notReady">
  Waiting for applicant to complete
</div>

<div *ngIf="config.stepStatus !== 'NOT_STARTED'" class="content">
  <div class="column">
    <div class="record">
      <span class="label">Declared bankrupt</span>
      <span class="value">{{ yesno(applicantData.creditLiabilityDetails?.bankrupt) }}</span>
    </div>
    <div *ngIf="applicantData.creditLiabilityDetails?.bankruptDetails" class="record">
      <span class="label">Details of bankrupcty/credit arrangement</span>
      <span class="value">{{ applicantData.creditLiabilityDetails?.bankruptDetails }}</span>
    </div>

    <div class="record">
      <span class="label">Previous mortgage or rent arrears</span>
      <span class="value">{{ yesno(applicantData.creditLiabilityDetails?.previousArrears) }}</span>
    </div>
    <div *ngIf="applicantData.creditLiabilityDetails?.previousArrearsDetails" class="record">
      <span class="label">Details of arrears</span>
      <span class="value">{{ applicantData.creditLiabilityDetails?.previousArrearsDetails }}</span>
    </div>

    <div class="record">
      <span class="label">Late payments</span>
      <span class="value">{{ yesno(applicantData.creditLiabilityDetails?.latePayments) }}</span>
    </div>
    <div *ngIf="applicantData.creditLiabilityDetails?.latePayments" class="record">
      <span class="label">CCJs/defaults</span>
      <span class="value">{{ yesno(applicantData.creditLiabilityDetails?.ccjDefaults) }}</span>
    </div>
  </div>

  <div class="column">
    <div class="record">
      <span class="label">Overdraft</span>
      <span class="value">{{ yesno(applicantData.creditLiabilityDetails?.overdraft) }}</span>
    </div>
    <div *ngIf="applicantData.creditLiabilityDetails?.overdraftLimit" class="record">
      <span class="label">Overdraft Limit</span>
      <span class="value">{{ applicantData.creditLiabilityDetails?.overdraftLimit | currency:"GBP":undefined:"1.0-0" }}</span>
    </div>
    <div *ngIf="applicantData.creditLiabilityDetails?.overdraftUsed" class="record">
      <span class="label">Overdraft Used</span>
      <span class="value">{{ applicantData.creditLiabilityDetails?.overdraftUsed | currency:"GBP":undefined:"1.0-0" }}</span>
    </div>
    <div *ngIf="applicantData.creditLiabilityDetails?.overdraft" class="record">
      <span class="label">Overdraft Fully Repaid With Mortgage</span>
      <span class="value">{{ yesno(applicantData.creditLiabilityDetails?.overdraftWillFullyRepay) }}</span>
    </div>
  </div>
</div>

<ng-container *ngIf="config.stepStatus !== 'NOT_STARTED' && applicantData.creditLiabilities?.length">
  <div class="header" style="margin-top: 22px">
    <div class="heading">
      Other Credit Commitments
    </div>
  </div>

  <div class="content">
    <ng-container *ngFor="let credit of applicantData.creditLiabilities">
      <div class="header-2">
        {{ CREDIT_TYPE_NAME.get(credit.type) }}
      </div>

      <div class="column">
        <div *ngIf="credit.otherPersonName" class="record">
          <span class="label">Other person in agreement</span>
          <span class="value">{{ credit.otherPersonName }}</span>
        </div>
        <div class="record">
          <span class="label">Lender</span>
          <span class="value">{{ credit.lender }}</span>
        </div>
        <div class="record">
          <span class="label">Current Balance</span>
          <span class="value">{{ credit.currentBalance | currency:"GBP":undefined:"1.0-0" }}</span>
        </div>
        <div class="record">
          <span class="label">Monthly Payment</span>
          <span class="value">{{ credit.monthlyPayment | currency:"GBP":undefined:"1.0-0" }}</span>
        </div>
      </div>

      <div class="column">
        <div class="record">
          <span class="label">Approximate End Date</span>
          <span class="value">{{ credit.approximateEndDate | date:'d MMM y' }}</span>
        </div>
        <div class="record">
          <span class="label">Fully repay in line with new mortgage</span>
          <span class="value">{{ yesno(credit.willFullyRepay) }}</span>
        </div>
        <div *ngIf="credit.willFullyRepay" class="record">
          <span class="label">Repayment</span>
          <span class="value">{{ credit.otherRepayMethod || REPAY_METHOD_NAME.get(credit.repayMethod) }}</span>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
