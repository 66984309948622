import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { UserSimple } from '../../../models';
import { ToasterService } from '../../../services/toaster.service';
import { UserApiService } from '../../../services/user-api.service';
import { fadeAnimation, sidepaneMove } from '../../../shared/animations/sidepane-animation';
import { HeronUserService } from '../user.service';
import { Subject } from 'rxjs';
import { SelectUserHeadingType, SelectUserService } from '../../../shared-layout/side-panes/select-user/select-user.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'hf-delete-advisor-both',
  templateUrl: 'delete-advisor-both.component.html',
  styleUrls: ['delete-advisor-both.component.scss',
    '../../../styles/sidepanes.partial.scss'
  ],
  animations: [sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove]': 'true' }
})

export class DeleteAdvisorBothComponent implements OnInit {

  public userId: string;
  public userFirstName: string;
  public userLastName: string;
  public selectedMortgageAdvisor: UserSimple;
  public selectedDefaultProtectionAdvisor: UserSimple;

  private destroy$ = new Subject();

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private userApiService: UserApiService,
              private heronUserService: HeronUserService,
              private toasterService: ToasterService,
              private selectUserService: SelectUserService) {
    this.selectUserService.userSelected$.pipe(takeUntil(this.destroy$)).subscribe((user: UserSimple) => {
      if (this.selectUserService.heading === SelectUserHeadingType.SELECT_MORTGAGE_ADVISOR) {
        this.selectedMortgageAdvisor = user;
      } else {
        this.selectedDefaultProtectionAdvisor = user;
      }
    });
  }

  public ngOnInit() {
    this.userId = this.activatedRoute.snapshot.params['userId'];
    this.userFirstName = this.activatedRoute.snapshot.queryParams['userFirstName'];
    this.userLastName = this.activatedRoute.snapshot.queryParams['userLastName'];
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }

  public onBack() {
    this.router.navigate(['..'], { relativeTo: this.activatedRoute });
  }

  onSelectMortgageAdvisor() {
    this.selectUserService.users$ = this.userApiService.getMortgageAdvisors(this.userId);
    this.selectUserService.heading = SelectUserHeadingType.SELECT_MORTGAGE_ADVISOR;
    this.router.navigate(["selectUser"], { relativeTo: this.activatedRoute });
  }

  onSelectProtectionAdvisor() {
    this.selectUserService.users$ = this.userApiService.getProtectionAdvisors(this.userId);
    this.selectUserService.heading = SelectUserHeadingType.SELECT_PROTECTION_ADVISOR;
    this.router.navigate(["selectUser"], { relativeTo: this.activatedRoute });
  }

  public submit() {
    if (!this.selectedMortgageAdvisor || !this.selectedDefaultProtectionAdvisor)
      return;

    this.userApiService.removeHeronUser(this.userId, this.selectedMortgageAdvisor.id, this.selectedDefaultProtectionAdvisor.id).then(() => {
      this.heronUserService.reloadUsersDashboard.emit();
      this.toasterService.callToaster({ severity: 'info', summary: 'Info', detail: 'User deleted successfully' });
      this.router.navigate(['./../..'], { relativeTo: this.activatedRoute });
    }).catch(() => {
      this.toasterService.callToaster({ severity: 'error', summary: 'Error', detail: 'A problem occurred deleting this user. Please try again' });
      this.router.navigate(['./../..'], { relativeTo: this.activatedRoute });
    });
  }
}
