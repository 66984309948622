import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ReviewSectionState } from '../../../../../../shared/components/review-section/review-section.component';
import { yesno } from '../../../../../../shared/functions/yesno';
import { CREDIT_TYPE_NAME, REPAY_METHOD_NAME } from '../../../../../enums.module';
import { ApplicantData } from '../../../../../services/fact-find-api.service';
import { getApplicantName } from '../get-applicant-name.function';

@Component({
  selector: 'hfc-credit-review-section',
  templateUrl: './credit-review-section.component.html',
  styleUrls: ['./credit-review-section.component.scss']
})
export class CreditReviewSectionComponent {
  @Input()
  public applicantData: ApplicantData;

  @Input()
  public config: ReviewSectionState;

  @Output()
  public buttonClick = new EventEmitter<ReviewSectionState>();

  public CREDIT_TYPE_NAME = CREDIT_TYPE_NAME;
  public REPAY_METHOD_NAME = REPAY_METHOD_NAME;

  public getApplicantName = getApplicantName;
  public yesno = yesno;
}
