<div (click)="onBack()" class="overlay" [@fadeAnimation]></div>

<div class="sidepane-content">
  <div class="flex-space-between-layout template-header">
    <h1>{{this.userId ? 'Edit' : 'Add'}} User</h1>
    <hf-custom-button type="button" class="button-plain-icon" icon="close" iconPos="center" (onClick)="onBack()">
    </hf-custom-button>
  </div>

  <div class="template-main">
    <ng-container *ngIf="isLoading">
      <hf-custom-spinner sidepaneLoading="true"></hf-custom-spinner>
    </ng-container>
    <ng-container *ngIf="!isLoading">
      <form [formGroup]="form">
        <label [style.width.%]="100">
          First Name
          <hf-custom-text-field class="text-field" [group]="form" type="text" [capitalize]="true" inputFormControlName="firstName" fieldName="firstName"
            validateOnBlur marginElement>
          </hf-custom-text-field>
        </label>

        <label [style.width.%]="100">
          Last Name
          <hf-custom-text-field class="text-field" [group]="form" type="text" [capitalize]="true" inputFormControlName="lastName" fieldName="lastName"
            validateOnBlur marginElement>
          </hf-custom-text-field>
        </label>

        <label [style.width.%]="100">
          Email Address
          <hf-custom-text-field class="text-field" [group]="form" type="email" inputFormControlName="email" fieldName="email"
            validateOnBlur marginElement>
          </hf-custom-text-field>
        </label>

        <div [style.width.%]="100" class="flex-space-between-layout phone-line-wrap">
          <div class="flex-phone-prefix">
            <div class="hf-selector">
              <label>Phone</label>
              <div class="hf-selector-container hf-selector-has-value hf-selector-one-line"
                [routerLink]="['./selectPhonePrefix']">
                <div class="hf-selector-content-area">
                  <div class="title">{{form?.get('phonePrefix')?.value}}</div>
                </div>
                <i class="material-icons chevron-icon">chevron_right</i>
              </div>
            </div>
          </div>

          <div class="flex-phone">
            <hfc-text-input type="tel" mask="0*" style="margin-bottom: 12px;" formControlName="phone" hfcRemoveLeadingZero></hfc-text-input>
          </div>
        </div>

        <div style="margin: 8px 0;">
          <label>
            Profile Photo
          </label>
          <div class="flex-space-between-layout"
               [style.width.%]="100">
            <hf-fileuploader controlName="photo"
                             [group]="form"
                             [fileControl]="form.value['photo']"
                             [allowedFileTypes]="ALLOWED_FILE_TYPES"
                             [makeSquare]="true">
            </hf-fileuploader>
          </div>
        </div>

        <!-- User type selector -->
        <div *ngIf="isAdminOrLineManager()" class="hf-selector">
          <label>User Type</label>
          <div class="hf-selector-container hf-selector-has-value" [class.hf-selector-black-color]="!!form.get('userType').value"
            [routerLink]="['./selectUserType']" [queryParams]="{ initialValue: form.get('userType').value }">
            <div class="hf-selector-label-area">
              <div *ngIf="form.get('userType').value" class="title">{{USER_TYPE_NAMES.get(form.get('userType').value)}}</div>
              <div *ngIf="!form.get('userType').value" class="title">Select Type</div>
            </div>
            <i class="material-icons chevron-icon">chevron_right</i>
          </div>
        </div>

        <!-- Line manager selector -->
        <div *ngIf="isAdminOrLineManager()" class="hf-selector">
          <label>Line Manager</label>
          <div class="hf-selector-container hf-selector-has-value" [class.hf-selector-black-color]="!!form.get('lineManager.id').value"
               (click)="onSelectLineManager()">
            <div class="hf-selector-label-area">
              <div *ngIf="form.get('lineManager.id').value" class="title">{{form.get('lineManager').value.fullName}}</div>
              <div *ngIf="!form.get('lineManager.id').value" class="title">Select Line Manager</div>
            </div>
            <i class="material-icons chevron-icon">chevron_right</i>
          </div>
        </div>

        <!-- Default Protection Advisor selector -->
        <hf-user-selector *ngIf="isAdminOrLineManager() && isMortgageAdvisor(this.form.get('userType').value)"
                                        [form]="form" controlName="defaultProtectionAdvisor"
                                        label="Default Protection Advisor"
                                        (selectUser)="onSelectProtectionAdvisor()">
        </hf-user-selector>

        <ng-container *ngIf="user?.support2fa">
          <label>
            2FA Status
          </label>
          <hf-two-factor-status [status]="user"></hf-two-factor-status>
        </ng-container>

        <label [style.width.%]="100">
          Midas ID
        </label>
        <hfc-text-input mask="0*" formControlName="midasId" [style.width.%]="100"></hfc-text-input>
      </form>
    </ng-container>

    <!-- <hfc-debug-form [form]="form"></hfc-debug-form> -->
  </div>

  <div class="template-footer">
    <hf-custom-button type="submit"
                      class="button-primary"
                      [label]="this.userId ? 'Update User' : 'Add User'"
                      [disabled]="!form.valid"
                      [callable]="true"
                      (onClick)="onRequest()">
    </hf-custom-button>

    <hf-custom-button type="button"
                      class="button-secondary"
                      label="Cancel"
                      [routerLink]="['..']">
    </hf-custom-button>

    <ng-container *ifPermission="'HERON_USER_REMOVE'">
      <hf-custom-button *ngIf="userId"
                        type="button"
                        class="button-plain-icon delete-button keep-right"
                        icon="delete"
                        iconPos="center"
                        (onClick)="onRemove()">
      </hf-custom-button>
    </ng-container>
  </div>
</div>

<div class="sidepane-child-anchor">
  <router-outlet></router-outlet>
</div>
