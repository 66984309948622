import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Attachment } from '../../models/attachment';
import { EventUtils } from '../../utils/event.utils';
import { FileuploaderService } from '../fileuploader/service/fileuploader.service';
import { UploadType } from '../fileuploader/utils/fileuploader.enum';

let controlId = 1;

@Component({
  selector: 'hf-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss'],
  providers: [
    FileuploaderService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FileInputComponent,
      multi: true
    }]
})
export class FileInputComponent implements ControlValueAccessor {
  @Input()
  public multiple = false;

  @Input()
  public allowedFileTypes;

  public attachments: Attachment[] = [];
  // [
  //   {
  //     "id": null,
  //     "createdDate": null,
  //     "filename": "Mon_11-05-2020_14-20-13_367_97.pdf",
  //     "folder": "temp/1589206813367",
  //     "thumbnail": "Mon_11-05-2020_14-20-13_367_97_thumbnail@xN.png",
  //     "originalFilename": "pdf.pdf",
  //     "emailName": null
  //   },
  //   {
  //     "id": null,
  //     "createdDate": null,
  //     "filename": "Mon_11-05-2020_14-20-13_367_88.png",
  //     "folder": "temp/1589206813367",
  //     "thumbnail": "Mon_11-05-2020_14-20-13_367_88_thumbnail@xN.png",
  //     "originalFilename": "idcard.png",
  //     "emailName": null
  //   }
  // ] as any;
  public isLoading = false;
  public controlId = `fileInputComponent_${controlId++}`;

  public get isHintVisible() { return (this.multiple || !this.attachments.length) && !this.isLoading }

  constructor(
    private fileuploaderService: FileuploaderService
  ) { }

  public onRemoveClicked(attachment: Attachment) {
    this.attachments = this.attachments.filter(a => a !== attachment);

    this.onChange();
  }

  public onFileChange(event) {
    const files = EventUtils.getFiles(event);

    this.upload(files);
  }

  private async upload(files: File[]) {
    this.isLoading = true;

    try {
      const uploads = files.map(f => this.fileuploaderService.uploadFile(f, UploadType.UPLOAD_FILE, false).toPromise());
      const attachments = await Promise.all(uploads);

      this.attachments = this.multiple
        ? [...(this.attachments || []), ...attachments]
        : [...attachments];

    } catch (e) { console.log("Error in FileInputComponent.upload()", e) }

    this.onChange();
    this.isLoading = false;
  }

  private onChange() {
    const value = this.multiple
      ? this.attachments
      : (this.attachments || [])[0]

    this.onChangeFn && this.onChangeFn(value || null);
  }

  private onChangeFn: (value: Attachment | Attachment[]) => void;

  writeValue(obj: any): void {
    if (this.multiple) {
      this.attachments = Array.isArray(obj) ? obj : []
    } else {
      this.attachments = obj ? [obj] : [];
    }
  }
  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }
  registerOnTouched(fn: any): void {
    // throw new Error("Method not implemented.");
  }
  setDisabledState?(isDisabled: boolean): void {
    // throw new Error("Method not implemented.");
  }
}
