import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FactFindData } from '../../../../../services/fact-find-api.service';

@Component({
  selector: 'hfc-common-documents-review-section',
  templateUrl: './common-documents-review-section.component.html',
  styleUrls: ['./common-documents-review-section.component.scss']
})
export class CommonDocumentsReviewSectionComponent implements OnChanges {
  @Input()
  public data: FactFindData;

  public collaborativeDocumentsHeading: string;

  public ngOnChanges(changes: SimpleChanges) {
    if ("data" in changes) {
      this.collaborativeDocumentsHeading = !!this.data.collaborativeDocuments.length
        ? "Other Helpful Documents"
        : "No other helpful documents uploaded";
    }
  }
}
