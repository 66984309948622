<div class="container">
  <div class="header">
    <hf-avatar [photo]="changesGroup.executor.photo"></hf-avatar>

    <div class="author">
      {{ changesGroup.executor.fullName }}
      made the following changes

      <ng-container *ngIf="EditChangesTypeName.get(changesGroup.type) as type">
        in
        {{ type }}
      </ng-container>
    </div>

    <div *ifMedia="'desktop'" class="date">
      {{ changesGroup.createdDate | dateOrdinal:'E R MMM HH:mm' }}
    </div>
  </div>

  <div class="content">
    <div *ngFor="let change of changes" class="change" [class.isShort]="!hasAnyLong">
      <div class="label">{{ change.field }}</div>
      <div class="value">{{ change.from }}</div>
      <div class="to">to</div>
      <div class="value">{{ change.to }}</div>
    </div>
  </div>

  <div class="content">
    <div class="change">
      <div class="label">Reason</div>
      <div class="value">{{ changesGroup.reason }}</div>
    </div>
  </div>

  <div *ifMedia="'phone'" class="content">
    <div class="change">
      <div class="label">Changed on</div>
      <div class="value">{{ changesGroup.createdDate | dateOrdinal:'E R MMM HH:mm' }}</div>
    </div>
  </div>

  <!-- <pre>{{ changes|json }}</pre> -->
</div>
