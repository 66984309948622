import { Component } from '@angular/core';
import { TemplateService } from '../../services/template.service';

@Component({
  selector: 'hf-nav-button',
  templateUrl: './nav-button.component.html',
  styleUrls: ['./nav-button.component.scss']
})
export class NavButtonComponent {
  constructor(public templateService: TemplateService) { }
}
