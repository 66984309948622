import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DEPENDANT_GENDER_NAME, DEPENDANT_TYPE_NAME } from '../../../../../enums.module';
import { FactFindData } from '../../../../../services/fact-find-api.service';
import { ReviewSectionState } from '../../../../../../shared/components/review-section/review-section.component';

@Component({
  selector: 'hfc-dependants-review-section',
  templateUrl: './dependants-review-section.component.html',
  styleUrls: ['./dependants-review-section.component.scss']
})
export class DependantsReviewSectionComponent {
  public DEPENDANT_TYPE_NAME = DEPENDANT_TYPE_NAME;
  public DEPENDANT_GENDER_NAME = DEPENDANT_GENDER_NAME;

  @Input()
  public data: FactFindData;

  @Input()
  public config: ReviewSectionState;

  @Output()
  public buttonClick = new EventEmitter<ReviewSectionState>();
}
