import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ClientSharedModule } from '../../../../../../projects/client/src/app/shared/client-shared.module';
import { CustomButtonModule } from '../../../shared/custom-button/custom-button.module';
import { CustomSelectorModule } from '../../../shared/custom-selector/custom-selector.module';
import { CustomTextAreaModule } from '../../../shared/custom-textarea/custom-text-area.module';
import { DatePickerModule } from '../../../shared/date-picker/date-picker.module';
import { HfCheckboxModule } from '../../../shared/hf-checkbox-component/hf-checkbox.module';
import { SharedModule } from '../../../shared/shared.module';
import { SelectObjectivesModule } from '../select-objectives/select-objectives.module';
import { CustomSpinnerModule } from '../../../shared/custom-spinner/custom-spinner.module';
import { NewComplianceHandoverComponent } from './new-compliance-handover.component';
import { LinkListModule } from '../../../shared/link-list/link-list.module';
import { NewComplianceHandoverObjectiveComponent } from './new-compliance-handover-objective/new-compliance-handover-objective.component';
import { EvergladesSharedMorule } from '../../../shared/everglades-shared.module';
import { NewComplianceHandoverPreferenceComponent } from './new-compliance-handover-preference/new-compliance-handover-preference.component';
import { NewComplianceHandoverBudgetComponent } from './new-compliance-handover-budget/new-compliance-handover-budget.component';
import { NewComplianceHandoverRecommendedComponent } from './new-compliance-handover-recommended/new-compliance-handover-recommended.component';
import { MortgagesSharedModule } from '../mortgages-shared.module';

@NgModule({
  declarations: [
    NewComplianceHandoverComponent,
    NewComplianceHandoverObjectiveComponent,
    NewComplianceHandoverPreferenceComponent,
    NewComplianceHandoverBudgetComponent,
    NewComplianceHandoverRecommendedComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    CustomButtonModule,
    CustomTextAreaModule,
    CustomSelectorModule,
    SelectObjectivesModule,
    HfCheckboxModule,
    DatePickerModule,
    CustomSpinnerModule,
    LinkListModule,

    SharedModule,
    EvergladesSharedMorule,
    ClientSharedModule,
    MortgagesSharedModule,
  ],
  exports: [
    NewComplianceHandoverComponent
  ],
})
export class NewComplianceHandoverModule { }
