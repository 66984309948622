import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectApplicationComponent } from './select-application.component';
import { CustomButtonModule } from '../../../shared/custom-button/custom-button.module';
import { CustomSpinnerModule } from '../../../shared/custom-spinner/custom-spinner.module';
import { CustomSelectorModule } from '../../../shared/custom-selector/custom-selector.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [SelectApplicationComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    CustomButtonModule,
    CustomSpinnerModule,
    CustomSelectorModule
  ],
  exports: [SelectApplicationComponent]
})
export class SelectApplicationModule { }
