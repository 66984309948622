import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeDifference',
  pure: false
})
export class TimeDifferencePipe implements PipeTransform {

  public transform(value: any): any {
    if (value === null || value === undefined) {
      return '--';
    }

    const d = new Date(value);
    const now = new Date();
    const seconds = Math.round(Math.abs((now.getTime() - d.getTime()) / 1000));
    const minutes = Math.round(Math.abs(seconds / 60));
    const hours = Math.round(Math.abs(minutes / 60));
    const days = Math.round(Math.abs(hours / 24));
    const months = Math.round(Math.abs(days / 30.416));
    const years = Math.round(Math.abs(days / 365));

    if (d < now) {
      if (Number.isNaN(seconds)) {
        return '--';
      } else if (seconds <= 45) {
        return 'Just now';
      } else if (seconds <= 90) {
        return '1 min ago';
      } else if (minutes <= 45) {
        return minutes + ' mins ago';
      } else if (minutes <= 90) {
        return '1 hr ago';
      } else if (hours <= 22) {
        return hours + ' hrs ago';
      } else if (hours <= 36) {
        return 'Yesterday';
      } else if (days <= 25) {
        return days + ' days ago';
      } else if (days <= 45) {
        return '1 mth ago';
      } else if (days <= 345) {
        return months + ' mths ago';
      } else if (days <= 545) {
        return '1 year ago';
      } else { // (days > 545)
        return years + ' years ago';
      }
    } else {
      if (Number.isNaN(seconds)) {
        return '--';
      } else if (seconds <= 45) {
        return 'Just now';
      } else if (seconds <= 90) {
        return '1 min';
      } else if (minutes <= 45) {
        return minutes + ' mins';
      } else if (minutes <= 90) {
        return '1 hr';
      } else if (hours <= 22) {
        return hours + ' hrs';
      } else if (hours <= 36) {
        return 'Tomorrow';
      } else if (days <= 25) {
        return days + ' days';
      } else if (days <= 45) {
        return '1 mth';
      } else if (days <= 345) {
        return months + ' mths';
      } else if (days <= 545) {
        return '1 year';
      } else { // (days > 545)
        return years + ' years';
      }
    }
  }
}
