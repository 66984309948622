import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CustomTextFieldComponent } from './custom-text-field.component';

@NgModule({
  declarations: [
    CustomTextFieldComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule
  ],
  exports: [
    CustomTextFieldComponent
  ],
  providers: []
})

export class CustomTextFieldModule {

}
