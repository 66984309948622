import { Component, Input } from '@angular/core';
import { yesno } from '../../../../../../../projects/client/src/app/shared/functions/yesno';
import { NewComplianceHandoverPreferenceDTO } from '../../../../models/new-compliance-handover-preference.dto';
import { MortgageOffsetReasonName, MortgageProductTermName } from '../../../../utils/variables.data';
import { valueOther } from '../new-client-sheet-objective/new-client-sheet-objective.component';

@Component({
  selector: 'hf-new-client-sheet-preference',
  templateUrl: './new-client-sheet-preference.component.html',
  styleUrls: ['./new-client-sheet-preference.component.scss']
})
export class NewClientSheetPreferenceComponent {
  @Input()
  public data: NewComplianceHandoverPreferenceDTO;

  public MortgageProductTermName = MortgageProductTermName;
  public MortgageOffsetReasonName = MortgageOffsetReasonName;

  public yesno = yesno;
  public valueOther = valueOther;
}
