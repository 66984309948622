import { Directive, HostBinding, HostListener } from '@angular/core';
import { EventUtils } from '../../../utils/event.utils';

@Directive({
  selector: '[hfDragover]'
})
export class DragoverDirective {
  @HostBinding('class.dragover')
  public dragover = false;

  @HostListener('dragover', ['$event']) 
  public onDragOver(event: Event) {
    EventUtils.preventDefaultAndStopPropagation(event);
    this.dragover = true;
  }

  @HostListener('dragleave', ['$event']) 
  public onDragLeave(event: Event) {
    EventUtils.preventDefaultAndStopPropagation(event);
    this.dragover = false;
  }

  @HostListener('drop', ['$event']) 
  public onDrop(event: Event) {
    EventUtils.preventDefaultAndStopPropagation(event);
    this.dragover = false;
  }
}
