<div class="ev-details-container">
  <div class="ev-details-column">
    <div class="ev-details-entry">
      <div class="ev-details-entry-label">Explain income calculation</div>
      <div class="ev-details-entry-value">{{ data.incomeCalculationExplanation }}</div>
    </div>

    <div class="ev-details-entry">
      <div class="ev-details-entry-label">Agreed monthly budget for new mortgage</div>
      <div class="ev-details-entry-value"><hf-currency [value]="data.agreedMonthlyBudget" format="1.0-2"></hf-currency></div>
    </div>
  </div>

  <div class="ev-details-column">
    <div class="ev-details-entry">
      <div class="ev-details-entry-label">Gifted deposit</div>
      <div class="ev-details-entry-value">{{ yesno(data.giftedDeposit) }}</div>
    </div>

    <div class="ev-details-entry">
      <div class="ev-details-entry-label">Adverse credit</div>
      <div class="ev-details-entry-value">{{ yesno(data.adverseCredit) }}</div>
    </div>

    <div class="ev-details-entry">
      <div class="ev-details-entry-label">Known income changes</div>
      <div class="ev-details-entry-value">{{ yesno(data.knownIncomeChanges) }}</div>
    </div>

    <div class="ev-details-entry">
      <div class="ev-details-entry-label">Income changes</div>
      <div class="ev-details-entry-value">{{ data.knownIncomeChangesExplanation }}</div>
    </div>
  </div>

  <div class="ev-details-column">
    <div *ngIf="data.depositObservedEvidence" class="ev-details-entry">
      <div class="ev-details-entry-label">Evidence of deposit observed</div>
      <div class="ev-details-entry-value">{{ data.depositObservedEvidence }}</div>
    </div>

    <div class="ev-details-entry">
      <div class="ev-details-entry-label">Is there surplus funds not being used?</div>
      <div class="ev-details-entry-value">{{ yesno(data.surplus) }}</div>
    </div>
    <div *ngIf="data.surplusAmount" class="ev-details-entry">
      <div class="ev-details-entry-label">Amount of surplus funds</div>
      <div class="ev-details-entry-value"><hf-currency [value]="data.surplusAmount" format="1.0-2"></hf-currency></div>
    </div>
    <div *ngIf="data.surplusDetails" class="ev-details-entry">
      <div class="ev-details-entry-label">Why are surplus funds not being used?</div>
      <div class="ev-details-entry-value">{{ data.surplusDetails }}</div>
    </div>
  </div>
</div>

<div class="ev-details-container">
  <div *ngFor="let applicantIncome of applicantsIncomes" class="ev-details-column">
    <div class="text-medium">{{ applicantIncome.applicant.fullName }}</div>

    <div class="text-small-medium-grey24" style="margin: 8px 0">Annual Breakdown</div>

    <div *ngFor="let income of applicantIncome.incomes" class="ev-details-entry">
      <div class="ev-details-entry-label">{{ income.description }}</div>
      <div class="ev-details-entry-value"><hf-currency [value]="income.amount" format="1.0-2"></hf-currency></div>
    </div>
    <div class="ev-details-entry">
      <div class="ev-details-entry-label">Total</div>
      <div class="ev-details-entry-value"><hf-currency [value]="applicantIncome.total" format="1.0-2"></hf-currency></div>
    </div>
    <div class="ev-details-entry">
      <div class="ev-details-entry-label">Net Monthly Income</div>
      <div class="ev-details-entry-value"><hf-currency [value]="getNetMontlyIncome(applicantIncome.applicant.id)" format="1.0-2"></hf-currency></div>
    </div>
  </div>
</div>
