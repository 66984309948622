import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { User, TwoFactorState } from '../../../models/user';
import { UserApiService } from '../../../services/user-api.service';
import { UserService } from '../../../services/user.service';
import { TableSettingsService, TABLE_PARAMS_STORAGE_KEY } from '../../../shared/table-utils/table-settings.service';
import { MicrosoftLinkState, USER_TYPE_NAMES } from '../../../utils/variables.data';
import { HeronUserService } from "../user.service";

@Component({
  selector: 'hf-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
  providers: [TableSettingsService, { provide: TABLE_PARAMS_STORAGE_KEY, useValue: "app/users" }]
})
export class UserListComponent implements AfterViewInit, OnDestroy {
  public USER_TYPE_NAMES = USER_TYPE_NAMES;
  public data: User[];

  private destroy$ = new Subject();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userApiService: UserApiService,
    private tableSettingsService: TableSettingsService,
    private heronUserService: HeronUserService,
    private userService: UserService
  ) {
    this.tableSettingsService.data$.pipe(takeUntil(this.destroy$))
      .subscribe(data => this.data = data);

    this.heronUserService.reloadUsersDashboard.pipe(takeUntil(this.destroy$))
      .subscribe(() => { this.tableSettingsService.refresh() });
  }

  public getLinkTooltip(state: any) {
    switch (state) {
      case "SETUP":
        return "Linked";
      case "NOT_SETUP":
        return "Not Linked";
    }
  }

  public getTwoFactorTooltip(row) {
    const state: TwoFactorState = row.twoFactorState;

    switch (state) {
      case "FULLY":
        return "SMS and Authenticator";
      case "SMS_ONLY":
        return "SMS Only";
      case "NOT_SETUP":
        return "Not set up";
    }
  }

  public ngAfterViewInit() {
    this.tableSettingsService.setDataSourceFactory(params => this.userApiService.listHeronUsers(params));
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }

  public hasPermission() {
    return this.userService.hasPermission("HERON_USER_VIEW");
  }

  public viewUser(userId: string) {
    this.hasPermission() && this.router.navigate([userId], { relativeTo: this.route });
  }
}
