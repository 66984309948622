import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectSolicitorComponent } from './select-solicitor.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomButtonModule } from '../../../shared/custom-button/custom-button.module';
import { CustomSelectorModule } from '../../../shared/custom-selector/custom-selector.module';
import { CustomSpinnerModule } from '../../../shared/custom-spinner/custom-spinner.module';
import { CustomTextFieldModule } from '../../../shared/custom-text-field/custom-text-field.module';
import { ClientSharedModule } from '../../../../../../projects/client/src/app/shared/client-shared.module';

@NgModule({
  declarations: [SelectSolicitorComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    CustomButtonModule,
    CustomSelectorModule,
    CustomSpinnerModule,
    CustomTextFieldModule,
    ClientSharedModule,
  ],
  exports: [SelectSolicitorComponent]
})
export class SelectSolicitorModule { }
