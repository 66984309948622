<div class="container">
  <div class="logo">
    <img *ngIf="image" [hfTooltip]="imageText" [hfSrcset]="image.folder + '/' + image.filename">
  </div>

  <div class="content">
    <div class="heading">{{heading}}</div>

    <div class="ng-content">
      <ng-content></ng-content>
    </div>
  </div>
</div>
