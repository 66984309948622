<div (click)="onBack()" class="overlay" [@fadeAnimation]></div>

<div class="sidepane-content">
  <div class="flex-space-between-layout template-header">
    <h1>Select Application</h1>
    <hf-custom-button type="button" class="button-plain-icon" icon="close" iconPos="center" (onClick)="onBack()">
    </hf-custom-button>
  </div>

  <div class="template-main">
    <ng-container *ngIf="isLoading">
      <hf-custom-spinner sidepaneLoading="true"></hf-custom-spinner>
    </ng-container>

    <form *ngIf="!isLoading" [formGroup]="form">
      <hf-custom-selector formControlName="application"
                          [items]="items"
                          [compareWith]="compareFn">
        <ng-template #itemTemplate let-item>
          <div>
            {{item.lender?.name}}
          </div>
        </ng-template>
      </hf-custom-selector>
    </form>
  </div>
</div>


