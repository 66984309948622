<div class="header">
  <div class="heading">
    <div>
      <span *ngIf="!pinnedNote.edited">Added by: </span> 
      <span *ngIf="pinnedNote.edited">Edited by: </span> 
      {{ pinnedNote.executor.fullName }}
    </div>

    <div class="date">
      {{ pinnedNote.lastAction|date:'d MMM, y HH:mm' }}
    </div>
  </div>

  <div class="edit" (click)="onEditClicked()">
    <span>Edit</span>
    <hf-named-icon icon="edit"></hf-named-icon>
  </div>
</div>

<div class="details">
  {{ pinnedNote.body }}
</div>
