import {CanDeactivate} from "@angular/router";
import { Component, Injectable } from "@angular/core";

@Injectable()
export class AppCanDeactivateGuardRouting implements CanDeactivate<Component>{

  canDeactivate(component) {
    return component.canDeactivate();
  }
}
