<section class="section">
  <div class="table-page-container">
    <hf-pinned-note-box *ngIf="pinnedNote" [pinnedNote]="pinnedNote" style="margin-bottom: 16px;"></hf-pinned-note-box>

    <div class="table-tools">
      <hf-custom-text-field type="text"
                            [searchMode]="true"
                            placeholder="Search journal …"
                            fieldName="input12"
                            icon="search"
                            preventValidation="true">
      </hf-custom-text-field>

      <hf-selector-link *ngIf="showApplication" placeholder="Select Application"
        [value]="application?.lender"
        (click)="onSelectApplicationClicked()" class="select-application">
      </hf-selector-link>
    </div>

    <hf-journal [dataSourceFactory]="journalDataSourceFactory"></hf-journal>
  </div>
</section>
