<div (click)="onBack()" class="overlay" [@fadeAnimation]></div>

<div class="sidepane-content">
  <div class="flex-space-between-layout template-header">
    <h1>Task</h1>
    <hf-custom-button type="button" class="button-plain-icon" icon="close" iconPos="center" (onClick)="onBack()">
    </hf-custom-button>
  </div>

  <div class="template-main">
    <hf-custom-spinner *ngIf="isLoading" sidepaneLoading="true"></hf-custom-spinner>

    <form *ngIf="!isLoading" [formGroup]="form">
      <label>Creator</label>
      <div class="hf-form-static-value">
        {{data?.createdBy.fullName}}
      </div>

      <label>Creation Date</label>
      <div class="hf-form-static-value">
        {{data?.createdDate | date:'d MMM y, HH:mm'}}
      </div>

      <hf-selector-link *ngIf="canReassign"
                        label="Assignee"
                        style="display: block; margin-top: -8px; margin-bottom: 12px"
                        [value]="data?.assignee.fullName"
                        (click)="onAssigneeClicked()">
      </hf-selector-link>
      <ng-container *ngIf="!canReassign">
        <label>Assignee</label>
        <div class="hf-form-static-value">
          {{data?.assignee.fullName}}
        </div>
      </ng-container>

      <ng-container *ngIf="data.application">
        <label>Application</label>
        <div class="hf-form-static-value">
          <hf-attachment-image [attachment]="data.application.lenderLogo" [alt]="data.application.lender" class="provider-logo"></hf-attachment-image>
        </div>

        <label>Application Reference</label>
        <div class="hf-form-static-value">
          {{ data.application.lenderReference }}
        </div>
      </ng-container>

      <label>Summary</label>
      <div class="hf-form-static-value">
        {{data?.summary}}
      </div>

      <label>Description</label>
      <div class="hf-form-static-value pre">{{data?.description}}</div>

      <ng-container *ngIf="data?.dueDate">
        <label>Due Date</label>
        <div class="hf-form-static-value" [class.red]="data.due && !data.completed">
          {{data?.dueDate | date:'d MMM y'}}
        </div>
      </ng-container>

      <hfc-button *ngIf="config.hasViewDetailButton" type="tertiary" size="small" class="viewMortgage" (click)="onViewClicked()">
        {{data?.entity === 'MORTGAGE' ? 'View Mortgage' : 'View Protection Case'}}
      </hfc-button>

      <ng-container *ngIf="!data?.completed">
        <hf-checkbox label="Mark as Done" formControlName="_markAsDone">
        </hf-checkbox>

        <ng-container *ngIf="form.get('note').enabled">
          <label style="margin-top: 12px">Note to {{data.createdBy.fullName}} (Optional)</label>
          <hf-textarea [group]="form" inputFormControlName="note" fieldName="note" [smaller]="true" #note>
          </hf-textarea>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="data?.completed">
        <label>Done Date</label>
        <div class="hf-form-static-value">
          {{data?.completed | date:'d MMM y'}}
        </div>

        <ng-container *ngIf="data?.completionNote">
          <label>Done Notes</label>
          <div class="hf-form-static-value pre">{{data?.completionNote}}</div>
        </ng-container>
      </ng-container>
    </form>
  </div>

  <div class="template-footer">
    <hf-custom-button *ngIf="canDone && !data.completed"
                      type="submit"
                      class="button-primary"
                      label="Done"
                      [disabled]="form.invalid"
                      [isLoading]="isSubmitting"
                      (onClick)="onDoneClicked()">
    </hf-custom-button>

    <hf-custom-button type="button"
                      class="button-secondary"
                      label="Cancel"
                      (click)="onBack()">
    </hf-custom-button>

    <hf-custom-button type="button"
                      class="button-plain-icon delete-button keep-right"
                      icon="edit"
                      iconPos="center"
                      (onClick)="onEditClicked()">
    </hf-custom-button>


    <hf-custom-button type="button"
                      class="button-plain-icon delete-button keep-right"
                      icon="delete"
                      iconPos="center"
                      *ngIf="hasPermissionToDeleteTask"
                      (onClick)="onDeleteClicked()">
    </hf-custom-button>
  </div>
</div>

  <div class="sidepane-child-anchor">
    <router-outlet></router-outlet>
  </div>
