import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DetailsContainerComponent } from './details-container.component';
import { DetailsAreaComponent } from './details-area/details-area.component';
import { DetailsRowComponent } from './details-row/details-row.component';



@NgModule({
  declarations: [DetailsContainerComponent, DetailsAreaComponent, DetailsRowComponent],
  imports: [
    CommonModule
  ],
  exports: [DetailsContainerComponent, DetailsAreaComponent, DetailsRowComponent]
})
export class DetailsContainerModule { }
