import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LinkListComponent } from './link-list.component';

@NgModule({
  imports: [
    CommonModule
  ],
  exports: [
    LinkListComponent
  ],
  declarations: [
    LinkListComponent
  ]
})
export class LinkListModule {
}
