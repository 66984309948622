import { Component, Input } from '@angular/core';
import { APPLICANT_SCHEME_NAMES_ALL, PROPERTY_PAYMENT_METHOD_NAME } from '../../../../../../../projects/client/src/app/fact-find/enums.module';
import { yesno } from '../../../../../../../projects/client/src/app/shared/functions/yesno';
import { NewComplianceHandoverRecommendedDTO } from '../../../../models/new-compliance-handover-recommended.dto';
import { NewComplianceHandoverViewDTO } from '../../../../models/new-compliance-handover-view.dto';
import { FeesToLoanReasonName, MortgageDebtConsolidationReasonName, MORTGAGE_PRODUCT_DATA_TYPE_NAME, MORTGAGE_PRODUCT_END_TYPE_NAME_ALL } from '../../../../utils/variables.data';
import { valueOther } from '../new-client-sheet-objective/new-client-sheet-objective.component';

@Component({
  selector: 'hf-new-client-sheet-recommended',
  templateUrl: './new-client-sheet-recommended.component.html',
  styleUrls: ['./new-client-sheet-recommended.component.scss']
})
export class NewClientSheetRecommendedComponent {
  @Input()
  public data: NewComplianceHandoverRecommendedDTO;

  @Input()
  public dto: NewComplianceHandoverViewDTO;

  public APPLICANT_SCHEME_NAMES_ALL = APPLICANT_SCHEME_NAMES_ALL;
  public MORTGAGE_PRODUCT_DATA_TYPE_NAME = MORTGAGE_PRODUCT_DATA_TYPE_NAME;
  public PROPERTY_PAYMENT_METHOD_NAME = PROPERTY_PAYMENT_METHOD_NAME;
  public MORTGAGE_PRODUCT_END_TYPE_NAME_ALL = MORTGAGE_PRODUCT_END_TYPE_NAME_ALL;
  public FeesToLoanReasonName = FeesToLoanReasonName;
  public MortgageDebtConsolidationReasonName = MortgageDebtConsolidationReasonName;

  public yesno = yesno;
  public valueOther = valueOther;

  public get objective() { return this.dto?.objective?.objective }
}
