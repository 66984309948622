import { Component, Input } from '@angular/core';

@Component({
  selector: 'hf-selector-link',
  templateUrl: './selector-link.component.html',
  styleUrls: ['./selector-link.component.scss']
})
export class SelectorLinkComponent {
  @Input()
  public value: string;

  @Input()
  public label: string;

  @Input()
  public placeholder: string;

  @Input()
  public readonly: boolean;

  @Input()
  public isInvalid: boolean;
}
