import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';

import { fadeAnimation, sidepaneMove } from "../../../shared/animations/sidepane-animation";
import { SelectDateAndTimeService, SelectedDateProperty } from "./select-date-and-time.service";

enum PERIOD {
  AM = 'AM',
  PM = 'PM'
}

interface DateTime {
  date: Date;
  hours: number;
  minutes: number;
}

@Component({
  selector: 'app-select-date-and-time',
  templateUrl: './select-date-and-time.component.html',
  styleUrls: ['./select-date-and-time.component.scss',
    '../../../styles/sidepanes.partial.scss'],
  animations: [sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove]': 'true' }
})

export class SelectDateAndTimeComponent implements OnInit {
  public minDateValue: Date = new Date();
  public maxDateValue: Date;
  public yearRangeValue = (new Date()).getFullYear() + ':2050';
  public onlyDate: boolean = false;

  public form = this.fb.group({
    date: ['', Validators.required],
    hours: ['', Validators.required],
    minutes: ['', Validators.required]
  });
  public periodEnum = PERIOD;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private dateAndTimeService: SelectDateAndTimeService
  ) { }

  ngOnInit() {
    this.onlyDate = !!this.activatedRoute.snapshot.queryParams["onlyDate"];
    if (this.onlyDate) {
      this.form.get("hours").disable();
      this.form.get("minutes").disable();
    }

    const initialValue = this.activatedRoute.snapshot.queryParams["initialValue"];
    initialValue && moment(initialValue).isValid() && this.setInitialValue(moment(initialValue).toDate());

    const maxDate = this.activatedRoute.snapshot.queryParams["maxDate"];
    const minDate = this.activatedRoute.snapshot.queryParams["minDate"];

    if (maxDate && moment(maxDate).isValid()) {
      this.maxDateValue = moment(maxDate).toDate();
      this.minDateValue = undefined;
      this.yearRangeValue = `${(new Date()).getFullYear() - 100}:${(new Date()).getFullYear()}`;
    }
    if (minDate === "none") {
      this.minDateValue = undefined;
      this.yearRangeValue = `${(new Date()).getFullYear() - 100}:${(new Date()).getFullYear() + 50}`;
    }
  }

  onSubmit() {
    const date = this.form.get('date').value as Date;
    if (this.onlyDate) {
      date.setHours(0, 0, 0, 0);
    } else {
      date.setHours(this.form.value.hours);
      date.setMinutes(this.form.value.minutes);
    }

    const name = this.activatedRoute.snapshot.queryParams["name"] || this.activatedRoute.snapshot.data.name;
    const selectedDateProperty: SelectedDateProperty = {
      date: date,
      name,
    };
    this.dateAndTimeService.dateSelected$.next(selectedDateProperty);

    this.router.navigate(['./..'], { relativeTo: this.activatedRoute });
  }

  private setInitialValue(date: Date) {
    const value: DateTime = {
      date: date,
      hours: date.getHours(),
      minutes: date.getMinutes()
    };

    this.form.setValue(value);
  }
}
