<ul>
  <li *ngFor="let route of routes">
    <a *ngIf="!route.children" (click)="onLinkClicked()" [routerLink]="route.path" routerLinkActive="selected">{{ route.name }}</a>

    <a *ngIf="route.children" (click)="onRouteClicked(route)">
      {{ route.name }}
      <i class="material-icons" [class.isOpen]="route.isOpen">arrow_drop_down</i>
    </a>

    <ul *ngIf="route.children" class="openable" [class.isOpen]="route.isOpen">
      <li *ngFor="let child of route.children">
        <a (click)="onLinkClicked()" [routerLink]="child.path" routerLinkActive="selected">{{ child.name }}</a>
      </li>
    </ul>
  </li>
</ul>
