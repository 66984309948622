<button [class.hasValue]="hasValue">
  <i *ngIf="!hasValue" class="material-icons add">add_circle</i>

  <ng-container *ngTemplateOutlet="hasValue ? hasValueTemplate : noValueTemplate">
  </ng-container>

  <i class="material-icons chevron">chevron_right</i>
</button>

<ng-template #noValueTemplate>
  <ng-content></ng-content>
</ng-template>
