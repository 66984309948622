<input *ngIf="!onlyDate"
       class="mobile-datetime-input"
       [class.hf-invalid-field]="invalid"
       type="datetime-local"
       [value]="value"
       [min]="min"
       [max]="max"
       (change)="onValueChange($event.target.value)">
<input *ngIf="onlyDate"
       class="mobile-datetime-input"
       [class.hf-invalid-field]="invalid"
       type="date"
       [value]="value"
       [min]="min"
       [max]="max"
       (change)="onValueChange($event.target.value)">
