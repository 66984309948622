import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { Solicitor } from '../../../models/solicitor';
import { MortgageApiService } from '../../../services/mortgage-api.service';
import { fadeAnimation, sidepaneMove } from '../../../shared/animations/sidepane-animation';
import { PhonePrefixService } from '../../introducers/select-phone-prefix/phone-prefix.service';
import { MortgagesService } from '../mortgages.service';
import { SelectSolicitorService } from './select-solicitor.service';
import { getUkPhoneValidator } from '../../../../../../projects/client/src/app/shared/functions/uk-phone.validator';


@Component({
  selector: 'hf-select-solicitor',
  templateUrl: './select-solicitor.component.html',
  styleUrls: [
    './select-solicitor.component.scss',
    '../../../styles/sidepanes.partial.scss'
  ],
  animations: [sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove]': 'true' }
})
export class SelectSolicitorComponent implements OnDestroy {
  public isLoading = true;
  public items: Solicitor[];
  public isAddMode = false;

  public selectorForm = this.fb.group({
    solicitor: []
  });

  public addForm = this.fb.group({
    id: [''],
    name: ['', Validators.required],
    email: ['', Validators.required],
    phonePrefix: ['+44', Validators.required],
    phone: ['', [Validators.required, getUkPhoneValidator()]],
    address: this.fb.group({
      house: ['', Validators.required],
      street: ['', Validators.required],
      line2: [''],
      city: ['', Validators.required],
      postcode: ['', Validators.required],
      country: ['', Validators.required]
    })
  });

  public compareFn = (solicitor1: Solicitor, solicitor2: Solicitor): boolean =>
    !!(solicitor1 && solicitor2 && solicitor1.id === solicitor2.id);
  public filterFn = (solicitor: Solicitor, filter: string): boolean =>
    solicitor && filter && solicitor.name.toLowerCase().includes(filter.toLowerCase())

  private destroy$ = new Subject();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private mortgagesService: MortgagesService,
    private mortgageApiService: MortgageApiService,
    private phonePrefixService: PhonePrefixService,
    private selectSolicitorService: SelectSolicitorService
  ) {
    const initialSolicitor = this.selectSolicitorService.initialSolicitor;
    if (initialSolicitor) {
      if (initialSolicitor.id) {
        this.selectorForm.get("solicitor").patchValue(initialSolicitor);
      } else {
        this.addForm.patchValue(initialSolicitor);
        this.isAddMode = true;
      }
    }

    this.mortgagesService.mortgageDashboardDataLoaded.pipe(take(1)).subscribe(() => {
      const clientId = this.mortgagesService.data.client.id;

      this.mortgageApiService.getSolicitors(clientId).then(items => {
        this.items = items;
        this.isLoading = false;
      });
    })

    this.phonePrefixService.phonePrefixUpdated.pipe(takeUntil(this.destroy$)).subscribe(phonePrefix => {
      this.addForm.patchValue({ phonePrefix });
    })

    this.selectorForm.get("solicitor").valueChanges.pipe(takeUntil(this.destroy$)).subscribe(solicitor => {
      this.selectSolicitorService.solicitorSelected$.next(solicitor);
      this.selectSolicitorService.initialSolicitor = solicitor;
      this.onBack();
    })
  }

  public onBack() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }

  public onAdd() {
    this.isAddMode = true;
  }

  public onSubmit() {
    this.selectSolicitorService.solicitorSelected$.next(this.addForm.value);
    this.selectSolicitorService.initialSolicitor = this.addForm.value;
    this.onBack();
  }

  public onCancel() {
    this.isAddMode = false;
  }
}
