import {Directive, Renderer2, ElementRef} from '@angular/core';

@Directive({
  selector: '[marginElement]'
})
export class MarginElementDirective {

  constructor(private ref: ElementRef, private renderer: Renderer2) {
    const parent = this.ref.nativeElement.parentNode;
    const divElement = this.renderer.createElement('div');
    this.renderer.setStyle(divElement, 'margin-top', '8px');
    this.renderer.insertBefore(parent, divElement, this.ref.nativeElement);
    this.renderer.removeChild(parent, this.ref.nativeElement);
    this.renderer.appendChild(divElement, this.ref.nativeElement);
  }
}
