<div (click)="onBack()" class="overlay"  [@fadeAnimation]></div>

<div class="sidepane-content">
  <div class="flex-space-between-layout template-header">
    <h1>{{ isEditMode ? 'Edit Applicant' : 'Add Applicant' }}</h1>
    <hf-custom-button type="button" class="button-plain-icon" icon="close" iconPos="center" (onClick)="onBack()">
    </hf-custom-button>
  </div>

  <div class="template-main">
    <hf-custom-spinner *ngIf="isLoading" sidepaneLoading="true"></hf-custom-spinner>

    <form *ngIf="!isLoading" [formGroup]="form" class="ev-sidepane-form">
      <div class="ev-sidepane-form-section">
        <div class="ev-sidepane-form-content">
          <div class="ev-sidepane-form-column-full-width">
            <div class="ev-form-field">
              <hf-client-selector [form]="form" [readonly]="isEditMode" (selectClient)="onSelectClientClicked()"></hf-client-selector>
            </div>

            <div class="ev-form-field">
              <label>Title (Optional)</label>
              <hf-selector-link placeholder="Select Title"
                                [value]="PERSONAL_TITLE_NAME.get(value.title)"
                                (click)="onSelectTitleClicked()">
              </hf-selector-link>
            </div>

            <div class="ev-form-field">
              <hf-checkbox label="Smoker" formControlName="smokerLastYear"></hf-checkbox>
            </div>

            <div class="ev-form-field">
              <label>Gender</label>
              <hfc-checkbox-list [map]="GenderNoOtherName" formControlName="gender"></hfc-checkbox-list>
            </div>

            <div class="ev-form-field">
              <label>Date of Birth</label>
              <hf-date-input [maxDate]="today" formControlName="dateOfBirth" propertyName="dateOfBirth"></hf-date-input>
            </div>

            <div class="ev-form-field">
              <label>Metric</label>
              <hfc-checkbox-list [map]="MetricTypeName" formControlName="metricType"></hfc-checkbox-list>
            </div>

            <div class="ev-form-field">
              <label>Height (Optional)</label>
              <div *ngIf="value.metricType === 'IMPERIAL'" class="metric-inputs">
                <hfc-text-input sufix="Feet" type="number" formControlName="heightFeet"></hfc-text-input>
                <hfc-text-input sufix="Inches" type="number" formControlName="heightInches"></hfc-text-input>
              </div>
              <hfc-text-input *ngIf="value.metricType === 'METRIC'" sufix="Centimetres" type="number" formControlName="heightCm"></hfc-text-input>
            </div>

            <div class="ev-form-field">
              <label>Weight (Optional)</label>
              <div *ngIf="value.metricType === 'IMPERIAL'" class="metric-inputs">
                <hfc-text-input sufix="Stone" type="number" formControlName="weightStone"></hfc-text-input>
                <hfc-text-input sufix="Pounds" type="number" formControlName="weightPounds"></hfc-text-input>
              </div>
              <hfc-text-input *ngIf="value.metricType === 'METRIC'" sufix="Kilograms" type="number" formControlName="weightKg"></hfc-text-input>
            </div>

            <div class="ev-form-field">
              <label>Employment Status (Optional)</label>
              <hfc-checkbox-list [map]="EMPLOYMENT_STATUS_NAME" formControlName="employmentStatus"></hfc-checkbox-list>
            </div>

            <div *ngIf="control('origoCode').enabled" class="ev-form-field">
              <label>Origo Code</label>
              <hf-selector-link placeholder="Select Code"
                                [value]="value.origoCode"
                                (click)="onSelectOrigoCodeClicked()">
              </hf-selector-link>
            </div>

            <div class="ev-form-field">
              <label>Annual Income <ng-container *ngIf="!isFinanciallyActive()">(Optional)</ng-container></label>
              <hfc-text-input prefix="£" currency="true" pattern="\d*" class="text-align-right" formControlName="annualIncome"></hfc-text-input>
            </div>
          </div>
        </div>
      </div>

    </form>

    <!-- <hfc-debug-form [form]="form"></hfc-debug-form> -->
  </div>

  <div class="template-footer">
    <hfc-button *ngIf="canEdit" type="primary" [disabled]="form.invalid" [isLoading]="isSubmitting" (click)="onSubmit()">{{ isEditMode ? 'Save' : 'Add Applicant' }}</hfc-button>
    <hfc-button type="secondary" (click)="onBack()">Close</hfc-button>

    <hf-custom-button *ngIf="canEdit && isEditMode && !value.lead"
                      type="button"
                      class="button-plain-icon delete-button keep-right"
                      icon="delete"
                      iconPos="center"
                      (onClick)="onRemove()">
    </hf-custom-button>
  </div>
</div>

<div class="sidepane-child-anchor">
  <router-outlet></router-outlet>
</div>
