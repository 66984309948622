import { Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';

import { fadeAnimation, sidepaneMove } from '../../../shared/animations/sidepane-animation';
import { DEBT_CONSOLIDATION_EXPLANATION, DEBT_CONSOLIDATION_EXPLANATION_NAME } from '../../../utils/variables.data';
import { SelectDebtConsolidationService, SelectedDebtConsolidation } from './select-debt-consolidation.service';

@Component({
  selector: 'hf-select-debt-consolidation',
  templateUrl: './select-debt-consolidation.component.html',
  styleUrls: [
    './select-debt-consolidation.component.scss',
    '../../../styles/sidepanes.partial.scss'
  ],
  animations: [sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove]': 'true' }
})
export class SelectDebtConsolidationComponent implements OnDestroy {
  public form = new FormGroup({
    debtConsolidationExplanation: new FormControl(null, Validators.required),
    otherDebtConsolidationExplanation: new FormControl(null, Validators.required),
  });

  public DEBT_CONSOLIDATION_EXPLANATION_NAME = DEBT_CONSOLIDATION_EXPLANATION_NAME;
  public debtConsolidation = [...DEBT_CONSOLIDATION_EXPLANATION_NAME.keys()];

  private destroy$ = new Subject();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private selectDebtConsolidationService: SelectDebtConsolidationService
  ) {
    this.form.get("debtConsolidationExplanation").valueChanges.subscribe((debtConsolidationExplanation: DEBT_CONSOLIDATION_EXPLANATION) => {
      if (debtConsolidationExplanation === "OTHER") {
        this.form.get("otherDebtConsolidationExplanation").enable();
      } else {
        this.form.get("otherDebtConsolidationExplanation").disable();
      }
    })

    const selectedDebtConsolidation = this.selectDebtConsolidationService.debtConsolidation;
    selectedDebtConsolidation && this.form.patchValue(selectedDebtConsolidation);

    this.form.valueChanges.subscribe((value: SelectedDebtConsolidation) => {
      if (value.debtConsolidationExplanation !== "OTHER") {
        this.onSubmit();
      }
    })
  }

  public onSubmit() {
    const value: SelectedDebtConsolidation = this.form.value;

    if (value.debtConsolidationExplanation !== "OTHER") {
      value.otherDebtConsolidationExplanation = undefined;
    }

    this.selectDebtConsolidationService.setDebtConsolidation(value);

    this.onBack();
  }

  public onBack() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }
}
