import { Inject, Injectable, InjectionToken, OnDestroy, Optional } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BehaviorSubject, fromEvent, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, debounceTime, map, switchMap, takeUntil } from 'rxjs/operators';
import { SessionStoreService } from '../../services/session-store.service';
import { TableSorting } from './table-sorting.model';


export interface TableParams {
  pageSize?: number;
  page?: number;
  filter?: string;
  sortBy?: string;
  sortDirection?: TableSorting;
  scroll?: number;
  _data?: any[];
}

// TODO martinbarnas 03/04/2020: make it type safe
export type DataSourceFactory<T extends TableParams> = (params: T) => Observable<any>;

const initialTableParams: TableParams = {
  page: 1,
  pageSize: 100,
};

export const TABLE_PARAMS_STORAGE_KEY = new InjectionToken<string>('TABLE_PARAMS_STORAGE_KEY');

@Injectable()
export class TableSettingsService implements OnDestroy {
  public tableParams$ = new BehaviorSubject<TableParams>({ ...initialTableParams });
  public get tableParams() { const tableParams = {...this.tableParams$.value}; delete tableParams._data; return tableParams }

  public get pageSize() { return this.tableParams && this.tableParams.pageSize }
  public get pageNum() { return this.tableParams && this.tableParams.page }

  public totalPages = 0;
  public totalElements = 0;
  public noScrollTop = false;

  public data$ = new Subject<any[]>();
  private _data: any[];

  private defaultSort: TableParams = {};
  private filter$ = new Subject<string>();
  private tableParamsSubscription: Subscription;
  private destroy$ = new Subject();
  private get routeData() { return this.route.snapshot.data }

  // TODO martinbarnas 03/04/2020: make it type safe
  private dataSourceFactory: DataSourceFactory<any>;

  constructor(
    private route: ActivatedRoute,
    @Optional() @Inject(TABLE_PARAMS_STORAGE_KEY) private tableParamsStorageKey: string,
    private sessionStoreService: SessionStoreService,
  ) {
    if (this.routeData.defaultSortBy) {
      const defs: TableParams = {
        sortBy: this.routeData.defaultSortBy,
        sortDirection: this.routeData.defaultSortDirection,
      }
      
      this.defaultSort = defs;
    }

    this.setupTableParamsStorageKey();
  }

  public composeAndSetupTableParamsStorageKey(router: Router) {
    this.tableParamsStorageKey = router.url.substr(1);
    this.setupTableParamsStorageKey();
  }

  private setupTableParamsStorageKey() {
    if (this.tableParamsStorageKey) {
      try {
        const storedTableParams = this.sessionStoreService.get(this.tableParamsStorageKey);
        if (storedTableParams) {
          const params = { ...initialTableParams, ...this.defaultSort, ...storedTableParams };
          this.tableParams$.next(params);
        }
      } catch (e) {
      }

      fromEvent(window, 'scroll').pipe(debounceTime(100), takeUntil(this.destroy$))
        .subscribe(() => {
          const tableParams = this.sessionStoreService.get(this.tableParamsStorageKey);
          tableParams.scroll = window.scrollY;
          this.sessionStoreService.set(this.tableParamsStorageKey, tableParams);
        });
    }
  }

  public sortByRequest(sortBy: string) {
    const tableParams: TableParams = { sortBy };

    if (sortBy == this.tableParams.sortBy) {
      if (this.tableParams.sortDirection == TableSorting.DESC) {
        tableParams.sortDirection = undefined;
      } else {
        tableParams.sortDirection = TableSorting.DESC;
      }
    } else {
      tableParams.sortDirection = undefined;
    }

    this.changeTableParams(tableParams);
  }

  public setDefaultSortBy(sortBy: string, sortDirection?: TableSorting) {
    this.defaultSort.sortBy = sortBy;
    this.defaultSort.sortDirection = sortDirection || TableSorting.ASC;

    if (!this.tableParams.sortBy) {
      this.changeTableParams({ sortBy, sortDirection: this.defaultSort.sortDirection });
    }
  }

  public pageSizeRequest(pageSize: number) {
    if (pageSize != this.tableParams.pageSize) {
      this.changeTableParams({ pageSize });
    }
  }

  public pageRequest(page: number, infinite?: boolean) {
    if (page != this.tableParams.page) {
      const _data = infinite
        ? this._data
        : null;

      this.changeTableParams({ page, _data });
    }
  }

  public filterRequest(filter: string) {
    this.filter$.next(filter);
  }

  // FIXME martinbarnas 03/04/2020: make it type safe
  public setDataSourceFactory(factory: DataSourceFactory<any>) {
    this.dataSourceFactory = factory;

    if (this.tableParamsSubscription) {
      this.tableParamsSubscription.unsubscribe();
      this.tableParamsSubscription = undefined;
    } else {
      this.filter$.pipe(takeUntil(this.destroy$), debounceTime(500)).subscribe(filter => {
        this.changeTableParams({ filter, page: 1 });
      })
    }

    this.tableParamsSubscription = this.tableParams$.pipe(
        map(params => [params, this.tableParamsToQueryParams(params)]),
        switchMap(([params, tableParams]) => of(tableParams).pipe(switchMap(params => this.dataSourceFactory(params)), catchError(err => { console.log("Error in TableSettingsService: ", err); return of(undefined); })),
          ([params, tableParams], data) => [params, data]
        )
      )
      // FIXME martinbarnas 03/04/2020: make it type safe
      .subscribe(([params, result]) => {
        if (result) {
          this.totalElements = result.totalElements;
          this.totalPages = result.totalPages;

          const data: any[] = [...(params._data || []), ...result.content];

          this._data = data;
          this.data$.next(data);

          !this.noScrollTop && setTimeout(() => {
            window.scrollTo(0, this.tableParams.scroll);
          });
        } else {
          this.data$.next(undefined);
        }
      });
  }

  public changeTableParams(params: TableParams) {
    let tableParams = { ...initialTableParams, ...this.defaultSort, ...this.tableParams, ...{ scroll: null }, ...params };

    this.tableParams$.next(tableParams);

    if (this.tableParamsStorageKey) {
      const params = {...tableParams};
      delete params._data;

      this.sessionStoreService.set(this.tableParamsStorageKey, params);
    }
  }

  public refresh() {
    const params = {...this.tableParams, page: 1 };
    this.tableParams$.next(params);
  }

  public ngOnDestroy() {
    this.tableParamsSubscription && this.tableParamsSubscription.unsubscribe();
    this.destroy$.next();
  }

  private tableParamsToQueryParams(tableParams: TableParams): Params {
    const params: Params = {};

    ["filter", "pageSize", "page", "sortBy", "sortDirection"].forEach(key => {
      if (tableParams[key]) {
        params[key] = tableParams[key]
      }
    });

    return params;
  }
}
