import { Injectable } from "@angular/core";
import { FilteringItemDefinition, FilteringService, itemsToFilteringItemDefinitions, mapToFilteringItemDefinitions } from "../../../classes/filtering-service.class";
import { UserApiService } from "../../../services/user-api.service";
import { UserSimple } from "../../../../../../projects/client/src/app/fact-find/services/fact-find-api.service";
import { RemortgageStatusName } from "../remortgage.enums";
import { Router } from "@angular/router";

const stageDefinitions: FilteringItemDefinition[] = [
  {
    type: "stage",
    typeLabel: "Milestone",
    value: "TWELVE",
    valueLabel: "12 months",
  },
  {
    type: "stage",
    typeLabel: "Milestone",
    value: "NINE",
    valueLabel: "9 months",
  },
  {
    type: "stage",
    typeLabel: "Milestone",
    value: "FIVE",
    valueLabel: "5 months",
  },
  {
    type: "stage",
    typeLabel: "Milestone",
    value: "THREE",
    valueLabel: "3 months",
  },
  {
    type: "stage",
    typeLabel: "Milestone",
    value: "ONE",
    valueLabel: "Less than 1 month",
  },
]

const toggleDefinitions: FilteringItemDefinition[] = [
  {
    type: "toggle",
    typeLabel: "Toggle",
    value: "true",
    valueLabel: "yes",
  }
]

const unassignedDefinitions: FilteringItemDefinition[] = [
  {
    type: "unassigned",
    typeLabel: "Unassigned",
    value: "true",
    valueLabel: "Yes",
  }
]

const productEndDateDefinitions: FilteringItemDefinition[] = [
  {
    type: "productEndDateFrom",
    typeLabel: "Product End Date From",
    dataType: "date",
    value: "",
    valueLabel: "",
  },
  {
    type: "productEndDateTo",
    typeLabel: "Product End Date To",
    dataType: "date",
    value: "",
    valueLabel: "",
  },
]

@Injectable()
export class RemortgageFilteringService extends FilteringService {
  public isLoading = false;

  constructor(
    private router: Router,
    private userApiService: UserApiService,
  ) {
    super();
    this.initializeFiltering();
    this.initializeUrlUpdating();
  }

  private async initializeFiltering() {
    this.isLoading = true;

    const advisors = await this.userApiService.getMortgageAdvisors();
    const advisorDefinitions = itemsToFilteringItemDefinitions(advisors, (advisor: UserSimple) => ({ value: advisor.id, valueLabel: advisor.fullName }), "advisor", "Advisor");
    const remortgageStatusDefinitions = mapToFilteringItemDefinitions(RemortgageStatusName, "remortgage-status", "Status");

    const definitions = [
      ...stageDefinitions,
      ...advisorDefinitions,
      ...remortgageStatusDefinitions,
      ...unassignedDefinitions,
      ...productEndDateDefinitions,
    ];

    this.setFilteringItemDefinitions(definitions);
    this.isLoading = false;
  }

  private async initializeUrlUpdating() {
    this.activeFilteringItemsSerialized$.subscribe(filters => {

      const queryParams = filters
        ? { filters }
        : { };

      this.router.navigate([], { queryParams, replaceUrl: true });
    });
  }
}
