import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '../../../../../projects/client/src/app/shared/services/toast.service';
import { fadeAnimation, sidepaneMove } from "../../shared/animations/sidepane-animation";
import { SidepaneHost } from './sidepane-helper.class';

@Component({
  selector: 'hf-sidepane',
  templateUrl: './sidepane.component.html',
  styleUrls: ['./sidepane.component.scss',
    '../../styles/sidepanes.partial.scss'],
  animations: [sidepaneMove, fadeAnimation],
  // host: { '[@sidepaneMove]': 'true' },
})
export class SidepaneComponent {
  public get sh() { return this.sidepaneHost.sh }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
    private sidepaneHost: SidepaneHost,
  ) { 
    // console.log("host:", this.sidepaneHost);

    this.sidepaneHost.sh.goBack = () => this.onBackClicked();
    this.sidepaneHost.sh.toastService = this.toastService;
  }

  public onBackClicked() {
    this.router.navigate([".."], { relativeTo: this.route });
  }

  public onSubmitClicked() {
    this.sidepaneHost.spOnSubmit();
  }
}
