import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomRadioChecksFieldComponent } from './custom-radio-checks-field.component';

@NgModule({
  declarations: [
    CustomRadioChecksFieldComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule
  ],
  exports: [
    CustomRadioChecksFieldComponent
  ]
})

export class CustomRadioChecksFieldModule {
}
