<div class="hf-information-box-container">
  <div class="hf-information-box-title">
    {{heading}}
    <div *ngIf="lastUpdateVisible"
         class="last-update">
      {{lastUpdateValue | timeDifference:lastUpdateValue}}
    </div>
  </div>
  <div class="hf-information-box-value">
    <ng-container *ngIf="isCurrency && !valueTemplate">{{value.currency | currency:'GBP':undefined:'1.0-2'}}</ng-container>
    <ng-container *ngIf="!isCurrency && !valueTemplate">{{outputValue}}</ng-container>

    <ng-container *ngTemplateOutlet="valueTemplate">
    </ng-container>
  </div>
  <div *ngIf="actionVisible"
       class="hf-information-box-action">
    <hf-custom-button [style]="{width: '100%'}"
                      [class.disabled]="action.disabled"
                      type="button"
                      class="button-card"
                      [disabled]="action.disabled"
                      [label]="action.label"
                      [callable]="!!actionCallableApi"
                      (onClick)="this.action.callback()"
                      [callableApi]="actionCallableApi">
    </hf-custom-button>
  </div>
</div>
