<div class="overlay" (click)="onBack()" [@fadeAnimation]></div>

<div class="sidepane-content">
  <div class="template-header flex-space-between-layout">
    <h1>Select Country Code</h1>
    <hf-custom-button type="button"
                      icon="close"
                      iconPos="center"
                      class="button-plain-icon"
                      (click)="onBack()">
    </hf-custom-button>
  </div>
  <div class="template-main phone-prefix-template-main-override">
    <form>
      <hf-custom-text-field [listSearchMode]="true"
                            [group]="searchBoxFormGroup"
                            listSearchModeControlName="prefixFilter"
                            fieldName="prefixFilter"
                            autocomplete="off"
                            type="text"
                            placeholder="Search Countries …"
                            icon="search"
                            preventValidation="true">
      </hf-custom-text-field>
      <div class="prefixes-container">
        <ng-container
          *ngFor="let prefix of prefixes | inputFieldTextSearch:searchBoxFormGroup.get('prefixFilter').value:'country'">
          <div class="hf-radio-element"
               [ngClass]="{'is-selected': prefixService.phonePrefixActiveIndex == prefix.id}">
            <div class="hf-radio-wrapper">
              <input type="radio"
                     name="phonePrefix"
                     (change)="onPrefixSelected(prefix)"
                     [value]="prefix.id"
                     [checked]="prefixService.phonePrefixActiveIndex == prefix.id"
                     [id]="prefix.id">
              <label [for]="prefix.id">
                <span class="radio">{{prefix.country}}</span>
                <span class="prefix">{{prefix.prefix}}</span></label>
            </div>
          </div>
        </ng-container>
      </div>
    </form>
  </div>

  <div class="template-footer">
    <hf-custom-button type="submit"
                      label="Save"
                      class="button-primary"
                      (onClick)="onSubmit()">
    </hf-custom-button>
    <hf-custom-button type="button"
                      label="Cancel"
                      class="button-secondary"
                      (click)="onBack()">
    </hf-custom-button>
  </div>
</div>
