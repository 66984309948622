import { Component, Input } from '@angular/core';

@Component({
  selector: 'hf-blue-link-button',
  templateUrl: './blue-link-button.component.html',
  styleUrls: ['./blue-link-button.component.scss']
})
export class BlueLinkButtonComponent {
  @Input()
  icon: string;

  @Input()
  isLoading = false;
}
