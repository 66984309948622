<div class="header">
  <div *ngFor="let tab of tabPanels" class="tabPanel" [class.active]="tab.key === activeKey" (click)="onTabPanelClicked(tab)">
    {{tab.label}}
    <span *ngIf="tab.badge" class="badge">{{tab.badge}}</span>
  </div>
</div>

<div class="content">
  <ng-container *ngTemplateOutlet="getActiveTabPanel(activeKey)?.content"></ng-container>
</div>


