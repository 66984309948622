<div (click)="onBack()" class="overlay" [@fadeAnimation]></div>

<div class="sidepane-content">
  <div class="flex-space-between-layout template-header">
    <h1>Select Applicants</h1>
    <hf-custom-button type="button" class="button-plain-icon" icon="close" iconPos="center" (onClick)="onBack()">
    </hf-custom-button>
  </div>

  <div class="template-main">
    <hf-custom-spinner *ngIf="isLoading" sidepaneLoading="true"></hf-custom-spinner>

    <form *ngIf="!isLoading" [formGroup]="form">
      <hf-custom-selector formControlName="applicant" [items]="applicants" [compareWith]="compareFn" [multi]="true">
        <ng-template #itemTemplate let-item>
          <div class="item-template">
            <div class="name">
              {{ item.fullName }}
              {{ item.lead ? '(Lead)' : '(Sub)' }}
            </div>
            <div class="contact">{{ item.email }}</div>
            <div class="contact phone">
              {{ item.phonePrefix }} {{ item.phone }}
              <!-- <hf-named-icon icon="whatsapp-icon.svg"></hf-named-icon> -->
            </div>
          </div>
        </ng-template>
      </hf-custom-selector>
    </form>
  </div>

  <div class="template-footer">
    <hfc-button type="primary" [disabled]="form.invalid" (click)="onSubmit()">Select</hfc-button>
    <hfc-button type="secondary" (click)="onBack()">Cancel</hfc-button>
  </div>
</div>
