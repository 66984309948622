import { CurrencyPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ExperianCreditSearchReportDTO } from '../../../../../../services/experian-credit-report-api.service';

@Component({
  selector: 'hf-mortgage-category-details',
  templateUrl: './mortgage-category-details.component.html',
  styleUrls: ['./mortgage-category-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CurrencyPipe]
})
export class MortgageCategoryDetailsComponent {
  @Input()
  public report: ExperianCreditSearchReportDTO;
}
