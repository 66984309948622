import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { Confirmation, DialogService } from '../../../../../../projects/client/src/app/shared/services/dialog.service';
import { ToastService } from '../../../../../../projects/client/src/app/shared/services/toast.service';
import { ApiService } from "../../../services/api.service";
import { ClientApiService } from '../../../services/client-api.service';
import { DocumentTitleService } from '../../../services/document-title.service';
import { TemplateService } from "../../../services/template.service";
import { MORTGAGE_STATES, PRODUCT_ROLE, PRODUCT_TYPE } from "../../../utils/variables.data";
import { MortgagesService } from "../../mortgages/mortgages.service";
import { ClientsService } from "../clients.service";
import { Subject } from 'rxjs';
import { AddMortgageService } from '../../mortgages/add-mortgage/add-mortgage.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'hf-client-dashboard',
  templateUrl: 'client-dashboard.component.html',
  styleUrls: ['client-dashboard.component.scss']
})

export class ClientDashboardComponent implements OnInit, OnDestroy {

  public clientId;
  public clientData;
  private updateInfoSub;

  public PRODUCT_ROLE = PRODUCT_ROLE;
  public PRODUCT_TYPE = PRODUCT_TYPE;

  private destroy$ = new Subject();

  constructor(
    public templateService: TemplateService,
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute,
    private clientsService: ClientsService,
    private clientApiService: ClientApiService,
    private router: Router,
    private mortgagesService: MortgagesService,
    private documentTitleService: DocumentTitleService,
    private dialogService: DialogService,
    private toastService: ToastService,
    private addMortgageService: AddMortgageService,
  ) {
    this.clientId = this.activatedRoute.snapshot.params['id'];

    this.updateInfoSub = this.clientsService.updateInfo.subscribe(() => {
      this.loadData();
    })

    this.addMortgageService.added$.pipe(takeUntil(this.destroy$)).subscribe(() => this.loadData());
  }

  public async onOptOutClicked() {
    const { creditSearchOptOut } = this.clientData;
    const confirmation: Confirmation = {
      title: creditSearchOptOut
        ? "Client Report Opt In"
        : "Client Report Opt Out",
      message: creditSearchOptOut
        ? "Opting in will allow an Experian Credit Report to run on this client."
        : "Opting out will prevent an Experian Credit Report from running on this client",
      acceptLabel: creditSearchOptOut
        ? "Confirm Opt In"
        : "Confirm Opt Out",
      acceptButtonType: "primary"
    };

    const doOpting = async () => {
      try {
        await this.clientApiService.changeClientCreditSearchOptOutOption(this.clientId);
        this.loadData();
      } catch (e) {
        this.toastService.add("Error opting client", "error", e);
      }
    }

    this.dialogService.confirmAndDo(confirmation, doOpting);
  }

  ngOnInit() {
    this.loadData();
  }

  private async loadData() {
    this.clientData = await this.apiService.get('client', 'getDashboardData?clientId=' + this.clientId).toPromise();
    this.documentTitleService.setTitleParams({ client: this.clientData.fullName });
  }

  public getStateType(state) {
    return MORTGAGE_STATES[state];
  }

  public getProductUrl(row) {
    if (row.type === "PROTECTION") {
      return "protection";
    }

    if (row.dashboardType === "MORTGAGE") {
      return "mortgages";
    }

    return "remortgages";
  }
  
  public getReference(row: any): string {
    return [row.yourkeysReferenceId, row.reference]
      .filter(v => !!v)
      .join(" - ");
  }

  public ngOnDestroy() {
    this.updateInfoSub && this.updateInfoSub.unsubscribe();
    this.documentTitleService.clearTitleParams();
    this.destroy$.next();
  }

  public onAddMortgage() {
    this.router.navigate(['./addMortgage'], { relativeTo: this.activatedRoute });
    setTimeout(() => {
      this.mortgagesService.mortgageForm.get('client').patchValue(this.clientData);
    }, 200);
  }

  public onAddProtection() {
    this.router.navigate(['./addProtection'], { relativeTo: this.activatedRoute });
  }

  public onBack() {
    this.router.navigate(['./..'], {
      relativeTo: this.activatedRoute
    });
  }
}
