<div (click)="onBack()" class="overlay" [@fadeAnimation]></div>

<div class="sidepane-content">
  <div class="flex-space-between-layout template-header">
    <h1>Request Document</h1>
    <hf-custom-button type="button" class="button-plain-icon" icon="close" iconPos="center" (onClick)="onBack()">
    </hf-custom-button>
  </div>

  <div class="template-main">
    <form [formGroup]="form">
      <div class="hf-selector">
        <label>Client</label>
        <div *ngIf="!client"
          class="hf-selector-container hf-selector-has-value" [routerLink]="['./selectClient']">
          <div class="hf-selector-label-area">
            <div class="title">Select Client</div>
          </div>
          <i class="material-icons chevron-icon">chevron_right</i>
        </div>

        <ng-container *ngIf="client">
          <div class="hf-selector-container hf-selector-has-value hf-selector-black-color"
            [routerLink]="['./selectClient']">
            <div class="hf-selector-content-area">
              <div class="title">{{client.fullName}}</div>
            </div>
            <i class="material-icons chevron-icon">chevron_right</i>
          </div>
        </ng-container>
      </div>

      <hf-selector-link *ngIf="handovers?.length > 0"
        label="Related Application (Optional)"
        placeholder="Select Application"
        [value]="value._handover?.lender"
        (click)="onSelectLender()"
        style="display: block; margin-top: 8px; margin-bottom: 12px">
      </hf-selector-link>

      <label>Document Type</label>

      <hf-selector-link *ngIf="!form.value.requestedTypes?.length" placeholder="Select Document Type" style="display: block; margin-bottom: 12px"
        (click)="onSelectDocumentTypeClicked()">
      </hf-selector-link>

      <hf-selector-link *ngFor="let document of form.value.requestedTypes" placeholder="Select Document Type" style="display: block; margin-bottom: 12px"
        [value]="document.otherTypeLabel || map.get(document.type)"
        (click)="onSelectDocumentTypeClicked()">
      </hf-selector-link>

      <!-- <div class="hf-selector">
        <label>Document Type</label>
        <div class="hf-selector-container hf-selector-has-value" [class.hf-selector-black-color]="!!getDocumentType()"
          [routerLink]="['./selectDocumentType']">
          <div class="hf-selector-label-area">
            <div class="title">{{getDocumentTypeName()}}</div>
          </div>
          <i class="material-icons chevron-icon">chevron_right</i>
        </div>
      </div> -->

      <!-- <ng-container *ngIf="isOther()">
        <label>Specify Document Type</label>
        <hf-custom-text-field type="text" [group]="form" inputFormControlName="otherTypeLabel">
        </hf-custom-text-field>
      </ng-container> -->

      <label>Message To Client</label>
      <hf-textarea [group]="form" inputFormControlName="messageToClient"></hf-textarea>

      <label>Private Notes</label>
      <hf-textarea [group]="form" inputFormControlName="internalNotes">
      </hf-textarea>
    </form>
  </div>

  <div class="template-footer">
    <hf-custom-button type="submit"
                      class="button-primary"
                      label="Request Document"
                      [disabled]="form.invalid"
                      [callable]="true"
                      (onClick)="onRequest()">
    </hf-custom-button>

    <hf-custom-button type="button"
                      class="button-secondary"
                      label="Cancel"
                      (onClick)="onBack()">
    </hf-custom-button>
  </div>
</div>

<div class="sidepane-child-anchor">
  <router-outlet></router-outlet>
</div>
