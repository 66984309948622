<div class="heading">
  Applicants Details
</div>

<ng-container *ngFor="let applicant of mortgage.applicants">
  <div class="subheading">
    {{ applicant.lead ? 'Lead Applicant' : 'Sub Applicant' }}
    -
    {{ applicant.fullName }}
  </div>

  <hf-details-container>
    <hf-details-area>
      <hf-details-row label="Client Email" [text]="applicant.email"></hf-details-row>
      <hf-details-row label="Client Phone" [text]="applicant.phonePrefix + ' ' + applicant.phone"></hf-details-row>
    </hf-details-area>

    <hf-details-area>
      <hf-details-row *ngIf="applicant.address" label="Correspondence Address" text=" ">
        {{ applicant.address.house }} {{ applicant.address.street }} <br>
        <ng-container *ngIf="applicant.address.line2">{{ applicant.address.line2}} <br></ng-container>
        {{ applicant.address.city }}
      </hf-details-row>
    </hf-details-area>

    <hf-details-area>
      <hf-details-row label="Credit Report Opt Out" [text]="applicant.creditSearchOptOut ? 'Opted Out' : 'Opted In'">
        <hf-icon-button class="change-advisor-btn" icon="swap_horizontal_circle" (click)="onOptOutClicked()"></hf-icon-button>
      </hf-details-row>
      <hf-details-row label="Contact By" [text]="formatContactBy(mortgage.contactByEmail, mortgage.contactByPhone, mortgage.contactByMessage)"></hf-details-row>
    </hf-details-area>
  </hf-details-container>
</ng-container>
