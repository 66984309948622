import { Component, Inject, InjectionToken, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { getUkPhoneValidator } from '../../../../../../projects/client/src/app/shared/functions/uk-phone.validator';
import { Confirmation, DialogService } from '../../../../../../projects/client/src/app/shared/services/dialog.service';
import { ToastService } from '../../../../../../projects/client/src/app/shared/services/toast.service';
import { UserSimple } from '../../../models';
import { Client } from '../../../models/client';
import { PhonePrefixService } from '../../../modules/introducers/select-phone-prefix/phone-prefix.service';
import { SelectDateAndTimeService } from '../../../modules/mortgages/select-date-and-time/select-date-and-time.service';
import { AppointmentApiService, AppointmentFormDTO } from '../../../services/appointment-api.service';
import { Environment } from '../../../services/environment.service';
import { SidepaneService } from '../../../services/sidepane-service';
import { fadeAnimation, sidepaneMove } from "../../../shared/animations/sidepane-animation";
import { FileControlsConfigBuilder } from '../../../shared/fileuploader/utils/fileuploader.utils';
import { HfValidators } from '../../../utils/form.validators';
import { APPOINTMENT_TYPES, LOCATION_TYPES } from "../../../utils/variables.data";
import { SelectAdvisorForAssignmentService } from '../select-advisor-for-assignment/select-advisor-for-assignment.service';
import { Appointment, ArrangeAppointmentService } from './arrange-appointment.service';

export type ArrangeAppointmentComponentArrangeAppointmentFn = (appointment: AppointmentFormDTO, routeParamMap: Params, appointmentApiService: AppointmentApiService) => Promise<void>

export const ARRANGE_APPOINTMENT_COMPONENT_ARRANGE_APPOINTMENT_FN = new InjectionToken<ArrangeAppointmentComponentArrangeAppointmentFn>('ARRANGE_APPOINTMENT_COMPONENT_ARRANGE_APPOINTMENT_FN');

export interface ArrangeAppointmentConfig {
  client?: any;
  currentAdvisor?: UserSimple;
  currentAppointment?: Appointment;

  editMode?: boolean;

  isFactFindRequestAllowed?: boolean;

  noSubmitMode?: boolean;
  initialAppointment?: Appointment;
}

@Component({
  selector: 'hf-arrange-appointment',
  templateUrl: './arrange-appointment.component.html',
  styleUrls: ['./arrange-appointment.component.scss',
    '../../../styles/sidepanes.partial.scss'],
  animations: [sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove]': 'true' }
})
export class ArrangeAppointmentComponent implements OnDestroy {
  public appointmentTypes = APPOINTMENT_TYPES;
  public locationTypes = LOCATION_TYPES;

  public form: FormGroup;

  public editMode: boolean = false;
  public isLoading: boolean = false;
  public isSubmitting: boolean = false;

  public get client(): Client { return this.config.client }
  public get noSubmitMode() { return this.config.noSubmitMode }
  public get saveAction() { return this.route.snapshot.data.saveAction }

  private storeConfig: ArrangeAppointmentConfig;
  public get config() { return this.route.snapshot.data.config ? this.storeConfig : this.arrangeAppointmentService }

  private destroy$ = new Subject();

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    private sidepaneService: SidepaneService,
    private selectDateAndTimeService: SelectDateAndTimeService,
    private phonePrefixService: PhonePrefixService,
    private appointmentApiService: AppointmentApiService,
    private arrangeAppointmentService: ArrangeAppointmentService,
    private selectAdvisorForAssignmentService: SelectAdvisorForAssignmentService,
    private toastService: ToastService,
    private environment: Environment,
    private store: Store,
    @Inject(ARRANGE_APPOINTMENT_COMPONENT_ARRANGE_APPOINTMENT_FN) private arrangeAppointmentFn: ArrangeAppointmentComponentArrangeAppointmentFn
  ) {
    this.createForm();

    const selector = this.route.snapshot.data.config;
    if (selector) {
      this.store.select<ArrangeAppointmentConfig>(selector).subscribe(config => {
        this.storeConfig = config;
        this.storeConfig && this.initialize();

        if (this.storeConfig) {
          this.isLoading = false;
        } else {
          this.isLoading = true;
        }
      });
    } else {
      this.initialize();
    }
  }

  private initialize() {
    if (this.config.currentAdvisor) {
      this.form.get("appointmentHost").patchValue(this.config.currentAdvisor);
    }

    if (!(this.config.isFactFindRequestAllowed && this.environment.requestFactFindEnabled)) {
      this.form.get('sendFactFind').disable();
    }

    if (this.config.editMode) {
      this.loadAppointment(this.config.currentAppointment.id);

      this.editMode = true;
    }

    if (this.config.initialAppointment) {
      this.form.patchValue(this.config.initialAppointment);
    }
  }

  private async loadAppointment(appointmentId: string) {
    this.isLoading = true;

    this.form.patchValue(await this.appointmentApiService.getAppointment(appointmentId));

    this.isLoading = false;
  }

  public setDifferentPhoneNumber() {
    this.form.get('otherClientPhone').setValue(!this.form.value.otherClientPhone);
  }

  public async onBack() {
    if (this.form.dirty && !await this.sidepaneService.confirmDiscardChanges()) {
      return;
    }

    this.goBack();
  }

  public goBack() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

  public async onCancelClicked() {
    const confirmation: Confirmation = {
      title: "Cancel Appointment",
      message: "Are you sure you want to cancel this appointment? The client will be notified.",
      acceptLabel: "Cancel Appointment",
      rejectLabel: "Abort"
    };

    if (!await this.dialogService.confirm(confirmation)) {
      return;
    }

    this.cancelAppointment();
  }

  public async cancelAppointment() {
    try {
      await this.appointmentApiService.cancelAppointment(this.config.currentAppointment.id);
      this.toastService.add("Appointment cancelled.");
      this.arrangeAppointmentService.appointmentSelected$.next();
      this.goBack();
    } catch (e) {
      this.toastService.add("Failed to cancel appointment. Please try again.", "error", e);
    }
  }

  public async onSubmit() {
    if (!this.form.valid) {
      return;
    }

    if (this.config.noSubmitMode) {
      this.arrangeAppointmentService.appointmentSelected$.next(this.form.value);
      this.goBack();
      return;
    }

    this.isSubmitting = true;

    try {
      const value = {...this.form.value};
      await this.saveAppointment(value);
      this.toastService.add("Appointment arranged.");
      this.arrangeAppointmentService.appointmentSubmitted$.next();
      this.saveAction && this.store.dispatch(new this.saveAction());
      this.goBack();
    } catch (e) {
      this.toastService.add("Failed to arrange appointment. Please try again. ", "error", e);
    }

    this.isSubmitting = false;
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }

  private async saveAppointment(appointment: AppointmentFormDTO) {
    return this.editMode
      ? this.appointmentApiService.changeAppointment(this.config.currentAppointment.id, appointment)
      : this.arrangeAppointmentFn(appointment, this.route.snapshot.paramMap, this.appointmentApiService);
  }

  private createForm() {
    this.form = this.fb.group({
      id: [null],
      appointmentDate: [undefined, [Validators.required, HfValidators.minDate(new Date())]],
      type: ['', Validators.required],
      otherClientPhone: [false, Validators.required],
      otherPhone: ['', [Validators.required, getUkPhoneValidator("otherPhonePrefix")]],
      otherPhonePrefix: ['+44', Validators.required],
      sendFactFind: [false],
      location: ['OFFICE', Validators.required],
      address: ['', Validators.required],
      appointmentHost: this.fb.group({
        id: ['', Validators.required],
        fullName: ['']
      }),
      journal: this.fb.group({
        connectedDocument: this.fb.group({
          attachment: this.fb.group(
            new FileControlsConfigBuilder()
            .maxFileSizeEmail()
            .build())
          ,
        }),
        internalNotes: [''],
        messageToClient: ['']
      })
    });

    this.form.get('location').disable();
    this.form.get('address').disable();
    this.form.get('otherClientPhone').disable();
    this.form.get('otherPhonePrefix').disable();
    this.form.get('otherPhone').disable();

    this.selectAdvisorForAssignmentService.advisorSelected$.pipe(takeUntil(this.destroy$))
      .subscribe(advisor => this.form.get("appointmentHost").patchValue(advisor));

    this.selectDateAndTimeService.dateSelected$.pipe(takeUntil(this.destroy$))
      .subscribe(dateSelected => {
        const control = this.form.get(dateSelected.name);
        control && control.setValue(dateSelected.date);
      });

    this.form.get('type').valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(type => {
        if (type == 'IN_PERSON') {
          this.form.get('otherClientPhone').disable();
          this.form.get('otherPhone').disable();
          this.form.get('otherPhonePrefix').disable();
          this.form.get('location').enable();
          this.form.get('otherClientPhone').setValue(false);
        } else {
          this.form.get('otherClientPhone').enable();
          this.form.get('location').disable();
        }
      });

    this.form.get('location').valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(location => {
        if (location == 'OTHER') {
          this.form.get('address').enable();
        } else {
          this.form.get('address').disable();
        }
      });

    this.form.get("otherClientPhone").valueChanges
      .subscribe((other: boolean) => {
        if (other) {
          this.form.get('otherPhone').enable();
          this.form.get('otherPhonePrefix').enable();
        } else {
          this.form.get('otherPhone').disable();
          this.form.get('otherPhonePrefix').disable();
        }
      });

    this.phonePrefixService.phonePrefixUpdated.pipe(takeUntil(this.destroy$))
      .subscribe(prefix => this.form.get('otherPhonePrefix').setValue(prefix));
  }
}
