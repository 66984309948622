import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { subscribeLastActivatedRouteSnapshotData } from "../../../../projects/client/src/app/fact-find/functions/subscribe-last-activated-route-snapshot-data";
import {UserService} from "./user.service";

@Injectable({
  providedIn: "root"
})
export class NavigationService {
  public history: string[] = [];

  private backButtonUrl: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
  ) {
    subscribeLastActivatedRouteSnapshotData(router, route).subscribe(data => {
      if (data.backButtonUrl) {
        this.backButtonUrl = data.backButtonUrl;
      }
    });
  }

  public goToApp() {
    return this.router.navigate(["/app"]);
  }

  public goToUserDefault() {
    if (this.userService.hasPermission("DASHBOARD_ALL")) {
      return this.router.navigate(["app/dashboard/charts"]);
    }

    if (this.userService.isIntroducer()) {
      return this.router.navigate(["app/mortgages/introducer-active"]);
    }

    if (this.userService.getUser().userType === "ADVISOR_PROTECTION") {
      return this.router.navigate(["app/protection/initial-stage"]);
    }

    if (this.userService.getUser().userType === "CASE_PROGRESSION") {
      return this.router.navigate(["app/mortgages/applications-submitted"]);
    }

    return this.router.navigate(["app/mortgages"]);
  }

  public goToLogin(returnUrl?: string) {
    return this.router.navigate(["/login"], { queryParams: { returnUrl } });
  }

  public goToSetupMobileNumber() {
    return this.router.navigateByUrl("/login/setup-mobile-number");
  }

  public goToVerifyMobileNumber() {
    return this.router.navigate(["/login/verify-mobile-number"]);
  }

  public goToSetupAuthApp() {
    return this.router.navigateByUrl("/login/setup-auth-app");
  }

  public goToVerifyAuthCode() {
    return this.router.navigate(["/login/verify-auth-code"]);
  }

  public mortgageDashboardGoBack() {
    if (this.goBackIfPreviousUrlNot("app/mortgages")) {
      return;
    }

    if (this.backButtonUrl) {
      this.router.navigate([this.backButtonUrl]);

      return;
    }

    this.router.navigate(["app/mortgages"]);
  }

  public remortgageDashboardGoBack() {
    if (this.goBackIfPreviousUrlNot("app/remortgages")) {
      return;
    }

    if (this.backButtonUrl) {
      this.router.navigate([this.backButtonUrl]);

      return;
    }

    this.router.navigate(["app/remortgages"]);
  }

  public protectionDashboardGoBack() {
    if (this.goBackIfPreviousUrlNot("app/protection")) {
      return;
    }

    if (this.backButtonUrl) {
      this.router.navigate([this.backButtonUrl]);

      return;
    }

    this.router.navigate(["app/protection"]);
  }

  public goToProtection(protectionCaseId: string) {
    this.router.navigate(["app/protection", protectionCaseId]);
  }

  public goToMortgage(mortgageId: string, reload?: boolean) {
    const url = `app/mortgages/${mortgageId}`;

    window.location = url as any;
  }

  public goToIntroducer(introducerId: string) {
    this.router.navigate(["app/introducers", introducerId]);
  }

  private goBackIfPreviousUrlNot(previousUrl: string): boolean {
    if (this.history.length < 2) {
      return false;
    }

    const previousHistoryUrl = this.history[this.history.length - 2];
    if (previousHistoryUrl.includes(previousUrl)) {
      return false;
    }

    history.back();

    return true;
  }
}
