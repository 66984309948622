import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { clampExpandAnimation } from '../../../../../../projects/client/src/app/shared/animations/expand.animation';
import { JournalEntry } from '../../../models/journal-details';

@Component({
  selector: 'hf-message-journal-item-message',
  templateUrl: './message-journal-item-message.component.html',
  styleUrls: ['./message-journal-item-message.component.scss'],
  animations: [clampExpandAnimation],
})
export class MessageJournalItemMessageComponent {
  @Input()
  public item: JournalEntry;

  @Input()
  public showDate = false;

  @Input()
  public noSeeLess = false;

  @ViewChild('body') 
  public bodyEl: ElementRef;

  @ViewChild('bodyContainer') 
  public bodyContainerEl: ElementRef;

  public isBigger = false;
  public isOpen = false;
  public hasClampClass = true;


  public isSeeMoreVisible = false;
  public isSeeLessVisible = false;
  public anim = "clamp"

  public onSeeMoreClicked() {
    if (!this.isSeeMoreVisible) {
      return;
    }

    this.isOpen = true;
    this.isSeeMoreVisible = false;
    this.isSeeLessVisible = true;
    this.anim = "open";
    this.hasClampClass = false;
  }

  public onSeeLessClicked() {
    this.isSeeMoreVisible = true;
    this.isSeeLessVisible = false;
    this.anim = "clamp";
  }

  public onClampDone() {
    if (this.isSeeMoreVisible) {
      this.hasClampClass = true;
      this.isOpen = false;
    }
  }

  public ngAfterViewInit() {
    setTimeout(() => {
      const bodyHeight = this.bodyEl.nativeElement.clientHeight;
      const bodyContainerHeight = this.bodyContainerEl.nativeElement.clientHeight;

      // console.log("bodyHeight:", bodyHeight); 
      // console.log("bodyContainerHeight:", bodyContainerHeight);

      this.isBigger = bodyHeight > bodyContainerHeight;
      this.hasClampClass = this.isBigger;
      this.isSeeMoreVisible = this.isBigger;
    })
  }
}
