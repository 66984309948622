import { Component, ContentChild, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';

@Component({
  selector: 'hf-link-list',
  templateUrl: './link-list.component.html',
  styleUrls: ['./link-list.component.scss']
})
export class LinkListComponent implements OnChanges {
  @ContentChild("itemTemplate")
  public itemTemplate: TemplateRef<any>;

  @Input()
  public inputItemTemplate: TemplateRef<any>;

  @Input()
  public items: any[];
  
  @Input()
  public noChevron = false;

  @Output()
  public itemClicked = new EventEmitter<any>();

  @Input()
  public selectedItem: any;

  @Input()
  public clickCallback: (item: any) => boolean;

  public async onItemClicked(item: any) {
    const resolveCallback = async () => {
      if (this.clickCallback) {
        const cb = this.clickCallback;
        const result: boolean = await Promise.resolve(cb(item));

        return result;
      }

      return true;
    }

    const canSwitch = await resolveCallback();

    if (!canSwitch) return;

    this.selectedItem = item;
    this.itemClicked.emit(item);
  }

  public ngOnChanges(changes: SimpleChanges) {
    if ("items" in changes) {
      const items = changes["items"].currentValue;

      // if (items?.length) {
      //   this.selectedItem = items[0];
      // }
    }
  }
}
