import { Injectable } from '@angular/core';
import * as moment from 'moment';

import { ApiService } from './api.service';
import { toLocalDateISO } from '../../../../projects/client/src/app/shared/functions/to-local-date-iso';
import { map } from 'rxjs/operators';

export type AppointmentDTO = any;
export type AppointmentFormDTO = any;

@Injectable({ providedIn: "root" })
export class AppointmentApiService {
  private readonly API_NAME: string = 'appointment';

  constructor(private apiService: ApiService) { }

  public getAppointment(appointmentId: string): Promise<AppointmentDTO> {
    const params = { appointmentId };

    return this.apiService.get(this.API_NAME, 'getAppointment', { params }).pipe(
      map(appointment => {
        appointment.appointmentDate = moment(appointment.appointmentDate).toDate();
        return appointment;
      })
    ).toPromise();
  }

  public arrangeMortgageAppointment(mortgageId: string, appointment: AppointmentFormDTO) {
    const params = { mortgageId };

    this.convertAppointmentDateToLocalDateISO(appointment);

    return this.apiService.post(this.API_NAME, 'arrangeMortgageAppointment', appointment, { params }).toPromise();
  }

  public arrangeProtectionAppointment(protectionCaseId: string, appointment: AppointmentFormDTO): Promise<void> {
    const params = { protectionCaseId };

    this.convertAppointmentDateToLocalDateISO(appointment);

    return this.apiService.post(this.API_NAME, 'arrangeProtectionAppointment', appointment, { params }).toPromise();
  }

  public changeAppointment(appointmentId: string, appointment: AppointmentFormDTO): Promise<void> {
    const params = { appointmentId };

    this.convertAppointmentDateToLocalDateISO(appointment);

    return this.apiService.post(this.API_NAME, 'changeAppointment', appointment, { params }).toPromise();
  }

  public cancelAppointment(appointmentId: string): Promise<void> {
    const params = { appointmentId };

    return this.apiService.post(this.API_NAME, 'cancelAppointment', undefined, { params }).toPromise();
  }

  private convertAppointmentDateToLocalDateISO(appointment: AppointmentFormDTO) {
    appointment.appointmentDate = toLocalDateISO(appointment.appointmentDate, false);
  }
}
