import { trigger, transition, style, animate } from "@angular/animations";

export const expandAnimation = trigger("expandAnimation", [
  transition(":enter", [
    style({ opacity: 0, height: 0 }),
    animate("250ms ease", style({ opacity: 1, height: "*" })),
  ]),
  transition(":leave", [
    animate("250ms ease", style({ opacity: 0, height: 0 })),
  ]),
]);

export const clampExpandAnimation = trigger("clampExpandAnimation", [
  transition("clamp => open", [
    style({ height: "54px" }),
    animate("250ms ease", style({ height: "*" })),
  ]),
  transition("open => clamp", [
    style({ height: "*", overflow: "hidden" }),
    animate("250ms ease", style({ height: "54px" })),
  ]),
]);
