import { Component, Input } from '@angular/core';

@Component({
  selector: 'hf-projected-increase',
  templateUrl: './projected-increase.component.html',
  styleUrls: ['./projected-increase.component.scss']
})
export class ProjectedIncreaseComponent {
  @Input()
  public projectedIncrease: number;

  public get isVisible() { return typeof this.projectedIncrease !== "undefined" && this.projectedIncrease !== 0 }
  public get isIncreased() { return this.projectedIncrease > 0 }
}
