import { Component, Input, OnInit } from '@angular/core';
import { DateOrdinalPipe } from '../../../../../projects/client/src/app/shared/pipes/date-ordinal.pipe';
import { EditChangeDTO, EditChangesGroupDTO } from '../../services/mortgage-api.service';
import { EditChangesTypeName } from '../../utils/variables.data';

interface DecoratedEditChangeDTO extends EditChangeDTO {
}

@Component({
  selector: 'hf-edit-changes-group',
  templateUrl: './edit-changes-group.component.html',
  styleUrls: ['./edit-changes-group.component.scss'],
  providers: [DateOrdinalPipe]
})
export class EditChangesGroupComponent implements OnInit {
  @Input()
  public changesGroup: EditChangesGroupDTO;

  public changes: DecoratedEditChangeDTO[];
  public hasAnyLong: boolean;

  public EditChangesTypeName = EditChangesTypeName;

  public ngOnInit(): void {
    this.changes = this.decorateEditChanges(this.changesGroup.changes);
    this.hasAnyLong = this.changes.some(c => !this.isShort(c));
  }

  private decorateEditChanges(changes: EditChangeDTO[]): DecoratedEditChangeDTO[] {
    const transform = (value: string) => {
      value = value.replace(/^true$/, "Yes");
      value = value.replace(/^false$/, "No");

      value = value.replace(/^''$/, "N/A");

      return value;
    };

    return changes.map(change => ({
      ...change,
      from: transform(change.from),
      to: transform(change.to),
    }));
  }

  private isShort(change: EditChangeDTO) {
    const spacesFrom = change.from?.replace(/[^ ]/g, "").length;
    const spacesTo = change.to?.replace(/[^ ]/g, "").length;
    const isLong = spacesFrom > 1 || spacesTo > 1
      || (change.from?.length > 8 && spacesFrom > 0)
      || (change.to?.length > 8 && spacesTo > 0);

    return !isLong;
  }
}
