import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CalendarModule } from "primeng/calendar";
import { SharedModule } from '../shared.module';
import { CustomTextFieldModule } from "../custom-text-field/custom-text-field.module";
import { CustomDatepickerComponent } from './custom-datepicker.component';

@NgModule({
  declarations: [
    CustomDatepickerComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    CalendarModule,
    CustomTextFieldModule
  ],
  exports: [
    CustomDatepickerComponent
  ]
})

export class CustomDatepickerModule {

}
