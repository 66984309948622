import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {fadeAnimation, sidepaneMove} from '../../../shared/animations/sidepane-animation';
import { PHONE_PREFIXES } from '../../../utils/variables.data';
import {PhonePrefixService} from "./phone-prefix.service";

@Component({
  selector: 'hf-select-phone-prefix',
  templateUrl: './select-phone-prefix.component.html',
  styleUrls: ['./select-phone-prefix.component.scss',
    '../../../styles/sidepanes.partial.scss'],
  animations: [sidepaneMove, fadeAnimation],
  host: {'[@sidepaneMove]': 'true'}

})
export class SelectPhonePrefixComponent implements OnInit {

  public prefixes = [];
  public searchBoxFormGroup: FormGroup;
  public selectedPrefix:any;
  public phonePrefixActiveIndex:number;

  constructor(public router: Router,
              private route: ActivatedRoute,
              private fb:FormBuilder,
              public prefixService:PhonePrefixService) {
    this.prefixes = PHONE_PREFIXES;

    this.searchBoxFormGroup = this.fb.group({
      prefixFilter: []
    });
  }

  ngOnInit() {
  }

  onBack() {
    this.router.navigate(['./..'], {relativeTo:this.route});
  }

  onPrefixSelected(prefix) {
    this.phonePrefixActiveIndex = prefix.id;
    this.selectedPrefix = prefix.prefix;
  }

  onSubmit() {
    this.prefixService.phonePrefixActiveIndex = this.phonePrefixActiveIndex;
    this.prefixService.phonePrefixUpdated.emit(this.selectedPrefix);
    this.onBack();
  }
}
