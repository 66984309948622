<hf-sidepane [@sidepaneMove]>
  <header>
    Target Exchange Date
  </header>

  <body>
    <form [formGroup]="form" class="ev-sidepane-form">
      <div class="ev-sidepane-form-section">
        <div class="ev-sidepane-form-content">
          <div class="ev-sidepane-form-column-full-width">

            <div class="ev-form-field">
              <label>Target Exchange Date</label>
              <hf-date-input formControlName="targetExchangeDate" propertyName="targetExchangeDate"></hf-date-input>
            </div>

          </div>
        </div>
      </div>
    </form>
  </body>
</hf-sidepane>
