import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { MortgageDashboard } from '../../../models/mortgage-dashboard';
import { fadeAnimation, sidepaneMove } from '../../../shared/animations/sidepane-animation';
import { APPLICATION_STATE, APPLICATION_STATE_NAMES } from '../../../utils/variables.data';
import { isTransferOrAdvance } from '../create-application/create-application.component';
import { MortgagesService } from '../mortgages.service';

const transferStates: APPLICATION_STATE[] = ["COMPLETED", "MORTGAGE_OFFER_ISSUED", "NOT_PROCEEDING"];

const isExchanged = (mortgage: MortgageDashboard) => {
  const isBeyondExchanged = application => ["EXCHANGED", "COMPLETED"].includes(application.state);
  const { applications } = mortgage;

  return applications && applications?.some(isBeyondExchanged);
}

const hasExchangedStatus = (mortgage: MortgageDashboard) => {
  return mortgage?.clientCategory === "PURCHASE";
}

@Component({
  selector: 'hf-select-status',
  templateUrl: './select-status.component.html',
  styleUrls: [
    './select-status.component.scss',
    '../../../styles/sidepanes.partial.scss'
  ],
  animations: [sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove]': 'true' }
})
export class SelectStatusComponent implements OnInit, OnDestroy {
  public form = new FormGroup({
    state: new FormControl()
  });

  public states = Array.from(APPLICATION_STATE_NAMES.keys()).filter(status => status != "SUBMITTED");
  public APPLICATION_STATE_NAMES = APPLICATION_STATE_NAMES;

  private destroy$ = new Subject();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private mortgagesService: MortgagesService
  ) {
    this.mortgagesService.mortgageDashboardDataLoaded.pipe(take(1)).subscribe(() => {
      if (isTransferOrAdvance(this.mortgagesService.data)) {
        this.states = transferStates;
      }
      if (!hasExchangedStatus(this.mortgagesService.data)) {
        this.states = this.states.filter(state => state !== "EXCHANGED");
      }
      if (hasExchangedStatus(this.mortgagesService.data) && !isExchanged(this.mortgagesService.data)) {
        this.states = this.states.filter(state => state !== "COMPLETED");
      }
    });
  }

  public ngOnInit() {
    this.mortgagesService.statusSelected$.pipe(take(1))
      .subscribe(status => this.form.get("state").setValue(status))
    this.form.get("state").valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        this.mortgagesService.statusSelected$.next(value);
        this.onBack();
      });
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }

  public onBack() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }
}
