import { Component, Input } from '@angular/core';
import { environment } from "../../../../environments/environment";
import { TableSettingsService } from '../table-utils/table-settings.service';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'hf-mortgage-export-button',
  templateUrl: './mortgage-export-button.component.html',
  styleUrls: ['./mortgage-export-button.component.scss']
})
export class MortgageExportButtonComponent {
  @Input()
  public quickFilter: string;

  @Input()
  public introducerId: string;

  constructor(private tableSettingsService: TableSettingsService) { }

  public onClicked() {
    const params: { filter?: string, quickFilter?: string, introducerId?: string } = { };

    this.tableSettingsService.tableParams.filter && (params.filter = this.tableSettingsService.tableParams.filter);
    this.quickFilter && (params.quickFilter = this.quickFilter);
    this.introducerId && (params.introducerId = this.introducerId);

    const url = `${environment.apiUrl}servlet/mortgageExport?${new HttpParams({ fromObject: params })}`;

    window.open(url);
  }
}
