<div class="header">
  <div class="heading">
    {{ collaborativeDocumentsHeading }}
  </div>
</div>

<div *ngIf="data.collaborativeDocuments?.length" class="content">
  <div class="thumbnails-container">
    <hfc-document-thumbnail *ngFor="let document of data.collaborativeDocuments" [document]="document"></hfc-document-thumbnail>
  </div>
</div>
