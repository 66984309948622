import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'hfc-agree-and-sign-review-section',
  templateUrl: './agree-and-sign-review-section.component.html',
  styleUrls: ['./agree-and-sign-review-section.component.scss']
})
export class AgreeAndSignReviewSectionComponent {
  @Input()
  public showTooltip = true;

  @Input()
  public form: FormGroup;
}
