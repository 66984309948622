import { Component, Input } from '@angular/core';
import { Attachment } from '../../models/attachment';

@Component({
  selector: 'hf-banner-with-image',
  templateUrl: './banner-with-image.component.html',
  styleUrls: ['./banner-with-image.component.scss']
})
export class BannerWithImageComponent {
  @Input()
  public heading: string;

  @Input()
  public image: Attachment;

  @Input()
  public imageText: Attachment;
}
