import { Component, Input } from '@angular/core';
import { MORTGAGE_PRODUCT_END_TYPE } from '../../utils/variables.data';

export interface ProductEndDateInput {
  endDateType: MORTGAGE_PRODUCT_END_TYPE;
  endDate: Date;
}

@Component({
  selector: 'hf-product-end-date',
  templateUrl: './product-end-date.component.html',
  styleUrls: ['./product-end-date.component.scss']
})
export class ProductEndDateComponent {
  @Input()
  public data: ProductEndDateInput;
}
