import { CurrencyPipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { BenefitCategoryName } from '../../../../../models/benefit.dto';
import { ProtectionHandoverGeneralDTO } from '../../../../../models/protection-handover-general.dto';
import { ProtectionHandoverHelperService } from '../../../services/protection-handover-helper.service';

@Component({
  selector: 'hf-unmet-needs',
  templateUrl: './unmet-needs.component.html',
  styleUrls: ['./unmet-needs.component.scss'],
  providers: [
    CurrencyPipe,
  ],
})
export class UnmetNeedsComponent {
  @Input()
  public form: FormGroup;

  @Input()
  public isSubmitted: boolean;

  public get value() { return this.form.value as ProtectionHandoverGeneralDTO }
  public get unmetNeeds() { return this.control("unmetNeeds") as FormArray }
  public control(name: string) { return this.form.get(name) }
  public enabled(name: string) { return this.control(name).enabled }

  public BenefitCategoryName = BenefitCategoryName;

  constructor(
    public helper: ProtectionHandoverHelperService,
  ) { }
}
