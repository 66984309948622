import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../../shared/shared.module';
import { CustomButtonModule } from '../../../shared/custom-button/custom-button.module';
import { CustomTextFieldModule } from '../../../shared/custom-text-field/custom-text-field.module';
import { CustomSpinnerModule } from '../../../shared/custom-spinner/custom-spinner.module';
import { SelectIntroducerComponent } from './select-introducer.component';

@NgModule({
  declarations: [
    SelectIntroducerComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    CustomButtonModule,
    CustomSpinnerModule,
    CustomTextFieldModule
  ],
  exports: [
    SelectIntroducerComponent
  ],
})
export class SelectIntroducerModule {
}
