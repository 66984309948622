import { Component, Input } from '@angular/core';
import { rotateAnimation } from '../../modules/protection/components/protection-dashboard/fact-find/protection-dashboard-fact-find-navigation/protection-dashboard-fact-find-navigation.component';

@Component({
  selector: 'hf-expander',
  templateUrl: './expander.component.html',
  styleUrls: ['./expander.component.scss'],
  animations: [rotateAnimation]
})
export class ExpanderComponent {
  @Input()
  public heading: string;
  
  @Input()
  public iconUrl: string;

  @Input()
  public isOpen: boolean = false;
}
