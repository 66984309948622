import { Directive, ElementRef, HostBinding, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TableSettingsService } from './table-settings.service';

@Directive({ selector: '[hfTable]' })
export class TableDirective implements OnDestroy {
  @HostBinding('class.table-is-loading')
  public tableIsLoading: boolean = true;

  @HostBinding('class.table-no-results')
  public tableNoResults: boolean = false;

  private destroy$ = new Subject();

  constructor(
    public elementRef: ElementRef,
    private tableSettingsService: TableSettingsService,
  ) {
    this.tableSettingsService.tableParams$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.tableIsLoading = true;
    });

    this.tableSettingsService.data$.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.tableIsLoading = false;
      this.tableNoResults = !(data && data.length);
    })
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }
}
