import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { APPLICATION_STEP } from "../../../../../../../projects/client/src/app/fact-find/enums.module";
import { FactFindApiService, ReturnSection } from "../../../../services/fact-find-api.service";
import { ReturnFactFindService } from "../../../../shared-layout/side-panes/return-fact-find/return-fact-find.component";
import { MortgageDashboardFactFindService } from "../fact-find/mortgage-dashboard-fact-find.service";

@Injectable()
export class MortgageReturnFactFindService implements ReturnFactFindService {
  constructor(
    private factFindApiService: FactFindApiService,
    private mortgageDashboardFactFindService: MortgageDashboardFactFindService,
  ) { }

  public async markSectionReturned(routeSnapshot: ActivatedRouteSnapshot, formValue: ReturnSection): Promise<void> {
    const applicantId = routeSnapshot.queryParamMap.get("applicantId");
    const mortgageId = routeSnapshot.paramMap.get("id");
    const step = routeSnapshot.queryParamMap.get("step") as APPLICATION_STEP;
    const value: ReturnSection = { ...formValue, applicantId, step };

    await this.factFindApiService.markSectionReturned(mortgageId, value);

    this.mortgageDashboardFactFindService.reload$.next();
  }
}
