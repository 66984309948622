import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ButtonModule } from 'primeng/button';
import { SplitButtonModule } from 'primeng/splitbutton';
import { CustomButtonModule } from '../custom-button/custom-button.module';
import { CustomDropdownModule } from '../custom-dropdown/custom-dropdown.module';
import { CustomSidenavComponent } from './custom-sidenav.component';
import { IfPermissionModule } from '../../directives/if-permission/if-permission.module';

@NgModule({
  declarations: [
    CustomSidenavComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ButtonModule,
    SplitButtonModule,
    CustomDropdownModule,
    CustomButtonModule,
    IfPermissionModule
  ],
  exports: [
    ButtonModule,
    CustomSidenavComponent
  ]
})
export class CustomSidenavModule {

}
