<div class="item">
  <div class="left">
    <div class="stuffing first"></div>

    <div class="circle w20"></div>

    <div class="stuffing last background"></div>
  </div>

  <div class="right white">
    <div class="triangle"></div>

    <div class="line-container">
      <div class="line w235"></div>
      <div class="line w93 keep-right"></div>
    </div>

    <div class="line-container">
      <div class="circle" style="margin-right: 12px"></div>

      <div style="margin-top: 4px; margin-bottom: 10px">
        <div class="line-container">
          <div class="line w106"></div>
        </div>
        <div class="line-container">
          <div class="line w149"></div>
        </div>
        <div class="line-container center">
          <div class="circle w12"></div>
          <div class="line w49"></div>
        </div>
      </div>

    </div>

    <div class="line-container">
      <div class="line"></div>
    </div>
    <div class="line-container">
      <div class="line"></div>
    </div>
    <div class="line-container">
      <div class="line w248"></div>
    </div>

    <div class="line-container">
      <div class="line w93 keep-right"></div>
    </div>
  </div>
</div>

<div class="item">
  <div class="left">
    <div class="circle w20"></div>

    <div class="stuffing last background"></div>
  </div>

  <div class="right">
    <div class="line-container">
      <div class="line w235"></div>
      <div class="line w93 keep-right"></div>
    </div>

    <div class="line-container">
      <div class="line"></div>
    </div>
    <div class="line-container mt3">
      <div class="line"></div>
    </div>
    <div class="line-container mt3">
      <div class="line w323"></div>
    </div>
  </div>
</div>

<div class="item">
  <div class="left">
    <div class="stuffing first background"></div>

    <div class="circle w20"></div>

    <div class="stuffing last background"></div>
  </div>

  <div class="right white">
    <div class="line-container">
      <div class="line w235"></div>
      <div class="line w93 keep-right"></div>
    </div>

    <div class="line-container">
      <div class="line w451"></div>
    </div>
  </div>
</div>

<div class="item">
  <div class="left">
    <div class="stuffing first background"></div>

    <div class="circle w20"></div>

    <div class="stuffing last background"></div>
  </div>

  <div class="right white">
    <div class="line-container">
      <div class="line w235"></div>
      <div class="line w93 keep-right"></div>
    </div>

    <div class="line-container">
      <div class="line w451"></div>
    </div>
  </div>
</div>

<div class="item">
  <div class="left">
    <div class="stuffing first background"></div>

    <div class="circle w20"></div>

    <div class="stuffing last background"></div>
  </div>

  <div class="right white">
    <div class="line-container">
      <div class="line w235"></div>
      <div class="line w93 keep-right"></div>
    </div>


    <div class="line-container">
      <div class="circle" style="margin-right: 12px"></div>

      <div style="margin-top: 4px; margin-bottom: 10px">
        <div class="line-container">
          <div class="line w106"></div>
        </div>
        <div class="line-container">
          <div class="line w149"></div>
        </div>
        <div class="line-container center">
          <div class="circle w12"></div>
          <div class="line w49"></div>
        </div>
      </div>

    </div>

    <div class="line-container">
      <div class="line"></div>
    </div>
    <div class="line-container">
      <div class="line"></div>
    </div>
    <div class="line-container">
      <div class="line w248"></div>
    </div>

    <div class="line-container">
      <div class="line w93 keep-right"></div>
    </div>
  </div>
</div>

<div class="item">
  <div class="left">
    <div class="circle w20"></div>

    <div class="stuffing last"></div>
  </div>

  <div class="right">
    <div class="line-container">
      <div class="line w235"></div>
      <div class="line w93 keep-right"></div>
    </div>

    <div class="line-container">
      <div class="line"></div>
    </div>
    <div class="line-container mt3">
      <div class="line"></div>
    </div>
    <div class="line-container mt3">
      <div class="line w323"></div>
    </div>
  </div>
</div>
