export type GroupByCriteria = string | ((item: any) => string);

export function groupBy(arr: any[], criteria: GroupByCriteria) {
  return arr.reduce(function (obj, item) {
    const key = typeof criteria === 'function' ? criteria(item) : item[criteria];

    if (!(key in obj)) {
      obj[key] = [];
    }

    obj[key].push(item);

    return obj;
  }, {});
}
