import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UserPermission } from "../../models";
import { UserService } from "../../services/user.service";
import { SessionStoreService } from "../../services/session-store.service";

interface NavigationRoute {
  name: string;
  path: string;
  permission?: UserPermission | UserPermission[];
  children?: NavigationRoute[];
  isOpen?: boolean;
}

const navigationRoutes: NavigationRoute[] = [
  {
    name: "Dashboard",
    path: "/app/dashboard/charts",
    permission: "DASHBOARD_ALL",
  },
  {
    name: "Clients",
    path: "/app/clients",
    permission: "CLIENTS_MENU",
  },
  {
    name: "Mortgages",
    path: "/app/mortgages",
    permission: "MORTGAGES_MENU",
    children: [
      {
        name: "Initial Stage",
        path: "/app/mortgages/initial-stage",
        permission: ["MORTGAGES_NOT_SUBMITTED_MENU"],
      },
      {
        name: "Active Prospect",
        path: "/app/mortgages/active-prospect",
        permission: ["MORTGAGES_NOT_SUBMITTED_MENU"],
      },
      {
        name: "Submitted",
        path: "/app/mortgages/applications-submitted",
        permission: "MORTGAGE_LIST_ALL",
      },
      {
        name: "Offered",
        path: "/app/mortgages/offered",
        permission: "MORTGAGE_LIST_ALL",
      },
      {
        name: "Completed",
        path: "/app/mortgages/completed",
        permission: "MORTGAGE_LIST_ALL",
      },
      {
        name: "Not Proceeding",
        path: "/app/mortgages/not-proceeding",
        permission: "MORTGAGE_LIST_ALL",
      },

      // Introducer
      {
        name: "Active Prospects",
        path: "/app/mortgages/introducer-active",
        permission: [
          "MORTGAGE_LIST_INTRODUCER",
          "MORTGAGE_LIST_REGION",
          "MORTGAGE_LIST_DIVISION",
        ],
      },
      {
        name: "Live Applications",
        path: "/app/mortgages/introducer-live",
        permission: [
          "MORTGAGE_LIST_INTRODUCER",
          "MORTGAGE_LIST_REGION",
          "MORTGAGE_LIST_DIVISION",
        ],
      },
      {
        name: "Completed Applications",
        path: "/app/mortgages/introducer-completed",
        permission: [
          "MORTGAGE_LIST_INTRODUCER",
          "MORTGAGE_LIST_REGION",
          "MORTGAGE_LIST_DIVISION",
        ],
      },
      {
        name: "Not Proceeding",
        path: "/app/mortgages/introducer-not-proceeding",
        permission: [
          "MORTGAGE_LIST_INTRODUCER",
          "MORTGAGE_LIST_REGION",
          "MORTGAGE_LIST_DIVISION",
        ],
      },
    ],
  },

  {
    name: "Remortgages",
    path: "/app/remortgages",
    permission: "REMORTGAGES_MENU",
    children: [
      {
        name: "Initial Stage",
        path: "/app/remortgages/initial-stage",
      },
      {
        name: "Active Prospect",
        path: "/app/remortgages/active-prospect",
      },
      {
        name: "Submitted",
        path: "/app/remortgages/applications-submitted",
      },
      {
        name: "Offered",
        path: "/app/remortgages/offered",
      },
      {
        name: "Completed",
        path: "/app/remortgages/completed",
      },
      {
        name: "Opted Out",
        path: "/app/remortgages/opted-out",
      },
      {
        name: "Not Proceeding",
        path: "/app/remortgages/not-proceeding",
      },
    ],
  },

  {
    name: "Protection",
    path: "/app/protection",
    permission: "PROTECTION_MENU",
    children: [
      {
        name: "Initial Stage",
        path: "/app/protection/initial-stage",
      },
      {
        name: "Active Prospect",
        path: "/app/protection/active-prospect",
      },
      {
        name: "Submitted",
        path: "/app/protection/applications-submitted",
      },
      {
        name: "On Risk",
        path: "/app/protection/on-risk",
      },
      {
        name: "Replaced",
        path: "/app/protection/replaced",
      },
      {
        name: "Not Proceeding",
        path: "/app/protection/not-proceeding",
      },
    ],
  },
  {
    name: "Tasks",
    path: "/app/tasks",
    permission: "TASKS_MENU",
  },
  {
    name: "Introducers",
    path: "/app/introducers",
    permission: "INTRODUCERS_MENU",
  },
  {
    name: "My Introducer",
    path: "/app/my-introducer",
    permission: "MY_INTRODUCER_MENU",
  },
  {
    name: "Reports",
    path: "/app/reports",
    permission: "REPORTS_MENU",
    children: [
      {
        name: "Introducers",
        path: "/app/reports/introducers",
      },
      {
        name: "Management",
        path: "/app/reports/management",
        permission: "REPORT_MANAGEMENT",
      },
      {
        name: "Midas",
        path: "/app/reports/midas",
      },
    ],
  },
  {
    name: "Heron Users",
    path: "/app/users",
    permission: "USERS_MENU",
  },
];

@Component({
  selector: "hf-navigation",
  templateUrl: "./navigation.component.html",
  styleUrls: ["./navigation.component.scss"],
})
export class NavigationComponent {
  public routes: NavigationRoute[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private sessionStoreService: SessionStoreService
  ) {
    this.filterRoutes();
    this.flatReportRoutes();
    this.tryOpenInitialRoute();
  }

  public onLinkClicked() {
    this.sessionStoreService.clear();
  }

  public onRouteClicked(route: NavigationRoute) {
    const isOpen = !route.isOpen;

    this.routes.forEach((r) => (r.isOpen = false));
    route.isOpen = isOpen;
  }

  private filterRoutes() {
    const filterRoute = (route: NavigationRoute) =>
      this.userService.hasPermission(route.permission);
    this.routes = navigationRoutes.filter(filterRoute).map((r) => ({
      ...r,
      children: r.children && r.children.filter(filterRoute),
    }));
  }

  private flatReportRoutes() {
    const report = this.routes?.find((r) => r.path === "/app/reports");

    if (report?.children?.length === 1) {
      report.path = report.children[0].path;
      delete report.children;
    }
  }

  private tryOpenInitialRoute() {
    const url = this.router.routerState.snapshot.url;
    const route = this.routes.find((r) => url.includes(r.path));
    if (route) {
      route.isOpen = true;
    }
  }
}
