import { Component, Input } from '@angular/core';

@Component({
  selector: 'hf-qualification-tooltip',
  templateUrl: './qualification-tooltip.component.html',
  styleUrls: ['./qualification-tooltip.component.scss']
})
export class QualificationTooltipComponent {
  @Input()
  public desiredQualification: number;
}
