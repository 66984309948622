import { Component, Input } from '@angular/core';
import { APPLICANT_SCHEME_NAMES_ALL } from '../../../../../../../projects/client/src/app/fact-find/enums.module';
import { yesno } from '../../../../../../../projects/client/src/app/shared/functions/yesno';
import { NewComplianceHandoverObjectiveDTO } from '../../../../models/new-compliance-handover-objective.dto';
import { MortgageDebtConsolidationReasonName, MORTGAGE_OBJECTIVE_NAME, RemortgageReasonName } from '../../../../utils/variables.data';

export const valueOther = (value: any, map: Map<any, string>, other?: string) => {
  if (value && (!Array.isArray(value) || value.length > 0)) {
    if (Array.isArray(value)) {
      return value.map(i => i === "OTHER" ? other : map.get(i)).join(", ");
    }
    return value === "OTHER"
      ? other
      : map.get(value);
  }

  return other;
}

@Component({
  selector: 'hf-new-client-sheet-objective',
  templateUrl: './new-client-sheet-objective.component.html',
  styleUrls: ['./new-client-sheet-objective.component.scss']
})
export class NewClientSheetObjectiveComponent {
  @Input()
  public data: NewComplianceHandoverObjectiveDTO;

  public MORTGAGE_OBJECTIVE_NAME = MORTGAGE_OBJECTIVE_NAME;
  public MortgageDebtConsolidationReasonName = MortgageDebtConsolidationReasonName;
  public RemortgageReasonName = RemortgageReasonName;
  public APPLICANT_SCHEME_NAMES_ALL = APPLICANT_SCHEME_NAMES_ALL;

  public yesno = yesno;
  public valueOther = valueOther;
}
