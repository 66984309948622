import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import * as moment from 'moment';
import * as Sentry from "@sentry/angular-ivy";
import { Replay } from '@sentry/angular-ivy';

moment.locale('en-gb');

if (environment.serviceWorkerEnabled) {
  Sentry.init({
    environment: environment.production ? "production" : "development",
    dsn: environment.sentryDsn,
    integrations: [
      new Sentry.BrowserTracing(),
      new Sentry.Integrations.Breadcrumbs({
        console: false
      })
    ],
    // Performance Monitoring
    tracesSampleRate: environment.production ? 0.2 : 1,
   });
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
