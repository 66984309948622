<div class="overlay" [routerLink]="['./..']" [@fadeAnimation]></div>

<div class="sidepane-content">
  <div class="template-header flex-space-between-layout">
    <h1 class="title-area">Add Note</h1>
    <hf-custom-button type="button"
                      class="button-plain-icon"
                      icon="close"
                      iconPos="center"
                      [routerLink]="['./..']">
    </hf-custom-button>
  </div>

  <div class="template-main">
    <div [style.width.%]="100" class="padding-y">
      <form [formGroup]="addNoteFG">
        <hf-selector-link *ngIf="handovers?.length > 0"
          label="Related Application (Optional)"
          placeholder="Select Application"
          [value]="value._handover?.lender"
          (click)="onSelectLender()"
          style="display: block; margin-top: 8px; margin-bottom: 12px">
        </hf-selector-link>

        <label class="hf-sidepane-label">
          Title
        </label>
        <hf-custom-text-field type="text"
                              [group]="addNoteFG"
                              inputFormControlName="title"
                              fieldName="title">
        </hf-custom-text-field>

        <label class="hf-sidepane-label">
          Note
        </label>
        <hf-textarea [group]="addNoteFG"
                     [inputFormControlName]="'internalNotes'"></hf-textarea>

        <ng-container *ngIf="hasNotifyIntroducer && addNoteFG.get('notifyIntroducer') && addNoteFG.get('messageToIntroducer')">
          <hf-checkbox formControlName="notifyIntroducer" [label]="'Message to Introducer'"></hf-checkbox>

          <hf-textarea  *ngIf="addNoteFG.get('messageToIntroducer').enabled"
                        [group]="addNoteFG"
                        [placeholder]="'Message to the Introducer'"
                        [inputFormControlName]="'messageToIntroducer'"></hf-textarea>
        </ng-container>

      </form>
    </div>
  </div>

  <div class="template-footer">
    <hf-custom-button type="submit"
                      class="button-primary"
                      label="Add Note"
                      [disabled]="addNoteFG.invalid"
                      [callable]="true"
                      [callableApi]="submitResponse"
                      (onClick)="onSubmit()">
    </hf-custom-button>

    <hf-custom-button type="button"
                      class="button-secondary"
                      label="Cancel"
                      [routerLink]="['./..']">
    </hf-custom-button>
  </div>
</div>

<div class="sidepane-child-anchor">
  <router-outlet></router-outlet>
</div>
