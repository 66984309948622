import { Component, Input, HostBinding } from '@angular/core';
import { JournalEntry, JournalEntryConnectedDocument } from '../../models/journal-details';
import { JOURNAL_ACTIVITY_WHITE } from '../../utils/variables.data';
import { DocumentThumbnail } from '../../../../../projects/client/src/app/shared/components/document-thumbnail/document-thumbnail.component';

@Component({
  selector: 'hf-journal-item',
  templateUrl: './journal-item.component.html',
  styleUrls: ['./journal-item.component.scss']
})
export class JournalItemComponent {
  @Input()
  public item: JournalEntry;

  @HostBinding("class.isWhite")
  public get isWhite() { return JOURNAL_ACTIVITY_WHITE.has(this.item?.activity) }

  public getDocumentThumbnail(document: JournalEntryConnectedDocument) {
    const thumbnail: DocumentThumbnail = {
      customType: document.attachment.originalFilename || document.attachment.filename,
      file: document.attachment,
    }

    return thumbnail;
  }
}
