import { Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { fadeAnimation, sidepaneMove } from '../../../shared/animations/sidepane-animation';
import { SelectHelpToBuyRegionService } from './select-help-to-buy-region.service';
import { MortgageApiService, HelpToBuyRegion } from '../../../services/mortgage-api.service';

@Component({
  selector: 'hf-select-help-to-buy-region',
  templateUrl: './select-help-to-buy-region.component.html',
  styleUrls: [
    './select-help-to-buy-region.component.scss',
    '../../../styles/sidepanes.partial.scss'
  ],
  animations: [sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove]': 'true' }
})
export class SelectHelpToBuyRegionComponent implements OnDestroy {
  public form = new FormGroup({
    region: new FormControl()
  });

  public regions = [];
  public compareFn = (region1: HelpToBuyRegion, region2: HelpToBuyRegion): boolean =>
    !!(region1 && region2 && region1.id === region2.id);

  private destroy$ = new Subject();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private mortgageApiService: MortgageApiService,
    private selectHelpToBuyRegionService: SelectHelpToBuyRegionService
  ) {
    this.mortgageApiService.getHelpToBuyRegions()
      .then(regions => this.regions = regions);

    this.form.get("region").setValue(this.selectHelpToBuyRegionService.region);

    this.form.get("region").valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(region => {
        this.selectHelpToBuyRegionService.setRegion(region);
        this.onBack();
      });
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }

  public onBack() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }
}
