<div (click)="onBack()" class="overlay" [@fadeAnimation]></div>

<div class="sidepane-content">
  <div class="flex-space-between-layout template-header">
    <h1>Select Type</h1>
    <hf-custom-button type="button" class="button-plain-icon" icon="close" iconPos="center" (onClick)="onBack()">
    </hf-custom-button>
  </div>

  <div class="template-main">
    <form [formGroup]="form">
      <hf-custom-selector formControlName="type" [items]="items" [multi]="true">
        <ng-template #itemTemplate let-item>
          <div>
            {{map.get(item)}}
          </div>
        </ng-template>
      </hf-custom-selector>

      <div *ngIf="isOtherSelected" class="other">
        <label>Please Specify</label>
        <hfc-text-input formControlName="otherTypeLabel" #otherTypeComponent></hfc-text-input>
      </div>
    </form>
  </div>

  <div class="template-footer">
    <hf-custom-button type="submit" class="button-primary" label="Select" (click)="onSubmit()"></hf-custom-button>
    <hf-custom-button type="button" class="button-secondary" label="Cancel" (click)="onBack()"></hf-custom-button>
  </div>
</div>
