import { Component, Input, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TableSettingsService } from '../table-utils/table-settings.service';

function capitalize(text: string): string {
  if (!text) {
    return text;
  }

  return text.replace(/(?:^|\s)\S/g, match => match.toUpperCase());
}

@Component({
  selector: 'hf-custom-text-field',
  templateUrl: './custom-text-field.component.html',
  styleUrls: ['./custom-text-field.component.scss']
})

export class CustomTextFieldComponent implements OnInit, OnDestroy {
  @Input() public group: FormGroup;
  @Input() public inputFormControlName: string;
  @Input() public fieldName: string;
  @Input() public type: string;
  @Input() public customInputGroupClass?: string;
  @Input() public placeholder = '';
  @Input() public addon?: string;
  @Input() public disabled?: string;
  @Input() public asset?: string;
  @Input() public icon?: string;
  @Input() public autofocus?: string;
  @Input() public autocomplete?: string;
  @Input() public valid?: string;
  @Input() public listSearchModeControlName?: string;
  @Input() public pattern?: string;
  @Input() public maxLength?: number = 255;
  @Input() private preventValidation: boolean;
  @Input() private searchMode: boolean;
  @Input() private listSearchMode: boolean;
  @Input() public capitalize: boolean = false;
  @Input() public prefix: string;
  @Input() public noEmailValidation = false;

  private isValid: boolean = null;
  private enableEmailValidation: boolean = false;
  private controlSub: any;

  private unsubscribe$ = new Subject();

  constructor(private tableSettingsService: TableSettingsService) {
  }

  @ViewChild("input")
  public inputElement: ElementRef;

  public setFocus() {
    this.inputElement.nativeElement.focus();
  }

  ngOnInit() {
    if (this.searchMode) {
      // this.group = this.tableSettingsService.searchBoxFg;
      this.group = new FormGroup({searchBox: new FormControl()});
      this.inputFormControlName = 'searchBox';

      this.group.get("searchBox").setValue(this.tableSettingsService.tableParams.filter);

      this.group.get("searchBox").valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(filter =>
        this.tableSettingsService.filterRequest(filter));
    }

    if (this.listSearchMode && this.listSearchModeControlName) {
      this.inputFormControlName = this.listSearchModeControlName;
    }

    if (this.valid !== undefined) {
      this.isValid = (this.valid === 'true');
    }
    if (this.type === 'email' && !this.noEmailValidation) {
      this.enableEmailValidation = true;
    }

    if (!this.preventValidation) {
      this.controlSub = this.group.controls[this.inputFormControlName].statusChanges.pipe(takeUntil(this.unsubscribe$))
        .subscribe(status => {
          switch (status) {
            case 'PENDING':
              this.isValid = true;
              break;
            case 'VALID':
              this.isValid = true;
              break;
            case 'INVALID':
              this.isValid = false;
              break;
            default:
              this.isValid = false;
              break;
          }
        });
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next('');
  }

  public inputIconClick() {
    if (!this.searchMode) {
      return; 
    }

    if (this.group.get("searchBox").value) {
      this.group.get("searchBox").setValue("");
    }
  }

  public focus(event: any) {
    const addon = event.srcElement.parentElement.getElementsByTagName('span').item(0);
    addon.classList.add('input-group-text-focus');
  }

  public focusout(event: any) {
    const addon = event.srcElement.parentElement.getElementsByTagName('span').item(0);
    addon.classList.remove('input-group-text-focus');
  }

  public mouseenter(event: any, manualHover?: string) {
    const addon = event.srcElement.parentElement.getElementsByTagName('span').item(0);
    if (manualHover) {
      const input = event.srcElement.parentElement.getElementsByTagName('input').item(0);
      if (!input.disabled) {
        input.classList.add('hf-form-control-hover');
        if (addon.classList.contains('input-group-text')) {
          addon.classList.add('input-group-text-hover');
        }
      }
    } else if (addon.classList.contains('input-group-text')) {
      addon.classList.add('input-group-text-hover');
    }
  }

  public mouseleave(event: any, manualHover?: string) {
    const addon = event.srcElement.parentElement.getElementsByTagName('span').item(0);
    if (manualHover) {
      const input = event.srcElement.parentElement.getElementsByTagName('input').item(0);
      if (!input.disabled) {
        input.classList.remove('hf-form-control-hover');
        if (addon.classList.contains('input-group-text')) {
          addon.classList.remove('input-group-text-hover');
        }
      }
    } else if (addon.classList.contains('input-group-text')) {
      addon.classList.remove('input-group-text-hover');
    }
  }

  public onBlur() {
    if (this.capitalize && this.group) {
      const control = this.group.controls[this.inputFormControlName];

      control && control.setValue(capitalize(control.value));
    }
  }
}
