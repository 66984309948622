<ng-container *ngIf="totalPages">
  <i class="material-icons"
     (click)="pageClicked(1)">
    first_page
  </i>

  <i class="material-icons"
     (click)="pageRelativeClicked(-1)">
    navigate_before
  </i>

  <ng-container
    *ngFor="let page of totalPages | numberArray">
    <div *ngIf="getIfShouldBeDisplayed(page)"
         [ngClass]="{'pagination-item-active': isPageActive(page)}"
         class="pagination-item"
         (click)="pageClicked(page)">
      {{page}}
    </div>
  </ng-container>

  <i class="material-icons"
     (click)="pageRelativeClicked(1)">
    navigate_next
  </i>

  <i class="material-icons"
     (click)="lastPageClicked()">
    last_page
  </i>
</ng-container>

