import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { getToday } from '../../../../../../../projects/client/src/app/shared/functions/get-today';
import { UserSimple } from '../../../../models';
import { DocumentTitleService } from '../../../../services/document-title.service';
import { UserApiService } from '../../../../services/user-api.service';
import { UserService } from '../../../../services/user.service';
import { SelectUserService } from '../../../../shared-layout/side-panes/select-user/select-user.service';
import { SelectorSidepaneService } from '../../../../shared-layout/side-panes/selector-sidepane/selector-sidepane.service';
import { TableSettingsService, TABLE_PARAMS_STORAGE_KEY } from '../../../../shared/table-utils/table-settings.service';
import { TASK_LIST_TYPE, USER_TYPE, USER_TYPE_NAMES } from '../../../../utils/variables.data';
import { Task, TaskApiService, TaskListFilterDTO, TaskListParamsDTO } from '../../task-api.service';
import { TaskService } from '../../task.service';

const allowedUserTypes: USER_TYPE[] = [
  "ADMINISTRATOR",
  "ADVISOR_MORTGAGES",
  "ADVISOR_PROTECTION",
  "APPLICATIONS",
  "CASE_PROGRESSION",
  "COMPLIANCE",
  "GENERAL",
];

export const teamsForFilter = new Map(allowedUserTypes.map(type => [type, USER_TYPE_NAMES.get(type)]));

@Component({
  selector: 'hf-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss'],
  providers: [TableSettingsService, { provide: TABLE_PARAMS_STORAGE_KEY, useValue: "app/tasks" }, SelectUserService]
})
export class TaskListComponent implements AfterViewInit, OnDestroy {
  public data: Task[];
  public viewCompleted: boolean = false;
  public type: TASK_LIST_TYPE = "MY";
  public today = getToday();
  public teams: USER_TYPE[];
  public assignees: UserSimple[];
  public USER_TYPE_NAMES = USER_TYPE_NAMES;

  public get teamsLabel() { return this.teams?.map(t => USER_TYPE_NAMES.get(t)).join(", ") }
  public get assigneesLabel() { return this.assignees?.map(t => t.fullName).join(", ") }

  public get isAssigneeColumnVisible() { return this.type === "DELEGATED" || this.type === "TEAM" }

  private destroy$ = new Subject();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private taskApiService: TaskApiService,
    private userApiService: UserApiService,
    private taskService: TaskService,
    private userService: UserService,
    private selectorSidepaneService: SelectorSidepaneService,
    private tableSettingsService: TableSettingsService,
    private documentTitleService: DocumentTitleService,
    private selectUserService: SelectUserService,
  ) {
    this.setup();
  }

  public onSelectTeamsClicked() {
    this.selectorSidepaneService.initialySelectedItem = this.teams;

    this.router.navigate(["selectTeams"], { relativeTo: this.route });
  }

  public onSelectAssigneesClicked() {
    this.selectUserService.users$ = this.userApiService.getHeronUsers();
    this.selectUserService.multi = true;
    this.selectUserService.initial = this.assignees;

    this.router.navigate(["selectAssignee"], { relativeTo: this.route });
  }

  public onTaskRowClicked(row: Task) {
    this.router.navigate(["viewTask", row.id], { relativeTo: this.route });
  }

  public onViewCompletedChanged() {
    setTimeout(() => this.tableSettingsService.refresh());
  }

  public ngAfterViewInit() {
    this.tableSettingsService.setDataSourceFactory(params => {
      const taskListParams: TaskListParamsDTO = {
        ...params,
        type: this.type
      };

      const taskListFilter: TaskListFilterDTO = {
        filter: params.filter,
        viewCompleted: this.viewCompleted,
        teams: this.teams,
        assigneeIds: this.assignees?.map(a => a.id)
      };

      return this.taskApiService.listTasks(taskListParams, taskListFilter);
    });
  }

  public onActiveKeyChanged(key: string) {
    const queryParams = { tab: key.toLowerCase() };

    this.router.navigate(["."], { relativeTo: this.route, queryParams });
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }

  private setup() {
    this.documentTitleService.setTitleParams({ taskType: "My Tasks" });

    this.route.queryParamMap.pipe(
      map(queryParamMap => queryParamMap.get("tab")),
      filter(tab => !!tab),
      map(tab => tab.toUpperCase())
    ).subscribe((type: TASK_LIST_TYPE) => {
      if (type !== this.type) {
        this.type = type;
        this.tableSettingsService.refresh();
        this.documentTitleService.setTitleParams({ taskType: this.type === "MY" ? "My Tasks" : "Delegated Tasks" });
      }
    })

    this.taskService.reloadTasks$.pipe(takeUntil(this.destroy$))
      .subscribe(() => this.tableSettingsService.refresh());

    this.tableSettingsService.data$.pipe(takeUntil(this.destroy$))
      .subscribe(data => this.data = data);

    this.selectorSidepaneService.subscribeByKey("selectTeams", this.destroy$).subscribe(teams => {
      this.teams = teams;
      this.tableSettingsService.refresh();
    });

    this.selectUserService.userSelected$.pipe(takeUntil(this.destroy$)).subscribe((userSelected: UserSimple[]) => {
      this.assignees = userSelected;
      this.tableSettingsService.refresh();
    });

    const userType = this.userService.getUser().userType;
    const initialTeams: USER_TYPE[] = userType === "ADVISOR_BOTH"
      ? ["ADVISOR_MORTGAGES", "ADVISOR_PROTECTION"]
      : [userType];

    this.teams = initialTeams;
  }
}
