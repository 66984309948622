<hf-custom-spinner *ngIf="isLoading" [darkTheme]="false"></hf-custom-spinner>

<div *ngIf="!isLoading" class="container">
  <hf-link-list [items]="leftMenu.steps" (itemClicked)="onMenuItemClicked($event)">
    <ng-template #itemTemplate let-item>
      <div style="display: flex; align-items: center;">
        <hf-fact-find-step-status-icon [status]="leftMenu.statuses[item]" style="width: 30px; flex: 0 0 30px;"></hf-fact-find-step-status-icon>
        {{APPLICATION_STEP_NAME.get(item)}}
      </div>
    </ng-template>
  </hf-link-list>

  <div class="summary">
    <div class="header">
      <hf-fact-find-status-banner [status]="data.factFindStatus"></hf-fact-find-status-banner>

      <div class="desktop-buttons">
        <hfc-button type="primary" postfixIcon="launch" (click)="onEditClicked()">Edit</hfc-button>

        <ng-container *ngIf="data.factFindStatus !== 'APPROVED'">
          <hfc-button type="primary" [disabled]="!isApproveButtonEnabled" (click)="onApproveClicked()">Approve</hfc-button>
          <hfc-button type="primary" [disabled]="!isReturnButtonEnabled" (click)="onReturnClicked()">Return</hfc-button>
        </ng-container>

        <hfc-button type="primary" *ngIf="isReactivateButtonEnabled" (click)="onReactivateClicked()">Reactivate</hfc-button>
      </div>
    </div>

    <hfc-sign-off-content [data]="data" [commonSectionConfigs]="commonSectionConfigs" [applicantSectionConfigs]="applicantSectionConfigs" (buttonClick)="onButtonClicked($event)"></hfc-sign-off-content>

    <hfc-readonly-signature-review-section [data]="data"></hfc-readonly-signature-review-section>
  </div>
</div>

<div *ngIf="!isLoading" class="buttons-wrapper">
  <div class="buttons">
    <hfc-button type="primary" postfixIcon="launch" (click)="onEditClicked()">Edit</hfc-button>

    <ng-container *ngIf="data.factFindStatus !== 'APPROVED'">
      <hfc-button type="primary" [disabled]="!isApproveButtonEnabled" (click)="onApproveClicked()">Approve</hfc-button>
      <hfc-button type="primary" [disabled]="!isReturnButtonEnabled" (click)="onReturnClicked()">Return</hfc-button>
    </ng-container>

    <hfc-button type="primary" *ngIf="isReactivateButtonEnabled" (click)="onReactivateClicked()">Reactivate</hfc-button>
  </div>
</div>
