import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApplicationCreateDTO } from '../../../models/application-create';
import { MortgageDashboard, MortgageDashboardHandover } from '../../../models/mortgage-dashboard';
import { MortgageApiService, CreateApplicationInfoDTO } from '../../../services/mortgage-api.service';
import { ToasterService } from '../../../services/toaster.service';
import { fadeAnimation, sidepaneMove } from '../../../shared/animations/sidepane-animation';
import { FileControlsConfigBuilder } from '../../../shared/fileuploader/utils/fileuploader.utils';
import { CLIENT_CATEGORY } from '../../../utils/variables.data';
import { AssignCaseProgressionService } from '../assign-case-progression/assign-case-progression.service';
import { MortgagesService } from '../mortgages.service';
import { SelectSolicitorService } from '../select-solicitor/select-solicitor.service';
import {setControlEnabled} from '../../../utils/functions/set-control-enabled';
import {SelectApplicationLenderService} from '../select-application-lender/select-application-lender.service';
import { Confirmation, DialogService } from '../../../../../../projects/client/src/app/shared/services/dialog.service';
import { ToastService } from '../../../../../../projects/client/src/app/shared/services/toast.service';

const transferCategories: CLIENT_CATEGORY[] = ["PRODUCT_TRANSFER", "FURTHER_ADVANCE"]
export function isTransferOrAdvance(mortgage: MortgageDashboard): boolean {
  return transferCategories.includes(mortgage?.clientCategory);
}

@Component({
  selector: 'hf-create-application',
  templateUrl: './create-application.component.html',
  styleUrls: [
    './create-application.component.scss',
    '../../../styles/sidepanes.partial.scss'
  ],
  animations: [sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove]': 'true' },
  providers: [SelectApplicationLenderService],
})
export class CreateApplicationComponent implements OnDestroy {
  public form = this.fb.group({
      caseProgression: this.fb.group({
        id: ['', Validators.required],
        fullName: ['']
      }),
      freeLegalSolicitor: [false],
      solicitor: this.fb.group({
        id: [''],
        name: ['', Validators.required],
        email: [''],
        phonePrefix: [''],
        phone: [''],
        address: this.fb.group({
          house: [''],
          street: [''],
          line2: [''],
          city: [''],
          postcode: [''],
          country: ['']
        })
      }),
      journal: this.fb.group({
        internalNotes: [''],
        messageToClient: [''],
        connectedDocument: this.fb.group({
          attachment: this.fb.group(
            new FileControlsConfigBuilder()
              .maxFileSizeEmail()
              .build()
          )
        })
      }),
      lenderReference: [''],
      loanAmount: [null, Validators.required],
      commission: [null, Validators.required],
      _hasCommission: [null, Validators.required],
      _handover: [null, Validators.required],
    });


  public handovers: MortgageDashboardHandover[];
  public isLoading = true;
  public get mortgageId(): string { return this.route.snapshot.params['id'] }
  public get value() { return this.form.value }
  public control(name: string) { return this.form.get(name) }

  private destroy$ = new Subject();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private toasterService: ToasterService,
    private toast: ToastService,
    private mortgagesService: MortgagesService,
    private mortgageApiService: MortgageApiService,
    private assignCaseProgressionService: AssignCaseProgressionService,
    private selectSolicitorService: SelectSolicitorService,
    private selectApplicationLenderService: SelectApplicationLenderService,
    private dialogService: DialogService,
  ) {
    this.form.get("commission").disable();
    this.form.get("loanAmount").disable();
    this.selectSolicitorService.initialSolicitor = undefined;

    this.assignCaseProgressionService.caseProgressionSelected$.pipe(takeUntil(this.destroy$)).subscribe(cp => {
      this.form.get("caseProgression").patchValue(cp);
    });

    this.selectSolicitorService.solicitorSelected$.pipe(takeUntil(this.destroy$)).subscribe(solicitor => {
      this.form.get("solicitor").patchValue(solicitor);
    });

    this.selectApplicationLenderService.application$.pipe(takeUntil(this.destroy$)).subscribe(handover => {
      this.control("_handover").setValue(handover);
      setControlEnabled(this.form.get("loanAmount"), !handover.loanAmount);

      if (handover.lenderRefRegex) {
        this.control("lenderReference").setValidators(Validators.pattern(handover.lenderRefRegex));
      } else {
        this.control("lenderReference").clearValidators();
      }
    });

    this.mortgagesService.mortgageDashboardDataLoaded.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (isTransferOrAdvance(this.mortgagesService.data)) {
        this.form.get("solicitor").disable();
      }
    });

    this.initialize();

    this.form.get("_hasCommission").valueChanges.subscribe(hasCommission => {
      setControlEnabled(this.form.get("commission"), hasCommission === true);
      if (hasCommission !== true) {
        this.form.get("commission").setValue(null);
      }
    });
  }

  public onSelectLender() {
    this.router.navigate(["selectLender"], { relativeTo: this.route });
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }

  public onFreeLegalSolicitorChange(free: boolean) {
    if (free) {
      this.form.get("solicitor.name").clearValidators();
    } else {
      this.form.get("solicitor.name").setValidators(Validators.required);
    }

    this.form.get("solicitor.name").updateValueAndValidity();
  }

  public async onCreate() {
    const application = {...this.form.value} as ApplicationCreateDTO;

    application.handoverId = this.value._handover.id;

    if (this.control("loanAmount").disabled) {
      application.loanAmount = this.value._handover.loanAmount;
    }

    application.journal.internalNotes || delete application.journal.internalNotes;
    application.journal.messageToClient || delete application.journal.messageToClient;

    application.journal.connectedDocument.attachment.filename || delete application.journal.connectedDocument;

    application.freeLegalSolicitor && !application.solicitor.name && delete application.solicitor;

    try {
      await this.mortgageApiService.createApplication(this.mortgageId, application);

      this.toasterService.callToaster({ severity: 'info', summary: 'Info', detail: 'Application created' });
      this.mortgagesService.reloadMortgageDashboard.emit();
      this.onBack();
    } catch (err) {
      this.toasterService.callToaster({ severity: 'error', summary: 'Error', detail: `Failed to create application. Please try again ${err.error.error.message}` });
    }
  }

  public onBack() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

  private async initialize() {
    try {
      const info = await this.mortgageApiService.getCreateApplicationInfo(this.mortgageId);

      this.mortgagesService.applicationHandovers = info.handovers;
      this.handovers = info.handovers.filter(h => h.suitabilityLetterState === "SENT_TO_CLIENT");

      if (!this.handovers?.length) {
        const isSending = info.handovers.some(h => h.suitabilityLetterState === "SENDING");
        // console.log("isSending", isSending);
        if (isSending) {
          const confirmation: Confirmation = {
            title: "Suitability Letter",
            message: "The suitability letter is still sending, please try again in a moment",
            acceptButtonType: "primary",
            rejectButtonType: null,
          }

          await this.dialogService.confirm(confirmation);

          this.onBack();
          return;
        }

        const isReady = info.handovers.some(h => h.suitabilityLetterState === "READY");
        // console.log("isReady", isReady);
        if (isReady) {
          const confirmation: Confirmation = {
            title: "Suitability Letter",
            message: "You must send the suitability letter before submitting an application",
            acceptButtonType: "primary",
            rejectLabel: "View Suitability Letter",
          }

          const result = await this.dialogService.confirm(confirmation);

          if (result) {
            this.onBack();
          } else {
            this.router.navigate(["../suitability-letter"], { relativeTo: this.route });
          }

          return;
        }

        const isOld = info.handovers.some(h => !h.suitabilityLetterState)
        if (isOld) {
          const confirmation: Confirmation = {
            title: "Suitability Letter",
            message: "You must use the new style client sheet to continue",
            acceptButtonType: "primary",
            rejectButtonType: null,
          }

          await this.dialogService.confirm(confirmation);

          this.onBack();
          return;
        }

        // Still problem - show info and close
        const confirmation: Confirmation = {
          title: "Suitability Letter",
          message: "There is a technical problem with suitability letter, please contact support.",
          acceptButtonType: "primary",
          rejectButtonType: null,
        }

        await this.dialogService.confirm(confirmation);

        this.onBack();
      }

      if (this.handovers?.length === 1) {
        this.control("_handover").setValue(this.handovers[0]);
        setControlEnabled(this.form.get("loanAmount"), !this.handovers[0].loanAmount);

        const handover = this.handovers[0];
        // console.log("regex: ", handover.lenderRefRegex);
        if (handover.lenderRefRegex) {
          this.control("lenderReference").setValidators(Validators.pattern(handover.lenderRefRegex));
        } else {
          this.control("lenderReference").clearValidators();
        }
      }

      this.isLoading = false;
    } catch (e) {
      this.toast.add("Failed", "error", e);
    }
  }
}
