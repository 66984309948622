import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'inputFieldTextSearch',
  pure: false
})

export class InputFieldTextSearchPipe implements PipeTransform {

  constructor() {}

  transform(value: any, filter: string, field: string): any {
    return filter ? value.reduce((prev, next) => {
      // in case we're testing a formGroup with controls
      if (next.controls && next.controls[field] !== undefined && next.controls[field].value.toLowerCase().indexOf(filter.toLowerCase()) !== -1)
        prev.push(next);
      // in case we're testing a formControl already
      if (next[field] !== undefined && next[field].toLowerCase().indexOf(filter.toLowerCase()) !== -1)
        prev.push(next);
      return prev;
    }, []) : value;
  }
}
