<div (click)="onBack()" class="overlay" [@fadeAnimation]></div>

<div class="sidepane-content">
  <div class="template-header flex-space-between-layout">
    <h1 class="title-area">Mortgage Notifications</h1>
    <hf-custom-button type="button"
                      class="button-plain-icon"
                      icon="close"
                      iconPos="center"
                      (onClick)="onBack()">
    </hf-custom-button>
  </div>

  <div class="template-main">
    <hf-custom-spinner *ngIf="isLoading" sidepaneLoading="true"></hf-custom-spinner>

    <form *ngIf="!isLoading">
      <label>Original Introducer</label>
      <div class="hf-form-static-value">
        {{mortgage?.introducer?.name}}
      </div>

      <label>Original Sales Advisor</label>
      <div class="hf-form-static-value">
        {{mortgage?.salesAdvisor?.fullName}}
      </div>

      <div *ngIf="mortgage.introducerLinkBroken" class="unlinked">Original Introducer has been unlinked.</div>

      <hfc-button type="tertiary" size="small" (click)="onLinkUnlinkClicked()">{{ mortgage.introducerLinkBroken ? 'Relink Original Introducer' : 'Unlink Original Introducer' }}</hfc-button>

      <hfc-info-box-container label="Notifications" class="info-box-container-full-width">
        <hfc-info-box *ngFor="let item of list" [title]="item.name" [details]="NOTIFICATION_PARTY_TYPE_NAME.get(item.type)" [clickable]="!readonlyPartyTypes.includes(item.type)" (click)="onInterestedPartyClicked(item)"></hfc-info-box>

        <hfc-add-button (click)="onAddInterestedPartyClicked()">Add Interested Party</hfc-add-button>
      </hfc-info-box-container>
    </form>
  </div>

  <div class="template-footer">
    <hf-custom-button type="button"
                      class="button-secondary"
                      label="Close"
                      [routerLink]="['./..']">
    </hf-custom-button>
  </div>
</div>

<div class="sidepane-child-anchor">
  <router-outlet></router-outlet>
</div>
