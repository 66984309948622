<div class="overlay" (click)="onBack()" [@fadeAnimation]></div>

<div class="sidepane-content">
  <div class="flex-space-between-layout template-header">

    <h1>Document Type</h1>
    <hf-custom-button type="button"
                      class="button-plain-icon"
                      icon="close"
                      iconPos="center"
                      (click)="onBack()">
    </hf-custom-button>
  </div>

  <div class="template-main">
      <hf-custom-selector [(ngModel)]="value" (ngModelChange)="onSelected($event)" [items]="notProceedingFollowups">
        <ng-template #itemTemplate let-item>
          <div>
            {{ item.displayName }}
          </div>
        </ng-template>
      </hf-custom-selector>

    <form>
      <div *ngIf="isOther()">
        <label>Specify Other</label>
        <hf-textarea
            [hasError]="hasError"
            [group]="form"
            [inputFormControlName]="'other'"
            [smaller]="true"></hf-textarea>
      </div>
    </form>
  </div>

  <div *ngIf="isOther()" class="template-footer">
    <hf-custom-button type="submit" class="button-primary" label="Select" (click)="onSelectClicked()"></hf-custom-button>
    <hf-custom-button type="button" class="button-secondary" label="Cancel" (click)="onBack()"></hf-custom-button>
  </div>
</div>
