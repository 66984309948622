import {Component, Input} from '@angular/core';
import {APPLICATION_STATE, APPLICATION_STATE_NAMES} from '../../../utils/variables.data';

@Component({
  selector: 'hf-application-status-badge',
  templateUrl: './application-status-badge.component.html',
  styleUrls: ['./application-status-badge.component.scss']
})
export class ApplicationStatusBadgeComponent {
  @Input()
  public state: APPLICATION_STATE;

  @Input()
  public isClickable: boolean;

  public APPLICATION_STATE_NAMES = APPLICATION_STATE_NAMES;
}
