import { Component, Optional } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Confirmation, DialogService } from '../../../../../../../projects/client/src/app/shared/services/dialog.service';
import { fadeAnimation, sidepaneMove } from '../../../../shared/animations/sidepane-animation';
import { JournalApiService } from '../../../../services/journal-api.service';
import { RemortgageDashboardService } from '../../pages/remortgage-dashboard/remortgage-dashboard.service';
import { PinnedNoteViewDTO } from '../../../../models/pinned-note-view.dto';
import { ToastService } from '../../../../../../../projects/client/src/app/shared/services/toast.service';
import { Store } from '@ngxs/store';
import { Mortgage } from '../../../mortgages/state/mortgage.actions';
import { MortgagesService } from '../../../mortgages/mortgages.service';
import { ProtectionDashboardService } from '../../../protection/components/protection-dashboard/protection-dashboard.service';

@Component({
  selector: 'hf-pinned-note',
  templateUrl: './pinned-note.component.html',
  styleUrls: ['./pinned-note.component.scss'
    ,'../../../../styles/sidepanes.partial.scss'],
  animations: [sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove]': 'true' }
})
export class PinnedNoteComponent {
  public form = this.fb.group({
    body: [null, Validators.required],
    version: [],
  });

  public isLoading = false;
  public isSubmitting = false;
  public isEdit = false;
  public get remortgageId() { return this.route.snapshot.paramMap.get("id") }
  public get value(): PinnedNoteViewDTO { return this.form.value }

  constructor(
    private store: Store,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private journalApiService: JournalApiService,
    @Optional() private remortgageDashboardService: RemortgageDashboardService,
    @Optional() private protectionDashboardService: ProtectionDashboardService,
    private mortgagesService: MortgagesService,
    private dialogService: DialogService,
    private toastService: ToastService,
  ) { 
    this.initialize();
  }

  private async save(value: PinnedNoteViewDTO) {
    if (this.protectionDashboardService) {
      return this.isEdit 
        ? this.journalApiService.editProtectionPinnedNote(this.remortgageId, value, value.version).toPromise()
        : this.journalApiService.addProtectionPinnedNote(this.remortgageId, value).toPromise();
    } else {
      return this.isEdit 
        ? this.journalApiService.editMortgagePinnedNote(this.remortgageId, value, value.version).toPromise()
        : this.journalApiService.addMortgagePinnedNote(this.remortgageId, value).toPromise();
    }
  }

  public async onSubmit() {
    const value: PinnedNoteViewDTO = this.form.value;
    this.isSubmitting = true;

    try {
      const edited = await this.save(value);

      this.toastService.add("Saved pinned note");

      if (this.remortgageDashboardService) {
        this.remortgageDashboardService.remortgage.pinnedNote = edited;
      }
      if (this.mortgagesService?.data) {
        this.mortgagesService.data.pinnedNote = edited;
      }
      if (this.protectionDashboardService?.protectionDashboardDTO) {
        this.protectionDashboardService.protectionDashboardDTO.pinnedNote = edited;
      }

      this.store.dispatch(new Mortgage.PinnedNoteSaved(edited));

      this.onBack();
    } catch (e) {
      this.toastService.add("Failed to save pinned note", "error", e);
    }

    this.isSubmitting = false;
  }

  public onDeleteClicked() {
    const confirmation: Confirmation = {
      title: "Delete Pinned Note",
      message: "Deleting the pinned note will permanently remove its contents. Are you sure you want to delete it?",
      acceptLabel: "Delete",
    }
    const doDelete = async () => {
      try {
        this.protectionDashboardService
          ? await this.journalApiService.deleteProtectionPinnedNote(this.remortgageId, this.value.version).toPromise()
          : await this.journalApiService.deleteMortgagePinnedNote(this.remortgageId, this.value.version).toPromise();
      } catch (e) {
        this.toastService.add("Failed to delete pinned note", "error", e);
        return;
      }

      this.toastService.add("Pinned note deleted");

      if (this.remortgageDashboardService) {
        delete this.remortgageDashboardService.remortgage.pinnedNote;
      }
      if (this.mortgagesService?.data) {
        delete this.mortgagesService.data.pinnedNote;
      }
      if (this.protectionDashboardService?.protectionDashboardDTO) {
        delete this.protectionDashboardService.protectionDashboardDTO.pinnedNote;
      }

      this.store.dispatch(new Mortgage.PinnedNoteSaved(null));

      this.onBack();
    }

    this.dialogService.confirmAndDo(confirmation, doDelete);
  }

  public onBack() {
    this.router.navigate([".."], { relativeTo: this.route });
  }

  private async initialize() {
    this.isLoading = true;

    let note: PinnedNoteViewDTO;
    try {
      note = this.protectionDashboardService
        ? await this.journalApiService.viewProtectionPinnedNote(this.remortgageId).toPromise()
        : await this.journalApiService.viewMortgagePinnedNote(this.remortgageId).toPromise();
    } catch (e) { }

    if (note) {
      this.form.patchValue(note);
      this.isEdit = true;
    }

    this.isLoading = false;
  }
}
