import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AbstractControl } from '@angular/forms';
import { FileControlsConfigBuilder } from '../utils/fileuploader.utils';
import { ApiService } from '../../../services/api.service';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { FileOrientation, UploadType } from '../utils/fileuploader.enum';

@Injectable()
export class FileuploaderService {

  constructor(private apiService: ApiService) {
  }

  uploadFile(file: File, uploadType: UploadType, makeSquare: boolean): Observable<any> {
    const servletUrl = environment.apiUrl + 'servlet/' + uploadType + '?filename=';
    const options = this.createOptions(file, uploadType, makeSquare);

    return this.apiService.rawPost(servletUrl + encodeURIComponent(file.name), file, options);
  }

  mapFileToControl(file: File, control: AbstractControl, subject: Subject<any>) {
    control.reset();
    control.patchValue({[FileControlsConfigBuilder.ORIGINAL_FILENAME]: file.name});
    control.patchValue({[FileControlsConfigBuilder.FILE_SIZE]: file.size});
    control.patchValue({[FileControlsConfigBuilder.FILE_TYPE]: file.type});

    if (this.isImage(file)) {
      this.mapImageToControl(file, control, subject);
    } else {
      subject.next();
    }
  }

  isImage(file: File) {
    return new RegExp(/(image\/)/).test(file.type);
  }

  mapImageToControl(file: File, control: AbstractControl, subject: Subject<any>) {
    const img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = () => {
      control.patchValue({[FileControlsConfigBuilder.FILE_ORIENTATION]: this.initFileOrientation(img.width, img.height)});
      control.patchValue({[FileControlsConfigBuilder.FILE_WIDTH]: img.width});
      control.patchValue({[FileControlsConfigBuilder.FILE_HEIGHT]: img.height});
      subject.next();
    };
  }

  initFileOrientation(width: number, height: number): FileOrientation {
    if (width === height) {
      return null;
    }
    return width < height ? FileOrientation.PORTRAIT : FileOrientation.LANDSCAPE;
  }

  getErrors(formGroup) {
    const controlWithError = Object.keys(formGroup.controls).reverse().find((control) => (formGroup.controls[control].errors));
    return formGroup.controls[controlWithError].errors;
  }

  initHttpParams(uploadType: UploadType, makeSquare: boolean) {
    const httpParams: Record<string, any> = {};

    if (uploadType === UploadType.UPLOAD_FILE && makeSquare) {
      httpParams.makeSquare = true;
    }

    return httpParams;
  }

  private createOptions(file: File, uploadType: UploadType, makeSquare: boolean) {
    const headers = new HttpHeaders();
    headers.set('Content-Type', file.type);
    return {
      headers,
      withCredentials: true,
      params: this.initHttpParams(uploadType, makeSquare)
    };
  }
}
