import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectUserComponent } from './select-user.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomButtonModule } from '../../../shared/custom-button/custom-button.module';
import { CustomSelectorModule } from '../../../shared/custom-selector/custom-selector.module';
import { CustomSpinnerModule } from '../../../shared/custom-spinner/custom-spinner.module';
import { UserSelectorComponent } from './user-selector/user-selector.component';

@NgModule({
  declarations: [SelectUserComponent, UserSelectorComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CustomButtonModule,
    CustomSelectorModule,
    CustomSpinnerModule
  ],
  exports: [SelectUserComponent, UserSelectorComponent]
})
export class SelectUserModule { }
