import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { CustomSidenavModule } from '../../shared/custom-sidenav/custom-sidenav.module';
import { DashboardTemplateModule } from './dashboard-template/dashboard-template.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ButtonModule,
    DashboardTemplateModule,
    CustomSidenavModule
  ],
  exports: [
    DashboardTemplateModule,
  ]
})
export class DashboardModule {
}
