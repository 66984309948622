<div class="overlay" (click)="templateService.closeSidebar()"></div>

<nav>
  <div class="flex-space-between-layout">
    <div class="icon">
      <a [routerLink]="['mortgages']">
        <img src="assets/img/Everglades-light-bg.svg" alt="Heron logo" />
      </a>
    </div>
    <hf-custom-button
      type="button"
      icon="close"
      iconPos="center"
      class="button-plain-icon for-phone-show"
      tabindex="0"
      (onClick)="templateService.closeSidebar()"
    >
    </hf-custom-button>
  </div>
  <hf-custom-dropdown [user]="user"></hf-custom-dropdown>
</nav>
