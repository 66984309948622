import { Injectable } from '@angular/core';
import { Subject } from "rxjs";
import { UserSimple } from '../../../models';

export enum SelectUserHeadingType {
  SELECT_USER = "Select User",
  SELECT_LINE_MANAGER = "Select Line Manager",
  SELECT_PROTECTION_ADVISOR = "Select Protection Advisor",
  SELECT_MORTGAGE_ADVISOR = "Select Mortgage Advisor",
}

@Injectable({ providedIn: 'root' })
export class SelectUserService {
  public userSelected$ = new Subject<UserSimple | UserSimple[]>();
  public initial: UserSimple | UserSimple[];
  public users$: Promise<UserSimple[]>;
  public heading = SelectUserHeadingType.SELECT_USER;
  public multi = false;
  public detailed = false;
}
