import { Directive, HostBinding, OnDestroy } from '@angular/core';
import { TableDirective } from '../table-utils/table.directive';

@Directive({
  selector: '[hfStickyTableHeadings]'
})
export class StickyTableHeadingsDirective implements OnDestroy {
  @HostBinding("class.isSticky")
  public isSticky = false;

  private intersectionObserver: IntersectionObserver;
  private resizeObserver: any;

  private tableElement: Element;
  private headingElement: HTMLElement;
  private headingWrapperElement: HTMLElement;

  constructor(
    private table: TableDirective,
  ) { }

  public ngAfterViewInit() {
    this.setupNavigationObserver();
  }

  private setupNavigationObserver() {
    if (!IntersectionObserver) {
      return;
    }

    this.tableElement = this.table.elementRef.nativeElement;
    this.headingElement = this.tableElement.querySelector(".everglades-table-headings");
    this.headingWrapperElement = this.tableElement.querySelector(".everglades-table-headings-sticky-wrapper");

    if (!this.headingWrapperElement) {
      return;
    }

    const callback: IntersectionObserverCallback = (entries) => {
      entries.forEach(e => {
        this.isSticky = !e.isIntersecting;
      });
    };

    this.intersectionObserver = new IntersectionObserver(callback);
    this.intersectionObserver.observe(this.headingWrapperElement);

    // @ts-ignore
    if (!ResizeObserver) {
      return;
    }

    // @ts-ignore
    this.resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {

        if (entry.contentRect) {
          this.headingElement.style.width = `${entry.contentRect.width}px`;
        }
      }
    });

    this.resizeObserver.observe(this.tableElement);
  }

  public ngOnDestroy() {
    this.intersectionObserver?.disconnect();
    this.resizeObserver?.disconnect();
  }
}
