import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'hf-user-selector',
  templateUrl: './user-selector.component.html',
  styleUrls: ['./user-selector.component.scss']
})
export class UserSelectorComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() controlName = 'advisor';
  @Input() label = 'Protection Advisor';
  @Input() placeholder = 'Select Protection Advisor';
  @Output() selectUser = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

}
