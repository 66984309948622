import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";

import { ToasterService } from "../../../services/toaster.service";
import { fadeAnimation, sidepaneMove } from "../../../shared/animations/sidepane-animation";
import { InterestedPartyApiService, NotificationParty } from '../../../services/interested-party-api.service';
import { MortgagesService } from '../mortgages.service';
import { NOTIFICATION_PARTY_TYPE, NOTIFICATION_PARTY_TYPE_NAME } from '../../../utils/variables.data';
import { DialogService, Confirmation } from '../../../../../../projects/client/src/app/shared/services/dialog.service';
import { NotificationsService } from './notifications.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToggleNotificationService } from '../toggle-notifications/toggle-notifications.service';

@Component({
  selector: 'hf-notifications',
  templateUrl: 'notifications.component.html',
  styleUrls: [
    'notifications.component.scss',
    '../../../styles/sidepanes.partial.scss'
  ],
  animations: [sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove]': 'true' }
})
export class NotificationsComponent implements OnDestroy {
  public list: NotificationParty[];
  public get mortgage() { return this.mortgagesService.data }

  public NOTIFICATION_PARTY_TYPE_NAME = NOTIFICATION_PARTY_TYPE_NAME;
  public readonlyPartyTypes: NOTIFICATION_PARTY_TYPE[] = ["INTRODUCER", "LEAD_APPLICANT"];
  public isLoading = true;

  private get mortgageId() { return this.route.snapshot.paramMap.get("id") }
  private destroy$ = new Subject();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private interestedPartyApiService: InterestedPartyApiService,
    private mortgagesService: MortgagesService,
    private notificationsService: NotificationsService,
    private toggleNotificationService: ToggleNotificationService,
    private dialogService: DialogService,
    private toasterService: ToasterService,
  ) {
    this.initialize();

    this.notificationsService.refresh$.pipe(takeUntil(this.destroy$)).subscribe(() => this.initialize());
  }

  public async initialize() {
    this.isLoading = true;

    this.list = await this.interestedPartyApiService.getNotificationsList(this.mortgageId);

    this.isLoading = false;
  }

  private async unlink() {
    const confirmation: Confirmation = {
      title: "Unlink Introducer",
      message: "Unlinking the original introducer will disable their notifications and remove all visibility of this mortgage from their account. The system will remember they were the original introducer for commission purposes.",
      acceptLabel: "Unlink"
    };

    if (! await this.dialogService.confirm(confirmation)) {
      return;
    }

    try {
      await this.interestedPartyApiService.unlinkIntroducer(this.mortgageId);
      this.mortgagesService.reloadMortgageDashboard.next();
      this.initialize();

      this.toasterService.callToaster({ severity: 'info', summary: 'Info', detail: 'Introducer unlinked.' });
    } catch (err) {
      this.toasterService.callToaster({ severity: 'error', summary: 'Error', detail: `Failed. Please try again. ${err.error.error.message}` });
    }
  }

  private async relink() {
    const confirmation: Confirmation = {
      title: "Relink Introducer",
      message: "Relinking the introducer will re-enable notifications to the original sales advisor and will become accessible in Everglades again.",
      acceptLabel: "Relink"
    };

    if (! await this.dialogService.confirm(confirmation)) {
      return;
    }

    try {
      await this.interestedPartyApiService.relinkIntroducer(this.mortgageId);
      this.mortgagesService.reloadMortgageDashboard.next();
      this.initialize();

      this.toasterService.callToaster({ severity: 'info', summary: 'Info', detail: 'Introducer relinked.' });
    } catch (err) {
      this.toasterService.callToaster({ severity: 'error', summary: 'Error', detail: `Failed. Please try again. ${err.error.error.message}` });
    }
  }

  public async onLinkUnlinkClicked() {
    if (this.mortgage.introducerLinkBroken) {
      this.relink()
    } else {
      this.unlink();
    }
  }

  public onAddInterestedPartyClicked() {
    this.router.navigate(["addInterestedParty"], { relativeTo: this.route });
  }

  public onInterestedPartyClicked(item: NotificationParty) {
    if (item.interestedPartyId) {
      this.router.navigate(["interestedParty", item.interestedPartyId], { relativeTo: this.route });
    }

    if (item.type === "SUB_APPLICANT") {
      this.toggleNotificationService.applicantId = item.applicantId;
      this.toggleNotificationService.applicantName = item.name;
      this.toggleNotificationService.applicantUnsubscribed = item.applicantUnsubscribed;

      this.router.navigate(["toggleNotifications"], { relativeTo: this.route });
    }
  }

  public onBack() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }
}
