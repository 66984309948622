import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, ActionType, ofActionSuccessful, Store } from '@ngxs/store';
import { merge, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import {
  Confirmation,
  DialogService,
  ToastService,
} from '../../../../../../projects/client/src/app/shared/services';
import { MortgageTableParams } from '../../../models/mortgage-dashboard';
import { TaskTableParams } from '../../../models/task';
import { ApiService } from '../../../services/api.service';
import { DocumentApiService } from '../../../services/document-api.service';
import { DocumentTitleService } from '../../../services/document-title.service';
import { Environment } from '../../../services/environment.service';
import { JournalApiService } from '../../../services/journal-api.service';
import { MortgageApiService } from '../../../services/mortgage-api.service';
import { NavigationService } from '../../../services/navigation.service';
import { SessionStoreService } from '../../../services/session-store.service';
import { UserService } from '../../../services/user.service';
import {
  AddProtectionService,
  ArrangeAppointmentService,
  LogContactAttemptService,
  NotProceedingService,
  NOT_PROCEEDING_SERVICE,
  RequestFactFindService,
  REQUEST_FACTFIND_SERVICE,
  SwitchSalesAdvisorService,
  SWITCH_SALES_ADVISOR_SERVICE,
} from '../../../shared-layout/side-panes';
import { DataSourceFactory } from '../../../shared/table-utils/table-settings.service';
import {
  MORTGAGE_MENU_ACTIONS,
  MORTGAGE_STATES,
  MORTGAGE_STATE_TYPE,
} from '../../../utils/variables.data';
import {
  TaskApiService,
  TaskListFilterDTO,
  TaskListParamsDTO,
} from '../../task/task-api.service';
import {
  MortgagesService,
  ReloadMortgageDashboardEvent,
} from '../mortgages.service';
import { Mortgage } from '../state/mortgage.actions';
import { DashboardActionModel } from './dashboard-action.model';

@Component({
  selector: 'hf-mortgage-dashboard',
  templateUrl: './mortgage-dashboard.component.html',
  styleUrls: ['./mortgage-dashboard.component.scss'],
})
export class MortgageDashboardComponent implements OnInit, OnDestroy {
  public tab: string;
  public id = '';
  public MORTGAGE_STATES = MORTGAGE_STATES;
  public mortgageMenuItems: DashboardActionModel[] = [];

  public primaryActionButtonLabel: string;
  public primaryActionButtonRoute: string;
  public primaryActionButtonIcon: string;
  public containsVulnerableCustomer = false;

  private destroy$ = new Subject();

  constructor(
    private store: Store,
    private actions$: Actions,
    private router: Router,
    private environment: Environment,
    private navigationService: NavigationService,
    private activatedRoute: ActivatedRoute,
    private mortgageApi: MortgageApiService,
    private mortgagesService: MortgagesService,
    private documentTitleService: DocumentTitleService,
    private dialogService: DialogService,
    private apiService: ApiService,
    private toastService: ToastService,
    private userService: UserService,
    private sessionStoreService: SessionStoreService,
    private addProtectionService: AddProtectionService,
    private journalApiService: JournalApiService,
    private documentApiService: DocumentApiService,
    private taskApiService: TaskApiService,
    private arrangeAppointmentService: ArrangeAppointmentService,
    private logContactAttemptService: LogContactAttemptService,
    @Inject(NOT_PROCEEDING_SERVICE)
    private notProceedingService: NotProceedingService,
    @Inject(SWITCH_SALES_ADVISOR_SERVICE)
    private switchSalesAdvisorService: SwitchSalesAdvisorService,
    @Inject(REQUEST_FACTFIND_SERVICE)
    private requestFactFindService: RequestFactFindService,
  ) {
    this.activatedRoute.queryParams
      .pipe(
        takeUntil(this.destroy$),
        filter((p) => p.tab),
      )
      .subscribe((params) => {
        this.tab = params.tab;
      });

    this.addProtectionService.add
      .pipe(takeUntil(this.destroy$))
      .subscribe((id) => {
        this.router.navigate(['/app/protection/' + id]);
      });

    this.actions$
      .pipe(takeUntil(this.destroy$), ofActionSuccessful(Mortgage.MenuAction))
      .subscribe((action: Mortgage.MenuAction) => {
        action.action === 'arrangeAppointment' && this.onArrangeAppointment();

        if (action.action === 'addContactAttempt') {
          this.prepareLogContactData();
          this.router.navigate(['addContactAttempt'], {
            relativeTo: this.activatedRoute,
          });
        }
      });
  }

  public get data() {
    return this.mortgagesService.data;
  }
  public get isIntroducer() {
    return this.userService.isIntroducer();
  }

  public get isFactFindVisible(): boolean {
    return (
      this.userService.hasPermission('MORTGAGE_MANAGE') &&
      !!this.mortgagesService.data &&
      !!this.mortgagesService.data.factFind
    );
  }

  public get isClientSheetVisible(): boolean {
    return (
      this.userService.hasPermission('MORTGAGE_MANAGE') &&
      !!this.mortgagesService.data &&
      !!this.mortgagesService.data.clientSheetAvailable
    );
  }

  public get isCreditReportVisible(): boolean {
    return (
      this.userService.hasPermission('MORTGAGE_MANAGE') &&
      this.mortgagesService.data?.hasCreditReport
    );
  }

  public journalDataSourceFactory: DataSourceFactory<MortgageTableParams> = (
    params,
  ) => {
    params.mortgageId = this.id;

    return this.journalApiService.listMortgageJournal(params);
  };

  public documentsDataSourceFactory: DataSourceFactory<MortgageTableParams> = (
    params,
  ) => {
    params.mortgageId = this.id;
    return this.documentApiService.listMortgageDocuments(params);
  };

  public taskDataSourceFactory: DataSourceFactory<TaskTableParams> = (
    params,
  ) => {
    const taskListParams: TaskListParamsDTO = {
      ...params,
      type: 'MORTGAGE',
      mortgageId: this.id,
    };

    const taskListFilter: TaskListFilterDTO = {
      filter: params.filter,
      viewCompleted: params.viewCompleted,
    };

    return this.taskApiService.listMortgageTasks(
      taskListParams,
      taskListFilter,
    );
  };

  public async ngOnInit() {
    this.activatedRoute.paramMap
      .pipe(takeUntil(this.destroy$))
      .subscribe((item) => {
        this.id = item.get('id');
      });

    this.mortgageApi
      .getDashboardData(this.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data) => {
          if (data.dashboardType !== 'MORTGAGE') {
            this.router.navigate(['/app/remortgages', data.id]);
            return;
          }

          this.mortgagesService.data = data;
          this.containsVulnerableCustomer = data.applicants.find(
            (applicant) => applicant.vulnerableCustomer,
          )
            ? true
            : false;
          this.mortgageMenuItems = this.initMortgageMenuItems();
          this.setPrimaryActionButton();
          this.mortgagesService.mortgageDashboardDataLoaded.next();
          this.documentTitleService.setTitleParams({
            client: this.mortgagesService.data.client.fullName,
          });
          this.store.dispatch(new Mortgage.Initialize(this.id, data));
        },
        (e) => {
          this.toastService.add('Mortgage not found', 'error', e);
          this.onBack();
        },
      );

    merge(
      this.mortgagesService.reloadMortgageDashboard,
      this.arrangeAppointmentService.appointmentSubmitted$,
      this.logContactAttemptService.contactAttemptAdded$,
      this.notProceedingService.stateChange$,
      this.switchSalesAdvisorService.advisorChange$,
      this.requestFactFindService.requested$,
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe((event: ReloadMortgageDashboardEvent) =>
        this.reloadData(event),
      );

    const refreshFor: ActionType[] = [
      Mortgage.CreditReportTriggered,
      Mortgage.TargetCompletionDateUpdated,
      Mortgage.TargetExchangeDateUpdated,
    ];
    this.actions$
      .pipe(takeUntil(this.destroy$), ofActionSuccessful(...refreshFor))
      .subscribe(() => {
        this.reloadData();
      });
  }

  public ngOnDestroy() {
    this.mortgagesService.data = {} as any;
    this.destroy$.next();

    this.sessionStoreService.set('app/mortgages/#');
    this.documentTitleService.clearTitleParams();
  }

  public onBack() {
    this.navigationService.mortgageDashboardGoBack();
  }

  public onActiveTabKeyChanged(tab: string) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { tab },
    });
  }

  public async onPrimaryActionButtonClick() {
    // FIXME martinbarnas 17/04/2020: MORTGAGE_ACTIONS and MORTGAGE_MENU_ACTIONS should be somehow linkable
    if ('ATTEMPT_CONTACT' === this.mortgagesService.data.nextAction) {
      this.prepareLogContactData();
    }

    if ('PROGRESS_APPLICATION' === this.mortgagesService.data.nextAction) {
      const application = this.data.applications[0];

      return this.router.navigate(['updateApplication', application.id], {
        relativeTo: this.activatedRoute,
      });
    }

    if ('COMPLIANCE_HANDOVER' === this.mortgagesService.data.nextAction) {
      this.onComplianceHandoverClicked();
      return;
    }

    this.router.navigate([this.primaryActionButtonRoute], {
      relativeTo: this.activatedRoute,
    });
  }

  public async onItemSelected(event: DashboardActionModel) {
    if (event.type === MORTGAGE_MENU_ACTIONS.NOT_PROCEEDING) {
      this.onClientNotProceedingClicked();
      return;
    }

    if (event.routerLink) {
      this.router.navigate(['./' + event.routerLink], {
        relativeTo: this.activatedRoute,
        queryParams: event.queryParams,
      });
      return;
    }

    if (event.callback) {
      event.callback();
      return;
    }

    if (event.type == MORTGAGE_MENU_ACTIONS.ASSIGN_CASE_PROGRESSION) {
      const queryParams = {
        submit: true,
      };

      this.router.navigate(['assignCaseProgression'], {
        queryParams,
        relativeTo: this.activatedRoute,
      });
    }

    if (event.type == MORTGAGE_MENU_ACTIONS.DELETE) {
      if (
        (this.mortgagesService.data.state as MORTGAGE_STATE_TYPE) ===
        'NOT_PROCEEDING'
      ) {
        const confirmation: Confirmation = {
          title: 'Delete Mortgage',
          message: 'Are you sure you want to delete this Mortgage?',
          acceptLabel: 'Delete',
        };

        if (!(await this.dialogService.confirm(confirmation))) {
          return;
        }

        await this.apiService
          .get('mortgage', 'deleteMortgage?mortgageId=' + this.id)
          .toPromise();

        this.toastService.add('Mortgage deleted');
        this.onBack();
      } else {
        const confirmation: Confirmation = {
          title: 'Delete Mortgage',
          message:
            'In order to delete this mortgage you first have to mark it as Not Proceeding.',
          acceptLabel: 'Not Proceeding',
        };

        if (!(await this.dialogService.confirm(confirmation))) {
          return;
        }

        this.router.navigate(['notProceeding'], {
          relativeTo: this.activatedRoute,
        });
      }
    }
  }

  private onClientNotProceedingClicked() {
    this.mortgageApi.getMortgageApplications(this.id).then(async (result) => {
      const applications = result;
      for (let i = 0; i < applications.length; i++) {
        if (
          applications[i].state !== 'NOT_PROCEEDING' &&
          applications[i].state !== 'COMPLETED'
        ) {
          const confirmation: Confirmation = {
            title: 'Client Not Proceeding',
            message:
              ' Please mark each individual application as not proceeding first.',
            acceptLabel: 'OK',
            acceptButtonType: 'primary',
            rejectButtonType: undefined,
          };
          await this.dialogService.confirm(confirmation);
          return;
        }
      }
      this.router.navigate(['./notProceeding'], {
        relativeTo: this.activatedRoute,
      });
    });
  }

  private onUndoClientNotProceedingClicked() {
    const confirmation: Confirmation = {
      title: 'Undo Not Proceeding',
      message:
        'Are you sure you want reactivate this mortgage back to Proceeding?',
      acceptLabel: 'Reactivate',
    };

    const doUndo = async () => {
      try {
        await this.mortgageApi.reactivateMortgage(this.id);

        this.toastService.add('Mortgage reactivated');
        this.reloadData();
      } catch (err) {
        this.toastService.add('Failed.', 'error', err);
      }
    };

    this.dialogService.confirmAndDo(confirmation, doUndo);
  }

  private setPrimaryActionButton() {
    this.primaryActionButtonLabel = null;
    this.primaryActionButtonRoute = null;

    switch (this.mortgagesService.data.nextAction) {
      case 'ASSIGN_ADVISOR': {
        this.primaryActionButtonLabel = 'Assign Mortgage';
        this.primaryActionButtonRoute = './assignMortgage';
        break;
      }

      case 'ATTEMPT_CONTACT': {
        this.primaryActionButtonLabel = 'Log Contact';
        this.primaryActionButtonRoute = './addContactAttempt';
        break;
      }

      case 'ARRANGE_APPOINTMENT': {
        this.primaryActionButtonLabel = 'Arrange Appointment';
        this.primaryActionButtonRoute = './arrangeAppointment';
        break;
      }

      case 'REQUEST_FACT_FIND ': {
        this.primaryActionButtonLabel = 'Log Contact';
        this.primaryActionButtonRoute = './addContactAttempt';
        break;
      }

      case 'SUBMIT_APPLICATION': {
        this.primaryActionButtonLabel = 'Submit Application';
        this.primaryActionButtonRoute = './createApplication';
        break;
      }

      case 'PROGRESS_APPLICATION': {
        if (this.data?.applications?.length === 1) {
          this.primaryActionButtonLabel = 'Progress Application';
          this.primaryActionButtonRoute = './updateApplication';
        }
        break;
      }

      case 'QUALIFY_CLIENT': {
        this.primaryActionButtonLabel = 'Qualify Client';
        this.primaryActionButtonRoute = './offer-qualification';
        break;
      }

      case 'COMPLIANCE_HANDOVER': {
        this.primaryActionButtonLabel = 'Compliance Handover';
        break;
      }
    }

    if (this.data?.state === 'COMPLETED') {
      this.primaryActionButtonLabel = 'Add Remortgage';
      this.primaryActionButtonRoute = 'addRemortgage';
      this.primaryActionButtonIcon = 'add_circle';
    } else {
      this.primaryActionButtonIcon = undefined;
    }
  }

  private initMortgageMenuItems() {
    return [
      {
        type: MORTGAGE_MENU_ACTIONS.EDIT,
        label: 'Edit...',
        routerLink: 'edit',
        permission: 'MORTGAGE_EDIT',
      },
      {
        type: MORTGAGE_MENU_ACTIONS.ASSIGN_ADVISOR,
        label: 'Assign Advisor...',
        routerLink: 'assignMortgage',
      },
      {
        type: MORTGAGE_MENU_ACTIONS.ASSIGN_CASE_PROGRESSION,
        label: 'Assign Case Progression...',
        visible: () => this.isAssignCaseProgressionMenuItemVisible(),
      },
      {
        type: MORTGAGE_MENU_ACTIONS.COMPLIANCE_HANDOVER,
        label: 'Compliance Handover...',
        callback: () =>
          this.onComplianceHandoverClicked() /* visible: () => this.mortgagesService.data && this.mortgagesService.data.factFind && this.mortgagesService.data.factFind.status === "APPROVED" */,
      },
      {
        type: MORTGAGE_MENU_ACTIONS.ADD_DOCUMENT,
        label: 'Add Document...',
        routerLink: 'addDocument',
      },
      {
        type: MORTGAGE_MENU_ACTIONS.ADD_NOTE,
        button: true,
        label: 'Add Note...',
        routerLink: 'addNote',
      },
      {
        type: MORTGAGE_MENU_ACTIONS.ADD_TASK,
        button: true,
        label: 'Add Task...',
        routerLink: 'addTask',
      },
      {
        type: MORTGAGE_MENU_ACTIONS.ADD_CONTACT_ATTEMPT,
        button: true,
        label: 'Log Contact...',
        routerLink: 'addContactAttempt',
        beforeClickFn: () => this.prepareLogContactData(),
      },
      {
        type: MORTGAGE_MENU_ACTIONS.REQUEST_DOCUMENT,
        label: 'Request Document...',
        routerLink: 'requestDocument',
        disabled: !this.environment.requestFactFindEnabled,
      },
      {
        label: 'Run Credit Report...',
        routerLink: 'runCreditReport',
        visible: () => this.isRunCreditReportMenuItemVisible(),
      },
      {
        type: MORTGAGE_MENU_ACTIONS.NOTIFICATIONS,
        label: 'Notifications...',
        routerLink: 'notifications',
        permission: 'INTERESTED_PARTY_MANAGE',
      },
      {
        type: MORTGAGE_MENU_ACTIONS.ADD_PROTECTION_CASE,
        label: 'Create Protection Case...',
        routerLink: 'addProtection',
        permission: 'PROTECTION_NEW',
        visible: () =>
          this.mortgagesService.data &&
          !this.mortgagesService.data.hasActiveProtectionCase,
        beforeClickFn: () => {
          this.addProtectionService.mortgageId = this.mortgagesService.data
            .advisor
            ? this.id
            : null;
          this.addProtectionService.mortgageAdvisorId = this.mortgagesService
            .data.advisor
            ? this.mortgagesService.data.advisor.id
            : null;
        },
      },
      {
        label: 'Suitability Letter...',
        routerLink: 'suitability-letter',
        visible: () => this.isSuitabilityLetterVisible(),
      },
      {
        type: MORTGAGE_MENU_ACTIONS.PUSH_TO_MIDAS,
        label: 'Midas Resolution...',
        routerLink: 'push-to-midas',
      },
      {
        type: MORTGAGE_MENU_ACTIONS.NOT_PROCEEDING,
        label: 'Client Not Proceeding...',
        routerLink: 'notProceeding',
        visible: () => this.mortgagesService.data.state !== 'NOT_PROCEEDING',
        styles: { color: '#ED3A26' },
      },
      {
        label: 'Undo Client Not Proceeding',
        callback: () => this.onUndoClientNotProceedingClicked(),
        visible: () => this.mortgagesService.data.state === 'NOT_PROCEEDING',
      },
      {
        type: MORTGAGE_MENU_ACTIONS.DELETE,
        label: 'Delete',
        styles: { color: '#ED3A26' },
        permission: 'MORTGAGE_REMOVE',
      },
    ];
  }

  private onComplianceHandoverClicked() {
    if (this.data?.handoverDrafts?.length > 0) {
      const [handover] = this.data.handoverDrafts;
      this.router.navigate(['newComplianceHandover', handover.id], {
        relativeTo: this.activatedRoute,
      });
    } else {
      this.router.navigate(['newComplianceHandover'], {
        relativeTo: this.activatedRoute,
      });
    }
  }

  private isAssignCaseProgressionMenuItemVisible() {
    return (
      this.data?.applications?.filter((a) => a.state !== 'NOT_PROCEEDING')
        .length > 0
    );
  }

  private isRunCreditReportMenuItemVisible() {
    const factFindStatus = this.mortgagesService.data?.factFind?.status;

    return factFindStatus && factFindStatus !== 'REQUESTED';
  }

  private prepareLogContactData() {
    this.logContactAttemptService.notifyIntroducerVisible = true;
    this.arrangeAppointmentService.noSubmitMode = false;
    this.logContactAttemptService.appointmentVisible =
      !this.mortgagesService.data.appointment ||
      this.mortgagesService.data.appointment.state !== 'PENDING';
    this.logContactAttemptService.currentAdvisor =
      this.mortgagesService.data.advisor;
    this.logContactAttemptService.client = this.mortgagesService.data.client;
    this.logContactAttemptService.state = this.mortgagesService.data.state;
  }

  private async reloadData(event?: ReloadMortgageDashboardEvent) {
    this.mortgageApi
      .getDashboardData(this.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data) => {
          this.mortgagesService.data = data;
          this.setPrimaryActionButton();
          event?.tab && this.onActiveTabKeyChanged(event.tab);
        },
        (e) => {
          this.toastService.add('Error reloading mortgage', 'error', e);
        },
      );
  }

  private onArrangeAppointment() {
    this.arrangeAppointmentService.noSubmitMode = false;
    this.arrangeAppointmentService.currentAdvisor = this.data.advisor;
    this.arrangeAppointmentService.currentAppointment = this.data.appointment;
    this.arrangeAppointmentService.client = this.data.client;

    const allowedMortgageStateForFactFindRequest: MORTGAGE_STATE_TYPE[] = [
      'IN_PROGRESS',
      'QUALIFIED',
      'PARTIALLY_QUALIFIED',
    ];

    this.arrangeAppointmentService.isFactFindRequestAllowed =
      allowedMortgageStateForFactFindRequest.includes(this.data.state) &&
      !this.data.factFind;

    this.router.navigate(['arrangeAppointment'], {
      relativeTo: this.activatedRoute,
    });
  }

  private isSuitabilityLetterVisible(): boolean {
    return (
      this.data.factFind?.status === 'APPROVED' &&
      !!this.mortgagesService.approvedHandovers?.length
    );
  }
}
