import { Component, Input } from '@angular/core';
import { FormGroup } from "@angular/forms";

@Component({
  selector: 'hf-custom-datepicker',
  templateUrl: './custom-datepicker.component.html',
  styleUrls: ['./custom-datepicker.component.scss']
})

export class CustomDatepickerComponent {
  @Input() public group: FormGroup;
  @Input() public pickerFormControlName: string;
  @Input() isInline = false;
  @Input() public minDate?: Date;
  @Input() public maxDate?: Date;
  @Input() public monthNavigator: boolean = false;
  @Input() public yearNavigator: boolean = false;
  @Input() public yearRange: boolean = false;

  public onMonthChanged(event: { month: number, year: number }) {
    const date: Date = this.group?.value?.date;

    if (date) {
      let newDate = new Date(event.year, event.month - 1, date.getDate());

      if (newDate.getMonth() !== (event.month - 1) || newDate.getFullYear() !== event.year) {
        newDate = new Date(event.year, event.month - 1, 1);
      }

      this.group.get(this.pickerFormControlName).setValue(newDate);
    }
  }
}
