import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from "../../../shared/shared.module";
import { CustomButtonModule } from '../../../shared/custom-button/custom-button.module';
import { CustomTextFieldModule } from '../../../shared/custom-text-field/custom-text-field.module';
import { SelectPhonePrefixComponent } from './select-phone-prefix.component';
import {PhonePrefixService} from "./phone-prefix.service";

@NgModule({
  declarations: [
    SelectPhonePrefixComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    CustomButtonModule,
    CustomTextFieldModule
  ],
  exports: [
    SelectPhonePrefixComponent
  ],
  providers: [
    PhonePrefixService
  ]
})

export class SelectPhonePrefixModule {
}
