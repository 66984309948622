<div class="hf-selector">
  <label>{{label}}</label>
  <div class="hf-selector-container hf-selector-has-value" [class.hf-selector-black-color]="!!form.get(controlName + '.id').value"
       (click)="selectUser.emit()">
    <div class="hf-selector-label-area">
      <div *ngIf="form.get(controlName + '.id').value" class="title">{{form.get(controlName).value.fullName}}</div>
      <div *ngIf="!form.get(controlName + '.id').value" class="title">{{placeholder}}</div>
    </div>
    <i class="material-icons chevron-icon">chevron_right</i>
  </div>
</div>
