import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class SessionStorageService {
  public getItem(key: string): string | any {
    const value = window.sessionStorage.getItem(key);

    try {
      const deserialized = JSON.parse(value);
      return deserialized.__value;

    } catch (e) { }

    return null;
  }

  public setItem(key: string, value: string | any) {
    const serialized = JSON.stringify({ __value: value });

    window.sessionStorage.setItem(key, serialized);
  }

  public clear() {
    window.sessionStorage.clear();
  }
}
