import { Component, Input } from '@angular/core';
import { CLIENT_CATEGORY } from '../../utils/variables.data';

@Component({
  selector: 'hf-client-category-icon',
  templateUrl: './client-category-icon.component.html',
  styleUrls: ['./client-category-icon.component.scss']
})
export class ClientCategoryIconComponent {
  @Input()
  public category: CLIENT_CATEGORY;
}
