import { Component, Input, OnChanges, OnInit, SimpleChanges, ContentChild, TemplateRef } from '@angular/core';

export interface InformationBoxValue {
  text: string;
  currency?: number;
  prefix?: string;
  suffix?: string;
}

export interface InformationBoxAction {
  label: string;
  callback: () => void;
  disabled?: boolean;
}

@Component({
  selector: 'hf-information-box',
  templateUrl: './information-box.component.html',
  styleUrls: ['./information-box.component.scss']
})
export class InformationBoxComponent implements OnInit, OnChanges {
  @Input() public heading: string;
  @Input() public lastUpdate: string;
  @Input() public lastUpdateVisible: boolean = false;
  @Input() public value: InformationBoxValue | string;
  @Input() public action: InformationBoxAction;
  @Input() public actionCallableApi: any = null;
  @Input() public actionVisible: boolean = false;

  @ContentChild("valueTemplate")
  valueTemplate: TemplateRef<any>;

  private lastUpdateValue: string;
  public outputValue: string;

  public get isCurrency() { return this.value && (typeof (this.value as InformationBoxValue).currency !== "undefined") }

  ngOnInit() {
    this.lastUpdateValue = this.lastUpdate;
    this.outputValue = this.createOutputValue(this.value as any);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('value')) {
      this.outputValue = this.createOutputValue(changes.value.currentValue);
    }
    if (changes.hasOwnProperty('lastUpdate')) {
      this.lastUpdateValue = changes.lastUpdate.currentValue
    }
  }

  private createOutputValue(value: InformationBoxValue): string {
    let output = '--';
    if (!value)
      return output;
    else {
      if (typeof value === "string") {
        return value;
      }
      if (value.hasOwnProperty('text') && value.text !== null && value.text !== undefined)
        output = value.text;
      if (value.hasOwnProperty('prefix'))
        output = value.prefix + output;
      if (value.hasOwnProperty('suffix'))
        output = output + value.suffix;
    }
    return output;
  }
}
