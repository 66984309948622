import { Component, EventEmitter, HostBinding, Input, OnInit, Optional, Output } from '@angular/core';
import { ControlValueAccessor, NgControl } from "@angular/forms";

@Component({
  selector: 'hf-checkbox',
  templateUrl: 'hf-checkbox.component.html',
  styleUrls: ['hf-checkbox.component.scss'],
})

export class HfCheckboxComponent implements OnInit, ControlValueAccessor{

  @Input() label:string;
  @Output() valueChanged:EventEmitter<boolean>= new EventEmitter();
  public _disabled:boolean;
  public _value:boolean;
  private isTouched:boolean;

  private propagateChange = (_: any) => {};
  private propagateTouch = (_: any) => {};

  @HostBinding("class.hf-invalid")
  public get hfInvalid() { 
    return this.ngControl?.invalid && this.ngControl?.dirty ;
  }

  constructor(
    @Optional() private ngControl: NgControl
  ) {
    this.ngControl && (this.ngControl.valueAccessor = this);
  }

  ngOnInit() {
    if(this._value === undefined) {
      this._value = false;
    }
  }

  registerOnChange(fn): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn): void {
    this.propagateTouch = fn;
  }

  setDisabledState(isDisabled:boolean): void {
    this._disabled = isDisabled;
  }

  writeValue(newValue:boolean): void {
    this._value = newValue;
  }

  public onChange() {
    this._value = !this._value;
    this.propagateChange(this._value);
    this.valueChanged.emit(this._value);

    if(!this.isTouched)
      this.propagateTouch(true);
  }

}
