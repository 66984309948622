<div class="header">
  <div class="heading">
    Personal Details
    <div class="applicant">{{ getApplicantName(applicantData) }}</div>

    <div *ngIf="config?.updatedBy" class="saved-by">
      Saved by {{ config.updatedBy.fullName }} at {{config?.lastUpdated | date:'HH:mm on E d MMM y'}}
    </div>
  </div>

  <hfc-button *ngIf="config.buttonType" [type]="config.buttonType" [isLoading]="config.buttonIsLoading" size="small" (click)="buttonClick.next(config)">{{ config.buttonLabel }}</hfc-button>
</div>

<div *ngIf="config.returnComment" class="returnComment">
  {{config.returnComment}}
  <div class="lastUpdated">{{config.lastUpdated | date:'d MMM y, HH:mm'}}</div>
</div>

<div *ngIf="config.stepStatus == 'NOT_STARTED'" class="notReady">
  Waiting for applicant to complete
</div>

<div *ngIf="config.stepStatus !== 'NOT_STARTED'" class="content">
  <div *ngIf="showSmartSearch && applicantData.smartSearchResult" (click)="onSmartSearchClicked(applicantData.smartSearchResult)" [attr.icon]="getSmartSearchResultIcon(applicantData.smartSearchResult)" [attr.errors]="applicantData.smartSearchResult.errors" class="smartSearchResult">
    <i class="material-icons">{{ getSmartSearchResultIcon(applicantData.smartSearchResult) }}</i>
    <div>
      {{ getSmartSearchResultLabel(applicantData.smartSearchResult) }}

      <div *ngIf="applicantData.smartSearchResult.errors" class="errors">
        {{applicantData.smartSearchResult.errors}}
      </div>
    </div>
  </div>

  <hfc-aml-result *ngIf="applicantData.experianAMLResult" [result]="applicantData.experianAMLResult"></hfc-aml-result>

  <div class="column">
    <div class="record">
      <span class="label">Title</span>
      <span class="value">{{ PERSONAL_TITLE_NAME.get(applicantData.personalDetails.title) }}</span>
    </div>
    <div class="record">
      <span class="label">First Name</span>
      <span class="value">{{ applicantData.personalDetails.firstName }}</span>
    </div>
    <div class="record" *ngIf="applicantData.personalDetails.middleName">
      <span class="label">Middle Name(s)</span>
      <span class="value">{{ applicantData.personalDetails.middleName }}</span>
    </div>
    <div class="record">
      <span class="label">Surname</span>
      <span class="value">{{ applicantData.personalDetails.lastName }}</span>
    </div>
    <div class="record">
      <span class="label">Gender</span>
      <span class="value">{{ applicantData.personalDetails.otherGender || GENDER_NAME.get(applicantData.personalDetails.gender) }}</span>
    </div>
    <div class="record" *ngIf="applicantData.personalDetails.vulnerableCustomer !== null">
      <span class="label">Vulnerable Customer</span>
      <span [ngClass]="applicantData.personalDetails.vulnerableCustomer ? 'text-medium-red' : 'value'">{{ applicantData.personalDetails.vulnerableCustomer ? 'Yes' : 'No' }}</span>
    </div>

    <ng-container *ngIf="applicantData.personalDetails.changedName">
      <div class="record">
        <span class="label">Previous Surname</span>
        <span class="value">{{ applicantData.personalDetails.previousSurname }}</span>
      </div>
      <div class="record">
        <span class="label">Reason for name change</span>
        <span class="value">{{ applicantData.personalDetails.nameChangeOtherReason || NAME_CHANGE_REASON_NAMES.get(applicantData.personalDetails.nameChangeReason) }}</span>
      </div>
      <div class="record">
        <span class="label">Date of Name Change</span>
        <span class="value">{{ applicantData.personalDetails.nameChangeDate|date:'d MMM y' }}</span>
      </div>
    </ng-container>

    <div class="record">
      <span class="label">Marital Status</span>
      <span class="value">{{ MARTITAL_STATUS_NAME.get(applicantData.personalDetails.maritalStatus) }}</span>
    </div>
    <div *ngIf="applicantData.personalDetails.maritalStatus === 'MARRIED'" class="record">
      <span class="label">Date of Marriage</span>
      <span class="value">{{ applicantData.personalDetails.marriageDate|date:'d MMM y' }}</span>
    </div>
  </div>

  <div class="column">
    <div class="record">
      <span class="label">Your Date of Birth</span>
      <span class="value">
        <span style="margin-right: 8px; white-space: nowrap;">{{ applicantData.personalDetails.birthDate|date:'d MMM y' }}</span>
        <hfc-aml-result-small *ngIf="applicantData.experianAMLResult" [match]="applicantData.experianAMLResult.dobMatch"></hfc-aml-result-small>
      </span>
    </div>

    <div class="record">
      <span class="label">Phone</span>
      <span class="value">{{ applicantData.personalDetails.phonePrefix }} {{ applicantData.personalDetails.phone }}</span>
    </div>
    <div class="record">
      <span class="label">Email Address</span>
      <span class="value">{{ applicantData.personalDetails.email }}</span>
    </div>

    <div class="record">
      <span class="label">Nationality</span>
      <span class="value">{{ applicantData.personalDetails.nationality.name }}</span>
    </div>
    <div class="record">
      <span class="label">Visa Status</span>
      <span class="value">{{ VISA_STATUS_NAMES.get(applicantData.personalDetails.visaStatus) }}</span>
    </div>
  </div>
</div>
