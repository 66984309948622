import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ChangeDetectorRef, Directive, ElementRef, HostBinding, HostListener, Input, OnDestroy, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil, filter } from 'rxjs/operators';
import { isIOS } from '../../../../../projects/client/src/app/shared/functions/is-ios';
import { TooltipTextContentComponent } from '../tooltip-text-content/tooltip-text-content.component';

@Directive({
  selector: '[hfTooltip]'
})
export class TooltipDirective implements OnDestroy {
  @Input("hfTooltip")
  public content: string;

  @Input()
  component: any;

  @Input()
  componentInputs: {};

  private overlayRef: OverlayRef;
  private show$ = new Subject<boolean>();
  private destroy$ = new Subject();

  constructor(
    private el: ElementRef,
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    if (isIOS) {
      return;
    }

    this.show$.pipe(
      takeUntil(this.destroy$),
      filter(() => this.content || this.component),
      debounceTime(250),
      distinctUntilChanged()
    ).subscribe(show => show ? this.show() : this.hide());
  }

  @HostListener("mouseenter")
  public mouseenter() {
    this.show$.next(true);
  }
  @HostListener("mouseleave")
  public mouseleave() {
    this.show$.next(false);
  }
  @HostListener("click")
  public click() {
    this.hide();
  }

  @HostBinding("style.cursor")
  public get cursor() { return !!this.content ? "help" : null };

  public ngOnDestroy() {
    this.destroy$.next();
    this.dispose();
  }

  private show() {
    this.buildOverlayRef();

    const component = new ComponentPortal<TooltipTextContentComponent>(TooltipTextContentComponent, this.viewContainerRef);
    const componentRef = this.overlayRef.attach(component);

    componentRef.instance.content = this.content;
    componentRef.instance.component = this.component;
    componentRef.instance.componentInputs = this.componentInputs;

    this.changeDetectorRef.detectChanges();
  }

  private hide() {
    this.overlayRef?.detach();
  }

  private dispose() {
    this.hide();
    this.overlayRef?.dispose();
    this.overlayRef = undefined;
  }

  private buildOverlayRef() {
    if (this.overlayRef) {
      return;
    }

    const position = {
      originX: 'center',
      originY: 'top',
      overlayX: 'center',
      overlayY: 'bottom',
      offsetY: -20
    }

    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo(this.el)
      .withPositions([position as any]);

    const scrollStrategy = this.overlay.scrollStrategies.close();

    this.overlayRef = this.overlay.create({ positionStrategy, scrollStrategy });

    this.overlayRef.overlayElement.addEventListener("mouseenter", () => this.show$.next(true));
    this.overlayRef.overlayElement.addEventListener("mouseleave", () => this.show$.next(false));
  }
}
