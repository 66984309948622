import { CurrencyPipe } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { take, takeUntil } from 'rxjs/operators';

import { Environment } from '../../../services/environment.service';
import { MortgageApiService, OfferQualification } from '../../../services/mortgage-api.service';
import { ToasterService } from '../../../services/toaster.service';
import { ArrangeAppointmentService } from '../../../shared-layout/side-panes/arrange-appointment/arrange-appointment.service';
import { fadeAnimation, sidepaneMove } from '../../../shared/animations/sidepane-animation';
import { OFFER_QUALIFICATION_STATUS } from '../../../utils/variables.data';
import { MortgagesService } from '../mortgages.service';
import { SelectDateAndTimeService } from '../select-date-and-time/select-date-and-time.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'hf-offer-qualification',
  templateUrl: './offer-qualification.component.html',
  styleUrls: [
    'offer-qualification.component.scss',
    '../../../styles/sidepanes.partial.scss'
  ],
  animations: [sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove]': 'true' },
  providers: [CurrencyPipe]
})
export class OfferQualificationComponent implements OnDestroy {
  public form = this.fb.group({
    status: [null, Validators.required],
    qualifiedUpTo: [null, Validators.required],
    purchasePrice: [null],
    helpToBuyLoan: [null],
    depositAmount: [null],
    mortgageAmount: [null],
    clientProceeding: [false, Validators.required],
    sendFactFind: [false],
    appointment: [null],

    journal: this.fb.group({
      internalNotes: [""],
      messageToIntroducer: [""],
      notifyClient: [true],
      messageToClient: ["", Validators.required],
    })
  });

  public isLoading = true;
  public qualifiedOptions = {
    QUALIFIED: "Qualified for",
    PARTIALLY_QUALIFIED: "Qualified for lower amount",
    NOT_QUALIFIED: "Not qualified"
  };

  private get mortgageId() { return this.route.snapshot.params["id"] }
  private destroy$ = new Subject();

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private currencyPipe: CurrencyPipe,
    private mortgageApiService: MortgageApiService,
    private arrangeAppointmentService: ArrangeAppointmentService,
    private selectDateAndTimeService: SelectDateAndTimeService,
    private mortgagesService: MortgagesService,
    private toasterService: ToasterService,
    private environment: Environment
  ) {
    this.form.get("qualifiedUpTo").disable();
    this.form.get("clientProceeding").disable();
    this.form.get("sendFactFind").disable();
    this.form.get("appointment").disable();

    this.form.get("status").valueChanges.subscribe((status: OFFER_QUALIFICATION_STATUS) => {
      if (status === "PARTIALLY_QUALIFIED") {
        this.form.get("qualifiedUpTo").enable();
      } else {
        this.form.get("qualifiedUpTo").disable();
      }

      if (status === "QUALIFIED" || status === "PARTIALLY_QUALIFIED") {
        this.form.get("clientProceeding").enable();
        this.form.get("clientProceeding").setValue(false);
      } else {
        this.form.get("clientProceeding").disable();
      }
    });

    this.form.get("clientProceeding").valueChanges.subscribe(clientProceeding => {
      if (clientProceeding) {
        if (this.environment.requestFactFindEnabled) {
          this.form.get("sendFactFind").enable();
        }

        this.form.get("appointment").enable();
      } else {
        if (this.environment.requestFactFindEnabled) {
          this.form.get("sendFactFind").disable();
        }

        this.form.get("appointment").disable();
      }
    });

    this.form.get("journal.notifyClient").valueChanges.subscribe((notifyClient: boolean) => {
      if (notifyClient) {
        this.form.get("journal.messageToClient").enable();
      } else {
        this.form.get("journal.messageToClient").disable();
      }
    });

    this.arrangeAppointmentService.appointmentSelected$.pipe(takeUntil(this.destroy$)).subscribe(appointment => {
      this.form.get("appointment").setValue(appointment);
    });

    this.mortgagesService.mortgageDashboardDataLoaded.pipe(take(1)).subscribe(() => {
      const amount = this.mortgagesService.data.desiredQualification || 500;

      this.qualifiedOptions.QUALIFIED = `${this.qualifiedOptions.QUALIFIED} ${this.currencyPipe.transform(amount, "GBP", undefined, "1.0-0")}`;
      this.isLoading = false;
    });
  }

  public onArrangeAppointmentClicked() {
    this.arrangeAppointmentService.noSubmitMode = true;
    this.arrangeAppointmentService.initialAppointment = this.form.get("appointment").value;

    this.arrangeAppointmentService.currentAdvisor = this.mortgagesService.data.advisor;
    this.arrangeAppointmentService.currentAppointment = undefined;
    this.arrangeAppointmentService.client = this.mortgagesService.data.client;
    this.arrangeAppointmentService.isFactFindRequestAllowed = false;

    this.router.navigate(['arrangeAppointment'], { relativeTo: this.route });
  }

  public async onSubmit() {
    const value: OfferQualification = this.form.value;

    if (value.appointment) {
      value.appointment.appointmentDate = this.selectDateAndTimeService.toLocalDateISO(value.appointment.appointmentDate, false);
    }

    try {
      await this.mortgageApiService.qualifyClient(this.mortgageId, value);
      this.toasterService.callToaster({ severity: 'info', summary: 'Info', detail: 'Qualification saved.' });
      this.mortgagesService.reloadMortgageDashboard.emit();
      this.goBack();
    } catch (err) {
      this.toasterService.callToaster({ severity: 'error', summary: 'Error', detail: `Failed. Please try again. ${err.error.error.message}` });
    }
  }

  public goBack() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }
}
