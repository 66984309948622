import { Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { NavigationService } from '../../../services/navigation.service';
import { ProtectionApiService } from '../../../services/protection-api.service';
import { UserApiService } from '../../../services/user-api.service';
import { fadeAnimation } from '../../../shared/animations/fade.animation';
import { sidepaneMove, sidepaneMove2Cols } from '../../../shared/animations/sidepane-animation';
import { SelectOrAddClientService } from '../select-or-add-client/select-or-add-client.service';
import { SelectUserHeadingType, SelectUserService } from '../select-user/select-user.service';
import { AddProtectionService } from './add-protection.service';
import { ClientDashboardComponent } from '../../../modules/clients/client-dashboard/client-dashboard.component';
import { ToastService } from '../../../../../../projects/client/src/app/shared/services/toast.service';
import { getUkPhoneValidator } from '../../../../../../projects/client/src/app/shared/functions/uk-phone.validator';

@Component({
  selector: 'hf-add-protection',
  templateUrl: './add-protection.component.html',
  styleUrls: ['./add-protection.component.scss',
    '../../../styles/sidepanes.partial.scss'],
  animations: [sidepaneMove2Cols, sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove2Cols]': 'true' }
})
export class AddProtectionComponent implements OnInit, OnDestroy {

  public form: FormGroup;
  public isSubmitting: boolean;

  private destroy$ = new Subject();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private selectOrAddClientService: SelectOrAddClientService,
    private toastService: ToastService,
    private protectionApiService: ProtectionApiService,
    private addProtectionService: AddProtectionService,
    private selectUserService: SelectUserService,
    private userApiService: UserApiService,
    private navigationService: NavigationService,
    @Optional() private clientDashboardComponent: ClientDashboardComponent
  ) {
    this.selectOrAddClientService.add.pipe(takeUntil(this.destroy$)).subscribe(client => this.form.get('client').patchValue(client));
    this.selectOrAddClientService.select.pipe(takeUntil(this.destroy$)).subscribe(client => this.form.get('client').patchValue(client));
    this.selectUserService.userSelected$.pipe(takeUntil(this.destroy$)).subscribe(advisor => this.form.get('advisor').patchValue(advisor));
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      client: this.fb.group({
        id: [''],
        fullName: [''],
        email: ['', Validators.required],
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        phonePrefix: ['+44', Validators.required],
        phone: ['', [Validators.required]], // do not use uk phone validator here EV-1377
      }, Validators.required),
      advisor: this.fb.group({
        id: ['', Validators.required],
        fullName: ['', Validators.required]
      }, Validators.required),
      notes: [null, Validators.required],
    });

    if (!this.showClientSelector()) {
      this.disableClient();
      this.initMortgage();
      this.initAdvisor();
    } else if (this.clientDashboardComponent?.clientData) {
      this.form.get("client").patchValue(this.clientDashboardComponent.clientData);
    }
  }

  public showClientSelector() {
    return this.addProtectionService.mortgageId == null;
  }

  private disableClient() {
    this.form.get('client').disable();
  }

  private initMortgage() {
    this.form.addControl('mortgageId', this.fb.control(this.addProtectionService.mortgageId, Validators.required));
  }

  private async initAdvisor() {
    try {
      const initialAdvisor = await this.userApiService.getDefaultProtectionAdvisor(this.addProtectionService.mortgageAdvisorId);
      if (initialAdvisor) {
        this.form.get('advisor').patchValue(initialAdvisor);
      }
    } catch (e) {
      // do not show error if there is default protection advisor missing (user can just select somebody else)
    }
  }

  onBack() {
    this.router.navigate(['..'], { relativeTo: this.activatedRoute });
  }

  public async onSubmitClicked() {
    this.isSubmitting = true;

    try {
      const protection = await this.protectionApiService.addProtectionCase(this.form.value);
      this.toastService.add("Protection case created", "info");

      this.navigationService.goToProtection(protection.id);
    } catch (e) {
      this.toastService.add("Failed to create protection case: ", "error", e);
    } finally {
      this.isSubmitting = false;
    }
  }

  onSelectClient() {
    this.selectOrAddClientService.client = this.form.value.client;
    this.router.navigate(["selectOrAddClient"], { relativeTo: this.activatedRoute });
  }

  onSelectAdvisor() {
    this.selectUserService.initial = this.form.get('advisor').value;
    this.selectUserService.users$ = this.userApiService.getProtectionAdvisors();
    this.selectUserService.heading = SelectUserHeadingType.SELECT_PROTECTION_ADVISOR;
    this.router.navigate(["selectProtectionAdvisor"], { relativeTo: this.activatedRoute });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
