import { ApplicationRef, Injectable } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";
import { first } from "rxjs/operators";
import { Confirmation, DialogService } from "../../../../projects/client/src/app/shared/services/dialog.service";

@Injectable({ providedIn: "root" })
export class SwUpdateService {

  private updateAvailable = false;

  constructor(
    private appRef: ApplicationRef,
    private swUpdate: SwUpdate,
    private dialogService: DialogService,
  ) {
    if (!this.swUpdate.isEnabled) {
      return; 
    }

    this.appRef.isStable.pipe(first(isStable => isStable === true)).subscribe(() => {
      this.swUpdate.available.subscribe(event => {
        console.log("Update available: ", event);
        this.updateAvailable = true;
      });
    });
  }

  public askUserToRefresh() {
    if (!(this.swUpdate.isEnabled && this.updateAvailable && this.isNotDeep())) {
      return;
    }

    this.updateAvailable = false;

    const confirmation: Confirmation = {
      title: "Everglades Update",
      message: "An update to Everglades is available, please click Update to load the new version before continuing.",
      acceptLabel: "Update",
      acceptButtonType: "primary",
      rejectButtonType: null,
    };

    const action = () => this.swUpdate.activateUpdate().then(() => document.location.reload());

    this.dialogService.confirmAndDo(confirmation, action);
  }

  private isNotDeep() {
    const chunks = document.location.pathname.split("/");
    return chunks.length < 5;
  }
}
