<div class="header">
  <div class="heading">
    Application Details

    <div *ngIf="config?.updatedBy" class="saved-by">
      Saved by {{ config.updatedBy.fullName }} at {{config?.lastUpdated | date:'HH:mm on E d MMM y'}}
    </div>
  </div>

  <hfc-button *ngIf="config.buttonType" [type]="config.buttonType" [isLoading]="config.buttonIsLoading" size="small" (click)="buttonClick.next(config)">{{ config.buttonLabel }}</hfc-button>
</div>

<div *ngIf="config.returnComment" class="returnComment">
  {{config.returnComment}}
  <div class="lastUpdated">{{config.lastUpdated | date:'d MMM y, HH:mm'}}</div>
</div>

<div *ngIf="config.stepStatus == 'NOT_STARTED'" class="notReady">
  Waiting for applicant to complete
</div>

<div *ngIf="config.stepStatus !== 'NOT_STARTED'" class="content">
  <div class="column">
    <div class="record">
      <span class="label">First Time Buyer</span>
      <span class="value">{{ yesno(data.applicationDetails.firstTimeBuyer) }}</span>
    </div>
    <div class="record">
      <span class="label">Category of Applicant</span>
      <span class="value">{{ APPLICANT_CATEGORY_NAMES.get(data.applicationDetails.applicantCategory) }}</span>
    </div>
    <div *ngIf="data.applicationDetails.applicantScheme" class="record">
      <span class="label">Purchase Scheme</span>
      <span class="value">{{ APPLICANT_SCHEME_NAMES.get(data.applicationDetails.applicantScheme) }}</span>
    </div>
    <div class="record">
      <span class="label">Type of Application</span>
      <span class="value">{{ APPLICATION_TYPE_NAME.get(data.applicationDetails.applicationType) }}</span>
    </div>
  </div>

  <div class="column">
    <div *ngIf="data.applicationDetails.helpToBuyLoan" class="record">
      <span class="label">Help To Buy Loan Amount</span>
      <span class="value">{{ data.applicationDetails.helpToBuyLoan | currency:"GBP":undefined:"1.0-0"}}</span>
    </div>
    <div *ngIf="data.applicationDetails.rightToBuyDiscount" class="record">
      <span class="label">Right To Buy Discount</span>
      <span class="value">{{ data.applicationDetails.rightToBuyDiscount | currency:"GBP":undefined:"1.0-0" }}</span>
    </div>
    <div *ngIf="data.applicationDetails.sharedPercentage" class="record">
      <span class="label">Share of Ownership</span>
      <span class="value">{{ data.applicationDetails.sharedPercentage }}%</span>
    </div>
    <div *ngIf="data.applicationDetails.sharedMonthlyRent" class="record">
      <span class="label">Shared Ownership Monthly Rent</span>
      <span class="value">{{ data.applicationDetails.sharedMonthlyRent | currency:"GBP":undefined:"1.0-0" }}</span>
    </div>
  </div>
</div>
