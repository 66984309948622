<ng-container [formGroup]="group">
  <div *ngIf="addon != null"
       [ngClass]="customInputGroupClass"
       class="hf-input-group">
    <div class=""
         (mouseenter)="mouseenter($event, 'input')"
         (mouseleave)="mouseleave($event, 'input')">
      <span [ngClass]="{ 'addon-disabled': disabled == 'disabled', 'hf-invalid-field-addon': isValid == false }"
            class="hf-input-group-text">
        {{addon}}
      </span>
    </div>
    <input #input
           [formControlName]="inputFormControlName"
           [name]="fieldName"
           [id]="fieldName"
           [type]="type"
           [pattern]="pattern"
           [maxlength]="maxLength"
           [placeholder]="placeholder"
           [autofocus]="autofocus"
           [autocomplete]="autocomplete"
           tabindex="0"
           class="hf-form-control with-addon"
           [ngClass]="{
            'hf-form-control-with-asset': asset,
            'hf-form-control-with-icon': icon,
            'hf-invalid-field': isValid == false,
            'capitalize': capitalize}"
           [email]="enableEmailValidation"
           (focus)="focus($event)"
           (focusout)="focusout($event)"
           (blur)="onBlur()"
           (mouseenter)="mouseenter($event)"
           (mouseleave)="mouseleave($event)"/>
    <span *ngIf="asset" class="hf-form-control-asset"
          (mouseenter)="mouseenter($event, 'input')"
          (mouseleave)="mouseleave($event, 'input')">{{asset}}</span>
    <span *ngIf="icon" class="hf-form-control-icon"
          (click)="inputIconClick()"
          (mouseenter)="mouseenter($event, 'input')"
          (mouseleave)="mouseleave($event, 'input')">
      <i class="material-icons">{{icon}}</i>
    </span>
    <span [ngClass]="{ 'hf-valid-status': isValid == true, 'hf-invalid-status': isValid == false }"
          class="hf-validity-icon">
      <i *ngIf="isValid" class="material-icons">check</i>
      <i *ngIf="!isValid" class="material-icons">error</i>
    </span>
  </div>
  <div *ngIf="addon == null"
       [ngClass]="customInputGroupClass"
       class="hf-input-group">

    <input #input
           [formControlName]="inputFormControlName"
           [ngClass]="{
           'hf-form-control-with-asset': asset,
           'hf-form-control-with-icon': icon,
           'hf-invalid-field': isValid == false,
           'capitalize': capitalize}"
           class="form-control hf-form-control"
           [name]="fieldName"
           [id]="fieldName"
           [type]="type"
           [pattern]="pattern"
           [maxlength]="maxLength"
           [placeholder]="placeholder"
           [autofocus]="autofocus"
           (blur)="onBlur()"
           [autocomplete]="autocomplete"
           [email]="enableEmailValidation"/>
    <span *ngIf="asset" class="hf-form-control-asset"
          (mouseenter)="mouseenter($event, 'input')"
          (mouseleave)="mouseleave($event, 'input')">{{asset}}</span>
    <span *ngIf="icon" class="hf-form-control-icon"
          (click)="inputIconClick()"
          (mouseenter)="mouseenter($event, 'input')"
          (mouseleave)="mouseleave($event, 'input')">
      <i *ngIf="!group.get('searchBox')?.value" class="material-icons">{{icon}}</i>
      <i *ngIf="group.get('searchBox')?.value" class="material-icons">close</i>
    </span>
    <span class="hf-validity-icon"
          [ngClass]="{ 'hf-valid-status': isValid == true, 'hf-invalid-status': isValid == false }">
      <i *ngIf="isValid" class="material-icons">check</i>
      <i *ngIf="!isValid" class="material-icons">error</i>
    </span>

    <div *ngIf="prefix" class="prefix">{{prefix}}</div>
  </div>
</ng-container>
