<div class="container">
  <div *ngFor="let item of filters" class="item" (click)="onItemClearClicked(item)">
    {{ item.typeLabel }}:

    <span *ngIf="item.dataType !== 'date'" class="value-label">
      {{ item.valueLabel }}
    </span>
    <span *ngIf="item.dataType === 'date'" class="value-label">
      {{ item.valueLabel | date:'d/M/y' }}
    </span>

    <i class="material-icons">
      close
    </i>
  </div>

  <div *ngIf="filters?.length" class="clear" (click)="onClearAllClicked()">
    Clear All
  </div>
</div>
