import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'hf-custom-switch-toggle',
  templateUrl: './custom-switch-toggle.component.html',
  styleUrls: ['./custom-switch-toggle.component.scss']
})

export class CustomSwitchToggleComponent {
  @Input() public group: FormGroup;
  @Input() public label: string;
  @Input() public inputFormControlName: string;
  @Input() public checked: boolean;

  constructor() {}
}
