import { Pipe, PipeTransform } from "@angular/core";

function formatSize(bytes, si=false) {
  const thresh = 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
  let u = -1;
  const r = 10;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


  return bytes.toFixed(1) + ' ' + units[u];
}

@Pipe({ name: "size" })
export class SizePipe implements PipeTransform {
  public transform(value: number): string {
    return value
      ? formatSize(value)
      : "";
  }
}
