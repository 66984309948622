<div class="overlay" (click)="onBack()" [@fadeAnimation]></div>

<div class="sidepane-content">
  <div class="flex-space-between-layout template-header">
    <h1>Log Contact</h1>
    <hf-custom-button
      type="button"
      class="button-plain-icon"
      icon="close"
      iconPos="center"
      (click)="onBack()"
    >
    </hf-custom-button>
  </div>

  <div class="template-main">
    <form [formGroup]="logContactAttempt">
      <label>
        Direction
        <hf-custom-radio-checks-field
          [group]="logContactAttempt"
          [type]="'radio'"
          [elemId]="'direction'"
          [dataObj]="logContactAttemptDirection"
        >
        </hf-custom-radio-checks-field>
      </label>

      <label>
        Contact
        <hf-custom-radio-checks-field
          [group]="logContactAttempt"
          [type]="'radio'"
          [elemId]="'result'"
          [dataObj]="logContactAttemptTypes"
        >
        </hf-custom-radio-checks-field>
      </label>

      <label *ngIf="logContactAttempt.get('subResult').enabled">
        Result
        <hf-custom-radio-checks-field
          [group]="logContactAttempt"
          [type]="'radio'"
          [elemId]="'subResult'"
          [dataObj]="logContactAttemptSpokenToTypes"
        >
        </hf-custom-radio-checks-field>
      </label>

      <ng-container *ngIf="logContactAttempt.get('otherSubResult').enabled">
        <label> Specific type </label>
        <hf-custom-text-field
          [group]="logContactAttempt"
          type="text"
          inputFormControlName="otherSubResult"
          fieldName="otherSubResult"
        >
        </hf-custom-text-field>
      </ng-container>

      <ng-container
        *ngIf="logContactAttempt.get('subResult').value === 'NOT_PROCEEDING'"
      >
        <!-- Not proceeding reasons list -->
        <div class="hf-selector">
          <label>Not Proceeding Reason</label>
          <div
            *ngIf="!notProceedingReason"
            class="hf-selector-container hf-selector-has-value"
            [routerLink]="['./selectNotProceedingReason']"
          >
            <div class="hf-selector-label-area">
              <div class="title">Select Not Proceeding Reason</div>
            </div>
            <i class="material-icons chevron-icon">chevron_right</i>
          </div>

          <ng-container *ngIf="notProceedingReason">
            <div
              class="hf-selector-container hf-selector-has-value hf-selector-black-color"
              [routerLink]="['./selectNotProceedingReason']"
            >
              <div class="hf-selector-content-area">
                <div class="title">
                  {{ notProceedingReason }}
                </div>
              </div>
              <i class="material-icons chevron-icon">chevron_right</i>
            </div>
          </ng-container>
        </div>

        <div
          *ngIf="enabled('declinedReasonId')"
          class="ev-form-field"
          [hfFormField]="control('declinedReasonId')"
        >
          <label>Declining Reason</label>
          <hf-selector-link
            placeholder="Select Declining Reason"
            [value]="value?._declinedReason?.other || value?._declinedReason?.followup?.displayName"
            [isInvalid]="control('declinedReasonId').invalid && isSubmitted"
            (click)="onSelectDecliningReasonClicked()"
          >
          </hf-selector-link>
        </div>
      </ng-container>

      <label [style.width.%]="100"> Private Notes </label>
      <hf-textarea
        [group]="logContactAttempt.get('journal')"
        [inputFormControlName]="'internalNotes'"
      ></hf-textarea>

      <hf-checkbox
        (valueChanged)="onNotifyClientSwitchChanged($event)"
        [label]="'Notify Client'"
      ></hf-checkbox>

      <div
        *ngIf="
          logContactAttempt.get('journal.connectedDocument') &&
          logContactAttempt.get('journal.connectedDocument').enabled
        "
      >
        <hf-textarea
          [group]="logContactAttempt.get('journal')"
          [placeholder]="'Message to the Client'"
          [inputFormControlName]="'messageToClient'"
        ></hf-textarea>
        <div
          marginElement
          class="flex-space-between-layout"
          [style.width.%]="100"
        >
          <hf-fileuploader
            controlName="attachment"
            [group]="logContactAttempt.get('journal.connectedDocument')"
            [fileControl]="
              logContactAttempt.get('journal.connectedDocument').value[
                'attachment'
              ]
            "
          >
          </hf-fileuploader>
        </div>
      </div>

      <ng-container
        [formGroup]="logContactAttempt.get('journal')"
        *ngIf="logContactAttempt.get('journal.notifyIntroducer').enabled"
      >
        <hf-checkbox
          formControlName="notifyIntroducer"
          [label]="'Notify Introducer'"
        ></hf-checkbox>

        <hf-textarea
          *ngIf="logContactAttempt.get('journal.messageToIntroducer').enabled"
          [group]="logContactAttempt.get('journal')"
          [placeholder]="'Message to the Introducer'"
          [inputFormControlName]="'messageToIntroducer'"
        ></hf-textarea>
      </ng-container>

      <hf-checkbox
        *ngIf="logContactAttempt.get('sendFactFind').enabled"
        label="Request Fact Find"
        formControlName="sendFactFind"
      ></hf-checkbox>

      <hf-selector-link
        label="Arrange Appointment (optional)"
        *ngIf="logContactAttempt.get('appointment').enabled"
        placeholder="Select Date and time"
        [value]="
          logContactAttempt.get('appointment').value?.appointmentDate
            | date : 'd MMM y, HH:mm'
        "
        (click)="onArrangeAppointmentClicked()"
      >
      </hf-selector-link>
    </form>
    <!-- <hfc-debug-form [form]="logContactAttempt"></hfc-debug-form> -->
  </div>

  <div class="template-footer">
    <hf-custom-button
      type="submit"
      class="button-primary"
      label="Submit"
      [isLoading]="isSubmitting"
      [disabled]="logContactAttempt.invalid"
      (onClick)="onSubmit()"
    >
    </hf-custom-button>

    <hf-custom-button
      type="button"
      class="button-secondary"
      label="Cancel"
      (click)="onBack()"
    >
    </hf-custom-button>
  </div>
</div>

<div class="sidepane-child-anchor">
  <router-outlet></router-outlet>
</div>
