import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePickerComponent } from './date-picker.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [DatePickerComponent],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [DatePickerComponent]
})
export class DatePickerModule { }
