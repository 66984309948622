<form [formGroup]="form" class="ev-sidepane-form-section top-border">
  <div class="ev-sidepane-form-heading">Reasons for not meeting these needs:</div>
  <div class="ev-sidepane-form-content">
    <div class="ev-sidepane-form-column-full-width">
      <!-- {{ unmetNeeds?.value | json }} -->

      <div *ngFor="let needControl of unmetNeeds.controls" class="ev-form-field" [hfFormField]="needControl">
        <label>{{ helper.formatNeed(needControl.value.needId, true) }}</label>
        <hfc-textarea-input [rows]="4" [autoexpand]="true" [formControl]="needControl.get('reason')"></hfc-textarea-input>
      </div>
    </div>
  </div>
</form>
