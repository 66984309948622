import { AfterContentInit, Component, ContentChildren, EventEmitter, Input, Output, QueryList } from '@angular/core';

import { TabPanelComponent } from '../tab-panel/tab-panel.component';

@Component({
  selector: 'hf-tab-group',
  templateUrl: './tab-group.component.html',
  styleUrls: ['./tab-group.component.scss']
})
export class TabGroupComponent implements AfterContentInit {
  @Input()
  public activeKey: string;

  @Output()
  public activeKeyChange = new EventEmitter<string>();

  @ContentChildren(TabPanelComponent)
  public tabPanelsList: QueryList<TabPanelComponent>;

  public tabPanels: TabPanelComponent[];

  public getActiveTabPanel(key: string) {
    return this.tabPanels.find(tp => tp.key === key);
  }

  public ngAfterContentInit() {
    this.initTabPanels();

    this.tabPanelsList.changes.subscribe(() => this.initTabPanels());
  }

  public onTabPanelClicked(tabPanel: TabPanelComponent) {
    if (this.activeKey === tabPanel.key) {
      return;
    }

    this.activeKey = tabPanel.key;
    this.activeKeyChange.next(tabPanel.key);
  }

  private initTabPanels() {
    this.tabPanels = this.tabPanelsList.toArray();
  }
}
