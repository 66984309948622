import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ReviewSectionState } from '../../../../../../shared/components/review-section/review-section.component';
import { DEBIT_COLLECTION_DATE_NAME } from '../../../../../enums.module';
import { FactFindData } from '../../../../../services/fact-find-api.service';

@Component({
  selector: 'hfc-payment-review-section',
  templateUrl: './payment-review-section.component.html',
  styleUrls: ['./payment-review-section.component.scss']
})
export class PaymentReviewSectionComponent {
  @Input()
  public data: FactFindData;

  @Input()
  public config: ReviewSectionState;

  @Output()
  public buttonClick = new EventEmitter<ReviewSectionState>();

  public DEBIT_COLLECTION_DATE_NAME = DEBIT_COLLECTION_DATE_NAME;
}
