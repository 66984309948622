import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { NewComplianceHandoverViewDTO } from "../models/new-compliance-handover-view.dto";
import { ListResult } from "./api-models";
import { ApiService } from "./api.service";
import { Environment } from "./environment.service";
import { fixDatesInNewClientSheet } from "./mortgage-api.service";

export interface Twenty7tecRecommendedProduct {
  "documentId": string;
  "productOverview": string;
  "dateCreated": string;
}

export interface Twenty7tecApplicationCreationResponseDTO {
  successful: boolean;
  error: string;
  redirectUrl: string;
}

@Injectable({ providedIn: "root" })
export class Twenty7tecApiService {
  private readonly API_NAME: string = "twentySevenTec";

  constructor(
    private apiService: ApiService,
    private environment: Environment,
  ) {}

  public listRecommendedProducts(handoverId: string): Observable<ListResult<Twenty7tecRecommendedProduct>> {
    const params = { handoverId };

    return this.apiService.get(this.API_NAME, "listRecommendedProducts", { params });
  }

  public selectRecommendedProduct(handoverId: string, documentId: string): Observable<NewComplianceHandoverViewDTO> {
    const params = { handoverId, documentId };

    return this.apiService
      .post(this.API_NAME, "selectRecommendedProduct", null, { params })
      .pipe(map(h => fixDatesInNewClientSheet(h)));
  }

  public syncClientData(mortgageId: string, handoverId: string): Observable<string> {
    const params = { mortgageId, handoverId };

    return this.apiService.post(this.API_NAME, "syncClientData", null, { params }).pipe(map(r => r.value));
  }

  public syncApplicationData(handoverId: string): Observable<Twenty7tecApplicationCreationResponseDTO> {
    const params = { handoverId };

    return this.apiService.post(this.API_NAME, "syncApplicationData", null, { params });
  }
}
