<div class="boxes-area">
  <hf-information-box heading="Next Action"
                      [value]="{text:mortgage?.nextAction && convertEnumToValue(MORTGAGE_ACTIONS, mortgage?.nextAction)}">
  </hf-information-box>

  <hf-information-box heading="Waiting On"
                      [value]="{text:mortgage?.waitingOn && convertEnumToValue(MORTGAGE_WAITING_ON, mortgage?.waitingOn)}">
  </hf-information-box>

  <!-- <hf-information-box heading="Date Created" -->
  <!--                     [value]="{text:mortgage?.createdDate | date:'d MMM y'}"> -->
  <!-- </hf-information-box> -->

  <hf-information-box heading="Product End Date">
    <ng-template #valueTemplate>
      <ng-container *ngIf="mortgage.productEndDate">
        {{ mortgage.productEndDate | date:'d MMM y' }}
        ({{ formatProductEndDate() }})
      </ng-container>
    </ng-template>
  </hf-information-box>
</div>

<div class="boxes-area">
  <hf-information-box heading="Appointment"
                      [actionVisible]="isAppointmentVisible"
                      [action]="appointmentAction"
                      [value]="appointmentValue">
  </hf-information-box>

  <hf-information-box heading="Fact Find"
                      [actionVisible]="isFactFindVisible"
                      [action]="factFindAction"
                      [value]="factFindValue"
                      [lastUpdate]="mortgage?.factFind?.lastAction"
                      [lastUpdateVisible]="!!mortgage?.factFind">
  </hf-information-box>

  <hf-information-box *ngIf="isHelpBuyVisible"
                      heading="Help to Buy"
                      [actionVisible]="isAppointmentVisible"
                      [action]="helpBuyAction"
                      [value]="helpBuyValue"
                      [lastUpdate]="mortgage?.helpToBuy?.lastAction"
                      [lastUpdateVisible]="!!mortgage?.helpToBuy">
  </hf-information-box>
</div>

<div class="boxes-area">
  <hf-information-box *ngIf="mortgage?.referralType === 'OFFER_QUALIFICATION'"
     heading="Offer Qualification"
     [actionVisible]="!!qualificationAction"
     [action]="qualificationAction"
     [value]="qualificationValue"
     [lastUpdate]="mortgage?.offerQualification?.lastAction"
     [lastUpdateVisible]="!!mortgage?.offerQualification">
  </hf-information-box>

  <hf-information-box *ngIf="mortgage?.productTransferDeclaration" heading="PT Declaration">
    <ng-template #valueTemplate>
      <div [hfTooltip]="productTransferDeclarationTooltip">
        {{ productTransferDeclarationValue }}
      </div>
    </ng-template>
  </hf-information-box>

  <div class="empty-box" *ngIf="!isHelpBuyVisible"></div>
</div>

<hf-application-list [mortgage]="mortgage" (createClick)="onCreateApplicationClicked()" (statusClick)="onApplicationStatusClicked($event)"></hf-application-list>

