import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";
import { ApplicantUser } from "../../../../../../../projects/client/src/app/fact-find/services/fact-find-api.service";
import { DialogService } from "../../../../../../../projects/client/src/app/shared/services/dialog.service";
import { DocumentApiService } from "../../../../services/document-api.service";
import { ExperianCreditReportApiService, ExperianCreditSearchReportDTO } from "../../../../services/experian-credit-report-api.service";
import { FactFindApiService } from "../../../../services/fact-find-api.service";
import { ToasterService } from "../../../../services/toaster.service";
import { MortgagesService } from "../../mortgages.service";

const categories = [
  ["mortgages", "Mortgages"],
  ["creditCards", "Credit Cards"],
  ["loans", "Loans"],
  ["hirePurchases", "Hire Purchase"],
  ["currentAccounts", "Current Account"],
  ["other", "Other"],
]

@Component({
  selector: 'hf-mortgage-dashboard-credit-report',
  templateUrl: './mortgage-dashboard-credit-report.component.html',
  styleUrls: ['./mortgage-dashboard-credit-report.component.scss']
})
export class MortgageDashboardCreditReportComponent implements OnDestroy {
  public applicants: ApplicantUser[];
  public applicant: ApplicantUser;
  public reportData: ExperianCreditSearchReportDTO;

  public isLoading = false;
  public categories = categories;
  public get mortgageId() { return this.route.snapshot.paramMap.get("id"); }

  private destroy$ = new Subject();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private factFindApiService: FactFindApiService,
    private experianCreditReportApiService: ExperianCreditReportApiService,
    private documentApiService: DocumentApiService,
    private mortgagesService: MortgagesService,
    private dialogService: DialogService,
    private toasterService: ToasterService,
  ) {
    this.initialize();
  }

  public onExportClicked() {
    this.documentApiService.triggerDownload(this.reportData.pdf);
  }

  public async onApplicantChange() {
    this.isLoading = true;

    await this.initializeReportData();

    this.isLoading = false;
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }

  private async initializeReportData() {
    this.reportData = await this.experianCreditReportApiService.getCreditReport(this.mortgageId, this.applicant.id);
  }

  private async initialize() {
    this.isLoading = true;

    this.applicants = await this.factFindApiService.getApplicants(this.mortgageId);
    this.applicant = this.applicants[0];

    await this.initializeReportData();

    this.isLoading = false;
  }
}
