import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserListComponent } from './user-list/user-list.component';
import { AddUserComponent } from './add-user/add-user.component';
import { SelectPhonePrefixComponent } from '../introducers/select-phone-prefix/select-phone-prefix.component';
import { SelectUserTypeComponent } from './select-user-type/select-user-type.component';
import { SelectUserComponent } from '../../shared-layout/side-panes/select-user/select-user.component';
import { SelectUserForDeleteComponent } from "./select-user-for-delete/select-user-for-delete.component";
import { DeleteAdvisorBothComponent } from './delete-advisor-both/delete-advisor-both.component';

const routes: Routes = [
  {
    path: '',
    component: UserListComponent,
    data: {
      permission: "HERON_USER_LIST"
    },
    children: [
      {
        path: 'addUser',
        component: AddUserComponent,
        data: {
          permission: ["HERON_USER_NEW"]
        },
        children: [
          {
            path: 'selectPhonePrefix',
            component: SelectPhonePrefixComponent
          }, {
            path: 'selectUserType',
            component: SelectUserTypeComponent
          }, {
            path: 'selectLineManager',
            component: SelectUserComponent
          }, {
            path: 'selectProtectionAdvisor',
            component: SelectUserComponent
          }
        ]
      }, {
        path: ':userId',
        component: AddUserComponent,
        data: {
          permission: ["HERON_USER_EDIT"]
        },
        children: [
          {
            path: 'selectPhonePrefix',
            component: SelectPhonePrefixComponent
          }, {
            path: 'selectUserType',
            component: SelectUserTypeComponent
          }, {
            path: 'selectLineManager',
            component: SelectUserComponent
          }, {
            path: 'selectProtectionAdvisor',
            component: SelectUserComponent
          }, {
            path: 'selectUserForDelete',
            component: SelectUserForDeleteComponent
          }, {
            path: 'deleteAdvisorBoth',
            component: DeleteAdvisorBothComponent,
            children: [
              {
                path: 'selectUser',
                component: SelectUserComponent
              }
            ]
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule { }
