import { Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { UserSimple } from '../../../../models';
import { ToasterService } from '../../../../services/toaster.service';
import { AddTaskService } from '../../../../shared-layout/side-panes/add-task/add-task.component';
import { fadeAnimation, sidepaneMove } from '../../../../shared/animations/sidepane-animation';
import { MortgagesService } from '../../../mortgages/mortgages.service';
import { TaskApiService } from '../../task-api.service';
import { TaskService } from '../../task.service';
import { ReassignTaskService } from './reassign-task.service';

@Component({
  selector: 'hf-reassign-task',
  templateUrl: './reassign-task.component.html',
  styleUrls: [
    './reassign-task.component.scss',
    '../../../../styles/sidepanes.partial.scss'
  ],
  animations: [sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove]': 'true' }
})
export class ReassignTaskComponent implements OnDestroy {
  public users: UserSimple[];
  public isLoading = true;
  public isSubmitting = false;
  public form: FormGroup = new FormGroup({
    newAssignee: new FormControl(),
    note: new FormControl()
  });

  public compareFn = (user1: UserSimple, user2: UserSimple): boolean =>
    !!(user1 && user2 && user1.id === user2.id);
  public filterFn = (user: UserSimple, filter: string): boolean =>
    user && filter && user.fullName.toLowerCase().includes(filter.toLowerCase())

  private get taskId() { return this.route.snapshot.paramMap.get("taskId") }
  private destroy$ = new Subject();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private addTaskService: AddTaskService,
    private taskApiService: TaskApiService,
    private reassignTaskService: ReassignTaskService,
    private taskService: TaskService,
    private toasterService: ToasterService,
    private mortgagesService: MortgagesService,
  ) {
    this.reassignTaskService.initial && this.form.get("newAssignee").setValue(this.reassignTaskService.initial);

    this.init();
  }

  public getUserLabel(user: any) {
    if (user.caseProgression) {
      return `${user.fullName} (Case Progression)`;
    }

    if (user.advisor) {
      return `${user.fullName} (Advisor)`;
    }

    return user.fullName;
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }

  public async onSubmitClicked() {
    const value = this.form.value;
    this.isSubmitting = true;

    try {
      await this.taskApiService.reassignTask(value, this.taskId);

      this.taskService.reloadTasks$.next();
      this.taskService.reloadCurrentTask$.next();
      this.mortgagesService.reloadMortgageDashboard.next();

      this.toasterService.callToaster({ severity: 'info', summary: 'Info', detail: 'Task reassigned' });
    } catch (err) {
      this.toasterService.callToaster({ severity: 'error', summary: 'Error', detail: `Failed to reassign task. Please try again ${err.error.error.message}` });
    }
    finally {
      this.isSubmitting = false;
    }

    this.onBack();
  }

  public onBack() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

  private async init() {
    this.users = await this.addTaskService.getHeronUsers(this.route.snapshot.paramMap);

    this.isLoading = false;
  }
}
