import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, ActionType, ofActionSuccessful } from '@ngxs/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserPermission } from '../../../models';
import { MortgageDashboardHandover } from '../../../models/mortgage-dashboard';
import { PinnedNoteViewDTO } from '../../../models/pinned-note-view.dto';
import { SelectApplicationLenderService } from '../../../modules/mortgages/select-application-lender/select-application-lender.service';
import { DataSourceFactory, TableParams, TableSettingsService } from '../../../shared/table-utils/table-settings.service';
import { JournalTabService } from './journal-tab.service';

@Component({
  selector: 'hf-journal-tab',
  templateUrl: './journal-tab.component.html',
  styleUrls: ['./journal-tab.component.scss'],
  providers: [TableSettingsService]
})
export class JournalTabComponent implements OnInit, OnDestroy {
  @Input()
  public dataSourceFactory: DataSourceFactory<TableParams>;

  @Input()
  public permissionsToManage: UserPermission | UserPermission[];

  @Input()
  public showApplication: boolean = false;

  @Input()
  public pinnedNote: PinnedNoteViewDTO;

  public application: MortgageDashboardHandover;
  public journalDataSourceFactory: DataSourceFactory<TableParams>;

  private destroy$ = new Subject();

  constructor(
    private actions$: Actions,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private tableSettingsService: TableSettingsService,
    private journalTabService: JournalTabService,
    private selectApplicationLenderService: SelectApplicationLenderService,
  ) {
    const refreshFor: ActionType[] = [
      // Mortgage.PinnedNoteSaved,
    ];

    this.actions$.pipe(takeUntil(this.destroy$), ofActionSuccessful(...refreshFor)).subscribe(() => {
      this.tableSettingsService.refresh();
    });

    this.journalTabService.reloadJournalTab.pipe(takeUntil(this.destroy$))
      .subscribe(() => this.tableSettingsService.refresh());

    this.selectApplicationLenderService.application$.pipe(takeUntil(this.destroy$)).subscribe(application => {
      this.application = application.id
        ? application
        : null;
      this.tableSettingsService.refresh();
    });
  }

  public ngOnInit() {
    this.journalDataSourceFactory = params => {
      if (this.application?.id) {
        (params as any).handoverId = this.application?.id;
      }

      return this.dataSourceFactory(params);
    }
  }

  public onSelectApplicationClicked() {
    this.router.navigate(["selectApplicationLender"], { relativeTo: this.activatedRoute });
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }

  public viewJournalEntry(journal) {
    // this.isHeron && this.router.navigate(['viewJournalEntry', journal.id], { relativeTo: this.activatedRoute });
  }
}
