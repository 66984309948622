import {Injectable} from "@angular/core";
import {UserApiService, ValidateEmailBody} from "./user-api.service";
import {AbstractControl} from "@angular/forms";

@Injectable()
export class ValidatorsService {
  constructor(private userApiService:UserApiService) {
  }

  public validateEmailAddressExist(body:ValidateEmailBody) {
    return (control: AbstractControl) => {
      return this.userApiService.validateEmailAddress(Object.assign({email:control.value}, body)).toPromise().then(valid => {
        if(!valid.value)
          return {emailAddressExist:true};
      }).catch(() => {return {emailAddressExist:true};})
    }
  }

  public validateEmailAddressForEditProfile() {
    return (control: AbstractControl) => {
      return this.userApiService.validateEmailAddressForEditProfile({email:control.value}).toPromise().then(valid => {
        if(!valid.value)
          return {emailAddressExist:true};
      }).catch(() => {return {emailAddressExist:true};})
    }
  }
}
