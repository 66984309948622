import { Component, Input } from '@angular/core';
import { yesno } from '../../../../../../../projects/client/src/app/shared/functions/yesno';
import { NewComplianceHandoverBudgetDTO } from '../../../../models/new-compliance-handover-budget.dto';
import { ApplicantIncome } from '../../../../services/mortgage-api.service';
import { MortgageOffsetReasonName, MortgageProductTermName } from '../../../../utils/variables.data';
import { valueOther } from '../new-client-sheet-objective/new-client-sheet-objective.component';

@Component({
  selector: 'hf-new-client-sheet-budget',
  templateUrl: './new-client-sheet-budget.component.html',
  styleUrls: ['./new-client-sheet-budget.component.scss']
})
export class NewClientSheetBudgetComponent {
  @Input()
  public data: NewComplianceHandoverBudgetDTO;
  
  @Input()
  public applicantsIncomes: ApplicantIncome[];

  public MortgageProductTermName = MortgageProductTermName;
  public MortgageOffsetReasonName = MortgageOffsetReasonName;

  public yesno = yesno;
  public valueOther = valueOther;

  public getNetMontlyIncome(applicantId: string) {
    const monthlyIncome = this.data.netMonthlyIncomes.find(i => i.applicantId == applicantId);
    return monthlyIncome && monthlyIncome.amount;
  }
}
