import { Component, Input, OnDestroy, OnInit, ContentChild, TemplateRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { expandAnimation } from '../../../../../../projects/client/src/app/shared/animations/expand.animation';
import { FilteringItem, FilteringItemDefinition, FilteringItemType, itemsToFilteringRequest, FilteringRequest } from '../../../classes/filtering-service.class';
import { RemortgageFilteringService } from '../../../modules/remortgage/services/remortgage-filtering.service';
import { FilterComposerComponent } from '../filter-composer.component';

let nextUniqueId = 0;

@Component({
  selector: 'hf-filter-composer-list',
  templateUrl: './filter-composer-list.component.html',
  styleUrls: ['./filter-composer-list.component.scss'],
  animations: [expandAnimation],
})
export class FilterComposerListComponent implements OnInit, OnDestroy {
  @Input()
  public type: FilteringItemType;

  @Input()
  public heading: string;

  @Input()
  public filterWith: (o: any, term: string) => boolean;

  @Input()
  public filterPlaceholder: string = "Search …";

  @Input()
  public lazyFilter: boolean;

  @ContentChild("itemTemplate") 
  public itemTemplate: TemplateRef<any>;

  public items: FilteringItemDefinition[];
  public isOpen = false;
  public numOfSelected = 0;

  public compareWithFn = (a: FilteringItem, b: FilteringItem) => a.value === b.value;

  public form = new FormGroup({
    filter: new FormControl('')
  });
  public term: string = "";
  public inputId: string = `${++nextUniqueId}`;
  public selectedItems: FilteringItemDefinition[] = [];
  public unassignedSelected: boolean;

  private destroy$ = new Subject();

  constructor(
    private remortgageFilteringService: RemortgageFilteringService,
    private filterComposer: FilterComposerComponent,
  ) { }

  public propagateChange() {
    const filter = itemsToFilteringRequest(this.selectedItems, this.type);

    this.filterComposer.onFilterChange(filter);
    this.updateNumOfSelected(this.selectedItems);

    if (this.lazyFilter) {
      const filter: FilteringRequest = {
        type: "unassigned",
        values: this.unassignedSelected ? ["true"] : [],
      }
      const defs = this.remortgageFilteringService.getFilteringItemDefinitionsByType("unassigned");
      const unassignedItems = this.unassignedSelected ? defs : [];

      this.filterComposer.onFilterChange(filter);
      this.updateNumOfSelected([...this.selectedItems, ...unassignedItems]);
    }
  }

  public onItemClick(item: FilteringItemDefinition) {
    const isSelected = this.isItemSelected(item);
    const selectedItems = isSelected
      ? this.selectedItems.filter(si => !this.compareWithFn(si, item))
      : [...this.selectedItems, item];

    this.selectedItems = selectedItems;
    this.propagateChange();
  }

  public onUnassignedItemClicked() {
    this.unassignedSelected = !this.unassignedSelected;

    this.propagateChange();
  }

  public isItemSelected(item: FilteringItemDefinition): boolean {
    const found = this.selectedItems.find(si => this.compareWithFn(si, item));

    return !!found;
  }

  public ngOnInit() {
     this.remortgageFilteringService.getActiveFilteringItemDefinitionsByType(this.type).pipe(take(1)).subscribe(items => {
       this.selectedItems = [...items];
       this.updateNumOfSelected(items);


       this.lazyFilter && this.remortgageFilteringService.getActiveFilteringItemDefinitionsByType("unassigned").pipe(take(1)).subscribe(unassignedItems => {
         if (unassignedItems.length) {
           this.unassignedSelected = true;
           this.updateNumOfSelected([...items, ...unassignedItems]);
         }
       });
     });

    this.form.get("filter").valueChanges.subscribe(term => {
      this.term = term || "";
      this.buildItems();
    });

    this.buildItems();
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }

  public updateNumOfSelected(items: FilteringItem[]) {
    this.numOfSelected = items?.length;
  }

  private buildItems() {
    const allitems = this.remortgageFilteringService.getFilteringItemDefinitionsByType(this.type);

    if (this.lazyFilter) {
      const selected = this.selectedItems;
      const items = allitems
        .filter(i => this.term.length > 2 && this.filterWith(i, this.term))
        .filter(i => !selected.find(si => this.compareWithFn(i, si)));

      this.items = [...selected, ...items];
    } else {
      this.items = allitems;
    }
  }
}
