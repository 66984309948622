import { Component, Input } from '@angular/core';
import { ExperianCreditSearchReportDTO } from '../../../../../../services/experian-credit-report-api.service';

@Component({
  selector: 'hf-hire-purchases-category-details',
  templateUrl: './hire-purchases-category-details.component.html',
  styleUrls: ['./hire-purchases-category-details.component.scss']
})
export class HirePurchasesCategoryDetailsComponent {
  @Input()
  public report: ExperianCreditSearchReportDTO;
}
