import { NgModule } from "@angular/core";
import { ViewJournalEntryComponent } from "./view-journal-entry.component";
import { CustomButtonModule } from "../../../shared/custom-button/custom-button.module";
import { SharedModule } from "../../../shared/shared.module";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { CustomSpinnerModule } from "../../../shared/custom-spinner/custom-spinner.module";
import {EvergladesSharedMorule} from "../../../shared/everglades-shared.module";

@NgModule({
  imports: [
    CustomButtonModule,
    SharedModule,
    EvergladesSharedMorule,
    RouterModule,
    CommonModule,
    CustomSpinnerModule,
  ], declarations: [
    ViewJournalEntryComponent
  ], exports: [
    ViewJournalEntryComponent
  ]
})

export class ViewJournalEntryModule {

}
