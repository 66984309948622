import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { ToastService } from '../../../../../../projects/client/src/app/shared/services/toast.service';
import { ExperianCreditReportApiService, TriggerCreditSearchDTO } from '../../../services/experian-credit-report-api.service';
import { CreditSearchOptOutApplicant, CreditSearchOptOutDTO, MortgageApiService } from '../../../services/mortgage-api.service';
import { fadeAnimation, sidepaneMove } from '../../../shared/animations/sidepane-animation';
import { Mortgage } from '../state/mortgage.actions';

@Component({
  selector: 'hf-trigger-credit-report',
  templateUrl: './trigger-credit-report.component.html',
  styleUrls: ['./trigger-credit-report.component.scss',
    '../../../styles/sidepanes.partial.scss'],
  animations: [sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove]': 'true' }
})
export class TriggerCreditReportComponent {
  public form = this.fb.group({
    applicants: [null]
  });

  public applicants: CreditSearchOptOutApplicant[];
  public activeStep: 1 | 2 = 1;
  public isLoading = false;
  public isSubmitting = false;

  public get mortgageId() { return this.route.snapshot.paramMap.get("id"); }
  public get value() { return this.form.value as TriggerCreditSearchDTO }

  constructor(
    private store: Store,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private mortgageApiService: MortgageApiService,
    private experianCreditReportApiService: ExperianCreditReportApiService,
    private toastService: ToastService,
  ) {
    this.initialize();
  }

  private async initialize() {
    this.isLoading = true;

    const creditSearchOptOut = await this.mortgageApiService.getApplicantsWithCreditSearchOptOut(this.mortgageId);
    this.applicants = creditSearchOptOut.applicants;

    this.isLoading = false;
  }

  public onBack() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

  public async onSubmit() {
    if (!this.value.applicants?.length) {
      return;
    }

    this.isSubmitting = true;

    try {
      const applicants = (this.form.value as CreditSearchOptOutDTO).applicants.map(a => a.id);
      const value: TriggerCreditSearchDTO = { applicants };

      await this.experianCreditReportApiService.triggerCreditSearch(this.mortgageId, value);

      this.activeStep = 2;
      this.store.dispatch(new Mortgage.CreditReportTriggered());
    } catch (err) {
      this.toastService.add("Error triggering credit search.", "error", err);
    }

    this.isSubmitting = false;
  }
}
