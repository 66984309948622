import {Directive, ElementRef, OnInit} from '@angular/core';

@Directive({selector: '[hf-sidepane]'})
export class SidepaneDirective implements OnInit{
  constructor(private elRef:ElementRef) {
  }

  ngOnInit() {
    console.log(this.elRef.nativeElement.querySelector('hf-custom-text-field'));
  }
}
