import { Component, OnDestroy } from '@angular/core';

import { sidepaneMove, fadeAnimation } from '../../../shared/animations/sidepane-animation';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserSimple } from '../../../models';
import { UserApiService } from '../../../services/user-api.service';
import { AssignCaseProgressionService } from './assign-case-progression.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MortgageApiService } from '../../../services/mortgage-api.service';
import { ToasterService } from '../../../services/toaster.service';
import { SelectApplicationService } from '../select-application/select-application.service';
import { MortgagesService } from '../mortgages.service';

// TODO add possible queryparam options as decorator into abstract class for sidepane
@Component({
  selector: 'hf-assign-case-progression',
  templateUrl: './assign-case-progression.component.html',
  styleUrls: ['./assign-case-progression.component.scss',
    '../../../styles/sidepanes.partial.scss'
  ],
  animations: [sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove]': 'true' }
})
export class AssignCaseProgressionComponent implements OnDestroy {
  public get mortgageId() { return this.route.snapshot.paramMap.get("id"); }
  public isLoading = false;

  /**
   * Show submit & cancel buttons and call `assignCaseProgression` API directly to submit case progression,
   * otherwise emit `assignCaseProgressionService.caseProgressionSelected$`
   */
  public submitMode: boolean;

  public get applications() { return this.selectApplicationService.applications }
  public items: UserSimple[];

  public form = new FormGroup({
    caseProgression: new FormControl(undefined, Validators.required),
    application: new FormControl(undefined, Validators.required)
  });

  public compareFn = (user1: UserSimple, user2: UserSimple): boolean =>
    !!(user1 && user2 && user1.id === user2.id);

  private destroy$ = new Subject();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userApiService: UserApiService,
    private mortgageApiService: MortgageApiService,
    private mortgagesService: MortgagesService,
    private assignCaseProgressionService: AssignCaseProgressionService,
    private selectApplicationService: SelectApplicationService,
    private toasterService: ToasterService
  ) {
    this.submitMode = !!this.route.snapshot.queryParamMap.get("submit");
    this.form.get("application").disable();

    if (this.submitMode) {
      this.selectApplicationService.applicationSelected$.pipe(takeUntil(this.destroy$)).subscribe(app => this.form.get("application").patchValue(app));
    } else {
      this.form.get("caseProgression").valueChanges.pipe(takeUntil(this.destroy$)).subscribe(caseProgression => {
        this.assignCaseProgressionService.caseProgressionSelected$.next(caseProgression);
        this.onBack();
      });
    }

    this.initialize();
  }

  public onSelectApplicationClicked() {
    this.router.navigate(["selectApplication"], { relativeTo: this.route });
  }

  private async initialize() {
    const promises: Promise<any>[] = [
      this.userApiService.getCaseProgressionUsers(),
    ];

    if (this.submitMode) {
      promises.push(this.mortgageApiService.getMortgageApplications(this.mortgageId));
    }

    this.isLoading = true;

    const results = await Promise.all(promises);
    this.items = results[0].items;

    if (this.submitMode) {
      this.selectApplicationService.applications = results[1];

      if (this.selectApplicationService.applications.length > 1) {
        this.form.get("application").enable();
      }
    }

    this.isLoading = false;
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }

  public async onSubmit() {
    const params: any = {
      mortgageId: this.mortgageId,
      caseProgressionId: this.form.get("caseProgression").value.id
    };

    if (this.submitMode && this.form.get("application").value) {
      params.applicationId = this.form.get("application").value.id;
    }

    try {
      await this.mortgageApiService.assignCaseProgression(params);

      this.mortgagesService.reloadMortgageDashboard.next();
      this.toasterService.callToaster({ severity: 'info', summary: 'Info', detail: 'Case Progression assigned' });
    } catch (err) {
      this.toasterService.callToaster({ severity: 'error', summary: 'Error', detail: `Unable to assign case progression. Please try again. ${err.error.error.message}` });
    }

    this.onBack();
  }

  public onBack() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }
}
