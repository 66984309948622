<div (click)="onBack()" class="overlay" [@fadeAnimation]></div>

<div class="sidepane-content">
  <div class="flex-space-between-layout template-header">
    <h1>Mortgage Status</h1>
    <hf-custom-button type="button" class="button-plain-icon" icon="close" iconPos="center" (onClick)="onBack()">
    </hf-custom-button>
  </div>

  <div class="template-main">
    <form [formGroup]="form">
      <hf-custom-selector formControlName="state"
                          [items]="states">
        <ng-template #itemTemplate let-item>
          <div>
            {{APPLICATION_STATE_NAMES.get(item)}}
          </div>
        </ng-template>
      </hf-custom-selector>
    </form>
  </div>
</div>
