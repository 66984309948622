<div class="overlay" [routerLink]="['./..']" [@fadeAnimation]></div>

<div class="sidepane-content">
  <div class="flex-space-between-layout template-header">

    <h1>{{headerLabel}}</h1>
    <hf-custom-button type="button"
                      class="button-plain-icon"
                      icon="close"
                      iconPos="center"
                      [routerLink]="['./..']">
    </hf-custom-button>
  </div>

  <div class="template-main">
    <div *ngIf="!addNewClient"
         class="select-client-pane-description">
      Select one of the clients below
      <ng-container *ngIf="!config.selectOnly">or create a new one:</ng-container>
    </div>

    <div *ngIf="!config.selectOnly && !addNewClient"
         class="hf-selector"
         (click)="showAddClientForm()">
      <div class="hf-selector-container">
        <div class="hf-selector-label-area">
          <i class="material-icons">add_circle</i>
          <div class="title">{{getTitle()}}</div>
        </div>
      </div>
    </div>

    <hf-custom-text-field *ngIf="!addNewClient"
                          [listSearchMode]="true"
                          [group]="searchBoxFormGroup"
                          listSearchModeControlName="clientNameFilter"
                          fieldName="clientNameFilter"
                          autocomplete="off"
                          type="text"
                          placeholder="Search client …"
                          icon="search"
                          preventValidation="true">
    </hf-custom-text-field>

    <ng-container *ngIf="isLoading">
      <hf-custom-spinner sidepaneLoading="true"></hf-custom-spinner>
    </ng-container>

    <ng-container *ngIf="!isLoading && !addNewClient">
      <!-- First Form: SELECT CLIENT from backend list -->
      <div *ngFor="let client of clients" class="hf-radio-element" [class.is-selected]="isSelected(client)">
        <div class="hf-radio-wrapper">
          <input type="radio"
                 name="radio"
                 [value]="client.fullName"
                 [id]="'client_'+client.id"
                 [checked]="isSelected(client)"
                 (click)="onClientSelected(client)">
          <label [for]="'client_'+client.id">
            <span class="radio">
              {{client.fullName}}
              <span class="radio-details">{{client.email}}</span>
              <span class="radio-details">{{client.phonePrefix}} {{client.phone}}</span>
            </span>
          </label>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="addNewClient">
      <!-- Second Form: ADD CLIENT using input fields -->
      <form [formGroup]="clientFormGroup"
            [style.height.%]="100"
            class="flex-col">

        <label>First Name
          <hf-custom-text-field type="text"
                                [group]="clientFormGroup"
                                inputFormControlName="firstName"
                                fieldName="firstName"
                                [capitalize]="true"
                                marginElement
                                validateOnBlur>
          </hf-custom-text-field>
        </label>

        <label>Last Name
          <hf-custom-text-field type="text"
                                [group]="clientFormGroup"
                                inputFormControlName="lastName"
                                fieldName="lastName"
                                [capitalize]="true"
                                marginElement
                                validateOnBlur>
          </hf-custom-text-field>
        </label>

        <label>Email
          <hf-custom-text-field type="email"
                                [group]="clientFormGroup"
                                inputFormControlName="email"
                                fieldName="email"
                                marginElement
                                validateOnBlur>
          </hf-custom-text-field>
        </label>

        <div [style.width.%]="100"
             class="flex-space-between-layout phone-line-wrap">
          <div class="flex-phone-prefix">
            <div class="hf-selector">
              <label>Phone</label>
              <div class="hf-selector-container hf-selector-has-value hf-selector-one-line"
                   [routerLink]="['./selectPhonePrefix']">
                <div class="hf-selector-content-area">
                  <div class="title">{{clientFormGroup.get('phonePrefix').value}}</div>
                </div>
                <i class="material-icons chevron-icon">chevron_right</i>
              </div>
            </div>
          </div>

          <div class="flex-phone">
            <!-- <hf-custom-text-field type="tel" -->
            <!--                       [group]="clientFormGroup" -->
            <!--                       inputFormControlName="phone" -->
            <!--                       fieldName="phone" -->
            <!--                       validateOnBlur> -->
            <!-- </hf-custom-text-field> -->
            <hfc-text-input type="tel" mask="0*" style="margin-bottom: 12px;" formControlName="phone" hfcRemoveLeadingZero></hfc-text-input>
          </div>
        </div>

      </form>
    </ng-container>
  </div>

  <div *ngIf="addNewClient"
       class="template-footer">
    <hf-custom-button type="submit"
                      class="button-primary"
                      label="Add Client"
                      [disabled]="clientFormGroup.invalid"
                      (onClick)="onSubmit(clientFormGroup)">
    </hf-custom-button>

    <hf-custom-button type="button"
                      class="button-secondary"
                      label="Cancel"
                      (click)="onBack()">
    </hf-custom-button>
  </div>
</div>

<div class="sidepane-child-anchor">
  <router-outlet></router-outlet>
</div>

