import { Component, Input, ContentChild, TemplateRef } from '@angular/core';

@Component({
  selector: 'hf-card-with-add-button',
  templateUrl: './card-with-add-button.component.html',
  styleUrls: ['./card-with-add-button.component.scss']
})
export class CardWithAddButtonComponent {
  @Input()
  public hasValue: boolean;

  @ContentChild("hasValueTemplate")
  hasValueTemplate: TemplateRef<any>;
}
