import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import * as moment from "moment";

import { SelectDateAndTimeService } from '../../modules/mortgages/select-date-and-time/select-date-and-time.service';

@Component({
  selector: 'hf-custom-native-mobile-datetime',
  templateUrl: './custom-native-mobile-datetime.component.html',
  styleUrls: ['./custom-native-mobile-datetime.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: CustomNativeMobileDatetimeComponent,
    multi: true
  }]
})
export class CustomNativeMobileDatetimeComponent implements OnChanges, ControlValueAccessor {
  @Input() public minDate: Date | "none" = new Date();
  @Input() public maxDate: Date;
  @Input() public onlyDate = false;

  @Input() public invalid: boolean;

  public value: string;

  public min: string = this.parseInputDate(this.minDate);
  public max: string;

  constructor(private selectDateAndTimeService: SelectDateAndTimeService) { }

  public ngOnChanges(changes: SimpleChanges) {
    if (Object.keys(changes).some(key => ["minDate", "maxDate", "onlyDate"].includes(key))) {
      this.min = this.parseInputDate(this.minDate);
      this.max = this.parseInputDate(this.maxDate);

      if (this.max) {
        this.min = undefined;
      }
    }
  }

  public onValueChange(value: string) {
    this.value = value;

    this.onChange && this.onChange(this.toNativeDate(value));
  }

  private parseInputDate(date: Date | "none"): string {
    if (!date || date === "none") {
      return undefined;
    }

    return this.fromNativeDate(date);
  }

  private toNativeDate(value: string): Date {
    return moment(value).toDate();
  }

  private fromNativeDate(value: Date): string {
    const iso = this.selectDateAndTimeService.toLocalDateISO(value, false);

    return this.onlyDate
      ? iso.substring(0, 10)
      : iso.substring(0, 16);
  }

  private onChange: (value: Date) => void;

  public writeValue(value: Date): void {
    if (value) {
      this.value = this.fromNativeDate(value);
    } else {
      this.value = undefined;
    }
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
  }
  public setDisabledState?(isDisabled: boolean): void {
  }
}
