import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ItemPerPageSelectorComponent } from './item-per-page-selector.component';

@NgModule({
  imports: [
    CommonModule
  ],
  exports: [
    ItemPerPageSelectorComponent
  ],
  declarations: [
    ItemPerPageSelectorComponent
  ]
})
export class ItemsPerPageSelectorModule {
}
