<div class="overlay" (click)="onBack()" [@fadeAnimation]></div>

<div class="sidepane-content">
  <div class="flex-space-between-layout template-header">

    <h1>Document Type</h1>
    <hf-custom-button type="button"
                      class="button-plain-icon"
                      icon="close"
                      iconPos="center"
                      (click)="onBack()">
    </hf-custom-button>
  </div>

  <div class="template-main">
    <form [formGroup]="form">
      <hf-custom-selector formControlName="item" [items]="items" [filterWith]="filterWithFn">
        <ng-template #itemTemplate let-item>
          <div (click)="onDocumentTypeSelected(item)">
            {{ selectDocumentTypeService.documentTypeName.get(item) }}
          </div>
        </ng-template>
      </hf-custom-selector>
    </form>
  </div>

</div>
