<div class="container" (click)="onClicked()">
  <div class="selected">
    <ng-container *ngTemplateOutlet="valueTemplate || defaultValueTemplate, context: { $implicit: value }">
    </ng-container>
  </div>
  <i class="material-icons">arrow_drop_down</i>
</div>

<ng-template #drawer>
  <div class="drawer">
    <div class="list">
      <div *ngFor="let item of items" class="item" (click)="onItemClicked(item)">
        <ng-container *ngTemplateOutlet="itemTemplate || defaultItemTemplate, context: { $implicit: item, isSelected: item === value }">
        </ng-container>
      </div>
    </div>

    <ng-container *ngTemplateOutlet="drawerBottomTemplate">
    </ng-container>
  </div>
</ng-template>

<ng-template #defaultItemTemplate let-item>{{ item }}</ng-template>
<ng-template #defaultValueTemplate let-item>{{ item }}</ng-template>
