<hf-journal-loader *ngIf="!initialized"></hf-journal-loader>

<div *ngIf="initialized" [@fade]>
  <hf-journal-item-container *ngFor="let item of items; trackBy: trackByFn; let first=first; let last=last" [item]="item" [first]="first" [last]="last">
    <hf-journal-item *ngIf="!isEmail(item)" [item]="item"></hf-journal-item>
    <hf-message-journal-item *ngIf="isEmail(item)" [item]="item"></hf-message-journal-item>
  </hf-journal-item-container>

</div>

<hf-infinite-scroll-trigger></hf-infinite-scroll-trigger>
