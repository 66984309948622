import { Subject } from "rxjs";

import { INTERESTED_PARTY_TYPE } from "../../../utils/variables.data";
import { Injectable } from "@angular/core";

@Injectable()
export class SelectInterestedPartyTypeService {
  public interestedPartyType$ = new Subject<INTERESTED_PARTY_TYPE>();
  public initialInterestedPartyType: INTERESTED_PARTY_TYPE;
}
