import {Component} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {MortgageDashboardHandover} from '../../../models/mortgage-dashboard';
import {fadeAnimation, sidepaneMove} from '../../../shared/animations/sidepane-animation';
import {MortgagesService} from '../mortgages.service';
import {SelectApplicationLenderService} from './select-application-lender.service';

export interface SelectApplicationLenderConfig {
  hasNoneOption?: boolean;
  source?: "dashboard" | "applicationHandovers" | "service";
}

@Component({
  selector: 'hf-select-application-lender',
  templateUrl: './select-application-lender.component.html',
  styleUrls: ['./select-application-lender.component.scss',
    '../../../styles/sidepanes.partial.scss'
  ],
  animations: [sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove]': 'true' }
})
export class SelectApplicationLenderComponent {
  public form = new FormGroup({
    application: new FormControl()
  });
  public items: MortgageDashboardHandover[] = [];

  public get isApplicationHandover() { return this.route.snapshot.data.applicationHandovers }
  public get hasNoneOption() { return this.route.snapshot.data.hasNoneOption }
  public get config() { return this.route.snapshot.data as SelectApplicationLenderConfig }

  public compareFn = (app1: MortgageDashboardHandover, app2: MortgageDashboardHandover): boolean =>
    !!(app1 && app2 && app1.id === app2.id);

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private mortgagesService: MortgagesService,
    private selectApplicationLenderService: SelectApplicationLenderService
  ) {
    this.form.get("application").valueChanges.subscribe(app => {
      this.selectApplicationLenderService.application$.next(app);
      this.onBack();
    });
    this.items = this.getItems();
  }

  public getItems(): MortgageDashboardHandover[] { 
    const first: MortgageDashboardHandover = this.hasNoneOption 
      ? { lender: "None" } as any
      : { lender: "All" } as any;

    let items: MortgageDashboardHandover[];

    if (this.config.source === "service") {
      items = this.selectApplicationLenderService.handovers;
    } else {
      items = this.isApplicationHandover 
        ? this.mortgagesService.applicationHandovers 
        : [first, ...this.mortgagesService.data?.handovers];
    }

    return items;
  }

  public onBack() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }
}
