import { Subject } from "rxjs";
import { DocumentTypeDTO } from "../../../models/document";

export abstract class SelectRequestDocumentTypeService {
  public selected$ = new Subject<DocumentTypeDTO[]>();

  public initial?: DocumentTypeDTO[];

  public abstract map: Map<string, string>;
}
