import { Component } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

import { fadeAnimation, sidepaneMove } from '../../../shared/animations/sidepane-animation';
import { RequalificationRequest, MortgageApiService } from '../../../services/mortgage-api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MortgagesService } from '../mortgages.service';
import { ToasterService } from '../../../services/toaster.service';

@Component({
  selector: 'hf-request-requalification',
  templateUrl: './request-requalification.component.html',
  styleUrls: ['./request-requalification.component.scss',
    '../../../styles/sidepanes.partial.scss'
  ],
  animations: [sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove]': 'true' }
})
export class RequestRequalificationComponent {
  public form = this.fb.group({
    desiredQualification: [null, Validators.required],
    comments: [null]
  });

  private get mortgageId() { return this.route.snapshot.params["id"] }

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private mortgageApiService: MortgageApiService,
    private mortgagesService: MortgagesService,
    private toasterService: ToasterService
  ) { }

  public async onSubmit() {
    const value: RequalificationRequest = this.form.value;

    try {
      await this.mortgageApiService.requestRequalification(this.mortgageId, value);
      this.toasterService.callToaster({ severity: 'info', summary: 'Info', detail: 'Re-qualification request saved.' });
      this.mortgagesService.reloadMortgageDashboard.emit();
      this.goBack();
    } catch (err) {
      this.toasterService.callToaster({ severity: 'error', summary: 'Error', detail: `Failed. Please try again. ${err.error.error.message}` });
    }
  }

  public goBack() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }
}
