import { Injectable } from "@angular/core";
import { Attachment } from "../models/attachment";
import { ApiService } from "./api.service";

export type CreditAccountBalanceStatus =
  | "SATISFACTORY"
  | "MONTH_DELINQUENT"
  | "TWO_MONTH_DELINQUENT"
  | "THREE_MONTH_DELINQUENT"
  | "FOUR_MONTH_DELINQUENT"
  | "FIVE_MONTH_DELINQUENT"
  | "SIX_MONTH_DELINQUENT"
  | "DEFAULT"
  | "BAD_DEBT"
  | "SLOW_PAYER"
  | "UNCLASSIFIED"
  | "DORMANT"
  | "UNKNOWN"

export const CreditAccountBalanceStatusName = new Map<CreditAccountBalanceStatus, string>([
  ["SATISFACTORY", "SATISFACTORY"],
  ["MONTH_DELINQUENT", "1m Delq"],
  ["TWO_MONTH_DELINQUENT", "2m Delq"],
  ["THREE_MONTH_DELINQUENT", "3m Delq"],
  ["FOUR_MONTH_DELINQUENT", "4m Delq"],
  ["FIVE_MONTH_DELINQUENT", "5m Delq"],
  ["SIX_MONTH_DELINQUENT", "6m Delq"],
  ["DEFAULT", "Default"],
  ["BAD_DEBT", "Bad debt"],
  ["SLOW_PAYER", "Slow payer"],
  ["UNCLASSIFIED", "Unclass"],
  ["DORMANT", "Dormant"],
  ["UNKNOWN", "Unknown"],
])

export type CreditPaymentChange =
  | "NO_CHANGE"
  | "INCREASE"
  | "DECREASE"
  | "CHANGE_NOT_KNOWN"

export interface ExperianCreditSearchBalanceDTO {
  status: CreditAccountBalanceStatus;
  balance: number;
  monthNum: number;
  month: Date;
  paymentChange: CreditPaymentChange;
  newPayment: number;
}

export type CreditAccountType =
  | "MORTGAGE"
  | "CREDIT_CARD"
  | "LOAN"
  | "HIRE_PURCHASE"
  | "CURRENT_ACCOUNT"
  | "OTHER"

export type CreditAccountStatus =
  | "DELINQUENT"
  | "ACTIVE"
  | "DEFAULT"
  | "SETTLED"

export const CreditAccountStatusName = new Map<CreditAccountStatus, string>([
  ["DELINQUENT", "Delinquent"],
  ["ACTIVE", "Active"],
  ["DEFAULT", "Default"],
  ["SETTLED", "Settled"],
])

export interface ExperianCreditSearchAccountDTO {
  experianId: string;
  company: { name: string, logo: Attachment };
  accountType: CreditAccountType;
  otherAccountType: string;
  paymentStatus: CreditAccountStatus;
  accountNumber: string;
  creditLimitBalance: number;
  startDate: Date;
  endDate: Date;
  currentBalance: number;
  initialBalance: number;
  defaultBalance: number;
  numberOfPayments: number;
  paymentAmount: number;
  paymentFrequency: string;
  defaultPayments60Months: number;
  defaultPayments12Months: number;
  missedPayments60Months: number;
  missedPayments12Months: number;
  lastUpdate: Date;
  balances: ExperianCreditSearchBalanceDTO[];
}

export interface ExperianCreditSearchCategoryDTO {
  accountsCount: number;
  status: CreditAccountStatus;
  accounts: ExperianCreditSearchAccountDTO[];
}

export interface ExperianCreditSearchReportDTO {
  reportId: string;
  reportDate: Date;
  creditScore: number;
  totalDefaultPayments60Months: number;
  totalDefaultPayments12Months: number;
  totalMissedPayments60Months: number;
  totalMissedPayments12Months: number;
  bankruptcyDetected: boolean;
  bankruptcyOutstandingDetected: boolean;
  creditUsedPercentage: number;
  hirePurchaseAmount: number;
  missedMortgagePayments: number;
  mortgageAmount: number;
  mortgageTermMonths: number;
  totalCreditCommitments: number;
  totalCreditLimitUsed: number;
  totalCreditLimit: number;
  totalUnsecuredDebt: number;

  mortgages: ExperianCreditSearchCategoryDTO;
  creditCards: ExperianCreditSearchCategoryDTO;
  loans: ExperianCreditSearchCategoryDTO;
  hirePurchases: ExperianCreditSearchCategoryDTO;
  currentAccounts: ExperianCreditSearchCategoryDTO;
  other: ExperianCreditSearchCategoryDTO;

  pdf: Attachment;
}

export interface TriggerCreditSearchDTO {
  applicants: string[];
}

@Injectable({ providedIn: "root" })
export class ExperianCreditReportApiService {
  private readonly API_NAME: string = 'experian';

  constructor(private apiService: ApiService) { }

  public async getCreditReport(mortgageId: string, clientId: string): Promise<ExperianCreditSearchReportDTO> {
    const params = { mortgageId, clientId };

    return this.apiService
      .get(this.API_NAME, "getCreditReport", { params })
      .toPromise();
  }

  public async triggerCreditSearch(mortgageId: string, triggerCreditSearch: TriggerCreditSearchDTO): Promise<void> {
    const params = { mortgageId };

    return this.apiService
      .post(this.API_NAME, "triggerCreditSearch", triggerCreditSearch, { params })
      .toPromise();
  }
}
