import { Injectable } from "@angular/core";
import { Confirmation, DialogService } from "../../../../projects/client/src/app/shared/services/dialog.service";

@Injectable({ providedIn: "root" })
export class SidepaneService {
  constructor(
    private dialogService: DialogService
  ) { }

  public confirmDiscardChanges(): Promise<boolean> {
    const confirmation: Confirmation = {
      title: "Discard Changes",
      message: "Are you sure you want to discard your changes?",
      acceptLabel: "Discard",
      acceptButtonType: "red"
    };

    return this.dialogService.confirm(confirmation);
  }
}
