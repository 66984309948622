import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const sidenavAnim = trigger('sidenavAnim',
  [
    state('in',
      style({
        position: 'fixed',
        transform: 'translateX(0%)',
        width: '100%',
        borderRadius: '4px',
        boxShadow: '2px 2px 4px 0 rgba(0, 0, 0, 0.12)',
        zIndex: 1
      })),
    state('out',
      style({
        position: 'fixed',
        transform: 'translateX(-100%)',
        width: '100%',
        boxShadow: 'none',
        zIndex: 0
      })),
    state('default',
      style({
        position: 'fixed',
        transform: 'translateX(0%)',
        width: '240px',
        boxShadow: 'none',
        zIndex: 0
      })),
    transition('* <=> *', animate('650ms ease')),
    transition('* <=> default', animate('30ms ease')),
  ],
);
