<div class="overlay" (click)="onBack()" [@fadeAnimation]></div>

<div class="sidepane-content">
  <div class="flex-space-between-layout template-header">

    <h1>Select Advisor</h1>
    <hf-custom-button type="button"
                      class="button-plain-icon"
                      icon="close"
                      iconPos="center"
                      (click)="onBack()">
    </hf-custom-button>
  </div>

  <div class="template-main">
    <div class="select-advisor-pane-search">
      <hf-custom-text-field [listSearchMode]="true"
                            [group]="searchBoxFormGroup"
                            listSearchModeControlName="advisorsNameFilter"
                            fieldName="advisorsNameFilter"
                            autocomplete="off"
                            type="text"
                            placeholder="Search Advisors …"
                            icon="search"
                            preventValidation="true">
      </hf-custom-text-field>
    </div>

    <ng-container *ngIf="isLoading">
      <hf-custom-spinner sidepaneLoading="true"></hf-custom-spinner>
    </ng-container>

    <ng-container *ngIf="!isLoading">
      <form [formGroup]="selectAdvisorsFormGroup"
            class="flex-col">
        <ng-container *ngFor="let advisor of selectAdvisorsFormGroup.controls['advisors'].controls | inputFieldTextSearch:searchBoxFormGroup.get('advisorsNameFilter').value:'fullName'"
                      [formGroup]="advisor">
          <div class="hf-radio-element"
               [ngClass]="advisor.controls.id.value == selectAdvisorsFormGroup.controls['selectedItem'].value ? 'is-selected' : ''">
            <div class="hf-radio-wrapper">
              <input type="radio"
                     formControlName="radio"
                     name="radio"
                     [value]="advisor.controls.fullName.value"
                     [id]="'advisor_'+advisor.controls.id.value"
                     (click)="selectRadio($event)">
              <label [for]="'advisor_'+advisor.controls.id.value">
                <span class="radio">{{advisor.controls.fullName.value}}</span>
              </label>
            </div>
          </div>
        </ng-container>
      </form>
    </ng-container>

  </div>
</div>
