import { HfValidators } from '../../../utils/form.validators';
import { FileOrientation, FileType } from './fileuploader.enum';

export class FileControlsConfigBuilder {

  static readonly ID = 'id';
  static readonly FILE_NAME = 'filename';
  static readonly ORIGINAL_FILENAME = 'originalFilename';
  static readonly FOLDER = 'folder';
  static readonly CREATED_DATE = 'createdDate';
  static readonly THUMBNAIL = 'thumbnail';
  static readonly FILE_SIZE = 'fileSize';
  static readonly FILE_TYPE = 'fileType';
  static readonly FILE_ORIENTATION = 'fileOrientation';
  static readonly FILE_WIDTH = 'fileWidth';
  static readonly FILE_HEIGHT = 'fileHeight';

  private fileControlsConfig = {};

  constructor() {
    this.fileControlsConfig[FileControlsConfigBuilder.ID] = [null];
    this.fileControlsConfig[FileControlsConfigBuilder.FILE_NAME] = [null];
    this.fileControlsConfig[FileControlsConfigBuilder.ORIGINAL_FILENAME] = [null];
    this.fileControlsConfig[FileControlsConfigBuilder.FOLDER] = [null];
    this.fileControlsConfig[FileControlsConfigBuilder.CREATED_DATE] = [null];
    this.fileControlsConfig[FileControlsConfigBuilder.THUMBNAIL] = [null];
  }

  maxFileSizeEmail(): FileControlsConfigBuilder {
    this.fileControlsConfig[FileControlsConfigBuilder.FILE_SIZE] = [null, HfValidators.maxFileSizeEmail()];
    return this;
  }

  maxFileSizeDisk(): FileControlsConfigBuilder {
    this.fileControlsConfig[FileControlsConfigBuilder.FILE_SIZE] = [null, HfValidators.maxFileSizeDisk()];
    return this;
  }

  allowedFileTypes(allowedFileTypes: FileType[], errorMessage: string): FileControlsConfigBuilder {
    this.fileControlsConfig[FileControlsConfigBuilder.FILE_TYPE] = [null, [HfValidators.allowedFileTypes(allowedFileTypes, errorMessage)]];
    return this;
  }

  fileOrientation(notAllowedFileOrientation: FileOrientation, errorMessage: string): FileControlsConfigBuilder {
    this.fileControlsConfig[FileControlsConfigBuilder.FILE_ORIENTATION] = [null, HfValidators.fileOrientation(notAllowedFileOrientation, errorMessage)];
    return this;
  }

  minFileWidth(minFileWidth: number, errorMessage: string): FileControlsConfigBuilder {
    this.fileControlsConfig[FileControlsConfigBuilder.FILE_WIDTH] = [null, HfValidators.minFileWidth(minFileWidth, errorMessage)];
    return this;
  }

  minFileHeight(minFileHeight: number, errorMessage: string): FileControlsConfigBuilder {
    this.fileControlsConfig[FileControlsConfigBuilder.FILE_HEIGHT] = [null, HfValidators.minFileHeight(minFileHeight, errorMessage)];
    return this;
  }

  required(errorMessage: string): FileControlsConfigBuilder {
    this.fileControlsConfig[FileControlsConfigBuilder.FILE_NAME] = [null, HfValidators.required(errorMessage)];
    return this;
  }

  build() {
    return this.fileControlsConfig;
  }
}
