import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'hf-textarea',
  templateUrl: 'custom-textarea.component.html',
  styleUrls: ['custom-textarea.component.scss']
})

export class CustomTextareaComponent implements OnInit {
  @Input() public group: FormGroup;
  @Input() public inputFormControlName: string;
  @Input() public fieldName: string;
  @Input() public customInputGroupClass?: string;
  @Input() public placeholder = '';
  @Input() public disabled?: string;
  @Input() public asset?: string;
  @Input() public icon?: string;
  @Input() public autofocus?: string;
  @Input() public valid?: string;
  @Input() public listSearchModeControlName?: string;
  @Input() private preventValidation: boolean;
  @Input() public smaller :boolean;
  @Input() public hasError :boolean;

  public isValid: boolean = null;
  private controlSub: any;

  private unsubscribe$ = new Subject();

  constructor() {
  }

  ngOnInit() {
    if (this.valid !== undefined) {
      this.isValid = (this.valid === 'true');
    }

    if (this.hasError !== undefined) {
      this.isValid = this.hasError;
    }

    if (!this.preventValidation) {
      this.controlSub = this.group.controls[this.inputFormControlName].statusChanges.pipe(takeUntil(this.unsubscribe$))
        .subscribe(status => {
          switch (status) {
            case 'VALID':
              this.isValid = true;
              this.hasError = false;
              break;
            case 'INVALID':
              this.isValid = false;
              this.hasError = true;
              break;
            default:
              this.isValid = false;
              this.hasError = true;
              break;
          }
        });
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next('');
  }
}
