import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SuitabilityLetterDTO } from '../../../models/suitability-letter.dto';
import { MortgageApiService } from '../../../services/mortgage-api.service';
import { SuitabilityPdfPreviewAdapter } from '../../../shared-layout/pages/suitability-pdf-preview/suitability-pdf-preview.component';
import { MortgagesService } from '../mortgages.service';

@Component({
  selector: 'hf-mortgage-suitability-pdf-preview',
  templateUrl: './mortgage-suitability-pdf-preview.component.html',
  styleUrls: ['./mortgage-suitability-pdf-preview.component.scss'],
  providers: [
    { provide: SuitabilityPdfPreviewAdapter, useExisting: MortgageSuitabilityPdfPreviewComponent },
  ],
})
export class MortgageSuitabilityPdfPreviewComponent extends SuitabilityPdfPreviewAdapter {
  public get mortgageId() { return this.route.snapshot.paramMap.get("id") }

  constructor(
    private route: ActivatedRoute,
    private mortgageApiService: MortgageApiService,
    private mortgagesService: MortgagesService,
  ) {
    super();
  }

  public initializeTrigger(): Observable<any> {
    return this.mortgagesService.mortgageDashboardDataLoaded;
  }

  public initialize(handoverId?: string): Observable<SuitabilityLetterDTO> {
    return this.mortgageApiService.getSuitabilityLetterPreview(this.mortgageId, handoverId || this.mortgagesService.latestHandover?.id);
  }

  public send(handoverId?: string): Observable<any> {
    return this.mortgageApiService.sendSuitabilityLetter(this.mortgageId, handoverId || this.mortgagesService.latestHandover?.id).pipe(tap(() => {
      this.mortgagesService.reloadMortgageDashboard.next();
    }));
  }

  public getHandovers(): any[] {
    return this.mortgagesService.approvedHandovers;
  }

  public getHandover(): any {
    return this.mortgagesService.latestHandover;
  }
}
