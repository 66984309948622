import { Component, Input, HostBinding } from '@angular/core';

const iconMap = {
  "PROCEEDING_STATE": {
    "WAITING": "trip_origin",
    "PROCEEDING": "check_circle",
    "NOT_PROCEEDING": "block",
  },

  "FACT_FIND_STATE": {
    "REQUESTED": "trip_origin",
    "STARTED": "trip_origin",
    "COMPLETED": "trip_origin",
    "SIGNED": "check_circle",
    "RETURNED": "swap_horizontal_circle",
    "APPROVED": "check_circle"
  },

  "APPLICATION_STATE": {
    "SUBMITTED": "trip_origin",
    "RECEIVED_BY_LENDER": "trip_origin",
    "VALUATION_INSTRUCTED": "trip_origin",
    "VALUATION_RECEIVED": "trip_origin",
    "VALUATION_BOOKED": "trip_origin",
    "APPROVED": "trip_origin",
    "MORTGAGE_OFFER_ISSUED": "check_circle",
    "COMPLETED": "check_circle",
    "NOT_PROCEEDING": "block",
  },

  "ProtectionApplicationState": {
    "NOT_SUBMITTED": "trip_origin",
    "REFERRED": "trip_origin",
    "ACCEPTED": "trip_origin",
    "ACCEPTED_NON_STANDARD": "trip_origin",
    "DECLINED": "highlight_off",
    "UNDERWRITING": "trip_origin",
    "ON_RISK": "check_circle",
    "NOT_PROCEEDING": "cancel",
  },

  "HELP_TO_BUY_STATUS": {
    "PIF_SUBMITTED": "trip_origin",
    "AMENDMENT_SUBMITTED": "trip_origin",
    "ATP_ISSUED": "check_circle",
    "CANCEL": "",
  },

  "ValuationState": {
    "INSTRUCTED": "trip_origin",
    "BOOKED": "check_circle", 
    "RECEIVED": "check_circle",
  },  

  "TwoFactorState": {
    "NOT_SETUP": "trip_origin",
    "SMS_ONLY": "check_circle",
    "FULLY": "check_circle",
  },

  "MicrosoftLinkState": {
    "SETUP": "check_circle",
    "NOT_SETUP": "trip_origin",
  },

  "HandoverSectionState": {
    "LEGACY": "radio_button_unchecked",
    "NOT_STARTED": "radio_button_unchecked",
    "STARTED": "radio_button_unchecked",
    "COMPLETED": "check_circle",
  },
}

@Component({
  selector: 'hf-status-icon',
  templateUrl: './status-icon.component.html',
  styleUrls: ['./status-icon.component.scss']
})
export class StatusIconComponent {
  @Input()
  @HostBinding("class")
  public type: string;

  @Input()
  public status: string;

  public getIconName(): string {
    return this.type && this.status
      ? iconMap[this.type][this.status]
      : undefined;
  }
}
