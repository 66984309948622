import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserSimple } from '../../../models';
import { fadeAnimation, sidepaneMove } from '../../../shared/animations/sidepane-animation';
import { SelectAdvisorForAssignmentService } from './select-advisor-for-assignment.service';

@Component({
  selector: 'hf-select-advisor-for-assignment',
  templateUrl: './select-advisor-for-assignment.component.html',
  styleUrls: [
    './select-advisor-for-assignment.component.scss',
    '../../../styles/sidepanes.partial.scss'
  ],
  animations: [sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove]': 'true' }
})
export class SelectAdvisorForAssignmentComponent implements OnInit, OnDestroy {
  public advisors: UserSimple[];

  public form: FormGroup = new FormGroup({
    advisor: new FormControl()
  });

  public get heading() { return this.selectAdvisorForAssignmentService.heading || "Select Host" }
  public compareFn = (user1: UserSimple, user2: UserSimple) =>
    user1 && user2 && user1.id === user2.id;
  public isLoading = true;

  private destroy$ = new Subject();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private selectAdvisorForAssignmentService: SelectAdvisorForAssignmentService,
  ) { }

  public async ngOnInit() {
    this.isLoading = true;

    this.advisors = await this.selectAdvisorForAssignmentService.selectAdvisorsFn(this.route.snapshot.paramMap);

    this.form.get('advisor').valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      this.selectAdvisorForAssignmentService.advisorSelected$.next(value);
      this.onBack();
    });

    this.isLoading = false;
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }

  public onBack() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }
}
