<hf-toolbar-button-container>
  <ng-container *ifMedia="'desktop'">
    <hf-toolbar-button icon="message" label="Send" (click)="onSendMessageClicked()"></hf-toolbar-button>
    <hf-toolbar-button icon="task_done.svg" label="Task" (click)="onAddTask()"></hf-toolbar-button>
    <hf-toolbar-button icon="event" label="Book" (click)="onAppointmentClicked()"></hf-toolbar-button>
    <hf-toolbar-button icon="post_add" label="Note" (click)="onAddNoteClicked()"></hf-toolbar-button>
    <hf-toolbar-button icon="add_ic_call" label="Log" (click)="onLogContactClicked()"></hf-toolbar-button>
    <hf-toolbar-button icon="push_pin" label="Pin" (click)="onPinnedNoteClicked()"></hf-toolbar-button>
    <hf-toolbar-button icon="more_horiz" label="More" (click)="onOpenMenuClicked()"></hf-toolbar-button>
  </ng-container>

  <ng-container *ifMedia="'phone'">
    <hf-toolbar-button icon="more_horiz" (click)="onOpenMenuClicked()"></hf-toolbar-button>
  </ng-container>
</hf-toolbar-button-container>

<ng-container *ifMedia="'desktop'">
  <hf-dropdown-menu #dropdownMenu (onItemSelected)="onItemSelected($event)" [menuItems]="menuItems">
  </hf-dropdown-menu>
</ng-container>

<ng-container *ifMedia="'phone'">
  <hf-dropdown-menu #dropdownMenu (onItemSelected)="onItemSelected($event)" [menuItems]="mobileMenuItems">
  </hf-dropdown-menu>
</ng-container>
