
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomButtonModule } from '../../../shared/custom-button/custom-button.module';
import { CustomSelectorModule } from '../../../shared/custom-selector/custom-selector.module';
import { SelectObjectivesComponent } from './select-objectives.component';
import { SelectObjectivesService } from './select-objectives.service';
import { CustomTextFieldModule } from '../../../shared/custom-text-field/custom-text-field.module';
import { ClientSharedModule } from '../../../../../../projects/client/src/app/shared/client-shared.module';

@NgModule({
  declarations: [SelectObjectivesComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CustomButtonModule,
    CustomTextFieldModule,
    CustomSelectorModule,
    ClientSharedModule
  ],
  exports: [SelectObjectivesComponent],
  providers: [SelectObjectivesService]
})
export class SelectObjectivesModule { }
