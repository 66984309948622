import { CurrencyPipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { CreditAccountBalanceStatusName, ExperianCreditSearchAccountDTO, ExperianCreditSearchBalanceDTO } from '../../../../../../services/experian-credit-report-api.service';

@Component({
  selector: 'hf-account-balance-table',
  templateUrl: './account-balance-table.component.html',
  styleUrls: ['./account-balance-table.component.scss'],
  providers: [CurrencyPipe]
})
export class AccountBalanceTableComponent {
  @Input()
  public account: ExperianCreditSearchAccountDTO;

  constructor(private currencyPipe: CurrencyPipe) { }

  public CreditAccountBalanceStatusName = CreditAccountBalanceStatusName;

  public getBalanceRows(balances: ExperianCreditSearchBalanceDTO[]) {
    return new Array(Math.ceil(balances.length / 12)).fill(1).map((e, i) => i);
  }

  public getBalanceCols(balances: ExperianCreditSearchBalanceDTO[], index: number) {
    return balances.slice(index * 12, index * 12 + 12);
  }

  public getBalanceTooltip(balance: ExperianCreditSearchBalanceDTO) {
    if (balance.paymentChange === "DECREASE") {
      return `Monthly payment decreased to ${this.currencyPipe.transform(balance.newPayment, "GBP", undefined, "1.2-2")}`;
    }
    if (balance.paymentChange === "INCREASE") {
      return `Monthly payment increased to ${this.currencyPipe.transform(balance.newPayment, "GBP", undefined, "1.2-2")}`;
    }
  }
}
