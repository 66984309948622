import { Component } from '@angular/core';
import { TableSettingsService } from '../table-utils/table-settings.service';

@Component({
  selector: 'hf-pagination-summary-box',
  templateUrl: 'pagination-summary-box.component.html',
  styleUrls: ['pagination-summary-box.component.scss']
})

export class PaginationSummaryBoxComponent {
  public getTotal() {
    return this.tableSettingsService.totalElements;
  }

  public getFrom() {
    return (this.tableSettingsService.pageNum - 1) * this.tableSettingsService.pageSize + 1;
  }

  public getTo() {
    if (this.tableSettingsService.pageNum == this.tableSettingsService.totalPages) {
      return this.tableSettingsService.totalElements;
    }

    return this.tableSettingsService.pageNum * this.tableSettingsService.pageSize;
  }

  constructor(private tableSettingsService: TableSettingsService) { }
}
