import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DialogService, Confirmation } from '../../../../../../projects/client/src/app/shared/services/dialog.service';
import { InterestedPartyApiService } from '../../../services/interested-party-api.service';
import { ToasterService } from "../../../services/toaster.service";
import { fadeAnimation, sidepaneMove } from "../../../shared/animations/sidepane-animation";
import { INTERESTED_PARTY_TYPE_NAME } from '../../../utils/variables.data';
import { PhonePrefixService } from '../../introducers/select-phone-prefix/phone-prefix.service';
import { SelectInterestedPartyTypeService } from '../select-interested-party-type/select-interested-party-type.service';
import { NotificationsService } from '../notifications/notifications.service';

@Component({
  selector: 'hf-add-interested-party',
  templateUrl: 'add-interested-party.component.html',
  styleUrls: [
    'add-interested-party.component.scss',
    '../../../styles/sidepanes.partial.scss'
  ],
  animations: [sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove]': 'true' }
})
export class AddInterestedPartyComponent implements OnDestroy {
  public form = this.fb.group({
    email: [null, Validators.required],
    firstName: [null, Validators.required],
    lastName: [null, Validators.required],
    phonePrefix: ["+44"],
    phone: [null],
    type: [null, Validators.required],
  });

  public INTERESTED_PARTY_TYPE_NAME = INTERESTED_PARTY_TYPE_NAME;
  public isSubmitting = false;
  public get interestedPartyId() { return this.route.snapshot.paramMap.get("interestedPartyId") }
  public isLoading = !!this.interestedPartyId;

  private get mortgageId() { return this.route.snapshot.paramMap.get("id") }
  private destroy$ = new Subject();

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private phonePrefixService: PhonePrefixService,
    private selectInterestedPartyTypeService: SelectInterestedPartyTypeService,
    private interestedPartyApiService: InterestedPartyApiService,
    private notificationsService: NotificationsService,
    private dialogService: DialogService,
    private toasterService: ToasterService,
  ) {
    this.phonePrefixService.phonePrefixUpdated.pipe(takeUntil(this.destroy$))
      .subscribe(phonePrefix => this.form.patchValue({ phonePrefix }));

    this.selectInterestedPartyTypeService.interestedPartyType$.pipe(takeUntil(this.destroy$))
      .subscribe(type => this.form.patchValue({ type }));

    this.interestedPartyId && this.initialize();
  }

  public async initialize() {
    const data = await this.interestedPartyApiService.viewInterestedParty(this.interestedPartyId);

    this.form.reset(data);

    this.isLoading = false;
  }

  public async onSelectTypeClicked() {
    if (this.form.get("type").value) {
      this.selectInterestedPartyTypeService.initialInterestedPartyType = this.form.get("type").value;
    }

    this.router.navigate(['selectInterestedPartyType'], { relativeTo: this.route });
  }

  public async onSubmit() {
    const value = this.form.value;
    this.isSubmitting = true;

    try {
      this.interestedPartyId
        ? await this.interestedPartyApiService.editInterestedParty(this.interestedPartyId, value)
        : await this.interestedPartyApiService.addInterestedParty(this.mortgageId, value);

      this.toasterService.callToaster({ severity: 'info', summary: 'Info', detail: 'Interested Party saved.' });
      this.notificationsService.refresh$.next();

      this.onBack();
    } catch (err) {
      this.toasterService.callToaster({ severity: 'error', summary: 'Error', detail: `Failed. Please try again. ${err.error.error.message}` });
    }

    this.isSubmitting = false;
  }

  public async onDeleteClicked() {
    const confirmation: Confirmation = {
      title: "Delete Interested Party",
      message: "Deleting an interested party will disable their email notifications and if applicable remove visibility of this mortgage from their online account.",
      acceptLabel: "Delete",
      acceptButtonType: "red"
    };

    if (!await this.dialogService.confirm(confirmation)) {
      return;
    }

    this.isSubmitting = true;

    try {
      await this.interestedPartyApiService.deleteInterestedParty(this.interestedPartyId);

      this.toasterService.callToaster({ severity: 'info', summary: 'Info', detail: 'Interested Party deleted.' });
      this.notificationsService.refresh$.next();

      this.onBack();
    } catch (err) {
      this.toasterService.callToaster({ severity: 'error', summary: 'Error', detail: `Failed. Please try again. ${err.error.error.message}` });
    }

    this.isSubmitting = false;
  }

  public onBack() {
    if (this.interestedPartyId) {
      this.router.navigate(['../..'], { relativeTo: this.route });
    } else {
      this.router.navigate(['..'], { relativeTo: this.route });
    }
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }
}
