import { Component, Input } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'hf-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: DateInputComponent,
    multi: true
  }]
})
export class DateInputComponent implements ControlValueAccessor {
  @Input()
  public formControlName: string;

  @Input()
  public withTime: boolean;

  @Input()
  public minDate: Date | "none";

  @Input()
  public maxDate: Date;

  @Input()
  public invalid: boolean;

  @Input()
  public propertyName: string;

  public inputControl = new FormControl();

  constructor() {
    this.inputControl.valueChanges.subscribe(value => {
      this.onChange && this.onChange(value);
    })
  }

  public onChange: (value: Date) => void;

  writeValue(obj: any): void {
    this.inputControl.setValue(obj);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    // throw new Error("Method not implemented.");
  }
  setDisabledState?(isDisabled: boolean): void {
    // throw new Error("Method not implemented.");
  }
}
