import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ClientSharedModule } from '../../../../../../projects/client/src/app/shared/client-shared.module';
import { CustomButtonModule } from '../../../shared/custom-button/custom-button.module';
import { CustomSelectorModule } from '../../../shared/custom-selector/custom-selector.module';
import { CustomTextAreaModule } from '../../../shared/custom-textarea/custom-text-area.module';
import { DatePickerModule } from '../../../shared/date-picker/date-picker.module';
import { HfCheckboxModule } from '../../../shared/hf-checkbox-component/hf-checkbox.module';
import { SharedModule } from '../../../shared/shared.module';
import { SelectObjectivesModule } from '../select-objectives/select-objectives.module';
import { ComplianceHandoverComponent } from './compliance-handover.component';
import { CustomSpinnerModule } from '../../../shared/custom-spinner/custom-spinner.module';

@NgModule({
  declarations: [ComplianceHandoverComponent],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    CustomButtonModule,
    CustomTextAreaModule,
    CustomSelectorModule,
    SelectObjectivesModule,
    HfCheckboxModule,
    DatePickerModule,
    CustomSpinnerModule,

    SharedModule,
    ClientSharedModule
  ],
  exports: [ComplianceHandoverComponent]
})
export class ComplianceHandoverModule { }
