import { EventEmitter } from '@angular/core';
import { Injectable, InjectionToken } from '@angular/core';
import { NotProceedingFollowup } from '../../../services/not-proceeding-reasons.service';
import { MORTGAGE_DOCUMENT_TYPE, PROTECTION_DOCUMENT_TYPE } from '../../../utils/variables.data';

export const SELECT_DOCUMENT_TYPE_SERVICE_DOCUMENT_TYPE_NAME = new InjectionToken<Map<MORTGAGE_DOCUMENT_TYPE | PROTECTION_DOCUMENT_TYPE, string>>('DOCUMENT_TYPE_NAME');

export interface SelectedDecliningReason {
  followup: NotProceedingFollowup;
  other?: string;
}

@Injectable()
export class SelectDecliningReasonService {
  notProceedingFollowups: NotProceedingFollowup[];

  followup$ = new EventEmitter<SelectedDecliningReason>();
}
