<div (click)="onBack()" class="overlay" [@fadeAnimation]></div>

<div class="sidepane-content">
  <div class="flex-space-between-layout template-header">
    <h1>{{heading || 'Select Line Manager'}}</h1>
    <hf-custom-button type="button" class="button-plain-icon" icon="close" iconPos="center" (onClick)="onBack()">
    </hf-custom-button>
  </div>

  <div class="template-main">
    <ng-container *ngIf="isLoading">
      <hf-custom-spinner sidepaneLoading="true"></hf-custom-spinner>
    </ng-container>

    <form *ngIf="!isLoading" [formGroup]="form">
      <hf-custom-selector formControlName="user"
                          [items]="users"
                          [multi]="multi"
                          [compareWith]="compareFn"
                          [filterWith]="filterFn">
        <ng-template #itemTemplate let-item>
          <div>
            {{getUserLabel(item)}}
          </div>
          <div *ngIf="showDetails">
            <div class="text-14">{{item.email}}</div>
            <div class="text-14">{{USER_TYPE_NAMES.get(item.userType)}}</div>
          </div>
        </ng-template>
      </hf-custom-selector>
    </form>
  </div>

  <div *ngIf="multi" class="template-footer">
    <hf-custom-button type="submit" class="button-primary" label="Select" (click)="onSelectClicked()"></hf-custom-button>
    <hf-custom-button type="button" class="button-secondary" label="Cancel" (click)="onBack()"></hf-custom-button>
  </div>
</div>
