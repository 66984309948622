import { Injectable, Inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Confirmation, DialogService } from '../../../../../../../projects/client/src/app/shared/services/dialog.service';
import { ProtectionDashboardDTO } from '../../../../models/protection';
import { SolutionBuilderApiService } from '../../../../services/solution-builder-api.service';
import { ArrangeAppointmentService } from '../../../../shared-layout/side-panes/arrange-appointment/arrange-appointment.service';
import { PROTECTION_CASE_STATE } from '../../../../utils/variables.data';
import { ProtectionApiService } from '../../../../services/protection-api.service';
import { RequestFactFindService, REQUEST_FACTFIND_SERVICE } from '../../../../shared-layout/side-panes/request-fact-find/request-fact-find.component';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable()
export class ProtectionDashboardService {
  public protectionDashboardDTO: ProtectionDashboardDTO;
  public isLoading = true;
  public _protectionDashboardDTO$ = new BehaviorSubject<ProtectionDashboardDTO>(null);
  public protectionDashboardDTO$: Observable<ProtectionDashboardDTO> = this._protectionDashboardDTO$.pipe(filter(d => !!d));

  private activatedRoute: ActivatedRoute;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private protectionApiService: ProtectionApiService,
    private arrangeAppointmentService: ArrangeAppointmentService,
    private solutionBuilderApiService: SolutionBuilderApiService,
    private dialogService: DialogService,
    @Inject(REQUEST_FACTFIND_SERVICE) private requestFactFindService: RequestFactFindService,
  ) {
    // TODO: write a function that will just walk through route.firstChild - that should be enought - no router event needed - hopefully
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        let active = this.route;
        while (active.firstChild) { active = active.firstChild };
        this.activatedRoute = active;
      });
  }

  public updateTotalMonthlyPremium(totalMonthlyPremium: number) {
    if (!this.protectionDashboardDTO) {
      return;
    }

    this.protectionDashboardDTO.totalMonthlyPremium = totalMonthlyPremium;
  }

  public async tryOpenSolutionBuilder(protection: ProtectionDashboardDTO) {
    if (protection.hasApplicantPrerequisites) {
      this.solutionBuilderApiService.openWithClientData(protection.id);

      return;
    }

    const confirmation: Confirmation = {
      title: "Applicant Information Required",
      message: "We require some additional information before proceeding to Solution Builder.",
      acceptLabel: "Add Prerequisites",
      acceptButtonType: "primary"
    }

    if (!await this.dialogService.confirm(confirmation)) {
      return;
    }

    this.router.navigate(["prerequisites"], { relativeTo: this.activatedRoute });
  }

  public goToArrangeAppointment(protection: ProtectionDashboardDTO) {

    this.arrangeAppointmentService.noSubmitMode = false;
    this.arrangeAppointmentService.currentAdvisor = protection.advisor;
    this.arrangeAppointmentService.currentAppointment = protection.appointment;
    this.arrangeAppointmentService.client = protection.client;

    const allowedStateForFactFindRequest: PROTECTION_CASE_STATE[] = ["IN_PROGRESS"];

    this.arrangeAppointmentService.isFactFindRequestAllowed =
      allowedStateForFactFindRequest.includes(protection.state)
      && !protection.factFind;

    this.router.navigate(['arrangeAppointment'], { relativeTo: this.activatedRoute });
  }

  public async goToReqestFactFind(protection: ProtectionDashboardDTO) {
    const users = await this.protectionApiService.getProtectionCaseApplicants(protection.id);
    this.requestFactFindService.requestFrom = users.map(u => u.email);

    this.router.navigate(["requestFactFind"], { relativeTo: this.activatedRoute });
  }
}
