export enum FileOrientation {
  LANDSCAPE = 'LANDSCAPE',
  PORTRAIT = 'PORTRAIT',
}

export enum FileType {
  IMAGE_PNG = 'image/png',
  IMAGE_ALL = 'image/*',
  PDF = 'application/pdf',
  EXCEL = 'application/vnd.ms-excel',
}

export enum UploadType {
  UPLOAD_FILE = 'uploadFile',
  UPLOAD_LOGO = 'uploadLogo',
}
