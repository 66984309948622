<div (click)="onBack()" class="overlay"  [@fadeAnimation]></div>

<div class="sidepane-content">
  <div class="flex-space-between-layout template-header">
    <h1>{{'Create Protection Case'}}</h1>
    <hf-custom-button type="button" class="button-plain-icon" icon="close" iconPos="center" (onClick)="onBack()">
    </hf-custom-button>
  </div>

  <div class="template-main">
    <form [formGroup]="form">
      <hf-client-selector *ngIf="showClientSelector()" [form]="form" (selectClient)="onSelectClient()"></hf-client-selector>

      <hf-user-selector [form]="form" (selectUser)="onSelectAdvisor()"></hf-user-selector>

      <label style="margin-top: 8px">Notes for Protection Advisor</label>
      <hf-textarea [group]="form" inputFormControlName="notes" fieldName="notes"></hf-textarea>
    </form>
  </div>

  <div class="template-footer">
    <hf-custom-button type="submit"
                      class="button-primary"
                      label="Create"
                      [disabled]="form.invalid"
                      [isLoading]="isSubmitting"
                      (onClick)="onSubmitClicked()">
    </hf-custom-button>

    <hf-custom-button type="button"
                      class="button-secondary"
                      label="Cancel"
                      [routerLink]="['..']">
    </hf-custom-button>
  </div>
</div>

<div class="sidepane-child-anchor">
  <router-outlet></router-outlet>
</div>
