<div class="container" *ngIf="importedData else loader">
  <div class="section">
    <div class="title">
      Mortgage Details
    </div>
    <div class="column">
      <hfc-review-section-item label="Mortgage Source">{{importedData.sourceName | valueOrDash}}</hfc-review-section-item>
      <hfc-review-section-item label="Completed">{{importedData.completedDate | date: 'd MMM y' | valueOrDash}}</hfc-review-section-item>
      <hfc-review-section-item label="Follow Up Date">{{importedData.followUpDate | date: 'd MMM y' | valueOrDash}}</hfc-review-section-item>
      <hfc-review-section-item label="Repayment Method">{{importedData.repaymentMethod | valueOrDash}}</hfc-review-section-item>
      <hfc-review-section-item label="Buyer Type">{{importedData.buyerType | valueOrDash}}</hfc-review-section-item>
    </div>
    <div class="column">
      <hfc-review-section-item label="Rate Type">{{importedData.interestRateType | valueOrDash}}</hfc-review-section-item>
      <hfc-review-section-item label="Rate">{{importedData.rate | percentage | valueOrDash}}</hfc-review-section-item>
      <hfc-review-section-item label="APR">{{importedData.apr | percentage | valueOrDash}}</hfc-review-section-item>
      <hfc-review-section-item label="Term">{{importedData.term | valueOrDash}} {{importedData.term && importedData.term === 1 ? "year" : "years"}}</hfc-review-section-item>
      <hfc-review-section-item label="Deal Term">{{importedData.dealTerm | valueOrDash}} {{importedData.dealTerm && importedData.dealTerm === 1 ? "month" : "months"}}</hfc-review-section-item>
    </div>
    <div class="column">
      <hfc-review-section-item label="Purpose of Loan">{{importedData.purposeOfLoan | valueOrDash}}</hfc-review-section-item>
      <hfc-review-section-item label="Purchase Price">{{importedData.purchasePrice | currency: 'GBP':undefined:'1.2-2' | valueOrDash}}</hfc-review-section-item>
      <hfc-review-section-item label="Existing Lender">{{importedData.existingLender | valueOrDash}}</hfc-review-section-item>
      <hfc-review-section-item label="Exisiting Loan">{{importedData.existingLoan | currency: 'GBP':undefined:'1.2-2' | valueOrDash}}</hfc-review-section-item>
    </div>
  </div>

  <div class="separator"></div>

  <div class="section">
    <div class="title">
      Property Details
    </div>
    <div class="column">
      <hfc-review-section-item label="Address">{{importedData.address | valueOrDash}}</hfc-review-section-item>
      <hfc-review-section-item label="Postcode">{{importedData.postcode | valueOrDash}}</hfc-review-section-item>
    </div>
    <div class="column">
      <hfc-review-section-item label="Intent to Move">{{importedData.intendToMove | yesNo | valueOrDash}}</hfc-review-section-item>
      <hfc-review-section-item label="Rental Income">{{importedData.rentalIncome | currency: 'GBP':undefined:'1.2-2' | valueOrDash}}</hfc-review-section-item>
    </div>
  </div>

  <div class="separator"></div>

  <div class="section">
    <div class="title">
      Case Details
    </div>
    <div class="column">
      <hfc-review-section-item label="Commission">{{importedData.commissionAmount | currency:'GBP':undefined:'1.2-2' | valueOrDash}}{{commissionPercentage}}</hfc-review-section-item>
      <hfc-review-section-item label="Abandon date">{{importedData.abandonDate | date: 'd MMM y' | valueOrDash}}</hfc-review-section-item>
      <hfc-review-section-item label="Abandon Reason">{{importedData.abandonReason | valueOrDash}}</hfc-review-section-item>
    </div>
    <div class="column">
      <hfc-review-section-item label="Abandon Notes">{{importedData.abandonText | valueOrDash}}</hfc-review-section-item>
    </div>
  </div>

  <hf-client-imported-data *ngIf="importedData.applicants?.length > 0" [applicants]="importedData.applicants"></hf-client-imported-data>

  <div class="separator"></div>

  <div class="section">
    <div class="title">
      Participants
    </div>
    <div class="boxes">
      <div class="box" *ngFor="let participant of importedData.participants">
        <div class="participant-type">{{participant.type}}</div>
        <hfc-review-section-item label="Company">{{participant.company | valueOrDash}}</hfc-review-section-item>
        <hfc-review-section-item label="Contact Name">{{participant.name | valueOrDash}}</hfc-review-section-item>
        <hfc-review-section-item label="Email">{{participant.email | valueOrDash}}</hfc-review-section-item>
        <hfc-review-section-item label="Address">{{participant.address | valueOrDash}}</hfc-review-section-item>
        <hfc-review-section-item label="Mobile">{{participant.phoneMobile | valueOrDash}}</hfc-review-section-item>
        <hfc-review-section-item label="Phone day">{{participant.phoneDay | valueOrDash}}</hfc-review-section-item>
        <hfc-review-section-item label="Phone night">{{participant.phoneNight | valueOrDash}}</hfc-review-section-item>
        <hfc-review-section-item label="Fax">{{participant.fax | valueOrDash}}</hfc-review-section-item>
        <hfc-review-section-item label="Website">{{participant.website | valueOrDash}}</hfc-review-section-item>
        <hfc-review-section-item label="Notes">{{participant.note | valueOrDash}}</hfc-review-section-item>
      </div>
    </div>
  </div>
</div>

<ng-template #loader>
  <hf-custom-spinner [darkTheme]="false"></hf-custom-spinner>
</ng-template>
