import { Component, EventEmitter, Output } from '@angular/core';
import { FilteringItemType, FilteringItemValue, FilteringItem, FilteringRequest, FilteringRequests } from '../../classes/filtering-service.class';

@Component({
  selector: 'hf-filter-composer',
  templateUrl: './filter-composer.component.html',
  styleUrls: ['./filter-composer.component.scss']
})
export class FilterComposerComponent {
  @Output()
  public filterChange = new EventEmitter<FilteringRequests>();

  private filters = new Map<FilteringItemType, FilteringItemValue[]>();

  public onFilterChange(filter: FilteringRequest) {
    this.filters.set(filter.type, filter.values);
    this.filterChange.next(this.filters);
  }
}
