import { Component, OnInit} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { IntroducerApiService } from '../../../services/introducer-api.service';
import { MortgagesService } from '../mortgages.service';
import { fadeAnimation, sidepaneMove } from '../../../shared/animations/sidepane-animation';
import { SelectIntroducerService } from './select-introducer.service';

@Component({
  selector: 'hf-select-introducer',
  templateUrl: './select-introducer.component.html',
  styleUrls: ['./select-introducer.component.scss',
    '../../../styles/sidepanes.partial.scss'],
  animations: [sidepaneMove, fadeAnimation],
  host: {'[@sidepaneMove]': 'true'}
})
export class SelectIntroducerComponent implements OnInit {

  public isLoading: boolean = true;

  public searchBoxFormGroup: FormGroup;
  public selectIntroducerFormGroup: FormGroup = this.fb.group({});

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private introducerApiService: IntroducerApiService,
    private selectIntroducerService: SelectIntroducerService,
    private mortgagesService: MortgagesService) {
    this.searchBoxFormGroup = this.fb.group({
      introducerNameFilter: []
    });

  }

  async ngOnInit() {
    const introducers = this.fb.array([]);
    if (this.activatedRoute.snapshot.queryParams.showAllOption) {
      introducers.push(this.fb.group({
        name: "All Introducers",
        id: null,
        radio: new FormControl(false)
      }));
    }
    await this.introducerApiService.getAllIntroducersWithContact().then(result => {
      for (const item of result.items) {
        introducers.push(this.fb.group({
          name: [item.name],
          primaryContactFirstName: [item.primaryContactFirstName],
          primaryContactLastName: [item.primaryContactLastName],
          id: [item.id, Validators.required],
          type: [item.type],
          radio: new FormControl(false)
        }));
      }

      this.selectIntroducerFormGroup = this.fb.group({
        introducers: introducers,
        selectedItem: new FormControl(this.mapItems(introducers.value), Validators.required)
      });

      setTimeout(() => {
        if (this.mortgagesService.mortgageForm.get('introducer.id').value !== '') {
          const radioEl = (<HTMLInputElement>document.getElementById('introducer_'+this.mortgagesService.mortgageForm.get('introducer.id').value));
          radioEl !== null ? radioEl.checked = true : null;

          if (radioEl !== null) {
            Object.keys((this.selectIntroducerFormGroup.controls['introducers'] as FormArray).controls).forEach(index => {
              (this.selectIntroducerFormGroup.controls['introducers'] as FormArray).controls[index].value.radio =
                radioEl.id === 'introducer_' + (this.selectIntroducerFormGroup.controls['introducers'] as FormArray).controls[index].value.id;
            });
            this.selectIntroducerFormGroup.get('selectedItem').setValue(this.mapItems((this.selectIntroducerFormGroup.controls['introducers'] as FormArray).value));
          }
        }
      }, 250);

      this.isLoading = false;
    });
  }

  onBack() {
    this.router.navigate(['./..'], { relativeTo: this.activatedRoute });
  }

  mapItems(items) {
      const selectedItem = items?.filter((item) => item.radio).map((item) => item.id);
      return selectedItem.length ? selectedItem : null;
  }

  selectRadio(event) {
    Object.keys((this.selectIntroducerFormGroup.controls['introducers'] as FormArray).controls).forEach(index => {
      (this.selectIntroducerFormGroup.controls['introducers'] as FormArray).controls[index].value.radio =
        event.srcElement.id === 'introducer_' + (this.selectIntroducerFormGroup.controls['introducers'] as FormArray).controls[index].value.id;
    });
    const mappedSelectedItem = this.mapItems((this.selectIntroducerFormGroup.controls['introducers'] as FormArray).value);
    this.selectIntroducerFormGroup.get('selectedItem').setValue(mappedSelectedItem);


    Object.keys((this.selectIntroducerFormGroup.controls['introducers'] as FormArray).controls).forEach(index => {
      if (this.selectIntroducerFormGroup.get('selectedItem').value[0] === (this.selectIntroducerFormGroup.controls['introducers'] as FormArray).controls[index].value.id) {
        this.mortgagesService.mortgageForm.get('introducer').patchValue(
          (this.selectIntroducerFormGroup.controls['introducers'] as FormArray).controls[index].value
        );
      }
    });

    this.router.navigate(['./..'], { relativeTo: this.activatedRoute });
  }
}
