import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { MortgageImportedData } from './state/mortgage-imported-data.actions';
import { MortgageImportedDataDTO } from '../../../../models/mortgage-imported-data.dto';
import { MortgageImportedDataItem, MortgageImportedDataState } from './state/mortgage-imported-data.state';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ImportedClientDataDTO } from '../../../../models/mortgage-imported-client-data.dto';
import { GENDER_NAME } from '../../../../../../../projects/client/src/app/fact-find/enums.module';
import { PercentagePipe } from '../../../../../../../projects/client/src/app/shared/pipes/percentage.pipe';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'hf-mortgage-dashboard-imported',
  templateUrl: './mortgage-dashboard-imported.component.html',
  styleUrls: ['./mortgage-dashboard-imported.component.scss'],
  host: { class: "hf-imported-data" },
})
export class MortgageDashboardImportedComponent implements OnInit, OnDestroy {
  @Select(MortgageImportedDataState.importedDataItems)
  public importedDataItems$: Observable<MortgageImportedDataItem[]>;

  public importedData: MortgageImportedDataDTO;
  public destroy$ = new Subject();

  public get mortgageId() { return this.route.snapshot.paramMap.get("id") }

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private percentagePipe: PercentagePipe,
  ) {
    this.importedDataItems$.pipe(
      takeUntil(this.destroy$),
    ).subscribe(importedDataItems => {
      this.importedData = importedDataItems.find(item => item.mortgageId === this.mortgageId)?.importedData;
    });
  }

  public async ngOnInit(): Promise<void> {
    await this.store.dispatch(new MortgageImportedData.GetImportedData(this.mortgageId)).toPromise();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
  }

  public get commissionPercentage(): string {
    const percentage = this.percentagePipe.transform(this.importedData?.commissionPercentage);

    if (!percentage) {
      return '';
    }

    if (this.importedData?.commissionAmount) {
      return ` (${percentage})`;
    }

    return percentage;
  }
}
