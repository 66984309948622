import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { fadeAnimation, sidepaneMove2Cols } from '../../../../shared/animations/sidepane-animation';
import { SelectProviderService } from '../select-provider/select-provider.service';
import { ProtectionApplicationDTO } from '../../../../models/protection-application.dto';
import { BenefitTypeName, BenefitDTO } from '../../../../models/benefit.dto';
import { AddBenefitService } from '../add-benefit/add-benefit.service';
import { ProtectionApiService } from '../../../../services/protection-api.service';
import { ToasterService } from '../../../../services/toaster.service';
import { DialogService, Confirmation } from '../../../../../../../projects/client/src/app/shared/services/dialog.service';
import { AddApplicationService } from './add-application.service';

@Component({
  selector: 'hf-add-application',
  templateUrl: './add-application.component.html',
  styleUrls: [
    './add-application.component.scss',
    '../../../../styles/sidepanes.partial.scss'
  ],
  animations: [sidepaneMove2Cols, fadeAnimation],
  host: { '[@sidepaneMove2Cols]': 'true' }
})
export class AddApplicationComponent implements OnDestroy {
  public form = this.fb.group({
    provider: [null, Validators.required],
    productName: [null, Validators.required],
    monthlyPremium: [null, Validators.required],
    hasExpiryDate: [null, Validators.required],
    expiryDate: [{ value: null, disabled: true }],
    hasCommission: [null, Validators.required],
    commissionAmount: [{ value: null, disabled: true }],
    illustration: [null, Validators.required], //TODO: required
    comparisonReport: [null],
    otherDocuments: [[]],
    benefits: [[], Validators.required],
  });

  public BenefitTypeName = BenefitTypeName;

  public isLoading = false;
  public isSubmitting = false;

  public control(name: string) { return this.form.get(name) }
  public get value() { return this.form.value as ProtectionApplicationDTO }
  public get isEditMode() { return !!this.protectionApplicationId }

  private get protectionCaseId() { return this.route.snapshot.paramMap.get("id") }
  private get protectionApplicationId() { return this.route.snapshot.paramMap.get("applicationId") }
  private destroy$ = new Subject();

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private protectionApiService: ProtectionApiService,
    private selectProviderService: SelectProviderService,
    private addBenefitService: AddBenefitService,
    private addApplicationService: AddApplicationService,
    private toasterService: ToasterService,
    private dialogService: DialogService,
  ) {
    this.selectProviderService.providerSelected$.pipe(takeUntil(this.destroy$)).subscribe(provider => {
      this.control("provider").patchValue(provider);
    })

    this.addBenefitService.benefitAdded$.pipe(takeUntil(this.destroy$)).subscribe(benefit => {
      const included = this.value.benefits.some(b => b.id === benefit.id);
      const benefits = included
        ? this.value.benefits.map(b => b.id === benefit.id ? benefit : b)
        : [...this.value.benefits, benefit];

      this.control("benefits").setValue(benefits);
    })

    this.control("hasExpiryDate").valueChanges.subscribe(hasExpiryDate => {
      if (hasExpiryDate) {
        this.control("expiryDate").enable();
      } else {
        this.control("expiryDate").disable();
      }
    });


    this.control("hasCommission").valueChanges.subscribe(hasCommission => {
      if (hasCommission) {
        this.control("commissionAmount").enable();
      } else {
        this.control("commissionAmount").disable();
      }
    });

    this.isEditMode && this.initialize();

    // const b = [
    //   {type: "LEVEL_TERM_ASSURANCE", coverFor: [{fullName: "Ivan Sevcik"}]},
    //   {type: "LEVEL_TERM_ASSURANCE", coverFor: [{fullName: "Ivan Sevcik"}]},
    //   {type: "LEVEL_TERM_ASSURANCE", coverFor: [{fullName: "Ivan Sevcik"}]},
    // ]

    // this.control("benefits").setValue(b);
  }

  public getBenefitDetails(benefit: BenefitDTO) {
    return benefit.coverFor.map(c => c.fullName).join(", ");
  }

  public onSelectProviderClicked() {
    this.router.navigate(["selectProvider"], { relativeTo: this.route });
  }

  public onAddBenefitClicked(benefit?: BenefitDTO) {
    this.addBenefitService.initial = benefit;

    this.router.navigate(["addBenefit"], { relativeTo: this.route });
  }

  public async onSubmit() {
    this.isSubmitting = true;

    try {
      await this.save();

      this.toasterService.callToaster({ severity: 'info', summary: 'Info', detail: 'Application saved.' });
      this.addApplicationService.applicationAdded$.next();
      this.onBack();
    } catch (err) {
      this.toasterService.callToaster({ severity: 'error', summary: 'Error', detail: `Failed. Please try again. ${err.error.error.message}` });
    } finally {
      this.isSubmitting = false;
    }
  }

  private save() {
    return this.isEditMode
      ? this.protectionApiService.editManualApplication(this.protectionApplicationId, this.value)
      : this.protectionApiService.addManualApplication(this.protectionCaseId, this.value);
  }

  public async onRemove() {
    const confirmation: Confirmation = {
      title: "Delete Application",
      message: "Are you sure you want to delete this application?",
      acceptLabel: "Delete Application",
      acceptButtonType: "red"
    }

    if (!await this.dialogService.confirm(confirmation)) {
      return;
    }

    this.isSubmitting = true;

    try {
      await this.protectionApiService.deleteApplication(this.protectionApplicationId);
      this.toasterService.callToaster({ severity: 'info', summary: 'Info', detail: 'Application deleted.' });
      this.addApplicationService.applicationAdded$.next();
      this.onBack();
    } catch (err) {
      this.toasterService.callToaster({ severity: 'error', summary: 'Error', detail: `Failed. Please try again. ${err.error.error.message}` });
    } finally {
      this.isSubmitting = false;
    }
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }

  public onBack() {
    const route = this.isEditMode
      ? ["../.."]
      : [".."];

    this.router.navigate(route, { relativeTo: this.route });
  }

  private async initialize() {
    this.isLoading = true;

    const value = await this.protectionApiService.viewApplication(this.protectionApplicationId);
    this.form.patchValue(value);

    this.isLoading = false;
  }
}
