import { CurrencyPipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { CreditAccountStatusName, ExperianCreditSearchCategoryDTO } from '../../../../../../services/experian-credit-report-api.service';

@Component({
  selector: 'hf-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss'],
  providers: [CurrencyPipe],
})
export class AccountDetailsComponent {
  @Input()
  public category: ExperianCreditSearchCategoryDTO;

  public CreditAccountStatusName = CreditAccountStatusName;
}
