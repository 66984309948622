<div class="overlay" (click)="onBack()" [@fadeAnimation]></div>

<div class="sidepane-content">
  <div class="flex-space-between-layout template-header">

    <h1>Select Sales Advisor</h1>
    <hf-custom-button type="button"
                      class="button-plain-icon"
                      icon="close"
                      iconPos="center"
                      (click)="onBack()">
    </hf-custom-button>
  </div>

  <div class="template-main">
    <div class="select-sales-advisors-pane-search">
      <hf-custom-text-field [listSearchMode]="true"
                            [group]="searchBoxFormGroup"
                            listSearchModeControlName="salesAdvisorsNameFilter"
                            fieldName="salesAdvisorsNameFilter"
                            autocomplete="off"
                            type="text"
                            placeholder="Search Sales Advisors …"
                            icon="search"
                            preventValidation="true">
      </hf-custom-text-field>
    </div>

    <ng-container *ngIf="isLoading">
      <hf-custom-spinner sidepaneLoading="true"></hf-custom-spinner>
    </ng-container>

    <ng-container *ngIf="!isLoading">
      <form [formGroup]="selectSalesAdvisorsFormGroup"
            class="flex-col">

        <ng-container *ngFor="let salesAdvisor of selectSalesAdvisorsFormGroup.controls['salesAdvisors'].controls | inputFieldTextSearch:searchBoxFormGroup.get('salesAdvisorsNameFilter').value:'fullName'"
                      [formGroup]="salesAdvisor">
          <div class="hf-radio-element"
               [ngClass]="salesAdvisor.controls.id.value == selectSalesAdvisorsFormGroup.controls['selectedItem'].value ? 'is-selected' : ''">
            <div class="hf-radio-wrapper">
              <input type="radio"
                     formControlName="radio"
                     name="radio"
                     [value]="salesAdvisor.controls.fullName.value"
                     [id]="'salesAdvisor_'+salesAdvisor.controls.id.value"
                     (click)="selectRadio($event)">
              <label [for]="'salesAdvisor_'+salesAdvisor.controls.id.value">
                <span class="radio">
                  {{ salesAdvisor.controls.fullName.value }}
                  <span class="radio-details">
                    {{ USER_TYPE_NAMES.get(salesAdvisor.controls.userType.value) }}
                  </span>
                </span>
              </label>
            </div>
          </div>
        </ng-container>
      </form>
    </ng-container>

  </div>
</div>
