import {NgModule} from "@angular/core";
import {CustomTextareaComponent} from "./custom-textarea.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";

@NgModule({
  declarations: [
    CustomTextareaComponent
  ], exports: [
    CustomTextareaComponent
  ], imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule
  ]
})

export class CustomTextAreaModule {

}
