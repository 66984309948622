<div class="container" [ngClass]="result.decision">
  <i class="material-icons"></i>

  <div>
    <div class="result">
      <img src="/assets/external/experian-logo.svg"  alt="Experian logo">

      <span *ngIf="result.decision === 'ACCEPT'">Identity verified</span>
      <span *ngIf="result.decision === 'REFER'">Unable to verify indentity</span>
      <span *ngIf="result.decision === 'ERROR'">AML check failed</span>
    </div>

    <div *ngIf="result.decision === 'ERROR'" class="errors">
      {{ result.decisionText }}
    </div>
  </div>
</div>
