import { Component, Input } from '@angular/core';
import { ExperianAMLResult } from '../../../../../../../../../../src/app/website/services/fact-find-api.service';

@Component({
  selector: 'hfc-aml-result',
  templateUrl: './aml-result.component.html',
  styleUrls: ['./aml-result.component.scss']
})
export class AmlResultComponent {
  @Input()
  public result: ExperianAMLResult;
}
