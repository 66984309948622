<div (click)="onBack()" class="overlay"  [@fadeAnimation]></div>

<div class="sidepane-content">
  <div class="flex-space-between-layout template-header">
    <h1>Add Remortgage / PT</h1>
    <hf-custom-button type="button" class="button-plain-icon" icon="close" iconPos="center" (onClick)="onBack()">
    </hf-custom-button>
  </div>

  <div class="template-main">
    <hf-custom-spinner *ngIf="isLoading" sidepaneLoading="true"></hf-custom-spinner>

    <form *ngIf="!isLoading" [formGroup]="form" class="ev-sidepane-form">
      <div class="ev-sidepane-form-section">
        <div class="ev-sidepane-form-content">
          <div class="ev-sidepane-form-column-full-width">
            <div class="ev-form-field">
              <label>Advisor</label>
              <hf-selector-link placeholder="Select Advisor"
                                [value]="value.advisor?.fullName"
                                (click)="onSelectAdvisorClicked()">
              </hf-selector-link>
            </div>

            <div class="ev-form-field field-checkbox-list">
              <label>Client Category</label>
              <hfc-checkbox-list [map]="clientCategoryName" formControlName="clientCategory"></hfc-checkbox-list>
            </div>

            <div class="ev-form-field field-checkbox-list">
              <label>Client Type</label>
              <hfc-checkbox-list [map]="clientTypeName" formControlName="clientType"></hfc-checkbox-list>
            </div>

            <div class="ev-form-field field-checkbox-list">
              <label>Property Value</label>
              <hfc-checkbox-list [map]="PROPERTY_VALUE_NAME" formControlName="propertyValue"></hfc-checkbox-list>
            </div>

            <div class="ev-form-field field-checkbox-list">
              <label>Scheme (Optional)</label>
              <hfc-checkbox-list [map]="CLIENT_SCHEME_TYPE_NAME" formControlName="clientScheme"></hfc-checkbox-list>
            </div>

            <div class="ev-form-field field-checkbox-list">
              <label>Contact by</label>
              <hfc-checkbox-list [map]="CONTACT_BY_NAME" [isMulti]="true" formControlName="_contactBy"></hfc-checkbox-list>
            </div>

            <div class="ev-form-field">
              <label>Notes</label>
              <hf-textarea [group]="form" [smaller]="true" [inputFormControlName]="'notes'" fieldName="notes"></hf-textarea>
            </div>
          </div>
        </div>
      </div>

    </form>

    <!-- <hfc-debug-form [form]="form"></hfc-debug-form> -->
  </div>

  <div class="template-footer">
    <hfc-button type="primary" [disabled]="form.invalid" [isLoading]="isSubmitting" (click)="onSubmit()">Add</hfc-button>
    <hfc-button type="secondary" (click)="onBack()">Cancel</hfc-button>
  </div>
</div>

<div class="sidepane-child-anchor">
  <router-outlet></router-outlet>
</div>
