import {NgModule} from '@angular/core';
import {ChangePasswordComponent} from "./change-password.component";
import {CustomButtonModule} from "../../../shared/custom-button/custom-button.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CustomTextFieldModule} from "../../../shared/custom-text-field/custom-text-field.module";
import {TableSettingsService} from "../../../shared/table-utils/table-settings.service";
import {SharedModule} from "../../../shared/shared.module";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";

@NgModule({
  imports: [
    CustomButtonModule,
    FormsModule,
    ReactiveFormsModule,
    CustomTextFieldModule,
    SharedModule,
    CommonModule,
    RouterModule
  ],
  exports: [
    ChangePasswordComponent,
  ],
  declarations: [
    ChangePasswordComponent
  ],
  entryComponents: [
    ChangePasswordComponent
  ],
  providers: [
    TableSettingsService
  ],
})

export class HfChangePasswordModule {
}
