import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { fadeAnimation } from '../animations/fade.animation';
import { UserPermission } from '../../models';

export interface DropdownMenuItem {
  label: string;

  visible?: () => boolean;
  disabled?: boolean;
  styles?: any;
  permission?: UserPermission|UserPermission[];
  beforeClickFn?: Function;
  routerLink?: string;
}

@Component({
  selector: 'hf-dropdown-menu',
  templateUrl: 'custom-menu.component.html',
  styleUrls: ['custom-menu.component.scss'],
  animations: [fadeAnimation]
})

export class CustomMenuComponent implements OnInit {

  @Output() onItemSelected = new EventEmitter();
  @Input() menuItems: DropdownMenuItem[];

  popupOpened: boolean;

  constructor() {}

  public items(): DropdownMenuItem[] {
    return this.menuItems && this.menuItems.filter(i => !i.visible || i.visible());
  }

  ngOnInit() {}

  public toggleMenu() {
    this.popupOpened = !this.popupOpened;
  }

  public closeMenu() {
    this.popupOpened = false;
  }

  public openMenu() {
    this.popupOpened = true;
  }

  public onItemClicked(menuItem: DropdownMenuItem) {
    if (menuItem.disabled) {
      return;
    }

    if (menuItem.beforeClickFn) {
      menuItem.beforeClickFn();
    }

    this.onItemSelected.emit(menuItem);
    this.closeMenu();
  }
}
