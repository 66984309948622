<div class="hf-switch-toggle-container" [formGroup]="group">
  <span>{{label}}</span>
  <div class="switch-wrapper">
    <label class="switch">
      <input type="checkbox"
             [formControlName]="inputFormControlName"
             [checked]="checked">
      <span class="slider round"></span>
    </label>
  </div>
</div>
