import { Component, Input } from '@angular/core';

@Component({
  selector: 'hf-missed-payments-box-row',
  templateUrl: './missed-payments-box-row.component.html',
  styleUrls: ['./missed-payments-box-row.component.scss']
})
export class MissedPaymentsBoxRowComponent {
  @Input()
  public label: string;

  @Input()
  public value: string;

  @Input()
  public icon: string;
}
