import { Pipe, PipeTransform } from '@angular/core';
import { MORTGAGE_STATES, MORTGAGE_STATE_TYPE } from '../variables.data';

interface Mortgage {
  state: MORTGAGE_STATE_TYPE
  contactAttempts: number;
}

@Pipe({
  name: 'mortgageStatus',
  pure: true
})
export class MortgageStatusPipe implements PipeTransform {

  transform(mortgage: Mortgage): string {
    if (!mortgage) {
      return '--';
    }

    const name = MORTGAGE_STATES[mortgage.state];

    if (mortgage.state === "CONTACT_ATTEMPTED" && mortgage.contactAttempts >= 2) {
      return `${name} (${mortgage.contactAttempts})`;
    }

    return name;
  }
}
