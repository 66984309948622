import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription, timer} from 'rxjs';
import {Confirmation, DialogService} from '../../../../../../projects/client/src/app/shared/services/dialog.service';
import {JournalEntry} from '../../../models/journal-details';
import {JournalApiService} from '../../../services/journal-api.service';
import {ToasterService} from '../../../services/toaster.service';
import {UserService} from '../../../services/user.service';
import {fadeAnimation, sidepaneMove} from '../../../shared/animations/sidepane-animation';
import {JOURNAL_ACTIVITY_NAME} from '../../../utils/variables.data';
import {JournalTabService} from '../../tabs/journal/journal-tab.service';


@Component({
  selector: 'hf-view-journal-entry',
  templateUrl: 'view-journal-entry.component.html',
  styleUrls: ['view-journal-entry.component.scss',
    '../../../styles/sidepanes.partial.scss'],
  animations: [sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove]': 'true' }
})

export class ViewJournalEntryComponent implements OnInit, OnDestroy {
  public get entryId(): string {
    return this.route.snapshot.params['entryId']
  }

  public JOURNAL_ACTIVITY_NAME = JOURNAL_ACTIVITY_NAME;

  public entry: JournalEntry;
  public canBeRemoved: boolean = false;
  public isLoading = false;
  public get showIntroducerNotified() { return !!this.route.snapshot.data.showIntroducerNotified }
  public get isMortgage() { return !!this.route.snapshot.data.isMortgage }

  private canBeRemovedSubscription: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private journalApiService: JournalApiService,
    private toasterService: ToasterService,
    private dialogService: DialogService,
    private journalTabService: JournalTabService
  ) { }

  public async ngOnInit() {
    try {
      this.isLoading = true;

      this.entry = this.isMortgage
        ? await this.journalApiService.getMortgageJournalDetails(this.entryId)
        : await this.journalApiService.viewEntry(this.entryId);

      if (this.entry.activity === "NOTE_ADDED") {
        if (this.userService.isAdmin()) {
          this.canBeRemoved = true;
        } else {
          this.canBeRemovedSubscription = timer(0, 1000).subscribe(() => {
            this.canBeRemoved = this.canRemove();
          })
        }
      }
    } catch {
      this.toasterService.callToaster({ severity: 'error', summary: 'Error', detail: `Document not found` });
      this.onBack();
    }

    this.isLoading = false;
  }

  public ngOnDestroy() {
    this.canBeRemovedSubscription && this.canBeRemovedSubscription.unsubscribe();
  }

  public onDocumentDownload() {
    this.entry && this.journalApiService.triggerAttachmentDownload(this.entry);
  }

  public onBack() {
    this.router.navigate(['./../..'], { relativeTo: this.route });
  }

  private removeEntry() {
    this.journalApiService.deleteEntry(this.entryId).then(() => {
      this.toasterService.callToaster({ severity: 'info', summary: 'Info', detail: `Journal entry deleted successfully` });
      this.journalTabService.reloadJournalTab.emit();
      this.onBack();
    }).catch(err => {
      this.toasterService.callToaster({ severity: 'error', summary: 'Error', detail: err.error.error.message });
      this.onBack();
    });
    this.canBeRemovedSubscription && this.canBeRemovedSubscription.unsubscribe();
  }

  public async onRemove() {
    const confirmation: Confirmation = {
      title: "Delete Entry",
      message: "Are you sure you want to delete this entry? You will not be able to retrieve it.",
      acceptLabel: "Delete"
    }

    if (!await this.dialogService.confirm(confirmation)) {
      return;
    }

    this.removeEntry();
  }

  private canRemove(): boolean {
    const diff = (Date.now() - (new Date(this.entry.createdDate)).getTime()) / 1000;

    return diff < 60 * 60; // less than an hour
  }
}
