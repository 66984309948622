<div (click)="onBack()" class="overlay" [@fadeAnimation]></div>

<div class="sidepane-content">
  <div class="flex-space-between-layout template-header">
    <h1>Credit Report Opt Out</h1>
    <hf-custom-button type="button"
                      class="button-plain-icon"
                      icon="close"
                      iconPos="center"
                      (onClick)="onBack()">
    </hf-custom-button>
  </div>

  <div class="template-main">
    <hf-custom-spinner *ngIf="isLoading" sidepaneLoading="true"></hf-custom-spinner>

    <form *ngIf="!isLoading" [formGroup]="form">
      <div class="text-big-grey24">
        You can opt out of an Experian credit report for the following applicants:
      </div>



      <div *ngFor="let applicant of applicants">
        <div class="applicant-toggle">
          {{ applicant.fullName }}
          <hf-toggle theme="red" formControlName="{{ applicant.id }}"></hf-toggle>
        </div>

      </div>
    </form>

    <!-- <hfc-debug-form [form]="form"></hfc-debug-form> -->
  </div>

  <div class="template-footer">
    <hf-custom-button type="submit"
                      class="button-primary"
                      label="Save"
                      [disabled]="form.invalid"
                      [isLoading]="isSubmitting"
                      (onClick)="onSubmit()">
    </hf-custom-button>

    <hf-custom-button type="button"
                      class="button-secondary"
                      label="Cancel"
                      (onClick)="onBack()">
    </hf-custom-button>
  </div>
</div>

<div class="sidepane-child-anchor">
  <router-outlet></router-outlet>
</div>
