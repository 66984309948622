import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';

export const layoutChangeAnim = trigger('layoutChangeAnim',
  [
    state('sidenav',
      style({
        position: 'absolute',
        transform: 'translateX(0%)',
      })),
    state('mobile-sidenav',
      style({
        position: 'absolute',
        transform: 'translateX(-100%)',

      })),
    state('section',
      style({
        position: 'relative',
        width: 'calc(100% - 240px)',
        transform: 'translateX(240px)',
      })),
    state('mobile-section',
      style({
        position: 'relative',
        width: '100vw',
        transform: 'translateX(0)',
      })),
    transition('* <=> *', animate('450ms ease')),
  ]
);
