import { Component, Input } from '@angular/core';

const minScore = -998;
const maxScore = 1682;
const step = 536;

@Component({
  selector: 'hf-credit-score',
  templateUrl: './credit-score.component.html',
  styleUrls: ['./credit-score.component.scss']
})
export class CreditScoreComponent {
  @Input()
  public score: number;

  public levels = [0, 1, 2, 3, 4].map((v, i) => minScore + i * step);
}
