<div class="hf-select-container" #hfSelectContainer>

  <label *ngIf="componentLabel">{{ componentLabel }}</label>
  <ng-container *ngIf="!multi">
    <div #singleContainer>
      <div *ngFor="let data of items"
           [ngClass]="{'hf-container-disabled' : data.disabled}"
           class="padding-element flex-space-between-stretch hf-select-container-element label"
           tabindex="0"
           (click)="onEditAction($event, data.form)"
           (keyup.enter)="!data.disabled && onEditAction($event, data.form)"
           #singleItem>

        <div class="flex-col flex-element"
             [ngClass]="{'flex-element-sm': shrinkElement(singleItem)}"
             [style.height.%]="100">
          <div class="title">{{ data.title }}</div>
          <div *ngFor="let item of data.props"
               class="padding-element description">
            {{ item }}
          </div>
        </div>
        <i class="material-icons chevron-icon">chevron_right</i>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="multi">
    <div *ngFor="let data of items; let i = index"
         [ngClass]="{'hf-container-disabled' : data.disabled}"
         class="padding-element flex-space-between-stretch hf-select-container-element"
         tabindex="0"
         (click)="onEditAction($event, data.form, i)"
         (keyup.enter)="!data.disabled && onEditAction($event, data.form, i)"
         #multiItem>

      <div class="flex-col flex-element"
           [ngClass]="{'flex-element-sm': shrinkElement(multiItem)}"
           [style.height.%]="100">
        <span class="title">{{ data.title }}</span>
        <div class="padding-element description" *ngFor="let item of data.props">
          {{ item }}
        </div>
      </div>

      <i class="material-icons chevron-icon">chevron_right</i>
    </div>
  </ng-container>
</div>

<div *ngIf="showAddButton && (multi || items.length < 1)"
     class="padding-x flex-space-between-layout add-element-button"
     tabindex="0"
     (click)="onAddAction($event)"
     (keyup.enter)="onAddAction($event)"
     #addItem>
  <i class="material-icons plus-icon">add_circle</i>
  <div class="flex-description add-description">
    Add {{ addButtonLabel }}
  </div>
  <i class="material-icons chevron-icon">chevron_right</i>
</div>

<div *ngIf="!showAddButton && items.length < 1"
     class="padding-x flex-space-between-layout add-element-button without-add-button"
     tabindex="0"
     (click)="onAddAction($event)"
     (keyup.enter)="onAddAction($event)">
    <span class="padding-x flex-description">
      {{ addButtonLabel }}
    </span>
  <i class="material-icons chevron-icon">chevron_right</i>
</div>
