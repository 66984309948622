<div class="email-header">
  <hf-avatar [photo]="item.executor.photo" class="size-24"></hf-avatar>

  <div class="from">
    {{ item.executor.fullName }}

    <!-- <hf-named-icon icon="reply"></hf-named-icon> -->

    <div *ngIf="showDate" class="date">
      {{ item.createdDate | date:'d MMM, y HH:mm' }}
    </div>
  </div>

  <div class="to">
    <strong>To:</strong>
    {{ item.email.recipients }}
  </div>

  <hf-email-events *ngIf="item.email.type === 'SENT' && !item.email.sentOutlook" [email]="item.email"></hf-email-events>
  <hf-email-attachments *ngIf="item.documents" [documents]="item.documents"></hf-email-attachments>
</div>

<div class="body-container" [class.isBigger]="isBigger && !isOpen" [class.hasClamp]="hasClampClass" (click)="onSeeMoreClicked()" #bodyContainer>
  <div>
    <div class="body hasClamp" [class.isBigger]="isBigger && !isOpen" [class.hasClamp]="hasClampClass" [@clampExpandAnimation]="anim" (@clampExpandAnimation.done)="onClampDone()" #body>
      <div [innerHTML]="item.email.body"></div> 
    </div>
  </div>
</div>

<div class="footer">
  <!-- <span *ngIf="isSeeMoreVisible" (click)="onSeeMoreClicked()">See more</span> -->
  <span *ngIf="isSeeLessVisible && noSeeLess" (click)="onSeeLessClicked()">See less</span>
</div>
