<div (click)="onBack()" class="overlay" [@fadeAnimation]></div>

<div class="sidepane-content">
  <div class="flex-space-between-layout template-header">
    <h1>Not Proceeding Reason</h1>
    <hf-custom-button
      type="button"
      class="button-plain-icon"
      icon="close"
      iconPos="center"
      (onClick)="onBack()"
    >
    </hf-custom-button>
  </div>

  <div class="template-main">
    <form [formGroup]="form">
      <ng-container *ngFor="let item of notProceedingReasons">
        <div
          class="hf-radio-element"
          [ngClass]="item === selectedNotProceedingReason ? 'is-selected' : ''"
        >
          <div class="hf-radio-wrapper">
            <input
              type="radio"
              [formControlName]="'notProceedingReasonControl'"
              [checked]="selectedNotProceedingReason == item"
              [value]="item.displayName"
              [id]="item.id"
              (click)="selectNotProceedingReason(item)"
            />
            <label [for]="item.id">
              <span class="radio">
                {{ item.displayName }}
              </span>
            </label>
          </div>
        </div>
      </ng-container>

      <div *ngIf="form.get('otherReasonControl').enabled" class="other-reasons-text-box">
        <label #otherReason>Specify Other (at least 3 characters)</label>
        <hfc-textarea-input formControlName="otherReasonControl" [rows]="2"></hfc-textarea-input>
      </div>
    </form>
  </div>

  <div *ngIf="isButtonsVisible" class="template-footer">
    <hf-custom-button
      type="submit"
      class="button-primary"
      label="Select"
      (onClick)="onSelectClicked()"
    >
    </hf-custom-button>

    <hf-custom-button
      type="button"
      class="button-secondary"
      label="Cancel"
      (click)="onBack()"
    >
    </hf-custom-button>
  </div>
</div>
