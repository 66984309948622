import { Component } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { fadeAnimation, sidepaneMove } from '../../../shared/animations/sidepane-animation';
import { MORTGAGE_DOCUMENT_TYPE, PROTECTION_DOCUMENT_TYPE } from '../../../utils/variables.data';
import { SelectDocumentTypeService } from './select-document-type.service';

@Component({
  selector: 'hf-select-document-type',
  templateUrl: 'select-document-type.component.html',
  styleUrls: ['select-document-type.component.scss',
    '../../../styles/sidepanes.partial.scss'],
  animations: [sidepaneMove, fadeAnimation],
  host: {'[@sidepaneMove]': 'true'}
})
export class SelectDocumentTypeComponent {
  public form: FormGroup = new FormGroup({
    item: new FormControl()
  });
  public items: string[];
  public filterWithFn = (type: any, term: string) => {
    const typeName: string = this.selectDocumentTypeService.documentTypeName.get(type);
    return type && term && typeName && typeName.toLowerCase().includes(term.toLowerCase());
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public selectDocumentTypeService: SelectDocumentTypeService
  ) {
    this.getTypes();
    this.form.get("item").patchValue(this.selectDocumentTypeService.selectedDocumentType);
   console.log(this.form.value)
    this.form.get("item").valueChanges.subscribe(item => {
      console.log("item")
     this.onDocumentTypeSelected(item);
    });
  }

  public onBack() {
    this.router.navigate(['./..'], {relativeTo: this.activatedRoute});
  }

  public onDocumentTypeSelected(type) {
    this.selectDocumentTypeService.documentTypeChanged.emit(type);
    this.selectDocumentTypeService.selectedDocumentType = type;
    this.onBack();
  }

  public getTypes() {
    this.items = [...this.selectDocumentTypeService.documentTypeName.keys()];
  }
}
