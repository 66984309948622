<div class="overlay" [routerLink]="['./..']" [@fadeAnimation]></div>

<div class="sidepane-content">
  <div class="template-header flex-space-between-layout">
    <h1 class="title-area">Help To Buy Status</h1>
    <hf-custom-button type="button"
                      class="button-plain-icon"
                      icon="close"
                      iconPos="center"
                      [routerLink]="['./..']">
    </hf-custom-button>
  </div>

  <div class="template-main">
    <form [formGroup]="form">
      <div class="hf-selector">
        <label>Status</label>
        <div *ngIf="!status"
            class="hf-selector-container hf-selector-has-value" [routerLink]="['./selectStatus']">
          <div class="hf-selector-label-area">
            <div class="title">Select Status</div>
          </div>
          <i class="material-icons chevron-icon">chevron_right</i>
        </div>

        <ng-container *ngIf="status">
          <div class="hf-selector-container hf-selector-has-value hf-selector-black-color"
              [routerLink]="['./selectStatus']">
            <div class="hf-selector-content-area">
              <div class="title">{{HELP_TO_BUY_STATUS_NAMES.get(status)}}</div>
            </div>
            <i class="material-icons chevron-icon">chevron_right</i>
          </div>
        </ng-container>
      </div>

      <!-- PIF_SUBMITTED -->
      <ng-container *ngIf="status === 'PIF_SUBMITTED'">
        <div class="hf-selector desktop-date-time">
          <label>Submitted Date</label>

          <div *ngIf="!form.get('submittedDate').value"
                class="hf-selector-container hf-selector-has-value"
                [routerLink]="['./selectDateAndTime']" [queryParams]="{name: 'submittedDate', onlyDate: 'true', maxDate: now.toISOString()}" >
            <div class="hf-selector-label-area">
              <div class="title">Select Date</div>
            </div>
            <i class="material-icons chevron-icon">chevron_right</i>
          </div>

          <ng-container *ngIf="form.get('submittedDate').value">
            <div class="hf-selector-container hf-selector-has-value hf-selector-black-color"
                  [routerLink]="['./selectDateAndTime']" [queryParams]="{name: 'submittedDate', onlyDate: 'true', maxDate: now.toISOString()}">
              <div class="hf-selector-content-area">
                <div class="title">{{form.get('submittedDate').value | date:'d MMM y'}}</div>
              </div>
              <i class="material-icons chevron-icon">chevron_right</i>
            </div>
          </ng-container>
        </div>
        <div class="flex-space-between-layout native-mobile-date-time"
             [style.width.%]="100" style="margin-bottom: 12px;">
          <label>Submitted Date</label>
          <hf-custom-native-mobile-datetime formControlName="submittedDate" onlyDate="true" [maxDate]="now" [invalid]="form.get('submittedDate').errors?.maxDate">
          </hf-custom-native-mobile-datetime>
        </div>

        <!-- Region -->
        <div class="hf-selector">
          <label>Help to Buy Region</label>
          <div *ngIf="!form.get('regionId').value"
               class="hf-selector-container hf-selector-has-value"
               [routerLink]="['./selectRegion']">
            <div class="hf-selector-label-area">
              <div class="title">Select Region</div>
            </div>
            <i class="material-icons chevron-icon">chevron_right</i>
          </div>

          <ng-container *ngIf="form.get('regionId').value">
            <div
              class="hf-selector-container hf-selector-has-value hf-selector-black-color"
              [routerLink]="['./selectRegion']">
              <div class="hf-selector-label-area">
                <div class="title">{{form.get('regionName').value}}</div>
              </div>
              <i class="material-icons chevron-icon">chevron_right</i>
            </div>
          </ng-container>
        </div>
      </ng-container>

      <!-- AMENDMENT_SUBMITTED -->
      <ng-container *ngIf="status === 'AMENDMENT_SUBMITTED'">
        <div class="hf-selector desktop-date-time">
          <label>Amendment Date</label>

          <div *ngIf="!form.get('amendmentDate').value"
                class="hf-selector-container hf-selector-has-value"
                [routerLink]="['./selectDateAndTime']" [queryParams]="{name: 'amendmentDate', onlyDate: 'true', maxDate: now.toISOString()}" >
            <div class="hf-selector-label-area">
              <div class="title">Select Date</div>
            </div>
            <i class="material-icons chevron-icon">chevron_right</i>
          </div>

          <ng-container *ngIf="form.get('amendmentDate').value">
            <div class="hf-selector-container hf-selector-has-value hf-selector-black-color"
                  [routerLink]="['./selectDateAndTime']" [queryParams]="{name: 'amendmentDate', onlyDate: 'true', maxDate: now.toISOString()}">
              <div class="hf-selector-content-area">
                <div class="title">{{form.get('amendmentDate').value | date:'d MMM y'}}</div>
              </div>
              <i class="material-icons chevron-icon">chevron_right</i>
            </div>
          </ng-container>
        </div>
        <div class="flex-space-between-layout native-mobile-date-time"
             [style.width.%]="100" style="margin-bottom: 12px;">
          <label>Amendment Date</label>
          <hf-custom-native-mobile-datetime formControlName="amendmentDate" onlyDate="true" [maxDate]="now" [invalid]="form.get('amendmentDate').errors?.maxDate">
          </hf-custom-native-mobile-datetime>
        </div>
      </ng-container>

      <!-- ATP_ISSUED -->
      <ng-container *ngIf="status === 'ATP_ISSUED'">
        <div class="hf-selector desktop-date-time">
          <label>Date of Issue</label>

          <div *ngIf="!form.get('dateOfIssue').value"
                class="hf-selector-container hf-selector-has-value"
                [routerLink]="['./selectDateAndTime']" [queryParams]="{name: 'dateOfIssue', onlyDate: 'true', maxDate: now.toISOString()}" >
            <div class="hf-selector-label-area">
              <div class="title">Select Date</div>
            </div>
            <i class="material-icons chevron-icon">chevron_right</i>
          </div>

          <ng-container *ngIf="form.get('dateOfIssue').value">
            <div class="hf-selector-container hf-selector-has-value hf-selector-black-color"
                  [routerLink]="['./selectDateAndTime']" [queryParams]="{name: 'dateOfIssue', onlyDate: 'true', maxDate: now.toISOString()}">
              <div class="hf-selector-content-area">
                <div class="title">{{form.get('dateOfIssue').value | date:'d MMM y'}}</div>
              </div>
              <i class="material-icons chevron-icon">chevron_right</i>
            </div>
          </ng-container>
        </div>
        <div class="flex-space-between-layout native-mobile-date-time"
             [style.width.%]="100" style="margin-bottom: 12px;">
          <label>Date of Issue</label>
          <hf-custom-native-mobile-datetime formControlName="dateOfIssue" onlyDate="true" [maxDate]="now" [invalid]="form.get('dateOfIssue').errors?.maxDate">
          </hf-custom-native-mobile-datetime>
        </div>
      </ng-container>

      <!-- CANCEL -->
      <ng-container *ngIf="status === 'CANCEL'">
        <label>Cancel Reason</label>
        <hf-textarea [group]="form"
                     [inputFormControlName]="'cancelReason'"
                     [smaller]="true"></hf-textarea>
      </ng-container>

      <label>Private Notes</label>
      <hf-textarea [group]="form.get('journal')"
                   [inputFormControlName]="'internalNotes'"
                   [smaller]="true"></hf-textarea>

      <div *ngIf="form.get('submittedDate')?.errors?.maxDate" class="hf-invalid-error">
        Submitted Date cannot be in the future.
      </div>
      <div *ngIf="form.get('amendmentDate')?.errors?.maxDate" class="hf-invalid-error">
        Amendment Date cannot be in the future.
      </div>
      <div *ngIf="form.get('dateOfIssue')?.errors?.maxDate" class="hf-invalid-error">
        Date of Issue cannot be in the future.
      </div>
    </form>
  </div>

  <div class="template-footer">
    <hf-custom-button type="submit"
                      class="button-primary"
                      label="Change"
                      [disabled]="form.invalid"
                      [callable]="true"
                      (onClick)="onUpdate()">
    </hf-custom-button>

    <hf-custom-button type="button"
                      class="button-secondary"
                      label="Cancel"
                      [routerLink]="['./..']">
    </hf-custom-button>

    <hf-custom-button *ngIf="isClearButtonVisible"
                      type="button"
                      class="button-plain-icon delete-button keep-right"
                      icon="settings_backup_restore"
                      iconPos="center"
                      (onClick)="onClear()">
    </hf-custom-button>
  </div>
</div>

<div class="sidepane-child-anchor">
  <router-outlet></router-outlet>
</div>
