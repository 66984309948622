import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { formatContactBy } from '../../../../utils/functions/format-contact-by.function';

@Component({
  selector: 'hf-dashboard-general-applicants',
  templateUrl: './dashboard-general-applicants.component.html',
  styleUrls: ['./dashboard-general-applicants.component.scss']
})
export class DashboardGeneralApplicantsComponent {
  @Input()
  public mortgage: any;

  public formatContactBy = formatContactBy;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  public onOptOutClicked() {
    this.router.navigate(["creditReportOpt"], { relativeTo: this.route });
  }
}
