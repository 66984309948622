import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, NavigationStart } from '@angular/router';
import { Observable, Subject } from 'rxjs';

import { SessionStoreService } from '../../../services/session-store.service';
import { TemplateService } from '../../../services/template.service';
import { layoutChangeAnim } from '../../../shared/animations/layout-animation';
import { sidenavAnim } from '../../../shared/animations/sidenav-animation';
import { DynamicAnimation } from '../../../shared/animations/sidepane-animation';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'hf-dashboard-template',
  templateUrl: './dashboard-template.component.html',
  styleUrls: ['./dashboard-template.component.scss'],
  animations: [sidenavAnim, layoutChangeAnim]
})
export class DashboardTemplateComponent implements OnInit, OnDestroy, AfterViewInit {
  public templateRoutes;
  public isVisible = true;

  showSidenav: Observable<boolean>;
  stateResult: DynamicAnimation = { value: 'hidden', params: { pos: 0 } };
  private unsubscribe$ = new Subject();

  @ViewChild("dynamicSidepanesAnchor", { read: ViewContainerRef })
  root: ViewContainerRef;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public templateService: TemplateService,
    private sessionStoreService: SessionStoreService // keep global instance for app lifecycle
  ) { }

  ngAfterViewInit() {
    this.templateService.rootRef = this.root;
  }

  ngOnInit() {
    this.templateRoutes = this.route;
    this.router.events.pipe(takeUntil(this.unsubscribe$)).subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.templateService.closeSidebar();
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.sessionStoreService.clear();
  }
}
