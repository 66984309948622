import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../../../../environments/environment';
import { Twenty7tecRecommendedProduct } from '../../../../services/twenty7tec-api.service';
import { SelectTwenty7tecMortgageService } from '../../../../shared-layout/side-panes/select-twenty7tec-mortgage/select-twenty7tec-mortgage.service';

export type SourceTwentySevenTecButtonMode = 
  "source"|"select"|"manual";

export interface SourceTwentySevenTecButtonModeChange {
  mode: SourceTwentySevenTecButtonMode;
  mortgage?: Twenty7tecRecommendedProduct;
}

@Component({
  selector: 'hf-source-twenty-seven-tec-button',
  templateUrl: './source-twenty-seven-tec-button.component.html',
  styleUrls: ['./source-twenty-seven-tec-button.component.scss']
})
export class SourceTwentySevenTecButtonComponent implements OnDestroy {
  @Input()
  mode: "source"|"select"|"manual";

  @Input()
  isSourceButtonLoading = false;

  @Output()
  modeChange = new EventEmitter<SourceTwentySevenTecButtonModeChange>();

  mortgage: Twenty7tecRecommendedProduct;

  private destroy$ = new Subject;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private selectTwenty7tecMortgageService: SelectTwenty7tecMortgageService,
  ) {
    this.selectTwenty7tecMortgageService.mortgage$
      .pipe(takeUntil(this.destroy$))
      .subscribe(mortgage => {
        this.mortgage = mortgage;
        this.modeChange.emit({ mode: "select", mortgage });
      });
  }

  onManualClicked() {
    this.modeChange.emit({ mode: "manual" });
  }

  onSelectClicked() {
    this.router.navigate(["selectTwenty7tecMortgage"], { relativeTo: this.route });
  }

  onSourceClicked() {
    this.modeChange.emit({ mode: "source" });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
