import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'hf-details-area',
  templateUrl: './details-area.component.html',
  styleUrls: ['./details-area.component.scss']
})
export class DetailsAreaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
