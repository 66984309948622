import { Component, Input, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';

@Component({
  selector: 'hf-handover-dropdown',
  templateUrl: './handover-dropdown.component.html',
  styleUrls: ['./handover-dropdown.component.scss']
})
export class HandoverDropdownComponent {
  @Input()
  public handovers: any[];
  public value: any;

  constructor(
    @Optional() private ngControl: NgControl
  ) {
    ngControl && (ngControl.valueAccessor = this);
  }

  public onHandoverChange() {
    this.onChangeFn && this.onChangeFn(this.value);
  }

  private onChangeFn: (any) => void;

  writeValue(obj: any): void {
    this.value = obj;
  }
  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }
  registerOnTouched(fn: any): void {
    // throw new Error("Method not implemented.");
  }
  setDisabledState?(isDisabled: boolean): void {
    // throw new Error("Method not implemented.");
  }
}
