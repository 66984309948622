import { Component, Inject, InjectionToken, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ToastService } from '../../../../../../projects/client/src/app/shared/services/toast.service';
import { JournalBasic } from '../../../services/mortgage-api.service';
import { fadeAnimation, sidepaneMove } from '../../../shared/animations/sidepane-animation';

export interface RequestFactFindService {
  requestFrom: string[];

  requested$: Subject<void>;

  requestFactFind(paramMap: ParamMap, journal: JournalBasic): Promise<void>
}

export const REQUEST_FACTFIND_SERVICE = new InjectionToken<RequestFactFindService>("REQUEST_FACTFIND_SERVICE");

@Component({
  selector: 'hf-request-fact-find',
  templateUrl: './request-fact-find.component.html',
  styleUrls: [
    './request-fact-find.component.scss',
    '../../../styles/sidepanes.partial.scss'
  ],
  animations: [sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove]': 'true' }
})
export class RequestFactFindComponent implements OnDestroy {
  public get mortgageId(): string { return this.route.snapshot.params['id'] }
  public get requestFrom(): string[] { return this.requestFactFindService.requestFrom }

  public isSubmitting = false;

  public form = new FormGroup({
    internalNotes: new FormControl(""),
    messageToClient: new FormControl("", Validators.required)
  });

  private destroy$ = new Subject();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    @Inject(REQUEST_FACTFIND_SERVICE) private requestFactFindService: RequestFactFindService,
    private tostService: ToastService
  ) { }

  public ngOnDestroy() {
    this.destroy$.next();
  }

  public onBack() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

  public async onRequest() {
    const value: JournalBasic = this.form.value;

    this.isSubmitting = true;

    try {
      await this.requestFactFindService.requestFactFind(this.route.snapshot.paramMap, value);

      this.tostService.add(`Fact Find request sent: ${this.requestFrom?.join(", ")}`);
      this.requestFactFindService.requested$.next();

      this.onBack();
    } catch (err) {
      this.tostService.add("Failed to request Fact Find. Please try again", "error", err);
    } finally {
      this.isSubmitting = false;
    }
  }
}
