import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'hf-link-button',
  templateUrl: './link-button.component.html',
  styleUrls: ['./link-button.component.scss']
})
export class LinkButtonComponent {
  @Input()
  public icon: string;
}
