import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectUserTypeComponent } from './select-user-type.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomButtonModule } from '../../../shared/custom-button/custom-button.module';
import { CustomSelectorModule } from '../../../shared/custom-selector/custom-selector.module';

@NgModule({
  declarations: [SelectUserTypeComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CustomButtonModule,
    CustomSelectorModule
  ],
  exports: [SelectUserTypeComponent]
})
export class SelectUserTypeModule { }
