import { CommonModule } from '@angular/common';
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FactFindSharedModule } from "../../../../../projects/client/src/app/fact-find/fact-find-shared.module";
import { ClientSharedModule } from "../../../../../projects/client/src/app/shared/client-shared.module";
import { CustomButtonModule } from "../../shared/custom-button/custom-button.module";
import { CustomSelectorModule } from "../../shared/custom-selector/custom-selector.module";
import { CustomSpinnerModule } from "../../shared/custom-spinner/custom-spinner.module";
import { EvergladesSharedMorule } from "../../shared/everglades-shared.module";
import { InformationBoxModule } from "../../shared/information-box/information-box.module";
import { LinkListModule } from "../../shared/link-list/link-list.module";
import { SharedModule } from "../../shared/shared.module";
import { ApplicationListComponent } from "./application-list/application-list.component";
import { ApplicationStatusBadgeComponent } from "./application-status-badge/application-status-badge.component";
import { DashboardGeneralApplicantsComponent } from "./components/dashboard-general-applicants/dashboard-general-applicants.component";
import { MortgageStatusBadgeComponent } from "./components/mortgage-status-badge/mortgage-status-badge.component";
import { MortgageToolbarWithMenuComponent } from "./components/mortgage-toolbar-with-menu/mortgage-toolbar-with-menu.component";
import { SourceTwentySevenTecButtonComponent } from './components/source-twenty-seven-tec-button/source-twenty-seven-tec-button.component';
import { MortgageDashboardClientSheetComponent } from "./mortgage-dashboard/client-sheet/mortgage-dashboard-client-sheet.component";
import { AccountBalanceTableComponent } from "./mortgage-dashboard/credit-report/credit-score/account-balance-table/account-balance-table.component";
import { AccountDetailsComponent } from "./mortgage-dashboard/credit-report/credit-score/account-details/account-details.component";
import { CategoryDetailsComponent } from "./mortgage-dashboard/credit-report/credit-score/category-details/category-details.component";
import { CategoryStatusBoxComponent } from "./mortgage-dashboard/credit-report/credit-score/category-status-box/category-status-box.component";
import { CreditCardsCategoryDetailsComponent } from "./mortgage-dashboard/credit-report/credit-score/credit-cards-category-details/credit-cards-category-details.component";
import { CreditScoreComponent } from "./mortgage-dashboard/credit-report/credit-score/credit-score.component";
import { CurrentAccountsCategoryDetailsComponent } from "./mortgage-dashboard/credit-report/credit-score/current-accounts-category-details/current-accounts-category-details.component";
import { HirePurchasesCategoryDetailsComponent } from "./mortgage-dashboard/credit-report/credit-score/hire-purchases-category-details/hire-purchases-category-details.component";
import { LoansCategoryDetailsComponent } from "./mortgage-dashboard/credit-report/credit-score/loans-category-details/loans-category-details.component";
import { MissedPaymentsBoxComponent } from "./mortgage-dashboard/credit-report/credit-score/missed-payments-box/missed-payments-box.component";
import { MortgageCategoryDetailsComponent } from "./mortgage-dashboard/credit-report/credit-score/mortgage-category-details/mortgage-category-details.component";
import { OtherCategoryDetailsComponent } from "./mortgage-dashboard/credit-report/credit-score/other-category-details/other-category-details.component";
import { MortgageDashboardCreditReportComponent } from "./mortgage-dashboard/credit-report/mortgage-dashboard-credit-report.component";
import { MortgageDashboardFactFindComponent } from "./mortgage-dashboard/fact-find/mortgage-dashboard-fact-find.component";
import { MortgageDashboardGeneralBoxesComponent } from "./mortgage-dashboard/general/components/mortgage-dashboard-general-boxes/mortgage-dashboard-general-boxes.component";
import { NewClientSheetBudgetComponent } from './new-client-sheet/new-client-sheet-budget/new-client-sheet-budget.component';
import { NewClientSheetObjectiveComponent } from "./new-client-sheet/new-client-sheet-objective/new-client-sheet-objective.component";
import { NewClientSheetPreferenceComponent } from "./new-client-sheet/new-client-sheet-preference/new-client-sheet-preference.component";
import { NewClientSheetRecommendedComponent } from './new-client-sheet/new-client-sheet-recommended/new-client-sheet-recommended.component';
import { NewClientSheetComponent } from "./new-client-sheet/new-client-sheet.component";
import { SelectLenderComponent } from "./select-lender/select-lender.component";
import { SelectLenderService } from "./select-lender/select-lender.service";
import { MissedPaymentsBoxRowComponent } from './mortgage-dashboard/credit-report/credit-score/missed-payments-box-row/missed-payments-box-row.component';

const exported = [
  SelectLenderComponent,
  MortgageDashboardGeneralBoxesComponent,
  ApplicationListComponent,
  ApplicationStatusBadgeComponent,
  MortgageToolbarWithMenuComponent,
  MortgageDashboardFactFindComponent,
  MortgageDashboardClientSheetComponent,
  DashboardGeneralApplicantsComponent,
  MortgageStatusBadgeComponent,
  MortgageDashboardCreditReportComponent,
  CreditScoreComponent,
  CategoryStatusBoxComponent,
  MissedPaymentsBoxComponent,
  MissedPaymentsBoxRowComponent,
  CategoryDetailsComponent,
  MortgageCategoryDetailsComponent,
  CreditCardsCategoryDetailsComponent,
  AccountBalanceTableComponent,
  AccountDetailsComponent,
  LoansCategoryDetailsComponent,
  HirePurchasesCategoryDetailsComponent,
  CurrentAccountsCategoryDetailsComponent,
  OtherCategoryDetailsComponent,
  NewClientSheetComponent,
  NewClientSheetObjectiveComponent,
  NewClientSheetPreferenceComponent,
  NewClientSheetBudgetComponent,
  NewClientSheetRecommendedComponent,
  SourceTwentySevenTecButtonComponent,
]

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    EvergladesSharedMorule,
    ClientSharedModule,
    FactFindSharedModule,
    CustomButtonModule,
    CustomSelectorModule,
    CustomSpinnerModule,
    InformationBoxModule,
    LinkListModule,
  ],
  declarations: exported,
  exports: exported,
  providers: [
    SelectLenderService,
  ],
})
export class MortgagesSharedModule { }
