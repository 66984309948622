<div *ngIf="!saveButton && !sidepaneLoading" class="spinner-container"
     [ngClass]="{
    'spinner-light-theme': darkTheme === false,
    'spinner-dark-theme':  darkTheme === true
    }">
</div>

<div *ngIf="saveButton && !sidepaneLoading" class="spinner-container-save-button"
     [ngClass]="{
    'spinner-dark-theme': (darkTheme === true),
    'spinner-light-theme': (darkTheme === false)
    }">
</div>

<div *ngIf="sidepaneLoading" class="spinner-container-sidepane-loading spinner-light-theme">
</div>
