import { Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { fadeAnimation, sidepaneMove } from '../../../shared/animations/sidepane-animation';
import { HELP_TO_BUY_STATUS_NAMES } from "../../../utils/variables.data";
import { SelectHelpToBuyStatusService } from './select-help-to-buy-status.service';


@Component({
  selector: 'hf-select-help-to-buy-status',
  templateUrl: './select-help-to-buy-status.component.html',
  styleUrls: [
    './select-help-to-buy-status.component.scss',
    '../../../styles/sidepanes.partial.scss'
  ],
  animations: [sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove]': 'true' }
})
export class SelectHelpToBuyStatusComponent implements OnDestroy {
  public form = new FormGroup({
    status: new FormControl()
  });

  public statuses = Array.from(HELP_TO_BUY_STATUS_NAMES.keys());
  public HELP_TO_BUY_STATUS_NAMES = HELP_TO_BUY_STATUS_NAMES;

  private destroy$ = new Subject();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private selectHelpToBuyStatusService: SelectHelpToBuyStatusService
  ) {
    this.form.get("status").setValue(this.selectHelpToBuyStatusService.status);

    this.form.get("status").valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(status => {
        this.selectHelpToBuyStatusService.setStatus(status);
        this.onBack();
      });
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }

  public onBack() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }
}
