import { FormBuilder, Validators } from '@angular/forms';
import { FileControlsConfigBuilder } from '../../../../shared/fileuploader/utils/fileuploader.utils';

export const getForm = (fb: FormBuilder) => {
  return fb.group({
    kfi: fb.group(
      new FileControlsConfigBuilder()
        .maxFileSizeDisk()
        .required("")
        .build()),
    recommendationEvidence: fb.group(
      new FileControlsConfigBuilder()
        .maxFileSizeDisk()
        .required("")
        .build()),
    complianceIllustration: fb.group(
      new FileControlsConfigBuilder()
        .maxFileSizeDisk()
        .build()),
    affordabilityCalculator: fb.group(
      new FileControlsConfigBuilder()
        .maxFileSizeDisk()
        .required("")
        .build()),

    illustrationDate: [null, Validators.required],
    scheme: [null, Validators.required],
    htb: [null, Validators.required],
    sharedOwnership: [null, Validators.required],
    loanAmount: [null, Validators.required],
    loanAmountReason: [null, Validators.required],
    transferOfEquity: [null, Validators.required],
    lender: [null, Validators.required],
    type: [null, Validators.required],
    repaymentMethod: [null, Validators.required],
    partAndPartInterest: [null, Validators.required],
    partAndPartRepayment: [null, Validators.required],

    initialRate: [null, Validators.required],
    monthlyRepayment: [null, Validators.required],
    mortgageTerm: fb.group({
      years: [null, Validators.required],
      months: [null, Validators.required],
    }),
    mortgageTermReason: [null, Validators.required],
    productCode: [null, Validators.required],
    productReason: [null, Validators.required],
    furtherAdvanceReason: [null, Validators.required],
    productTransferReason: [null, Validators.required],
    portingReason: [null, Validators.required],
    portFurtherAdvanceReason: [null, Validators.required],
    notPortingReason: [null, Validators.required],
    erc: [null, Validators.required],
    ercAmount: [null, Validators.required],
    ercReason: [null, Validators.required],
    sharedOwnershipReason: [null, Validators.required],
    endDateType: [null, Validators.required],
    endDate: [null, Validators.required],
    endYearsMonths: fb.group({
      years: [null, Validators.required],
      months: [null, Validators.required],
    }),
    productEndDateReason: [null, Validators.required],
    portable: [null, Validators.required],
    lifetimeProductRateReason: [null, Validators.required],
    offset: [null, Validators.required],
    offsetReason: [null, Validators.required],
    newErc: [null, Validators.required],
    newErcAmount: [null, Validators.required],
    noErcReason: [null, Validators.required],
    mortgageBeyondRetirement: [null, Validators.required],
    retirementIncomeDetail: [null, Validators.required],

    debtConsolidation: [null, Validators.required],
    debtConsolidationMatchesObjective: [null, Validators.required],
    amountUsedToRepay: [null, Validators.required],
    debtConsolidationNote: [null, Validators.required],
    debtConsolidationReason: [null, Validators.required],
    otherDebtConsolidationReasonNotAppropriateReason: [null],
    notProceedingReason: [null, Validators.required],

    liabilitiesNotRepaidReason: [null, Validators.required],
    notes: [null, Validators.required],
    fees: [null, Validators.required],
    feesToLoan: [null, Validators.required],
    feesToLoanReason: [null, Validators.required],
    otherFeesToLoanReason: [null],
    propCode: [null, Validators.required],

    reasonForChange: [null],
  });
}
