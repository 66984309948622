import { Component, Inject, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { filterMap } from '../../../../../../projects/client/src/app/shared/functions/filter-map';
import { ToastService } from '../../../../../../projects/client/src/app/shared/services/toast.service';
import { MortgageApiService, RemortgageDTO } from '../../../services/mortgage-api.service';
import { NavigationService } from '../../../services/navigation.service';
import { UserService } from '../../../services/user.service';
import { SelectAdvisorForAssignmentService } from '../../../shared-layout/side-panes/select-advisor-for-assignment/select-advisor-for-assignment.service';
import { fadeAnimation } from '../../../shared/animations/fade.animation';
import { sidepaneMove } from '../../../shared/animations/sidepane-animation';
import { CLIENT_CATEGORY_NAME, CLIENT_SCHEME_TYPE_NAME, CLIENT_TYPE_NAME, CONTACT_BY, CONTACT_BY_NAME, PROPERTY_VALUE_NAME, USER_TYPE } from '../../../utils/variables.data';
import { composeClientTypeName } from '../add-mortgage/add-mortgage.component';
import { MortgagesService } from '../mortgages.service';
import { RemortgageSelectAdvisorService } from './remortgage-select-advisor.service';

@Component({
  selector: 'hf-add-remortgage',
  templateUrl: './add-remortgage.component.html',
  styleUrls: ['./add-remortgage.component.scss', '../../../styles/sidepanes.partial.scss'],
  animations: [sidepaneMove, fadeAnimation],
  host: { '[@sidepaneMove]': 'true' },
  providers: [{ provide: SelectAdvisorForAssignmentService, useClass: RemortgageSelectAdvisorService }]
})
export class AddRemortgageComponent implements OnDestroy {
  public form = this.fb.group({
    advisor: [null, Validators.required],
    propertyValue: [null, Validators.required],
    clientCategory: [null, Validators.required],
    clientType: [null, Validators.required],
    clientScheme: [null],
    notes: [null, Validators.required],

    _contactBy: [null, Validators.required],
  });

  public PROPERTY_VALUE_NAME = PROPERTY_VALUE_NAME;
  public CLIENT_SCHEME_TYPE_NAME = CLIENT_SCHEME_TYPE_NAME;
  public CONTACT_BY_NAME = CONTACT_BY_NAME;
  public clientCategoryName = filterMap(CLIENT_CATEGORY_NAME, ([category]) => category !== "PURCHASE")
  public clientTypeName: typeof CLIENT_TYPE_NAME;

  public isLoading = false;
  public isSubmitting = false;

  public get value() { return this.form.value as RemortgageDTO }
  public get mortgageId() { return this.route.snapshot.paramMap.get("id") }

  private destroy$ = new Subject();

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private navigationService: NavigationService,
    private userService: UserService,
    private mortgageApiService: MortgageApiService,
    @Inject(SelectAdvisorForAssignmentService) private remortgageSelectAdvisor: RemortgageSelectAdvisorService,
    private toastService: ToastService,
  ) {
    this.setupForm()
    this.initialize();
  }

  public onSelectAdvisorClicked() {
    this.router.navigate(["selectAdvisor"], { relativeTo: this.route });
  }

  public async onSubmit() {
    const value = this.getFinishedValue();
    this.isSubmitting = true;

    try {
      const mortgage = await this.mortgageApiService.addRemortgage(value);

      this.toastService.add("Remortgage added.");
      this.navigationService.goToMortgage(mortgage.id);
    } catch (e) {
      this.toastService.add("Error when saving", "error", e);
    }

    this.isSubmitting = false;
  }

  public onBack() {
    this.router.navigate([".."], { relativeTo: this.route });
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }

  private getFinishedValue() {
    const map = new Map<CONTACT_BY, string>([
      ["EMAIL", "contactByEmail"],
      ["PHONE", "contactByPhone"],
      ["MESSAGE", "contactByMessage"],
    ]);
    const value = { ...this.value, mortgageId: this.mortgageId };
    const keys: CONTACT_BY[] = this.form.value._contactBy;

    keys?.map(key => map.get(key))
      .filter(key => !!key)
      .forEach(key => value[key] = true);

    return value;
  }

  private initialize() {
    if (this.userService.isAdvisor()) {
      const { id, fullName } = this.userService.getUser()
      const advisor = { id, fullName };

      this.form.patchValue({ advisor });
    }
  }

  private setupForm() {
    this.remortgageSelectAdvisor.advisorSelected$.pipe(takeUntil(this.destroy$)).subscribe(advisor => {
      this.form.patchValue({ advisor });
    });

    this.form.get("clientCategory").valueChanges.subscribe(clientCategory => {
      this.clientTypeName = composeClientTypeName("EXISTING_CLIENT", clientCategory);
    });

    this.clientTypeName = composeClientTypeName("EXISTING_CLIENT", null);
  }
}
