import { Component, Input } from '@angular/core';
import { ExperianCreditSearchReportDTO } from '../../../../../../services/experian-credit-report-api.service';

@Component({
  selector: 'hf-other-category-details',
  templateUrl: './other-category-details.component.html',
  styleUrls: ['./other-category-details.component.scss']
})
export class OtherCategoryDetailsComponent {
  @Input()
  public report: ExperianCreditSearchReportDTO;
}
