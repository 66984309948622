import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SignOffSectionConfig } from '../../../../../../../../projects/client/src/app/protection-fact-find/steps/sign-off-component/components/sign-off-applicant-details/sign-off-applicant-details.component';
import { Confirmation, DialogService } from '../../../../../../../../projects/client/src/app/shared/services/dialog.service';
import { ToastService } from '../../../../../../../../projects/client/src/app/shared/services/toast.service';
import { ReturnSection } from '../../../../../services/fact-find-api.service';
import { ProtectionFactFindApiService, ProtectionFactFindDataAllDTO } from '../../../services/protection-fact-find-api.service';
import { ProtectionDashboardFactFindService } from './protection-dashboard-fact-find.service';
import { Environment } from '../../../../../services/environment.service';
import { ProtectionDashboardService } from '../protection-dashboard.service';
import { ProtectionDashboardComponent } from '../protection-dashboard.component';

@Component({
  selector: 'hf-protection-dashboard-fact-find',
  templateUrl: './protection-dashboard-fact-find.component.html',
  styleUrls: ['./protection-dashboard-fact-find.component.scss']
})
export class ProtectionDashboardFactFindComponent implements OnDestroy {
  public data: ProtectionFactFindDataAllDTO;

  public isReturnButtonEnabled = false;
  public isApproveButtonEnabled = false;
  public isReactivateButtonEnabled = false;

  public isLoading = true;

  public visibleApplicantId = new Subject<string>();

  private get protectionCaseId() { return this.route.snapshot.paramMap.get("id") }
  private destroy$ = new Subject();
  private observer: IntersectionObserver;
  private visibility: { [key: string]: boolean } = {}

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private environment: Environment,
    private protectionFactFindApiService: ProtectionFactFindApiService,
    private protectionDashboardFactFindService: ProtectionDashboardFactFindService,
    private protectionDashboardService: ProtectionDashboardService,
    private dialogService: DialogService,
    private toastService: ToastService,
    private dashboard: ProtectionDashboardComponent,
  ) {
    this.initialize();

    this.protectionDashboardFactFindService.reload$.pipe(takeUntil(this.destroy$)).subscribe(() => this.reload());
  }

  public onEditClicked() {
    const leadApplicant = this.data.applicants.find(applicant => applicant.lead);
    const applicantId = leadApplicant?.applicant?.id;

    if (!applicantId) {
      return;
    }

    const url = `${this.environment.clientWebUrl}/protection-fact-find/${this.protectionCaseId}/crossroad?applicantId=${applicantId}`;

    window.open(url, "_blank");
  }

  public onReactivateClicked() {
    const confirmation: Confirmation = {
      title: "Reactivate Fact Find",
      message: "The Fact Find has already been approved and should not be reactivated unless there is an issue with the data.",
      acceptLabel: "Reactivate Anyway",
      acceptButtonType: "primary"
    }

    const doReactivate = async () => {
      try {
        await this.protectionFactFindApiService.reactivateFactFind(this.protectionCaseId);

        await this.reload();
        this.toastService.add("Fact Find reactivated successfully");
      } catch (err) {
        this.toastService.add("Failed to reactivate Fact Find", "error", err);
      }
    }

    this.dialogService.confirmAndDo(confirmation, doReactivate);
  }

  public onApproveClicked() {
    const confirmation: Confirmation = {
      title: "Approve Fact Find",
      message: "Please confirm the Fact Find has been approved as accurate.",
      acceptLabel: "Approve",
      acceptButtonType: "primary"
    }

    const doApprove = async () => {
      try {
        await this.protectionFactFindApiService.approveFactFind(this.protectionCaseId);

        await this.reload();
        this.dashboard.refresh();
        this.toastService.add("Fact Find approved successfully");
      } catch (err) {
        this.toastService.add("Failed to approve Fact Find", "error", err);
      }
    }

    this.dialogService.confirmAndDo(confirmation, doApprove);
  }

  public onReturnClicked() {
    const confirmation: Confirmation = {
      title: "Return Fact Find",
      message: "Please make sure you have returned all relevant sections before continuing. The client will be asked to make the clarifications you have requested.",
      acceptLabel: "Return",
      acceptButtonType: "red"
    };

    const doReturn = async () => {
      try {
        await this.protectionFactFindApiService.returnFactFind(this.protectionCaseId);

        await this.reload();
        this.toastService.add("Fact Find returned successfully");
      } catch (err) {
        this.toastService.add("Failed to return Fact Find", "error", err);
      }
    }

    this.dialogService.confirmAndDo(confirmation, doReturn);
  }

  public onSectionButtonClicked(config: SignOffSectionConfig, applicantId: string) {
    if (config.markToReturn) {
      this.onUndoReturn(config, applicantId);
    } else {
      const step = config.step;
      this.router.navigate(["returnFactFind"], { relativeTo: this.route, queryParams: { step, applicantId } });
    }
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }

  private async onUndoReturn(config: SignOffSectionConfig, applicantId: string) {
    const confirmation: Confirmation = {
      title: "Undo Return",
      message: "Are you sure you want to undo the return of this section?",
      acceptLabel: "Undo Return",
      acceptButtonType: "primary"
    };

    const undoReturn = async () => {
      try {
        const value: ReturnSection = { applicantId, step: config.step as any };
        await this.protectionFactFindApiService.undoReturn(this.protectionCaseId, value);

        await this.reload();
        this.toastService.add("Section return undone");
      } catch (err) {
        this.toastService.add("Failed to undo section return", "error", err);
      }
    }

    this.dialogService.confirmAndDo(confirmation, undoReturn);
  }

  private setupNavigationObserver() {
    if (!IntersectionObserver) {
      return;
    }

    const options = {
      // threshold: 1
    };
    const callback: IntersectionObserverCallback = (entries, observer) => {
      entries.forEach(e => {
        // console.log(`intersection: `, e);

        this.visibility[e.target.id] = e.isIntersecting;
      });

      const visible = Object.entries(this.visibility).filter(([k, v]) => v);
      visible.length === 1 && this.visibleApplicantId.next(visible[0][0].split("-")[1]);
      // entries.forEach(e => e.isIntersecting && console.log(`intersection: ${e.target.id}`))
    };

    this.observer = new IntersectionObserver(callback, options);

    // const elements = [...document.querySelectorAll("[id^='A']") as any];
    const elements = [...document.querySelectorAll("[id^='applicant']") as any];
    elements.forEach(e => this.observer.observe(e));
  }

  private setupButtons() {
    const stepStatuses = this.data.applicants.flatMap(applicant => applicant.stepStatuses)

    this.isReturnButtonEnabled = stepStatuses.some(ss => ss.markToReturn);
    this.isApproveButtonEnabled = this.data.factFindStatus === "SIGNED" && !this.isReturnButtonEnabled;
    this.isReactivateButtonEnabled = this.data.factFindStatus === "APPROVED";
  }

  private async reload() {
    this.data = await this.protectionFactFindApiService.getFactFindDataHeron(this.protectionCaseId);
    this.setupButtons();
  }

  private async initialize() {
    this.isLoading = true;

    this.data = await this.protectionFactFindApiService.getFactFindDataHeron(this.protectionCaseId);
    this.setupButtons();
    setTimeout(() => this.setupNavigationObserver());

    this.isLoading = false;
  }
}
