<div class="header">
  <div class="heading">
    Payment and Preferences

    <div *ngIf="config?.updatedBy" class="saved-by">
      Saved by {{ config.updatedBy.fullName }} at {{config?.lastUpdated | date:'HH:mm on E d MMM y'}}
    </div>
  </div>

  <hfc-button *ngIf="config.buttonType" [type]="config.buttonType" [isLoading]="config.buttonIsLoading" size="small" (click)="buttonClick.next(config)">{{ config.buttonLabel }}</hfc-button>
</div>

<div *ngIf="config.returnComment" class="returnComment">
  {{config.returnComment}}
  <div class="lastUpdated">{{config.lastUpdated | date:'d MMM y, HH:mm'}}</div>
</div>

<div *ngIf="config.stepStatus == 'NOT_STARTED'" class="notReady">
  Waiting for applicant to complete
</div>

<div *ngIf="config.stepStatus !== 'NOT_STARTED' && data.paymentDetails" class="content">
  <div class="column">
    <div class="record">
      <span class="label">Account Holder Name</span>
      <span class="value">{{ data.paymentDetails.accountHolder }}</span>
    </div>
    <div class="record">
      <span class="label">Account Number</span>
      <span class="value">{{ data.paymentDetails.accountNumber }}</span>
    </div>
    <div class="record">
      <span class="label">Sort Code</span>
      <span class="value">{{ data.paymentDetails.sortCode }}</span>
    </div>
  </div>

  <div class="column">
    <div class="record">
      <span class="label">Bank</span>
      <span class="value">{{ data.paymentDetails.bank }}</span>
    </div>
    <div class="record">
      <span class="label">Branch</span>
      <span class="value">{{ data.paymentDetails.branch }}</span>
    </div>
    <div class="record">
      <span class="label">Debit Collection Day of the Month</span>
      <span class="value">{{ data.paymentDetails.directDebitCollectionDay || DEBIT_COLLECTION_DATE_NAME.get(data.paymentDetails.directDebitCollection) }}</span>
    </div>
  </div>
</div>
