<div class="separator"></div>

<div class="section">
  <div class="title">
    Applicant{{applicants?.length > 1 ? "s" : ""}} Details
  </div>
  <ng-container *ngFor="let applicant of applicants; let first = first">
    <div class="applicant-name" *ngIf="applicants?.length > 1" [class.first]="first">{{getApplicantNameHeading(applicant, first)}}</div>
    <div class="column">
      <hfc-review-section-item label="Title">{{applicant.title | valueOrDash}}</hfc-review-section-item>
      <hfc-review-section-item label="Middle Name">{{applicant.middleName | valueOrDash}}</hfc-review-section-item>
      <hfc-review-section-item label="Date of Birth">{{applicant.dateOfBirth | date: 'd MMMM, y' | valueOrDash}}</hfc-review-section-item>
      <hfc-review-section-item label="Gender">{{GENDER_NAME.get(applicant.gender) | valueOrDash}}</hfc-review-section-item>
      <hfc-review-section-item label="Address">{{applicant.address | valueOrDash}}</hfc-review-section-item>
      <hfc-review-section-item label="Postcode">{{applicant.postcode | valueOrDash}}</hfc-review-section-item>
    </div>
    <div class="column">
      <hfc-review-section-item label="Occupation">{{applicant.occupationCode | valueOrDash}}</hfc-review-section-item>
      <hfc-review-section-item label="Income">{{applicant.income | currency: 'GBP':undefined:'1.2-2' | valueOrDash}}</hfc-review-section-item>
      <hfc-review-section-item label="Smoker">{{applicant.smoker | yesNo | valueOrDash}}</hfc-review-section-item>
    </div>
    <div class="column">
      <hfc-review-section-item label="Notes">{{applicant.notes | valueOrDash}}</hfc-review-section-item>
    </div>
  </ng-container>
</div>
