import { Subject } from "rxjs";
import { Injectable } from "@angular/core";
import { UserService } from "../../services/user.service";
import { Task } from "./task-api.service";

@Injectable()
export class TaskService {
  public reloadTasks$ = new Subject();

  public reloadCurrentTask$ = new Subject();

  private get userId() { return this.userService.getUser().id }

  constructor(private userService: UserService) { }

  public hasPermissionToEditTask(task: Task) {
    return this.userService.isAdmin()
      || this.userService.getUser().userType === "CASE_PROGRESSION"
      || this.userId == task.createdBy.id;
  }
    
  public hasPermissionToDoneTask(task: Task) {
    return true;

    // return this.userService.isAdmin()
    //   || this.userService.getUser().userType === "CASE_PROGRESSION"
    //   || this.userId == task.createdBy.id
    //   || this.userId == task.assignee.id;
  }

  public hasPermissionToDeleteTask(task: Task) {
    return true;

    // return this.userService.isAdmin()
    //   || this.userId == task.createdBy.id;
  }

  public hasPermissionToReassignTask(task: Task) {
    return this.userService.isAdmin()
      || this.userId == task.createdBy.id
      || this.userId == task.assignee.id;
  }
}
